// constant
import { GET, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { LAYER_NAME_FAILURE, LAYER_NAME_SUCCESS, LAYER_NAME_REQUEST } from "./actionConstants";

// url
import { LAYER_NAME_USER_URL } from "../common/urls";

const getlayerRequested = () => ({
    type: LAYER_NAME_REQUEST,
});

const getlayerSuccess = (data) => ({
    type: LAYER_NAME_SUCCESS,
    data,
});

const getlayerFailure = (data) => ({
    type: LAYER_NAME_FAILURE,
    data,
});

export const getProductLayerUser = (successCallBack, failureCallBack) => (dispatch) => {

    dispatch(getlayerRequested);

    const url = LAYER_NAME_USER_URL;

    const onSuccess = (response) => {
        successCallBack(response);
        dispatch(getlayerSuccess(response));
    };

    const onFailure = (response) => {
        failureCallBack(response);
        dispatch(getlayerFailure(response));
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(GET, url, "", onSuccess, onFailure, dispatch, token);
        }
    });
};
