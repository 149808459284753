// react
import React, { useState } from "react";

// plugin
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import OtpInput from '@prakharsharma/react-otp-input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

// component
import LoaderButton from "../../component/loader-button/LoaderButton";

// function
import { userOtp } from "../../action/otp";

// style
import "../../layouts/otp-layout/otp.scss";

// Image
import close from '../../asstes/images/close.png';

// contanier

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    colors: {
        borderColor: "green",
    },
}));

const OtpLayout = (props) => {
    let history = useHistory();

    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [otp, setOtp] = useState("");
    const [OTP, setOTP] = useState("");
    const classes = useStyles();

    const [loginLoader, setLoginLoader] = useState(false);

    const triggerOtpSend = () => {
        var dataSet = {
            email: email,
            phone: phone,
            otp: OTP,
        };
        props.userOtp(dataSet, otpSuccessCallBack, otpfailureCallBack);
    }
    const triggerOtpSubmit = () => {
        // console.log(email);
        // setLoginLoader(true);
    };
    const otpfailureCallBack = (response) => {
        console.log("fails", response);
        // setLoginLoader(false);
    };
    const otpSuccessCallBack = (response) => {
        console.log("succes", response);
        // history.push("/dashBoard");
        // setLoginLoader(true);
        // setTimeout(() => {
        //     window.location.reload();
        // }, 300);
    };


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="signup-section"
        >
            <Modal.Body >
                <div className="close-icon" onClick={props.onHide}>
                    <img src={close} alt="close" /></div>
                <div className="irri-otp">
                    <div className="otp">
                        <h2>Otp</h2>
                        <div className="otp-field">
                            <TextField
                                id="outlined-password-input"
                                label="Email"
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="off"
                                variant="outlined"
                            />
                            <PhoneInput
                                country={'in'}
                            // value={this.state.phone}
                            // onChange={phone => this.setState({ phone })}
                            // value={phone}
                            // onChange={(e) => setPhone(e.target.value)}
                            />
                            <div className="otp-sections">
                                <OtpInput
                                    onChange={setOTP}
                                    numInputs={4}
                                    separator={<span>-</span>} />
                            </div>
                            <div className="resend-otp">
                                <LoaderButton
                                    btnName={"Send Otp"}
                                    btnCallBack={triggerOtpSend}
                                    btnDisable={loginLoader}
                                    btnLoader={loginLoader}
                                />
                                {/* <LoaderButton
                                    btnName={"Resend Otp"}
                                    btnCallBack={triggerOtpSend}
                                    btnDisable={loginLoader}
                                    btnLoader={loginLoader}
                                /> */}
                                <LoaderButton
                                    btnName={"Confirm"}
                                    btnCallBack={triggerOtpSubmit}
                                    btnDisable={loginLoader}
                                    btnLoader={loginLoader}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

// redux;
const mapStateToProps = (state) => {
    return {
        // isFetchingOtp: state.userOtp.isFetchingOtp,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            userOtp: userOtp,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpLayout);
