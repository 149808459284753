// constant
import { GET, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { VECTOR_LAYER_NAME_FAILURE, VECTOR_LAYER_NAME_SUCCESS, VECTOR_LAYER_NAME_REQUEST } from "./actionConstants";

// url
import { VECTOR_LAYER_NAME_URL } from "../common/urls";

const getVectorlayerRequested = () => ({
    type: VECTOR_LAYER_NAME_REQUEST,
});

const getVectorlayerSuccess = (data) => ({
    type: VECTOR_LAYER_NAME_SUCCESS,
    data,
});

const getVectorlayerFailure = (data) => ({
    type: VECTOR_LAYER_NAME_FAILURE,
    data,
});

export const getVectorLayerName = (successCallBack, failureCallBack) => (dispatch) => {

    dispatch(getVectorlayerRequested);

    const url = VECTOR_LAYER_NAME_URL;
      
    const onSuccess = (response) => {
        successCallBack(response);
        dispatch(getVectorlayerSuccess(response));
    };

    const onFailure = (response) => {
        failureCallBack(response);
        dispatch(getVectorlayerFailure(response));
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(GET, url, "", onSuccess, onFailure, dispatch, token);
        }
    });
};
