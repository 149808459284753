import {
  DISTRICTS_LIST_FAILURE,
  DISTRICTS_LIST_SUCCESS,
  DISTRICTS_LIST_REQUEST,
  } from "../action/actionConstants";
  
  const initialState = {
    isFetchingDistricts: false,
    error: false,
    status: "",
    message: "intial",
  };
  
  const districtsList = (state = initialState, action) => {
    switch (action.type) {
      case DISTRICTS_LIST_REQUEST:
        return {
          ...state,
          isFetchingDistricts: true,
          message: "Request",
        };
      case DISTRICTS_LIST_FAILURE:
        return {
          ...state,
          isFetchingDistricts: false,
          error: true,
          status: action.data.status,
          message: "Failure",
        };
      case DISTRICTS_LIST_SUCCESS:
        return {
          ...state,
          isFetchingDistricts: true,
          error: false,
          status: action.data.status,
          message: "Success",
        };
      default:
        return state;
    }
  };
  
  export default districtsList;
  