import {
    ENABLE_FAILURE,
    ENABLE_SUCCESS,
    ENABLE_REQUEST,
  } from "../action/actionConstants";
  
  const initialState = {
    isFetchingEnable: false,
    error: false,
    status: "",
    message: "intial",
  };
  
  const enableUser = (state = initialState, action) => {
    switch (action.type) {
      case ENABLE_REQUEST:
        return {
          ...state,
          isFetchingEnable: true,
          message: "Request",
        };
      case ENABLE_FAILURE:
        return {
          ...state,
          isFetchingEnable: false,
          error: true,
          status: action.data.status,
          message: "Failure",
        };
      case ENABLE_SUCCESS:
        return {
          ...state,
          isFetchingEnable: true,
          error: false,
          status: action.data.status,
          message: "Success",
        };
      default:
        return state;
    }
  };
  
  export default enableUser;
  