// react
import React from "react";

import CompareMap from "../../layouts-components/sesson-components/map-compare/MapCompare";

const Compare = () => {
    return <div>
        <CompareMap />
    </div>
}


export default Compare;