// react
import React, { useState, useRef } from "react";

// plugin
import BackgroundSlider from 'react-background-slider';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

// function
import { loginUser } from "../../action/login";

// component
import LoaderButton from "../../component/loader-button/LoaderButton";
import Header from "../../layouts-components/dash-board-components/header/Header";
import ComponentLoader from "../../public-screen/component-loader/ComponetLoader";
// style
import "../../layouts/login-layout/login.scss";

// Images
import back1 from "../../asstes/images/login/1_Odisha_Vegetation.gif";
import back2 from "../../asstes/images/login/2_Rice-fields_droneview.JPG";
import back3 from "../../asstes/images/login/3_Fallow-fields_droneview.JPG";
import back4 from "../../asstes/images/login/4_Line_sowing_DSR.jfif";
import back5 from "../../asstes/images/login/5_Crop_Intensify_Diversify3.png";
import mapLogo from "../../asstes/images/map.png";
// contanier

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  colors: {
    borderColor: "green",
  },
}));

const LogInLayout = (props) => {
  let history = useHistory();
  const inputEl = useRef(null)

  const classes = useStyles();

  const [lemail, setLemail] = useState("");
  const [lpassword, setLpassword] = useState("");
  const [loginLoader, setLoginLoader] = useState(false);
  const [lpasswordErrorShow, setLPasswordErrorShow] = useState(false);
  const [LEmailErrorShow, setLEmailErrorShow] = useState(false);

  // Email
  const lemailValidCalBack = (e) => {
    var validMail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
      e
    );
    // console.log(!validMail);
    setLEmailErrorShow(!validMail);
    setLemail(e);
  };
  // Password
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const passwordVaildCalBack = (e) => {
    var pas = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    ).test(e);
    // console.log(!pas);
    setLPasswordErrorShow(!pas);
    setLpassword(e);
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const triggerLogin = () => {

    var isLemail = lemail === "";
    var isLpassword = lpassword === "";
    var validMail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
      lemail
    );
    var validsuccess = isLemail && isLpassword;

    // console.log(isLemail);
    // console.log(isLpassword);

    if (!validsuccess) {
      console.log("Both are Success");
      if (validMail) {
        console.log("Login id is vaild");
        // toast.error("Please Fill All Field");
        setLoginLoader(true);
        props.loginUser(
          lemail,
          lpassword,
          loginSuccessCallBack,
          loginfailureCallBack
        );
      } else {
        console.log("Login id is not vaild");
        toast.error("Please enter login id", {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
    } else {
      console.log("please check value");
      toast.error("Please enter required fields", {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  const loginSuccessCallBack = (response) => {
    // console.log(response);

    if (response.data.isAdmin === true) {
      // setAdmin("admin");
      history.push("/SRFCSD-Odisha/admin");
      setLoginLoader(true);
      // setTimeout(() => {
      // window.location.reload();
      // }, 100);
    } else {
      // setAdmin("public");
      history.push("/SRFCSD-Odisha/dashBoard");
      setLoginLoader(true);
      // setTimeout(() => {
      // window.location.reload();
      // }, 100);
    }
  };

  const loginfailureCallBack = () => {
    console.log("fails");
    setLoginLoader(false);
    toast.error("Please check login details", {
      position: toast.POSITION.BOTTOM_CENTER
    });
  };

  return (
    <div className="irri-login">
      <Header
        filterSidebar={() => console.log()}
        barChatScreen={() => console.log()}
        compareCallBack={() => console.log()}
        headerCerdential={{
          isVisble: false
        }}
        mapScreen={false}
        silderScreen={false}
        graphScreen={false}
        loginuiHeader={false}
      />

      <div className="login-select">
        <div className="login">

          <div className="map-logo">
            <label>
              <img src={mapLogo} alt="IrriLogo" />
            </label>
          </div>

          <div className="login-field">
            <TextField
              id="outlined-password-input"
              label="Email"
              value={lemail}
              onChange={(e) => lemailValidCalBack(e.target.value)}
              type="text"
              autoComplete="off"
              variant="outlined"
              error={LEmailErrorShow}
            />
            <FormControl
              className={clsx(
                classes.margin,
                classes.textField,
                classes.colors
              )}
              variant="outlined"
              autoComplete="off"
              error={lpasswordErrorShow}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={lpassword}
                onChange={(e) => passwordVaildCalBack(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
                autoComplete="off"
              />
              {lpasswordErrorShow ? (
                <FormHelperText id="component-error-text">
                  Use 8 or more characters with a mix of letters, numbers &
                  symbols
                </FormHelperText>
              ) : null}
            </FormControl>
            {/* <div className="forgot">
            <div onClick={() => setModalShow(true)}>
              Forgot Password ?
            </div> */}
            {/* <ForgotPasswordLayout  */}
            {/* <OtpLayout
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div> */}
            <LoaderButton
              btnName={"Login"}
              btnCallBack={triggerLogin}
              btnDisable={loginLoader}
              btnLoader={loginLoader}
            />
          </div>

          <BackgroundSlider
            images={[back1, back2, back3, back4, back5,]}
            duration={2} transition={5} />

        </div>

        {/* {(() => {
          if (loginLoader) {
            return (<div class="cloader"> <ComponentLoader /></div>);
          }
        })()} */}
      </div>
    </div>
  );
};

// redux;
const mapStateToProps = (state) => {
  return {
    isFetchingLogin: state.login.isFetchingLogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginUser: loginUser,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LogInLayout);
