// react
import React from "react";

// plugin
import Button from "@material-ui/core/Button";

import "./loader.scss";

const LoaderButton = (props) => {

  return (
    <Button
      variant="contained"
      onClick={props.btnCallBack}
      disabled={props.btnDisable}
      className="loader-button"
    >
      {/* <p>{props.btnName}</p> */}
      {(() => {
        if (props.btnLoader) {
          return (
            <div class="loadbtn">
              <div class="loader" >
                <div class="circle" id="a"></div>
                <div class="circle" id="b"></div>
                <div class="circle" id="c"></div>
              </div>
            </div>
          );
        } else {
          return <p>{props.btnName}</p>;
        }
      })()}
    </Button>
  );
};

export default LoaderButton;
