// react
import React from "react";

// plugin

// function

// components
import SessonLayout from "../../layouts/sesson-layout/SessonLayout"

// contanier

const Sesson = () => {
  return <SessonLayout />;
};

export default Sesson;
