// constant
import { POST } from "../common/constants";

// api
import { apiCall } from "../common/connect";

// action Constant
import { ENABLE_FAILURE, ENABLE_SUCCESS, ENABLE_REQUEST } from "./actionConstants";

// url
import { ENABLE_USER_URL } from "../common/urls";


const enableRequested = () => ({
  type: ENABLE_REQUEST,
});

const enableSuccess = (data) => ({
  type: ENABLE_SUCCESS,
  data,
});

const enableFailure = (data) => ({
  type: ENABLE_FAILURE,
  data,
});

export const enableUser = ( userData, successCallBack, failureCallBack) => (dispatch) => {
  dispatch(enableRequested);

  const url = ENABLE_USER_URL;

  const onSuccess = (response) => {
    successCallBack(response);
    dispatch(enableSuccess(response));
  };

  const onFailure = (response) => {
    failureCallBack(response);
    dispatch(enableFailure(response));
  };

  apiCall(POST, url, userData, onSuccess, onFailure, dispatch);
};
