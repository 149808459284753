import React, { Fragment } from 'react';
import './App.css';
import "./asstes/styles/common-style.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './router/Routes';

const App = () => {
  return (
    <Fragment>
      <ToastContainer />
      <Routes />
    </Fragment>
  );
};

export default App;