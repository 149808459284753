import {
    BLOCK_LIST_FAILURE,
    BLOCK_LIST_SUCCESS,
    BLOCK_LIST_REQUEST,
    } from "../action/actionConstants";
    
    const initialState = {
      isFetchingBlock: false,
      error: false,
      status: "",
      message: "intial",
    };
    
    const blockList = (state = initialState, action) => {
      switch (action.type) {
        case BLOCK_LIST_REQUEST:
          return {
            ...state,
            isFetchingBlock: true,
            message: "Request",
          };
        case BLOCK_LIST_FAILURE:
          return {
            ...state,
            isFetchingBlock: false,
            error: true,
            status: action.data.status,
            message: "Failure",
          };
        case BLOCK_LIST_SUCCESS:
          return {
            ...state,
            isFetchingBlock: true,
            error: false,
            status: action.data.status,
            message: "Success",
          };
        default:
          return state;
      }
    };
    
    export default blockList;
    