import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";

// component
import AdminSignUpLayout from "../../layouts/admin-signup-layout/AdminSignupLyout";
import ForgotPasswordLayout from "../../layouts/forgot-password-layout/ForgotPasswordLayout";
import EnableDisableLayout from "../../layouts/enable-disable-layout/EnableDisableLayout";

// 
import { logoutUser } from "../../action/logout";

// style
import "../../containers/admin/admin.scss";

import { removeAllCookies } from "../../common/localStorage";


const Admin = (props) => {
    let history = useHistory();
    // Popup
    const [signupmodalShow, setSignupModalShow] = React.useState(false);
    const [changemodalShow, setChangeModalShow] = React.useState(false);
    const [edemailmodalShow, setEDEmailModalShow] = React.useState(false);

    const triggerAdminLogout = () => {

        props.logoutUser(succesCallBackLogout);
    };

    const succesCallBackLogout = () => {
        history.push("/");
        setTimeout(() =>{
            removeAllCookies();
            setTimeout(() => {
                window.location.reload();
            }, 100);
        }, 200);
    }

    // 
    return (
        <div className="admin-section">
            <div className="admin-wrapper">
                <h2>Admin</h2>
                {/* Admin List */}
                <div className="admin-list">
                    <div className="margin-bottom admin-signup">
                        <div onClick={() => setSignupModalShow(true)}>
                            <label className="common-text"> Admin SignUp </label>
                        </div>
                        <AdminSignUpLayout
                            show={signupmodalShow}
                            onHide={() => setSignupModalShow(false)}
                            closeSingUpModel={() => setSignupModalShow(false)}
                        />
                    </div>
                    <div className="margin-bottom change-password">
                        <div onClick={() => setChangeModalShow(true)}>
                            <label className="common-text"> Change User Password</label>
                        </div>
                        <ForgotPasswordLayout
                            show={changemodalShow}
                            onHide={() => setChangeModalShow(false)}
                            closeForgotUpModel={() => setChangeModalShow(false)}
                        />
                    </div>
                    <div className="change-password">
                        <div onClick={() => setEDEmailModalShow(true)}>
                            <label className="common-text">   Enable & Disable </label>
                        </div>
                        <EnableDisableLayout
                            show={edemailmodalShow}
                            onHide={() => setEDEmailModalShow(false)}
                            closeEnableDisableUpModel={() => setEDEmailModalShow(false)}
                        />
                    </div>
                </div>
                <div className="admin-logout">
                    <label className="common-text" onClick={triggerAdminLogout}> Logout </label>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logoutUser: logoutUser,
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Admin);