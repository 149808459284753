// react
import React, { useState } from "react";
import { toast } from 'react-toastify';

// function
import { forgotpasswordUser } from "../../action/forgotPassword";

// plugin
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import FormHelperText from '@material-ui/core/FormHelperText';

// component
import LoaderButton from "../../component/loader-button/LoaderButton";

// style
import "../../layouts/forgot-password-layout/forgot-password.scss";

// Image
import close from '../../asstes/images/close.png';

// contanier

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    colors: {
        borderColor: "green",
    },
}));

const ForgotPasswordLayout = (props) => {
    let history = useHistory();

    const [femail, setFEmail] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [newpasswordErrorShow, setNewPasswordErrorShow] = useState(false);
    const [confirmPasswordErrorShow, setConfirmPasswordErrorShow] = useState(false);
    const [confirmpassword, setConfirmpassword] = useState("");
    const [FEmailErrorShow, setFEmailErrorShow] = useState(false);
    const [isVisbleError, setIsVisbleError] = React.useState(false);
    const classes = useStyles();
    const [forgotLoader, setForgotLoader] = useState(false);


    // Email
    const femailValidCalBack = (e) => {
        var validFEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(e);
        // console.log(!validFEmail);
        setFEmailErrorShow(!validFEmail)
        setFEmail(e)
    }

    //New Password
    const [values, setValues] = React.useState({
        newpassword: '',
        showNewPassword: false,
    });

    const newPasswordVaildCalBack = (e) => {
        var pas = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})").test(e);
        // console.log(!pas);
        setNewPasswordErrorShow(!pas)
        setNewPassword(e)
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showNewPassword: !values.showNewPassword });
        setIsVisbleError(!isVisbleError)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Confirm Password
    const [confirmvalues, setConfirmValues] = React.useState({
        confirmpassword: '',
        showConfirmPassword: false,
    });
    const cpasswordVaildCalBack = (e) => {

        setConfirmpassword(e)

        var confirmPwd = newpassword !== e;
        setConfirmPasswordErrorShow(confirmPwd)
        // console.log("confirmPwd", confirmPwd);
    }
    const handleClickShowConfirmPassword = () => {
        setConfirmValues({ ...confirmvalues, showConfirmPassword: !confirmvalues.showConfirmPassword });
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };
    const confirm = () => {
        setForgotLoader(true);
        var dataSet = {
            email: femail,
            newpassword: newpassword,
        };
        props.forgotpasswordUser(dataSet, createSuccessCallBack, createfailureCallBack);
    };
    const createSuccessCallBack = () => {
        setForgotLoader(true);
        props.closeSingUpModel();
        toast.success("Success 🤗");
        // setTimeout(() => {
        //     window.location.reload();
        // }, 300);
    };
    const createfailureCallBack = () => {
        console.log("fails");
        setForgotLoader(false);
        props.closeForgotUpModel();
        toast.error("Please fill all values 👨‍💻");
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="signup-section"
        >
            <Modal.Body >
                <div className="close-icon" onClick={props.onHide}>
                    <img src={close} alt="close" /></div>
                <div className="irri-forgot">
                    <div className="forgot-password">
                        <h2>Forgot Password</h2>
                        <div className="forgot-field">
                            <TextField
                                id="outlined-password-input"
                                label="Email"
                                type="text"
                                value={femail}
                                onChange={(e) => femailValidCalBack(e.target.value)}
                                autoComplete="off"
                                variant="outlined"
                                error={FEmailErrorShow}
                            />
                            <FormControl
                                className={clsx(classes.margin, classes.textField, classes.colors)}
                                variant="outlined"
                                autoComplete="off"
                                error={newpasswordErrorShow}
                            >
                                <InputLabel htmlFor="outlined-adornment-password">
                                    New Password
            </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={values.showNewPassword ? "text" : "password"}
                                    value={newpassword}
                                    onChange={(e) => newPasswordVaildCalBack(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={70}
                                    autoComplete="off"
                                />
                                {newpasswordErrorShow ? (<FormHelperText id="component-error-text">Use 8 or more characters with a mix of letters, numbers & symbols</FormHelperText>) : null}
                            </FormControl>

                            <FormControl
                                className={clsx(classes.margin, classes.textField, classes.colors)}
                                variant="outlined"
                                autoComplete="off"
                                error={confirmPasswordErrorShow}
                            >
                                <InputLabel htmlFor="outlined-adornment-password">
                                    Confirm Password
                    </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={confirmvalues.showConfirmPassword ? "text" : "password"}
                                    value={confirmpassword}
                                    onChange={(e) => cpasswordVaildCalBack(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownConfirmPassword}
                                                edge="end"
                                            >
                                                {confirmvalues.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={70}
                                    autoComplete="off"
                                />
                                {(() => {

                                })()}
                                {confirmPasswordErrorShow ? (<FormHelperText id="component-error-text">Please fill correct password</FormHelperText>) : null}
                            </FormControl>
                            <LoaderButton
                                btnName={"Confirm"}
                                btnCallBack={confirm}
                                btnDisable={forgotLoader}
                                btnLoader={forgotLoader}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};


// redux;
const mapStateToProps = (state) => {
    return {
        isFetchingForgotpassword: state.forgotpasswordUser.isFetchingForgotpassword,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            forgotpasswordUser: forgotpasswordUser,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordLayout);