import {
    VILLAGE_LIST_FAILURE,
    VILLAGE_LIST_SUCCESS,
    VILLAGE_LIST_REQUEST,
} from "../action/actionConstants";

const initialState = {
    isFetchingVillage: false,
    error: false,
    status: "",
    message: "intial",
};

const villageList = (state = initialState, action) => {
    switch (action.type) {
        case VILLAGE_LIST_REQUEST:
            return {
                ...state,
                isFetchingVillage: true,
                message: "Request",
            };
        case VILLAGE_LIST_FAILURE:
            return {
                ...state,
                isFetchingVillage: false,
                error: true,
                status: action.data.status,
                message: "Failure",
            };
        case VILLAGE_LIST_SUCCESS:
            return {
                ...state,
                isFetchingVillage: true,
                error: false,
                status: action.data.status,
                message: "Success",
            };
        default:
            return state;
    }
};

export default villageList;
