import React from "react";
import { Redirect, Route } from "react-router-dom";

import { userLoggedIn, whichUserLoggedIn } from "../common/common";

const OnBoardingRoute = ({ component: Component, ...rest }) => {
  const whetherUserLoggedIn = whichUserLoggedIn();

  if(whetherUserLoggedIn === 'admin') {
    return (
      <Route
        {...rest}
        render={props =>
          whetherUserLoggedIn ? (
            <Redirect
              to={{
                pathname: "/SRFCSD-Odisha/admin",
                state: { from: props.location }
              }}
            />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={props =>
          whetherUserLoggedIn ? (
            <Redirect
              to={{
                pathname: "/SRFCSD-Odisha/dashBoard",
                state: { from: props.location }
              }}
            />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  }

  
};

export default OnBoardingRoute;
