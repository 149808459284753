// constant
import { POST, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { ADMIN_CREATE_FAILURE, ADMIN_CREATE_SUCCESS, ADMIN_CREATE_REQUEST } from "./actionConstants";

// url
import { ADMIN_CREATE_USER_URL } from "../common/urls";

const createRequested = () => ({
    type: ADMIN_CREATE_REQUEST,
});

const createSuccess = (data) => ({
    type: ADMIN_CREATE_SUCCESS,
    data,
});

const createFailure = (data) => ({
    type: ADMIN_CREATE_FAILURE,
    data,
});

export const adminUser = (userData, successCallBack, failureCallBack) => (dispatch) => {
    dispatch(createRequested);

    const url = ADMIN_CREATE_USER_URL;

    const onSuccess = (response) => {
        successCallBack(response);
        dispatch(createSuccess(response));
    };

    const onFailure = (response) => {
        failureCallBack(response);
        dispatch(createFailure(response));
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(POST, url, userData, onSuccess, onFailure, dispatch, token);
        }
    });
};
