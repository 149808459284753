// react
import React from "react";

// plugin

// function

// components
import OtpLayout from "../../layouts/otp-layout/OtpLayout";

// contanier

const Otp = () => {
    return <OtpLayout />;
};

export default Otp;
