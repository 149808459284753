// react
import React from "react";

// plugin
import { useHistory } from "react-router-dom";

// function

// components
// import SideBar from "../../layouts-components/dash-board-components/side-bar/SideBar";
import CompareMap from "../../layouts-components/sesson-components/map-compare/MapCompare";
import ComponentLoader from "../../public-screen/component-loader/ComponetLoader";

// style
import "./sesson-layout.scss";
// contanier

const SessonLayout = (props) => {
  let history = useHistory();
  // const [leftMapWmsYear, setLeftMapWmsYear] = React.useState("");
  // const [rightMapWmsYear, setRightMapWmsYear] = React.useState("");
  // const [compareMapLoader, setCompareMapLoader] = React.useState(true);

  // console.log("props :::::::::::::::::::::::", props);

  // const changeLeftMapWms = (value) => {

  //   // console.log("changeLeftMapWms", value);

  //   setCompareMapLoader(false);
  //   setLeftMapWmsYear(value);
  //   setTimeout(() => {
  //     setCompareMapLoader(true);
  //   }, 500);
  // }

  // const changeRightMapWms = (value) => {
  //   console.log("changeRightMapWms", value);
  //   setCompareMapLoader(false);
  //   setRightMapWmsYear(value);
  //   setTimeout(() => {
  //     setCompareMapLoader(true);
  //   }, 500);
  // }

  // console.log("centerob asmsdhjdshj",);


  return (<div className='seasson-layout'>
    {(() => {
      if (!props.mapRender) {
        if (props.compareMapLoader) {
          return (
            <CompareMap
              mapRender={props.mapRender}
              wmsRegionLayer={props.displayList.wmsRegionLayer}
              wmsRegion={props.displayList.wmsRegion}
              bboxData={props.displayList.bboxData}
              polygonBoundry={props.displayList.polygonBoundry}
              layer={props.displayList.product.value}
              center={props.displayList.centroid}
              leftMapWmsYear={props.leftMapWmsYear}
              rightMapWmsYear={props.rightMapWmsYear}
              styleLayer={props.displayList.styleLayer}
            />
          )
        }
        // else {
        //   return (<div class="cloader" > <ComponentLoader /></div>)
        // }
      }
      else {
        return (<div class="cloader" > <ComponentLoader /></div>)
      }
      // else {
      //   return (<div class="cloader" > <ComponentLoader /></div>)
      // }
    })()}
  </div>);
};

export default SessonLayout;
