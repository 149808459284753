import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  useHistory,
  Route
} from "react-router-dom";
// redried
import PrivateRoute from "../privateRouter/PrivateRouter";
import OnBoardingRoute from "../onBordingRouter/OnBordingRouter";

// componets
import LogIn from "../containers/login/Login";
import Admin from "../containers/admin/Admin";
import Otp from "../containers/otp/otp";
import ForgotPassword from "../containers/forgot-password/forgot-password";
import DashBoard from "../containers/dash-board/DashBoard";
import Sesson from "../containers/sesson/Sesson";
import BarChartDashBoard from "../layouts-components/dash-board-components/filter-chat/BarChartDashBoard";
import CompareMap from "../layouts/compare-map/Compare";

// not Found
import NotFound from "../public-screen/page-not-found/NotFlound";

const Routes = () => {

  useEffect(() => {
    console.log(window.location.href)
  }, []);

  return (
    <Router history={useHistory}>
      <Switch>
        <OnBoardingRoute exact path="/" component={LogIn} />
        <PrivateRoute exact path="/SRFCSD-Odisha/admin" component={Admin} />
        <PrivateRoute exact path="/SRFCSD-Odisha/otp" component={Otp} />
        <PrivateRoute exact path="/SRFCSD-Odisha/forgotPassword" component={ForgotPassword} />
        <PrivateRoute exact path="/SRFCSD-Odisha/dashBoard" component={DashBoard} />
        <PrivateRoute exact path="/SRFCSD-Odisha/sesson" component={Sesson} />
        <PrivateRoute exact path="/SRFCSD-Odisha/barchart" component={BarChartDashBoard} />
        <PrivateRoute exact path="/SRFCSD-Odisha/compare" component={CompareMap} />
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;

