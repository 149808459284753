// constant
import {
  ACCESS_TOKEN,
  USER_ID,
  REFRESH_TOKEN,
  POST,
  ADMIN_USER,
} from "./constants";

// plugin
import jwt_decode from "jwt-decode";

// url
import { REFERSH_URL } from "../common/urls";

// function
import { refreshAccessToken } from "../common/connect";

export async function _getStorageValue(storageKey) {
  if (storageKey === "ACCESS_TOKEN") {
    var refresh = jwt_decode(getRefreshToken());
    if (refresh.exp <= Math.round(new Date().getTime() / 1000)) {
      removeAllCookies();
      window.location.reload();
    } else {
      var access = jwt_decode(getAccessToken());
      if (access.exp <= Math.round(new Date().getTime() / 1000)) {
        var url = REFERSH_URL;
        refreshAccessToken(POST, url, getRefreshToken()).then((respones) => {
          if (respones.status === false) {
            removeAllCookies();
            window.location.reload();
          } else {
            setAccessToken(respones.data.accessToken);
            setUserId(respones.data.userId);
            setRefreshToken(respones.data.refreshToken);
            return respones.data.accessToken;
          }
        });
      } else {
        return localStorage.getItem(storageKey);
      }
    }
  }
  return await localStorage.getItem(storageKey);
}

export const callback = () => {
  try {
    setInterval(async () => {
      // console.log(getAccessToken())
      if(getAccessToken() !== null) {
        var access = jwt_decode(getAccessToken());
        if (access.exp <= Math.round(new Date().getTime() / 1000)) {
          var url = REFERSH_URL;
          refreshAccessToken(POST, url, getRefreshToken()).then((respones) => {
            if (respones.status) {
              setAccessToken(respones.data.accessToken);
              setUserId(respones.data.userId);
              setRefreshToken(respones.data.refreshToken);
            } else {
              removeAllCookies();
              window.location.reload();
            }
          });
        }        
      } else {
        window.location.reload();
      }
    }, 1000);
  } catch (e) {
    console.log(e);
  }

};

export const setAccessToken = (accessToken) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setUserId = (userId) => {
  localStorage.setItem(USER_ID, userId);
};

export const getUserId = () => {
  return localStorage.getItem(USER_ID);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const setRefreshToken = (refreshToken) => {
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const setAdmin = (admin) => {
  localStorage.setItem(ADMIN_USER, admin);
};

export const getAdmin = () => {
  return localStorage.getItem(ADMIN_USER);
};

export const removeAllCookies = () => {
  window.localStorage.clear();
};

export const setSelectedValue = (selectKey, selectValue) => {
  localStorage.setItem(selectKey, selectValue);
};

export const removeSelectedItem = (removeKey) => {
  localStorage.removeItem(removeKey);
};

export const getValue = (valueKey) => {
  return localStorage.getItem(valueKey);
};
