import {
    PANCHAYAT_LIST_FAILURE,
    PANCHAYAT_LIST_SUCCESS,
    PANCHAYAT_LIST_REQUEST,
} from "../action/actionConstants";

const initialState = {
    isFetchingPanchayat: false,
    error: false,
    status: "",
    message: "intial",
};

const panchayatList = (state = initialState, action) => {
    switch (action.type) {
        case PANCHAYAT_LIST_REQUEST:
            return {
                ...state,
                isFetchingPanchayat: true,
                message: "Request",
            };
        case PANCHAYAT_LIST_FAILURE:
            return {
                ...state,
                isFetchingPanchayat: false,
                error: true,
                status: action.data.status,
                message: "Failure",
            };
        case PANCHAYAT_LIST_SUCCESS:
            return {
                ...state,
                isFetchingPanchayat: true,
                error: false,
                status: action.data.status,
                message: "Success",
            };
        default:
            return state;
    }
};

export default panchayatList;
