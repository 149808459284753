// constant
import { DELETE, REFRESH_TOKEN } from "../common/constants";

// api
import { apiCall } from "../common/connect";

// action Constant
import { LOGOUT_FAILURE, LOGOUT_SUCCESS, LOGOUT_REQUEST } from "./actionConstants";
import { removeAllCookies, _getStorageValue } from "../common/localStorage";

// url
import { LOGOUT_URL } from "../common/urls";

// plugin



const logoutRequested = () => ({
    type: LOGOUT_REQUEST,
});

const logoutSuccess = (data) => ({
    type: LOGOUT_SUCCESS,
    data,
});

const logoutFailure = (data) => ({
    type: LOGOUT_FAILURE,
    data,
});

export const logoutUser = (successLogOut) => (dispatch) => {
    
    dispatch(logoutRequested());

    var url = LOGOUT_URL;

    const onSuccess = (response) => {
        dispatch(logoutSuccess(response));
        successLogOut();
    };

    const onFailure = (response) => {
        if (response.status === false) {
            successLogOut();
            // history.push("/");
            // setTimeout(() =>{
            //     removeAllCookies();
            //     setTimeout(() => {
            //         window.location.reload();
            //     }, 100);
            // }, 200);
        }
        dispatch(logoutFailure(response));
    };
    _getStorageValue(REFRESH_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(DELETE, url, "", onSuccess, onFailure, dispatch, token);
        }
    })
};