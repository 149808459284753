// export const statGeo = [
//     [
//         [
//             [84.73021698, 19.08930588],
//             [84.75096893, 19.08208084],
//             [84.78347015, 19.11069489],
//             [84.76125336, 19.07125092],
//             [84.73021698, 19.08930588],
//         ],
//     ],
//     [
//         [
//             [86.80278015, 20.42083359],
//             [86.81486511, 20.4066906],
//             [86.80388641, 20.37138939],
//             [86.80278015, 20.42083359],
//         ],
//     ],
//     [
//         [
//             [87.01651001, 20.80282974],
//             [87.00250244, 20.83222198],
//             [87.01857758, 20.82287407],
//             [87.01651001, 20.80282974],
//         ],
//     ],
//     [
//         [
//             [86.88194275, 20.78861046],
//             [86.95153046, 20.7747097],
//             [86.86528015, 20.78249931],
//             [86.88194275, 20.78861046],
//         ],
//     ],
//     [
//         [
//             [86.32213593, 20.01102448],
//             [86.30110931, 20.02667046],
//             [86.31582642, 20.03111076],
//             [86.32213593, 20.01102448],
//         ],
//     ],
//     [
//         [
//             [81.75829315, 17.89336014],
//             [81.70317078, 17.86112976],
//             [81.68828583, 17.88479996],
//             [81.66306305, 17.87693024],
//             [81.61115265, 17.81536293],
//             [81.56874084, 17.82788086],
//             [81.48583984, 17.8026104],
//             [81.46684265, 17.82492065],
//             [81.41941833, 17.8205986],
//             [81.40925598, 17.80483818],
//             [81.38310242, 17.81414032],
//             [81.39805603, 17.84678078],
//             [81.39382935, 17.88513947],
//             [81.43725586, 17.87593842],
//             [81.44523621, 17.92617035],
//             [81.47731018, 17.97002029],
//             [81.47389221, 18.02851105],
//             [81.50865936, 18.09241104],
//             [81.5226059, 18.15727043],
//             [81.504776, 18.18381119],
//             [81.52796173, 18.25908089],
//             [81.56759644, 18.27321053],
//             [81.59340668, 18.30128098],
//             [81.65866852, 18.3110981],
//             [81.64916992, 18.33100128],
//             [81.65809631, 18.33929062],
//             [81.74491119, 18.34535027],
//             [81.76210022, 18.4113102],
//             [81.84430695, 18.48154068],
//             [81.85794067, 18.51305962],
//             [81.8968277, 18.52839279],
//             [81.90859985, 18.56422806],
//             [81.94690704, 18.5591507],
//             [81.95452881, 18.58670998],
//             [81.8883667, 18.62099075],
//             [81.88537598, 18.65032959],
//             [81.9474411, 18.62882042],
//             [81.96768188, 18.69061279],
//             [82.03373718, 18.71879959],
//             [82.07858276, 18.71208191],
//             [82.08141327, 18.75587082],
//             [82.12906647, 18.75765038],
//             [82.16114807, 18.79096031],
//             [82.15820313, 18.86945915],
//             [82.17272949, 18.89528847],
//             [82.23956299, 18.91045952],
//             [82.22506714, 19.01459122],
//             [82.1940918, 19.06022072],
//             [82.21211243, 19.09054947],
//             [82.1985321, 19.09416962],
//             [82.19975281, 19.11746025],
//             [82.17623901, 19.12506104],
//             [82.16982269, 19.11285973],
//             [82.15978241, 19.1264801],
//             [82.17478943, 19.14523125],
//             [82.16413879, 19.15463829],
//             [82.17878723, 19.15885925],
//             [82.15570831, 19.19652939],
//             [82.15172577, 19.26546288],
//             [82.1831665, 19.31970024],
//             [82.14083099, 19.3619194],
//             [82.16636658, 19.36593056],
//             [82.18305969, 19.41761017],
//             [82.1672287, 19.42984962],
//             [82.11930847, 19.4239006],
//             [82.09689331, 19.46108055],
//             [82.09207916, 19.50984001],
//             [82.02239227, 19.50180244],
//             [82.04612732, 19.5386219],
//             [82.03395844, 19.59069061],
//             [82.05134583, 19.62444115],
//             [82.03756714, 19.70391083],
//             [82.05982971, 19.78309822],
//             [81.98014832, 19.79578018],
//             [81.96037292, 19.85473824],
//             [81.84963226, 19.90805054],
//             [81.83730316, 19.95008087],
//             [81.86016846, 20.02336121],
//             [81.94420624, 20.10160255],
//             [81.98660278, 20.05207253],
//             [82.00995636, 20.0447998],
//             [82.02440643, 20.01004028],
//             [82.05789948, 20.04986954],
//             [82.1160965, 20.01040077],
//             [82.16583252, 19.99814987],
//             [82.1784668, 19.97808266],
//             [82.23062897, 19.99808121],
//             [82.26169586, 19.97155952],
//             [82.29823303, 19.883461],
//             [82.33944702, 19.82996178],
//             [82.38881683, 19.88175964],
//             [82.44239044, 19.90298271],
//             [82.49423981, 19.89735985],
//             [82.52305603, 19.87278938],
//             [82.5298996, 19.88212967],
//             [82.59349823, 19.8645401],
//             [82.57126617, 19.82238007],
//             [82.58506012, 19.7710495],
//             [82.62296295, 19.78931046],
//             [82.64573669, 19.82537079],
//             [82.70271301, 19.83103943],
//             [82.71750641, 19.87080956],
//             [82.70053101, 19.89328957],
//             [82.70748901, 19.99021912],
//             [82.63175964, 20.00008965],
//             [82.59836578, 19.98563957],
//             [82.39086151, 20.05958939],
//             [82.37892914, 20.14463043],
//             [82.41359711, 20.20227051],
//             [82.4050827, 20.26353073],
//             [82.42922974, 20.2829628],
//             [82.42243958, 20.30965996],
//             [82.39377594, 20.3355217],
//             [82.40989685, 20.40267944],
//             [82.4291687, 20.42575264],
//             [82.41007996, 20.47330093],
//             [82.38809204, 20.45251083],
//             [82.38049316, 20.50984955],
//             [82.34879303, 20.52293015],
//             [82.32401276, 20.55388069],
//             [82.34063721, 20.56973267],
//             [82.33867645, 20.5943203],
//             [82.36823273, 20.62405968],
//             [82.33512878, 20.75851822],
//             [82.34661865, 20.88384819],
//             [82.35917664, 20.8827095],
//             [82.35903931, 20.86654091],
//             [82.40141296, 20.86243057],
//             [82.41552734, 20.82646942],
//             [82.45905304, 20.82384109],
//             [82.48192596, 20.85531044],
//             [82.485672, 20.90351105],
//             [82.55294037, 20.94148064],
//             [82.62230682, 21.0365696],
//             [82.60938263, 21.07119751],
//             [82.64502716, 21.10187912],
//             [82.63594055, 21.1494503],
//             [82.75229645, 21.15974045],
//             [82.78884888, 21.13927269],
//             [82.81247711, 21.1585598],
//             [82.86882782, 21.15974998],
//             [82.87922668, 21.14908981],
//             [82.95606995, 21.17548943],
//             [82.96514893, 21.15575981],
//             [82.99297333, 21.15427971],
//             [83.04058075, 21.11893082],
//             [83.11984253, 21.10106087],
//             [83.19274902, 21.13944054],
//             [83.21835327, 21.26012039],
//             [83.2424469, 21.27643967],
//             [83.26767731, 21.26857758],
//             [83.27480316, 21.29057121],
//             [83.25421143, 21.31958961],
//             [83.27001953, 21.37503815],
//             [83.37481689, 21.3399601],
//             [83.40265656, 21.34843063],
//             [83.39376068, 21.39944267],
//             [83.35028839, 21.44398117],
//             [83.33463287, 21.49555016],
//             [83.36577606, 21.54968071],
//             [83.38072205, 21.61275101],
//             [83.44123077, 21.64927292],
//             [83.45275879, 21.60519028],
//             [83.48454285, 21.62556267],
//             [83.483078, 21.63960075],
//             [83.4197464, 21.67502022],
//             [83.42796326, 21.69232941],
//             [83.45633698, 21.69432068],
//             [83.48413086, 21.74155235],
//             [83.46717834, 21.78273964],
//             [83.49091339, 21.81454086],
//             [83.53656769, 21.79393005],
//             [83.53124237, 21.83189964],
//             [83.5735321, 21.82966042],
//             [83.58447266, 21.84360123],
//             [83.57151794, 21.87187004],
//             [83.60047913, 21.91084099],
//             [83.55335999, 21.9321804],
//             [83.55771637, 21.9439106],
//             [83.53517151, 21.96350098],
//             [83.5485611, 22.00670242],
//             [83.53399658, 22.02227974],
//             [83.53836823, 22.05537987],
//             [83.60031891, 22.0655098],
//             [83.55916595, 22.10386086],
//             [83.64575195, 22.22458839],
//             [83.69300079, 22.24550056],
//             [83.75279999, 22.24286079],
//             [83.79364777, 22.28769112],
//             [83.81944275, 22.29245186],
//             [83.82520294, 22.31962967],
//             [83.86100769, 22.34378052],
//             [83.89697266, 22.33968925],
//             [83.9155426, 22.35967064],
//             [84.00421143, 22.36766052],
//             [84.04000854, 22.43289948],
//             [84.04155731, 22.46410942],
//             [83.97609711, 22.50221062],
//             [83.98010254, 22.52201271],
//             [84.05758667, 22.51015091],
//             [84.10611725, 22.47384834],
//             [84.13492584, 22.47094917],
//             [84.13587952, 22.42068291],
//             [84.15248871, 22.4009304],
//             [84.19410706, 22.37181091],
//             [84.24655914, 22.3737011],
//             [84.28922272, 22.33732033],
//             [84.42608643, 22.3488102],
//             [84.44785309, 22.37907982],
//             [84.47759247, 22.38368034],
//             [84.47666931, 22.40598106],
//             [84.52662659, 22.42002106],
//             [84.63210297, 22.4286499],
//             [84.66160583, 22.41391945],
//             [84.74385071, 22.41475105],
//             [84.75269318, 22.44201279],
//             [84.80779266, 22.44686127],
//             [84.83254242, 22.42637825],
//             [84.87652588, 22.44325066],
//             [84.88024902, 22.41759109],
//             [84.89379883, 22.41801071],
//             [84.94728088, 22.45108032],
//             [84.95458221, 22.4389019],
//             [85.06249237, 22.47841072],
//             [85.07363892, 22.46668053],
//             [85.05703735, 22.44470978],
//             [85.08307648, 22.37844086],
//             [85.07405853, 22.34859085],
//             [85.09295654, 22.34109116],
//             [85.08795929, 22.31408119],
//             [85.11403656, 22.3142128],
//             [85.11445618, 22.2904892],
//             [85.07051086, 22.27215958],
//             [85.0694809, 22.23064995],
//             [85.04779053, 22.17429924],
//             [85.02539825, 22.15400124],
//             [85.02374268, 22.11199188],
//             [84.98049927, 22.09126091],
//             [84.98742676, 22.07673836],
//             [85.04389954, 22.11070061],
//             [85.0954361, 22.09945107],
//             [85.11888123, 22.0664711],
//             [85.1555481, 22.07045937],
//             [85.17080688, 22.04819107],
//             [85.17246246, 22.06818962],
//             [85.21074677, 22.04318047],
//             [85.21488953, 22.01071167],
//             [85.23094177, 22.00027084],
//             [85.30007935, 22.10705948],
//             [85.32301331, 22.12499046],
//             [85.36138153, 22.12144089],
//             [85.36319733, 22.15456963],
//             [85.41028595, 22.15608025],
//             [85.53954315, 22.08658981],
//             [85.59108734, 22.07444],
//             [85.64460754, 22.09028053],
//             [85.6815033, 22.04878044],
//             [85.74606323, 22.06987953],
//             [85.80204773, 22.11083031],
//             [85.83075714, 22.07697105],
//             [85.78794098, 22.0771904],
//             [85.77033234, 22.05128288],
//             [85.77559662, 22.02827072],
//             [85.7561264, 22.00839043],
//             [85.76074982, 21.98971939],
//             [85.81832886, 21.97001076],
//             [85.89115143, 21.97851944],
//             [85.90542603, 21.96663094],
//             [85.91223907, 22.0048008],
//             [85.94258118, 22.01913071],
//             [86.00047302, 22.10897064],
//             [85.99462891, 22.13178062],
//             [86.02671814, 22.18522263],
//             [85.9697876, 22.24340057],
//             [86.00849915, 22.2926712],
//             [86.04328918, 22.30619049],
//             [86.00842285, 22.30995941],
//             [86.02043152, 22.32939911],
//             [85.99288177, 22.33815956],
//             [86.02124786, 22.37504005],
//             [86.00874329, 22.42432022],
//             [85.99297333, 22.42214012],
//             [85.95350647, 22.45597267],
//             [85.96924591, 22.49978065],
//             [86.02202606, 22.52054977],
//             [86.01834106, 22.5436306],
//             [86.0384903, 22.5635891],
//             [86.06920624, 22.54306984],
//             [86.10733032, 22.48489761],
//             [86.21820831, 22.46821022],
//             [86.22038269, 22.44909096],
//             [86.2798233, 22.4455204],
//             [86.35273743, 22.34542084],
//             [86.43335724, 22.30154037],
//             [86.43839264, 22.32336807],
//             [86.49994659, 22.34095192],
//             [86.53237915, 22.29819107],
//             [86.57846069, 22.29781914],
//             [86.59092712, 22.27483177],
//             [86.64557648, 22.26136971],
//             [86.65114594, 22.23320007],
//             [86.72097015, 22.21684074],
//             [86.71544647, 22.14349174],
//             [86.79032135, 22.1539402],
//             [86.79709625, 22.12613106],
//             [86.83200836, 22.09510994],
//             [86.96705627, 22.07775116],
//             [87.02604675, 22.02874947],
//             [87.03431702, 21.98919106],
//             [86.99793243, 21.90750122],
//             [87.03024292, 21.86531067],
//             [87.09464264, 21.86034012],
//             [87.09353638, 21.90789986],
//             [87.15641785, 21.9289093],
//             [87.17085266, 21.97261047],
//             [87.19510651, 21.95356941],
//             [87.23484802, 21.95438004],
//             [87.25240326, 21.91780281],
//             [87.23480988, 21.89560127],
//             [87.27156067, 21.80340004],
//             [87.29281616, 21.80546951],
//             [87.33666992, 21.77918053],
//             [87.3520813, 21.78573036],
//             [87.37011719, 21.76007271],
//             [87.38330078, 21.77086067],
//             [87.44367218, 21.76088905],
//             [87.44293213, 21.72904968],
//             [87.46517944, 21.72636032],
//             [87.47083282, 21.70718002],
//             [87.45864868, 21.64493942],
//             [87.48208618, 21.62586975],
//             [87.48110962, 21.60777855],
//             [87.40444183, 21.55639076],
//             [87.38638306, 21.56656265],
//             [87.39138794, 21.55000114],
//             [87.37999725, 21.54555511],
//             [87.20089722, 21.54849052],
//             [87.1297226, 21.51292992],
//             [87.10694885, 21.51773453],
//             [87.1138916, 21.5083313],
//             [87.08944702, 21.48388863],
//             [87.07333374, 21.47105217],
//             [87.04416656, 21.47528076],
//             [87.06416321, 21.46388817],
//             [86.98110962, 21.41083336],
//             [86.91110992, 21.34056091],
//             [86.85943604, 21.2619381],
//             [86.82250214, 21.19388962],
//             [86.82332611, 21.13833046],
//             [86.97027588, 20.82332993],
//             [86.95749664, 20.78471947],
//             [86.8813858, 20.79750252],
//             [86.82776642, 20.77166939],
//             [86.81277466, 20.74833488],
//             [86.8161087, 20.7238884],
//             [86.75569916, 20.61334419],
//             [86.77361298, 20.64528084],
//             [86.82055664, 20.63958359],
//             [86.81408691, 20.66365814],
//             [86.79650879, 20.66557121],
//             [86.80223846, 20.67521095],
//             [86.82853699, 20.67226982],
//             [86.8446579, 20.65076256],
//             [86.93393707, 20.71071053],
//             [87, 20.69528008],
//             [87.0451889, 20.7094841],
//             [86.88723755, 20.60606194],
//             [86.88694763, 20.61778069],
//             [86.88500214, 20.6044445],
//             [86.78444672, 20.53582954],
//             [86.74472809, 20.46972084],
//             [86.73388672, 20.47916603],
//             [86.72943115, 20.40488052],
//             [86.77971649, 20.39167023],
//             [86.76416779, 20.36582947],
//             [86.78607941, 20.37691498],
//             [86.78083038, 20.34583473],
//             [86.68000031, 20.26333237],
//             [86.67111206, 20.28222275],
//             [86.67973328, 20.25805092],
//             [86.55361176, 20.2100029],
//             [86.50971985, 20.1772213],
//             [86.49749756, 20.19028091],
//             [86.46833038, 20.14722061],
//             [86.45999908, 20.16222191],
//             [86.46604156, 20.14599037],
//             [86.48500061, 20.17000008],
//             [86.49111176, 20.15777016],
//             [86.46221924, 20.11471939],
//             [86.47138977, 20.09749985],
//             [86.46389008, 20.11471939],
//             [86.48750305, 20.14055824],
//             [86.48916626, 20.12138939],
//             [86.50388336, 20.16444016],
//             [86.5588913, 20.20055962],
//             [86.43678284, 20.0609684],
//             [86.41176605, 20.05554008],
//             [86.42722321, 20.04972267],
//             [86.38889313, 19.97722816],
//             [86.3777771, 19.97888947],
//             [86.38223267, 19.99555969],
//             [86.37384796, 19.97999763],
//             [86.34221649, 19.98639107],
//             [86.32740021, 20.03067017],
//             [86.29046631, 20.06098175],
//             [86.28722382, 20.04972267],
//             [86.26915741, 20.06278038],
//             [86.24785614, 20.05318832],
//             [86.23665619, 20.02695084],
//             [86.29494476, 20.02441978],
//             [86.32194519, 19.98444366],
//             [86.30266571, 19.9835434],
//             [86.34102631, 19.97237968],
//             [86.31749725, 19.94916534],
//             [86.34243774, 19.9695034],
//             [86.36422729, 19.95372963],
//             [86.24739075, 19.91123009],
//             [86.24160004, 19.92205048],
//             [86.2277832, 19.90361023],
//             [86.22252655, 19.92562103],
//             [86.20804596, 19.90472031],
//             [86.22796631, 19.90088081],
//             [86.09514618, 19.85991096],
//             [85.91027832, 19.82055473],
//             [85.90499878, 19.83222198],
//             [85.9080658, 19.8178997],
//             [85.66361237, 19.73916817],
//             [85.34944153, 19.58666992],
//             [85.14025879, 19.44416809],
//             [85.06472015, 19.36444473],
//             [84.91874695, 19.26458549],
//             [84.90569305, 19.26958275],
//             [84.9126358, 19.25958061],
//             [84.77791595, 19.11013985],
//             [84.74569702, 19.11763954],
//             [84.72541809, 19.09653091],
//             [84.75041962, 19.13597107],
//             [84.73542023, 19.14375114],
//             [84.70318604, 19.12070084],
//             [84.71903229, 19.09584427],
//             [84.69381714, 19.11952019],
//             [84.70522308, 19.1490097],
//             [84.66501617, 19.15673065],
//             [84.66117096, 19.1218605],
//             [84.59184265, 19.12384033],
//             [84.61982727, 19.07650948],
//             [84.64871216, 19.08419991],
//             [84.66458893, 19.06629181],
//             [84.65453339, 19.05231094],
//             [84.61271667, 19.05483055],
//             [84.59011841, 19.01338005],
//             [84.57531738, 19.02719116],
//             [84.57811737, 19.06212807],
//             [84.56613922, 19.06503868],
//             [84.5535965, 19.04740906],
//             [84.48938751, 19.02095032],
//             [84.4716568, 18.98122025],
//             [84.41513062, 19.0138607],
//             [84.44064331, 18.96693039],
//             [84.41559601, 18.93803978],
//             [84.42938995, 18.91368294],
//             [84.40615082, 18.9061203],
//             [84.41208649, 18.89393997],
//             [84.39782715, 18.88611984],
//             [84.3653183, 18.89625931],
//             [84.38762665, 18.87766838],
//             [84.35144806, 18.88319969],
//             [84.37156677, 18.86721992],
//             [84.3624115, 18.85106087],
//             [84.33602142, 18.86671066],
//             [84.34410095, 18.81194115],
//             [84.31249237, 18.77919006],
//             [84.28392029, 18.79082108],
//             [84.25218964, 18.77248192],
//             [84.21671295, 18.78728104],
//             [84.08177948, 18.74464035],
//             [84.02706146, 18.80220032],
//             [83.93978882, 18.79660034],
//             [83.90197754, 18.81692123],
//             [83.88687897, 18.80540848],
//             [83.83709717, 18.86470985],
//             [83.840271, 18.90406036],
//             [83.81693268, 18.90940285],
//             [83.81476593, 18.95317841],
//             [83.78830719, 19.00781059],
//             [83.73956299, 18.97844124],
//             [83.78006744, 18.90772057],
//             [83.74582672, 18.91817284],
//             [83.7263031, 18.97556114],
//             [83.70574188, 18.98524094],
//             [83.72325134, 19.01189995],
//             [83.69702148, 19.01647949],
//             [83.62328339, 19.15363121],
//             [83.59429932, 19.12033081],
//             [83.60441589, 19.08818054],
//             [83.58444977, 19.07973099],
//             [83.57876587, 19.04978943],
//             [83.55766296, 19.05138016],
//             [83.55497742, 19.07813072],
//             [83.5464325, 19.07382965],
//             [83.5522995, 19.04718971],
//             [83.53138733, 19.00542068],
//             [83.49539948, 19.01109123],
//             [83.49355316, 19.02680016],
//             [83.51367188, 19.02478027],
//             [83.50538635, 19.06477165],
//             [83.49362946, 19.05308914],
//             [83.47323608, 19.0777626],
//             [83.45939636, 19.06967163],
//             [83.47796631, 19.02119064],
//             [83.47460175, 19.00031281],
//             [83.44851685, 18.99963188],
//             [83.46029663, 18.98077965],
//             [83.45548248, 18.94557953],
//             [83.40878296, 18.97989082],
//             [83.40885162, 18.99619102],
//             [83.36232758, 18.99473],
//             [83.34633636, 19.0098896],
//             [83.32917786, 18.98818016],
//             [83.30526733, 18.98968124],
//             [83.3324585, 18.94337082],
//             [83.3184967, 18.93362045],
//             [83.32901001, 18.91690826],
//             [83.34136963, 18.91839981],
//             [83.34575653, 18.95709991],
//             [83.38022614, 18.92328262],
//             [83.34661102, 18.92687035],
//             [83.36052704, 18.90117073],
//             [83.38391113, 18.89969063],
//             [83.37248993, 18.88983917],
//             [83.38539124, 18.87326241],
//             [83.41133881, 18.85934067],
//             [83.39630127, 18.83070946],
//             [83.37283325, 18.82121086],
//             [83.35887909, 18.83200073],
//             [83.35629272, 18.8001194],
//             [83.27950287, 18.78960037],
//             [83.26609802, 18.75700951],
//             [83.21939087, 18.76581955],
//             [83.20506287, 18.71586037],
//             [83.20170593, 18.74222946],
//             [83.13295746, 18.77244949],
//             [83.05097198, 18.65415955],
//             [83.0096817, 18.63652992],
//             [83.02126312, 18.61045074],
//             [83.00804138, 18.58842087],
//             [83.02770233, 18.58227158],
//             [83.04110718, 18.59673119],
//             [83.05394745, 18.58646965],
//             [83.0268631, 18.56796074],
//             [83.03247833, 18.54788971],
//             [83.05117798, 18.5349102],
//             [83.08933258, 18.53791046],
//             [83.01541138, 18.4444294],
//             [83.037323, 18.43482971],
//             [83.03743744, 18.41894913],
//             [83.05982971, 18.42863274],
//             [83.0664978, 18.3922596],
//             [83.0533905, 18.37529945],
//             [83.03697968, 18.36984253],
//             [83.0171814, 18.3845005],
//             [82.97644806, 18.3549118],
//             [82.90274811, 18.35591125],
//             [82.88490295, 18.37862015],
//             [82.88461304, 18.41275978],
//             [82.84490967, 18.40977287],
//             [82.81835938, 18.43852997],
//             [82.79917145, 18.43940163],
//             [82.77622223, 18.41969109],
//             [82.79329681, 18.39496994],
//             [82.7670517, 18.33107948],
//             [82.65883636, 18.28660965],
//             [82.64530945, 18.24044991],
//             [82.62687683, 18.22874069],
//             [82.58074188, 18.27009964],
//             [82.59095001, 18.29195976],
//             [82.5764389, 18.30681038],
//             [82.60214233, 18.31726074],
//             [82.59651184, 18.3408699],
//             [82.61128235, 18.34646034],
//             [82.60050964, 18.36870956],
//             [82.53515625, 18.3895607],
//             [82.55377197, 18.43765068],
//             [82.53015137, 18.43431091],
//             [82.51613617, 18.45588112],
//             [82.5275116, 18.46821976],
//             [82.52313232, 18.50392914],
//             [82.49915314, 18.50175095],
//             [82.47400665, 18.53683281],
//             [82.36128998, 18.4099102],
//             [82.38442993, 18.36911011],
//             [82.33444214, 18.31713295],
//             [82.34874725, 18.30615044],
//             [82.3843689, 18.31870079],
//             [82.393013, 18.30644035],
//             [82.37187958, 18.27410126],
//             [82.3368988, 18.26624107],
//             [82.32131958, 18.22245979],
//             [82.33281708, 18.21578979],
//             [82.30609894, 18.19565964],
//             [82.31030273, 18.17460251],
//             [82.34873199, 18.16183281],
//             [82.36515808, 18.13966942],
//             [82.35756683, 18.12510109],
//             [82.33336639, 18.14237976],
//             [82.34362793, 18.07499123],
//             [82.32977295, 18.040411],
//             [82.32182312, 18.05018044],
//             [82.30552673, 18.03780937],
//             [82.29789734, 18.06196022],
//             [82.26695251, 18.04827118],
//             [82.26725006, 17.98644066],
//             [82.4170685, 17.97976112],
//             [82.23699188, 17.99201012],
//             [82.17740631, 18.01057053],
//             [82.16034698, 18.04359818],
//             [82.13237, 18.03561974],
//             [82.12708282, 18.0510006],
//             [82.07221222, 18.06563759],
//             [82.0597229, 18.04511833],
//             [82.0246582, 18.0578804],
//             [82.00157928, 18.04481125],
//             [82.0016861, 18.02366066],
//             [81.95176697, 17.99209976],
//             [81.80104065, 17.93608093],
//             [81.75829315, 17.89336014],
//         ],
//     ],
//     [
//         [
//             [86.28814697, 20.02861023],
//             [86.24475861, 20.03425217],
//             [86.26999664, 20.05833244],
//             [86.30805206, 20.03472137],
//             [86.28814697, 20.02861023],
//         ],
//     ],
//     [
//         [
//             [86.80426788, 20.67662048],
//             [86.79541016, 20.66562271],
//             [86.81335449, 20.66238976],
//             [86.81912231, 20.63924217],
//             [86.77379608, 20.6463871],
//             [86.83000183, 20.76000023],
//             [86.85101318, 20.77482986],
//             [86.99054718, 20.77166939],
//             [87.00694275, 20.74305916],
//             [86.99658966, 20.71027946],
//             [86.93187714, 20.7157402],
//             [86.85917664, 20.66472054],
//             [86.85694122, 20.68416977],
//             [86.88527679, 20.70722008],
//             [86.87832642, 20.73306084],
//             [86.88388824, 20.70722008],
//             [86.85639191, 20.68610954],
//             [86.85972595, 20.66278076],
//             [86.84487152, 20.65199852],
//             [86.82847595, 20.67359543],
//             [86.80426788, 20.67662048],
//         ],
//     ],
// ];

export const statGeo = [
  [
    [
      [84.73021698, 19.08930588],
      [84.73236084, 19.08930588],
      [84.73236084, 19.08958244],
      [84.73291779, 19.08958054],
      [84.73291779, 19.08986282],
      [84.7334671, 19.08986282],
      [84.7334671, 19.09013939],
      [84.73374939, 19.09013939],
      [84.73374939, 19.09041977],
      [84.73402405, 19.09041405],
      [84.73402405, 19.09069252],
      [84.73432159, 19.09069061],
      [84.73432159, 19.09152985],
      [84.73596954, 19.09152985],
      [84.73596954, 19.09181023],
      [84.73764038, 19.09180641],
      [84.73764038, 19.09208488],
      [84.73930359, 19.09208488],
      [84.73930359, 19.09152794],
      [84.73958588, 19.09152985],
      [84.73958588, 19.09069061],
      [84.73986053, 19.09069252],
      [84.73986053, 19.09041405],
      [84.74014282, 19.09041977],
      [84.74014282, 19.08986282],
      [84.74097443, 19.08986282],
      [84.7409668, 19.08930969],
      [84.74124908, 19.08930969],
      [84.74124908, 19.08847046],
      [84.74180603, 19.08847046],
      [84.74180603, 19.08819389],
      [84.74208069, 19.08819389],
      [84.74208069, 19.08791733],
      [84.74263763, 19.08792114],
      [84.74263763, 19.08764267],
      [84.74375153, 19.08764267],
      [84.74375153, 19.08792114],
      [84.74402618, 19.08791733],
      [84.74402618, 19.08819389],
      [84.74430847, 19.08819389],
      [84.74430847, 19.08847046],
      [84.74485779, 19.08847046],
      [84.74485779, 19.08875275],
      [84.74514008, 19.08875275],
      [84.74514008, 19.08903122],
      [84.7454071, 19.08903122],
      [84.7454071, 19.08930969],
      [84.74569702, 19.08930969],
      [84.74569702, 19.09041977],
      [84.74597168, 19.09041405],
      [84.74597168, 19.09069252],
      [84.74736023, 19.09069252],
      [84.74736023, 19.09041405],
      [84.74819183, 19.09041977],
      [84.74819183, 19.09013939],
      [84.74903107, 19.09013939],
      [84.74903107, 19.08986282],
      [84.7492981, 19.08986282],
      [84.7492981, 19.08958054],
      [84.74958038, 19.08958244],
      [84.74958038, 19.08847046],
      [84.74930573, 19.08847046],
      [84.74930573, 19.08763885],
      [84.75013733, 19.08764267],
      [84.75013733, 19.08736038],
      [84.75125122, 19.08736038],
      [84.75125122, 19.08764267],
      [84.75152588, 19.08764267],
      [84.75152588, 19.08792114],
      [84.75208282, 19.08792114],
      [84.75208282, 19.08708191],
      [84.75152588, 19.08708382],
      [84.75152588, 19.08680534],
      [84.75096893, 19.08681107],
      [84.75096893, 19.08597183],
      [84.75069427, 19.08597183],
      [84.75069427, 19.08458328],
      [84.75041962, 19.08458328],
      [84.75041962, 19.08263969],
      [84.75068665, 19.08263969],
      [84.75068665, 19.08208084],
      [84.75096893, 19.08208084],
      [84.75096893, 19.08236122],
      [84.75152588, 19.08236122],
      [84.75152588, 19.08291817],
      [84.75180817, 19.08291817],
      [84.75180817, 19.08402824],
      [84.75208282, 19.08402824],
      [84.75208282, 19.08430481],
      [84.75235748, 19.08430481],
      [84.75235748, 19.08458328],
      [84.75263977, 19.08458328],
      [84.75263977, 19.08486176],
      [84.7529068, 19.08485985],
      [84.7529068, 19.08513832],
      [84.75319672, 19.08513832],
      [84.75319672, 19.08542061],
      [84.75514221, 19.08542061],
      [84.75514221, 19.0857029],
      [84.75541687, 19.08569527],
      [84.75541687, 19.08597183],
      [84.75597382, 19.08597183],
      [84.75597382, 19.08625031],
      [84.75708008, 19.08624077],
      [84.75708008, 19.08653259],
      [84.75736237, 19.08653259],
      [84.75736237, 19.08681107],
      [84.75763702, 19.08680534],
      [84.75763702, 19.08708382],
      [84.75791931, 19.08708191],
      [84.75791931, 19.08736038],
      [84.75819397, 19.08736038],
      [84.7582016, 19.08875275],
      [84.75791931, 19.08875275],
      [84.75791931, 19.08958244],
      [84.7582016, 19.08958054],
      [84.7582016, 19.09152985],
      [84.75791931, 19.09152985],
      [84.75791931, 19.09181023],
      [84.7582016, 19.09181023],
      [84.7582016, 19.09237099],
      [84.75846863, 19.09236145],
      [84.75846863, 19.09486198],
      [84.75819397, 19.09486198],
      [84.75819397, 19.09569359],
      [84.75791931, 19.09568977],
      [84.75791931, 19.09625053],
      [84.75763702, 19.09625053],
      [84.75763702, 19.09736061],
      [84.75736237, 19.09736061],
      [84.75736237, 19.0984726],
      [84.75763702, 19.09847069],
      [84.75763702, 19.09874916],
      [84.75791931, 19.09874916],
      [84.75791931, 19.09903145],
      [84.7582016, 19.09903145],
      [84.7582016, 19.09931183],
      [84.75875092, 19.09930611],
      [84.75875092, 19.09958267],
      [84.75902557, 19.09958267],
      [84.75902557, 19.10013962],
      [84.75930786, 19.10013962],
      [84.75930786, 19.10069466],
      [84.75958252, 19.10069466],
      [84.75958252, 19.10041618],
      [84.76040649, 19.10042191],
      [84.76040649, 19.10013962],
      [84.76180267, 19.10013962],
      [84.76180267, 19.10042191],
      [84.76486206, 19.10042191],
      [84.76486206, 19.09985924],
      [84.76513672, 19.09985924],
      [84.76513672, 19.09958076],
      [84.76568604, 19.09958076],
      [84.76568604, 19.10042191],
      [84.76625061, 19.10041618],
      [84.76625061, 19.10069466],
      [84.76680756, 19.10069466],
      [84.76680756, 19.10097122],
      [84.76708221, 19.10097122],
      [84.76708221, 19.10124969],
      [84.76763916, 19.10124969],
      [84.76763916, 19.10153961],
      [84.76819611, 19.10152817],
      [84.76819611, 19.1020813],
      [84.76847076, 19.1020813],
      [84.76847076, 19.10236359],
      [84.76902771, 19.10235977],
      [84.76902771, 19.10264015],
      [84.76930237, 19.10264015],
      [84.76930237, 19.10292053],
      [84.76957703, 19.10292053],
      [84.76957703, 19.10320091],
      [84.76985931, 19.10319328],
      [84.76985931, 19.10347366],
      [84.7701416, 19.10346985],
      [84.7701416, 19.10375023],
      [84.77043152, 19.10375023],
      [84.77043152, 19.10403061],
      [84.77069092, 19.10403061],
      [84.77069092, 19.10431099],
      [84.7720871, 19.10430336],
      [84.7720871, 19.10458565],
      [84.77236176, 19.10457993],
      [84.77236176, 19.10486031],
      [84.7729187, 19.10486031],
      [84.7729187, 19.10514069],
      [84.77346802, 19.10514069],
      [84.77346802, 19.10542107],
      [84.77402496, 19.10541725],
      [84.77402496, 19.10569572],
      [84.77458191, 19.10569572],
      [84.77458191, 19.10597229],
      [84.77513885, 19.10597038],
      [84.77513885, 19.10625076],
      [84.7756958, 19.10625076],
      [84.7756958, 19.10653114],
      [84.77623749, 19.10653114],
      [84.77623749, 19.10680962],
      [84.7765274, 19.10680771],
      [84.7765274, 19.10708237],
      [84.77680206, 19.10708427],
      [84.77680206, 19.10736084],
      [84.77707672, 19.1073494],
      [84.77707672, 19.10764122],
      [84.77735901, 19.10764122],
      [84.77735901, 19.10791969],
      [84.77791595, 19.10791779],
      [84.77791595, 19.10819435],
      [84.7784729, 19.10819435],
      [84.7784729, 19.10847282],
      [84.77902985, 19.10847092],
      [84.77902985, 19.10874939],
      [84.77931213, 19.10874939],
      [84.77931213, 19.10902977],
      [84.78013611, 19.10902977],
      [84.78013611, 19.10930824],
      [84.7804184, 19.10930634],
      [84.7804184, 19.1095829],
      [84.78069305, 19.1095829],
      [84.78069305, 19.10986137],
      [84.78098297, 19.10985947],
      [84.78098297, 19.11013985],
      [84.78125, 19.11013985],
      [84.78125, 19.11041641],
      [84.78180695, 19.11041641],
      [84.78180695, 19.11069489],
      [84.78236389, 19.11069489],
      [84.78236389, 19.11097145],
      [84.7831955, 19.11097145],
      [84.7831955, 19.11069489],
      [84.78347015, 19.11069489],
      [84.78347015, 19.11013985],
      [84.78320313, 19.11013985],
      [84.78320313, 19.10985947],
      [84.78263855, 19.10986137],
      [84.78263855, 19.10847282],
      [84.78236389, 19.10847282],
      [84.78236389, 19.10791779],
      [84.7820816, 19.10791969],
      [84.7820816, 19.10764122],
      [84.78180695, 19.10764122],
      [84.78180695, 19.10542107],
      [84.78151703, 19.10542107],
      [84.78151703, 19.10457993],
      [84.78125, 19.10458565],
      [84.78125, 19.1040287],
      [84.78098297, 19.10403061],
      [84.78098297, 19.10320091],
      [84.78069305, 19.10320091],
      [84.78069305, 19.10292053],
      [84.7804184, 19.10292053],
      [84.7804184, 19.10264015],
      [84.78013611, 19.10264015],
      [84.78013611, 19.10235977],
      [84.77986145, 19.10236359],
      [84.77986145, 19.1020813],
      [84.77958679, 19.1020813],
      [84.77958679, 19.10180473],
      [84.7793045, 19.10180473],
      [84.7793045, 19.10152817],
      [84.77902985, 19.10153961],
      [84.77902985, 19.10097122],
      [84.77874756, 19.10097122],
      [84.77874756, 19.10041618],
      [84.7784729, 19.10042191],
      [84.7784729, 19.10013962],
      [84.77819824, 19.10013962],
      [84.77819061, 19.09985924],
      [84.77791595, 19.09985924],
      [84.77791595, 19.09958076],
      [84.7776413, 19.09958267],
      [84.7776413, 19.09930611],
      [84.77735901, 19.09931183],
      [84.77735901, 19.09792137],
      [84.77707672, 19.09792137],
      [84.77707672, 19.09763908],
      [84.77680206, 19.09763908],
      [84.77680206, 19.09708023],
      [84.7765274, 19.09708405],
      [84.7765274, 19.0965271],
      [84.7756958, 19.09653091],
      [84.7756958, 19.09597015],
      [84.77541351, 19.09597206],
      [84.77541351, 19.09569359],
      [84.77513885, 19.09569359],
      [84.77513885, 19.09541893],
      [84.7748642, 19.09541893],
      [84.7748642, 19.09430695],
      [84.77458191, 19.09431076],
      [84.77458191, 19.09375],
      [84.77430725, 19.09375],
      [84.77430725, 19.0929203],
      [84.77403259, 19.0929203],
      [84.77403259, 19.09208107],
      [84.77375031, 19.09208488],
      [84.77375031, 19.09152794],
      [84.77346802, 19.09152985],
      [84.77346802, 19.09124947],
      [84.7731781, 19.09124947],
      [84.7731781, 19.09069061],
      [84.7729187, 19.09069252],
      [84.7729187, 19.09041405],
      [84.77263641, 19.09041977],
      [84.77263641, 19.09013939],
      [84.7720871, 19.09013939],
      [84.7720871, 19.08958054],
      [84.77180481, 19.08958244],
      [84.77180481, 19.08875275],
      [84.77153015, 19.08875275],
      [84.77153015, 19.08792114],
      [84.77124786, 19.08792114],
      [84.77124786, 19.08736038],
      [84.77097321, 19.08736038],
      [84.77097321, 19.08653259],
      [84.77069092, 19.08653259],
      [84.77069092, 19.08624077],
      [84.77041626, 19.08625031],
      [84.77041626, 19.08569527],
      [84.7701416, 19.0857029],
      [84.7701416, 19.08513832],
      [84.76985931, 19.08513832],
      [84.76985931, 19.08457947],
      [84.76958466, 19.08458328],
      [84.76958466, 19.08402824],
      [84.76930237, 19.08402824],
      [84.76930237, 19.08374977],
      [84.76902771, 19.08374977],
      [84.76902771, 19.08317947],
      [84.76875305, 19.08319473],
      [84.76875305, 19.08263969],
      [84.76847076, 19.08263969],
      [84.76847076, 19.08208084],
      [84.76819611, 19.08208275],
      [84.76819611, 19.08152771],
      [84.76790619, 19.08152962],
      [84.76790619, 19.08125114],
      [84.76763916, 19.08125114],
      [84.76763916, 19.08097076],
      [84.7673645, 19.08097267],
      [84.7673645, 19.08041763],
      [84.76708221, 19.08042908],
      [84.76708221, 19.07986069],
      [84.76680756, 19.07986069],
      [84.76680756, 19.07958031],
      [84.76652527, 19.07958412],
      [84.76652527, 19.07902718],
      [84.76625061, 19.07903099],
      [84.76625061, 19.07847023],
      [84.76597595, 19.07847214],
      [84.76597595, 19.07819557],
      [84.76569366, 19.07819557],
      [84.76569366, 19.0779171],
      [84.76541901, 19.07792091],
      [84.76541901, 19.07736969],
      [84.76513672, 19.07736969],
      [84.76513672, 19.07707977],
      [84.76486206, 19.07708549],
      [84.76486206, 19.07569313],
      [84.76457977, 19.07570076],
      [84.76457977, 19.07485962],
      [84.76430511, 19.07486343],
      [84.76430511, 19.07458115],
      [84.76347351, 19.07458115],
      [84.76347351, 19.07430649],
      [84.76319122, 19.07430649],
      [84.76319122, 19.07374954],
      [84.76291656, 19.07374954],
      [84.76291656, 19.07346916],
      [84.76264191, 19.07347107],
      [84.76264191, 19.0731945],
      [84.76235962, 19.0731945],
      [84.76235962, 19.07263947],
      [84.76208496, 19.07263947],
      [84.76208496, 19.07235909],
      [84.76180267, 19.07235909],
      [84.76180267, 19.07208061],
      [84.76152802, 19.07208252],
      [84.76152802, 19.07152748],
      [84.7612381, 19.07152939],
      [84.76125336, 19.07125092],
      [84.76114655, 19.07127762],
      [84.76101685, 19.07131195],
      [84.73021698, 19.08930588],
    ],
  ],
  [
    [
      [84.76985931, 19.10597229],
      [84.76985931, 19.10486031],
      [84.76957703, 19.10486031],
      [84.76957703, 19.10431099],
      [84.76930237, 19.10431099],
      [84.76930237, 19.10403061],
      [84.76902771, 19.10403061],
      [84.76902771, 19.10375023],
      [84.76875305, 19.10375023],
      [84.76875305, 19.10346985],
      [84.76847076, 19.10347366],
      [84.76847076, 19.10319328],
      [84.76790619, 19.10320091],
      [84.76790619, 19.10264015],
      [84.76763916, 19.10264015],
      [84.76763916, 19.10207939],
      [84.7673645, 19.1020813],
      [84.7673645, 19.10180473],
      [84.76680756, 19.10180473],
      [84.76680756, 19.10152817],
      [84.7665329, 19.10153961],
      [84.7665329, 19.10124969],
      [84.76597595, 19.10124969],
      [84.76597595, 19.10097122],
      [84.76568604, 19.10097122],
      [84.76568604, 19.10124969],
      [84.76513672, 19.10124969],
      [84.76513672, 19.10153961],
      [84.76486206, 19.10152817],
      [84.76486206, 19.10180473],
      [84.76513672, 19.10180473],
      [84.76513672, 19.1020813],
      [84.76541901, 19.1020813],
      [84.76541901, 19.10236359],
      [84.76568604, 19.10235977],
      [84.76568604, 19.10292053],
      [84.76597595, 19.10292053],
      [84.76597595, 19.10320091],
      [84.76625061, 19.10320091],
      [84.76625061, 19.10375023],
      [84.76652527, 19.10375023],
      [84.76652527, 19.10458565],
      [84.76708221, 19.10457993],
      [84.76708221, 19.10486031],
      [84.76737213, 19.10486031],
      [84.76737213, 19.10514069],
      [84.76791382, 19.10513878],
      [84.76791382, 19.10569572],
      [84.76875305, 19.10569572],
      [84.76875305, 19.10597229],
      [84.76985931, 19.10597229],
    ],
  ],
  [
    [
      [84.75653076, 19.11097145],
      [84.75653076, 19.11041641],
      [84.75680542, 19.11041641],
      [84.75680542, 19.10986137],
      [84.75708008, 19.10986137],
      [84.75708008, 19.1095829],
      [84.75736237, 19.1095829],
      [84.75736237, 19.10902786],
      [84.75708008, 19.10902977],
      [84.75708008, 19.10847092],
      [84.75791931, 19.10847092],
      [84.75791931, 19.10930824],
      [84.7582016, 19.10930824],
      [84.7582016, 19.11013985],
      [84.75875092, 19.11013985],
      [84.75875092, 19.10958099],
      [84.76069641, 19.1095829],
      [84.76069641, 19.10902786],
      [84.75985718, 19.10902977],
      [84.75985718, 19.10874939],
      [84.75958252, 19.10874939],
      [84.75958252, 19.10819054],
      [84.75930786, 19.10819435],
      [84.75930786, 19.10763931],
      [84.7590332, 19.10764122],
      [84.7590332, 19.1073494],
      [84.75763702, 19.10736084],
      [84.75763702, 19.10680771],
      [84.75791931, 19.10680962],
      [84.75791931, 19.10569],
      [84.75819397, 19.10569572],
      [84.75819397, 19.10513878],
      [84.75846863, 19.10514069],
      [84.75846863, 19.10486031],
      [84.75985718, 19.10486031],
      [84.75985718, 19.10514069],
      [84.76069641, 19.10514069],
      [84.76069641, 19.10542107],
      [84.76125336, 19.10541725],
      [84.76125336, 19.10569572],
      [84.76235962, 19.10569572],
      [84.76235962, 19.10597229],
      [84.76264191, 19.10597229],
      [84.76264191, 19.10736084],
      [84.76291656, 19.10736084],
      [84.76291656, 19.10708427],
      [84.76430511, 19.10708427],
      [84.76430511, 19.10736084],
      [84.76541901, 19.10736084],
      [84.76541901, 19.10708427],
      [84.76569366, 19.10708427],
      [84.76569366, 19.10486031],
      [84.76541901, 19.10486031],
      [84.76541901, 19.10403061],
      [84.76513672, 19.10403061],
      [84.76513672, 19.10375023],
      [84.76457977, 19.10375023],
      [84.76457977, 19.10320091],
      [84.76429749, 19.10320091],
      [84.76429749, 19.10292053],
      [84.76403046, 19.10292053],
      [84.76403046, 19.10264015],
      [84.76374817, 19.10264015],
      [84.76374817, 19.10235977],
      [84.76291656, 19.10236359],
      [84.76291656, 19.1020813],
      [84.76208496, 19.1020813],
      [84.76208496, 19.10236359],
      [84.76040649, 19.10235977],
      [84.76040649, 19.10264015],
      [84.75930786, 19.10264015],
      [84.75930786, 19.10235977],
      [84.75875092, 19.10236359],
      [84.75875092, 19.1020813],
      [84.75846863, 19.1020813],
      [84.75846863, 19.10180473],
      [84.75819397, 19.10180473],
      [84.75819397, 19.10152817],
      [84.75680542, 19.10152817],
      [84.75680542, 19.10180473],
      [84.75653076, 19.10180283],
      [84.75653076, 19.10431099],
      [84.75708008, 19.10430336],
      [84.75708008, 19.10458565],
      [84.75736237, 19.10457993],
      [84.75736237, 19.10542107],
      [84.75708008, 19.10541725],
      [84.75708008, 19.10569572],
      [84.75680542, 19.10569572],
      [84.75680542, 19.10597229],
      [84.75653076, 19.10597038],
      [84.75653076, 19.10625076],
      [84.75624847, 19.10625076],
      [84.75624847, 19.10680962],
      [84.75597382, 19.10680771],
      [84.75597382, 19.10708427],
      [84.75624847, 19.10708427],
      [84.75624847, 19.10736084],
      [84.75596619, 19.1073494],
      [84.75596619, 19.10764122],
      [84.75624847, 19.10764122],
      [84.75624847, 19.10874939],
      [84.75596619, 19.10874939],
      [84.75596619, 19.10902977],
      [84.75569153, 19.10902977],
      [84.75569153, 19.10930824],
      [84.75541687, 19.10930634],
      [84.75541687, 19.10986137],
      [84.75514221, 19.10986137],
      [84.75514221, 19.11069489],
      [84.75541687, 19.11069489],
      [84.75541687, 19.11097145],
      [84.75653076, 19.11097145],
    ],
  ],
  [
    [
      [86.38694763, 19.96277618],
      [86.38694763, 19.96223068],
      [86.38582611, 19.96111107],
      [86.38555908, 19.96111107],
      [86.38471985, 19.96027946],
      [86.38445282, 19.96027946],
      [86.3842392, 19.96007347],
      [86.3841629, 19.95999908],
      [86.38388824, 19.95972061],
      [86.38361359, 19.95972252],
      [86.3833313, 19.95944214],
      [86.38277435, 19.95944214],
      [86.38249969, 19.95916748],
      [86.38166809, 19.95916748],
      [86.38137817, 19.95893669],
      [86.38083649, 19.95889091],
      [86.38054657, 19.95861053],
      [86.37999725, 19.95861053],
      [86.3797226, 19.95889091],
      [86.37944794, 19.95888901],
      [86.37889099, 19.95944214],
      [86.37916565, 19.95972252],
      [86.3797226, 19.95972061],
      [86.37999725, 19.96000099],
      [86.38054657, 19.96000099],
      [86.38111115, 19.96055984],
      [86.3813858, 19.96055412],
      [86.38166809, 19.9608326],
      [86.38194275, 19.96083069],
      [86.38249969, 19.96138954],
      [86.38276672, 19.96138954],
      [86.38305664, 19.96166992],
      [86.3833313, 19.9616642],
      [86.38361359, 19.96194458],
      [86.38388824, 19.96194458],
      [86.38444519, 19.96249962],
      [86.38500214, 19.96249962],
      [86.38527679, 19.96278],
      [86.38555908, 19.96277618],
      [86.3859024, 19.96312523],
      [86.3861084, 19.96333313],
      [86.38639069, 19.96333313],
      [86.38694763, 19.96277618],
    ],
  ],
  [
    [
      [86.39248657, 19.96610832],
      [86.39221954, 19.96582985],
      [86.39194489, 19.96583366],
      [86.39104462, 19.96551132],
      [86.39047241, 19.96550941],
      [86.39047241, 19.96618843],
      [86.39061737, 19.96645927],
      [86.39194489, 19.96722221],
      [86.39221954, 19.96722221],
      [86.39264679, 19.96765137],
      [86.39407349, 19.9688015],
      [86.39570618, 19.97007942],
      [86.39620972, 19.97063065],
      [86.39723969, 19.97145271],
      [86.39837646, 19.97225952],
      [86.39877319, 19.97238731],
      [86.39905548, 19.97252464],
      [86.3992691, 19.97238731],
      [86.39935303, 19.97232056],
      [86.39913177, 19.97150993],
      [86.39836121, 19.97074127],
      [86.39778137, 19.97028351],
      [86.39649963, 19.96900368],
      [86.39499664, 19.96805573],
      [86.39332581, 19.96639061],
      [86.39305878, 19.96639061],
      [86.39277649, 19.96610832],
      [86.39248657, 19.96610832],
    ],
  ],
  [
    [
      [86.37249756, 19.95444489],
      [86.37166595, 19.95361137],
      [86.37150574, 19.95375824],
      [86.37093353, 19.95389366],
      [86.37043762, 19.95389366],
      [86.37020111, 19.95380402],
      [86.37007904, 19.95375824],
      [86.36962128, 19.95331955],
      [86.36908722, 19.9529705],
      [86.36824036, 19.95235825],
      [86.36782074, 19.95215988],
      [86.36660004, 19.95222282],
      [86.36620331, 19.95216179],
      [86.36572266, 19.9519043],
      [86.36447906, 19.95166016],
      [86.36326599, 19.95158958],
      [86.3629303, 19.95152473],
      [86.3611145, 19.95111084],
      [86.36083221, 19.95083046],
      [86.36000061, 19.95083427],
      [86.35972595, 19.95055771],
      [86.35945129, 19.95055962],
      [86.35939026, 19.95050049],
      [86.35916901, 19.95029068],
      [86.35861206, 19.95029068],
      [86.35832977, 19.95000076],
      [86.35703278, 19.94972038],
      [86.35639191, 19.94972229],
      [86.35540771, 19.94958878],
      [86.35513306, 19.94992065],
      [86.35526276, 19.95020103],
      [86.35547638, 19.95033073],
      [86.35700989, 19.95075607],
      [86.35730743, 19.95083427],
      [86.35740662, 19.95085907],
      [86.35876465, 19.95113182],
      [86.36018372, 19.95140266],
      [86.36289215, 19.95228004],
      [86.36517334, 19.95318985],
      [86.36746216, 19.95346069],
      [86.36846161, 19.95421028],
      [86.36917877, 19.95441055],
      [86.36989594, 19.95462608],
      [86.37007904, 19.95479012],
      [86.37082672, 19.95497513],
      [86.37110901, 19.95531082],
      [86.37125397, 19.95578766],
      [86.37132263, 19.95599174],
      [86.37132263, 19.95653343],
      [86.3713913, 19.9569397],
      [86.3720932, 19.95763969],
      [86.37229919, 19.95763969],
      [86.37265778, 19.95722961],
      [86.37258148, 19.95654106],
      [86.37223053, 19.95601082],
      [86.3722229, 19.9552803],
      [86.37249756, 19.95472145],
      [86.37249756, 19.95444489],
    ],
  ],
  [
    [
      [86.24055481, 19.90944672],
      [86.24028015, 19.90916443],
      [86.23990631, 19.90916443],
      [86.23972321, 19.90916443],
      [86.23944092, 19.90944672],
      [86.23860931, 19.90944672],
      [86.23777771, 19.9086113],
      [86.23777771, 19.90833282],
      [86.23722076, 19.90777779],
      [86.23694611, 19.90805435],
      [86.23665619, 19.90833092],
      [86.23666382, 19.90888977],
      [86.23722076, 19.90944672],
      [86.23722076, 19.90971947],
      [86.23777771, 19.91027641],
      [86.23777771, 19.91055489],
      [86.23799896, 19.91077423],
      [86.23833466, 19.91111183],
      [86.24021912, 19.91111183],
      [86.24055481, 19.91111183],
      [86.24111176, 19.91055489],
      [86.24111176, 19.91027641],
      [86.24054718, 19.90971947],
      [86.24055481, 19.90944672],
    ],
  ],
  [
    [
      [86.36860657, 19.9738903],
      [86.36888885, 19.97360992],
      [86.37046051, 19.97361183],
      [86.37232971, 19.97351837],
      [86.37252808, 19.97351074],
      [86.37281036, 19.97336769],
      [86.37302399, 19.97296715],
      [86.37301636, 19.97270012],
      [86.37281036, 19.97243118],
      [86.37252808, 19.97216034],
      [86.3713913, 19.9719429],
      [86.3713913, 19.97166634],
      [86.3722229, 19.97083282],
      [86.3722229, 19.9691658],
      [86.37169647, 19.96864128],
      [86.37088776, 19.96820259],
      [86.3706665, 19.96805954],
      [86.36972046, 19.96805573],
      [86.36958313, 19.96819496],
      [86.3694458, 19.9683342],
      [86.36916351, 19.96805573],
      [86.36805725, 19.96805573],
      [86.36777496, 19.9683342],
      [86.36723328, 19.96832848],
      [86.36694336, 19.96861076],
      [86.36499786, 19.96861076],
      [86.36486816, 19.96874046],
      [86.36416626, 19.96944427],
      [86.36444092, 19.96972275],
      [86.36444092, 19.96999931],
      [86.36528015, 19.97083282],
      [86.36528015, 19.97110176],
      [86.36499786, 19.97138977],
      [86.36499786, 19.97194481],
      [86.36555481, 19.97249985],
      [86.3658371, 19.97278023],
      [86.36611176, 19.97277832],
      [86.36638641, 19.97305489],
      [86.3666687, 19.97305489],
      [86.36694336, 19.97333336],
      [86.36722565, 19.97333336],
      [86.36750031, 19.97361183],
      [86.36808014, 19.97363663],
      [86.36831665, 19.9738903],
      [86.36860657, 19.9738903],
    ],
  ],
  [
    [
      [87.05751038, 20.74435806],
      [87.05760956, 20.74394989],
      [87.05742645, 20.74367905],
      [87.05712891, 20.74386978],
      [87.05671692, 20.74406052],
      [87.05628204, 20.7440815],
      [87.05608368, 20.74400711],
      [87.05589294, 20.74393463],
      [87.05547333, 20.74382019],
      [87.05510712, 20.74357414],
      [87.05477142, 20.74335289],
      [87.05421448, 20.7435112],
      [87.05369568, 20.74359322],
      [87.05317688, 20.74358177],
      [87.0526123, 20.74346352],
      [87.05195618, 20.74331474],
      [87.05164337, 20.74329185],
      [87.05132294, 20.74314308],
      [87.0508728, 20.7429924],
      [87.05047607, 20.74273109],
      [87.05013275, 20.74258995],
      [87.04981232, 20.74273109],
      [87.04953003, 20.74307632],
      [87.04927826, 20.74347115],
      [87.04902649, 20.74384117],
      [87.04911804, 20.74421883],
      [87.04953003, 20.74452209],
      [87.04995728, 20.74473953],
      [87.05036163, 20.74498749],
      [87.05075073, 20.74517059],
      [87.05116272, 20.74525833],
      [87.05158997, 20.74529076],
      [87.05201721, 20.74537086],
      [87.05246735, 20.74551773],
      [87.05293274, 20.74559975],
      [87.05335999, 20.74578094],
      [87.05378723, 20.74598122],
      [87.05430603, 20.7460804],
      [87.05488586, 20.74591064],
      [87.0554657, 20.74568367],
      [87.05599213, 20.7454052],
      [87.05648041, 20.74518013],
      [87.05692291, 20.74498177],
      [87.05728912, 20.74480057],
      [87.05742645, 20.74460983],
      [87.05751038, 20.74435806],
    ],
  ],
  [
    [
      [87.09083557, 20.75752068],
      [87.09111023, 20.75718689],
      [87.09140015, 20.75688171],
      [87.09172058, 20.75662231],
      [87.09210205, 20.7563858],
      [87.09249878, 20.75613022],
      [87.09290314, 20.75584602],
      [87.09333801, 20.75556946],
      [87.09379578, 20.75536919],
      [87.09422302, 20.75517082],
      [87.09440613, 20.75484848],
      [87.09442139, 20.7544899],
      [87.0943222, 20.75416756],
      [87.09414673, 20.75386047],
      [87.09393311, 20.75352097],
      [87.09368134, 20.7531414],
      [87.09334564, 20.7528019],
      [87.09288025, 20.75252533],
      [87.0923233, 20.75234032],
      [87.09205627, 20.75222969],
      [87.09176636, 20.75222015],
      [87.09136963, 20.75223923],
      [87.09088898, 20.75231743],
      [87.09059143, 20.75240135],
      [87.09037018, 20.75246048],
      [87.08983612, 20.75250053],
      [87.08930969, 20.75255966],
      [87.08882904, 20.75272369],
      [87.08837128, 20.75276947],
      [87.08792877, 20.75278091],
      [87.08750153, 20.75278091],
      [87.08706665, 20.75279045],
      [87.08664703, 20.75284004],
      [87.08624268, 20.75294113],
      [87.08583832, 20.75280952],
      [87.0854187, 20.75269127],
      [87.08493805, 20.75267982],
      [87.08439636, 20.75290108],
      [87.08393097, 20.75307655],
      [87.08349609, 20.75325203],
      [87.0830307, 20.7533226],
      [87.08268738, 20.75332642],
      [87.08257294, 20.75333023],
      [87.0823822, 20.75330544],
      [87.08213043, 20.7532692],
      [87.0817337, 20.75313568],
      [87.0812912, 20.7530899],
      [87.08088684, 20.75304031],
      [87.08048248, 20.7531414],
      [87.08003998, 20.7532444],
      [87.07985687, 20.75318527],
      [87.07958984, 20.75310326],
      [87.0790863, 20.75303078],
      [87.07860565, 20.75290489],
      [87.07816315, 20.75276375],
      [87.07779694, 20.75260544],
      [87.07752228, 20.75247955],
      [87.07731628, 20.75237846],
      [87.0769043, 20.75245094],
      [87.07653046, 20.75248337],
      [87.07639313, 20.75246811],
      [87.0761795, 20.75244522],
      [87.07582855, 20.75226974],
      [87.07543182, 20.75217056],
      [87.07502747, 20.7520771],
      [87.07460022, 20.75223923],
      [87.07418823, 20.75247955],
      [87.07382965, 20.75267982],
      [87.07345581, 20.75280952],
      [87.07311249, 20.75299835],
      [87.07272339, 20.75332069],
      [87.07247925, 20.7541008],
      [87.0725174, 20.75455856],
      [87.07264709, 20.75502968],
      [87.07285309, 20.75555038],
      [87.073349, 20.75587082],
      [87.07395935, 20.75612259],
      [87.07457733, 20.75637817],
      [87.07520294, 20.75658035],
      [87.07578278, 20.75674248],
      [87.07628632, 20.75692749],
      [87.07676697, 20.75705719],
      [87.0772171, 20.75723267],
      [87.07766724, 20.75745964],
      [87.07818604, 20.75766945],
      [87.07861328, 20.75799179],
      [87.07901764, 20.75830078],
      [87.07942963, 20.75847054],
      [87.07995605, 20.75831032],
      [87.08044434, 20.7579422],
      [87.08079529, 20.75763321],
      [87.08110046, 20.75741768],
      [87.08142853, 20.75729942],
      [87.08180237, 20.75726128],
      [87.0821991, 20.75727844],
      [87.08257294, 20.75735855],
      [87.08291626, 20.75748062],
      [87.08325195, 20.75765991],
      [87.08352661, 20.75789833],
      [87.08374023, 20.75823021],
      [87.08383942, 20.75866127],
      [87.08383942, 20.75917053],
      [87.08376312, 20.75970078],
      [87.08362579, 20.7602005],
      [87.08342743, 20.76067162],
      [87.08318329, 20.76108932],
      [87.08287048, 20.76151276],
      [87.08249664, 20.76200294],
      [87.08216095, 20.76264],
      [87.08200836, 20.76303291],
      [87.08216858, 20.76325035],
      [87.08250427, 20.76336479],
      [87.08283234, 20.76355362],
      [87.08317566, 20.76375389],
      [87.08349609, 20.76402092],
      [87.08394623, 20.76424026],
      [87.08452606, 20.76439095],
      [87.08469391, 20.76436996],
      [87.08534241, 20.76428986],
      [87.08615112, 20.76411057],
      [87.08642578, 20.76387024],
      [87.08666992, 20.76358986],
      [87.08690643, 20.76325417],
      [87.08714294, 20.76287079],
      [87.08737183, 20.76250076],
      [87.08759308, 20.76214027],
      [87.0878067, 20.76176834],
      [87.08805847, 20.76137161],
      [87.08833313, 20.76092148],
      [87.08862305, 20.76045036],
      [87.08893585, 20.76003456],
      [87.08929443, 20.75972557],
      [87.0896225, 20.75943947],
      [87.08991241, 20.75912094],
      [87.0901413, 20.75872993],
      [87.09034729, 20.75828552],
      [87.09057617, 20.75788116],
      [87.09083557, 20.75752068],
    ],
  ],
  [
    [
      [87.07055664, 20.73621368],
      [87.07038879, 20.73551559],
      [87.06999969, 20.73527718],
      [87.06944275, 20.73583412],
      [87.06916809, 20.73583412],
      [87.06824493, 20.73674393],
      [87.06804657, 20.73694038],
      [87.06804657, 20.73777962],
      [87.06833649, 20.73805618],
      [87.06833649, 20.73861122],
      [87.06916809, 20.73944473],
      [87.06948853, 20.73944473],
      [87.06972504, 20.73944473],
      [87.06999969, 20.7397213],
      [87.06977844, 20.7400074],
      [87.06965637, 20.7401619],
      [87.06955719, 20.74061012],
      [87.06967926, 20.74086761],
      [87.06971741, 20.74119186],
      [87.06973267, 20.74156952],
      [87.06972504, 20.74201775],
      [87.06972504, 20.74252129],
      [87.06976318, 20.74304962],
      [87.06990814, 20.74352837],
      [87.07003021, 20.74401093],
      [87.07023621, 20.74446106],
      [87.07026672, 20.74502945],
      [87.07028198, 20.74560928],
      [87.0703125, 20.74614716],
      [87.07048035, 20.74661064],
      [87.07056427, 20.74704552],
      [87.07068634, 20.74748039],
      [87.07087708, 20.74798965],
      [87.07116699, 20.74846077],
      [87.07149506, 20.74878311],
      [87.0717926, 20.74881172],
      [87.07192993, 20.74867249],
      [87.07205963, 20.74853897],
      [87.07205963, 20.74818993],
      [87.07212067, 20.74776077],
      [87.07200623, 20.74724007],
      [87.07195282, 20.74671364],
      [87.07194519, 20.74621964],
      [87.07195282, 20.7457695],
      [87.07194519, 20.74536133],
      [87.07194519, 20.74495888],
      [87.07193756, 20.7445507],
      [87.07187653, 20.74411011],
      [87.07174683, 20.74370003],
      [87.07170868, 20.74323082],
      [87.07174683, 20.74273109],
      [87.07161713, 20.7422905],
      [87.07144165, 20.74187469],
      [87.07138824, 20.74140167],
      [87.07132721, 20.74094772],
      [87.07113647, 20.74057961],
      [87.07104492, 20.7403717],
      [87.07097626, 20.74022293],
      [87.07084656, 20.73981667],
      [87.07071686, 20.73937035],
      [87.0705719, 20.7389946],
      [87.07043457, 20.73861885],
      [87.0703125, 20.73822021],
      [87.07028961, 20.73775864],
      [87.07032013, 20.73727036],
      [87.07045746, 20.7367897],
      [87.07055664, 20.73621368],
    ],
  ],
  [
    [
      [87.12388611, 21.51972008],
      [87.12388611, 21.5177784],
      [87.12375641, 21.51749992],
      [87.12361145, 21.51499939],
      [87.12387848, 21.51472092],
      [87.12361145, 21.51444435],
      [87.12304688, 21.51444054],
      [87.12249756, 21.51499939],
      [87.12243652, 21.51528931],
      [87.1222229, 21.51555824],
      [87.12210083, 21.51570129],
      [87.12194824, 21.51571083],
      [87.1213913, 21.51555824],
      [87.12110901, 21.51556396],
      [87.12083435, 21.51556396],
      [87.12055206, 21.51555634],
      [87.1202774, 21.51555824],
      [87.11916351, 21.51666641],
      [87.11888123, 21.51721954],
      [87.11888885, 21.51749992],
      [87.11888885, 21.5177784],
      [87.11909485, 21.51812363],
      [87.11915588, 21.51833153],
      [87.1194458, 21.51874924],
      [87.1194458, 21.51889038],
      [87.11972046, 21.51909065],
      [87.1202774, 21.51944351],
      [87.12055206, 21.5194416],
      [87.12166595, 21.5205555],
      [87.12243652, 21.52082443],
      [87.1230545, 21.52082443],
      [87.12365723, 21.52050209],
      [87.12388611, 21.51972008],
    ],
  ],
  [
    [
      [86.87416077, 20.67361069],
      [86.87388611, 20.67333031],
      [86.87361145, 20.67333412],
      [86.87319946, 20.67314339],
      [86.87241364, 20.67267227],
      [86.87206268, 20.67267036],
      [86.87197876, 20.67308998],
      [86.8722229, 20.67388916],
      [86.8722229, 20.67416954],
      [86.87333679, 20.67527771],
      [86.87333679, 20.67555046],
      [86.87555695, 20.67778015],
      [86.8758316, 20.67778015],
      [86.87611389, 20.67805862],
      [86.87695313, 20.67889023],
      [86.87722015, 20.67889023],
      [86.8777771, 20.6794529],
      [86.87832642, 20.6794529],
      [86.87889099, 20.67889023],
      [86.87889099, 20.67861176],
      [86.87833405, 20.67805481],
      [86.87833405, 20.67777824],
      [86.87638855, 20.67583084],
      [86.87638855, 20.67555618],
      [86.87444305, 20.67361069],
      [86.87416077, 20.67361069],
    ],
  ],
  [
    [
      [86.80278015, 20.42083359],
      [86.80361176, 20.41999817],
      [86.80388641, 20.41999817],
      [86.8041687, 20.4197197],
      [86.80444336, 20.4197216],
      [86.80472565, 20.41944504],
      [86.80555725, 20.41944504],
      [86.80583191, 20.4197216],
      [86.80722046, 20.4197216],
      [86.8077774, 20.41916656],
      [86.80805206, 20.41917038],
      [86.80832672, 20.41945076],
      [86.80860901, 20.41945076],
      [86.8088913, 20.41917038],
      [86.8088913, 20.41888809],
      [86.80944824, 20.41833115],
      [86.80944824, 20.41805649],
      [86.8097229, 20.41777992],
      [86.80999756, 20.41777992],
      [86.81223297, 20.41555977],
      [86.81223297, 20.41527939],
      [86.81305695, 20.41444016],
      [86.81305695, 20.4141674],
      [86.8133316, 20.41389084],
      [86.81305695, 20.41362],
      [86.81305695, 20.41333008],
      [86.8133316, 20.41305542],
      [86.8133316, 20.41250038],
      [86.81360626, 20.41222191],
      [86.81361389, 20.41166687],
      [86.81388092, 20.4113903],
      [86.81388092, 20.41111183],
      [86.81416321, 20.41083336],
      [86.81416321, 20.41055489],
      [86.81445313, 20.41028023],
      [86.81445313, 20.40806007],
      [86.81472015, 20.40777969],
      [86.81472015, 20.40722084],
      [86.81486511, 20.4066906],
      [86.81468201, 20.40624619],
      [86.81446075, 20.40584946],
      [86.81434631, 20.40545273],
      [86.81446075, 20.40504074],
      [86.81463623, 20.40462112],
      [86.81465912, 20.40414047],
      [86.81450653, 20.40367889],
      [86.81439209, 20.40316963],
      [86.81420135, 20.40267944],
      [86.81404877, 20.40218735],
      [86.81391144, 20.40172005],
      [86.81377411, 20.40130043],
      [86.81373596, 20.40076828],
      [86.81360626, 20.40028],
      [86.81360626, 20.39999962],
      [86.81276703, 20.39916992],
      [86.81276703, 20.39862061],
      [86.8125, 20.3983326],
      [86.8125, 20.39777756],
      [86.81223297, 20.3975029],
      [86.81223297, 20.39722252],
      [86.81194305, 20.39694405],
      [86.81194305, 20.39639091],
      [86.8116684, 20.39610863],
      [86.81141663, 20.39571953],
      [86.81118011, 20.39534187],
      [86.81095886, 20.39491653],
      [86.81079102, 20.39444923],
      [86.81054688, 20.39402962],
      [86.81031799, 20.39363289],
      [86.81015778, 20.39322281],
      [86.81010437, 20.39276695],
      [86.810112, 20.39227104],
      [86.80992889, 20.39190292],
      [86.80966949, 20.3915596],
      [86.80950165, 20.3911705],
      [86.80944824, 20.3908329],
      [86.80944824, 20.39027786],
      [86.80916595, 20.38999939],
      [86.80916595, 20.38943291],
      [86.80860901, 20.38888931],
      [86.80860901, 20.38861084],
      [86.80832672, 20.38833046],
      [86.80833435, 20.38722229],
      [86.80805206, 20.38694382],
      [86.80805206, 20.38638878],
      [86.8077774, 20.38611031],
      [86.8077774, 20.38528061],
      [86.80750275, 20.38500023],
      [86.80750275, 20.38389015],
      [86.8077774, 20.38362122],
      [86.8077774, 20.38194084],
      [86.80750275, 20.38166618],
      [86.80750275, 20.38110924],
      [86.8077774, 20.38083076],
      [86.8077774, 20.38055611],
      [86.80750275, 20.38027954],
      [86.80750275, 20.37833023],
      [86.80722046, 20.37805557],
      [86.80722046, 20.375],
      [86.8069458, 20.37471962],
      [86.8069458, 20.37444305],
      [86.80665588, 20.3741703],
      [86.80665588, 20.37388992],
      [86.80444336, 20.37166977],
      [86.8041687, 20.37166977],
      [86.80388641, 20.37138939],
      [86.80361176, 20.37166595],
      [86.80361176, 20.37194443],
      [86.8033371, 20.3722229],
      [86.8033371, 20.37249947],
      [86.80361176, 20.37277985],
      [86.80361176, 20.37388802],
      [86.80444336, 20.37472153],
      [86.80304718, 20.37611008],
      [86.80304718, 20.37667084],
      [86.80444336, 20.37805939],
      [86.80444336, 20.37861061],
      [86.80473328, 20.37889099],
      [86.80472565, 20.37916946],
      [86.80500031, 20.37944412],
      [86.80500031, 20.37972069],
      [86.80526733, 20.37999916],
      [86.80527496, 20.38027954],
      [86.80555725, 20.38055038],
      [86.80555725, 20.38110924],
      [86.80565643, 20.38121033],
      [86.80583191, 20.38139153],
      [86.80583191, 20.38166618],
      [86.8061142, 20.38194466],
      [86.80610657, 20.38221931],
      [86.80583191, 20.3825016],
      [86.80583191, 20.38333321],
      [86.80580902, 20.38389969],
      [86.80578613, 20.38443947],
      [86.80563354, 20.38495445],
      [86.80570984, 20.38542938],
      [86.80580902, 20.38588905],
      [86.80583191, 20.38636208],
      [86.80587769, 20.38685226],
      [86.80606079, 20.38735008],
      [86.80605316, 20.38786125],
      [86.80589294, 20.38834],
      [86.80587006, 20.38884926],
      [86.80589294, 20.38931084],
      [86.80570221, 20.38962173],
      [86.80543518, 20.38975143],
      [86.80508423, 20.38950157],
      [86.80474091, 20.38900757],
      [86.80466461, 20.38887405],
      [86.80440521, 20.38843536],
      [86.80404663, 20.38792038],
      [86.80370331, 20.38748169],
      [86.80269623, 20.3864975],
      [86.80220032, 20.38609505],
      [86.80169678, 20.38610268],
      [86.80155182, 20.38656807],
      [86.8015213, 20.38713074],
      [86.80145264, 20.38766098],
      [86.80124664, 20.38812065],
      [86.80105591, 20.38863754],
      [86.80073547, 20.38921356],
      [86.80049133, 20.3895874],
      [86.80030823, 20.3898716],
      [86.80041504, 20.39044952],
      [86.80057526, 20.39105225],
      [86.80083466, 20.39155006],
      [86.80110931, 20.39194107],
      [86.80194092, 20.3927803],
      [86.80194092, 20.39305496],
      [86.80222321, 20.39333344],
      [86.80222321, 20.39471817],
      [86.80194092, 20.39499855],
      [86.80194092, 20.3955555],
      [86.80166626, 20.39582825],
      [86.80166626, 20.39610863],
      [86.80110931, 20.39666939],
      [86.80098724, 20.39692116],
      [86.8006897, 20.39712524],
      [86.80033112, 20.3973484],
      [86.80000305, 20.39770508],
      [86.79977417, 20.39822388],
      [86.79973602, 20.39883995],
      [86.79989624, 20.39937782],
      [86.80055237, 20.39964294],
      [86.80097198, 20.4000206],
      [86.80122375, 20.40039063],
      [86.8013916, 20.40083122],
      [86.80222321, 20.40167999],
      [86.80222321, 20.40195084],
      [86.80110931, 20.40305519],
      [86.80110931, 20.40332985],
      [86.8013916, 20.40361023],
      [86.8013916, 20.40416527],
      [86.80166626, 20.40443039],
      [86.80166626, 20.40611076],
      [86.8013916, 20.40639114],
      [86.8013916, 20.4066658],
      [86.80110931, 20.40694046],
      [86.80110931, 20.40750122],
      [86.80082703, 20.40777969],
      [86.80110931, 20.40806007],
      [86.80110931, 20.40889168],
      [86.8013916, 20.40916634],
      [86.8013916, 20.40971947],
      [86.80083466, 20.41027832],
      [86.80047607, 20.41052055],
      [86.80046844, 20.4109211],
      [86.80059052, 20.41135979],
      [86.80086517, 20.41177559],
      [86.8013916, 20.41222382],
      [86.80166626, 20.41222191],
      [86.80194092, 20.41250038],
      [86.80222321, 20.41250038],
      [86.80249786, 20.41278076],
      [86.80278015, 20.41278076],
      [86.8033371, 20.41222191],
      [86.80361176, 20.41222382],
      [86.80388641, 20.41194534],
      [86.8041687, 20.41195107],
      [86.80473328, 20.4113903],
      [86.80500031, 20.4113903],
      [86.80555725, 20.41082954],
      [86.80555725, 20.41055489],
      [86.80583191, 20.41028023],
      [86.80638885, 20.41027832],
      [86.8069458, 20.41082954],
      [86.8061142, 20.41166687],
      [86.80610657, 20.41222191],
      [86.80638885, 20.41250038],
      [86.80638885, 20.41277695],
      [86.8069458, 20.41333389],
      [86.80583191, 20.41444588],
      [86.80500031, 20.41444016],
      [86.8041687, 20.41527748],
      [86.8041687, 20.41583061],
      [86.80361176, 20.41638947],
      [86.8033371, 20.41638947],
      [86.80304718, 20.41666031],
      [86.80305481, 20.41777611],
      [86.80222321, 20.41860962],
      [86.80222321, 20.41888809],
      [86.80166626, 20.41945076],
      [86.80166626, 20.41999817],
      [86.80249786, 20.42083359],
      [86.80278015, 20.42083359],
    ],
  ],
  [
    [
      [86.80272675, 20.45947266],
      [86.80259705, 20.45947266],
      [86.80202484, 20.45953751],
      [86.8015213, 20.45967293],
      [86.80130768, 20.45994377],
      [86.80123901, 20.46047974],
      [86.80123901, 20.46115685],
      [86.80137634, 20.46183014],
      [86.80145264, 20.46224976],
      [86.80194855, 20.46250725],
      [86.80245209, 20.46264267],
      [86.80281067, 20.46264267],
      [86.80330658, 20.46250725],
      [86.8035965, 20.46197128],
      [86.8035965, 20.46149063],
      [86.80323792, 20.46081924],
      [86.80287933, 20.46027756],
      [86.80272675, 20.45947266],
    ],
  ],
  [
    [
      [86.80527496, 20.49194527],
      [86.80638885, 20.49083328],
      [86.80638885, 20.49055481],
      [86.8069458, 20.49000168],
      [86.8069458, 20.48971939],
      [86.80722046, 20.48944473],
      [86.80722046, 20.48916626],
      [86.80750275, 20.48887825],
      [86.80750275, 20.48860931],
      [86.8077774, 20.48833084],
      [86.8077774, 20.48777962],
      [86.80805206, 20.48749924],
      [86.8077774, 20.48722076],
      [86.8077774, 20.4869442],
      [86.80805206, 20.48666573],
      [86.80805206, 20.48639107],
      [86.80832672, 20.48611069],
      [86.80832672, 20.48555946],
      [86.80860901, 20.48528099],
      [86.80860901, 20.48443985],
      [86.80944824, 20.48361206],
      [86.80916595, 20.48333359],
      [86.80916595, 20.48305511],
      [86.80860901, 20.48250008],
      [86.8088913, 20.4822197],
      [86.8088913, 20.48166656],
      [86.80860901, 20.48139],
      [86.80944824, 20.4805603],
      [86.80944824, 20.47999954],
      [86.80916595, 20.47970963],
      [86.80916595, 20.4794445],
      [86.8088913, 20.47916603],
      [86.8088913, 20.47805977],
      [86.80916595, 20.47777939],
      [86.80916595, 20.47722244],
      [86.80944824, 20.47694206],
      [86.80944824, 20.4766674],
      [86.80860901, 20.47582817],
      [86.80833435, 20.47444534],
      [86.80832672, 20.47417068],
      [86.80805206, 20.47390175],
      [86.8077774, 20.47390175],
      [86.80715179, 20.47326279],
      [86.8069458, 20.47305489],
      [86.8069458, 20.47277641],
      [86.80665588, 20.47249985],
      [86.80665588, 20.47194099],
      [86.80583191, 20.4711113],
      [86.80583191, 20.4691658],
      [86.80603027, 20.46838188],
      [86.80603027, 20.46757126],
      [86.80594635, 20.46689034],
      [86.80573273, 20.46669006],
      [86.80558777, 20.46648788],
      [86.8055191, 20.46600914],
      [86.80530548, 20.46567726],
      [86.80523682, 20.46521187],
      [86.80487823, 20.46459961],
      [86.80478668, 20.46445274],
      [86.80444336, 20.46388817],
      [86.8041687, 20.4636116],
      [86.80361176, 20.4636116],
      [86.80249786, 20.46470833],
      [86.80278015, 20.46500015],
      [86.80278015, 20.46555519],
      [86.80304718, 20.46582985],
      [86.80305481, 20.46666718],
      [86.80278015, 20.46694374],
      [86.80278015, 20.46776962],
      [86.80249786, 20.46805573],
      [86.80249786, 20.46972084],
      [86.80222321, 20.47000122],
      [86.80222321, 20.47056007],
      [86.80194092, 20.47083282],
      [86.80194092, 20.47222137],
      [86.80110931, 20.47305489],
      [86.80110931, 20.47333336],
      [86.80082703, 20.47360992],
      [86.80082703, 20.47390175],
      [86.80110931, 20.47417068],
      [86.80110931, 20.47444534],
      [86.8013916, 20.47471809],
      [86.8013916, 20.47527695],
      [86.80153656, 20.47542572],
      [86.80166626, 20.47555542],
      [86.80166626, 20.47582817],
      [86.80194092, 20.47610855],
      [86.80194092, 20.47639084],
      [86.80388641, 20.47833061],
      [86.80526733, 20.47970963],
      [86.80527496, 20.48027802],
      [86.80583191, 20.48083305],
      [86.80583191, 20.48110962],
      [86.8041687, 20.48278046],
      [86.80388641, 20.48277855],
      [86.8033371, 20.48332977],
      [86.80388641, 20.48389053],
      [86.80388641, 20.48416519],
      [86.80361176, 20.48443985],
      [86.80361176, 20.48500061],
      [86.8041687, 20.48555946],
      [86.8041687, 20.48583984],
      [86.80388641, 20.48611069],
      [86.80388641, 20.48639107],
      [86.8041687, 20.48666954],
      [86.80444336, 20.48666573],
      [86.80472565, 20.4869442],
      [86.80473328, 20.48722076],
      [86.80500031, 20.48749733],
      [86.80500031, 20.48833275],
      [86.80444336, 20.48887825],
      [86.80444336, 20.48916626],
      [86.8041687, 20.48944473],
      [86.8041687, 20.48971939],
      [86.80444336, 20.49000168],
      [86.80444336, 20.49083328],
      [86.8041687, 20.49111176],
      [86.8041687, 20.49139023],
      [86.80444336, 20.49167061],
      [86.80500031, 20.49166679],
      [86.80527496, 20.49194527],
    ],
  ],
  [
    [
      [87.0085144, 20.70812225],
      [87.00765991, 20.70798874],
      [87.00522614, 20.70799065],
      [87.00444794, 20.70806122],
      [87.00366211, 20.70833015],
      [87.00243378, 20.70866585],
      [87.00243378, 20.70900345],
      [87.00286102, 20.70953751],
      [87.00328827, 20.70994949],
      [87.00378418, 20.71042061],
      [87.00443268, 20.71089172],
      [87.00463867, 20.71108437],
      [87.00508118, 20.71150017],
      [87.00585938, 20.71237946],
      [87.00628662, 20.71305084],
      [87.00656891, 20.71359062],
      [87.00714111, 20.71426964],
      [87.00734711, 20.71480942],
      [87.00764465, 20.7154808],
      [87.00814056, 20.71622276],
      [87.0087204, 20.71696281],
      [87.00928497, 20.71770668],
      [87.00992584, 20.7183094],
      [87.01064301, 20.71905518],
      [87.01107025, 20.71937943],
      [87.0114975, 20.7198658],
      [87.01199341, 20.72040558],
      [87.01264954, 20.72081184],
      [87.01328278, 20.72114754],
      [87.01391602, 20.72154808],
      [87.01441956, 20.7218895],
      [87.01513672, 20.72216034],
      [87.01550293, 20.72223091],
      [87.01572418, 20.72222328],
      [87.01583099, 20.72222137],
      [87.01611328, 20.72194481],
      [87.01642609, 20.72154045],
      [87.01657867, 20.72120094],
      [87.01686096, 20.72079659],
      [87.01706696, 20.72032928],
      [87.01721191, 20.71992111],
      [87.01743317, 20.71945],
      [87.01750183, 20.71898079],
      [87.0175705, 20.71857071],
      [87.0175705, 20.71768951],
      [87.01763916, 20.71742439],
      [87.01778412, 20.71722031],
      [87.01778412, 20.71668434],
      [87.01750183, 20.71610832],
      [87.01748657, 20.71602058],
      [87.01743317, 20.7156105],
      [87.01743317, 20.71500587],
      [87.01721954, 20.71433067],
      [87.01693726, 20.71393013],
      [87.01657867, 20.71325111],
      [87.01615143, 20.71244049],
      [87.01586914, 20.7121048],
      [87.01551056, 20.71183205],
      [87.01486969, 20.7110939],
      [87.01415253, 20.71041679],
      [87.01373291, 20.7100811],
      [87.01315308, 20.70981026],
      [87.01258087, 20.70935249],
      [87.01180267, 20.70900154],
      [87.01115417, 20.70879745],
      [87.01072693, 20.70853043],
      [87.00987244, 20.70833015],
      [87.0091629, 20.70819092],
      [87.0085144, 20.70812225],
    ],
  ],
  [
    [
      [87.00302124, 20.71079063],
      [87.00230408, 20.71033287],
      [87.00216675, 20.7105999],
      [87.00216675, 20.71087265],
      [87.00238037, 20.71114159],
      [87.0025177, 20.71141052],
      [87.00266266, 20.71181488],
      [87.00295258, 20.71215057],
      [87.0030899, 20.71249008],
      [87.00323486, 20.71289635],
      [87.00330353, 20.71343231],
      [87.00402069, 20.71423912],
      [87.00430298, 20.71471977],
      [87.0045929, 20.71525955],
      [87.00502014, 20.71572876],
      [87.00526428, 20.71611595],
      [87.005867, 20.71707916],
      [87.0063858, 20.71762085],
      [87.00686646, 20.71870041],
      [87.00786591, 20.7197094],
      [87.00830078, 20.72005081],
      [87.00872803, 20.72024918],
      [87.00901794, 20.72024918],
      [87.0091629, 20.71997833],
      [87.00908661, 20.7197094],
      [87.00879669, 20.7193718],
      [87.00880432, 20.71883392],
      [87.00865936, 20.71849442],
      [87.0085144, 20.71809006],
      [87.00830078, 20.71767807],
      [87.00815582, 20.71707726],
      [87.00765991, 20.7166729],
      [87.00737762, 20.71640205],
      [87.00694275, 20.71559334],
      [87.00687408, 20.71518707],
      [87.00630188, 20.71430969],
      [87.00601959, 20.71336555],
      [87.00559235, 20.71289063],
      [87.00523376, 20.71242142],
      [87.00504303, 20.71223831],
      [87.00487518, 20.71208382],
      [87.00437927, 20.71174622],
      [87.00395203, 20.71147919],
      [87.00358582, 20.71128273],
      [87.00337982, 20.71114159],
      [87.00302124, 20.71079063],
    ],
  ],
  [
    [
      [87.01651001, 20.80282974],
      [87.01651001, 20.80067062],
      [87.0167923, 20.79904938],
      [87.0167923, 20.79824066],
      [87.01651001, 20.79770088],
      [87.01566315, 20.79743958],
      [87.01499939, 20.79722023],
      [87.01444244, 20.79722023],
      [87.01416779, 20.79750252],
      [87.01389313, 20.79750252],
      [87.01333618, 20.79805946],
      [87.01333618, 20.79861259],
      [87.01361084, 20.79888153],
      [87.01361084, 20.79916573],
      [87.01399994, 20.79958344],
      [87.01399994, 20.80120277],
      [87.01342773, 20.80201149],
      [87.01314545, 20.80228233],
      [87.01315308, 20.80255127],
      [87.01228333, 20.80282974],
      [87.01200104, 20.80282974],
      [87.01171875, 20.80308914],
      [87.01142883, 20.80336189],
      [87.00858307, 20.80606079],
      [87.00743103, 20.80714035],
      [87.00685883, 20.80795097],
      [87.00657654, 20.80821991],
      [87.00572205, 20.80957031],
      [87.00543213, 20.81038094],
      [87.00514984, 20.81118965],
      [87.00457764, 20.81226921],
      [87.00457764, 20.81578827],
      [87.00430298, 20.81659126],
      [87.00401306, 20.8168602],
      [87.00343323, 20.81793594],
      [87.00343323, 20.81874084],
      [87.00286865, 20.81955528],
      [87.00257874, 20.82090378],
      [87.00229645, 20.82198334],
      [87.00200653, 20.82333374],
      [87.00172424, 20.8244133],
      [87.00115204, 20.8257637],
      [87.00086975, 20.82683945],
      [87.00057983, 20.82846069],
      [87.00086975, 20.82953835],
      [87.00250244, 20.83222198],
      [87.0027771, 20.83222198],
      [87.00487518, 20.83232117],
      [87.0065918, 20.83232117],
      [87.00830078, 20.83177948],
      [87.00944519, 20.83097267],
      [87.01087189, 20.82989311],
      [87.01258087, 20.82827568],
      [87.01429749, 20.82665443],
      [87.01544189, 20.82584381],
      [87.01629639, 20.82449532],
      [87.0174408, 20.82368088],
      [87.01857758, 20.82287407],
      [87.01914978, 20.82152748],
      [87.01972198, 20.81991005],
      [87.01999664, 20.81855965],
      [87.01999664, 20.81478119],
      [87.0194397, 20.81343079],
      [87.0187912, 20.81226921],
      [87.0187912, 20.81064987],
      [87.01822662, 20.80903244],
      [87.01822662, 20.80768585],
      [87.0173645, 20.80444527],
      [87.0167923, 20.80336952],
      [87.01651001, 20.80282974],
    ],
  ],
  [
    [
      [86.98416901, 20.78222275],
      [86.98444366, 20.78194427],
      [86.98500061, 20.78194427],
      [86.9861145, 20.7808342],
      [86.98665619, 20.78083992],
      [86.98722076, 20.78028107],
      [86.98750305, 20.78028107],
      [86.98750305, 20.77943993],
      [86.98666382, 20.77861023],
      [86.98527527, 20.77861023],
      [86.98500061, 20.77833366],
      [86.98305511, 20.77833366],
      [86.98278046, 20.77805519],
      [86.98194122, 20.77805519],
      [86.98166656, 20.77833366],
      [86.98139191, 20.77832985],
      [86.98055267, 20.77916718],
      [86.98055267, 20.77944374],
      [86.98027802, 20.77972031],
      [86.98027802, 20.78000069],
      [86.98055267, 20.78028107],
      [86.98055267, 20.78055954],
      [86.98166656, 20.78166771],
      [86.98222351, 20.7816658],
      [86.98249817, 20.78194427],
      [86.98278046, 20.78194427],
      [86.98305511, 20.78222275],
      [86.98416901, 20.78222275],
    ],
  ],
  [
    [
      [86.87527466, 20.77583313],
      [86.87555695, 20.77555466],
      [86.87611389, 20.77555466],
      [86.8769455, 20.77472305],
      [86.87722015, 20.77472305],
      [86.8777771, 20.77416611],
      [86.8777771, 20.77318954],
      [86.8777771, 20.77305603],
      [86.87750244, 20.77277946],
      [86.87695313, 20.77277946],
      [86.87666321, 20.77305984],
      [86.87611389, 20.77305603],
      [86.8758316, 20.7733345],
      [86.87555695, 20.77333069],
      [86.87526703, 20.77361107],
      [86.875, 20.77361107],
      [86.87444305, 20.77416611],
      [86.87444305, 20.77444077],
      [86.87388611, 20.77499962],
      [86.87388611, 20.77555466],
      [86.8741684, 20.77583313],
      [86.87472534, 20.77583313],
      [86.875, 20.77555466],
      [86.87516022, 20.77571869],
      [86.87527466, 20.77583313],
    ],
  ],
  [
    [
      [86.86499023, 20.78111076],
      [86.86528015, 20.78083992],
      [86.86554718, 20.78083992],
      [86.8658371, 20.78055954],
      [86.86638641, 20.78055954],
      [86.8666687, 20.78028107],
      [86.86723328, 20.78028107],
      [86.86776733, 20.77972031],
      [86.86805725, 20.77972221],
      [86.86833191, 20.77944374],
      [86.8686142, 20.77944374],
      [86.87166595, 20.77638817],
      [86.87166595, 20.7761097],
      [86.87083435, 20.77527809],
      [86.8702774, 20.77527809],
      [86.87000275, 20.77555466],
      [86.8694458, 20.77555466],
      [86.86916351, 20.77583313],
      [86.8686142, 20.77583313],
      [86.86833191, 20.7761116],
      [86.86805725, 20.7761097],
      [86.86750031, 20.77667046],
      [86.86722565, 20.77666664],
      [86.8666687, 20.77722168],
      [86.86638641, 20.77722168],
      [86.8658371, 20.77777863],
      [86.86554718, 20.777771],
      [86.86444092, 20.77889061],
      [86.86444092, 20.77916718],
      [86.86416626, 20.77943993],
      [86.86416626, 20.78055954],
      [86.86472321, 20.78111076],
      [86.86499023, 20.78111076],
    ],
  ],
  [
    [
      [86.88194275, 20.78861046],
      [86.88223267, 20.78833008],
      [86.88666534, 20.7883358],
      [86.88694763, 20.78805542],
      [86.89083099, 20.78806114],
      [86.89111328, 20.78778076],
      [86.89277649, 20.78778076],
      [86.89305878, 20.78750038],
      [86.89417267, 20.78750038],
      [86.89444733, 20.78722382],
      [86.89666748, 20.78722382],
      [86.89694214, 20.78694534],
      [86.89833069, 20.78694534],
      [86.89861298, 20.78666687],
      [86.90000153, 20.78667068],
      [86.90027618, 20.7863903],
      [86.90083313, 20.7863903],
      [86.90110016, 20.78611183],
      [86.90193176, 20.78611374],
      [86.90249634, 20.78611374],
      [86.90277863, 20.78583145],
      [86.90305328, 20.78583145],
      [86.90333557, 20.78555489],
      [86.90416718, 20.78555489],
      [86.90444183, 20.78527832],
      [86.90471649, 20.78528023],
      [86.90499878, 20.78500175],
      [86.90556335, 20.78500175],
      [86.90583038, 20.78471947],
      [86.90611267, 20.78471947],
      [86.90638733, 20.78444481],
      [86.90777588, 20.78444481],
      [86.90805817, 20.78416634],
      [86.90888977, 20.78417015],
      [86.9091568, 20.78390121],
      [86.90999603, 20.78390121],
      [86.91028595, 20.78360939],
      [86.91139221, 20.78360939],
      [86.91166687, 20.78333282],
      [86.91305542, 20.78333282],
      [86.91333008, 20.78305626],
      [86.91361237, 20.78306007],
      [86.91388702, 20.7827816],
      [86.91443634, 20.7827816],
      [86.91471863, 20.78249931],
      [86.91694641, 20.78249931],
      [86.91722107, 20.78222084],
      [86.91777802, 20.78222275],
      [86.91805267, 20.78194427],
      [86.91944122, 20.78194427],
      [86.91972351, 20.7816658],
      [86.92193604, 20.78166771],
      [86.92222595, 20.78138924],
      [86.92415619, 20.78138924],
      [86.92444611, 20.78111076],
      [86.92610931, 20.78111076],
      [86.9263916, 20.78083992],
      [86.92694092, 20.78083992],
      [86.92722321, 20.78055954],
      [86.92778015, 20.78055954],
      [86.92804718, 20.78083992],
      [86.92861176, 20.78083992],
      [86.92888641, 20.78055954],
      [86.93055725, 20.78055954],
      [86.93083191, 20.78028107],
      [86.93222046, 20.78028107],
      [86.93250275, 20.78055954],
      [86.93360901, 20.78055954],
      [86.9338913, 20.78028107],
      [86.93638611, 20.78028107],
      [86.9366684, 20.78028107],
      [86.93694305, 20.78000069],
      [86.93723297, 20.78000069],
      [86.9375, 20.78028107],
      [86.9383316, 20.78028107],
      [86.93860626, 20.78000069],
      [86.94026947, 20.78000069],
      [86.94055176, 20.77972031],
      [86.94194794, 20.77972221],
      [86.9422226, 20.77944374],
      [86.94249725, 20.77944374],
      [86.94277954, 20.77916718],
      [86.94361115, 20.77917099],
      [86.94481659, 20.77840805],
      [86.94610596, 20.77786827],
      [86.94760895, 20.77726173],
      [86.94944, 20.77667046],
      [86.95024872, 20.77639961],
      [86.95096588, 20.77579117],
      [86.95153046, 20.77518082],
      [86.95153046, 20.7747097],
      [86.95054626, 20.77388954],
      [86.94999695, 20.77333069],
      [86.94944763, 20.7733345],
      [86.94927979, 20.77316666],
      [86.94916534, 20.77305603],
      [86.94902802, 20.77305603],
      [86.9486084, 20.77305984],
      [86.94832611, 20.77277946],
      [86.94695282, 20.77277946],
      [86.9466629, 20.7725029],
      [86.94555664, 20.7725029],
      [86.94526672, 20.77277946],
      [86.9422226, 20.77277946],
      [86.94194794, 20.7725029],
      [86.9411087, 20.7725029],
      [86.94082642, 20.77222061],
      [86.94055176, 20.77222061],
      [86.94026947, 20.77194023],
      [86.93972015, 20.7725029],
      [86.93945313, 20.7725029],
      [86.93916321, 20.77277946],
      [86.93527985, 20.77277946],
      [86.93499756, 20.77305984],
      [86.93416595, 20.77305603],
      [86.9338913, 20.7733345],
      [86.93222046, 20.77333069],
      [86.9319458, 20.77361107],
      [86.93110657, 20.77361107],
      [86.93083191, 20.77388954],
      [86.92804718, 20.77388954],
      [86.92778015, 20.77416992],
      [86.92666626, 20.77416992],
      [86.9263916, 20.77388954],
      [86.92555237, 20.77388954],
      [86.92527008, 20.77416992],
      [86.92082977, 20.77416992],
      [86.92073822, 20.77407646],
      [86.92055511, 20.77388954],
      [86.92028046, 20.77388954],
      [86.91999817, 20.77416992],
      [86.91832733, 20.77416992],
      [86.91805267, 20.77388954],
      [86.91777802, 20.77388954],
      [86.91750336, 20.77416992],
      [86.91666412, 20.77416611],
      [86.91638947, 20.77444458],
      [86.91444397, 20.77444458],
      [86.91416931, 20.77472305],
      [86.91388702, 20.77472305],
      [86.91361237, 20.77444458],
      [86.91333008, 20.77472305],
      [86.91194153, 20.77472305],
      [86.91166687, 20.77444458],
      [86.91083527, 20.77444458],
      [86.91055298, 20.77472305],
      [86.90777588, 20.77472305],
      [86.90750122, 20.77444458],
      [86.90694427, 20.77444458],
      [86.90638733, 20.77388954],
      [86.90611267, 20.77388954],
      [86.90583038, 20.77361107],
      [86.90471649, 20.77361107],
      [86.90444183, 20.77333069],
      [86.90333557, 20.7733345],
      [86.90313721, 20.77313805],
      [86.90305328, 20.77305603],
      [86.90277863, 20.77305984],
      [86.90249634, 20.77277946],
      [86.90222168, 20.77277946],
      [86.90194702, 20.7725029],
      [86.90139008, 20.7725029],
      [86.90110016, 20.77222061],
      [86.90027618, 20.77222061],
      [86.90000153, 20.77194214],
      [86.89722443, 20.77194214],
      [86.89694214, 20.77166748],
      [86.89527893, 20.77166748],
      [86.89499664, 20.77194214],
      [86.89083099, 20.77194214],
      [86.89055634, 20.77222061],
      [86.88722229, 20.77222061],
      [86.88694763, 20.7725029],
      [86.88500214, 20.7725029],
      [86.88471985, 20.77277946],
      [86.88360596, 20.77277946],
      [86.8833313, 20.77305984],
      [86.88083649, 20.77305603],
      [86.8805542, 20.7733345],
      [86.87999725, 20.7733345],
      [86.87889099, 20.77444077],
      [86.87944794, 20.77499962],
      [86.88028717, 20.77499962],
      [86.8805542, 20.77472305],
      [86.88111115, 20.77472305],
      [86.8813858, 20.77444458],
      [86.88222504, 20.77444458],
      [86.88249969, 20.77416611],
      [86.88361359, 20.77416611],
      [86.88388824, 20.77444458],
      [86.8841629, 20.77444458],
      [86.88445282, 20.7747097],
      [86.8841629, 20.77499962],
      [86.88388824, 20.77499962],
      [86.88360596, 20.77528],
      [86.88277435, 20.77527809],
      [86.88249969, 20.77555466],
      [86.88222504, 20.77555466],
      [86.88194275, 20.77583313],
      [86.88166809, 20.77583313],
      [86.8813858, 20.7761116],
      [86.88083649, 20.7761097],
      [86.88054657, 20.77639008],
      [86.87999725, 20.77639008],
      [86.8797226, 20.77667046],
      [86.87944794, 20.77667046],
      [86.87917328, 20.77695084],
      [86.87889099, 20.77695084],
      [86.87722015, 20.77861023],
      [86.87670135, 20.77861023],
      [86.87638855, 20.77861023],
      [86.87631226, 20.77853584],
      [86.87611389, 20.77833366],
      [86.8758316, 20.77833366],
      [86.87555695, 20.77806091],
      [86.87444305, 20.77917099],
      [86.87388611, 20.77916718],
      [86.87361145, 20.77944374],
      [86.8730545, 20.77944374],
      [86.87277985, 20.77916718],
      [86.87249756, 20.77944374],
      [86.87194824, 20.77944374],
      [86.87166595, 20.77972221],
      [86.87082672, 20.77972031],
      [86.87055206, 20.78000069],
      [86.87000275, 20.78000069],
      [86.86972046, 20.78028107],
      [86.8694458, 20.78028107],
      [86.86915588, 20.78055954],
      [86.86888885, 20.78055954],
      [86.86860657, 20.78083992],
      [86.86833191, 20.78083992],
      [86.86805725, 20.78111076],
      [86.86776733, 20.78111076],
      [86.86750031, 20.78138924],
      [86.86723328, 20.78138924],
      [86.86694336, 20.78166771],
      [86.8666687, 20.7816658],
      [86.86638641, 20.78194427],
      [86.86611176, 20.78194427],
      [86.8658371, 20.78222275],
      [86.86554718, 20.78222084],
      [86.86528015, 20.78249931],
      [86.86528015, 20.78305626],
      [86.86555481, 20.78333282],
      [86.86555481, 20.78360939],
      [86.86638641, 20.78444481],
      [86.8666687, 20.78444481],
      [86.86722565, 20.78500175],
      [86.86750031, 20.78500175],
      [86.86805725, 20.78555489],
      [86.86833191, 20.78555489],
      [86.8686142, 20.78583145],
      [86.86888885, 20.78583145],
      [86.86916351, 20.78611374],
      [86.86972046, 20.78611183],
      [86.87000275, 20.7863903],
      [86.87082672, 20.7863903],
      [86.87111664, 20.78667068],
      [86.8713913, 20.78666687],
      [86.87194824, 20.78722382],
      [86.87304688, 20.78722],
      [86.87333679, 20.78750038],
      [86.87472534, 20.78750038],
      [86.87490845, 20.78768539],
      [86.875, 20.78778076],
      [86.87555695, 20.78778076],
      [86.8758316, 20.78806114],
      [86.87638855, 20.78805542],
      [86.87666321, 20.7883358],
      [86.87906647, 20.78833389],
      [86.88083649, 20.78833008],
      [86.88111115, 20.78861046],
      [86.88194275, 20.78861046],
    ],
  ],
  [
    [
      [86.94812775, 20.78457069],
      [86.94813538, 20.78423691],
      [86.94777679, 20.78390121],
      [86.94728088, 20.78390121],
      [86.94670868, 20.78396988],
      [86.94556427, 20.78430557],
      [86.94535065, 20.78437233],
      [86.94456482, 20.78437233],
      [86.94428253, 20.78423691],
      [86.94371033, 20.78430939],
      [86.94364166, 20.78443909],
      [86.94361115, 20.78500175],
      [86.94361115, 20.78528023],
      [86.94499969, 20.78667068],
      [86.94610596, 20.78667068],
      [86.94621277, 20.78619003],
      [86.94656372, 20.78565407],
      [86.94734955, 20.78531647],
      [86.94783783, 20.78498077],
      [86.94812775, 20.78457069],
    ],
  ],
  [
    [
      [86.79166412, 20.37361145],
      [86.79194641, 20.37333488],
      [86.79222107, 20.37333488],
      [86.79250336, 20.37305641],
      [86.79360962, 20.37305641],
      [86.79380035, 20.37324142],
      [86.79389191, 20.37333488],
      [86.79416656, 20.37333488],
      [86.79444122, 20.37305641],
      [86.79471588, 20.37306023],
      [86.79556274, 20.3722229],
      [86.79582977, 20.3722229],
      [86.79582977, 20.37166595],
      [86.79528046, 20.37111282],
      [86.79528046, 20.37000275],
      [86.7950058, 20.36965942],
      [86.79483795, 20.36925697],
      [86.79466248, 20.3687706],
      [86.79444122, 20.36832047],
      [86.79424286, 20.36791039],
      [86.79411316, 20.36748505],
      [86.79389954, 20.36713028],
      [86.79360962, 20.36667061],
      [86.79232788, 20.36538696],
      [86.79222107, 20.36527634],
      [86.79166412, 20.36527634],
      [86.79111481, 20.36583328],
      [86.79111481, 20.36611176],
      [86.7910614, 20.36624908],
      [86.79083252, 20.36681938],
      [86.7906189, 20.36735725],
      [86.79046631, 20.36792183],
      [86.79038239, 20.36857986],
      [86.79038239, 20.36929512],
      [86.79037476, 20.36997414],
      [86.79042816, 20.37067032],
      [86.79052734, 20.37136078],
      [86.79055786, 20.37205124],
      [86.79062653, 20.37269974],
      [86.79103088, 20.37322044],
      [86.79166412, 20.37361145],
    ],
  ],
  [
    [
      [86.79390717, 20.3799305],
      [86.7943573, 20.37966919],
      [86.79469299, 20.37936974],
      [86.79490662, 20.37907028],
      [86.79503632, 20.37874413],
      [86.79521179, 20.37842369],
      [86.79534912, 20.37804222],
      [86.79554749, 20.37770081],
      [86.79574585, 20.37741089],
      [86.7958374, 20.37722015],
      [86.79676819, 20.37661171],
      [86.79711914, 20.37607193],
      [86.79698181, 20.37505913],
      [86.79638672, 20.37471962],
      [86.79638672, 20.37444305],
      [86.7958374, 20.37445068],
      [86.79555511, 20.37416649],
      [86.79499817, 20.37471962],
      [86.79416656, 20.37556076],
      [86.79389191, 20.37556076],
      [86.79389191, 20.375],
      [86.79360962, 20.37471962],
      [86.79277802, 20.37471962],
      [86.79250336, 20.375],
      [86.79222107, 20.375],
      [86.79166412, 20.37555695],
      [86.79165649, 20.3758297],
      [86.79110718, 20.37639046],
      [86.79110718, 20.37778091],
      [86.79083252, 20.37805557],
      [86.79083252, 20.37833023],
      [86.79110718, 20.37861061],
      [86.79111481, 20.37888718],
      [86.79194641, 20.3797226],
      [86.79222107, 20.37972069],
      [86.79250336, 20.37999916],
      [86.79332733, 20.37999916],
      [86.79390717, 20.3799305],
    ],
  ],
  [
    [
      [86.79305267, 20.38417053],
      [86.79277802, 20.38389015],
      [86.79110718, 20.3855629],
      [86.79165649, 20.38611031],
      [86.79194641, 20.38611031],
      [86.79332733, 20.38750076],
      [86.79389191, 20.38750076],
      [86.79444122, 20.38694],
      [86.79444122, 20.38666725],
      [86.79416656, 20.38639069],
      [86.79389191, 20.38639069],
      [86.79389191, 20.38445282],
      [86.79389191, 20.38417053],
      [86.79305267, 20.38417053],
    ],
  ],
  [
    [
      [86.50166321, 20.17584038],
      [86.50195312, 20.17556],
      [86.50250244, 20.17556],
      [86.5027771, 20.17527962],
      [86.5036087, 20.17527962],
      [86.50417328, 20.17472076],
      [86.50444794, 20.17472267],
      [86.5047226, 20.1744442],
      [86.50499725, 20.1744442],
      [86.50527954, 20.17416573],
      [86.50583649, 20.17416954],
      [86.50611115, 20.17389107],
      [86.50723267, 20.17389107],
      [86.50805664, 20.17305946],
      [86.50805664, 20.17250061],
      [86.50694275, 20.17139053],
      [86.50666809, 20.17139053],
      [86.50554657, 20.17028046],
      [86.50499725, 20.17028046],
      [86.50499725, 20.17000008],
      [86.50388336, 20.16889191],
      [86.5027771, 20.16889191],
      [86.50250244, 20.16861153],
      [86.50236511, 20.16875076],
      [86.50222015, 20.16889191],
      [86.50166321, 20.16832924],
      [86.50027466, 20.16833305],
      [86.5, 20.16805649],
      [86.5, 20.16778183],
      [86.49994659, 20.16721916],
      [86.49978638, 20.16676712],
      [86.49951172, 20.16634941],
      [86.4992218, 20.16589737],
      [86.4989624, 20.16539001],
      [86.49861908, 20.16489983],
      [86.4982605, 20.1644268],
      [86.49793243, 20.16390991],
      [86.49773407, 20.1632309],
      [86.49746704, 20.16254997],
      [86.49710846, 20.16195869],
      [86.49656677, 20.1616993],
      [86.49594879, 20.1616993],
      [86.4956665, 20.1616993],
      [86.4953537, 20.16183662],
      [86.49488831, 20.16197395],
      [86.49440765, 20.16221237],
      [86.49395752, 20.16245079],
      [86.49363708, 20.16277122],
      [86.49346924, 20.16312027],
      [86.49358368, 20.16348457],
      [86.49371338, 20.16383934],
      [86.49388885, 20.16419029],
      [86.4940567, 20.16455078],
      [86.49423218, 20.16489029],
      [86.49446869, 20.16519928],
      [86.49462891, 20.1654644],
      [86.49440002, 20.16570282],
      [86.49394226, 20.16586685],
      [86.4938736, 20.16589355],
      [86.49352264, 20.16594124],
      [86.49311829, 20.16580009],
      [86.49272156, 20.16556931],
      [86.49228668, 20.16530991],
      [86.49185944, 20.16489029],
      [86.49156952, 20.1648922],
      [86.4913559, 20.16525078],
      [86.49111938, 20.16572762],
      [86.49091339, 20.16628075],
      [86.49085999, 20.16685677],
      [86.4908905, 20.16739464],
      [86.49079132, 20.16788101],
      [86.49054718, 20.16834259],
      [86.49025726, 20.16883278],
      [86.48993683, 20.16935921],
      [86.48960876, 20.16990089],
      [86.48963928, 20.17038727],
      [86.49008179, 20.17073059],
      [86.49066162, 20.17098045],
      [86.49117279, 20.17128944],
      [86.4916687, 20.17152023],
      [86.49222565, 20.1716671],
      [86.49250031, 20.17194366],
      [86.49277496, 20.17194366],
      [86.49305725, 20.17222214],
      [86.49333191, 20.17222023],
      [86.49388885, 20.17278099],
      [86.49388885, 20.17305565],
      [86.49416351, 20.17333221],
      [86.4944458, 20.17333221],
      [86.49500275, 20.17277718],
      [86.4952774, 20.17278099],
      [86.49555206, 20.17305946],
      [86.49583435, 20.17305565],
      [86.49610901, 20.17345238],
      [86.49645233, 20.1738205],
      [86.49675751, 20.17416954],
      [86.49700928, 20.17451096],
      [86.49717712, 20.17484283],
      [86.49732208, 20.17519951],
      [86.4974823, 20.17556763],
      [86.49767303, 20.17597198],
      [86.49798584, 20.17632675],
      [86.49835968, 20.176651],
      [86.49884033, 20.17681122],
      [86.49944305, 20.17661095],
      [86.50004578, 20.17637062],
      [86.50061798, 20.17613029],
      [86.50119019, 20.17588997],
      [86.50166321, 20.17584038],
    ],
  ],
  [
    [
      [86.43722534, 20.05944443],
      [86.43805695, 20.05861092],
      [86.43776703, 20.058321],
      [86.4375, 20.05833244],
      [86.43694305, 20.05777931],
      [86.4366684, 20.05778122],
      [86.43638611, 20.05750084],
      [86.43611145, 20.05750084],
      [86.43527985, 20.05667114],
      [86.43527985, 20.05611038],
      [86.43444824, 20.05528069],
      [86.43444824, 20.05500031],
      [86.43360901, 20.05417061],
      [86.43360901, 20.05278015],
      [86.43416595, 20.05221939],
      [86.43444824, 20.0522213],
      [86.4347229, 20.05194473],
      [86.4347229, 20.05166626],
      [86.43416595, 20.05111122],
      [86.43360901, 20.05111122],
      [86.43332672, 20.05083275],
      [86.43305206, 20.05111122],
      [86.43222046, 20.05111122],
      [86.43222046, 20.05221939],
      [86.4319458, 20.05251122],
      [86.4319458, 20.05444336],
      [86.43165588, 20.05471992],
      [86.43166351, 20.05527687],
      [86.43222046, 20.05583],
      [86.43222046, 20.05611038],
      [86.43250275, 20.05638885],
      [86.43250275, 20.05694389],
      [86.4338913, 20.05833244],
      [86.43416595, 20.058321],
      [86.43444824, 20.05861092],
      [86.43527985, 20.05861092],
      [86.43554688, 20.05888748],
      [86.43611145, 20.05888748],
      [86.43638611, 20.05916977],
      [86.4366684, 20.05916786],
      [86.43694305, 20.05944443],
      [86.43722534, 20.05944443],
    ],
  ],
  [
    [
      [86.49111176, 20.14972305],
      [86.49138641, 20.14944458],
      [86.4916687, 20.14944458],
      [86.4916687, 20.14805412],
      [86.49223328, 20.14750099],
      [86.49276733, 20.14750099],
      [86.49333191, 20.14805984],
      [86.49333191, 20.14861107],
      [86.49360657, 20.14890099],
      [86.49472046, 20.14890099],
      [86.49472046, 20.14805984],
      [86.49360657, 20.14694023],
      [86.4936142, 20.14666748],
      [86.49276733, 20.14583015],
      [86.49250031, 20.14583206],
      [86.49222565, 20.1455555],
      [86.49111176, 20.1455555],
      [86.4908371, 20.14583206],
      [86.49028015, 20.14583015],
      [86.48999786, 20.14611053],
      [86.48972321, 20.14611053],
      [86.48916626, 20.14666748],
      [86.48916626, 20.14694595],
      [86.48944092, 20.14722061],
      [86.48944092, 20.14805412],
      [86.48916626, 20.14833069],
      [86.48916626, 20.14861107],
      [86.48999786, 20.14944458],
      [86.49055481, 20.14944458],
      [86.4908371, 20.14972305],
      [86.49111176, 20.14972305],
    ],
  ],
  [
    [
      [86.55297089, 20.20527077],
      [86.5526123, 20.20527077],
      [86.5526886, 20.20581055],
      [86.55332947, 20.20641708],
      [86.554039, 20.20681953],
      [86.55503845, 20.20735931],
      [86.55532837, 20.20708847],
      [86.55539703, 20.20675468],
      [86.55511475, 20.20628166],
      [86.55432892, 20.20614624],
      [86.55368042, 20.20569038],
      [86.55297089, 20.20527077],
    ],
  ],
  [
    [
      [86.4263916, 20.03861046],
      [86.42610931, 20.03833008],
      [86.42555237, 20.03833389],
      [86.42472076, 20.03750038],
      [86.42472076, 20.03722191],
      [86.42500305, 20.03694534],
      [86.42500305, 20.0363903],
      [86.42444611, 20.03582954],
      [86.42444611, 20.03555489],
      [86.42305756, 20.03416634],
      [86.42279053, 20.03417015],
      [86.42279053, 20.03388977],
      [86.42166901, 20.03277969],
      [86.42166901, 20.03250122],
      [86.42138672, 20.03222084],
      [86.42111206, 20.03222275],
      [86.42082977, 20.03194427],
      [86.42056274, 20.03222084],
      [86.42056274, 20.03250122],
      [86.42028046, 20.03277969],
      [86.42055511, 20.03305626],
      [86.42055511, 20.03333282],
      [86.42082977, 20.03360748],
      [86.42082977, 20.03416634],
      [86.42111206, 20.03444481],
      [86.42111206, 20.03472137],
      [86.42138672, 20.03500175],
      [86.42138672, 20.03555489],
      [86.42166901, 20.03583336],
      [86.42166901, 20.03611183],
      [86.42138672, 20.0363903],
      [86.42138672, 20.03750992],
      [86.42166901, 20.03777695],
      [86.42166901, 20.03833008],
      [86.42360687, 20.04027939],
      [86.42360687, 20.04057121],
      [86.42415619, 20.04111099],
      [86.42415619, 20.04138947],
      [86.42444611, 20.04166412],
      [86.42444611, 20.0419445],
      [86.42472076, 20.04222107],
      [86.42472076, 20.04249954],
      [86.42583466, 20.04361153],
      [86.4263916, 20.04361153],
      [86.42722321, 20.04277611],
      [86.42722321, 20.04249954],
      [86.42749786, 20.04222107],
      [86.42749786, 20.04166412],
      [86.42722321, 20.04138947],
      [86.42722321, 20.04111099],
      [86.42694092, 20.04083061],
      [86.42694092, 20.04027748],
      [86.42666626, 20.04000092],
      [86.42666626, 20.03944016],
      [86.4263916, 20.0391674],
      [86.4263916, 20.03861046],
    ],
  ],
  [
    [
      [86.41085052, 20.01832962],
      [86.41027832, 20.01777649],
      [86.40999603, 20.0177803],
      [86.40972137, 20.01749992],
      [86.40944672, 20.01749992],
      [86.4088974, 20.01805115],
      [86.40888977, 20.01889038],
      [86.40916443, 20.01916885],
      [86.4091568, 20.01971817],
      [86.40944672, 20.02000046],
      [86.40944672, 20.0205555],
      [86.40972137, 20.02082825],
      [86.40972137, 20.02166748],
      [86.41004181, 20.02203941],
      [86.41027069, 20.02240944],
      [86.41049957, 20.02272987],
      [86.41083527, 20.02305603],
      [86.41085052, 20.02361107],
      [86.41110992, 20.02388954],
      [86.41110992, 20.02416611],
      [86.41139221, 20.02444458],
      [86.41139221, 20.02472115],
      [86.41166687, 20.02499962],
      [86.41166687, 20.02527809],
      [86.41222382, 20.02583313],
      [86.41249847, 20.02583313],
      [86.41293335, 20.02540016],
      [86.41305542, 20.02527809],
      [86.41278076, 20.02499962],
      [86.41278076, 20.02472115],
      [86.41249847, 20.02444458],
      [86.41249847, 20.02388954],
      [86.41221619, 20.02361107],
      [86.41221619, 20.0233326],
      [86.41166687, 20.02277756],
      [86.41166687, 20.02166939],
      [86.41221619, 20.02111053],
      [86.41221619, 20.02055931],
      [86.4119873, 20.02011108],
      [86.41178894, 20.01963806],
      [86.41158295, 20.01908684],
      [86.41118622, 20.01863289],
      [86.41085052, 20.01832962],
    ],
  ],
  [
    [
      [86.32110596, 19.98806],
      [86.32055664, 19.98806],
      [86.31999969, 19.98861122],
      [86.31999969, 19.98916435],
      [86.31972504, 19.98944473],
      [86.31972504, 19.99055481],
      [86.31887054, 19.99183273],
      [86.31879425, 19.9925766],
      [86.31861115, 19.99332047],
      [86.31861115, 19.99360847],
      [86.31833649, 19.99389076],
      [86.31833649, 19.99471855],
      [86.31861115, 19.99500084],
      [86.31944275, 19.99416924],
      [86.31944275, 19.99360847],
      [86.31973267, 19.99332809],
      [86.31972504, 19.99305534],
      [86.32073212, 19.9914093],
      [86.32094574, 19.9907341],
      [86.32137299, 19.98945808],
      [86.32150269, 19.98908043],
      [86.32144928, 19.98839569],
      [86.32110596, 19.98806],
    ],
  ],
  [
    [
      [86.32138824, 19.99888992],
      [86.32138824, 19.99777985],
      [86.3216629, 19.99749947],
      [86.3216629, 19.99666977],
      [86.32195282, 19.99638939],
      [86.32194519, 19.99500084],
      [86.3216629, 19.99472237],
      [86.3216629, 19.99389076],
      [86.32110596, 19.99332809],
      [86.3208313, 19.99332809],
      [86.32027435, 19.99389076],
      [86.31999969, 19.99443817],
      [86.31999969, 19.99500084],
      [86.31973267, 19.99526978],
      [86.31972504, 19.99555588],
      [86.31944275, 19.99583244],
      [86.31944275, 19.99611282],
      [86.31861115, 19.99694443],
      [86.31861115, 19.9972229],
      [86.31833649, 19.99749947],
      [86.31833649, 19.99777794],
      [86.31777954, 19.99833107],
      [86.31749725, 19.99833107],
      [86.3172226, 19.99861145],
      [86.3161087, 19.99860954],
      [86.31583405, 19.99888802],
      [86.31561279, 19.99916458],
      [86.31604004, 19.99943352],
      [86.31667328, 20],
      [86.31685638, 20.00038719],
      [86.31735229, 20.00065231],
      [86.31749725, 20.00083351],
      [86.31777954, 20.00111008],
      [86.31817627, 20.00119019],
      [86.31826782, 20.00172806],
      [86.31859589, 20.00213051],
      [86.31900787, 20.00233269],
      [86.31944275, 20.00226021],
      [86.31990051, 20.00214577],
      [86.32021332, 20.00188065],
      [86.32032776, 20.00148201],
      [86.32047272, 20.00112534],
      [86.32064056, 20.00078964],
      [86.32095337, 20.00043488],
      [86.32055664, 20],
      [86.32110596, 19.9991703],
      [86.32138824, 19.99888992],
    ],
  ],
  [
    [
      [86.33557129, 19.99082947],
      [86.33556366, 19.98981667],
      [86.33557129, 19.98954964],
      [86.33528137, 19.98888969],
      [86.33528137, 19.98861122],
      [86.33499908, 19.98833084],
      [86.3347168, 19.98833084],
      [86.33389282, 19.98917007],
      [86.33361053, 19.98916435],
      [86.33305359, 19.9897213],
      [86.33277893, 19.98971939],
      [86.33249664, 19.98999977],
      [86.33220673, 19.98999977],
      [86.33140564, 19.99081421],
      [86.33028412, 19.99238205],
      [86.32993317, 19.99394035],
      [86.32927704, 19.99570274],
      [86.32800293, 19.99698639],
      [86.3272171, 19.99860954],
      [86.3272171, 20],
      [86.32694244, 20.00027847],
      [86.32694244, 20.00111008],
      [86.32672882, 20.00132942],
      [86.3266983, 20.00140381],
      [86.32655334, 20.00179291],
      [86.3263855, 20.00229836],
      [86.32623291, 20.00284004],
      [86.32620239, 20.00337791],
      [86.32640076, 20.00381088],
      [86.32662201, 20.00419235],
      [86.32743835, 20.00574112],
      [86.32779694, 20.00682259],
      [86.32804871, 20.00797844],
      [86.32816315, 20.00851059],
      [86.32836914, 20.00905037],
      [86.32888794, 20.01000023],
      [86.32943726, 20.0105629],
      [86.330513, 20.00975037],
      [86.33065033, 20.00926971],
      [86.33095551, 20.00894928],
      [86.3312912, 20.00859833],
      [86.33333588, 20.00720978],
      [86.33333588, 20.00694466],
      [86.33455658, 20.00572395],
      [86.33472443, 20.00555611],
      [86.33472443, 20.00513077],
      [86.33472443, 20.00499725],
      [86.33499908, 20.00472069],
      [86.33499908, 20.00444412],
      [86.33528137, 20.00416565],
      [86.33528137, 20.00389099],
      [86.33557129, 20.00361061],
      [86.33555603, 20.00333595],
      [86.33583069, 20.00305557],
      [86.33557129, 20.00278091],
      [86.33561707, 20.00242996],
      [86.33583069, 20.00208092],
      [86.33603668, 20.00172997],
      [86.33613586, 20.00131989],
      [86.33625793, 20.0009594],
      [86.33634949, 20.00062561],
      [86.3364563, 20.00031853],
      [86.33666992, 20],
      [86.33657074, 19.99971962],
      [86.33686066, 19.99776077],
      [86.33677673, 19.99613953],
      [86.33642578, 19.99416924],
      [86.33637238, 19.99401093],
      [86.33555603, 19.9916687],
      [86.33557129, 19.99082947],
    ],
  ],
  [
    [
      [86.32213593, 20.01102448],
      [86.32194519, 20.01083374],
      [86.32194519, 20.01055527],
      [86.32138824, 20.01000023],
      [86.32111359, 20.01000023],
      [86.3208313, 20.01026917],
      [86.3208313, 20.0105629],
      [86.32027435, 20.01111031],
      [86.32027435, 20.01194382],
      [86.31999969, 20.01222229],
      [86.31999969, 20.01249123],
      [86.31999969, 20.01305962],
      [86.31972504, 20.01333427],
      [86.31973267, 20.01361084],
      [86.31944275, 20.01389122],
      [86.31944275, 20.01416588],
      [86.3188858, 20.01472092],
      [86.3188858, 20.01499939],
      [86.31861115, 20.01527977],
      [86.31861115, 20.01556015],
      [86.31777954, 20.01638794],
      [86.31777954, 20.01694679],
      [86.31749725, 20.01721954],
      [86.31749725, 20.01749992],
      [86.3172226, 20.01777649],
      [86.3172226, 20.01833344],
      [86.31694794, 20.01860809],
      [86.31694794, 20.01917076],
      [86.31672668, 20.01954079],
      [86.31661987, 20.0199604],
      [86.31643677, 20.02037048],
      [86.31638336, 20.02078056],
      [86.31639099, 20.02111053],
      [86.3161087, 20.02139091],
      [86.31582642, 20.02166939],
      [86.3152771, 20.02111053],
      [86.31472015, 20.02111053],
      [86.31414795, 20.02055931],
      [86.31361389, 20.0205555],
      [86.3133316, 20.02083397],
      [86.31305695, 20.02082825],
      [86.31276703, 20.02111053],
      [86.3125, 20.02111053],
      [86.31223297, 20.02139091],
      [86.31194305, 20.02139091],
      [86.31111145, 20.02222252],
      [86.31027985, 20.02139091],
      [86.30833435, 20.0233326],
      [86.30750275, 20.0233326],
      [86.30722046, 20.02361107],
      [86.3069458, 20.02361107],
      [86.30665588, 20.02388954],
      [86.30638885, 20.02388954],
      [86.30610657, 20.02416992],
      [86.30555725, 20.02416611],
      [86.30527496, 20.02444458],
      [86.30473328, 20.02444267],
      [86.3041687, 20.02499962],
      [86.30361176, 20.02499962],
      [86.3033371, 20.02528],
      [86.30223083, 20.02528],
      [86.30110931, 20.02639008],
      [86.30110931, 20.02667046],
      [86.30194092, 20.02750015],
      [86.30223083, 20.02750015],
      [86.30249786, 20.02778053],
      [86.3033371, 20.02777863],
      [86.30377197, 20.02792168],
      [86.30419922, 20.02806282],
      [86.30666351, 20.02777863],
      [86.3069458, 20.02778053],
      [86.30722046, 20.02750015],
      [86.3077774, 20.02750015],
      [86.30805206, 20.02778053],
      [86.30805206, 20.02806091],
      [86.30832672, 20.02833939],
      [86.30860901, 20.02833939],
      [86.3088913, 20.02861023],
      [86.30944824, 20.02861023],
      [86.31027985, 20.02944374],
      [86.30999756, 20.02972031],
      [86.30999756, 20.03000069],
      [86.31027985, 20.03028107],
      [86.31027985, 20.03055763],
      [86.3105545, 20.0308342],
      [86.31138611, 20.0308342],
      [86.3116684, 20.03055763],
      [86.31194305, 20.03055954],
      [86.31276703, 20.02972031],
      [86.31472015, 20.03166962],
      [86.31500244, 20.03166962],
      [86.31555176, 20.03111076],
      [86.31582642, 20.03111076],
      [86.3161087, 20.03083038],
      [86.31555176, 20.03027534],
      [86.31555176, 20.03000069],
      [86.31582642, 20.02972031],
      [86.31583405, 20.02861023],
      [86.3161087, 20.02833939],
      [86.3161087, 20.02806091],
      [86.31667328, 20.02750015],
      [86.31666565, 20.02722168],
      [86.31694794, 20.02694321],
      [86.31694794, 20.0261097],
      [86.3172226, 20.02583313],
      [86.3172226, 20.02555466],
      [86.31777954, 20.02499962],
      [86.31777954, 20.02472115],
      [86.31833649, 20.02416611],
      [86.31833649, 20.02388954],
      [86.31861115, 20.02361107],
      [86.3187561, 20.02339172],
      [86.31902313, 20.02324104],
      [86.31926727, 20.02301025],
      [86.31973267, 20.02277946],
      [86.31973267, 20.0225029],
      [86.31999969, 20.02220917],
      [86.32027435, 20.02222252],
      [86.32055664, 20.02194405],
      [86.3208313, 20.02194405],
      [86.32138824, 20.02139091],
      [86.3216629, 20.02139091],
      [86.32195282, 20.02111053],
      [86.32221985, 20.02111053],
      [86.32305908, 20.02028084],
      [86.32334137, 20.02028084],
      [86.32389069, 20.01971817],
      [86.32389069, 20.01888657],
      [86.32444763, 20.01832962],
      [86.32444763, 20.01805496],
      [86.32472229, 20.0177803],
      [86.32472229, 20.01721954],
      [86.32499695, 20.01694679],
      [86.32499695, 20.01666641],
      [86.32527924, 20.01639938],
      [86.32499695, 20.01610947],
      [86.32499695, 20.01555443],
      [86.32478333, 20.01503944],
      [86.32443237, 20.0147171],
      [86.32401276, 20.01441956],
      [86.32363129, 20.01400185],
      [86.32332611, 20.01348686],
      [86.32305908, 20.01278114],
      [86.32277679, 20.01250076],
      [86.32277679, 20.01194191],
      [86.32221985, 20.01138878],
      [86.32221985, 20.01111031],
      [86.32213593, 20.01102448],
    ],
  ],
  [
    [
      [86.3104248, 20.0162468],
      [86.3105545, 20.01611137],
      [86.31138611, 20.01611137],
      [86.31194305, 20.01555443],
      [86.3125, 20.01555443],
      [86.31305695, 20.01611137],
      [86.3133316, 20.01611137],
      [86.31361389, 20.0158329],
      [86.31416321, 20.0158329],
      [86.31472015, 20.01527786],
      [86.31472015, 20.01499939],
      [86.31500244, 20.01472092],
      [86.31500244, 20.01333427],
      [86.31472015, 20.01305962],
      [86.31472015, 20.01222038],
      [86.3144455, 20.01194382],
      [86.3144455, 20.01138878],
      [86.31472015, 20.01111031],
      [86.31472015, 20.01083183],
      [86.3152771, 20.0102787],
      [86.3152771, 20.01000023],
      [86.31555176, 20.00972176],
      [86.31555176, 20.00916672],
      [86.31582642, 20.00889015],
      [86.31582642, 20.00861168],
      [86.3152771, 20.00805473],
      [86.3133316, 20.00805473],
      [86.31305695, 20.00833321],
      [86.31305695, 20.00861168],
      [86.31276703, 20.00889015],
      [86.3133316, 20.00945282],
      [86.3133316, 20.01000023],
      [86.31194305, 20.01139069],
      [86.31140137, 20.01139069],
      [86.31111145, 20.01111031],
      [86.31083679, 20.01111031],
      [86.31054688, 20.01139069],
      [86.31027985, 20.01139069],
      [86.30999756, 20.01166725],
      [86.31027985, 20.01194382],
      [86.31027985, 20.01222038],
      [86.31054688, 20.01250076],
      [86.31054688, 20.01278114],
      [86.31111145, 20.01334],
      [86.31111145, 20.01389122],
      [86.31083679, 20.01416588],
      [86.31083679, 20.01444054],
      [86.31027985, 20.01499939],
      [86.31027985, 20.01527977],
      [86.30999756, 20.01555443],
      [86.31003571, 20.01615143],
      [86.31027985, 20.01639938],
      [86.3104248, 20.0162468],
    ],
  ],
  [
    [
      [81.75829315, 17.89336014],
      [81.72631836, 17.87409973],
      [81.70317078, 17.86112976],
      [81.69927216, 17.8697319],
      [81.69787598, 17.87553978],
      [81.68828583, 17.88479996],
      [81.6832428, 17.87948036],
      [81.67774963, 17.87758064],
      [81.66805267, 17.87842941],
      [81.66306305, 17.87693024],
      [81.6569519, 17.8728199],
      [81.65270233, 17.86819077],
      [81.64906311, 17.86246872],
      [81.64601898, 17.85626984],
      [81.64614105, 17.84687042],
      [81.64279175, 17.83928108],
      [81.6365509, 17.83628082],
      [81.63143921, 17.83044052],
      [81.62397766, 17.82442284],
      [81.61569977, 17.82201004],
      [81.61115265, 17.81536293],
      [81.59973907, 17.81544113],
      [81.59395599, 17.81911087],
      [81.57486725, 17.82734108],
      [81.56874084, 17.82788086],
      [81.56459045, 17.82569122],
      [81.55393982, 17.8163681],
      [81.52572632, 17.81087112],
      [81.51130676, 17.80656052],
      [81.49632263, 17.80647087],
      [81.48583984, 17.8026104],
      [81.48117828, 17.80286026],
      [81.47870636, 17.80470848],
      [81.47457123, 17.80999947],
      [81.47271729, 17.81789017],
      [81.47012329, 17.82318115],
      [81.46684265, 17.82492065],
      [81.45942688, 17.82471085],
      [81.43756866, 17.81529045],
      [81.43387604, 17.81575966],
      [81.42592621, 17.82127953],
      [81.41941833, 17.8205986],
      [81.41433716, 17.81643105],
      [81.41445923, 17.81011009],
      [81.40925598, 17.80483818],
      [81.40276337, 17.80532074],
      [81.39431, 17.80812073],
      [81.38497162, 17.80659294],
      [81.38310242, 17.81414032],
      [81.38304901, 17.81695175],
      [81.38300323, 17.82057953],
      [81.38378906, 17.82348824],
      [81.38442993, 17.82579041],
      [81.39334106, 17.83582115],
      [81.39736938, 17.84364128],
      [81.39805603, 17.84678078],
      [81.38987732, 17.85943031],
      [81.38954926, 17.8621006],
      [81.39050293, 17.87429047],
      [81.39382935, 17.88513947],
      [81.39585114, 17.88755989],
      [81.39901733, 17.88849068],
      [81.40376282, 17.88859177],
      [81.41166687, 17.88539124],
      [81.42312622, 17.87742043],
      [81.4302063, 17.87467957],
      [81.43450928, 17.87490082],
      [81.43595886, 17.87544823],
      [81.43725586, 17.87593842],
      [81.44026184, 17.87914085],
      [81.44274902, 17.88516045],
      [81.4437027, 17.89323044],
      [81.44827271, 17.90302086],
      [81.44818115, 17.9050808],
      [81.44806671, 17.90714073],
      [81.44621277, 17.91028023],
      [81.44592285, 17.91717911],
      [81.44445801, 17.92210007],
      [81.44510651, 17.92546844],
      [81.44523621, 17.92617035],
      [81.45479584, 17.94655991],
      [81.46695709, 17.95490074],
      [81.47325897, 17.96532059],
      [81.47567749, 17.96708107],
      [81.47644043, 17.96763039],
      [81.47731018, 17.97002029],
      [81.47688293, 17.97265053],
      [81.47666931, 17.97389984],
      [81.47307587, 17.98328972],
      [81.47451019, 17.98870277],
      [81.47767639, 17.9935112],
      [81.47894287, 18.00370026],
      [81.47750092, 18.01999283],
      [81.47389221, 18.02851105],
      [81.47751617, 18.03872108],
      [81.48149872, 18.04992294],
      [81.49465942, 18.05800056],
      [81.49488068, 18.06266022],
      [81.49516296, 18.06868935],
      [81.49600983, 18.07069969],
      [81.49684906, 18.07271194],
      [81.50865936, 18.09241104],
      [81.51072693, 18.10387993],
      [81.51187897, 18.1292305],
      [81.51580048, 18.13993073],
      [81.52173615, 18.14830971],
      [81.5226059, 18.15727043],
      [81.51487732, 18.17217064],
      [81.50892639, 18.17407036],
      [81.50524902, 18.17778969],
      [81.504776, 18.18381119],
      [81.50878906, 18.1925106],
      [81.51113892, 18.20194054],
      [81.52287292, 18.22737122],
      [81.52577209, 18.23652077],
      [81.52368164, 18.24865913],
      [81.52796173, 18.25908089],
      [81.53945923, 18.26210976],
      [81.54807281, 18.26213074],
      [81.56759644, 18.27321053],
      [81.57401276, 18.28116989],
      [81.58178711, 18.28816032],
      [81.58624268, 18.29215813],
      [81.59050751, 18.29333115],
      [81.59203339, 18.29747963],
      [81.59340668, 18.30128098],
      [81.59687042, 18.30211067],
      [81.60611725, 18.2994194],
      [81.61588287, 18.30084038],
      [81.6213913, 18.30372238],
      [81.62761688, 18.30487061],
      [81.63214111, 18.3080101],
      [81.64675903, 18.31150055],
      [81.65866852, 18.3110981],
      [81.6603775, 18.31575966],
      [81.65245819, 18.32478905],
      [81.64937592, 18.33057976],
      [81.64916992, 18.33100128],
      [81.65280914, 18.33667946],
      [81.65809631, 18.33929062],
      [81.68078613, 18.34103012],
      [81.69924164, 18.34357071],
      [81.71750641, 18.34320068],
      [81.73707581, 18.34422112],
      [81.74491119, 18.34535027],
      [81.74607849, 18.35174179],
      [81.74675751, 18.35550117],
      [81.75554657, 18.38624954],
      [81.76036835, 18.39982986],
      [81.76210022, 18.4113102],
      [81.76738739, 18.41477013],
      [81.79756927, 18.43976021],
      [81.82892609, 18.46895027],
      [81.84430695, 18.48154068],
      [81.84958649, 18.48904991],
      [81.84896088, 18.50060081],
      [81.85302734, 18.50732994],
      [81.85794067, 18.51305962],
      [81.86296082, 18.51727295],
      [81.86805725, 18.51748276],
      [81.8707962, 18.51758957],
      [81.87361908, 18.51993942],
      [81.87538147, 18.5214119],
      [81.87867737, 18.52627945],
      [81.88259888, 18.52918053],
      [81.89607239, 18.52843094],
      [81.8968277, 18.52839279],
      [81.90029144, 18.53251839],
      [81.89865112, 18.53615952],
      [81.89794159, 18.53773117],
      [81.89763641, 18.54271126],
      [81.89975739, 18.54904175],
      [81.90080261, 18.55717087],
      [81.90859985, 18.56422806],
      [81.91316986, 18.56118965],
      [81.91639709, 18.559021],
      [81.92019653, 18.55730057],
      [81.92545319, 18.55768013],
      [81.92984772, 18.55800056],
      [81.93624115, 18.55770111],
      [81.94352722, 18.55541992],
      [81.94625854, 18.55841064],
      [81.94690704, 18.5591507],
      [81.94534302, 18.56245041],
      [81.943573, 18.56615829],
      [81.94576263, 18.57491112],
      [81.95452881, 18.58670998],
      [81.94888306, 18.58954048],
      [81.93847656, 18.59179115],
      [81.9330368, 18.59465027],
      [81.92971802, 18.59638977],
      [81.92623138, 18.60322952],
      [81.91303253, 18.6129303],
      [81.89993286, 18.61960983],
      [81.8883667, 18.62099075],
      [81.88729095, 18.62438965],
      [81.88780212, 18.63162994],
      [81.88820648, 18.6373806],
      [81.88931274, 18.64112854],
      [81.89000702, 18.64347076],
      [81.88537598, 18.65032959],
      [81.89317322, 18.65388107],
      [81.89483643, 18.65196037],
      [81.9048996, 18.64639282],
      [81.90914154, 18.6409893],
      [81.91552734, 18.63582039],
      [81.93557739, 18.63289833],
      [81.9474411, 18.62882042],
      [81.94832611, 18.63278961],
      [81.94608307, 18.64048958],
      [81.94592285, 18.64637947],
      [81.9473877, 18.65571022],
      [81.95163727, 18.66098022],
      [81.96063232, 18.66772079],
      [81.95507813, 18.68022919],
      [81.95716095, 18.68341064],
      [81.96386719, 18.68779945],
      [81.96768188, 18.69061279],
      [81.97603607, 18.69514847],
      [81.99186707, 18.69769096],
      [81.99772644, 18.7004509],
      [82.00998688, 18.7105999],
      [82.01561737, 18.71353149],
      [82.03373718, 18.71879959],
      [82.03949738, 18.71704102],
      [82.04943085, 18.7175808],
      [82.05175781, 18.71183968],
      [82.05799103, 18.71251106],
      [82.05912781, 18.71778107],
      [82.06128693, 18.71800995],
      [82.06739044, 18.71710968],
      [82.07398224, 18.7130909],
      [82.07858276, 18.71208191],
      [82.08048248, 18.72274017],
      [82.0848999, 18.73269081],
      [82.08674622, 18.74693108],
      [82.08141327, 18.75587082],
      [82.08470917, 18.75839996],
      [82.09129333, 18.75617981],
      [82.10572815, 18.75844955],
      [82.11392212, 18.76066017],
      [82.12906647, 18.75765038],
      [82.1281662, 18.76432037],
      [82.13066101, 18.77009964],
      [82.13897705, 18.76954079],
      [82.14547729, 18.77327919],
      [82.15001678, 18.77869987],
      [82.15170288, 18.78380013],
      [82.15921783, 18.78811073],
      [82.16114807, 18.79096031],
      [82.15891266, 18.79507065],
      [82.15429688, 18.7963028],
      [82.15611267, 18.79994011],
      [82.16101074, 18.80356979],
      [82.16184235, 18.80496025],
      [82.15867615, 18.80920029],
      [82.15830994, 18.81147003],
      [82.16493988, 18.83020973],
      [82.16175079, 18.83786011],
      [82.16242218, 18.8451004],
      [82.15802765, 18.85197067],
      [82.16126251, 18.8577404],
      [82.16279602, 18.86551094],
      [82.15820313, 18.86945915],
      [82.16310883, 18.87193108],
      [82.17041016, 18.87757111],
      [82.17076874, 18.89159012],
      [82.17272949, 18.89528847],
      [82.17714691, 18.89690018],
      [82.18538666, 18.89698029],
      [82.1987381, 18.90554047],
      [82.21378326, 18.91213989],
      [82.22486115, 18.90924835],
      [82.22767639, 18.9066906],
      [82.23121643, 18.90522003],
      [82.23429108, 18.90526962],
      [82.23956299, 18.91045952],
      [82.22666168, 18.99398041],
      [82.22506714, 19.01459122],
      [82.22248077, 19.02111053],
      [82.1940918, 19.06022072],
      [82.19683075, 19.06786919],
      [82.20855713, 19.07852173],
      [82.21122742, 19.08575821],
      [82.21211243, 19.09054947],
      [82.21058655, 19.09225082],
      [82.19986725, 19.09217834],
      [82.1985321, 19.09416962],
      [82.19948578, 19.09982109],
      [82.19689941, 19.10115051],
      [82.20149994, 19.11576271],
      [82.19975281, 19.11746025],
      [82.19101715, 19.11631012],
      [82.17623901, 19.12506104],
      [82.16982269, 19.11285973],
      [82.16480255, 19.11469078],
      [82.15978241, 19.1264801],
      [82.16117096, 19.12975121],
      [82.16655731, 19.13214111],
      [82.1681366, 19.13384056],
      [82.16471863, 19.13947105],
      [82.1658783, 19.14314079],
      [82.16990662, 19.14364052],
      [82.17198944, 19.14249992],
      [82.17478943, 19.14523125],
      [82.17238617, 19.14764977],
      [82.16500092, 19.15044975],
      [82.16413879, 19.15463829],
      [82.16760254, 19.15687943],
      [82.1714859, 19.15390968],
      [82.17471313, 19.15275002],
      [82.17684174, 19.15497971],
      [82.17878723, 19.15885925],
      [82.17823792, 19.16147995],
      [82.17298126, 19.16476059],
      [82.17112732, 19.16827011],
      [82.17516327, 19.16963959],
      [82.17459869, 19.17358017],
      [82.16940308, 19.17670059],
      [82.17339325, 19.18431091],
      [82.16478729, 19.18732071],
      [82.15804291, 19.19317818],
      [82.15570831, 19.19652939],
      [82.16117859, 19.20838928],
      [82.15376282, 19.21577835],
      [82.15164948, 19.22356987],
      [82.15196228, 19.23890114],
      [82.15099335, 19.25842285],
      [82.15172577, 19.26546288],
      [82.15487671, 19.27477837],
      [82.15836334, 19.28148079],
      [82.16853333, 19.29066086],
      [82.1700058, 19.29572105],
      [82.17008972, 19.3024807],
      [82.17368317, 19.31630135],
      [82.1831665, 19.31970024],
      [82.18025208, 19.33220291],
      [82.17749023, 19.33237076],
      [82.17320251, 19.33407974],
      [82.16895294, 19.33723068],
      [82.16314697, 19.33780289],
      [82.15722656, 19.34284019],
      [82.14955139, 19.34437943],
      [82.14234924, 19.34817123],
      [82.14225769, 19.35511017],
      [82.14083099, 19.3619194],
      [82.14118195, 19.36578178],
      [82.14456177, 19.37011909],
      [82.15080261, 19.37034035],
      [82.16636658, 19.36593056],
      [82.17398071, 19.37693977],
      [82.17777252, 19.40557289],
      [82.18348694, 19.41246033],
      [82.18305969, 19.41761017],
      [82.17925262, 19.41913986],
      [82.17045593, 19.42949104],
      [82.1672287, 19.42984962],
      [82.16069031, 19.42951965],
      [82.13410187, 19.42482758],
      [82.11930847, 19.4239006],
      [82.10679626, 19.44307137],
      [82.10716248, 19.45261192],
      [82.09689331, 19.46108055],
      [82.09986877, 19.46606064],
      [82.10182953, 19.47365952],
      [82.09902954, 19.48957062],
      [82.09101868, 19.49405861],
      [82.09207916, 19.50984001],
      [82.08822632, 19.51044273],
      [82.07084656, 19.51592064],
      [82.0671463, 19.51102066],
      [82.05979156, 19.50364113],
      [82.02239227, 19.50180244],
      [82.0302124, 19.51420975],
      [82.03246307, 19.52129173],
      [82.04102325, 19.52570152],
      [82.04257202, 19.53156853],
      [82.04612732, 19.5386219],
      [82.0430069, 19.5444603],
      [82.04042053, 19.55220985],
      [82.0426178, 19.55800056],
      [82.04413605, 19.56479836],
      [82.04323578, 19.56767082],
      [82.0422287, 19.57092094],
      [82.03779602, 19.5771904],
      [82.03489685, 19.58327293],
      [82.03395844, 19.59069061],
      [82.03727722, 19.59791946],
      [82.0450592, 19.60165024],
      [82.04471588, 19.61079025],
      [82.04847717, 19.61581039],
      [82.05134583, 19.62444115],
      [82.05082703, 19.63162041],
      [82.04728699, 19.63828087],
      [82.04638672, 19.64402962],
      [82.04830933, 19.64937019],
      [82.04603577, 19.65359116],
      [82.04766846, 19.66268921],
      [82.04677582, 19.66937065],
      [82.03848267, 19.69277954],
      [82.03756714, 19.70391083],
      [82.04160309, 19.70828819],
      [82.0437088, 19.71391106],
      [82.04255676, 19.72313118],
      [82.04425812, 19.72912979],
      [82.04400635, 19.73768997],
      [82.04659271, 19.74302101],
      [82.05719757, 19.75341034],
      [82.05541992, 19.7610302],
      [82.05898285, 19.77507019],
      [82.05938721, 19.77903938],
      [82.05982971, 19.78309822],
      [82.05219269, 19.79134178],
      [82.04827881, 19.78841019],
      [82.04444122, 19.78924942],
      [82.04107666, 19.79219055],
      [82.03494263, 19.79265976],
      [82.02906036, 19.79219055],
      [82.02419281, 19.79297829],
      [82.00818634, 19.79656982],
      [82.00305176, 19.79851913],
      [82.00289154, 19.7985096],
      [81.99932098, 19.79830933],
      [81.99237061, 19.79442978],
      [81.98741913, 19.79174042],
      [81.98014832, 19.79578018],
      [81.97020721, 19.81274033],
      [81.9614563, 19.82443237],
      [81.95741272, 19.83600998],
      [81.96037292, 19.85473824],
      [81.93942261, 19.86484146],
      [81.92447662, 19.86872292],
      [81.89805603, 19.87851906],
      [81.86924744, 19.89281082],
      [81.85840607, 19.89954948],
      [81.84963226, 19.90805054],
      [81.84580994, 19.91486168],
      [81.84567261, 19.92295265],
      [81.84883118, 19.938591],
      [81.84651184, 19.94144058],
      [81.83895111, 19.94549942],
      [81.83730316, 19.95008087],
      [81.84026337, 19.95513916],
      [81.84689331, 19.9703598],
      [81.85031891, 19.98260117],
      [81.85101318, 19.98975182],
      [81.85161591, 19.99605942],
      [81.85205078, 19.99715996],
      [81.85546112, 20.00352097],
      [81.85762024, 20.00908089],
      [81.85891724, 20.01637077],
      [81.86016846, 20.02336121],
      [81.86458588, 20.02864075],
      [81.86548615, 20.03388023],
      [81.86611176, 20.03518105],
      [81.866539, 20.03603935],
      [81.87375641, 20.04618073],
      [81.8762207, 20.04438972],
      [81.880867, 20.04241943],
      [81.88504028, 20.04171944],
      [81.8894577, 20.04327011],
      [81.8941803, 20.04824829],
      [81.90142822, 20.05912018],
      [81.91027069, 20.06705093],
      [81.91542816, 20.07382965],
      [81.92188263, 20.08694839],
      [81.92563629, 20.09011841],
      [81.93392944, 20.09930992],
      [81.93696594, 20.10115051],
      [81.94135284, 20.10225105],
      [81.94420624, 20.10160255],
      [81.95477295, 20.09148026],
      [81.96264648, 20.08556938],
      [81.97357178, 20.07561111],
      [81.97849274, 20.06917953],
      [81.98329926, 20.05702972],
      [81.98660278, 20.05207253],
      [81.9927063, 20.04782104],
      [81.99555206, 20.04753113],
      [82.00102997, 20.04926109],
      [82.00689697, 20.04733086],
      [82.00995636, 20.0447998],
      [82.01270294, 20.04103088],
      [82.014328, 20.0364399],
      [82.01449585, 20.03025055],
      [82.01551819, 20.02683067],
      [82.01478577, 20.01951027],
      [82.02440643, 20.01004028],
      [82.03070068, 20.01503944],
      [82.03156281, 20.01666069],
      [82.03102875, 20.02288055],
      [82.03875732, 20.02891922],
      [82.04897308, 20.03524017],
      [82.05789948, 20.04986954],
      [82.06980133, 20.04804039],
      [82.07630157, 20.04404068],
      [82.08304596, 20.03273964],
      [82.08943939, 20.03454971],
      [82.09319305, 20.03267288],
      [82.0952301, 20.02680016],
      [82.09773254, 20.02337074],
      [82.10273743, 20.02082825],
      [82.10547638, 20.01781273],
      [82.1160965, 20.01040077],
      [82.12142181, 20.00979042],
      [82.12841034, 20.01258087],
      [82.13169861, 20.01268959],
      [82.14834595, 20.00300026],
      [82.15557861, 20.0023098],
      [82.16583252, 19.99814987],
      [82.16607666, 19.99584007],
      [82.17578125, 19.98553085],
      [82.1784668, 19.97808266],
      [82.18418121, 19.98116112],
      [82.19381714, 19.98130989],
      [82.2042923, 19.98033905],
      [82.21058655, 19.99081039],
      [82.21425629, 19.99334145],
      [82.2194519, 19.9934597],
      [82.22438049, 19.99587059],
      [82.23062897, 19.99808121],
      [82.23335266, 19.99587059],
      [82.25271606, 19.97647858],
      [82.25863647, 19.97174072],
      [82.26169586, 19.97155952],
      [82.26864624, 19.95792961],
      [82.26946259, 19.94524956],
      [82.28333282, 19.91806984],
      [82.29532623, 19.89895821],
      [82.29823303, 19.883461],
      [82.31794739, 19.85915947],
      [82.32132721, 19.85191917],
      [82.32511902, 19.84049034],
      [82.32675171, 19.83833122],
      [82.33944702, 19.82996178],
      [82.35624695, 19.84662056],
      [82.35736084, 19.84950066],
      [82.35607147, 19.85513115],
      [82.35845184, 19.85688019],
      [82.36804962, 19.85448074],
      [82.37725067, 19.86296844],
      [82.37798309, 19.86869049],
      [82.38617706, 19.87684059],
      [82.38881683, 19.88175964],
      [82.39700317, 19.88454056],
      [82.40040588, 19.8888092],
      [82.41043854, 19.89340019],
      [82.41388702, 19.89652061],
      [82.42090607, 19.89833069],
      [82.42492676, 19.89760971],
      [82.43225861, 19.89948082],
      [82.43949127, 19.90291977],
      [82.44239044, 19.90298271],
      [82.45835114, 19.89464951],
      [82.46556091, 19.89657974],
      [82.46808624, 19.89948082],
      [82.47569275, 19.90052986],
      [82.48692322, 19.89653015],
      [82.49423981, 19.89735985],
      [82.497612, 19.8950882],
      [82.49848175, 19.88516998],
      [82.5022583, 19.88244057],
      [82.51241302, 19.88412094],
      [82.51795959, 19.87631035],
      [82.52305603, 19.87278938],
      [82.5298996, 19.88212967],
      [82.53636169, 19.87689018],
      [82.54428864, 19.86903],
      [82.54721832, 19.87330055],
      [82.5508728, 19.8745594],
      [82.55488586, 19.86950111],
      [82.56674957, 19.87172127],
      [82.57758331, 19.86343956],
      [82.59076691, 19.86621857],
      [82.59349823, 19.8645401],
      [82.59738159, 19.86046982],
      [82.59146881, 19.85243034],
      [82.58982086, 19.8481884],
      [82.58573151, 19.8463707],
      [82.58149719, 19.84049034],
      [82.58349609, 19.83832169],
      [82.57894135, 19.83662987],
      [82.57419586, 19.83197975],
      [82.57182312, 19.82780838],
      [82.57126617, 19.82238007],
      [82.57382965, 19.82370949],
      [82.57183838, 19.80760956],
      [82.57303619, 19.80555916],
      [82.57157898, 19.80200958],
      [82.57927704, 19.7972126],
      [82.57779694, 19.79232979],
      [82.57781219, 19.78780937],
      [82.5792923, 19.78466034],
      [82.57791138, 19.77959061],
      [82.57847595, 19.77581978],
      [82.5812912, 19.77269173],
      [82.58506012, 19.7710495],
      [82.59513855, 19.77306938],
      [82.59828186, 19.7751503],
      [82.59900665, 19.78127289],
      [82.60327911, 19.78355026],
      [82.60813141, 19.78512955],
      [82.61886597, 19.78610039],
      [82.62296295, 19.78931046],
      [82.62734222, 19.79615021],
      [82.62890625, 19.80376053],
      [82.6362915, 19.80799294],
      [82.64179993, 19.8137207],
      [82.64573669, 19.82537079],
      [82.65410614, 19.8255291],
      [82.66001892, 19.82372093],
      [82.66913605, 19.82520294],
      [82.68331909, 19.82372093],
      [82.6905365, 19.82575989],
      [82.69844055, 19.82686043],
      [82.70271301, 19.83103943],
      [82.70588684, 19.8380909],
      [82.70471191, 19.84460068],
      [82.71100616, 19.84856033],
      [82.71385956, 19.86209106],
      [82.71750641, 19.87080956],
      [82.70935059, 19.870121],
      [82.70636749, 19.87851906],
      [82.70269012, 19.88584137],
      [82.70053101, 19.89328957],
      [82.70147705, 19.90073013],
      [82.70040894, 19.90661049],
      [82.70587921, 19.91123009],
      [82.70426178, 19.91833115],
      [82.70632172, 19.92685127],
      [82.70649719, 19.93353081],
      [82.71061707, 19.93947029],
      [82.71156311, 19.9444809],
      [82.70944977, 19.94667053],
      [82.71092987, 19.98326111],
      [82.70748901, 19.99021912],
      [82.70063019, 19.99287033],
      [82.69802094, 19.99273109],
      [82.69230652, 19.9924202],
      [82.68644714, 19.99463081],
      [82.68102264, 19.99545097],
      [82.65048218, 19.99940109],
      [82.64189911, 19.99790955],
      [82.63625336, 19.99829102],
      [82.63175964, 20.00008965],
      [82.62358093, 19.99764061],
      [82.62088776, 19.99555969],
      [82.61875916, 19.99156952],
      [82.61438751, 19.9869709],
      [82.59836578, 19.98563957],
      [82.58235931, 19.98994064],
      [82.57872009, 19.9956398],
      [82.57594299, 19.99895287],
      [82.54361725, 20.01213074],
      [82.53198242, 20.01222992],
      [82.52424622, 20.01044083],
      [82.5021286, 20.01353073],
      [82.49006653, 20.01857185],
      [82.48159027, 20.02456093],
      [82.47590637, 20.03005028],
      [82.47077179, 20.03202057],
      [82.46409607, 20.03154945],
      [82.45832825, 20.0338707],
      [82.45149994, 20.03526115],
      [82.44142914, 20.03535271],
      [82.43192291, 20.03897095],
      [82.4247818, 20.04354095],
      [82.41713715, 20.04534912],
      [82.40618896, 20.04631042],
      [82.39576721, 20.04899979],
      [82.39086151, 20.05958939],
      [82.39034271, 20.06423187],
      [82.39260101, 20.07331085],
      [82.39466858, 20.08916092],
      [82.39264679, 20.10824966],
      [82.39073944, 20.11905098],
      [82.38775635, 20.12693977],
      [82.38658142, 20.13338089],
      [82.38228607, 20.13817024],
      [82.37892914, 20.14463043],
      [82.38719177, 20.14885139],
      [82.38330078, 20.16045952],
      [82.39048767, 20.16731262],
      [82.40574646, 20.17898941],
      [82.40763092, 20.19409943],
      [82.41359711, 20.20227051],
      [82.41308594, 20.20795059],
      [82.41365051, 20.21327972],
      [82.41277313, 20.21744919],
      [82.40818787, 20.22063065],
      [82.40727997, 20.22764015],
      [82.40432739, 20.23960114],
      [82.40837097, 20.24166107],
      [82.41407776, 20.24155045],
      [82.40537262, 20.25587273],
      [82.4050827, 20.26353073],
      [82.412323, 20.26990128],
      [82.42082214, 20.27260017],
      [82.42804718, 20.27861023],
      [82.42922974, 20.2829628],
      [82.4283371, 20.28986168],
      [82.42395782, 20.29650116],
      [82.42243958, 20.30965996],
      [82.41629791, 20.31695938],
      [82.40717316, 20.32207108],
      [82.40045929, 20.32467079],
      [82.40029907, 20.32925034],
      [82.39878845, 20.33165169],
      [82.39377594, 20.3355217],
      [82.39441681, 20.34013748],
      [82.39795685, 20.34677124],
      [82.39794159, 20.35585976],
      [82.40251923, 20.36133003],
      [82.40307617, 20.36526108],
      [82.41040802, 20.37720108],
      [82.41060638, 20.38247108],
      [82.41274261, 20.38824272],
      [82.40989685, 20.40267944],
      [82.41768646, 20.4056282],
      [82.42428589, 20.41015053],
      [82.42295837, 20.41833115],
      [82.4291687, 20.42575264],
      [82.427742, 20.43206978],
      [82.42565155, 20.43631935],
      [82.41771698, 20.43830109],
      [82.41940308, 20.44557953],
      [82.41938782, 20.45728111],
      [82.41609955, 20.4661808],
      [82.41007996, 20.47330093],
      [82.40640259, 20.46614075],
      [82.39731598, 20.45191956],
      [82.38809204, 20.45251083],
      [82.39006805, 20.45939064],
      [82.38960266, 20.46536064],
      [82.38513947, 20.4704895],
      [82.38613129, 20.47552109],
      [82.38375092, 20.48266029],
      [82.38123322, 20.50180054],
      [82.38131714, 20.50777054],
      [82.38049316, 20.50984955],
      [82.36994934, 20.51394081],
      [82.35851288, 20.51623917],
      [82.34879303, 20.52293015],
      [82.34224701, 20.53769112],
      [82.33609009, 20.54323959],
      [82.32775879, 20.54927063],
      [82.32401276, 20.55388069],
      [82.32446289, 20.55664825],
      [82.32637787, 20.55657959],
      [82.3274765, 20.55789757],
      [82.32782745, 20.56196022],
      [82.33235168, 20.56227112],
      [82.33715057, 20.56517982],
      [82.34063721, 20.56973267],
      [82.3425293, 20.57522011],
      [82.33766937, 20.58493042],
      [82.33837128, 20.59082985],
      [82.33867645, 20.5943203],
      [82.3454895, 20.59790039],
      [82.34799957, 20.6016407],
      [82.35582733, 20.60349083],
      [82.35785675, 20.60921097],
      [82.35665894, 20.61136055],
      [82.35817719, 20.61516953],
      [82.36633301, 20.6202507],
      [82.36823273, 20.62405968],
      [82.36754608, 20.62957001],
      [82.36380005, 20.6395607],
      [82.36225128, 20.65047073],
      [82.36580658, 20.66114998],
      [82.36522675, 20.66376114],
      [82.36068726, 20.67191124],
      [82.35428619, 20.6750412],
      [82.35131836, 20.67807007],
      [82.3455658, 20.69491005],
      [82.34329987, 20.69885254],
      [82.34426117, 20.7095108],
      [82.34699249, 20.71423912],
      [82.33940887, 20.72583008],
      [82.34586334, 20.72871971],
      [82.3404007, 20.74671936],
      [82.33512878, 20.75851822],
      [82.33692932, 20.77930069],
      [82.33879089, 20.79017067],
      [82.33567047, 20.79737282],
      [82.33310699, 20.8196907],
      [82.3355484, 20.82783127],
      [82.33403778, 20.84058952],
      [82.33687592, 20.8492527],
      [82.34121704, 20.86806107],
      [82.34661865, 20.88384819],
      [82.35917664, 20.8827095],
      [82.35617828, 20.87635994],
      [82.35591888, 20.8699398],
      [82.35903931, 20.86654091],
      [82.36801147, 20.8640995],
      [82.37667084, 20.86692047],
      [82.38279724, 20.8699398],
      [82.40141296, 20.86243057],
      [82.40246582, 20.85795975],
      [82.40225983, 20.85328102],
      [82.40727997, 20.84802055],
      [82.40737152, 20.84349823],
      [82.40563202, 20.8372097],
      [82.4134903, 20.82956123],
      [82.41552734, 20.82646942],
      [82.42294312, 20.82600021],
      [82.43650055, 20.82953072],
      [82.43939209, 20.8271904],
      [82.44108582, 20.82376289],
      [82.44465637, 20.82406998],
      [82.44837189, 20.82217979],
      [82.45350647, 20.8212204],
      [82.45905304, 20.82384109],
      [82.46246338, 20.8275795],
      [82.46231079, 20.83035088],
      [82.45983124, 20.83564949],
      [82.46338654, 20.83967018],
      [82.46826172, 20.84263039],
      [82.47762299, 20.84671974],
      [82.48192596, 20.85531044],
      [82.48136902, 20.86145973],
      [82.48452759, 20.87289047],
      [82.48467255, 20.87955093],
      [82.48313904, 20.8996582],
      [82.485672, 20.90351105],
      [82.49363708, 20.90934944],
      [82.50309753, 20.91389084],
      [82.51344299, 20.91721916],
      [82.51924133, 20.92469025],
      [82.52625275, 20.92770958],
      [82.53585052, 20.92952156],
      [82.54573822, 20.93494034],
      [82.55294037, 20.94148064],
      [82.56651306, 20.96700096],
      [82.57813263, 20.97894096],
      [82.58207703, 20.98677826],
      [82.59514618, 20.99712944],
      [82.60444641, 21.00587273],
      [82.60772705, 21.01216125],
      [82.60861969, 21.01819992],
      [82.62230682, 21.0365696],
      [82.62003326, 21.04439926],
      [82.60912323, 21.06392097],
      [82.60938263, 21.07119751],
      [82.61524963, 21.07517052],
      [82.62194061, 21.07520103],
      [82.62587738, 21.07682037],
      [82.63183594, 21.08695984],
      [82.63536072, 21.09198952],
      [82.64299774, 21.09776115],
      [82.64502716, 21.10187912],
      [82.63833618, 21.10647011],
      [82.63710022, 21.11161995],
      [82.63955688, 21.11700058],
      [82.64531708, 21.12066841],
      [82.64775848, 21.12496185],
      [82.6446228, 21.12881088],
      [82.6383667, 21.13161278],
      [82.63408661, 21.13635063],
      [82.63279724, 21.14266014],
      [82.63594055, 21.1494503],
      [82.64582825, 21.15171051],
      [82.66204834, 21.15075111],
      [82.68453217, 21.15806007],
      [82.6919632, 21.15685081],
      [82.69403839, 21.15280151],
      [82.70053864, 21.15326118],
      [82.70553589, 21.15489006],
      [82.7114563, 21.15576935],
      [82.72115326, 21.15894127],
      [82.72724152, 21.15919113],
      [82.73535156, 21.15787125],
      [82.75229645, 21.15974045],
      [82.75752258, 21.1585598],
      [82.78208923, 21.14101982],
      [82.78884888, 21.13927269],
      [82.79176331, 21.14511108],
      [82.79559326, 21.1505909],
      [82.80381012, 21.15307045],
      [82.80684662, 21.15664101],
      [82.81247711, 21.1585598],
      [82.83999634, 21.1633606],
      [82.84712982, 21.16268158],
      [82.84677124, 21.1581707],
      [82.85402679, 21.15700912],
      [82.85949707, 21.15730286],
      [82.86367035, 21.15538025],
      [82.86882782, 21.15974998],
      [82.87285614, 21.1510582],
      [82.87922668, 21.14908981],
      [82.88741302, 21.15377235],
      [82.8951416, 21.15982056],
      [82.90231323, 21.16296959],
      [82.90795898, 21.1602726],
      [82.91442108, 21.16064072],
      [82.92192841, 21.15821075],
      [82.9280014, 21.16103172],
      [82.93739319, 21.16114807],
      [82.94232941, 21.16304016],
      [82.9489975, 21.16977119],
      [82.95606995, 21.17548943],
      [82.95964813, 21.17385101],
      [82.96363831, 21.16167068],
      [82.96514893, 21.15575981],
      [82.97161102, 21.15631104],
      [82.97992706, 21.15746117],
      [82.98342133, 21.14826965],
      [82.99297333, 21.15427971],
      [82.99912262, 21.14813995],
      [83.00554657, 21.14583015],
      [83.00772858, 21.14653015],
      [83.01048279, 21.14537048],
      [83.01589203, 21.13972092],
      [83.01862335, 21.13817978],
      [83.02954102, 21.13132286],
      [83.02908325, 21.12602997],
      [83.03275299, 21.12436295],
      [83.03643036, 21.1212101],
      [83.04058075, 21.11893082],
      [83.04682922, 21.12021065],
      [83.05139923, 21.11717033],
      [83.05976105, 21.11591148],
      [83.06436157, 21.11110115],
      [83.07144165, 21.10807037],
      [83.08156586, 21.1067009],
      [83.08870697, 21.10745049],
      [83.09208679, 21.10597038],
      [83.0951767, 21.1043129],
      [83.10827637, 21.10770035],
      [83.11984253, 21.10106087],
      [83.13400269, 21.10523033],
      [83.13771057, 21.1098175],
      [83.14875031, 21.11987114],
      [83.15339661, 21.12577057],
      [83.16139221, 21.13145065],
      [83.16986847, 21.13151932],
      [83.17662048, 21.13074112],
      [83.18297577, 21.13204956],
      [83.19010162, 21.13552856],
      [83.19274902, 21.13944054],
      [83.19373322, 21.1576519],
      [83.1993866, 21.16197014],
      [83.20007324, 21.16889954],
      [83.19953156, 21.17404938],
      [83.20201874, 21.17743111],
      [83.20520782, 21.18404007],
      [83.2100296, 21.1880703],
      [83.21121216, 21.19118118],
      [83.20757294, 21.20139122],
      [83.21536255, 21.21549034],
      [83.21438599, 21.22058105],
      [83.21520996, 21.22369194],
      [83.21906281, 21.22371101],
      [83.21469879, 21.24041939],
      [83.21414185, 21.25086975],
      [83.21835327, 21.26012039],
      [83.22489929, 21.26696968],
      [83.23187256, 21.26534081],
      [83.2424469, 21.27643967],
      [83.24836731, 21.27730179],
      [83.25730133, 21.27555084],
      [83.26275635, 21.27260971],
      [83.26767731, 21.26857758],
      [83.2713089, 21.28050041],
      [83.27455902, 21.28567123],
      [83.27480316, 21.29057121],
      [83.27268219, 21.29613113],
      [83.25879669, 21.30212975],
      [83.25409698, 21.31237984],
      [83.25421143, 21.31958961],
      [83.26085663, 21.32674026],
      [83.25578308, 21.32932281],
      [83.25495911, 21.33192062],
      [83.26064301, 21.33717918],
      [83.26609039, 21.34543037],
      [83.27078247, 21.35923958],
      [83.26951599, 21.36721039],
      [83.27001953, 21.37503815],
      [83.27614594, 21.37592125],
      [83.2813797, 21.37514114],
      [83.2872467, 21.3714695],
      [83.30164337, 21.36783981],
      [83.3095932, 21.36456108],
      [83.31933594, 21.35814285],
      [83.33460236, 21.35579109],
      [83.37481689, 21.3399601],
      [83.37663269, 21.34329987],
      [83.3879776, 21.33893967],
      [83.39385986, 21.34208107],
      [83.40265656, 21.34843063],
      [83.40444946, 21.35511971],
      [83.4018631, 21.3583107],
      [83.39415741, 21.3611412],
      [83.39317322, 21.36519051],
      [83.3963089, 21.36927032],
      [83.39655304, 21.37227058],
      [83.39308929, 21.38321114],
      [83.39376068, 21.39944267],
      [83.38967133, 21.40166283],
      [83.38140869, 21.40957832],
      [83.37393951, 21.42222023],
      [83.37137604, 21.42835999],
      [83.37014008, 21.43610001],
      [83.36579132, 21.43984032],
      [83.36094666, 21.44218063],
      [83.35028839, 21.44398117],
      [83.34732819, 21.45514107],
      [83.34335327, 21.46488953],
      [83.34197998, 21.47413063],
      [83.34205627, 21.48533058],
      [83.33561707, 21.49191093],
      [83.33463287, 21.49555016],
      [83.33589935, 21.49963951],
      [83.3377533, 21.50263023],
      [83.3458786, 21.50823975],
      [83.34896851, 21.51596069],
      [83.35426331, 21.53531837],
      [83.36577606, 21.54968071],
      [83.36692047, 21.55302048],
      [83.36686707, 21.55931282],
      [83.3633194, 21.56777954],
      [83.36232758, 21.57252121],
      [83.36818695, 21.59707069],
      [83.37725067, 21.60467911],
      [83.38072205, 21.61275101],
      [83.38683319, 21.61536026],
      [83.38981628, 21.62054062],
      [83.39524078, 21.62419128],
      [83.40180969, 21.62675095],
      [83.40518951, 21.63181114],
      [83.40862274, 21.63420105],
      [83.41352844, 21.63631058],
      [83.42405701, 21.63848114],
      [83.42650604, 21.64353943],
      [83.44123077, 21.64927292],
      [83.44757843, 21.64459991],
      [83.44139099, 21.63246155],
      [83.44048309, 21.62669945],
      [83.44142151, 21.62502289],
      [83.44133759, 21.61809158],
      [83.44993591, 21.6169796],
      [83.45001221, 21.61339951],
      [83.44872284, 21.60822105],
      [83.45279694, 21.60732269],
      [83.45275879, 21.60519028],
      [83.46296692, 21.60551071],
      [83.46990204, 21.62677002],
      [83.4765625, 21.62729263],
      [83.48454285, 21.62556267],
      [83.48647308, 21.63317108],
      [83.4833374, 21.6371727],
      [83.483078, 21.63960075],
      [83.47477722, 21.64121819],
      [83.46621704, 21.64412117],
      [83.44077301, 21.65517044],
      [83.43535614, 21.66312027],
      [83.4286499, 21.66889],
      [83.4197464, 21.67502022],
      [83.42111206, 21.68654823],
      [83.42488098, 21.68901062],
      [83.42796326, 21.69232941],
      [83.43183136, 21.6939106],
      [83.4407959, 21.69338989],
      [83.44970703, 21.69095993],
      [83.45633698, 21.69432068],
      [83.45762634, 21.69967079],
      [83.46160889, 21.70627975],
      [83.46692657, 21.71056938],
      [83.47801971, 21.7279911],
      [83.47986603, 21.7341404],
      [83.48413086, 21.74155235],
      [83.47733307, 21.74588966],
      [83.47390747, 21.7496109],
      [83.47541809, 21.75341034],
      [83.47902679, 21.75869942],
      [83.47789001, 21.76459122],
      [83.47592163, 21.77379036],
      [83.46717834, 21.78273964],
      [83.47429657, 21.78614998],
      [83.48404694, 21.79434013],
      [83.48545837, 21.80322075],
      [83.49133301, 21.80866051],
      [83.49091339, 21.81454086],
      [83.49771881, 21.80618095],
      [83.50614166, 21.80039978],
      [83.51412201, 21.79780006],
      [83.52218628, 21.79411125],
      [83.53656769, 21.79393005],
      [83.5357666, 21.79896164],
      [83.53866577, 21.80061913],
      [83.53478241, 21.80681992],
      [83.53639984, 21.81834984],
      [83.52765656, 21.82299042],
      [83.53124237, 21.83189964],
      [83.54094696, 21.83465958],
      [83.54853821, 21.83523178],
      [83.55745697, 21.83440971],
      [83.56453705, 21.83036995],
      [83.5735321, 21.82966042],
      [83.57489777, 21.83260155],
      [83.57948303, 21.83712006],
      [83.58447266, 21.84360123],
      [83.5859375, 21.84762955],
      [83.58405304, 21.85279274],
      [83.57151794, 21.87187004],
      [83.57330322, 21.87636948],
      [83.57810974, 21.87978935],
      [83.57670593, 21.8861599],
      [83.57988739, 21.88925171],
      [83.58333588, 21.89096069],
      [83.58319855, 21.89406967],
      [83.58151245, 21.89743042],
      [83.58325195, 21.89856911],
      [83.59263611, 21.90075111],
      [83.59931183, 21.90600014],
      [83.60047913, 21.91084099],
      [83.58776855, 21.92612076],
      [83.55335999, 21.9321804],
      [83.55397034, 21.9362812],
      [83.55771637, 21.9439106],
      [83.54949188, 21.95038986],
      [83.53517151, 21.96350098],
      [83.53447723, 21.97624969],
      [83.53544617, 21.9796505],
      [83.54010773, 21.98533058],
      [83.54083252, 21.99323273],
      [83.543396, 21.99436951],
      [83.54663849, 21.99827957],
      [83.54496765, 21.99938965],
      [83.54673767, 22.00335121],
      [83.5485611, 22.00670242],
      [83.53885651, 22.01437187],
      [83.53399658, 22.02227974],
      [83.5326767, 22.02863121],
      [83.53244781, 22.03404808],
      [83.53414917, 22.04343987],
      [83.53836823, 22.05537987],
      [83.5430069, 22.05939102],
      [83.55095673, 22.05564117],
      [83.56562805, 22.0567894],
      [83.58351135, 22.05495071],
      [83.58595276, 22.0555706],
      [83.59056854, 22.05934143],
      [83.60031891, 22.0655098],
      [83.58917999, 22.07635117],
      [83.58306885, 22.08049011],
      [83.57369995, 22.08880043],
      [83.56482697, 22.09397125],
      [83.55892181, 22.0954895],
      [83.55726624, 22.0999794],
      [83.55916595, 22.10386086],
      [83.56575775, 22.11285019],
      [83.56730652, 22.1137085],
      [83.59253693, 22.13998032],
      [83.60233307, 22.1518383],
      [83.60594177, 22.15760994],
      [83.61183929, 22.17714119],
      [83.61927032, 22.19635963],
      [83.62628174, 22.20585823],
      [83.64575195, 22.22458839],
      [83.65457153, 22.2238903],
      [83.65744019, 22.22479057],
      [83.66461945, 22.2319603],
      [83.67273712, 22.2374382],
      [83.67904663, 22.2406311],
      [83.69300079, 22.24550056],
      [83.70043182, 22.24588013],
      [83.71898651, 22.2432518],
      [83.74565125, 22.24122047],
      [83.75279999, 22.24286079],
      [83.7568512, 22.25037956],
      [83.76180267, 22.25415039],
      [83.76583862, 22.25601959],
      [83.77131653, 22.25733948],
      [83.77471924, 22.26733971],
      [83.77855682, 22.27450943],
      [83.78862762, 22.28025055],
      [83.79078674, 22.28464127],
      [83.79364777, 22.28769112],
      [83.79751587, 22.28948975],
      [83.79798889, 22.28996277],
      [83.80478668, 22.28988075],
      [83.81598663, 22.29120255],
      [83.81944275, 22.29245186],
      [83.82080841, 22.29603958],
      [83.82010651, 22.30508232],
      [83.82065582, 22.31025124],
      [83.82248688, 22.31563759],
      [83.82520294, 22.31962967],
      [83.82940674, 22.32394028],
      [83.84303284, 22.32634926],
      [83.84757996, 22.33083916],
      [83.85330963, 22.33880043],
      [83.86100769, 22.34378052],
      [83.86414337, 22.34361839],
      [83.86714935, 22.34102058],
      [83.87194824, 22.33893013],
      [83.89697266, 22.33968925],
      [83.9053421, 22.35261154],
      [83.9155426, 22.35967064],
      [83.91929626, 22.35978127],
      [83.92402649, 22.35749054],
      [83.92958069, 22.35628128],
      [83.9432373, 22.35845947],
      [83.95231628, 22.36120987],
      [83.95661163, 22.3610611],
      [83.96698761, 22.35784912],
      [83.97820282, 22.36510849],
      [83.98536682, 22.36637115],
      [83.99297333, 22.36923981],
      [83.99739838, 22.36853981],
      [84.00119019, 22.36967087],
      [84.00421143, 22.36766052],
      [84.00167847, 22.37585068],
      [84.00166321, 22.38036919],
      [84.00898743, 22.39139938],
      [84.00994873, 22.39608955],
      [84.01248932, 22.39889908],
      [84.01444244, 22.40409279],
      [84.01875305, 22.40678024],
      [84.02216339, 22.41016006],
      [84.02599335, 22.41909027],
      [84.02398682, 22.42218018],
      [84.03270721, 22.42603111],
      [84.04000854, 22.43289948],
      [84.04139709, 22.43792915],
      [84.03951263, 22.44872284],
      [84.04155731, 22.46410942],
      [84.03872681, 22.46888161],
      [84.03465271, 22.46989822],
      [84.0238266, 22.47071075],
      [84.02168274, 22.47205925],
      [84.01548767, 22.48242188],
      [84.01302338, 22.48524094],
      [83.99707794, 22.4907608],
      [83.99298096, 22.49398041],
      [83.990448, 22.49439049],
      [83.98069, 22.49873161],
      [83.97609711, 22.50221062],
      [83.97384644, 22.50913811],
      [83.97445679, 22.51398087],
      [83.97657013, 22.51839066],
      [83.98010254, 22.52201271],
      [83.98584747, 22.52548027],
      [83.99298096, 22.52737808],
      [83.99429321, 22.5288105],
      [84.00250244, 22.52086067],
      [84.00963593, 22.52029037],
      [84.01554108, 22.52168083],
      [84.01914978, 22.52107048],
      [84.02472687, 22.51621246],
      [84.0330658, 22.5120697],
      [84.05065918, 22.51235962],
      [84.05758667, 22.51015091],
      [84.06047821, 22.50502014],
      [84.06668854, 22.50223923],
      [84.06900024, 22.49954987],
      [84.0670929, 22.49812126],
      [84.07164001, 22.49419022],
      [84.07920837, 22.48885155],
      [84.0877533, 22.48493004],
      [84.0998764, 22.48033905],
      [84.1029129, 22.48129082],
      [84.10347748, 22.47933006],
      [84.10211945, 22.47731018],
      [84.10611725, 22.47384834],
      [84.117836, 22.47142029],
      [84.12557983, 22.47359276],
      [84.12914276, 22.47348976],
      [84.13069153, 22.47001076],
      [84.13492584, 22.47094917],
      [84.13743591, 22.46710014],
      [84.13751984, 22.45718956],
      [84.13522339, 22.4490509],
      [84.13310242, 22.44531059],
      [84.13587952, 22.42068291],
      [84.14229584, 22.41539955],
      [84.15248871, 22.4009304],
      [84.17516327, 22.38894081],
      [84.18344116, 22.38368034],
      [84.19410706, 22.37181091],
      [84.22612762, 22.37412071],
      [84.24046326, 22.3744812],
      [84.24655914, 22.3737011],
      [84.25141144, 22.37041092],
      [84.26088715, 22.35370064],
      [84.26616669, 22.34782982],
      [84.27324677, 22.34509277],
      [84.28501892, 22.34211159],
      [84.28922272, 22.33732033],
      [84.31461334, 22.33892059],
      [84.31874847, 22.34049988],
      [84.32396698, 22.33892059],
      [84.333992, 22.34291077],
      [84.33838654, 22.34209824],
      [84.34496307, 22.34404945],
      [84.35328674, 22.34510994],
      [84.35909271, 22.34504128],
      [84.36171722, 22.34852982],
      [84.36741638, 22.34956932],
      [84.37200165, 22.34905052],
      [84.37758636, 22.34590912],
      [84.38786316, 22.34642029],
      [84.3944931, 22.34385109],
      [84.41911316, 22.35077095],
      [84.42608643, 22.3488102],
      [84.42584229, 22.3520298],
      [84.43144226, 22.36001015],
      [84.42719269, 22.36198044],
      [84.42694092, 22.36465073],
      [84.44207764, 22.37302017],
      [84.44785309, 22.37907982],
      [84.45565033, 22.37522125],
      [84.47280884, 22.38269043],
      [84.47759247, 22.38368034],
      [84.48020935, 22.38723946],
      [84.47572327, 22.39018822],
      [84.47595978, 22.39353943],
      [84.47447968, 22.40173149],
      [84.47666931, 22.40598106],
      [84.48126984, 22.40569115],
      [84.50179291, 22.41531944],
      [84.50582886, 22.41620064],
      [84.52101135, 22.41489029],
      [84.52662659, 22.42002106],
      [84.54065704, 22.42058945],
      [84.54329681, 22.41719818],
      [84.57215881, 22.42301178],
      [84.58907318, 22.42537117],
      [84.59395599, 22.42884064],
      [84.5953064, 22.4249382],
      [84.61364746, 22.42757988],
      [84.6255188, 22.42758942],
      [84.63210297, 22.4286499],
      [84.66160583, 22.41391945],
      [84.66934967, 22.41629982],
      [84.67282104, 22.41493034],
      [84.6815033, 22.41492081],
      [84.69149017, 22.41251183],
      [84.7113266, 22.41390038],
      [84.72614288, 22.41664124],
      [84.74385071, 22.41475105],
      [84.75269318, 22.44201279],
      [84.77805328, 22.44354057],
      [84.79128265, 22.4428196],
      [84.80124664, 22.44371033],
      [84.80779266, 22.44686127],
      [84.81047058, 22.44125175],
      [84.81047821, 22.43522835],
      [84.81269073, 22.43161011],
      [84.82842255, 22.42917061],
      [84.83162689, 22.4299202],
      [84.83254242, 22.42637825],
      [84.83574677, 22.42755127],
      [84.83946991, 22.43403053],
      [84.84136963, 22.43486977],
      [84.84780121, 22.43441963],
      [84.85534668, 22.43197823],
      [84.86371613, 22.43453026],
      [84.87124634, 22.43822289],
      [84.87525177, 22.44117928],
      [84.87652588, 22.44325066],
      [84.87779999, 22.4425106],
      [84.87947083, 22.43219948],
      [84.88150024, 22.42943954],
      [84.88198853, 22.42624855],
      [84.88024902, 22.41759109],
      [84.89379883, 22.41801071],
      [84.90770721, 22.41963959],
      [84.91639709, 22.4233799],
      [84.91986847, 22.42599106],
      [84.91815186, 22.42856979],
      [84.91739655, 22.43437958],
      [84.92533875, 22.43660927],
      [84.93136597, 22.43964958],
      [84.94077301, 22.44742966],
      [84.94728088, 22.45108032],
      [84.95244598, 22.45277023],
      [84.95336151, 22.4455204],
      [84.95243835, 22.43944931],
      [84.95458221, 22.4389019],
      [84.95728302, 22.44185829],
      [84.96024323, 22.44371986],
      [84.97779083, 22.45092964],
      [84.994133, 22.45827103],
      [85.00049591, 22.45836067],
      [85.02157593, 22.46710968],
      [85.02810669, 22.46820259],
      [85.03156281, 22.46978188],
      [85.03330994, 22.47692108],
      [85.03544617, 22.47729111],
      [85.03733063, 22.47508049],
      [85.04223633, 22.47694016],
      [85.04672241, 22.47739029],
      [85.05139923, 22.47941971],
      [85.06249237, 22.47841072],
      [85.07319641, 22.47286987],
      [85.07363892, 22.46668053],
      [85.07093811, 22.46116066],
      [85.07110596, 22.45590019],
      [85.06048584, 22.44951057],
      [85.05703735, 22.44470978],
      [85.05815125, 22.4397316],
      [85.06289673, 22.43608284],
      [85.06369019, 22.42322159],
      [85.06809235, 22.41806984],
      [85.07459259, 22.41345978],
      [85.07726288, 22.40830994],
      [85.07720184, 22.4030304],
      [85.08068085, 22.39546013],
      [85.08307648, 22.37844086],
      [85.07788086, 22.3691597],
      [85.07504272, 22.36096954],
      [85.07549286, 22.35577965],
      [85.07405853, 22.34859085],
      [85.07990265, 22.34439087],
      [85.08216095, 22.34132957],
      [85.09295654, 22.34109116],
      [85.09046173, 22.32487106],
      [85.08590698, 22.31698036],
      [85.08795929, 22.31408119],
      [85.09512329, 22.31239128],
      [85.10122681, 22.31432915],
      [85.10772705, 22.31523132],
      [85.11403656, 22.3142128],
      [85.11218262, 22.30507088],
      [85.11483765, 22.30038071],
      [85.11445618, 22.2904892],
      [85.10836029, 22.28947258],
      [85.10529327, 22.2911396],
      [85.09857178, 22.28792],
      [85.08235168, 22.27770805],
      [85.07051086, 22.27215958],
      [85.06941986, 22.26758194],
      [85.06990051, 22.26029015],
      [85.07431793, 22.25094032],
      [85.07206726, 22.24571991],
      [85.0681076, 22.24344063],
      [85.0694809, 22.23064995],
      [85.06253815, 22.21615982],
      [85.05879974, 22.20595169],
      [85.04692078, 22.18579292],
      [85.04960632, 22.17930984],
      [85.04779053, 22.17429924],
      [85.0414505, 22.17066002],
      [85.03322601, 22.1608429],
      [85.02802277, 22.15735817],
      [85.02539825, 22.15400124],
      [85.02619934, 22.14845085],
      [85.03031158, 22.14246178],
      [85.03115082, 22.13735962],
      [85.02735138, 22.1225605],
      [85.02429199, 22.11794281],
      [85.02374268, 22.11199188],
      [85.0194931, 22.10839081],
      [84.99362946, 22.09794807],
      [84.98586273, 22.09256935],
      [84.9834671, 22.09148979],
      [84.98049927, 22.09126091],
      [84.97982025, 22.08468056],
      [84.98104095, 22.08242035],
      [84.98742676, 22.07673836],
      [84.99359894, 22.07720947],
      [85.00231934, 22.0799427],
      [85.00720215, 22.08312988],
      [85.00901794, 22.09065056],
      [85.0140686, 22.09370995],
      [85.01995087, 22.10337067],
      [85.0271225, 22.10113907],
      [85.03118134, 22.10247993],
      [85.03513336, 22.1081295],
      [85.04389954, 22.11070061],
      [85.06945038, 22.10712814],
      [85.07623291, 22.10297012],
      [85.08409882, 22.10043907],
      [85.0954361, 22.09945107],
      [85.09900665, 22.09612083],
      [85.09970856, 22.08453941],
      [85.10447693, 22.08278084],
      [85.10897064, 22.0800209],
      [85.1102829, 22.07586288],
      [85.11329651, 22.07107925],
      [85.11888123, 22.0664711],
      [85.12539673, 22.06912041],
      [85.13506317, 22.07114029],
      [85.14083862, 22.07355118],
      [85.14891052, 22.07263947],
      [85.1555481, 22.07045937],
      [85.1578064, 22.06698036],
      [85.15896606, 22.06192017],
      [85.16374969, 22.0529995],
      [85.16720581, 22.0486908],
      [85.17080688, 22.04819107],
      [85.16983032, 22.05310059],
      [85.16436768, 22.06522942],
      [85.16960907, 22.06609917],
      [85.17246246, 22.06818962],
      [85.17675018, 22.06671906],
      [85.17913055, 22.06479073],
      [85.18345642, 22.05492973],
      [85.18699646, 22.05273056],
      [85.19232178, 22.05115128],
      [85.21074677, 22.04318047],
      [85.21460724, 22.03728104],
      [85.21389771, 22.02716064],
      [85.21383667, 22.01628113],
      [85.21488953, 22.01071167],
      [85.21835327, 22.00667],
      [85.22212982, 22.00344849],
      [85.23094177, 22.00027084],
      [85.24105835, 22.01251984],
      [85.24652863, 22.0254097],
      [85.25609589, 22.04245949],
      [85.25998688, 22.05285263],
      [85.26545715, 22.06025124],
      [85.26995087, 22.06896019],
      [85.27416229, 22.08008957],
      [85.28089905, 22.08764076],
      [85.28742218, 22.0931797],
      [85.30007935, 22.10705948],
      [85.30970001, 22.11338997],
      [85.31536865, 22.11584091],
      [85.32301331, 22.12499046],
      [85.33850098, 22.12471962],
      [85.34536743, 22.12699127],
      [85.35356903, 22.12057114],
      [85.35813141, 22.1196003],
      [85.36138153, 22.12144089],
      [85.36171722, 22.12475967],
      [85.35865784, 22.13280106],
      [85.35807037, 22.1379509],
      [85.36292267, 22.14657974],
      [85.36242676, 22.15004921],
      [85.36319733, 22.15456963],
      [85.36937714, 22.15521049],
      [85.37217712, 22.15244102],
      [85.37931824, 22.15168953],
      [85.38536835, 22.15234184],
      [85.39035034, 22.15513039],
      [85.3944397, 22.15859985],
      [85.40236664, 22.15653038],
      [85.41028595, 22.15608025],
      [85.41781616, 22.15335846],
      [85.43578339, 22.14417076],
      [85.45700836, 22.1357708],
      [85.46517944, 22.1312027],
      [85.47173309, 22.12413979],
      [85.47940063, 22.12177086],
      [85.4858017, 22.11648941],
      [85.50567627, 22.11145973],
      [85.5148468, 22.10820961],
      [85.52416992, 22.10305023],
      [85.52873993, 22.0987606],
      [85.53350067, 22.09679985],
      [85.53604889, 22.09440994],
      [85.53734589, 22.09029961],
      [85.53954315, 22.08658981],
      [85.54862213, 22.08855057],
      [85.55433655, 22.09082031],
      [85.56022644, 22.08490944],
      [85.56623077, 22.08703995],
      [85.57110596, 22.08605957],
      [85.58223724, 22.08098984],
      [85.5867691, 22.07681847],
      [85.59108734, 22.07444],
      [85.59664917, 22.07472038],
      [85.60176086, 22.07634163],
      [85.60359955, 22.0792408],
      [85.60732269, 22.08179092],
      [85.6085968, 22.07895088],
      [85.61110687, 22.07679939],
      [85.61958313, 22.07784081],
      [85.62623596, 22.0796299],
      [85.63124847, 22.08154106],
      [85.63639069, 22.08576965],
      [85.6400528, 22.09023285],
      [85.64460754, 22.09028053],
      [85.64805603, 22.08681107],
      [85.65033722, 22.08130074],
      [85.65532684, 22.07781029],
      [85.65750885, 22.07427979],
      [85.66472626, 22.06657028],
      [85.66757202, 22.06102943],
      [85.67236328, 22.05972099],
      [85.67195129, 22.05147171],
      [85.6815033, 22.04878044],
      [85.68788147, 22.05607986],
      [85.69600677, 22.0568409],
      [85.70465088, 22.05433083],
      [85.70780182, 22.05628967],
      [85.71277618, 22.0564003],
      [85.71755981, 22.05772972],
      [85.72235107, 22.05813026],
      [85.73381805, 22.06452942],
      [85.74606323, 22.06987953],
      [85.7504425, 22.07439041],
      [85.75213623, 22.0792408],
      [85.75782013, 22.08498192],
      [85.76502228, 22.08820915],
      [85.77339172, 22.09024048],
      [85.77903748, 22.09342003],
      [85.78425598, 22.09898758],
      [85.79091644, 22.10038948],
      [85.79397583, 22.10536957],
      [85.79718018, 22.10630035],
      [85.80204773, 22.11083031],
      [85.81474304, 22.10211182],
      [85.81521606, 22.09902],
      [85.80870819, 22.09184074],
      [85.81542206, 22.09282112],
      [85.8189621, 22.08963013],
      [85.82491302, 22.08712959],
      [85.82808685, 22.08266068],
      [85.83075714, 22.07697105],
      [85.8143692, 22.07007027],
      [85.80272675, 22.07176018],
      [85.79676056, 22.07450104],
      [85.78794098, 22.0771904],
      [85.78511047, 22.07191086],
      [85.77033234, 22.05128288],
      [85.7712326, 22.04714966],
      [85.77330017, 22.04330826],
      [85.77893829, 22.0362606],
      [85.77559662, 22.02827072],
      [85.77217102, 22.02470016],
      [85.76184845, 22.01794052],
      [85.75869751, 22.01480293],
      [85.7561264, 22.00839043],
      [85.76052094, 21.99694061],
      [85.76074982, 21.98971939],
      [85.77140045, 21.98371124],
      [85.78050232, 21.98104095],
      [85.78218842, 21.9793911],
      [85.78427887, 21.97859955],
      [85.80097961, 21.98166084],
      [85.80515289, 21.98022079],
      [85.81265259, 21.98096085],
      [85.81539154, 21.98013115],
      [85.81606293, 21.9753418],
      [85.81558228, 21.97130013],
      [85.81832886, 21.97001076],
      [85.82227325, 21.97013092],
      [85.82859802, 21.97294044],
      [85.84041595, 21.97648048],
      [85.8469696, 21.97707939],
      [85.87132263, 21.97475052],
      [85.88175201, 21.97483063],
      [85.88613129, 21.97542],
      [85.89115143, 21.97851944],
      [85.89417267, 21.97829056],
      [85.90284729, 21.96659088],
      [85.90542603, 21.96663094],
      [85.90599823, 21.96809006],
      [85.90445709, 21.9726696],
      [85.90921021, 21.97554016],
      [85.90966797, 21.97758102],
      [85.90725708, 21.98130989],
      [85.90924835, 21.99012947],
      [85.91306305, 21.9966507],
      [85.91223907, 22.0048008],
      [85.91954041, 22.00979042],
      [85.92340088, 22.01407814],
      [85.92488861, 22.01735115],
      [85.92877197, 22.01935959],
      [85.93309021, 22.0174408],
      [85.93856049, 22.01662064],
      [85.94258118, 22.01913071],
      [85.94470978, 22.02326965],
      [85.95153809, 22.0316391],
      [85.95358276, 22.03610039],
      [85.95797729, 22.04251099],
      [85.96331024, 22.0544281],
      [85.96737671, 22.05978012],
      [85.97122955, 22.06682014],
      [85.97528839, 22.0717907],
      [85.97738647, 22.07673836],
      [85.97731781, 22.08003998],
      [85.97899628, 22.08336067],
      [85.98919678, 22.09345055],
      [85.99259949, 22.10025024],
      [86.00047302, 22.10897064],
      [86.00258636, 22.11302185],
      [85.99608612, 22.12065125],
      [85.99508667, 22.12356949],
      [85.99462891, 22.13178062],
      [85.99909973, 22.13710976],
      [85.99968719, 22.14240074],
      [85.99923706, 22.14492989],
      [86.00385284, 22.15105057],
      [86.01140594, 22.15765953],
      [86.01312256, 22.16361046],
      [86.01257324, 22.16785812],
      [86.01477814, 22.17264175],
      [86.02671814, 22.18522263],
      [86.0255661, 22.18921089],
      [86.01738739, 22.19876289],
      [85.992836, 22.21548271],
      [85.98513031, 22.22384262],
      [85.97779083, 22.23554993],
      [85.97135925, 22.23838043],
      [85.9697876, 22.24340057],
      [85.98181152, 22.2520504],
      [85.98544312, 22.25745964],
      [85.98519135, 22.2672081],
      [85.99304199, 22.2732811],
      [85.99752808, 22.27534103],
      [86.00074005, 22.27933121],
      [86.00222015, 22.28146172],
      [86.00023651, 22.28437042],
      [86.00849915, 22.2926712],
      [86.01605988, 22.29652977],
      [86.0197525, 22.29781914],
      [86.02339172, 22.29767036],
      [86.02732086, 22.29949951],
      [86.0293808, 22.30076027],
      [86.03031921, 22.30298805],
      [86.03996277, 22.30355072],
      [86.04328918, 22.30619049],
      [86.04225922, 22.30824089],
      [86.03930664, 22.30865097],
      [86.03407288, 22.30793953],
      [86.03230286, 22.30523109],
      [86.02816772, 22.30768967],
      [86.02471924, 22.30417061],
      [86.02040863, 22.30554008],
      [86.01777649, 22.30455017],
      [86.01139832, 22.30939102],
      [86.01117706, 22.31056976],
      [86.00842285, 22.30995941],
      [86.008461, 22.31324005],
      [86.00589752, 22.31591034],
      [86.01328278, 22.32396126],
      [86.01918793, 22.32713127],
      [86.02043152, 22.32939911],
      [86.01399994, 22.33450127],
      [86.00476074, 22.33564949],
      [85.99842834, 22.33107948],
      [85.99582672, 22.33311844],
      [85.99288177, 22.33815956],
      [86.0061264, 22.34875107],
      [86.01686096, 22.3622303],
      [86.01526642, 22.36600113],
      [86.01596832, 22.37297058],
      [86.01841736, 22.37153053],
      [86.01891327, 22.37475967],
      [86.02124786, 22.37504005],
      [86.0196228, 22.37815094],
      [86.02149963, 22.38176155],
      [86.02050781, 22.38420105],
      [86.0175705, 22.38612175],
      [86.01906586, 22.39066124],
      [86.01802063, 22.39324188],
      [86.01574707, 22.39334106],
      [86.01477814, 22.39705086],
      [86.01226807, 22.39899063],
      [86.01365662, 22.40388107],
      [86.01204681, 22.41473007],
      [86.00961304, 22.41889],
      [86.00974274, 22.42165947],
      [86.00874329, 22.42432022],
      [86.0059967, 22.42536163],
      [86.00283813, 22.42506027],
      [86.00002289, 22.42264748],
      [85.99685669, 22.4216404],
      [85.99297333, 22.42214012],
      [85.98213196, 22.42898941],
      [85.96697998, 22.44122124],
      [85.96311188, 22.44512177],
      [85.96140289, 22.45269966],
      [85.95350647, 22.45597267],
      [85.95826721, 22.46590042],
      [85.9593277, 22.47038078],
      [85.95813751, 22.47618103],
      [85.96079254, 22.47861862],
      [85.9667511, 22.48216057],
      [85.9670105, 22.48601151],
      [85.96560669, 22.4901619],
      [85.96637726, 22.49381065],
      [85.96924591, 22.49978065],
      [85.98071289, 22.50974083],
      [85.98413849, 22.51062012],
      [85.99297333, 22.50892067],
      [85.99591827, 22.50896263],
      [85.9998703, 22.51497269],
      [86.00179291, 22.51486015],
      [86.00868225, 22.51855087],
      [86.01544952, 22.52448082],
      [86.02202606, 22.52054977],
      [86.02826691, 22.53433037],
      [86.01834106, 22.5436306],
      [86.0384903, 22.5635891],
      [86.04664612, 22.55788994],
      [86.0533905, 22.55465126],
      [86.05813599, 22.55342102],
      [86.06182861, 22.5485096],
      [86.06920624, 22.54306984],
      [86.07834625, 22.53041077],
      [86.09155273, 22.50915909],
      [86.09350586, 22.50822067],
      [86.0981369, 22.49563026],
      [86.10137939, 22.49015045],
      [86.10733032, 22.48489761],
      [86.11192322, 22.48293114],
      [86.11992645, 22.48555946],
      [86.1462326, 22.47914124],
      [86.14514923, 22.47513962],
      [86.1555481, 22.4790802],
      [86.16602325, 22.47674751],
      [86.17370605, 22.47643089],
      [86.19668579, 22.46774101],
      [86.20239258, 22.47027969],
      [86.20913696, 22.46284103],
      [86.21653748, 22.46504974],
      [86.2175827, 22.46824837],
      [86.21820831, 22.46821022],
      [86.21694946, 22.46403122],
      [86.21150208, 22.45971107],
      [86.22038269, 22.44909096],
      [86.22103119, 22.45059967],
      [86.22849274, 22.44958115],
      [86.2425766, 22.44453049],
      [86.25956726, 22.44127083],
      [86.2651825, 22.43954086],
      [86.27137756, 22.44282913],
      [86.2798233, 22.4455204],
      [86.28795624, 22.42536163],
      [86.29292297, 22.42014122],
      [86.30065155, 22.41531944],
      [86.30223846, 22.40520287],
      [86.30979919, 22.39726067],
      [86.31770325, 22.39059067],
      [86.31986237, 22.38035965],
      [86.32534027, 22.37308121],
      [86.33931732, 22.36605072],
      [86.34736633, 22.35900116],
      [86.35266113, 22.35153008],
      [86.35273743, 22.34542084],
      [86.36303711, 22.33960915],
      [86.37552643, 22.33711815],
      [86.39060974, 22.32528114],
      [86.40130615, 22.31941032],
      [86.41137695, 22.31555939],
      [86.42169189, 22.31389046],
      [86.42469788, 22.30920982],
      [86.42437744, 22.3062706],
      [86.43335724, 22.30154037],
      [86.4393158, 22.30578041],
      [86.44019318, 22.3114109],
      [86.43881226, 22.31645966],
      [86.43839264, 22.32336807],
      [86.44181824, 22.32534027],
      [86.44954681, 22.3230629],
      [86.45823669, 22.32211113],
      [86.46026611, 22.32641983],
      [86.46395874, 22.32699966],
      [86.4670105, 22.32891083],
      [86.46842194, 22.33228111],
      [86.47457886, 22.33422089],
      [86.47853088, 22.33446121],
      [86.48171234, 22.34201813],
      [86.48371887, 22.34441948],
      [86.49994659, 22.34095192],
      [86.50125885, 22.33146095],
      [86.50798798, 22.32402039],
      [86.51957703, 22.32057953],
      [86.52365112, 22.31857109],
      [86.52509308, 22.3123703],
      [86.52083588, 22.31148911],
      [86.52320099, 22.30783844],
      [86.52455139, 22.30285072],
      [86.53237915, 22.29819107],
      [86.53988647, 22.30373001],
      [86.54573822, 22.29892921],
      [86.55206299, 22.29528999],
      [86.561203, 22.29701042],
      [86.56771088, 22.29700279],
      [86.57068634, 22.29294968],
      [86.57433319, 22.29393959],
      [86.5752182, 22.2963295],
      [86.57846069, 22.29781914],
      [86.58130646, 22.29491806],
      [86.58217621, 22.29101944],
      [86.58560181, 22.29088974],
      [86.58561707, 22.28663063],
      [86.58992767, 22.28214073],
      [86.59092712, 22.27483177],
      [86.59845734, 22.27437973],
      [86.60263062, 22.27843285],
      [86.60851288, 22.27876091],
      [86.61605835, 22.27600288],
      [86.62715149, 22.26683044],
      [86.63025665, 22.26902962],
      [86.63465881, 22.26914978],
      [86.63729095, 22.26620293],
      [86.63761902, 22.26309013],
      [86.64137268, 22.26419258],
      [86.64227295, 22.26184273],
      [86.64557648, 22.26136971],
      [86.64589691, 22.25728035],
      [86.64833832, 22.25323105],
      [86.64729309, 22.24969292],
      [86.6481781, 22.23569107],
      [86.65114594, 22.23320007],
      [86.66034698, 22.2342205],
      [86.66269684, 22.23748016],
      [86.66514587, 22.23855972],
      [86.66989136, 22.2368412],
      [86.66995239, 22.23010063],
      [86.67399597, 22.22496986],
      [86.68216705, 22.21951103],
      [86.69178772, 22.2173996],
      [86.71147156, 22.21772003],
      [86.72097015, 22.21684074],
      [86.72278595, 22.21524811],
      [86.71996307, 22.21018982],
      [86.71766663, 22.19792938],
      [86.71607208, 22.19379997],
      [86.71721649, 22.18556976],
      [86.71335602, 22.18135262],
      [86.71277618, 22.17828941],
      [86.71534729, 22.17440987],
      [86.71689606, 22.16376114],
      [86.71520996, 22.15872002],
      [86.71544647, 22.14349174],
      [86.72280884, 22.14274025],
      [86.73233795, 22.14491081],
      [86.73953247, 22.14508247],
      [86.75222015, 22.14396095],
      [86.75670624, 22.14519119],
      [86.77153778, 22.14678001],
      [86.77694702, 22.1490097],
      [86.78283691, 22.15014076],
      [86.78681183, 22.15372276],
      [86.79032135, 22.1539402],
      [86.80045319, 22.1459198],
      [86.799263, 22.13908958],
      [86.80047607, 22.1304512],
      [86.79984283, 22.12848091],
      [86.79709625, 22.12613106],
      [86.79785919, 22.12528038],
      [86.80396271, 22.12613106],
      [86.806633, 22.12536049],
      [86.80796814, 22.12382126],
      [86.80438232, 22.11707115],
      [86.80621338, 22.11416054],
      [86.81217194, 22.11367035],
      [86.81484985, 22.11244011],
      [86.8208313, 22.11362076],
      [86.82331085, 22.11292076],
      [86.82540131, 22.10863113],
      [86.83132935, 22.10069084],
      [86.83200836, 22.09510994],
      [86.84169006, 22.09180069],
      [86.84603119, 22.09514999],
      [86.84790039, 22.09835052],
      [86.90876007, 22.08638],
      [86.92225647, 22.08576012],
      [86.92901611, 22.08453941],
      [86.93203735, 22.08584976],
      [86.94426727, 22.08197975],
      [86.95197296, 22.08134079],
      [86.95723724, 22.0833683],
      [86.96705627, 22.07775116],
      [86.97046661, 22.07399178],
      [86.97190857, 22.06627083],
      [86.98754883, 22.05507278],
      [86.99048615, 22.04938126],
      [86.99517822, 22.04579926],
      [87.00093079, 22.04500008],
      [87.00470734, 22.04278946],
      [87.0094223, 22.04148102],
      [87.01761627, 22.04128075],
      [87.02469635, 22.03269005],
      [87.02604675, 22.02874947],
      [87.02350616, 22.02102089],
      [87.02226257, 22.0120697],
      [87.02500916, 22.00395012],
      [87.02796936, 21.99842262],
      [87.0319519, 21.99567032],
      [87.03431702, 21.98919106],
      [87.03224182, 21.98610115],
      [87.03085327, 21.97883987],
      [87.02030945, 21.96836281],
      [87.01490021, 21.95326042],
      [87.00554657, 21.94065094],
      [87.00408173, 21.9354229],
      [87.0025177, 21.92235947],
      [86.99939728, 21.91814041],
      [87.00061035, 21.91438103],
      [86.99793243, 21.90750122],
      [87.00125885, 21.90584183],
      [87.00483704, 21.8955307],
      [87.01478577, 21.88968277],
      [87.0167923, 21.88722992],
      [87.01996613, 21.88574982],
      [87.02511597, 21.87034988],
      [87.03024292, 21.86531067],
      [87.05377197, 21.86094284],
      [87.06210327, 21.86018944],
      [87.0777359, 21.85980034],
      [87.09464264, 21.86034012],
      [87.0929184, 21.87420273],
      [87.09626007, 21.88210106],
      [87.09612274, 21.88624954],
      [87.09314728, 21.88986969],
      [87.0894165, 21.89240074],
      [87.08853912, 21.89507294],
      [87.08876801, 21.89812088],
      [87.09101868, 21.89923096],
      [87.09353638, 21.90789986],
      [87.09955597, 21.91098022],
      [87.10180664, 21.91608047],
      [87.10468292, 21.91781998],
      [87.10785675, 21.91741943],
      [87.1103363, 21.91802025],
      [87.11255646, 21.92115021],
      [87.11424255, 21.92217064],
      [87.11618042, 21.92064095],
      [87.12351227, 21.92024994],
      [87.13091278, 21.91801071],
      [87.1367569, 21.91797066],
      [87.14112854, 21.91969109],
      [87.14782715, 21.92592812],
      [87.15168762, 21.9279995],
      [87.15641785, 21.9289093],
      [87.15828705, 21.93054962],
      [87.15989685, 21.93392944],
      [87.15976715, 21.94908142],
      [87.16127777, 21.95389938],
      [87.16056061, 21.96244049],
      [87.16520691, 21.96751022],
      [87.16744232, 21.97340965],
      [87.17085266, 21.97261047],
      [87.17497253, 21.96619987],
      [87.18837738, 21.95701027],
      [87.19510651, 21.95356941],
      [87.20291138, 21.95643997],
      [87.20957184, 21.95737076],
      [87.22119141, 21.95321083],
      [87.22323608, 21.95323181],
      [87.22440338, 21.95401955],
      [87.22561646, 21.9571209],
      [87.22873688, 21.95607948],
      [87.23484802, 21.95438004],
      [87.23500061, 21.95298958],
      [87.23232269, 21.94646263],
      [87.23223114, 21.94036102],
      [87.23542786, 21.93374062],
      [87.25208282, 21.92097282],
      [87.25240326, 21.91780281],
      [87.25054932, 21.91518021],
      [87.23937225, 21.90682983],
      [87.23651886, 21.90272141],
      [87.23480988, 21.89560127],
      [87.24732971, 21.87172127],
      [87.24897766, 21.86651993],
      [87.24900055, 21.86380005],
      [87.24475098, 21.85637093],
      [87.24762726, 21.84820747],
      [87.25527191, 21.8438797],
      [87.25714874, 21.84137917],
      [87.25888824, 21.83501053],
      [87.26169586, 21.83329964],
      [87.26226044, 21.82982826],
      [87.26357269, 21.82719994],
      [87.26346588, 21.82501984],
      [87.26756287, 21.82149124],
      [87.26780701, 21.81970978],
      [87.26326752, 21.81604195],
      [87.26242065, 21.81385994],
      [87.26542664, 21.81289101],
      [87.27147675, 21.81307983],
      [87.27379608, 21.81171989],
      [87.27314758, 21.80579948],
      [87.27165222, 21.80471992],
      [87.27156067, 21.80340004],
      [87.27333832, 21.80360031],
      [87.27350616, 21.8020401],
      [87.28060913, 21.7991581],
      [87.2869873, 21.80384827],
      [87.29281616, 21.80546951],
      [87.29480743, 21.80389023],
      [87.294487, 21.79842186],
      [87.29656982, 21.79533005],
      [87.29872131, 21.79444122],
      [87.30605316, 21.79385948],
      [87.30755615, 21.79268265],
      [87.30916595, 21.78890991],
      [87.31796265, 21.79055023],
      [87.31685638, 21.78854942],
      [87.31730652, 21.78572083],
      [87.32286072, 21.78596115],
      [87.32427216, 21.78537941],
      [87.32618713, 21.78564072],
      [87.33011627, 21.78770256],
      [87.33270264, 21.78720093],
      [87.33534241, 21.78363037],
      [87.33573151, 21.78121185],
      [87.33500671, 21.78013039],
      [87.33666992, 21.77918053],
      [87.33922577, 21.7808094],
      [87.34046173, 21.78395844],
      [87.34449768, 21.78596115],
      [87.3520813, 21.78573036],
      [87.35504913, 21.77927971],
      [87.35736084, 21.77724075],
      [87.36779785, 21.77463913],
      [87.37045288, 21.76802063],
      [87.36873627, 21.76263046],
      [87.37011719, 21.76007271],
      [87.37166595, 21.76039124],
      [87.37306213, 21.76204109],
      [87.37348938, 21.76641083],
      [87.37542725, 21.76799965],
      [87.38330078, 21.77086067],
      [87.39096069, 21.77044106],
      [87.39240265, 21.76898193],
      [87.38990021, 21.76654053],
      [87.39315796, 21.76613808],
      [87.39377594, 21.76377106],
      [87.39524078, 21.76242256],
      [87.39993286, 21.76256943],
      [87.40101624, 21.76486015],
      [87.40283203, 21.7655201],
      [87.41208649, 21.76375008],
      [87.42481995, 21.76565933],
      [87.43139648, 21.76440048],
      [87.43930817, 21.76436043],
      [87.44367218, 21.76088905],
      [87.44245911, 21.75533104],
      [87.44330597, 21.74212074],
      [87.44563293, 21.7379303],
      [87.45111084, 21.7354126],
      [87.45137024, 21.73241043],
      [87.44441986, 21.732481],
      [87.44293213, 21.72904968],
      [87.45419312, 21.72943115],
      [87.46276855, 21.72640038],
      [87.46517944, 21.72636032],
      [87.46605682, 21.72519112],
      [87.46573639, 21.7235775],
      [87.46231842, 21.72224998],
      [87.46083069, 21.72050095],
      [87.46264648, 21.71706009],
      [87.47083282, 21.70718002],
      [87.46929169, 21.69845009],
      [87.4675293, 21.69565964],
      [87.46969604, 21.6916008],
      [87.46839142, 21.68378067],
      [87.46826172, 21.67727089],
      [87.46520233, 21.67513084],
      [87.46507263, 21.67110062],
      [87.46349335, 21.66876984],
      [87.46491241, 21.65855026],
      [87.4604187, 21.65712929],
      [87.45864868, 21.64493942],
      [87.46090698, 21.64445114],
      [87.46776581, 21.64565086],
      [87.47074127, 21.64307022],
      [87.47751617, 21.63175964],
      [87.4785614, 21.62794113],
      [87.48208618, 21.62586975],
      [87.48265839, 21.61803246],
      [87.48171234, 21.61383057],
      [87.48175812, 21.60946274],
      [87.48203278, 21.60870361],
      [87.48110962, 21.60777855],
      [87.48082733, 21.60778046],
      [87.4799881, 21.60771751],
      [87.47948456, 21.60771751],
      [87.47861481, 21.60785294],
      [87.47833252, 21.60777855],
      [87.47782898, 21.60750008],
      [87.4774704, 21.60705757],
      [87.47695923, 21.60679054],
      [87.47635651, 21.60679054],
      [87.47580719, 21.60698128],
      [87.47466278, 21.60686493],
      [87.47389984, 21.60678482],
      [87.47354126, 21.60638046],
      [87.47270966, 21.60647202],
      [87.47214508, 21.60612106],
      [87.47145081, 21.6061058],
      [87.47071838, 21.60632324],
      [87.46992493, 21.60650444],
      [87.46920776, 21.60645103],
      [87.46852112, 21.60639],
      [87.46788025, 21.60630989],
      [87.46722412, 21.60644722],
      [87.46673584, 21.6065197],
      [87.46639252, 21.60667038],
      [87.4658432, 21.60667038],
      [87.46565247, 21.60648537],
      [87.46555328, 21.60638618],
      [87.46389008, 21.60639],
      [87.46360779, 21.60610962],
      [87.46333313, 21.60611153],
      [87.46305847, 21.60583305],
      [87.46277618, 21.60583305],
      [87.4624939, 21.60555649],
      [87.46221924, 21.60527611],
      [87.46193695, 21.60527992],
      [87.46166992, 21.60499954],
      [87.46138763, 21.60499954],
      [87.46055603, 21.60416985],
      [87.46028137, 21.60416985],
      [87.4597168, 21.60361099],
      [87.4597168, 21.60305977],
      [87.46055603, 21.60222054],
      [87.45972443, 21.60179329],
      [87.45944214, 21.6016674],
      [87.45889282, 21.60167122],
      [87.45870209, 21.60148239],
      [87.45861053, 21.60138893],
      [87.45833588, 21.60139275],
      [87.45777893, 21.60083008],
      [87.45761871, 21.6006794],
      [87.45707703, 21.60037422],
      [87.45639038, 21.60000038],
      [87.45582581, 21.60000038],
      [87.45500946, 21.59917259],
      [87.45471954, 21.5988903],
      [87.45443726, 21.5988903],
      [87.454216, 21.59865761],
      [87.45388794, 21.59861183],
      [87.45361328, 21.59833336],
      [87.45305634, 21.59833336],
      [87.45249939, 21.59777832],
      [87.45276642, 21.59749985],
      [87.45276642, 21.59693909],
      [87.45305634, 21.59694481],
      [87.45333099, 21.59722137],
      [87.45388794, 21.59721756],
      [87.45417023, 21.59749985],
      [87.45500946, 21.59749985],
      [87.45542908, 21.59707642],
      [87.45555878, 21.59694481],
      [87.45500183, 21.59638977],
      [87.45471954, 21.59638977],
      [87.45417023, 21.59583282],
      [87.45388794, 21.59583282],
      [87.45333099, 21.59527969],
      [87.45305634, 21.59527969],
      [87.4522171, 21.59444046],
      [87.45178986, 21.59422684],
      [87.45124054, 21.59387016],
      [87.44972229, 21.5933342],
      [87.44721985, 21.59083557],
      [87.4466629, 21.59083557],
      [87.44638824, 21.59055328],
      [87.44610596, 21.59057045],
      [87.44581604, 21.59028053],
      [87.44555664, 21.59028053],
      [87.44444275, 21.58917046],
      [87.44361115, 21.58917046],
      [87.44194794, 21.58751106],
      [87.44139099, 21.58751106],
      [87.44114685, 21.58726311],
      [87.44083405, 21.58722115],
      [87.44055176, 21.58694458],
      [87.4402771, 21.58694458],
      [87.44000244, 21.58666611],
      [87.43972015, 21.58667183],
      [87.43860626, 21.58556175],
      [87.4383316, 21.58556175],
      [87.43776703, 21.58499908],
      [87.4375, 21.58499908],
      [87.43742371, 21.58492088],
      [87.43731689, 21.58481216],
      [87.43694305, 21.58444405],
      [87.4366684, 21.58444405],
      [87.43611145, 21.58389091],
      [87.43583679, 21.58389091],
      [87.43444824, 21.58250046],
      [87.43416595, 21.58250046],
      [87.43332672, 21.58167076],
      [87.43305206, 21.58167076],
      [87.4327774, 21.58138084],
      [87.43250275, 21.58138847],
      [87.43138885, 21.5802784],
      [87.43110657, 21.5802803],
      [87.4306488, 21.579813],
      [87.43055725, 21.57971954],
      [87.43055725, 21.57944679],
      [87.43027496, 21.57916451],
      [87.43000031, 21.57917023],
      [87.42944336, 21.57860947],
      [87.4291687, 21.57861137],
      [87.42875671, 21.57819557],
      [87.4283371, 21.57777786],
      [87.4283371, 21.57749939],
      [87.42778015, 21.57694054],
      [87.42694092, 21.57694244],
      [87.42666626, 21.57666588],
      [87.42582703, 21.57639122],
      [87.42415619, 21.57472038],
      [87.42388916, 21.57472229],
      [87.4236145, 21.57444382],
      [87.42333221, 21.57444382],
      [87.42319489, 21.57430649],
      [87.42305756, 21.57416725],
      [87.4227829, 21.57417107],
      [87.42193604, 21.57333183],
      [87.42166901, 21.57333374],
      [87.42138672, 21.57305527],
      [87.42111206, 21.5730629],
      [87.42028046, 21.57389069],
      [87.41971588, 21.57389069],
      [87.41916656, 21.57333183],
      [87.41916656, 21.57305527],
      [87.41887665, 21.57278252],
      [87.41887665, 21.57250023],
      [87.41805267, 21.57167244],
      [87.41805267, 21.57055092],
      [87.41694641, 21.56944466],
      [87.41666412, 21.56944466],
      [87.41576385, 21.5688324],
      [87.41555023, 21.5684967],
      [87.41570282, 21.56829262],
      [87.41619873, 21.56829453],
      [87.4168396, 21.56816292],
      [87.4168396, 21.5680294],
      [87.41677094, 21.56776047],
      [87.41619873, 21.56709099],
      [87.41567993, 21.56651115],
      [87.41503906, 21.56637001],
      [87.41444397, 21.56583595],
      [87.41416931, 21.56583023],
      [87.41333008, 21.56666946],
      [87.41339874, 21.56701088],
      [87.41346741, 21.56777954],
      [87.41352844, 21.56805992],
      [87.4136734, 21.56916618],
      [87.41333008, 21.56972313],
      [87.41278076, 21.56916618],
      [87.41278076, 21.56861115],
      [87.41249847, 21.56833076],
      [87.41249847, 21.56749916],
      [87.41166687, 21.56666946],
      [87.41166687, 21.56640053],
      [87.41139221, 21.56611061],
      [87.41139221, 21.56555939],
      [87.41110992, 21.56528091],
      [87.41110992, 21.56500053],
      [87.4108429, 21.56472015],
      [87.41083527, 21.56416512],
      [87.40970612, 21.56306076],
      [87.40952301, 21.56285095],
      [87.40910339, 21.56243324],
      [87.40916443, 21.56222153],
      [87.40916443, 21.56194305],
      [87.40944672, 21.5616703],
      [87.40952301, 21.56151962],
      [87.40994263, 21.56131172],
      [87.41166687, 21.56272125],
      [87.41207886, 21.56236076],
      [87.41207886, 21.56215477],
      [87.4119339, 21.56173134],
      [87.41007233, 21.56004906],
      [87.40970612, 21.56011963],
      [87.40943909, 21.56001663],
      [87.40937042, 21.55999184],
      [87.4091568, 21.56005859],
      [87.40888977, 21.55999947],
      [87.40860748, 21.55999947],
      [87.40791321, 21.55977821],
      [87.40776825, 21.55983734],
      [87.40727997, 21.55962944],
      [87.40693665, 21.55888939],
      [87.40673065, 21.55861092],
      [87.40611267, 21.55777931],
      [87.40596771, 21.55750084],
      [87.40499878, 21.5566597],
      [87.40444183, 21.55639076],
      [87.40409851, 21.55631065],
      [87.40291595, 21.55644989],
      [87.40222168, 21.55666924],
      [87.40090179, 21.55686951],
      [87.40027618, 21.55721092],
      [87.39923859, 21.55755806],
      [87.39861298, 21.55777931],
      [87.39833069, 21.55777931],
      [87.39805603, 21.5580616],
      [87.39778137, 21.55805588],
      [87.39749908, 21.55833244],
      [87.3972168, 21.55833054],
      [87.39569092, 21.55918312],
      [87.39533997, 21.5597229],
      [87.39520264, 21.55986977],
      [87.39499664, 21.55999947],
      [87.39303589, 21.56113052],
      [87.39261627, 21.56139946],
      [87.39248657, 21.56167984],
      [87.39235687, 21.56167984],
      [87.39193726, 21.56182098],
      [87.39179993, 21.56208801],
      [87.3915329, 21.56223106],
      [87.39111328, 21.5625],
      [87.39027405, 21.56333542],
      [87.38967133, 21.56386948],
      [87.38947296, 21.56417084],
      [87.38906097, 21.56484032],
      [87.38863373, 21.56511688],
      [87.38814545, 21.5652504],
      [87.38692474, 21.56630898],
      [87.38638306, 21.56656265],
      [87.38679504, 21.5662632],
      [87.38703918, 21.56541443],
      [87.38676453, 21.564991],
      [87.38684845, 21.56478119],
      [87.38663483, 21.56451225],
      [87.3864975, 21.56423569],
      [87.38642883, 21.56388855],
      [87.38643646, 21.56360054],
      [87.38630676, 21.56333542],
      [87.3861084, 21.56278038],
      [87.3861084, 21.5625],
      [87.38556671, 21.5616703],
      [87.38527679, 21.56138992],
      [87.38533783, 21.56110954],
      [87.3852005, 21.56083107],
      [87.38492584, 21.5602932],
      [87.3848877, 21.56021309],
      [87.38459778, 21.55999184],
      [87.38406372, 21.55921555],
      [87.3844223, 21.55796814],
      [87.38443756, 21.55708122],
      [87.3842926, 21.55652046],
      [87.38444519, 21.55611038],
      [87.38401794, 21.55399704],
      [87.38340759, 21.55352974],
      [87.38285065, 21.55346107],
      [87.38201904, 21.55335045],
      [87.38276672, 21.55249977],
      [87.38305664, 21.55249977],
      [87.3833313, 21.55221939],
      [87.38388824, 21.5522213],
      [87.3841629, 21.55194664],
      [87.38444519, 21.55194664],
      [87.38471985, 21.5522213],
      [87.38500214, 21.5522213],
      [87.38527679, 21.55194664],
      [87.3861084, 21.55194664],
      [87.38639069, 21.55166435],
      [87.38694763, 21.55167007],
      [87.38722229, 21.55140114],
      [87.38791656, 21.55104065],
      [87.38944244, 21.55083084],
      [87.3895874, 21.55089569],
      [87.39026642, 21.55120087],
      [87.39065552, 21.55122375],
      [87.39083099, 21.55140114],
      [87.39111328, 21.55140114],
      [87.39138794, 21.55111122],
      [87.39138794, 21.55000114],
      [87.39111328, 21.55000114],
      [87.39026642, 21.54916954],
      [87.38999939, 21.54916954],
      [87.38916779, 21.54833031],
      [87.3888855, 21.54833221],
      [87.38833618, 21.54777718],
      [87.38804626, 21.5477829],
      [87.38777924, 21.54750061],
      [87.38749695, 21.54750061],
      [87.38722229, 21.5477829],
      [87.38694763, 21.54777718],
      [87.38639069, 21.54833221],
      [87.3861084, 21.54833031],
      [87.38582611, 21.54861069],
      [87.38527679, 21.54861069],
      [87.38500214, 21.54889107],
      [87.38500214, 21.54916573],
      [87.38471985, 21.54944229],
      [87.38444519, 21.54944229],
      [87.3841629, 21.54916573],
      [87.38388824, 21.54916954],
      [87.38360596, 21.54889107],
      [87.3833313, 21.54889107],
      [87.38276672, 21.54833031],
      [87.38277435, 21.54805565],
      [87.38249969, 21.5477829],
      [87.38249969, 21.54750061],
      [87.38111115, 21.54611015],
      [87.38083649, 21.54611015],
      [87.3805542, 21.54583359],
      [87.38027954, 21.54583359],
      [87.37999725, 21.54555511],
      [87.37944794, 21.54555511],
      [87.37916565, 21.54583359],
      [87.37889099, 21.54583359],
      [87.3786087, 21.54611015],
      [87.3777771, 21.54611015],
      [87.37750244, 21.54639053],
      [87.37722015, 21.54639053],
      [87.37696075, 21.54611015],
      [87.37610626, 21.54611015],
      [87.3758316, 21.54639053],
      [87.375, 21.54639053],
      [87.37473297, 21.54667282],
      [87.3741684, 21.5466671],
      [87.37388611, 21.54694366],
      [87.37359619, 21.54694176],
      [87.37249756, 21.54805565],
      [87.37249756, 21.54833031],
      [87.37277985, 21.54861069],
      [87.3741684, 21.54861069],
      [87.37444305, 21.54889107],
      [87.3741684, 21.54916573],
      [87.3741684, 21.54944038],
      [87.37359619, 21.55000114],
      [87.37277985, 21.55000114],
      [87.3722229, 21.55055428],
      [87.3722229, 21.55083275],
      [87.37166595, 21.55083084],
      [87.3713913, 21.55111122],
      [87.37110901, 21.55083084],
      [87.3694458, 21.55083275],
      [87.36916351, 21.55055428],
      [87.36860657, 21.55056],
      [87.36833191, 21.55027962],
      [87.36776733, 21.55027962],
      [87.36750031, 21.55000114],
      [87.36723328, 21.55000114],
      [87.36694336, 21.55027962],
      [87.3666687, 21.55027962],
      [87.36638641, 21.55056],
      [87.36611176, 21.55056],
      [87.36528015, 21.55138969],
      [87.36528015, 21.5522213],
      [87.36416626, 21.55333328],
      [87.3638916, 21.55305672],
      [87.3638916, 21.55277824],
      [87.36305237, 21.55194092],
      [87.36222076, 21.55194664],
      [87.36208344, 21.55180359],
      [87.36194611, 21.55166435],
      [87.3602829, 21.55167007],
      [87.36000061, 21.55140114],
      [87.35943604, 21.55140114],
      [87.35916901, 21.55167007],
      [87.35805511, 21.55166435],
      [87.35778046, 21.55194664],
      [87.35749817, 21.55194664],
      [87.35722351, 21.5522213],
      [87.35639191, 21.55138969],
      [87.35582733, 21.55140114],
      [87.3555603, 21.55167007],
      [87.35500336, 21.55167007],
      [87.35472107, 21.55140114],
      [87.35388947, 21.55140114],
      [87.35360718, 21.55167007],
      [87.35221863, 21.55167007],
      [87.35193634, 21.55140114],
      [87.35109711, 21.55140114],
      [87.35083008, 21.55167007],
      [87.35063171, 21.55166435],
      [87.34972382, 21.55166435],
      [87.34916687, 21.5522213],
      [87.34889221, 21.55194664],
      [87.34833527, 21.55194664],
      [87.34805298, 21.55166435],
      [87.34777832, 21.55194664],
      [87.34666443, 21.55194664],
      [87.34638977, 21.5522213],
      [87.34610748, 21.55221939],
      [87.34555817, 21.55278015],
      [87.34416962, 21.55277824],
      [87.34388733, 21.55305672],
      [87.34361267, 21.55305672],
      [87.34336853, 21.55282021],
      [87.34166718, 21.55277824],
      [87.34139252, 21.55305672],
      [87.34083557, 21.55305672],
      [87.34054565, 21.55327988],
      [87.34027863, 21.55333328],
      [87.33999634, 21.55361176],
      [87.33916473, 21.55361176],
      [87.33865356, 21.55309868],
      [87.33833313, 21.55277824],
      [87.33721924, 21.55277824],
      [87.33694458, 21.55305672],
      [87.33666992, 21.55305672],
      [87.33638763, 21.55333328],
      [87.33499908, 21.55333328],
      [87.33374786, 21.55424118],
      [87.33333588, 21.55446053],
      [87.33203125, 21.55438042],
      [87.3312912, 21.55442429],
      [87.33072662, 21.55450249],
      [87.33059692, 21.55451965],
      [87.3299408, 21.5544796],
      [87.32928467, 21.55446243],
      [87.32863617, 21.55446053],
      [87.32795715, 21.55442047],
      [87.3272171, 21.55446053],
      [87.32694244, 21.55446053],
      [87.32666779, 21.55417061],
      [87.32639313, 21.55446053],
      [87.32305908, 21.55444527],
      [87.32277679, 21.55472183],
      [87.32221985, 21.55472183],
      [87.32195282, 21.55446053],
      [87.31890106, 21.55424118],
      [87.31833649, 21.5541687],
      [87.31777954, 21.55417061],
      [87.31742859, 21.55444527],
      [87.3172226, 21.55451012],
      [87.31694794, 21.55465508],
      [87.31555176, 21.55472183],
      [87.31520844, 21.55466461],
      [87.31361389, 21.55473137],
      [87.3133316, 21.55472183],
      [87.3132019, 21.55472183],
      [87.31305695, 21.55471992],
      [87.31138611, 21.55500031],
      [87.30999756, 21.55500031],
      [87.3097229, 21.55508041],
      [87.3077774, 21.55528069],
      [87.30735779, 21.55523109],
      [87.30444336, 21.55555534],
      [87.30392456, 21.55570412],
      [87.30361176, 21.55555534],
      [87.3033371, 21.55555916],
      [87.30304718, 21.55569839],
      [87.30272675, 21.55565834],
      [87.30251312, 21.55563164],
      [87.30136108, 21.55563164],
      [87.30014801, 21.55542946],
      [87.29611206, 21.55501938],
      [87.29528046, 21.5549202],
      [87.29472351, 21.55472183],
      [87.29444122, 21.55479813],
      [87.29277802, 21.55500031],
      [87.29242706, 21.5549202],
      [87.29083252, 21.5549202],
      [87.2902832, 21.55500984],
      [87.29000092, 21.55500984],
      [87.2894516, 21.55505943],
      [87.28923035, 21.55498123],
      [87.28853607, 21.5546875],
      [87.28806305, 21.55468941],
      [87.28749847, 21.55463982],
      [87.28722382, 21.55456924],
      [87.28666687, 21.55472183],
      [87.28639221, 21.55451202],
      [87.28610992, 21.55472183],
      [87.28527832, 21.55471992],
      [87.28472137, 21.55480766],
      [87.28451538, 21.55487442],
      [87.28409576, 21.55473137],
      [87.28388977, 21.55472183],
      [87.28367615, 21.55456924],
      [87.2831192, 21.55462074],
      [87.28291321, 21.55449104],
      [87.28277588, 21.55449486],
      [87.28221893, 21.55444527],
      [87.28193665, 21.55446053],
      [87.28132629, 21.55439949],
      [87.28083038, 21.55447006],
      [87.28041077, 21.55431747],
      [87.2800827, 21.55433273],
      [87.27944183, 21.55433273],
      [87.27861023, 21.55417061],
      [87.27777863, 21.55417061],
      [87.27429199, 21.55425072],
      [87.27271271, 21.55417824],
      [87.27111053, 21.55360985],
      [87.26944733, 21.55361176],
      [87.26773071, 21.55307198],
      [87.26568604, 21.55329704],
      [87.26529694, 21.55334091],
      [87.26465607, 21.55340958],
      [87.26286316, 21.55321121],
      [87.2612915, 21.55321121],
      [87.26051331, 21.55301094],
      [87.25863647, 21.55266953],
      [87.25327301, 21.55266953],
      [87.25269318, 21.55280304],
      [87.2514801, 21.55273628],
      [87.24983215, 21.55247116],
      [87.24846649, 21.55186081],
      [87.24782562, 21.55179405],
      [87.24568176, 21.55158997],
      [87.24446106, 21.55152512],
      [87.24310303, 21.55158997],
      [87.24188232, 21.55166054],
      [87.24082184, 21.55200005],
      [87.23960876, 21.55120087],
      [87.23912048, 21.55125237],
      [87.23851776, 21.55121231],
      [87.23782349, 21.55107117],
      [87.23709106, 21.55092812],
      [87.23638153, 21.55084038],
      [87.23573303, 21.55079079],
      [87.23513031, 21.55064011],
      [87.23456573, 21.55035782],
      [87.23416901, 21.55000114],
      [87.23220825, 21.55000114],
      [87.23194122, 21.54972076],
      [87.23082733, 21.54972076],
      [87.22956848, 21.54980087],
      [87.22720337, 21.54980087],
      [87.22648621, 21.54957008],
      [87.22596741, 21.5494709],
      [87.22538757, 21.54943275],
      [87.22514343, 21.54947281],
      [87.224823, 21.54953003],
      [87.22416687, 21.54944992],
      [87.22368622, 21.54938316],
      [87.22333527, 21.54944229],
      [87.22211456, 21.54931641],
      [87.22090149, 21.54891014],
      [87.22025299, 21.5485096],
      [87.21837616, 21.54824066],
      [87.21746063, 21.54797173],
      [87.21723175, 21.54780006],
      [87.21694183, 21.54770279],
      [87.21424103, 21.54722977],
      [87.21322632, 21.54689026],
      [87.21187592, 21.54675865],
      [87.21037292, 21.54676056],
      [87.2099762, 21.54672623],
      [87.2095871, 21.54668999],
      [87.20901489, 21.54628754],
      [87.20835114, 21.54639053],
      [87.20803833, 21.54639053],
      [87.20780182, 21.54613876],
      [87.20749664, 21.54611015],
      [87.20722198, 21.54639053],
      [87.20694733, 21.54639053],
      [87.20666504, 21.5466671],
      [87.20575714, 21.5471611],
      [87.20548248, 21.54729843],
      [87.20492554, 21.54765129],
      [87.20437622, 21.54784966],
      [87.20417023, 21.54807091],
      [87.20361328, 21.5481987],
      [87.20333099, 21.54834175],
      [87.20305634, 21.54833031],
      [87.20276642, 21.54840279],
      [87.20236206, 21.54841995],
      [87.20188141, 21.54849052],
      [87.20139313, 21.54849052],
      [87.20089722, 21.54849052],
      [87.20027924, 21.54805946],
      [87.19999695, 21.54805946],
      [87.19944763, 21.54750061],
      [87.19944763, 21.54694176],
      [87.19984436, 21.54653358],
      [87.20021057, 21.54618645],
      [87.20052338, 21.54580116],
      [87.20069122, 21.54536057],
      [87.20059204, 21.54493141],
      [87.20037842, 21.54450035],
      [87.20005035, 21.54413033],
      [87.19963837, 21.54379082],
      [87.19922638, 21.5434494],
      [87.19877625, 21.54323006],
      [87.19834137, 21.54305077],
      [87.19788361, 21.54289436],
      [87.19741821, 21.54274368],
      [87.19696045, 21.54255676],
      [87.19645691, 21.54243279],
      [87.19602966, 21.54223061],
      [87.19558716, 21.54203033],
      [87.19509125, 21.54189873],
      [87.19461823, 21.5417099],
      [87.19425201, 21.54157639],
      [87.1941452, 21.54153824],
      [87.19365692, 21.54138947],
      [87.19319153, 21.54123116],
      [87.19272614, 21.54105186],
      [87.1922226, 21.54087067],
      [87.19166565, 21.5408268],
      [87.19151306, 21.54081154],
      [87.19110107, 21.54077339],
      [87.19057465, 21.54060936],
      [87.19000244, 21.54050064],
      [87.18942261, 21.54032326],
      [87.18881226, 21.54021645],
      [87.18821716, 21.54002953],
      [87.18761444, 21.53991318],
      [87.18704987, 21.53970337],
      [87.18651581, 21.53946114],
      [87.18598938, 21.53920174],
      [87.18541718, 21.53902817],
      [87.18480682, 21.53887939],
      [87.18421936, 21.53868103],
      [87.1836319, 21.53849983],
      [87.18305206, 21.53830147],
      [87.18251801, 21.5380497],
      [87.18199921, 21.5377903],
      [87.18147278, 21.53755188],
      [87.18090057, 21.5373764],
      [87.180336, 21.53721046],
      [87.17980194, 21.53703308],
      [87.1792984, 21.53680801],
      [87.17877197, 21.53655815],
      [87.1782608, 21.5363369],
      [87.1778717, 21.53627777],
      [87.17751312, 21.53634071],
      [87.17707062, 21.53635979],
      [87.17648315, 21.53619003],
      [87.17582703, 21.53593063],
      [87.17537689, 21.53565025],
      [87.17505646, 21.53536987],
      [87.17471313, 21.53517342],
      [87.17433167, 21.53497505],
      [87.17392731, 21.53475952],
      [87.17349243, 21.53460121],
      [87.17304993, 21.5344429],
      [87.17260742, 21.53427124],
      [87.17216492, 21.53410149],
      [87.17172241, 21.53390884],
      [87.17124939, 21.53373909],
      [87.17077637, 21.53357506],
      [87.17032623, 21.53337097],
      [87.1698761, 21.53320122],
      [87.16943359, 21.53304863],
      [87.16899109, 21.53289032],
      [87.16854095, 21.53273964],
      [87.16810608, 21.53254128],
      [87.16765594, 21.53238106],
      [87.16722107, 21.53222084],
      [87.16677094, 21.53206825],
      [87.16630554, 21.5319767],
      [87.16580963, 21.53193855],
      [87.16532135, 21.53186989],
      [87.16487885, 21.53165817],
      [87.16445923, 21.53140259],
      [87.16404724, 21.53116035],
      [87.16362, 21.53095818],
      [87.16319275, 21.53077507],
      [87.16279602, 21.5305481],
      [87.1624527, 21.53032112],
      [87.16207886, 21.53012085],
      [87.16165924, 21.53001022],
      [87.16122437, 21.52992439],
      [87.16078949, 21.52972984],
      [87.16033173, 21.52951813],
      [87.15986633, 21.52930832],
      [87.15947723, 21.52905273],
      [87.15911865, 21.52879333],
      [87.15870667, 21.52859116],
      [87.15830231, 21.52837944],
      [87.15785217, 21.52824974],
      [87.15738678, 21.52808952],
      [87.15638733, 21.52743912],
      [87.15473938, 21.52683067],
      [87.15380859, 21.52602768],
      [87.15231323, 21.5250206],
      [87.15087128, 21.52408028],
      [87.14966583, 21.52361107],
      [87.1492157, 21.52345657],
      [87.14853668, 21.52346039],
      [87.14785004, 21.52310944],
      [87.14720154, 21.52273941],
      [87.14658356, 21.52234459],
      [87.14594269, 21.52199745],
      [87.14533997, 21.52164078],
      [87.1446991, 21.52136612],
      [87.14404297, 21.52109909],
      [87.14337921, 21.52083015],
      [87.14277649, 21.52050018],
      [87.14228058, 21.52011299],
      [87.14176941, 21.51984024],
      [87.14128113, 21.51963043],
      [87.14083099, 21.51944351],
      [87.13968658, 21.51861572],
      [87.13896942, 21.51828194],
      [87.13846588, 21.51781082],
      [87.13817596, 21.51740074],
      [87.13796997, 21.51720238],
      [87.13718414, 21.51700211],
      [87.1366806, 21.51673317],
      [87.13648224, 21.51650429],
      [87.13592529, 21.51619148],
      [87.13542938, 21.51584625],
      [87.13498688, 21.51551056],
      [87.134552, 21.51521111],
      [87.13414001, 21.51492119],
      [87.13374329, 21.51499939],
      [87.13275909, 21.51472092],
      [87.13194275, 21.51415825],
      [87.13173676, 21.51403046],
      [87.1314621, 21.51403236],
      [87.1305542, 21.51333427],
      [87.1297226, 21.51292992],
      [87.1286087, 21.51303291],
      [87.12832642, 21.5128994],
      [87.12805176, 21.51303864],
      [87.12722015, 21.51333046],
      [87.12722015, 21.51361084],
      [87.12917328, 21.51555824],
      [87.12944794, 21.51582527],
      [87.12957764, 21.51609993],
      [87.12978363, 21.5161705],
      [87.13040924, 21.51651955],
      [87.13069153, 21.51672554],
      [87.13082123, 21.51679993],
      [87.13166046, 21.51756859],
      [87.1313858, 21.51779175],
      [87.13111115, 21.5177784],
      [87.13083649, 21.5177784],
      [87.13054657, 21.51749992],
      [87.13027954, 21.51749992],
      [87.1297226, 21.51693916],
      [87.12944794, 21.51694489],
      [87.1286087, 21.51611137],
      [87.12833405, 21.51611137],
      [87.12805176, 21.5158329],
      [87.1277771, 21.5158329],
      [87.12722015, 21.51527977],
      [87.12693787, 21.51528931],
      [87.12666321, 21.51499939],
      [87.12638855, 21.51499939],
      [87.12610626, 21.51472092],
      [87.12555695, 21.51472282],
      [87.12527466, 21.51471329],
      [87.12472534, 21.51499939],
      [87.12472534, 21.5158329],
      [87.12458801, 21.51610947],
      [87.12472534, 21.51638985],
      [87.12473297, 21.51721954],
      [87.125, 21.51749992],
      [87.125, 21.51833344],
      [87.12526703, 21.51861191],
      [87.12527466, 21.51916695],
      [87.125, 21.5194416],
      [87.125, 21.52000046],
      [87.12361145, 21.52139282],
      [87.12333679, 21.52138901],
      [87.12277985, 21.52147484],
      [87.12242889, 21.52147484],
      [87.12194824, 21.52138901],
      [87.1213913, 21.52112007],
      [87.12110901, 21.52109909],
      [87.12083435, 21.52111053],
      [87.12055206, 21.52083206],
      [87.1202774, 21.52083206],
      [87.12000275, 21.5205555],
      [87.11972046, 21.52056122],
      [87.11915588, 21.52028275],
      [87.11807251, 21.51917267],
      [87.11778259, 21.51917267],
      [87.11759186, 21.51898384],
      [87.11750031, 21.51889038],
      [87.11723328, 21.51889038],
      [87.11638641, 21.51806259],
      [87.1159668, 21.51791954],
      [87.11562347, 21.51764107],
      [87.11521149, 21.51737213],
      [87.11459351, 21.51667595],
      [87.11416626, 21.51666832],
      [87.1138916, 21.51638985],
      [87.11305237, 21.51625061],
      [87.11277771, 21.51617813],
      [87.11242676, 21.51625061],
      [87.11138916, 21.51638985],
      [87.11110687, 21.51666832],
      [87.10944366, 21.51666641],
      [87.10916901, 21.51694489],
      [87.10888672, 21.51694489],
      [87.10861206, 21.51722145],
      [87.10749817, 21.51722145],
      [87.10694885, 21.51773453],
      [87.10697174, 21.51732063],
      [87.10668945, 21.51669121],
      [87.10643768, 21.51634026],
      [87.10722351, 21.51555634],
      [87.10849762, 21.51478004],
      [87.10928345, 21.51485252],
      [87.11107635, 21.51458359],
      [87.11164856, 21.51431465],
      [87.11229706, 21.51424789],
      [87.11422729, 21.5137825],
      [87.11472321, 21.51333046],
      [87.11472321, 21.51277542],
      [87.11472321, 21.51250076],
      [87.1147995, 21.51222992],
      [87.11473083, 21.51151276],
      [87.11444092, 21.50972176],
      [87.11416626, 21.50944328],
      [87.11416626, 21.50889015],
      [87.1138916, 21.50860977],
      [87.1138916, 21.5083313],
      [87.11360931, 21.50805473],
      [87.11333466, 21.50805473],
      [87.11222076, 21.50694466],
      [87.11222076, 21.50666618],
      [87.11193848, 21.50638962],
      [87.11193848, 21.5060997],
      [87.11166382, 21.50583267],
      [87.11166382, 21.50555611],
      [87.11138916, 21.50527954],
      [87.11138916, 21.50499916],
      [87.10916901, 21.50278091],
      [87.10887909, 21.50278091],
      [87.10861206, 21.50250053],
      [87.10805511, 21.50250053],
      [87.10694122, 21.50138855],
      [87.10666656, 21.50139046],
      [87.10652924, 21.50125122],
      [87.10639191, 21.50111198],
      [87.10618591, 21.50097656],
      [87.10597229, 21.50077057],
      [87.10569763, 21.50036049],
      [87.10398102, 21.4985466],
      [87.10382843, 21.49833488],
      [87.10361481, 21.49805641],
      [87.10305786, 21.49749947],
      [87.10305786, 21.49722099],
      [87.10194397, 21.49611092],
      [87.10193634, 21.49583054],
      [87.10166931, 21.49555588],
      [87.10138702, 21.49555969],
      [87.09967804, 21.4938488],
      [87.09944153, 21.49361038],
      [87.0983429, 21.49250031],
      [87.09812927, 21.49229813],
      [87.09722137, 21.49160194],
      [87.09694672, 21.49138832],
      [87.09664917, 21.49111938],
      [87.09645844, 21.49098015],
      [87.09636688, 21.4908886],
      [87.09625244, 21.49077415],
      [87.09563446, 21.49027824],
      [87.09527588, 21.48999977],
      [87.09443665, 21.48916817],
      [87.09416962, 21.48916817],
      [87.09310913, 21.4881115],
      [87.09194183, 21.4869442],
      [87.09194183, 21.48666763],
      [87.09139252, 21.48611069],
      [87.09083557, 21.48611069],
      [87.09046936, 21.48574448],
      [87.09027863, 21.48555756],
      [87.09027863, 21.48528099],
      [87.08999634, 21.48499107],
      [87.08999634, 21.48444557],
      [87.08944702, 21.48388863],
      [87.0891571, 21.48389053],
      [87.08776855, 21.48250008],
      [87.08750153, 21.48250008],
      [87.08583069, 21.48083115],
      [87.08555603, 21.48083115],
      [87.08499908, 21.48027802],
      [87.08444214, 21.48028183],
      [87.08361053, 21.47972107],
      [87.08333588, 21.4794445],
      [87.08277893, 21.4794445],
      [87.08222961, 21.47893333],
      [87.08166504, 21.4781189],
      [87.08110046, 21.47758293],
      [87.08027649, 21.47722054],
      [87.08027649, 21.47694397],
      [87.08000183, 21.4766674],
      [87.07971954, 21.47666931],
      [87.07943726, 21.47639084],
      [87.0789032, 21.47639084],
      [87.07805634, 21.47555923],
      [87.07776642, 21.47555923],
      [87.07704163, 21.47482491],
      [87.07694244, 21.47471809],
      [87.07694244, 21.4738884],
      [87.07444763, 21.47138977],
      [87.07389069, 21.4711895],
      [87.0736084, 21.47110939],
      [87.07333374, 21.47105217],
      [87.07305908, 21.47105026],
      [87.07276917, 21.47112083],
      [87.07250214, 21.47110939],
      [87.07118225, 21.47090912],
      [87.07105255, 21.47088051],
      [87.0708313, 21.47083473],
      [87.07026672, 21.47083092],
      [87.06929779, 21.47139549],
      [87.06902313, 21.47146034],
      [87.06881714, 21.47167778],
      [87.06861115, 21.47194672],
      [87.06777954, 21.47205162],
      [87.0661087, 21.47278023],
      [87.06583405, 21.47291183],
      [87.06548309, 21.47324944],
      [87.06495667, 21.47377968],
      [87.0644455, 21.47402954],
      [87.06419373, 21.47439194],
      [87.06382751, 21.47465134],
      [87.06359863, 21.47471809],
      [87.06276703, 21.47555923],
      [87.06194305, 21.47639084],
      [87.06138611, 21.47639084],
      [87.06111145, 21.47666931],
      [87.0605545, 21.4766674],
      [87.06027985, 21.47694397],
      [87.05999756, 21.4766674],
      [87.05916595, 21.4766674],
      [87.0588913, 21.47694397],
      [87.05833435, 21.47694397],
      [87.05805206, 21.4766674],
      [87.0577774, 21.47694397],
      [87.0533371, 21.47694397],
      [87.05305481, 21.4766674],
      [87.05249786, 21.47666931],
      [87.05222321, 21.47639084],
      [87.0513916, 21.47639084],
      [87.05110931, 21.47666931],
      [87.05000305, 21.47666931],
      [87.04972076, 21.47680855],
      [87.04924011, 21.47673988],
      [87.04888916, 21.4766674],
      [87.04833221, 21.47666931],
      [87.04805756, 21.47687912],
      [87.04750061, 21.47687912],
      [87.04722595, 21.47666931],
      [87.04666901, 21.47666931],
      [87.04638672, 21.47639084],
      [87.04611206, 21.47639084],
      [87.04582977, 21.4761219],
      [87.04556274, 21.4761219],
      [87.04547119, 21.47602463],
      [87.04528046, 21.47582817],
      [87.04499817, 21.47583389],
      [87.04444122, 21.47527885],
      [87.04416656, 21.47528076],
      [87.04379272, 21.47490692],
      [87.04360962, 21.47471809],
      [87.04360962, 21.47444534],
      [87.04332733, 21.47417068],
      [87.04332733, 21.47360992],
      [87.04360962, 21.47333336],
      [87.04360962, 21.47305679],
      [87.04389191, 21.47277832],
      [87.04389191, 21.47249985],
      [87.04380798, 21.47221947],
      [87.04373932, 21.47201157],
      [87.04360199, 21.47166443],
      [87.04389191, 21.47056007],
      [87.04400635, 21.47044182],
      [87.04416656, 21.47027779],
      [87.04416656, 21.46972084],
      [87.04444122, 21.46944237],
      [87.04464722, 21.46925163],
      [87.04499817, 21.46918678],
      [87.04573822, 21.46899033],
      [87.04581451, 21.46936798],
      [87.04582977, 21.46998978],
      [87.04576874, 21.47027969],
      [87.04576874, 21.47055435],
      [87.04582977, 21.47083473],
      [87.04605103, 21.47110939],
      [87.04611206, 21.47138977],
      [87.04611206, 21.47166443],
      [87.04626465, 21.47194672],
      [87.04633331, 21.4721508],
      [87.04611206, 21.47249985],
      [87.04611969, 21.47277832],
      [87.04605103, 21.47305679],
      [87.04611206, 21.47333336],
      [87.04618073, 21.47375107],
      [87.04722595, 21.47500038],
      [87.04839325, 21.4754715],
      [87.04888916, 21.47527885],
      [87.04944611, 21.47528076],
      [87.04972076, 21.47500038],
      [87.05000305, 21.47500038],
      [87.05027771, 21.47471809],
      [87.05083466, 21.47472191],
      [87.05133057, 21.47439957],
      [87.05194092, 21.47413445],
      [87.05263519, 21.47371292],
      [87.05326843, 21.47335815],
      [87.0538559, 21.4731102],
      [87.05444336, 21.47305679],
      [87.05527496, 21.47359467],
      [87.05555725, 21.47373962],
      [87.05610657, 21.47417831],
      [87.0569458, 21.4742012],
      [87.05750275, 21.4742012],
      [87.05805206, 21.47417831],
      [87.05833435, 21.47418404],
      [87.05860901, 21.47417641],
      [87.0588913, 21.47417831],
      [87.05944824, 21.4738903],
      [87.0597229, 21.47360992],
      [87.06083679, 21.47361183],
      [87.06111145, 21.47333336],
      [87.06138611, 21.47333336],
      [87.06194305, 21.47277832],
      [87.06194305, 21.47249985],
      [87.0616684, 21.47221947],
      [87.0616684, 21.47166443],
      [87.06138611, 21.47138977],
      [87.06138611, 21.47083092],
      [87.06222534, 21.46999931],
      [87.06222534, 21.46694374],
      [87.06305695, 21.46611023],
      [87.06305695, 21.46583176],
      [87.0633316, 21.46555519],
      [87.0633316, 21.46500015],
      [87.06359863, 21.46472168],
      [87.06361389, 21.46444511],
      [87.06416321, 21.46388817],
      [87.06305695, 21.46277809],
      [87.06305695, 21.46222115],
      [87.0625, 21.46166611],
      [87.0625, 21.46138954],
      [87.0616684, 21.46055984],
      [87.06138611, 21.46055984],
      [87.06053925, 21.45972061],
      [87.0597229, 21.45972061],
      [87.05944824, 21.46000099],
      [87.05944824, 21.46138954],
      [87.05916595, 21.46166992],
      [87.05916595, 21.46277809],
      [87.05944824, 21.46305466],
      [87.05916595, 21.46333313],
      [87.05805206, 21.46333313],
      [87.0577774, 21.4636116],
      [87.05722046, 21.4636116],
      [87.0569458, 21.46333313],
      [87.05638885, 21.46333313],
      [87.0561142, 21.4636116],
      [87.05555725, 21.4636116],
      [87.05548096, 21.46353722],
      [87.05527496, 21.46333313],
      [87.05477905, 21.46320152],
      [87.05435181, 21.46300888],
      [87.05393219, 21.46275902],
      [87.05349731, 21.46247101],
      [87.05309296, 21.46217155],
      [87.05275726, 21.46185112],
      [87.0524292, 21.46158028],
      [87.05212402, 21.46130371],
      [87.05179596, 21.46101952],
      [87.05149078, 21.46066284],
      [87.05114746, 21.46030235],
      [87.0508194, 21.45993423],
      [87.05050659, 21.45957565],
      [87.05039978, 21.45944786],
      [87.05023956, 21.45924377],
      [87.05001068, 21.45895576],
      [87.0497818, 21.45869827],
      [87.04944611, 21.45842934],
      [87.04899597, 21.45824051],
      [87.04854584, 21.45800972],
      [87.04815674, 21.45769691],
      [87.04769897, 21.45741272],
      [87.04722595, 21.4571209],
      [87.04682159, 21.45679283],
      [87.04646301, 21.45644188],
      [87.04615784, 21.45609665],
      [87.04588318, 21.45576286],
      [87.04556274, 21.45546913],
      [87.04521179, 21.4552002],
      [87.04482269, 21.45492363],
      [87.04440308, 21.45463371],
      [87.04397583, 21.45432854],
      [87.04351807, 21.45401955],
      [87.04299927, 21.45368195],
      [87.04251862, 21.45330238],
      [87.04202271, 21.45285034],
      [87.04151154, 21.45243263],
      [87.04103851, 21.45204353],
      [87.04063416, 21.45158958],
      [87.0402298, 21.45116806],
      [87.03975677, 21.45086098],
      [87.03926086, 21.45054817],
      [87.03876495, 21.45025253],
      [87.03829193, 21.44995117],
      [87.03785706, 21.44964981],
      [87.03748322, 21.44933128],
      [87.03710938, 21.44903183],
      [87.03678894, 21.44870949],
      [87.03650665, 21.44836044],
      [87.03620148, 21.44805145],
      [87.03585815, 21.44776154],
      [87.03546143, 21.44748878],
      [87.03504181, 21.44721985],
      [87.03462219, 21.44695091],
      [87.03422546, 21.44669533],
      [87.03388214, 21.44639969],
      [87.03349304, 21.44610977],
      [87.03304291, 21.44580269],
      [87.032547, 21.44547653],
      [87.03203583, 21.44516945],
      [87.03157043, 21.44487381],
      [87.03118134, 21.44451141],
      [87.03084564, 21.44411469],
      [87.03048706, 21.44373131],
      [87.03006744, 21.44339371],
      [87.02961731, 21.44302368],
      [87.02915192, 21.4425869],
      [87.02864838, 21.44209099],
      [87.02805328, 21.44166946],
      [87.02805328, 21.4413929],
      [87.02749634, 21.44083023],
      [87.02722168, 21.44083214],
      [87.02694702, 21.44055557],
      [87.0266571, 21.44055939],
      [87.02555847, 21.43944168],
      [87.02527618, 21.43944359],
      [87.02500153, 21.43916702],
      [87.02416992, 21.43917274],
      [87.02361298, 21.43861008],
      [87.02333069, 21.43861008],
      [87.02278137, 21.43805504],
      [87.02249908, 21.43806267],
      [87.0222168, 21.43778229],
      [87.02194214, 21.43778229],
      [87.0211792, 21.43730164],
      [87.02057648, 21.4369297],
      [87.02005768, 21.43658638],
      [87.01957703, 21.4362812],
      [87.01911163, 21.43602943],
      [87.01863861, 21.43575096],
      [87.01815033, 21.43544006],
      [87.01767731, 21.43511009],
      [87.01724243, 21.43473053],
      [87.01681519, 21.43437195],
      [87.01638031, 21.4340229],
      [87.01593781, 21.43370628],
      [87.01552582, 21.43338013],
      [87.01512909, 21.43307495],
      [87.01473236, 21.43279076],
      [87.01434326, 21.43251038],
      [87.01396179, 21.43223953],
      [87.01355743, 21.43196106],
      [87.01313019, 21.43168068],
      [87.01267242, 21.43139076],
      [87.0121994, 21.43109131],
      [87.01174164, 21.43075371],
      [87.01131439, 21.4303894],
      [87.01087189, 21.43008995],
      [87.0104599, 21.4297924],
      [87.01004791, 21.42954826],
      [87.0096817, 21.42926979],
      [87.00930786, 21.42901039],
      [87.00895691, 21.42869949],
      [87.00857544, 21.42841148],
      [87.00812531, 21.42812538],
      [87.00763702, 21.42780113],
      [87.00708771, 21.42744064],
      [87.00653076, 21.42702293],
      [87.00591278, 21.42664909],
      [87.00533295, 21.4262867],
      [87.00484467, 21.42592239],
      [87.00435638, 21.42571831],
      [87.00395966, 21.42550278],
      [87.0036087, 21.42527962],
      [87.00305176, 21.42472076],
      [87.0027771, 21.42472267],
      [87.00250244, 21.4244442],
      [87.00222015, 21.4244442],
      [87.0019455, 21.42416573],
      [87.00166321, 21.42388916],
      [87.00138855, 21.42388916],
      [87.00110626, 21.42361069],
      [87.0008316, 21.42361069],
      [87.0002594, 21.42277908],
      [87, 21.42250061],
      [86.99973297, 21.42222023],
      [86.99861145, 21.4216671],
      [86.99833679, 21.42139053],
      [86.99777985, 21.42111015],
      [86.99610901, 21.42083359],
      [86.99555206, 21.42055511],
      [86.99555206, 21.42028046],
      [86.9952774, 21.42000008],
      [86.9952774, 21.4197197],
      [86.99472046, 21.41916656],
      [86.99415588, 21.41888046],
      [86.99333191, 21.41861153],
      [86.9916687, 21.4169426],
      [86.99111176, 21.41666412],
      [86.99054718, 21.41611099],
      [86.98999786, 21.41584015],
      [86.98972321, 21.41555977],
      [86.98916626, 21.41527939],
      [86.9883194, 21.41500282],
      [86.9883194, 21.41472054],
      [86.98694611, 21.41333389],
      [86.98583221, 21.41277695],
      [86.98554993, 21.41250038],
      [86.98500061, 21.41222191],
      [86.98472595, 21.41194534],
      [86.98472595, 21.41167259],
      [86.98361206, 21.4113903],
      [86.98332977, 21.41110992],
      [86.98278046, 21.41083336],
      [86.98278046, 21.41055489],
      [86.98166656, 21.41055489],
      [86.98166656, 21.41083336],
      [86.98110962, 21.41083336],
      [86.98027802, 21.40999985],
      [86.98027802, 21.40970993],
      [86.98000336, 21.40944481],
      [86.98000336, 21.40916634],
      [86.97972107, 21.40888977],
      [86.97972107, 21.40833092],
      [86.97944641, 21.40805626],
      [86.97944641, 21.40777969],
      [86.97915649, 21.40750122],
      [86.97915649, 21.40722084],
      [86.97888947, 21.40694427],
      [86.97888947, 21.40666771],
      [86.97833252, 21.40611076],
      [86.97833252, 21.40583038],
      [86.97750092, 21.40500069],
      [86.97693634, 21.40472031],
      [86.97638702, 21.40416718],
      [86.97583008, 21.40390015],
      [86.97556305, 21.40361023],
      [86.97555542, 21.40305328],
      [86.97528076, 21.40278053],
      [86.97528076, 21.40250015],
      [86.97389221, 21.4011097],
      [86.97333527, 21.40083122],
      [86.97083282, 21.39833069],
      [86.97083282, 21.39805603],
      [86.97027588, 21.39749908],
      [86.96971893, 21.39722061],
      [86.96916962, 21.39666748],
      [86.96861267, 21.39639091],
      [86.96806335, 21.39582825],
      [86.96805573, 21.3955555],
      [86.96721649, 21.39471054],
      [86.96722412, 21.39444351],
      [86.96694183, 21.39416885],
      [86.96611023, 21.39389038],
      [86.96555328, 21.39332962],
      [86.96555328, 21.39305687],
      [86.96305847, 21.39056015],
      [86.96221924, 21.39027977],
      [86.96138, 21.38944054],
      [86.96138763, 21.38916588],
      [86.95944214, 21.38722038],
      [86.95944214, 21.38694382],
      [86.95888519, 21.38666725],
      [86.95833588, 21.38611031],
      [86.95833588, 21.3855629],
      [86.95777893, 21.38500023],
      [86.95777893, 21.38472176],
      [86.95722198, 21.38416672],
      [86.95667267, 21.38388824],
      [86.95610809, 21.38332748],
      [86.95527649, 21.38305473],
      [86.95500183, 21.38277817],
      [86.95500183, 21.38249969],
      [86.95417023, 21.38166809],
      [86.95361328, 21.38138962],
      [86.95305634, 21.38083076],
      [86.95305634, 21.38055611],
      [86.95249939, 21.38000107],
      [86.95194244, 21.37973022],
      [86.95083618, 21.37861061],
      [86.95083618, 21.37833023],
      [86.95055389, 21.37805367],
      [86.95054626, 21.37778091],
      [86.94999695, 21.37750053],
      [86.94889069, 21.37639046],
      [86.94777679, 21.3758297],
      [86.94721985, 21.37527847],
      [86.94721985, 21.375],
      [86.94695282, 21.37471962],
      [86.94694519, 21.37444305],
      [86.9466629, 21.3741703],
      [86.9466629, 21.37389183],
      [86.94610596, 21.37333107],
      [86.94611359, 21.37305641],
      [86.9458313, 21.37278175],
      [86.94526672, 21.37249947],
      [86.94526672, 21.37221909],
      [86.94472504, 21.37166595],
      [86.94473267, 21.37138939],
      [86.94444275, 21.37111092],
      [86.94444275, 21.37083054],
      [86.94416809, 21.37055588],
      [86.94402313, 21.37041092],
      [86.9438858, 21.37000084],
      [86.94330597, 21.3698597],
      [86.94167328, 21.36750031],
      [86.94138336, 21.36722183],
      [86.94055176, 21.36611176],
      [86.94033813, 21.3659687],
      [86.94000244, 21.36555099],
      [86.93916321, 21.3647213],
      [86.93873596, 21.36444092],
      [86.93784332, 21.36346817],
      [86.93776703, 21.36313057],
      [86.93638611, 21.36139107],
      [86.93638611, 21.36111069],
      [86.93611145, 21.36055946],
      [86.93583679, 21.3602829],
      [86.93554688, 21.3602829],
      [86.93499756, 21.35972023],
      [86.93499756, 21.35944366],
      [86.93360901, 21.35805511],
      [86.93332672, 21.35806274],
      [86.93305206, 21.35778236],
      [86.93305206, 21.35749817],
      [86.93222046, 21.35666847],
      [86.93222046, 21.35610962],
      [86.9291687, 21.35305786],
      [86.9291687, 21.35279083],
      [86.92861176, 21.35222054],
      [86.92861176, 21.3516674],
      [86.92804718, 21.35111046],
      [86.92804718, 21.35083008],
      [86.92472076, 21.34749985],
      [86.92472076, 21.34721947],
      [86.9236145, 21.3461113],
      [86.92333221, 21.3461113],
      [86.92028046, 21.34305573],
      [86.91999817, 21.34306145],
      [86.91971588, 21.34277916],
      [86.91944122, 21.34277916],
      [86.91722107, 21.34056091],
      [86.91638947, 21.34056091],
      [86.91583252, 21.34000015],
      [86.91555786, 21.34000015],
      [86.9152832, 21.33971977],
      [86.91333008, 21.33972168],
      [86.91306305, 21.34000015],
      [86.91278076, 21.34000015],
      [86.91249847, 21.34028053],
      [86.91166687, 21.34028053],
      [86.91139221, 21.34056091],
      [86.91110992, 21.34056091],
      [86.91098022, 21.34042168],
      [86.9108429, 21.34028053],
      [86.9108429, 21.33971977],
      [86.91027832, 21.33916664],
      [86.91028595, 21.33889008],
      [86.90999603, 21.3386097],
      [86.91000366, 21.33694649],
      [86.91055298, 21.33638954],
      [86.91055298, 21.33611107],
      [86.91166687, 21.3350029],
      [86.91166687, 21.33472061],
      [86.91139221, 21.33444214],
      [86.91139221, 21.33416748],
      [86.91110992, 21.33389282],
      [86.91110992, 21.33333015],
      [86.91083527, 21.3330555],
      [86.9108429, 21.33278275],
      [86.91055298, 21.33250046],
      [86.91055298, 21.33222008],
      [86.91000366, 21.33166695],
      [86.90999603, 21.33139038],
      [86.90972137, 21.33111191],
      [86.90972137, 21.33083344],
      [86.90944672, 21.33055496],
      [86.90944672, 21.3302784],
      [86.90888977, 21.32971954],
      [86.90888977, 21.32944489],
      [86.90777588, 21.3283329],
      [86.90777588, 21.32805634],
      [86.90389252, 21.32417107],
      [86.90361023, 21.32417107],
      [86.90277863, 21.32332993],
      [86.90277863, 21.32305336],
      [86.90110016, 21.32139015],
      [86.90083313, 21.32139015],
      [86.89861298, 21.31917191],
      [86.89833069, 21.31917191],
      [86.89694214, 21.31777954],
      [86.89666748, 21.31777954],
      [86.89639282, 21.31749916],
      [86.89528656, 21.31749916],
      [86.89499664, 21.31777954],
      [86.89417267, 21.31777954],
      [86.89360809, 21.31722069],
      [86.89305878, 21.3172226],
      [86.89221954, 21.31638908],
      [86.89222717, 21.31611061],
      [86.89194489, 21.31583405],
      [86.89194489, 21.31500053],
      [86.89167023, 21.31472015],
      [86.89167023, 21.31138611],
      [86.89138794, 21.31110954],
      [86.89138794, 21.31055641],
      [86.89111328, 21.31027794],
      [86.89111328, 21.30972099],
      [86.89083099, 21.30944252],
      [86.89083099, 21.30916405],
      [86.89111328, 21.30888939],
      [86.89055634, 21.30833054],
      [86.89055634, 21.30750275],
      [86.89027405, 21.30722427],
      [86.89027405, 21.30694389],
      [86.88999939, 21.30666733],
      [86.88999939, 21.30611038],
      [86.88944244, 21.30556107],
      [86.88944244, 21.30528259],
      [86.88899994, 21.30483437],
      [86.88889313, 21.30472565],
      [86.88861084, 21.30444527],
      [86.88861084, 21.30417252],
      [86.88809204, 21.30360413],
      [86.88804626, 21.30332756],
      [86.88777924, 21.30305481],
      [86.88777924, 21.30277824],
      [86.88749695, 21.30249977],
      [86.88749695, 21.30194092],
      [86.88722229, 21.30166626],
      [86.88722229, 21.30111122],
      [86.88694763, 21.30083084],
      [86.88694763, 21.30027771],
      [86.88680267, 21.30013275],
      [86.8861084, 21.2994442],
      [86.8861084, 21.29916763],
      [86.88582611, 21.29889107],
      [86.88582611, 21.29861069],
      [86.88555908, 21.29832077],
      [86.88555908, 21.29805565],
      [86.88527679, 21.29778099],
      [86.88527679, 21.29750061],
      [86.88500214, 21.29722023],
      [86.88500214, 21.29694557],
      [86.88444519, 21.29638863],
      [86.88445282, 21.29611015],
      [86.8841629, 21.29583549],
      [86.8841629, 21.29555321],
      [86.88388824, 21.29527855],
      [86.88388824, 21.29500008],
      [86.88360596, 21.2947197],
      [86.88361359, 21.29444313],
      [86.88276672, 21.29360962],
      [86.88277435, 21.29333115],
      [86.88194275, 21.29249954],
      [86.88194275, 21.29221916],
      [86.88027954, 21.29055595],
      [86.88028717, 21.29027939],
      [86.8797226, 21.28972054],
      [86.8797226, 21.28944397],
      [86.87750244, 21.28722],
      [86.87750244, 21.28694534],
      [86.87526703, 21.28471947],
      [86.87527466, 21.28444672],
      [86.87472534, 21.28388977],
      [86.87472534, 21.2836113],
      [86.8741684, 21.28305626],
      [86.87416077, 21.28277969],
      [86.87388611, 21.28250122],
      [86.87388611, 21.28222084],
      [86.87361145, 21.28194237],
      [86.87361145, 21.2816658],
      [86.87304688, 21.28111076],
      [86.87304688, 21.28083038],
      [86.8722229, 21.28000259],
      [86.87194824, 21.28000259],
      [86.8713913, 21.27944183],
      [86.87110901, 21.27944374],
      [86.87083435, 21.27916718],
      [86.87082672, 21.27889252],
      [86.86972046, 21.27778244],
      [86.86972046, 21.27750969],
      [86.86833191, 21.2761097],
      [86.86833191, 21.27583313],
      [86.86805725, 21.27555466],
      [86.86805725, 21.27527809],
      [86.86833191, 21.27499962],
      [86.86833191, 21.27472115],
      [86.86750031, 21.27388954],
      [86.86750031, 21.27361107],
      [86.86694336, 21.27305984],
      [86.86694336, 21.27277946],
      [86.86638641, 21.27222061],
      [86.86638641, 21.27194595],
      [86.86611176, 21.27166748],
      [86.86611176, 21.27083015],
      [86.86555481, 21.27027512],
      [86.86528015, 21.27028084],
      [86.86499023, 21.27000046],
      [86.86472321, 21.27000046],
      [86.86444092, 21.26972008],
      [86.86444092, 21.26916695],
      [86.86416626, 21.26889038],
      [86.86416626, 21.26861],
      [86.86360931, 21.26805305],
      [86.86360931, 21.2677803],
      [86.86305237, 21.26721954],
      [86.86305237, 21.26694489],
      [86.86166382, 21.26555634],
      [86.86165619, 21.26528168],
      [86.86138916, 21.26499939],
      [86.86138916, 21.2641716],
      [86.86055756, 21.26333046],
      [86.86055756, 21.2630558],
      [86.85943604, 21.2619381],
      [86.85944366, 21.26166725],
      [86.85917664, 21.26139069],
      [86.85917664, 21.26111031],
      [86.85888672, 21.26083374],
      [86.85888672, 21.26055527],
      [86.85832977, 21.26000023],
      [86.85832977, 21.25971985],
      [86.85758972, 21.25760841],
      [86.85694122, 21.25667],
      [86.85694122, 21.25611115],
      [86.85666656, 21.25583076],
      [86.85666656, 21.2555542],
      [86.85639191, 21.25527954],
      [86.85639191, 21.25500107],
      [86.85500336, 21.25361061],
      [86.85500336, 21.25333977],
      [86.85444641, 21.25278282],
      [86.85444641, 21.25250053],
      [86.85415649, 21.25222015],
      [86.85416412, 21.25194359],
      [86.85444641, 21.25166702],
      [86.85360718, 21.2508297],
      [86.85361481, 21.25055504],
      [86.85333252, 21.25027084],
      [86.85333252, 21.24999809],
      [86.85305023, 21.24971962],
      [86.85305786, 21.24944496],
      [86.85250092, 21.24888992],
      [86.85250092, 21.24860954],
      [86.85277557, 21.24833298],
      [86.85221863, 21.24777794],
      [86.85221863, 21.24749947],
      [86.85166931, 21.24694061],
      [86.85166931, 21.24666786],
      [86.85111237, 21.24611092],
      [86.85111237, 21.24555969],
      [86.85056305, 21.24500084],
      [86.85055542, 21.24472237],
      [86.85028076, 21.2444458],
      [86.85028076, 21.24388885],
      [86.84999084, 21.24361038],
      [86.84999084, 21.24333],
      [86.84944153, 21.24277687],
      [86.84944153, 21.24250031],
      [86.84916687, 21.24221992],
      [86.84916687, 21.24194527],
      [86.84860992, 21.24139977],
      [86.84860992, 21.24110985],
      [86.84833527, 21.24083138],
      [86.84833527, 21.24027824],
      [86.84777832, 21.23971939],
      [86.84777832, 21.23944473],
      [86.84750366, 21.23916626],
      [86.84749603, 21.23888969],
      [86.84722137, 21.23860931],
      [86.84722137, 21.23833084],
      [86.84694672, 21.23805618],
      [86.84694672, 21.23778152],
      [86.84638977, 21.23722076],
      [86.84638977, 21.2369442],
      [86.84610748, 21.23666573],
      [86.84610748, 21.23611069],
      [86.84583282, 21.23583031],
      [86.84583282, 21.23555565],
      [86.84527588, 21.23500061],
      [86.84527588, 21.23472023],
      [86.84500122, 21.23444366],
      [86.84500122, 21.23388863],
      [86.84471893, 21.23361015],
      [86.84471893, 21.23333359],
      [86.84444427, 21.23305511],
      [86.84443665, 21.23278046],
      [86.84471893, 21.23250008],
      [86.84500122, 21.23250008],
      [86.84527588, 21.23221016],
      [86.84583282, 21.2322216],
      [86.84610748, 21.23194695],
      [86.84638977, 21.23195076],
      [86.84675598, 21.23158455],
      [86.84889221, 21.2294426],
      [86.84889221, 21.22888947],
      [86.84916687, 21.2286129],
      [86.84916687, 21.22805977],
      [86.84889221, 21.22777939],
      [86.84889221, 21.22667122],
      [86.8483429, 21.22611046],
      [86.84777832, 21.22667122],
      [86.84750366, 21.2266674],
      [86.84722137, 21.22694206],
      [86.84722137, 21.22722054],
      [86.84694672, 21.22750282],
      [86.84694672, 21.22805405],
      [86.84555817, 21.2294426],
      [86.8454361, 21.2294426],
      [86.84500122, 21.2294426],
      [86.84471893, 21.22972488],
      [86.84444427, 21.22972488],
      [86.84417725, 21.22999954],
      [86.84388733, 21.22999954],
      [86.84361267, 21.23027992],
      [86.84333038, 21.22999954],
      [86.84333038, 21.22972107],
      [86.84278107, 21.22916412],
      [86.84278107, 21.22888947],
      [86.84361267, 21.22805977],
      [86.84361267, 21.22777939],
      [86.84378815, 21.2276001],
      [86.84388733, 21.22750282],
      [86.84388733, 21.22722054],
      [86.84361267, 21.22694206],
      [86.84361267, 21.2266674],
      [86.84333038, 21.22639275],
      [86.84333038, 21.22583008],
      [86.84305573, 21.22555542],
      [86.84306335, 21.22500038],
      [86.84278107, 21.22472191],
      [86.84278107, 21.22416687],
      [86.84249878, 21.2238884],
      [86.84249878, 21.22360992],
      [86.84222412, 21.22333336],
      [86.84222412, 21.22305489],
      [86.84194183, 21.22277832],
      [86.84194183, 21.22138977],
      [86.84139252, 21.22083092],
      [86.84139252, 21.22055626],
      [86.84111023, 21.22027016],
      [86.84111023, 21.21972275],
      [86.84055328, 21.21916771],
      [86.84055328, 21.21861076],
      [86.84027863, 21.21833038],
      [86.84027863, 21.21722221],
      [86.83944702, 21.21639061],
      [86.83944702, 21.21611023],
      [86.83972168, 21.21582985],
      [86.83972168, 21.21527863],
      [86.83944702, 21.21500015],
      [86.83944702, 21.21471977],
      [86.83805847, 21.21333122],
      [86.83805847, 21.21110916],
      [86.83833313, 21.2108326],
      [86.83889008, 21.21083069],
      [86.83944702, 21.21138954],
      [86.83944702, 21.21166611],
      [86.83972168, 21.21194458],
      [86.83972168, 21.21221924],
      [86.84027863, 21.21277809],
      [86.84027863, 21.21305466],
      [86.84055328, 21.21333122],
      [86.84055328, 21.21389008],
      [86.84111023, 21.21445084],
      [86.84111023, 21.21500015],
      [86.84166718, 21.21555328],
      [86.84166718, 21.21582985],
      [86.84222412, 21.2163887],
      [86.84221649, 21.21693993],
      [86.84249878, 21.21722984],
      [86.84249878, 21.21750069],
      [86.84305573, 21.21805573],
      [86.84306335, 21.21861076],
      [86.84333038, 21.21889114],
      [86.84333038, 21.21916771],
      [86.84361267, 21.21944427],
      [86.84361267, 21.21972275],
      [86.84416962, 21.22027779],
      [86.84444427, 21.22027779],
      [86.84471893, 21.21999931],
      [86.84500122, 21.22000122],
      [86.84527588, 21.21972084],
      [86.84527588, 21.21916771],
      [86.84500122, 21.21889114],
      [86.84500122, 21.21833038],
      [86.84471893, 21.21805573],
      [86.84471893, 21.21778107],
      [86.84388733, 21.21693993],
      [86.84388733, 21.2163887],
      [86.84333038, 21.21582985],
      [86.84333038, 21.21555328],
      [86.84306335, 21.21528053],
      [86.84306335, 21.21500015],
      [86.84278107, 21.21472359],
      [86.84278107, 21.21416664],
      [86.84194183, 21.21333122],
      [86.84194183, 21.21305466],
      [86.84166718, 21.21277809],
      [86.84166718, 21.21249962],
      [86.84139252, 21.21221924],
      [86.84139252, 21.21194458],
      [86.84111023, 21.21166611],
      [86.84111023, 21.21109962],
      [86.84083557, 21.2108326],
      [86.84083557, 21.21055603],
      [86.84055328, 21.21027946],
      [86.84055328, 21.20999908],
      [86.83999634, 21.20943832],
      [86.83944702, 21.20944405],
      [86.83889008, 21.2088871],
      [86.83889008, 21.20806122],
      [86.83944702, 21.20750046],
      [86.83944702, 21.20722008],
      [86.83777618, 21.20555687],
      [86.83750153, 21.20583344],
      [86.83666992, 21.20583344],
      [86.83638763, 21.20555687],
      [86.83611298, 21.20555687],
      [86.83555603, 21.20611191],
      [86.83528137, 21.20611],
      [86.83500671, 21.20639038],
      [86.83416748, 21.20639038],
      [86.83389282, 21.20611],
      [86.83361053, 21.20611191],
      [86.83333588, 21.20583344],
      [86.83305359, 21.20583344],
      [86.83222198, 21.20499992],
      [86.83139038, 21.20499992],
      [86.83110809, 21.20528984],
      [86.83082581, 21.20499992],
      [86.83055878, 21.20499992],
      [86.82971954, 21.20417023],
      [86.82971954, 21.20306015],
      [86.82943726, 21.20277977],
      [86.82943726, 21.20223045],
      [86.82917023, 21.20194054],
      [86.82917023, 21.20153999],
      [86.82917023, 21.20138931],
      [86.82888031, 21.20111275],
      [86.82888031, 21.20083046],
      [86.82971954, 21.20000267],
      [86.83000183, 21.20000267],
      [86.83082581, 21.19916153],
      [86.83083344, 21.19888878],
      [86.83110809, 21.19861031],
      [86.83110809, 21.19778252],
      [86.83000183, 21.19666862],
      [86.83027649, 21.19638824],
      [86.83027649, 21.19610023],
      [86.83083344, 21.19555473],
      [86.83055878, 21.19527817],
      [86.83055878, 21.19444084],
      [86.83000183, 21.19388962],
      [86.83000183, 21.19361115],
      [86.82943726, 21.19305992],
      [86.82917023, 21.19305611],
      [86.82888794, 21.19333267],
      [86.82776642, 21.19333076],
      [86.82749939, 21.19361115],
      [86.82749939, 21.19388962],
      [86.82694244, 21.19444466],
      [86.82694244, 21.19472122],
      [86.82666779, 21.19499969],
      [86.82666779, 21.19527817],
      [86.82678986, 21.19539833],
      [86.82749939, 21.19611168],
      [86.82777405, 21.19611168],
      [86.82796478, 21.1962986],
      [86.82805634, 21.19638824],
      [86.82861328, 21.19638824],
      [86.82888794, 21.19666672],
      [86.82888031, 21.19721985],
      [86.82917023, 21.19750023],
      [86.82861328, 21.1980629],
      [86.82776642, 21.1980629],
      [86.82722473, 21.19861031],
      [86.8263855, 21.19861031],
      [86.8263855, 21.19833374],
      [86.82611084, 21.19805527],
      [86.82611084, 21.19721985],
      [86.82555389, 21.19666672],
      [86.82527924, 21.19666672],
      [86.82472229, 21.19722176],
      [86.82389069, 21.19638824],
      [86.82389069, 21.19582748],
      [86.82416534, 21.19555473],
      [86.82416534, 21.19527817],
      [86.82306671, 21.19416809],
      [86.82277679, 21.19416809],
      [86.82250214, 21.19388962],
      [86.82250214, 21.19333076],
      [86.82277679, 21.19305611],
      [86.82277679, 21.19277954],
      [86.82306671, 21.19250107],
      [86.82306671, 21.19194031],
      [86.82333374, 21.19166756],
      [86.82332611, 21.19111061],
      [86.82306671, 21.19083023],
      [86.82305908, 21.19055557],
      [86.82250214, 21.19000053],
      [86.82250214, 21.18943977],
      [86.82221985, 21.18916702],
      [86.82221985, 21.18889046],
      [86.82195282, 21.18861008],
      [86.82194519, 21.1883316],
      [86.8216629, 21.18805313],
      [86.8216629, 21.1866703],
      [86.82138824, 21.18639183],
      [86.82138824, 21.18528175],
      [86.82110596, 21.18499947],
      [86.82110596, 21.18471909],
      [86.8208313, 21.18444443],
      [86.8208313, 21.18361092],
      [86.82055664, 21.18333054],
      [86.82055664, 21.1827774],
      [86.82026672, 21.18250084],
      [86.82026672, 21.18193817],
      [86.81972504, 21.18138885],
      [86.81973267, 21.18082809],
      [86.81916809, 21.18027878],
      [86.81916809, 21.17945099],
      [86.8188858, 21.17917061],
      [86.8188858, 21.17860985],
      [86.81861115, 21.17833328],
      [86.81861115, 21.17805672],
      [86.81916809, 21.17749977],
      [86.81944275, 21.17749977],
      [86.82027435, 21.17833328],
      [86.82055664, 21.17833328],
      [86.8208313, 21.17861176],
      [86.82108307, 21.17861176],
      [86.82138824, 21.17860985],
      [86.8216629, 21.17889023],
      [86.82194519, 21.17888832],
      [86.82211304, 21.17906189],
      [86.82221985, 21.17917061],
      [86.82250214, 21.17917061],
      [86.82277679, 21.17945099],
      [86.82306671, 21.17945099],
      [86.82332611, 21.17917061],
      [86.82389069, 21.17917061],
      [86.82389069, 21.17860985],
      [86.82305908, 21.17777824],
      [86.82306671, 21.17749977],
      [86.82333374, 21.1772213],
      [86.8236084, 21.1772213],
      [86.82416534, 21.17666435],
      [86.82444763, 21.17667007],
      [86.82583618, 21.17806053],
      [86.82611084, 21.17806053],
      [86.8272171, 21.17694092],
      [86.82833099, 21.17694664],
      [86.82833099, 21.17638969],
      [86.82805634, 21.17611122],
      [86.82805634, 21.17361069],
      [86.82694244, 21.17250252],
      [86.82694244, 21.17222023],
      [86.82722473, 21.17194366],
      [86.82722473, 21.1716671],
      [86.82749939, 21.17139244],
      [86.82749939, 21.17056274],
      [86.82666779, 21.1697197],
      [86.82666779, 21.16944504],
      [86.82694244, 21.16918182],
      [86.82694244, 21.1680584],
      [86.8272171, 21.16777992],
      [86.8272171, 21.16694069],
      [86.82694244, 21.16666794],
      [86.82694244, 21.16500092],
      [86.82666779, 21.16472054],
      [86.82666779, 21.1641674],
      [86.82639313, 21.16389084],
      [86.82639313, 21.16250038],
      [86.82583618, 21.16194534],
      [86.82583618, 21.16055107],
      [86.82611084, 21.16027832],
      [86.82611084, 21.15806007],
      [86.82554626, 21.15749931],
      [86.82527924, 21.15749931],
      [86.82513428, 21.15735817],
      [86.82499695, 21.15722275],
      [86.82499695, 21.15694427],
      [86.82472229, 21.15667152],
      [86.82472229, 21.15611076],
      [86.82554626, 21.15527916],
      [86.82554626, 21.15221977],
      [86.82583618, 21.15194511],
      [86.82583618, 21.15167046],
      [86.82554626, 21.15139008],
      [86.82552338, 21.15051842],
      [86.82527924, 21.15027809],
      [86.82527924, 21.14972115],
      [86.82499695, 21.14944458],
      [86.82499695, 21.14861107],
      [86.82417297, 21.14777946],
      [86.82472229, 21.14722061],
      [86.82472229, 21.14694214],
      [86.82499695, 21.14666748],
      [86.82499695, 21.14611053],
      [86.82472229, 21.14583015],
      [86.82472229, 21.14500046],
      [86.82444763, 21.14472008],
      [86.82444763, 21.14333344],
      [86.82472229, 21.14305496],
      [86.82472229, 21.14138985],
      [86.82417297, 21.14083099],
      [86.8236084, 21.1408329],
      [86.8236084, 21.13999939],
      [86.82389069, 21.13972092],
      [86.8236084, 21.13944435],
      [86.8236084, 21.13888931],
      [86.82332611, 21.13861084],
      [86.82332611, 21.13833046],
      [86.82389069, 21.13777733],
      [86.82417297, 21.13778114],
      [86.82666779, 21.13528061],
      [86.82666779, 21.13500023],
      [86.82694244, 21.13471985],
      [86.82694244, 21.13361359],
      [86.82749939, 21.13305473],
      [86.82749939, 21.13194466],
      [86.82777405, 21.13166618],
      [86.82776642, 21.13056183],
      [86.82805634, 21.13028145],
      [86.82805634, 21.12805748],
      [86.82833099, 21.12778091],
      [86.82833099, 21.12693977],
      [86.82805634, 21.12666893],
      [86.82805634, 21.1258297],
      [86.82833099, 21.12555695],
      [86.82833099, 21.12528038],
      [86.82861328, 21.125],
      [86.82861328, 21.12445068],
      [86.82888031, 21.1241703],
      [86.82888031, 21.12388039],
      [86.82917023, 21.12361145],
      [86.82917023, 21.12333488],
      [86.82944489, 21.12305641],
      [86.82943726, 21.12277985],
      [86.83000183, 21.1222229],
      [86.83000183, 21.12194252],
      [86.83027649, 21.12166405],
      [86.83027649, 21.12111282],
      [86.83055878, 21.12082291],
      [86.83055878, 21.12055588],
      [86.83082581, 21.12028122],
      [86.83082581, 21.11944008],
      [86.83055878, 21.11916733],
      [86.83082581, 21.11889267],
      [86.83055878, 21.11861038],
      [86.83082581, 21.11861038],
      [86.83082581, 21.11750031],
      [86.83110809, 21.11721992],
      [86.83110809, 21.11694527],
      [86.83139038, 21.11667061],
      [86.83139038, 21.11610985],
      [86.83166504, 21.11583328],
      [86.83166504, 21.11555481],
      [86.83194733, 21.11527824],
      [86.83194733, 21.11444092],
      [86.83222198, 21.11416626],
      [86.83222198, 21.11333084],
      [86.83305359, 21.11249924],
      [86.83304596, 21.11222076],
      [86.83333588, 21.1119442],
      [86.83333588, 21.11166763],
      [86.83361053, 21.11139107],
      [86.83361053, 21.11083031],
      [86.83388519, 21.11055565],
      [86.83389282, 21.11028099],
      [86.83416748, 21.11000061],
      [86.83416748, 21.10972023],
      [86.83444214, 21.10944557],
      [86.83444214, 21.10888863],
      [86.83472443, 21.10861206],
      [86.83472443, 21.10833168],
      [86.83499908, 21.10805321],
      [86.83500671, 21.10750008],
      [86.83472443, 21.10722351],
      [86.83472443, 21.10666656],
      [86.83583069, 21.1055603],
      [86.83583069, 21.10499954],
      [86.83555603, 21.10471916],
      [86.83555603, 21.1044445],
      [86.83583069, 21.10416603],
      [86.83583069, 21.10333061],
      [86.83611298, 21.10305595],
      [86.83611298, 21.10277939],
      [86.83666992, 21.10222054],
      [86.83666992, 21.10194397],
      [86.83694458, 21.1016674],
      [86.83693695, 21.10056114],
      [86.83666992, 21.10028076],
      [86.83666992, 21.09971046],
      [86.83694458, 21.09944534],
      [86.83721924, 21.09945107],
      [86.83750153, 21.09917068],
      [86.83777618, 21.09917068],
      [86.8391571, 21.09778023],
      [86.8391571, 21.09721947],
      [86.83944702, 21.09694672],
      [86.83944702, 21.09638977],
      [86.83972168, 21.09610939],
      [86.83972168, 21.09583473],
      [86.83999634, 21.09555626],
      [86.83999634, 21.09527969],
      [86.84055328, 21.09472084],
      [86.84055328, 21.09444237],
      [86.84083557, 21.0941658],
      [86.8408432, 21.09390068],
      [86.84055328, 21.09361267],
      [86.84055328, 21.09333038],
      [86.84111023, 21.09277725],
      [86.84111023, 21.09250259],
      [86.84166718, 21.09194183],
      [86.84166718, 21.09166718],
      [86.84194183, 21.09139252],
      [86.84194183, 21.09111023],
      [86.84221649, 21.09083939],
      [86.84221649, 21.08999825],
      [86.84249878, 21.08971977],
      [86.84249878, 21.08916664],
      [86.84278107, 21.08888817],
      [86.84278107, 21.08722115],
      [86.84305573, 21.08694458],
      [86.84305573, 21.08666611],
      [86.84333038, 21.08638954],
      [86.84333038, 21.08611107],
      [86.84361267, 21.08583069],
      [86.84361267, 21.08527756],
      [86.84388733, 21.08500099],
      [86.84388733, 21.08444023],
      [86.84361267, 21.08416748],
      [86.84361267, 21.08389091],
      [86.84388733, 21.08361053],
      [86.84388733, 21.08306122],
      [86.84417725, 21.08278084],
      [86.84417725, 21.08222008],
      [86.84388733, 21.08194542],
      [86.84388733, 21.08166695],
      [86.84443665, 21.08111],
      [86.84471893, 21.08111382],
      [86.84500122, 21.08083153],
      [86.84527588, 21.08083153],
      [86.84555817, 21.08055305],
      [86.84583282, 21.08056068],
      [86.84805298, 21.07832909],
      [86.84805298, 21.07805634],
      [86.84860992, 21.07749939],
      [86.84860992, 21.07722092],
      [86.84889221, 21.07694435],
      [86.84916687, 21.07666588],
      [86.84916687, 21.07638931],
      [86.84944153, 21.07611084],
      [86.84944153, 21.07583046],
      [86.84972382, 21.0755558],
      [86.84971619, 21.07527924],
      [86.85028076, 21.07472038],
      [86.85028076, 21.07444382],
      [86.85055542, 21.07416725],
      [86.85056305, 21.07389069],
      [86.85083008, 21.07361031],
      [86.85083008, 21.07332993],
      [86.85111237, 21.07305527],
      [86.85111237, 21.07277107],
      [86.85138702, 21.07250023],
      [86.85138702, 21.07139015],
      [86.85221863, 21.07055092],
      [86.85221863, 21.07027817],
      [86.8527832, 21.06973076],
      [86.8527832, 21.06944084],
      [86.85305786, 21.06916618],
      [86.85305023, 21.06888962],
      [86.85333252, 21.06861115],
      [86.85333252, 21.06833076],
      [86.85361481, 21.06805611],
      [86.85360718, 21.06722069],
      [86.85388947, 21.06694221],
      [86.85388947, 21.06666756],
      [86.85444641, 21.06611061],
      [86.85444641, 21.06555939],
      [86.85472107, 21.06528282],
      [86.85472107, 21.06500244],
      [86.85527802, 21.06444168],
      [86.85555267, 21.06444359],
      [86.85583496, 21.06416702],
      [86.85582733, 21.06360054],
      [86.85610962, 21.06333351],
      [86.85610962, 21.06277847],
      [86.85639191, 21.06249809],
      [86.85639191, 21.06195068],
      [86.85666656, 21.0616684],
      [86.85666656, 21.06138992],
      [86.85694122, 21.06110954],
      [86.85694122, 21.06055641],
      [86.85721588, 21.06027985],
      [86.85721588, 21.05972099],
      [86.85694122, 21.05944443],
      [86.85694122, 21.05888939],
      [86.85721588, 21.05861092],
      [86.85721588, 21.05833054],
      [86.85749817, 21.05805779],
      [86.85749817, 21.05779076],
      [86.85778046, 21.05750084],
      [86.85778046, 21.05694008],
      [86.85805511, 21.05666733],
      [86.85806274, 21.05611038],
      [86.85832977, 21.05611038],
      [86.85806274, 21.05583],
      [86.85805511, 21.05555534],
      [86.85832977, 21.05528069],
      [86.85832977, 21.05500031],
      [86.85917664, 21.05417061],
      [86.85943604, 21.05417061],
      [86.86055756, 21.0530529],
      [86.86055756, 21.05277824],
      [86.86110687, 21.05221939],
      [86.8611145, 21.05194473],
      [86.86166382, 21.0513916],
      [86.86165619, 21.05110931],
      [86.86222076, 21.0505619],
      [86.86222076, 21.05028152],
      [86.86332703, 21.04917145],
      [86.86360931, 21.04917145],
      [86.8638916, 21.04888916],
      [86.86416626, 21.04888916],
      [86.86611176, 21.04693985],
      [86.86611176, 21.04638863],
      [86.86638641, 21.04611015],
      [86.86638641, 21.04583359],
      [86.86777496, 21.04444695],
      [86.86776733, 21.04417038],
      [86.86833191, 21.04360962],
      [86.86833191, 21.04305649],
      [86.86888885, 21.04249954],
      [86.86888885, 21.04222107],
      [86.87000275, 21.0411129],
      [86.87000275, 21.04083061],
      [86.8702774, 21.04055595],
      [86.8702774, 21.04027939],
      [86.87055206, 21.04000282],
      [86.87055206, 21.03972054],
      [86.87083435, 21.03944206],
      [86.87083435, 21.0391674],
      [86.87111664, 21.03889275],
      [86.87111664, 21.03861046],
      [86.8730545, 21.03666687],
      [86.8730545, 21.0363884],
      [86.87361145, 21.03582954],
      [86.87361145, 21.03555489],
      [86.87388611, 21.03527832],
      [86.87388611, 21.03499985],
      [86.87610626, 21.03277969],
      [86.87610626, 21.03222084],
      [86.87666321, 21.03166771],
      [86.87666321, 21.03139114],
      [86.87722015, 21.03083038],
      [86.87722015, 21.03027725],
      [86.87889099, 21.02861023],
      [86.87917328, 21.02861023],
      [86.88083649, 21.02694511],
      [86.88083649, 21.02667046],
      [86.88111115, 21.02639008],
      [86.88111115, 21.0261097],
      [86.8813858, 21.02583122],
      [86.8813858, 21.02555466],
      [86.88166809, 21.02527809],
      [86.88166809, 21.02499962],
      [86.88249969, 21.02417183],
      [86.88249969, 21.02388954],
      [86.88276672, 21.02360916],
      [86.88276672, 21.02333069],
      [86.88361359, 21.02249908],
      [86.88360596, 21.02222061],
      [86.8841629, 21.02166748],
      [86.8841629, 21.02139091],
      [86.88471985, 21.02083015],
      [86.88471985, 21.0205555],
      [86.88500214, 21.02028084],
      [86.88500214, 21.01972008],
      [86.88527679, 21.01972198],
      [86.88583374, 21.01916695],
      [86.88610077, 21.01917076],
      [86.88889313, 21.01638985],
      [86.88889313, 21.01610947],
      [86.88916779, 21.01583481],
      [86.88916779, 21.01555634],
      [86.88944244, 21.01527977],
      [86.88944244, 21.01499939],
      [86.8897171, 21.01472282],
      [86.88972473, 21.01416588],
      [86.89026642, 21.01361275],
      [86.89026642, 21.01333046],
      [86.89055634, 21.0130558],
      [86.89055634, 21.01278114],
      [86.89083099, 21.01248932],
      [86.89083099, 21.01194382],
      [86.89111328, 21.01166725],
      [86.89111328, 21.01111031],
      [86.89138794, 21.01083183],
      [86.89138794, 21.0102787],
      [86.89167023, 21.00999832],
      [86.89167023, 21.00945091],
      [86.89193726, 21.00917053],
      [86.89193726, 21.00888824],
      [86.89332581, 21.00749969],
      [86.89332581, 21.00722122],
      [86.89360809, 21.00694466],
      [86.89360809, 21.00666618],
      [86.89389038, 21.00638962],
      [86.89389038, 21.00555992],
      [86.89417267, 21.00527954],
      [86.89417267, 21.00500107],
      [86.89444733, 21.00472069],
      [86.89444733, 21.00444412],
      [86.89472198, 21.00416756],
      [86.89472198, 21.00389099],
      [86.89528656, 21.00333023],
      [86.89527893, 21.00305557],
      [86.89554596, 21.00278091],
      [86.89554596, 21.00250053],
      [86.89666748, 21.00139046],
      [86.89666748, 21.00055122],
      [86.89666748, 21.00027847],
      [86.89694214, 21.00000191],
      [86.89749908, 20.99945068],
      [86.89749908, 20.9991703],
      [86.89778137, 20.99889183],
      [86.89778137, 20.99860954],
      [86.89861298, 20.99777794],
      [86.89861298, 20.99694061],
      [86.89888763, 20.99666595],
      [86.89888763, 20.99611092],
      [86.89916992, 20.99583054],
      [86.89916992, 20.99472237],
      [86.89944458, 20.99444389],
      [86.89943695, 20.99417114],
      [86.89971924, 20.99389076],
      [86.89971924, 20.99333],
      [86.90000153, 20.99305534],
      [86.90000153, 20.99278069],
      [86.90027618, 20.99250031],
      [86.90027618, 20.99221992],
      [86.90055847, 20.99194527],
      [86.90055847, 20.99110985],
      [86.90067291, 20.99006081],
      [86.9016571, 20.98890114],
      [86.90209961, 20.98749733],
      [86.9023819, 20.98689079],
      [86.90294647, 20.98669052],
      [86.90331268, 20.98641968],
      [86.90331268, 20.9860096],
      [86.90316772, 20.98486519],
      [86.90294647, 20.98411942],
      [86.90345001, 20.98317719],
      [86.90400696, 20.98311043],
      [86.90416718, 20.98237038],
      [86.90416718, 20.97999954],
      [86.90444183, 20.97972107],
      [86.90444183, 20.97916794],
      [86.90499115, 20.97777939],
      [86.90585327, 20.97662926],
      [86.90628052, 20.97616005],
      [86.90663147, 20.97534943],
      [86.90670776, 20.9743309],
      [86.90734863, 20.97338867],
      [86.90734863, 20.97250938],
      [86.90749359, 20.97122955],
      [86.90798187, 20.96980286],
      [86.90847778, 20.96912193],
      [86.90854645, 20.96858215],
      [86.90833282, 20.96804237],
      [86.90805054, 20.96750259],
      [86.90783691, 20.96696091],
      [86.90798187, 20.96662521],
      [86.90904999, 20.96664047],
      [86.90956116, 20.96628952],
      [86.90985107, 20.96587944],
      [86.9101181, 20.96507263],
      [86.9101181, 20.96345139],
      [86.9101944, 20.96298027],
      [86.91026306, 20.96244049],
      [86.91026306, 20.9617691],
      [86.9101181, 20.96142769],
      [86.90985107, 20.96089172],
      [86.90990448, 20.96035004],
      [86.91047668, 20.96006966],
      [86.91104889, 20.95974159],
      [86.91104889, 20.95927048],
      [86.9108429, 20.95880127],
      [86.9108429, 20.95750237],
      [86.91055298, 20.95722008],
      [86.91055298, 20.95638847],
      [86.91028595, 20.95610809],
      [86.91000366, 20.95611191],
      [86.91000366, 20.95583344],
      [86.91055298, 20.9552784],
      [86.91110992, 20.9552784],
      [86.91139221, 20.95499992],
      [86.91166687, 20.95499992],
      [86.91194153, 20.95471954],
      [86.91194153, 20.95444489],
      [86.91249847, 20.95388794],
      [86.91249847, 20.95360947],
      [86.91278076, 20.9533329],
      [86.91278076, 20.95277786],
      [86.91249847, 20.95249939],
      [86.91249847, 20.95111084],
      [86.91361237, 20.95000076],
      [86.91361237, 20.94972038],
      [86.91378784, 20.94891739],
      [86.91378784, 20.94831085],
      [86.91414642, 20.94750023],
      [86.91464996, 20.94696236],
      [86.91514587, 20.94581413],
      [86.9153595, 20.9448719],
      [86.91578674, 20.94425964],
      [86.91657257, 20.94264412],
      [86.91693115, 20.94129562],
      [86.91728973, 20.93981171],
      [86.91779327, 20.93919945],
      [86.91835785, 20.93893242],
      [86.9193573, 20.93866348],
      [86.91999817, 20.93805695],
      [86.92056274, 20.9375],
      [86.92055511, 20.93722153],
      [86.92082977, 20.93694687],
      [86.92082977, 20.9366703],
      [86.92056274, 20.93638992],
      [86.92056274, 20.93583107],
      [86.92028046, 20.93555641],
      [86.92028046, 20.93499947],
      [86.92056274, 20.9347229],
      [86.92055511, 20.93444443],
      [86.92082977, 20.93416405],
      [86.92082977, 20.93388939],
      [86.92166901, 20.93305969],
      [86.92166901, 20.93250275],
      [86.92193604, 20.93222046],
      [86.92194366, 20.93194389],
      [86.92222595, 20.93166733],
      [86.92222595, 20.93111038],
      [86.92250061, 20.93083191],
      [86.92250061, 20.93055534],
      [86.9227829, 20.93028259],
      [86.9227829, 20.9299984],
      [86.92305756, 20.92972183],
      [86.92305756, 20.92861176],
      [86.92472076, 20.92694473],
      [86.92472076, 20.92666626],
      [86.92582703, 20.92555809],
      [86.92582703, 20.92527008],
      [86.92610931, 20.92499924],
      [86.92610931, 20.92472076],
      [86.9263916, 20.9244442],
      [86.9263916, 20.92416573],
      [86.92694092, 20.92361069],
      [86.92694092, 20.92333031],
      [86.92722321, 20.92305565],
      [86.92722321, 20.92278099],
      [86.92861176, 20.92139053],
      [86.92861176, 20.92082977],
      [86.92888641, 20.92055321],
      [86.92888641, 20.92000008],
      [86.9291687, 20.9197197],
      [86.9291687, 20.91944504],
      [86.93055725, 20.9180603],
      [86.93055725, 20.91694069],
      [86.93083191, 20.91666603],
      [86.93083191, 20.91638947],
      [86.93110657, 20.91610909],
      [86.93110657, 20.91556168],
      [86.93139648, 20.91527748],
      [86.93139648, 20.91500092],
      [86.9319458, 20.91444397],
      [86.9319458, 20.9141674],
      [86.93250275, 20.91361046],
      [86.93250275, 20.91333008],
      [86.9327774, 20.91305542],
      [86.9327774, 20.91250038],
      [86.93305206, 20.91222],
      [86.9327774, 20.91194534],
      [86.9327774, 20.9113903],
      [86.93305206, 20.91110992],
      [86.93305206, 20.91083336],
      [86.9338913, 20.90999985],
      [86.9338913, 20.90971947],
      [86.93416595, 20.90944481],
      [86.93416595, 20.90916634],
      [86.93445587, 20.90888977],
      [86.93444824, 20.9086113],
      [86.9347229, 20.90833473],
      [86.93499756, 20.90833473],
      [86.93499756, 20.9066658],
      [86.93527985, 20.90639114],
      [86.93527985, 20.90583038],
      [86.9355545, 20.90555573],
      [86.93554688, 20.90528107],
      [86.93583679, 20.90500259],
      [86.93583679, 20.90472031],
      [86.93638611, 20.90416718],
      [86.93638611, 20.90389252],
      [86.9366684, 20.90360832],
      [86.9366684, 20.90332985],
      [86.93694305, 20.90305519],
      [86.93694305, 20.90277863],
      [86.93723297, 20.90249825],
      [86.93722534, 20.90222168],
      [86.9375, 20.90194511],
      [86.9375, 20.90167046],
      [86.93888855, 20.90027809],
      [86.93888855, 20.89916992],
      [86.93860626, 20.89888954],
      [86.93860626, 20.89861107],
      [86.93888855, 20.89833069],
      [86.93888855, 20.89777756],
      [86.9383316, 20.89722061],
      [86.9383316, 20.89694595],
      [86.9394455, 20.89583588],
      [86.9394455, 20.8955555],
      [86.93972015, 20.89529037],
      [86.94082642, 20.89306068],
      [86.94108582, 20.89232063],
      [86.94129944, 20.89145088],
      [86.94165802, 20.89097023],
      [86.94237518, 20.89016151],
      [86.9430542, 20.88972282],
      [86.94416809, 20.88861084],
      [86.94416809, 20.88833046],
      [86.94444275, 20.8880558],
      [86.94444275, 20.88777924],
      [86.94452667, 20.8868103],
      [86.94460297, 20.88626862],
      [86.94474792, 20.8853302],
      [86.9451828, 20.88479042],
      [86.94589233, 20.8841095],
      [86.94645691, 20.88323402],
      [86.94681549, 20.88242531],
      [86.94682312, 20.88167953],
      [86.94689178, 20.88114357],
      [86.94745636, 20.88040161],
      [86.94810486, 20.88019753],
      [86.94860077, 20.87985992],
      [86.94895935, 20.87925339],
      [86.94966888, 20.87851143],
      [86.95002747, 20.87790489],
      [86.95083618, 20.87722015],
      [86.95166779, 20.87638855],
      [86.95166779, 20.87610817],
      [86.95194244, 20.87583351],
      [86.95194244, 20.87555504],
      [86.95276642, 20.87471962],
      [86.95277405, 20.87444496],
      [86.95333099, 20.87388992],
      [86.95305634, 20.87360954],
      [86.95305634, 20.87305641],
      [86.95361328, 20.87249947],
      [86.95361328, 20.87222099],
      [86.95417023, 20.87166786],
      [86.95417023, 20.87083054],
      [86.95583344, 20.86916733],
      [86.95582581, 20.86833],
      [86.95777893, 20.86638832],
      [86.95777893, 20.86610985],
      [86.95805359, 20.86583138],
      [86.95805359, 20.86555481],
      [86.95888519, 20.86471939],
      [86.95888519, 20.86444473],
      [86.95916748, 20.86416626],
      [86.95916748, 20.8638916],
      [86.95944214, 20.86360931],
      [86.95944214, 20.86249924],
      [86.9597168, 20.86222076],
      [86.95972443, 20.8619442],
      [86.95999908, 20.86166573],
      [86.95999908, 20.86111069],
      [86.96028137, 20.86083031],
      [86.96028137, 20.86055565],
      [86.96055603, 20.86027908],
      [86.96055603, 20.85972023],
      [86.96083069, 20.85944366],
      [86.96083069, 20.8591671],
      [86.96055603, 20.85889053],
      [86.96138, 20.85806084],
      [86.96138, 20.85778046],
      [86.96166992, 20.85750008],
      [86.96166992, 20.85639],
      [86.96193695, 20.85612106],
      [86.96193695, 20.85472107],
      [86.96221924, 20.8544445],
      [86.96225739, 20.85354996],
      [86.96253967, 20.85280609],
      [86.96253967, 20.85098267],
      [86.96282196, 20.85031128],
      [86.96325684, 20.84922981],
      [86.96339417, 20.84801674],
      [86.96353912, 20.84707069],
      [86.96368408, 20.84612656],
      [86.96396637, 20.84504128],
      [86.96453857, 20.84390068],
      [86.9647522, 20.84289169],
      [86.96517944, 20.84193993],
      [86.9653244, 20.84079742],
      [86.96533203, 20.83971977],
      [86.96553802, 20.83910942],
      [86.96575165, 20.8379612],
      [86.96553802, 20.83721924],
      [86.9659729, 20.83641052],
      [86.96639252, 20.83539963],
      [86.96681976, 20.83466148],
      [86.96695709, 20.83337021],
      [86.96717834, 20.83256531],
      [86.96775055, 20.83128166],
      [86.9681778, 20.83067322],
      [86.96868134, 20.8300705],
      [86.96897125, 20.82925987],
      [86.96944427, 20.82833481],
      [86.97000122, 20.82777786],
      [86.97000122, 20.82639122],
      [86.97027588, 20.82611275],
      [86.97027588, 20.82332993],
      [86.97000122, 20.82305527],
      [86.97000122, 20.82195091],
      [86.96971893, 20.82167053],
      [86.96971893, 20.82138824],
      [86.96943665, 20.82110977],
      [86.96944427, 20.82027626],
      [86.96916962, 20.82000923],
      [86.96916962, 20.81944084],
      [86.96888733, 20.81916618],
      [86.96888733, 20.81888962],
      [86.96861267, 20.81861115],
      [86.96861267, 20.81805992],
      [86.96833038, 20.81777954],
      [86.96833038, 20.81666946],
      [86.96806335, 20.81639099],
      [86.96806335, 20.81583023],
      [86.96778107, 20.81555557],
      [86.96778107, 20.81472015],
      [86.96749878, 20.81444168],
      [86.96749878, 20.81388855],
      [86.96722412, 20.81361389],
      [86.96722412, 20.81222343],
      [86.96694183, 20.81194496],
      [86.96694183, 20.81081963],
      [86.96666718, 20.81055641],
      [86.96666718, 20.80861092],
      [86.96639252, 20.80833054],
      [86.96639252, 20.80750084],
      [86.96611023, 20.80722046],
      [86.96611023, 20.80500031],
      [86.9658432, 20.80471992],
      [86.96583557, 20.80361176],
      [86.96555328, 20.80333328],
      [86.96555328, 20.80277824],
      [86.96527863, 20.80249977],
      [86.96466827, 20.80175018],
      [86.96466827, 20.80025864],
      [86.96472168, 20.79916573],
      [86.96472168, 20.79805946],
      [86.96443939, 20.7977829],
      [86.96472168, 20.79750252],
      [86.96443939, 20.79722023],
      [86.96444702, 20.7966671],
      [86.96414948, 20.79638863],
      [86.96414948, 20.79527855],
      [86.96389008, 20.79499817],
      [86.96389008, 20.7947197],
      [86.96416473, 20.79444504],
      [86.96414948, 20.79417038],
      [86.96389008, 20.79388809],
      [86.96389008, 20.79360962],
      [86.96360779, 20.79333305],
      [86.96360779, 20.79249954],
      [86.96333313, 20.79222107],
      [86.96333313, 20.79166794],
      [86.96305847, 20.79138947],
      [86.96305847, 20.79111099],
      [86.96277618, 20.79083061],
      [86.96277618, 20.79055405],
      [86.96260834, 20.7903862],
      [86.96250153, 20.79027748],
      [86.96250153, 20.79000092],
      [86.96221924, 20.78971291],
      [86.96221924, 20.78944588],
      [86.96111298, 20.7883358],
      [86.96111298, 20.78805542],
      [86.95804596, 20.78500175],
      [86.95777893, 20.78500175],
      [86.95749664, 20.78471947],
      [86.95722198, 20.78500175],
      [86.95639038, 20.78500175],
      [86.95582581, 20.78443909],
      [86.95556641, 20.78443909],
      [86.95471954, 20.78528023],
      [86.95444489, 20.78527832],
      [86.95417023, 20.78555489],
      [86.95388794, 20.78555489],
      [86.95333099, 20.78611374],
      [86.95276642, 20.78611183],
      [86.95249939, 20.7863903],
      [86.95194244, 20.7863903],
      [86.95166779, 20.78667068],
      [86.95111084, 20.78666687],
      [86.95083618, 20.78694534],
      [86.95055389, 20.78694534],
      [86.95027924, 20.78722382],
      [86.94917297, 20.78722],
      [86.94889069, 20.78750038],
      [86.94805908, 20.78750038],
      [86.94777679, 20.78778076],
      [86.94721985, 20.78778076],
      [86.94695282, 20.78806114],
      [86.9466629, 20.78805542],
      [86.94638824, 20.7883358],
      [86.94555664, 20.78833008],
      [86.94527435, 20.78861046],
      [86.94473267, 20.78861046],
      [86.94444275, 20.78889084],
      [86.9438858, 20.78889084],
      [86.94361115, 20.78917122],
      [86.9430542, 20.7891674],
      [86.94277954, 20.78944588],
      [86.9422226, 20.78944588],
      [86.94194794, 20.78972244],
      [86.94026947, 20.78971291],
      [86.94000244, 20.79000092],
      [86.93972015, 20.79000092],
      [86.93945313, 20.79027939],
      [86.93527985, 20.79027939],
      [86.93499756, 20.79055977],
      [86.93333435, 20.79055405],
      [86.93305206, 20.79083252],
      [86.93083191, 20.79083252],
      [86.93055725, 20.79055405],
      [86.92973328, 20.79055977],
      [86.92944336, 20.79027939],
      [86.9291687, 20.79027939],
      [86.92888641, 20.79055977],
      [86.92583466, 20.79055405],
      [86.92555237, 20.79083252],
      [86.92527771, 20.79083252],
      [86.92500305, 20.79055405],
      [86.92388916, 20.79055405],
      [86.9236145, 20.79083252],
      [86.92166901, 20.79083252],
      [86.92138672, 20.79055405],
      [86.92111206, 20.79055405],
      [86.92082977, 20.79083252],
      [86.91944122, 20.79083061],
      [86.91916656, 20.79111099],
      [86.91805267, 20.79111099],
      [86.91777802, 20.79138947],
      [86.91694641, 20.79138947],
      [86.91665649, 20.79166985],
      [86.91583252, 20.79166794],
      [86.91555786, 20.7919445],
      [86.91500092, 20.7919445],
      [86.91471863, 20.79222298],
      [86.90999603, 20.79222107],
      [86.90972137, 20.79249954],
      [86.90305328, 20.79249954],
      [86.90277863, 20.79277992],
      [86.90249634, 20.79277992],
      [86.90222168, 20.7930603],
      [86.9016571, 20.7930603],
      [86.90110016, 20.79249954],
      [86.90055847, 20.79249954],
      [86.90027618, 20.79277992],
      [86.89833069, 20.79277992],
      [86.89805603, 20.7930603],
      [86.89666748, 20.79305649],
      [86.89638519, 20.79333305],
      [86.89499664, 20.79333305],
      [86.89472198, 20.79361153],
      [86.89417267, 20.79360962],
      [86.89389038, 20.79388809],
      [86.89332581, 20.79388809],
      [86.89305878, 20.79417038],
      [86.89193726, 20.79417038],
      [86.89167023, 20.79445076],
      [86.89055634, 20.79445076],
      [86.89026642, 20.79417038],
      [86.88944244, 20.79417038],
      [86.88916016, 20.79445076],
      [86.88833618, 20.79444504],
      [86.88805389, 20.7947216],
      [86.88722229, 20.7947197],
      [86.88694763, 20.79499817],
      [86.88639069, 20.79499817],
      [86.88610077, 20.79527855],
      [86.88582611, 20.79527855],
      [86.88555908, 20.79556084],
      [86.88527679, 20.79556084],
      [86.88500214, 20.79583931],
      [86.88471985, 20.79583359],
      [86.88444519, 20.79611015],
      [86.8841629, 20.79610825],
      [86.88388824, 20.79638863],
      [86.88360596, 20.79638863],
      [86.8833313, 20.79667282],
      [86.88305664, 20.7966671],
      [86.88277435, 20.79694366],
      [86.88222504, 20.79694366],
      [86.88194275, 20.79722023],
      [86.88166809, 20.79722023],
      [86.8813858, 20.79750252],
      [86.87944794, 20.79638863],
      [86.87917328, 20.79638863],
      [86.87889099, 20.79610825],
      [86.87808228, 20.79610825],
      [86.8769455, 20.79611015],
      [86.87666321, 20.79583359],
      [86.87638855, 20.79583931],
      [86.87610626, 20.79556084],
      [86.8758316, 20.79556084],
      [86.87555695, 20.79527855],
      [86.87361145, 20.79527855],
      [86.87333679, 20.79499817],
      [86.87277985, 20.79499817],
      [86.87249756, 20.7947197],
      [86.87166595, 20.7947216],
      [86.8713913, 20.79444504],
      [86.87068176, 20.79445076],
      [86.87055206, 20.79445076],
      [86.8702774, 20.79417038],
      [86.86972046, 20.79417038],
      [86.8694458, 20.79388809],
      [86.86860657, 20.79388809],
      [86.86833191, 20.79360962],
      [86.86805725, 20.79361153],
      [86.86798096, 20.79353714],
      [86.86777496, 20.79333305],
      [86.86750031, 20.79333305],
      [86.86722565, 20.79305649],
      [86.8666687, 20.7930603],
      [86.86638641, 20.79277992],
      [86.86611176, 20.79277992],
      [86.8658371, 20.79249954],
      [86.86528015, 20.79249954],
      [86.86499023, 20.79222107],
      [86.86444092, 20.79222298],
      [86.86416626, 20.7919445],
      [86.8638916, 20.7919445],
      [86.86360931, 20.79166794],
      [86.86332703, 20.79166985],
      [86.86277771, 20.79111099],
      [86.86250305, 20.79111099],
      [86.86222076, 20.79083061],
      [86.86194611, 20.79083252],
      [86.86138916, 20.79027748],
      [86.86110687, 20.79027939],
      [86.86083221, 20.79000092],
      [86.86055756, 20.79000092],
      [86.8602829, 20.78971291],
      [86.86000061, 20.78972244],
      [86.85944366, 20.7891674],
      [86.85917664, 20.78917122],
      [86.85806274, 20.78806114],
      [86.85778046, 20.78806114],
      [86.85721588, 20.78750038],
      [86.85694122, 20.78750038],
      [86.85666656, 20.78722],
      [86.85610962, 20.78722382],
      [86.85527802, 20.7863884],
      [86.85500336, 20.7863903],
      [86.8548584, 20.78624916],
      [86.85472107, 20.78611374],
      [86.85444641, 20.78611374],
      [86.85434723, 20.78601456],
      [86.85416412, 20.78583145],
      [86.85388947, 20.78583145],
      [86.85361481, 20.78555489],
      [86.85333252, 20.78555489],
      [86.85305786, 20.78527832],
      [86.8527832, 20.78528023],
      [86.85221863, 20.78471947],
      [86.85194397, 20.78471947],
      [86.85138702, 20.78416634],
      [86.85111237, 20.78417015],
      [86.85083008, 20.78390121],
      [86.85056305, 20.78417015],
      [86.85028076, 20.78390121],
      [86.84999084, 20.78390121],
      [86.84916687, 20.78306007],
      [86.8483429, 20.78306007],
      [86.84777832, 20.78249931],
      [86.84749603, 20.78249931],
      [86.84722137, 20.78222084],
      [86.84694672, 20.78222275],
      [86.84684753, 20.78212738],
      [86.84666443, 20.78194427],
      [86.84638977, 20.78194427],
      [86.84625244, 20.78180885],
      [86.84610748, 20.7816658],
      [86.84555817, 20.78166771],
      [86.84527588, 20.78138924],
      [86.84500122, 20.78138924],
      [86.84471893, 20.78111076],
      [86.84416962, 20.78111076],
      [86.84388733, 20.7808342],
      [86.84361267, 20.78083992],
      [86.84333038, 20.78055954],
      [86.84278107, 20.78055954],
      [86.84249878, 20.78028107],
      [86.84221649, 20.78028107],
      [86.84194183, 20.78000069],
      [86.84139252, 20.78000069],
      [86.84111023, 20.77972031],
      [86.84083557, 20.77972221],
      [86.84055328, 20.77944374],
      [86.84027863, 20.77944374],
      [86.83999634, 20.77916718],
      [86.83944702, 20.77917099],
      [86.83889008, 20.77861023],
      [86.83860779, 20.77861023],
      [86.83833313, 20.77832985],
      [86.83805847, 20.77833366],
      [86.83777618, 20.77805519],
      [86.83721924, 20.77806091],
      [86.83693695, 20.777771],
      [86.83666992, 20.77777863],
      [86.83638763, 20.77750015],
      [86.83611298, 20.77750015],
      [86.83583069, 20.77721977],
      [86.83555603, 20.77722168],
      [86.83528137, 20.77694511],
      [86.83500671, 20.77695084],
      [86.8347168, 20.77667046],
      [86.83444214, 20.77667046],
      [86.83389282, 20.7761116],
      [86.83333588, 20.77555084],
      [86.83305359, 20.77555466],
      [86.83249664, 20.77499962],
      [86.83222198, 20.77499962],
      [86.83139038, 20.77416992],
      [86.83110809, 20.77416992],
      [86.83082581, 20.77388954],
      [86.83055878, 20.77388954],
      [86.83027649, 20.77361107],
      [86.83027649, 20.77333069],
      [86.82971954, 20.77277756],
      [86.82943726, 20.77277946],
      [86.82917023, 20.7725029],
      [86.82888031, 20.7725029],
      [86.82805634, 20.77166939],
      [86.82776642, 20.77166939],
      [86.82485962, 20.76874924],
      [86.82195282, 20.76583099],
      [86.82194519, 20.76555634],
      [86.82157135, 20.7651844],
      [86.8208313, 20.76444435],
      [86.8208313, 20.76416779],
      [86.82055664, 20.76389122],
      [86.82055664, 20.76361084],
      [86.82000732, 20.76305962],
      [86.82000732, 20.7627697],
      [86.8188858, 20.76166725],
      [86.8188858, 20.76139069],
      [86.81804657, 20.76056099],
      [86.81804657, 20.76028061],
      [86.81777954, 20.76000023],
      [86.81777954, 20.75972939],
      [86.81749725, 20.75945091],
      [86.81749725, 20.75917053],
      [86.8172226, 20.75889015],
      [86.8172226, 20.75832939],
      [86.81694794, 20.75805473],
      [86.81694794, 20.7575016],
      [86.81667328, 20.75721931],
      [86.81666565, 20.75694466],
      [86.81639099, 20.75666618],
      [86.81639099, 20.75639153],
      [86.8161087, 20.75610924],
      [86.8161087, 20.75444031],
      [86.81583405, 20.75416756],
      [86.81582642, 20.75360107],
      [86.81555176, 20.75333405],
      [86.81555176, 20.75305557],
      [86.81532288, 20.75282097],
      [86.8152771, 20.75250053],
      [86.81500244, 20.75222015],
      [86.81500244, 20.75138855],
      [86.81472015, 20.75111008],
      [86.81472015, 20.75083351],
      [86.8133316, 20.74944687],
      [86.8133316, 20.7491703],
      [86.81305695, 20.74888992],
      [86.81305695, 20.74860954],
      [86.81277466, 20.74833488],
      [86.81277466, 20.74805641],
      [86.81356049, 20.7462101],
      [86.81369781, 20.74588776],
      [86.81412506, 20.74494362],
      [86.8146286, 20.74372864],
      [86.81576538, 20.74420166],
      [86.81666565, 20.74444389],
      [86.81694031, 20.74444008],
      [86.81749725, 20.74500275],
      [86.81777954, 20.74500275],
      [86.81861115, 20.74417114],
      [86.81861115, 20.74389267],
      [86.81833649, 20.74361038],
      [86.81861115, 20.74332809],
      [86.81861115, 20.74027634],
      [86.81833649, 20.73999977],
      [86.81833649, 20.73859978],
      [86.8180542, 20.73833275],
      [86.81807709, 20.7378006],
      [86.81778717, 20.73624039],
      [86.81771088, 20.73496246],
      [86.81771088, 20.73347664],
      [86.81742859, 20.73266983],
      [86.81749725, 20.73110962],
      [86.81749725, 20.72999954],
      [86.8172226, 20.72971916],
      [86.8172226, 20.7294445],
      [86.81694794, 20.72916603],
      [86.81694031, 20.72889137],
      [86.81667328, 20.72860909],
      [86.81666565, 20.72833252],
      [86.81639099, 20.72805595],
      [86.81639099, 20.72722054],
      [86.8161087, 20.72694397],
      [86.8161087, 20.72611046],
      [86.81639099, 20.72583008],
      [86.8161087, 20.72555542],
      [86.8161087, 20.72442055],
      [86.8161087, 20.7238884],
      [86.81555176, 20.72332954],
      [86.81555176, 20.72277832],
      [86.8152771, 20.72249985],
      [86.8152771, 20.72221947],
      [86.81500244, 20.72194481],
      [86.81500244, 20.72166634],
      [86.81416321, 20.72083282],
      [86.81388855, 20.72083473],
      [86.81361389, 20.72055626],
      [86.8133316, 20.72056007],
      [86.81305695, 20.72027969],
      [86.81276703, 20.72027969],
      [86.80665588, 20.71417046],
      [86.80665588, 20.71388817],
      [86.8033371, 20.71055984],
      [86.80207825, 20.70965958],
      [86.80136108, 20.70885468],
      [86.80065155, 20.70777321],
      [86.80014801, 20.70656013],
      [86.79972076, 20.7052803],
      [86.79972076, 20.70471954],
      [86.79916382, 20.70416641],
      [86.79915619, 20.70389175],
      [86.79860687, 20.70332909],
      [86.7986145, 20.70305634],
      [86.79805756, 20.70248985],
      [86.79805756, 20.70222282],
      [86.79722595, 20.70138741],
      [86.79722595, 20.70111084],
      [86.79638672, 20.70028114],
      [86.79638672, 20.70000076],
      [86.7958374, 20.69943047],
      [86.79582977, 20.69916725],
      [86.79556274, 20.69889069],
      [86.79556274, 20.69861031],
      [86.79499817, 20.69805527],
      [86.79499817, 20.69778061],
      [86.79444122, 20.69721985],
      [86.79444122, 20.69666672],
      [86.79416656, 20.69639015],
      [86.79416656, 20.69610977],
      [86.79389191, 20.69583321],
      [86.79389191, 20.69555473],
      [86.79360962, 20.69527817],
      [86.79360962, 20.69499969],
      [86.79332733, 20.69472122],
      [86.79333496, 20.69416618],
      [86.79305267, 20.69388962],
      [86.79305267, 20.69333076],
      [86.79277802, 20.69305611],
      [86.79277802, 20.69277954],
      [86.79250336, 20.69250107],
      [86.79250336, 20.69194031],
      [86.79222107, 20.69166756],
      [86.79222107, 20.6913929],
      [86.79194641, 20.69111252],
      [86.79194641, 20.69055176],
      [86.79166412, 20.6902771],
      [86.79165649, 20.69000244],
      [86.79138947, 20.68971825],
      [86.79138947, 20.68944359],
      [86.79111481, 20.68916702],
      [86.79110718, 20.68860817],
      [86.79083252, 20.68833351],
      [86.79083252, 20.68805504],
      [86.79055786, 20.68778038],
      [86.79055786, 20.68721962],
      [86.79027557, 20.68694496],
      [86.7902832, 20.6866703],
      [86.79000092, 20.68638992],
      [86.79000092, 20.68583107],
      [86.78971863, 20.68555641],
      [86.789711, 20.68527985],
      [86.78943634, 20.68499947],
      [86.78943634, 20.68445015],
      [86.78916931, 20.68416977],
      [86.78916931, 20.68388939],
      [86.78888702, 20.68361092],
      [86.78888702, 20.68305969],
      [86.78861237, 20.68278122],
      [86.78861237, 20.68250084],
      [86.78833008, 20.68222046],
      [86.78833008, 20.68138885],
      [86.78806305, 20.68111038],
      [86.78805542, 20.68083382],
      [86.78833008, 20.68055534],
      [86.78833008, 20.68028069],
      [86.78861237, 20.68000031],
      [86.78861237, 20.67861176],
      [86.78888702, 20.67833138],
      [86.78888702, 20.67805481],
      [86.78861237, 20.67777824],
      [86.78861237, 20.67721939],
      [86.78833008, 20.67694473],
      [86.78833008, 20.67666626],
      [86.78861237, 20.6763916],
      [86.78861237, 20.67528152],
      [86.78833008, 20.67499924],
      [86.78833008, 20.67472076],
      [86.78805542, 20.6744442],
      [86.78805542, 20.67416573],
      [86.78778076, 20.67388916],
      [86.78778076, 20.67361069],
      [86.78749847, 20.67333031],
      [86.78749847, 20.67277718],
      [86.78721619, 20.67250824],
      [86.78721619, 20.67222023],
      [86.78694153, 20.67194366],
      [86.78694153, 20.67166519],
      [86.78610992, 20.67082977],
      [86.78610992, 20.67055511],
      [86.78555298, 20.67000008],
      [86.78555298, 20.66945076],
      [86.78499603, 20.66889],
      [86.78499603, 20.66860962],
      [86.78444672, 20.66805649],
      [86.78444672, 20.66777992],
      [86.7838974, 20.66722107],
      [86.78388977, 20.66666603],
      [86.78360748, 20.66638184],
      [86.78360748, 20.6661129],
      [86.78277588, 20.66527939],
      [86.78277588, 20.66500282],
      [86.78083801, 20.66306114],
      [86.78083801, 20.66278076],
      [86.77971649, 20.66167068],
      [86.77944183, 20.66167068],
      [86.77861023, 20.66082954],
      [86.77833557, 20.66083336],
      [86.77805328, 20.66055489],
      [86.77777863, 20.66055489],
      [86.77500153, 20.65777779],
      [86.77500153, 20.65751076],
      [86.77471161, 20.65722084],
      [86.77471924, 20.65694427],
      [86.77388763, 20.65611076],
      [86.77388763, 20.65583038],
      [86.77278137, 20.65472221],
      [86.77278137, 20.65444565],
      [86.77222443, 20.6538887],
      [86.7722168, 20.65361023],
      [86.77194214, 20.65332985],
      [86.77194214, 20.65305519],
      [86.77166748, 20.65278053],
      [86.77166748, 20.65250015],
      [86.77083588, 20.65167046],
      [86.77083588, 20.65137863],
      [86.77027893, 20.65083122],
      [86.77027893, 20.65055466],
      [86.76999664, 20.65027809],
      [86.76999664, 20.64971924],
      [86.76833344, 20.64805603],
      [86.76832581, 20.64777756],
      [86.76805878, 20.64749908],
      [86.76805878, 20.64722061],
      [86.76777649, 20.64694405],
      [86.76777649, 20.64666748],
      [86.76750183, 20.64639091],
      [86.76750183, 20.64611053],
      [86.76721954, 20.64583015],
      [86.76721954, 20.64527512],
      [86.76693726, 20.64500046],
      [86.76693726, 20.64472008],
      [86.76667023, 20.64444351],
      [86.76667023, 20.63944435],
      [86.76638794, 20.63916588],
      [86.76638794, 20.63694191],
      [86.76618958, 20.63487434],
      [86.76597595, 20.63224411],
      [86.76583862, 20.63008499],
      [86.76541138, 20.62799072],
      [86.7650528, 20.62650871],
      [86.76416779, 20.62471962],
      [86.76416779, 20.62444496],
      [86.76361084, 20.62389183],
      [86.76361084, 20.62360954],
      [86.76249695, 20.62249947],
      [86.76249695, 20.62221909],
      [86.76194763, 20.62166595],
      [86.76194763, 20.62111092],
      [86.76139069, 20.62055969],
      [86.76139069, 20.62028122],
      [86.76055908, 20.61944008],
      [86.76055908, 20.61916733],
      [86.75972748, 20.61833],
      [86.75944519, 20.61833382],
      [86.75805664, 20.61694527],
      [86.75776672, 20.61695099],
      [86.75749969, 20.61667061],
      [86.75723267, 20.61667061],
      [86.75694275, 20.61639023],
      [86.7563858, 20.61639023],
      [86.75624847, 20.61624908],
      [86.75611115, 20.61610985],
      [86.75583649, 20.61611176],
      [86.75577545, 20.61449242],
      [86.75569916, 20.61388206],
      [86.75569916, 20.61334419],
      [86.75741577, 20.61355019],
      [86.75805664, 20.61388969],
      [86.7583313, 20.61417007],
      [86.75888824, 20.61416626],
      [86.7591629, 20.61444473],
      [86.75971985, 20.61444473],
      [86.76000214, 20.6147213],
      [86.76027679, 20.61471939],
      [86.76055908, 20.61499977],
      [86.76083374, 20.61499977],
      [86.7611084, 20.61527824],
      [86.76139069, 20.61527824],
      [86.76166534, 20.61555481],
      [86.76194763, 20.61555099],
      [86.76277161, 20.61639023],
      [86.76304626, 20.61639023],
      [86.76333618, 20.61667061],
      [86.76361084, 20.61667061],
      [86.76389313, 20.61695099],
      [86.76416779, 20.61695099],
      [86.76583099, 20.61861038],
      [86.76583099, 20.61889076],
      [86.76750183, 20.62055588],
      [86.76750183, 20.62083054],
      [86.76777649, 20.62111092],
      [86.76777649, 20.62138748],
      [86.76860809, 20.62221909],
      [86.76860809, 20.62249947],
      [86.76889038, 20.62277985],
      [86.76889038, 20.62333107],
      [86.76917267, 20.62360954],
      [86.76917267, 20.6241703],
      [86.76944733, 20.62444496],
      [86.76944733, 20.62471962],
      [86.76999664, 20.62527847],
      [86.76999664, 20.6258297],
      [86.77083588, 20.62667084],
      [86.77083588, 20.62750053],
      [86.77111053, 20.62778091],
      [86.77111053, 20.62805557],
      [86.77138519, 20.62833595],
      [86.77139282, 20.62972069],
      [86.77166748, 20.62999916],
      [86.77166748, 20.63027],
      [86.77194214, 20.63055992],
      [86.77194214, 20.63166618],
      [86.77166748, 20.63194466],
      [86.77194214, 20.63222122],
      [86.77194214, 20.63277626],
      [86.77222443, 20.63305473],
      [86.7722168, 20.63360977],
      [86.77194214, 20.63388824],
      [86.77194214, 20.63444519],
      [86.77166748, 20.63471985],
      [86.77166748, 20.63528061],
      [86.77138519, 20.63555527],
      [86.77139282, 20.63582993],
      [86.77111053, 20.6361084],
      [86.77166748, 20.63666725],
      [86.77166748, 20.63722038],
      [86.77139282, 20.63750267],
      [86.77166748, 20.63778114],
      [86.77166748, 20.6380558],
      [86.77139282, 20.63833046],
      [86.77139282, 20.63889122],
      [86.77153015, 20.64017105],
      [86.77138519, 20.64166641],
      [86.77111053, 20.64194489],
      [86.77111053, 20.64221001],
      [86.77194214, 20.64305687],
      [86.77194214, 20.64361],
      [86.7722168, 20.64389038],
      [86.7722168, 20.64417076],
      [86.77305603, 20.64500046],
      [86.77333069, 20.64500046],
      [86.77361298, 20.64528084],
      [86.77555847, 20.64528084],
      [86.77583313, 20.64557076],
      [86.77722168, 20.64557076],
      [86.77749634, 20.64528084],
      [86.77916718, 20.64528084],
      [86.77944183, 20.64500046],
      [86.77999878, 20.64500046],
      [86.78056335, 20.6444397],
      [86.78083038, 20.64444351],
      [86.78111267, 20.64416695],
      [86.78138733, 20.64417076],
      [86.78193665, 20.64361],
      [86.78194427, 20.64333344],
      [86.78221893, 20.64305687],
      [86.78250122, 20.64306068],
      [86.78277588, 20.6427803],
      [86.78305817, 20.6427784],
      [86.78324127, 20.64296341],
      [86.78360748, 20.64333344],
      [86.78416443, 20.6427784],
      [86.78472137, 20.6427803],
      [86.78499603, 20.64306068],
      [86.78555298, 20.64305687],
      [86.78583527, 20.64333344],
      [86.78833008, 20.64333344],
      [86.78861237, 20.64361191],
      [86.79055786, 20.64361],
      [86.79083252, 20.64389038],
      [86.79110718, 20.64389038],
      [86.79138947, 20.64417076],
      [86.79166412, 20.64416695],
      [86.79187012, 20.64436913],
      [86.79194641, 20.64444351],
      [86.79250336, 20.6444397],
      [86.79305267, 20.64500046],
      [86.79416656, 20.64500046],
      [86.79444122, 20.64528084],
      [86.79499817, 20.64528084],
      [86.79528046, 20.64500046],
      [86.79722595, 20.64500046],
      [86.79750061, 20.64472008],
      [86.79777527, 20.64472198],
      [86.79805756, 20.64444351],
      [86.7986145, 20.64444351],
      [86.79916382, 20.64388847],
      [86.79973602, 20.64394379],
      [86.79988098, 20.64390755],
      [86.8001709, 20.64383888],
      [86.80062866, 20.6437397],
      [86.80116272, 20.64381027],
      [86.80171967, 20.64385033],
      [86.80231476, 20.64386177],
      [86.80291748, 20.64387131],
      [86.80350494, 20.64387321],
      [86.80405426, 20.64384842],
      [86.80452728, 20.64376068],
      [86.80497742, 20.64362907],
      [86.80555725, 20.64361],
      [86.80583191, 20.64333344],
      [86.8069458, 20.64333344],
      [86.80722046, 20.64305687],
      [86.80750275, 20.64306068],
      [86.8077774, 20.6427803],
      [86.80805206, 20.6427803],
      [86.80860901, 20.64221001],
      [86.8088913, 20.64222145],
      [86.80999756, 20.64111137],
      [86.80999756, 20.64083481],
      [86.81027985, 20.64055634],
      [86.81027985, 20.64027977],
      [86.81083679, 20.63972282],
      [86.81083679, 20.63944435],
      [86.81111145, 20.63916588],
      [86.81134033, 20.63888168],
      [86.8117218, 20.63856316],
      [86.81212616, 20.63824654],
      [86.81254578, 20.6379509],
      [86.81297302, 20.63764954],
      [86.81344604, 20.63739586],
      [86.81396484, 20.63713074],
      [86.81459808, 20.63686943],
      [86.81524658, 20.63661194],
      [86.81552887, 20.6364727],
      [86.81587219, 20.63640022],
      [86.81636047, 20.63635445],
      [86.81687927, 20.63645935],
      [86.81731415, 20.63668442],
      [86.81771851, 20.63694191],
      [86.81816101, 20.63722038],
      [86.81861115, 20.63754082],
      [86.81903839, 20.63791084],
      [86.81945038, 20.63832092],
      [86.81985474, 20.63874817],
      [86.82022858, 20.6391716],
      [86.82055664, 20.63958359],
      [86.8208313, 20.63997269],
      [86.82105255, 20.64035034],
      [86.8212204, 20.64074707],
      [86.82142639, 20.64112663],
      [86.82164764, 20.64147949],
      [86.82187653, 20.64183807],
      [86.82211304, 20.64217949],
      [86.82227325, 20.64253044],
      [86.82242584, 20.64286995],
      [86.82254791, 20.64322853],
      [86.82267761, 20.64359283],
      [86.82279968, 20.64397621],
      [86.82290649, 20.6443882],
      [86.82301331, 20.64487839],
      [86.82306671, 20.64541054],
      [86.82306671, 20.64597511],
      [86.82298279, 20.64651108],
      [86.82283783, 20.64703178],
      [86.82264709, 20.64749908],
      [86.82247162, 20.64793587],
      [86.82229614, 20.64834023],
      [86.82216644, 20.64874268],
      [86.822052, 20.64912033],
      [86.82190704, 20.64946747],
      [86.82177734, 20.64977837],
      [86.82162476, 20.6500473],
      [86.82147217, 20.65030098],
      [86.82131958, 20.6505661],
      [86.82115173, 20.6508007],
      [86.82097626, 20.6510601],
      [86.82083893, 20.65133286],
      [86.8207016, 20.65160179],
      [86.82057953, 20.65190125],
      [86.82047272, 20.6522007],
      [86.82034302, 20.65249252],
      [86.82021332, 20.65278053],
      [86.82006836, 20.65304947],
      [86.81991577, 20.65335464],
      [86.81970215, 20.6536808],
      [86.81942749, 20.6540699],
      [86.81909943, 20.65454102],
      [86.81877136, 20.65510368],
      [86.81848145, 20.65571976],
      [86.81819153, 20.65631104],
      [86.81794739, 20.65683937],
      [86.81772614, 20.65732956],
      [86.81751251, 20.65777969],
      [86.81730652, 20.65824127],
      [86.81710815, 20.65871429],
      [86.81694031, 20.65921021],
      [86.81677246, 20.65975189],
      [86.81656647, 20.66027641],
      [86.81630707, 20.6607399],
      [86.81601715, 20.66114998],
      [86.81574249, 20.66151047],
      [86.81547546, 20.66187859],
      [86.81521606, 20.66224289],
      [86.81495667, 20.66261101],
      [86.81468201, 20.66294861],
      [86.81439972, 20.66331482],
      [86.81408691, 20.66365814],
      [86.81373596, 20.66396713],
      [86.81334686, 20.66422081],
      [86.81299591, 20.66451263],
      [86.81265259, 20.66483116],
      [86.81226349, 20.66513252],
      [86.81185913, 20.66540146],
      [86.81147766, 20.66573906],
      [86.8110733, 20.66609955],
      [86.81060791, 20.66643524],
      [86.81013489, 20.66676331],
      [86.80961609, 20.66703033],
      [86.80912018, 20.66727066],
      [86.8086319, 20.66750145],
      [86.80817413, 20.66774368],
      [86.80771637, 20.66798019],
      [86.80724335, 20.66819954],
      [86.80666351, 20.66833305],
      [86.80638885, 20.66861153],
      [86.80610657, 20.66860962],
      [86.80583191, 20.66889],
      [86.80500031, 20.66889],
      [86.80473328, 20.66917038],
      [86.80361176, 20.66917038],
      [86.8033371, 20.66889],
      [86.80194092, 20.66889],
      [86.80180359, 20.66875076],
      [86.80166626, 20.66861153],
      [86.8013916, 20.66861153],
      [86.80097198, 20.66819572],
      [86.80055237, 20.66777802],
      [86.80055237, 20.66749954],
      [86.79915619, 20.6661129],
      [86.79915619, 20.66583061],
      [86.79869843, 20.66535759],
      [86.79837036, 20.66505051],
      [86.79802704, 20.6648407],
      [86.7976532, 20.66476059],
      [86.79727173, 20.66480064],
      [86.79695892, 20.66493797],
      [86.79672241, 20.66519928],
      [86.79650879, 20.66557121],
      [86.7963028, 20.66604996],
      [86.79618073, 20.66666985],
      [86.79612732, 20.66736984],
      [86.79613495, 20.66810036],
      [86.79621124, 20.66883278],
      [86.79638672, 20.66953087],
      [86.7966156, 20.67018509],
      [86.79686737, 20.67074013],
      [86.79709625, 20.67123985],
      [86.79733276, 20.67168045],
      [86.79762268, 20.67208099],
      [86.79795837, 20.67250633],
      [86.79831696, 20.67287254],
      [86.7986908, 20.67311859],
      [86.79907227, 20.67337036],
      [86.79946136, 20.6736908],
      [86.79988861, 20.6740284],
      [86.80039978, 20.67435455],
      [86.8010025, 20.67466354],
      [86.80164337, 20.67494965],
      [86.80223846, 20.67521095],
      [86.80285645, 20.67538071],
      [86.80345917, 20.67547035],
      [86.80397797, 20.67547035],
      [86.80444336, 20.67551804],
      [86.80484772, 20.67555046],
      [86.8052597, 20.67561913],
      [86.80568695, 20.6757412],
      [86.80618286, 20.67578125],
      [86.80648041, 20.67576218],
      [86.80665588, 20.67575073],
      [86.8070755, 20.67566872],
      [86.80745697, 20.67560005],
      [86.80782318, 20.67562866],
      [86.80818939, 20.6757412],
      [86.80860138, 20.67579079],
      [86.80905914, 20.67580032],
      [86.80950928, 20.67579079],
      [86.80995178, 20.67575073],
      [86.81040192, 20.67567062],
      [86.81080627, 20.6757412],
      [86.81125641, 20.67579079],
      [86.81171417, 20.67580223],
      [86.81217194, 20.67579651],
      [86.81262207, 20.67579079],
      [86.81304932, 20.67578125],
      [86.8134613, 20.67572975],
      [86.81385803, 20.67562675],
      [86.81427002, 20.6756115],
      [86.81469727, 20.67569351],
      [86.81512451, 20.67568207],
      [86.8155365, 20.6756115],
      [86.81596375, 20.6756115],
      [86.81639862, 20.67556763],
      [86.81685638, 20.67554092],
      [86.81735229, 20.67551994],
      [86.81784821, 20.67546082],
      [86.81830597, 20.67534065],
      [86.81881714, 20.67528152],
      [86.81936646, 20.67525101],
      [86.81993866, 20.67518997],
      [86.82047272, 20.6750412],
      [86.82099915, 20.67493057],
      [86.82150269, 20.67477036],
      [86.82202148, 20.67464828],
      [86.82253265, 20.67448997],
      [86.82308197, 20.67437935],
      [86.82360077, 20.6742382],
      [86.82411194, 20.67410851],
      [86.82457733, 20.67394066],
      [86.8250885, 20.67381859],
      [86.82559204, 20.67366028],
      [86.82611847, 20.6735363],
      [86.82660675, 20.67332077],
      [86.82710266, 20.67304993],
      [86.82758331, 20.67278099],
      [86.82805634, 20.67252159],
      [86.82853699, 20.67226982],
      [86.82897186, 20.67198181],
      [86.82939911, 20.67169952],
      [86.82982635, 20.67141914],
      [86.83024597, 20.67114067],
      [86.83067322, 20.6708107],
      [86.83110046, 20.67044258],
      [86.83153534, 20.67004395],
      [86.83200836, 20.6696434],
      [86.83246613, 20.66921043],
      [86.83291626, 20.66873741],
      [86.83331299, 20.66822815],
      [86.83370209, 20.6677475],
      [86.83409119, 20.66737938],
      [86.83441162, 20.66703987],
      [86.83463287, 20.66673279],
      [86.83474731, 20.6664505],
      [86.83487701, 20.66621017],
      [86.83502197, 20.66596031],
      [86.83518982, 20.66571236],
      [86.83531952, 20.66542244],
      [86.83547211, 20.66514778],
      [86.83560181, 20.66484833],
      [86.83574677, 20.66456032],
      [86.83586884, 20.66424942],
      [86.83600616, 20.66395569],
      [86.83608246, 20.66365051],
      [86.83612061, 20.66333771],
      [86.83615875, 20.66303062],
      [86.83627319, 20.66275978],
      [86.83641052, 20.66244125],
      [86.83659363, 20.66205788],
      [86.8367691, 20.66152763],
      [86.8368988, 20.6608696],
      [86.83699799, 20.66016006],
      [86.83715057, 20.65949059],
      [86.83721161, 20.6588707],
      [86.8372345, 20.65832901],
      [86.8372879, 20.65785027],
      [86.83744049, 20.65742111],
      [86.83753967, 20.65695953],
      [86.83769989, 20.65650368],
      [86.83792877, 20.65607643],
      [86.83817291, 20.65563965],
      [86.83841705, 20.65523911],
      [86.83863068, 20.65481949],
      [86.83882904, 20.65439987],
      [86.83899689, 20.65390015],
      [86.83930206, 20.65341949],
      [86.83969879, 20.65294266],
      [86.84012604, 20.65242767],
      [86.84056091, 20.65197945],
      [86.84100342, 20.65162659],
      [86.84142303, 20.65134811],
      [86.84181976, 20.65116119],
      [86.84217072, 20.65100098],
      [86.8425293, 20.65088844],
      [86.84291077, 20.6508007],
      [86.84332275, 20.65075493],
      [86.84375763, 20.65073967],
      [86.84421539, 20.65073776],
      [86.8446579, 20.65076256],
      [86.84506226, 20.65081978],
      [86.84541321, 20.65088081],
      [86.84572601, 20.65092087],
      [86.84606171, 20.6510601],
      [86.84645844, 20.65124321],
      [86.84692383, 20.65144348],
      [86.84739685, 20.65166283],
      [86.8478775, 20.65187263],
      [86.84835815, 20.65197945],
      [86.84879303, 20.65209007],
      [86.8491745, 20.65222359],
      [86.84955597, 20.65241051],
      [86.84998322, 20.65269089],
      [86.8504715, 20.65304947],
      [86.85099792, 20.65343666],
      [86.85154724, 20.65377045],
      [86.85206604, 20.65406036],
      [86.85253906, 20.65439034],
      [86.8529892, 20.65471268],
      [86.85350037, 20.65501976],
      [86.85399628, 20.65539169],
      [86.85452271, 20.65578079],
      [86.85504913, 20.6561203],
      [86.85553741, 20.65647888],
      [86.85597992, 20.65682983],
      [86.85639191, 20.65718079],
      [86.85681915, 20.65748978],
      [86.85728455, 20.65776825],
      [86.85777283, 20.65805054],
      [86.85822296, 20.65837288],
      [86.85861206, 20.658741],
      [86.8589859, 20.65912247],
      [86.85942841, 20.65942955],
      [86.85985565, 20.65978432],
      [86.8602066, 20.66024971],
      [86.86060333, 20.66069031],
      [86.86107635, 20.66107559],
      [86.86164093, 20.66138268],
      [86.86226654, 20.66166115],
      [86.86295319, 20.66194916],
      [86.86362457, 20.66226959],
      [86.864151, 20.66274071],
      [86.86464691, 20.66324806],
      [86.86527252, 20.66357613],
      [86.86585999, 20.66387939],
      [86.86641693, 20.66418076],
      [86.86688232, 20.66451073],
      [86.86724091, 20.66489983],
      [86.86753845, 20.66531944],
      [86.867836, 20.66573906],
      [86.86821747, 20.6660881],
      [86.86867523, 20.66638565],
      [86.86917877, 20.66666031],
      [86.86969757, 20.66694641],
      [86.87017822, 20.66725922],
      [86.87058258, 20.66761017],
      [86.87098694, 20.66790009],
      [86.87136078, 20.66819],
      [86.87169647, 20.66848946],
      [86.87207794, 20.6687603],
      [86.87240601, 20.66908073],
      [86.87272644, 20.66940117],
      [86.87304688, 20.66971016],
      [86.87338257, 20.67000008],
      [86.87371063, 20.67027664],
      [86.87403107, 20.67054939],
      [86.87435913, 20.67084122],
      [86.87468719, 20.67115974],
      [86.87499237, 20.67152977],
      [86.8752594, 20.67194939],
      [86.87548828, 20.67239952],
      [86.87580109, 20.67274284],
      [86.87610626, 20.67301178],
      [86.87639618, 20.67324066],
      [86.87665558, 20.67343903],
      [86.87690735, 20.67365074],
      [86.87716675, 20.67391586],
      [86.87741852, 20.67422295],
      [86.87766266, 20.6745739],
      [86.87800598, 20.67482376],
      [86.87831879, 20.67511177],
      [86.87858582, 20.6754303],
      [86.87883759, 20.67577553],
      [86.87913513, 20.67606163],
      [86.87943268, 20.6763401],
      [86.87970734, 20.67664909],
      [86.87999725, 20.67695045],
      [86.88027954, 20.67726326],
      [86.88054657, 20.67757988],
      [86.88082886, 20.67790604],
      [86.88110352, 20.6782341],
      [86.88135529, 20.67856979],
      [86.88166046, 20.67885208],
      [86.88195038, 20.67912102],
      [86.88223267, 20.67938042],
      [86.88249969, 20.67962074],
      [86.88275146, 20.67987061],
      [86.88305664, 20.68028069],
      [86.8833313, 20.68056107],
      [86.88360596, 20.68056107],
      [86.8841629, 20.68111038],
      [86.88445282, 20.68111038],
      [86.88610077, 20.68278122],
      [86.88639069, 20.68278122],
      [86.88666534, 20.68305588],
      [86.88683319, 20.68349838],
      [86.88712311, 20.68381119],
      [86.88739014, 20.68414116],
      [86.8877182, 20.68438911],
      [86.88804626, 20.68464851],
      [86.88835907, 20.68494987],
      [86.88870239, 20.68525124],
      [86.88909912, 20.68548965],
      [86.88948822, 20.68571091],
      [86.88987732, 20.68587112],
      [86.89016724, 20.68615723],
      [86.89055634, 20.68645096],
      [86.89097595, 20.68668175],
      [86.89134979, 20.68693542],
      [86.89167023, 20.68721962],
      [86.89187622, 20.68742943],
      [86.89250183, 20.68806076],
      [86.89277649, 20.68805504],
      [86.89444733, 20.68972015],
      [86.89472198, 20.68971825],
      [86.89527893, 20.6902771],
      [86.89528656, 20.69083023],
      [86.89778137, 20.69333458],
      [86.89861298, 20.69417],
      [86.89888763, 20.69416618],
      [86.89888763, 20.69444466],
      [86.90018463, 20.69574165],
      [86.90083313, 20.69639015],
      [86.90110016, 20.69639015],
      [86.90249634, 20.69778061],
      [86.90305328, 20.6977787],
      [86.90361023, 20.69833374],
      [86.90389252, 20.69832993],
      [86.90416718, 20.69861031],
      [86.90444183, 20.69861031],
      [86.90471649, 20.69889069],
      [86.90499878, 20.69889069],
      [86.90528107, 20.69917107],
      [86.90555573, 20.69916725],
      [86.90583038, 20.69944382],
      [86.9059906, 20.69944382],
      [86.90611267, 20.69944382],
      [86.90638733, 20.69972229],
      [86.90666962, 20.69972038],
      [86.90693665, 20.70000076],
      [86.90721893, 20.70000076],
      [86.90740967, 20.70018768],
      [86.90750122, 20.70028114],
      [86.90777588, 20.70028114],
      [86.90805817, 20.70055962],
      [86.90860748, 20.7005558],
      [86.90888977, 20.70083427],
      [86.9091568, 20.70083046],
      [86.90972137, 20.70139122],
      [86.90999603, 20.70139122],
      [86.91028595, 20.70166969],
      [86.91083527, 20.70166588],
      [86.91110992, 20.70194435],
      [86.91166687, 20.70194435],
      [86.91194153, 20.70222282],
      [86.91249847, 20.70222282],
      [86.91278076, 20.70249939],
      [86.91306305, 20.70248985],
      [86.91333008, 20.70278168],
      [86.91500092, 20.70278168],
      [86.9152832, 20.70306015],
      [86.91583252, 20.70305634],
      [86.91611481, 20.7033329],
      [86.91638947, 20.7033329],
      [86.91666412, 20.70360947],
      [86.91694641, 20.70360947],
      [86.91722107, 20.70388794],
      [86.91765594, 20.7040081],
      [86.91822052, 20.70420074],
      [86.91873932, 20.70451546],
      [86.91936493, 20.70472336],
      [86.91997528, 20.70498466],
      [86.92056274, 20.70523071],
      [86.92112732, 20.70539093],
      [86.92166901, 20.70557976],
      [86.92218781, 20.70587921],
      [86.92271423, 20.70623779],
      [86.92338562, 20.70626259],
      [86.92391205, 20.70644951],
      [86.92432404, 20.7067585],
      [86.92487335, 20.70683479],
      [86.925354, 20.70698166],
      [86.92578125, 20.70722008],
      [86.92619324, 20.70750046],
      [86.92664337, 20.70778084],
      [86.92708588, 20.70802116],
      [86.92758179, 20.70816612],
      [86.9280014, 20.70833588],
      [86.92835999, 20.70849991],
      [86.92871094, 20.70862961],
      [86.92900848, 20.70880127],
      [86.9292984, 20.70901108],
      [86.92970276, 20.70919991],
      [86.93014526, 20.70937347],
      [86.9306488, 20.70946121],
      [86.93112183, 20.70960808],
      [86.93157196, 20.70974541],
      [86.93198395, 20.70990372],
      [86.93209076, 20.70993614],
      [86.93239594, 20.71003151],
      [86.93279266, 20.71026993],
      [86.93328094, 20.71050072],
      [86.93393707, 20.71071053],
      [86.93468475, 20.71088028],
      [86.93540955, 20.71108055],
      [86.93612671, 20.71119118],
      [86.93682098, 20.71125984],
      [86.93742371, 20.71136665],
      [86.93802643, 20.71139908],
      [86.938591, 20.7115097],
      [86.93916321, 20.71161079],
      [86.93979645, 20.71146965],
      [86.94050598, 20.71139908],
      [86.94123077, 20.71138954],
      [86.9419632, 20.71138954],
      [86.94267273, 20.71136665],
      [86.94335175, 20.71124077],
      [86.94402313, 20.71112061],
      [86.94468689, 20.71103096],
      [86.94535828, 20.71092033],
      [86.9460144, 20.71083832],
      [86.94668579, 20.7107811],
      [86.94734192, 20.71077728],
      [86.94798279, 20.7107811],
      [86.94848633, 20.71058083],
      [86.94898224, 20.71044731],
      [86.94944, 20.71027946],
      [86.94986725, 20.71013069],
      [86.95027161, 20.71001816],
      [86.95069885, 20.71000099],
      [86.95115662, 20.71000099],
      [86.95160675, 20.70996094],
      [86.95198822, 20.70974922],
      [86.95231628, 20.7096405],
      [86.95258331, 20.70947838],
      [86.95288086, 20.70957375],
      [86.95321655, 20.70968056],
      [86.95358276, 20.70972061],
      [86.95397949, 20.70972061],
      [86.95440674, 20.70968056],
      [86.95481873, 20.7094841],
      [86.9552536, 20.70942879],
      [86.95565796, 20.70935249],
      [86.9560318, 20.70922279],
      [86.95642853, 20.70918083],
      [86.95684052, 20.70916939],
      [86.95726776, 20.70916939],
      [86.95770264, 20.70916939],
      [86.95812225, 20.70916939],
      [86.95852661, 20.70916939],
      [86.95889282, 20.70916939],
      [86.95916748, 20.70916748],
      [86.95944214, 20.70944595],
      [86.95972443, 20.70944595],
      [86.95999908, 20.70916748],
      [86.96194458, 20.70916748],
      [86.96221924, 20.70944595],
      [86.96305847, 20.70944595],
      [86.96333313, 20.70916748],
      [86.9658432, 20.70916939],
      [86.96611023, 20.70889091],
      [86.96669006, 20.70889091],
      [86.9672699, 20.70889091],
      [86.96787262, 20.70889091],
      [86.96843719, 20.70887947],
      [86.96899414, 20.70885658],
      [86.96952057, 20.7088623],
      [86.97000885, 20.70883179],
      [86.97044373, 20.70862961],
      [86.97088623, 20.70849991],
      [86.97133636, 20.70840645],
      [86.97171783, 20.70820427],
      [86.9720993, 20.70802116],
      [86.97245789, 20.70779037],
      [86.97286224, 20.70759964],
      [86.97336578, 20.7074604],
      [86.97387695, 20.70722008],
      [86.97447205, 20.70695114],
      [86.97614288, 20.70610046],
      [86.97673035, 20.70566177],
      [86.97714233, 20.70535469],
      [86.97821808, 20.70467949],
      [86.9797287, 20.70421982],
      [86.98030853, 20.70421028],
      [86.98091125, 20.70413971],
      [86.98150635, 20.70396042],
      [86.98213959, 20.70386124],
      [86.98274994, 20.7036705],
      [86.98335266, 20.70359039],
      [86.98387909, 20.70347404],
      [86.98442078, 20.70335007],
      [86.98501587, 20.70331001],
      [86.98562622, 20.70331955],
      [86.9861908, 20.70331001],
      [86.98670197, 20.70318031],
      [86.98722839, 20.70297813],
      [86.98777008, 20.70279121],
      [86.98823547, 20.70263672],
      [86.98870087, 20.70252037],
      [86.98924255, 20.7024498],
      [86.98984528, 20.70226097],
      [86.99064636, 20.70222282],
      [86.9911499, 20.70220566],
      [86.99166107, 20.70219421],
      [86.99238586, 20.7021122],
      [86.99311829, 20.70203209],
      [86.99427795, 20.70196342],
      [86.99544525, 20.70189667],
      [87, 20.70028114],
      [87.00305176, 20.70028114],
      [87.00405884, 20.7003212],
      [87.00491333, 20.70024109],
      [87.00519562, 20.7002449],
      [87.00548553, 20.70010757],
      [87.00569916, 20.69984055],
      [87.00569916, 20.69936943],
      [87.00554657, 20.69875908],
      [87.00527191, 20.69848824],
      [87.00454712, 20.6982193],
      [87.0041275, 20.69795036],
      [87.00376892, 20.69782066],
      [87.00327301, 20.69748116],
      [87.00263214, 20.69728088],
      [87.00226593, 20.69713974],
      [87.00177002, 20.69713974],
      [87, 20.69668007],
      [86.99973297, 20.69695091],
      [86.99973297, 20.69717026],
      [86.99960327, 20.69723129],
      [86.99930573, 20.69722176],
      [86.99897003, 20.69721413],
      [86.99859619, 20.69722939],
      [86.99820709, 20.69729805],
      [86.99780273, 20.69742966],
      [86.99734497, 20.6974926],
      [86.99687958, 20.69754028],
      [86.99643707, 20.6977005],
      [86.99595642, 20.69781685],
      [86.99549103, 20.69799423],
      [86.99497986, 20.69812393],
      [86.99449921, 20.69831085],
      [86.99401093, 20.69848824],
      [86.99346161, 20.69864273],
      [86.99295044, 20.69881058],
      [86.99238586, 20.69888115],
      [86.99186707, 20.69889641],
      [86.99138641, 20.69894218],
      [86.99095917, 20.69906044],
      [86.99053192, 20.69910812],
      [86.99009705, 20.6990509],
      [86.98968506, 20.69891167],
      [86.98924255, 20.69882011],
      [86.9888382, 20.69874001],
      [86.98854065, 20.69861031],
      [86.98828888, 20.69855499],
      [86.98812103, 20.69853973],
      [86.98783875, 20.6984005],
      [86.98721313, 20.69820023],
      [86.98750305, 20.69721031],
      [86.98810577, 20.69742012],
      [86.9883194, 20.69750023],
      [86.9888382, 20.69762039],
      [86.98922729, 20.6976738],
      [86.98995972, 20.69766235],
      [86.99044037, 20.69771194],
      [86.99088287, 20.69767189],
      [86.9912796, 20.69754028],
      [86.99171448, 20.69748306],
      [86.99214935, 20.69741249],
      [86.99253845, 20.69725227],
      [86.99295044, 20.69712257],
      [86.99333954, 20.69697952],
      [86.99375916, 20.69688606],
      [86.994133, 20.6967411],
      [86.99452209, 20.69663429],
      [86.99491119, 20.69649887],
      [86.99533081, 20.69635963],
      [86.9957962, 20.69619942],
      [86.99633789, 20.69606972],
      [86.99687958, 20.69592094],
      [86.99743652, 20.69583321],
      [86.99803925, 20.69576645],
      [86.9988327, 20.69569969],
      [87, 20.69528008],
      [87.0011673, 20.69549942],
      [87.00159454, 20.69563675],
      [87.00216675, 20.69577217],
      [87.00293732, 20.69597054],
      [87.00359344, 20.69617653],
      [87.00408936, 20.69624329],
      [87.00479889, 20.69632149],
      [87.00522614, 20.69639015],
      [87.005867, 20.69639015],
      [87.00644684, 20.69645119],
      [87.00715637, 20.69664955],
      [87.00787354, 20.69691849],
      [87.0085907, 20.69725609],
      [87.00894165, 20.69739151],
      [87.00951385, 20.69772911],
      [87.01029968, 20.69792747],
      [87.01065826, 20.69799805],
      [87.01114655, 20.69812012],
      [87.01151276, 20.69833946],
      [87.01208496, 20.69853783],
      [87.01243591, 20.69866943],
      [87.01287079, 20.69880867],
      [87.01315308, 20.69894028],
      [87.01344299, 20.69901085],
      [87.01358032, 20.69930077],
      [87.01442719, 20.69963074],
      [87.01515198, 20.69997025],
      [87.01571655, 20.70030594],
      [87.01657867, 20.70064354],
      [87.01715088, 20.70091057],
      [87.01772308, 20.70111847],
      [87.01843262, 20.70165634],
      [87.0187149, 20.7017231],
      [87.01900482, 20.7017231],
      [87.01957703, 20.70165634],
      [87.01999664, 20.70145035],
      [87.02043152, 20.7010498],
      [87.02070618, 20.70084953],
      [87.02107239, 20.70078278],
      [87.0213623, 20.7007103],
      [87.02163696, 20.70064163],
      [87.02285767, 20.70064163],
      [87.02313995, 20.7007103],
      [87.02342987, 20.70084572],
      [87.02407074, 20.70145226],
      [87.02428436, 20.7017231],
      [87.02457428, 20.70199203],
      [87.02493286, 20.70226097],
      [87.02513885, 20.70232964],
      [87.0252533, 20.7023716],
      [87.02574158, 20.70265007],
      [87.0265274, 20.70304108],
      [87.02709961, 20.70345116],
      [87.02767181, 20.70358086],
      [87.02831268, 20.70384979],
      [87.02880859, 20.70412064],
      [87.0293808, 20.70458984],
      [87.02967072, 20.70493126],
      [87.03009796, 20.70540237],
      [87.03038025, 20.7056675],
      [87.03067017, 20.70587921],
      [87.03116608, 20.70587921],
      [87.03180695, 20.70573807],
      [87.0326004, 20.70560074],
      [87.03344727, 20.70560074],
      [87.03373718, 20.70573807],
      [87.03423309, 20.70594025],
      [87.03452301, 20.70607948],
      [87.03495026, 20.70621109],
      [87.03530884, 20.70641518],
      [87.03573608, 20.70648003],
      [87.03601837, 20.7065506],
      [87.03637695, 20.70668411],
      [87.03665924, 20.70675087],
      [87.03697968, 20.70706177],
      [87.03733826, 20.7074604],
      [87.03733826, 20.70800018],
      [87.03826141, 20.70853996],
      [87.03875732, 20.70887947],
      [87.03911591, 20.70907974],
      [87.03946686, 20.70922279],
      [87.03990173, 20.70941734],
      [87.0404129, 20.70955086],
      [87.04068756, 20.70975494],
      [87.04090118, 20.70981979],
      [87.04125977, 20.70996094],
      [87.04168701, 20.71002579],
      [87.04219055, 20.71009064],
      [87.04247284, 20.71022987],
      [87.04276276, 20.71029472],
      [87.04325867, 20.71036148],
      [87.0446167, 20.71036148],
      [87.04483032, 20.71022987],
      [87.04489136, 20.71015358],
      [87.04512024, 20.70988846],
      [87.0451889, 20.7094841],
      [87.0446167, 20.70887756],
      [87.04403687, 20.70845985],
      [87.04319, 20.70800018],
      [87.04234314, 20.70759964],
      [87.04154968, 20.70725822],
      [87.04067993, 20.70650864],
      [87.04032135, 20.70624161],
      [87.03974915, 20.70556641],
      [87.03846741, 20.70543098],
      [87.03760529, 20.70468712],
      [87.03653717, 20.7038784],
      [87.03568268, 20.70334053],
      [87.03497314, 20.70280075],
      [87.03417969, 20.70192337],
      [87.03318024, 20.70145035],
      [87.0324707, 20.70083809],
      [87.03153992, 20.70010376],
      [87.03070831, 20.69924927],
      [87.03042603, 20.69898033],
      [87.02970886, 20.69856071],
      [87.02921295, 20.69823837],
      [87.02884674, 20.6977005],
      [87.02700043, 20.69634819],
      [87.02635956, 20.69615173],
      [87.02578735, 20.69581032],
      [87.02542877, 20.69547272],
      [87.0241394, 20.69466019],
      [87.02364349, 20.69445992],
      [87.02307129, 20.69406128],
      [87.02258301, 20.6936512],
      [87.0213623, 20.69277191],
      [87.02072144, 20.69223213],
      [87.02028656, 20.69182777],
      [87.01950073, 20.6914196],
      [87.01885986, 20.69108772],
      [87.01821899, 20.69082069],
      [87.01735687, 20.69033813],
      [87.01657104, 20.68965912],
      [87.01635742, 20.68939972],
      [87.01535797, 20.68844032],
      [87.01457214, 20.68776894],
      [87.01364899, 20.68722916],
      [87.01136017, 20.68601036],
      [87.01065063, 20.6854744],
      [87.00993347, 20.6848011],
      [87.00921631, 20.68419075],
      [87.00665283, 20.68264198],
      [87.00565338, 20.68196869],
      [87.00465393, 20.68142891],
      [87.00222778, 20.67974091],
      [87.00144196, 20.67918968],
      [87.00086975, 20.67866135],
      [87, 20.67721939],
      [86.99834442, 20.67666245],
      [86.9972229, 20.67610931],
      [86.99694824, 20.67610931],
      [86.99610901, 20.67528152],
      [86.99582672, 20.67528152],
      [86.99536896, 20.67481232],
      [86.9952774, 20.67472267],
      [86.99500275, 20.67472267],
      [86.99472046, 20.6744442],
      [86.9944458, 20.6744442],
      [86.99416351, 20.67416573],
      [86.99388885, 20.67416954],
      [86.99360657, 20.67388916],
      [86.99333191, 20.67388916],
      [86.99305725, 20.67361069],
      [86.99276733, 20.67361069],
      [86.99194336, 20.67278099],
      [86.9916687, 20.67278099],
      [86.99111176, 20.67222023],
      [86.99055481, 20.67222214],
      [86.98972321, 20.67138863],
      [86.98944092, 20.67139053],
      [86.98860931, 20.67056084],
      [86.9883194, 20.67056084],
      [86.98722076, 20.66945076],
      [86.98722076, 20.66917038],
      [86.98694611, 20.66889],
      [86.98694611, 20.66860962],
      [86.98666382, 20.66833305],
      [86.98638916, 20.66833305],
      [86.9861145, 20.66805649],
      [86.98583221, 20.6680603],
      [86.98554993, 20.66777992],
      [86.9852829, 20.66777992],
      [86.98477173, 20.66727448],
      [86.98444366, 20.6669445],
      [86.98332977, 20.6669445],
      [86.98278046, 20.66638947],
      [86.98249817, 20.66638947],
      [86.98194122, 20.66583061],
      [86.98083496, 20.66583061],
      [86.98027802, 20.66527748],
      [86.98000336, 20.66527939],
      [86.97972107, 20.66500282],
      [86.97860718, 20.66500282],
      [86.97833252, 20.66472054],
      [86.97805786, 20.66472054],
      [86.97638702, 20.66305542],
      [86.97611237, 20.66306114],
      [86.97471619, 20.66167068],
      [86.97444153, 20.66167068],
      [86.97407532, 20.66129684],
      [86.97389221, 20.66111183],
      [86.97360992, 20.66111183],
      [86.97305298, 20.66055489],
      [86.97277832, 20.66055489],
      [86.97250366, 20.66027832],
      [86.97222137, 20.66027832],
      [86.97194672, 20.65999985],
      [86.9716568, 20.65999985],
      [86.97138214, 20.65977097],
      [86.97083282, 20.65972137],
      [86.97027588, 20.65916634],
      [86.97000122, 20.65917015],
      [86.96916962, 20.65833092],
      [86.96888733, 20.65833282],
      [86.96833038, 20.65777779],
      [86.96806335, 20.65777969],
      [86.96778107, 20.65751076],
      [86.96750641, 20.65751076],
      [86.96721649, 20.65722084],
      [86.96666718, 20.65722275],
      [86.96648407, 20.65703964],
      [86.96611023, 20.6566658],
      [86.9658432, 20.65666962],
      [86.96527863, 20.65611076],
      [86.96499634, 20.65611076],
      [86.96333313, 20.65443993],
      [86.96250153, 20.65444565],
      [86.96221924, 20.65416718],
      [86.96193695, 20.65417099],
      [86.96166992, 20.65389061],
      [86.96111298, 20.65389061],
      [86.96103668, 20.6538105],
      [86.96092987, 20.65370369],
      [86.96055603, 20.65333366],
      [86.96055603, 20.65305519],
      [86.95999908, 20.65306091],
      [86.9597168, 20.65278053],
      [86.95916748, 20.65278053],
      [86.95833588, 20.65195084],
      [86.95804596, 20.65195084],
      [86.95769501, 20.65159035],
      [86.95749664, 20.65138817],
      [86.95722198, 20.65138817],
      [86.95639038, 20.65055466],
      [86.95639038, 20.65027809],
      [86.95582581, 20.64971924],
      [86.95471954, 20.64972115],
      [86.95444489, 20.64944458],
      [86.95417023, 20.64944458],
      [86.95381927, 20.64909554],
      [86.95277405, 20.64805603],
      [86.95249939, 20.64805984],
      [86.95220947, 20.64777756],
      [86.95139313, 20.64777756],
      [86.9511795, 20.64756966],
      [86.95110321, 20.64755249],
      [86.95083618, 20.64749908],
      [86.95054626, 20.64722061],
      [86.95055389, 20.64694405],
      [86.94944763, 20.64583397],
      [86.94916534, 20.64583397],
      [86.94889069, 20.6455555],
      [86.94832611, 20.64557076],
      [86.94805908, 20.64528084],
      [86.94777679, 20.64528084],
      [86.9466629, 20.64417076],
      [86.94639587, 20.64417076],
      [86.94610596, 20.64389038],
      [86.9458313, 20.64389038],
      [86.94526672, 20.64332962],
      [86.94499969, 20.64333344],
      [86.9430542, 20.64138794],
      [86.94194794, 20.64138985],
      [86.94139099, 20.6408329],
      [86.94083405, 20.64083481],
      [86.94055176, 20.64111137],
      [86.94000244, 20.64111137],
      [86.93972015, 20.64083481],
      [86.9394455, 20.64083481],
      [86.93916321, 20.64055634],
      [86.93860626, 20.64056015],
      [86.9383316, 20.64027977],
      [86.93805695, 20.64027977],
      [86.93638611, 20.63861275],
      [86.93638611, 20.63833046],
      [86.9355545, 20.63750267],
      [86.93527985, 20.63750267],
      [86.93499756, 20.63722038],
      [86.9347229, 20.63722038],
      [86.93453979, 20.63703918],
      [86.93416595, 20.63666725],
      [86.9338913, 20.63667107],
      [86.93360901, 20.63640022],
      [86.93332672, 20.63640022],
      [86.93305206, 20.6361084],
      [86.9327774, 20.6361084],
      [86.93250275, 20.63582993],
      [86.93222046, 20.63582993],
      [86.9319458, 20.6361084],
      [86.93000031, 20.63417053],
      [86.92973328, 20.63417053],
      [86.92944336, 20.63388824],
      [86.9291687, 20.63388824],
      [86.92778015, 20.63249969],
      [86.92722321, 20.63249969],
      [86.92694092, 20.63222122],
      [86.92666626, 20.63222313],
      [86.9263916, 20.63194466],
      [86.92610931, 20.63194466],
      [86.92583466, 20.63166618],
      [86.92555237, 20.63166809],
      [86.92500305, 20.63111115],
      [86.92472076, 20.63111115],
      [86.92444611, 20.63083076],
      [86.92388916, 20.63083267],
      [86.92166901, 20.62861061],
      [86.92138672, 20.62861061],
      [86.92056274, 20.62778091],
      [86.92056274, 20.62721062],
      [86.91999817, 20.62666702],
      [86.91916656, 20.62667084],
      [86.91889191, 20.62639046],
      [86.91832733, 20.62639046],
      [86.91809845, 20.62615967],
      [86.91694641, 20.62611389],
      [86.91684723, 20.62601471],
      [86.91666412, 20.62583351],
      [86.91500092, 20.62583351],
      [86.91416931, 20.62500191],
      [86.91416931, 20.62446022],
      [86.91443634, 20.6241703],
      [86.91416931, 20.62389183],
      [86.91416931, 20.62332916],
      [86.91388702, 20.62305641],
      [86.91278076, 20.62306023],
      [86.91249847, 20.62278175],
      [86.91221619, 20.62278175],
      [86.91166687, 20.62221909],
      [86.91139221, 20.6222229],
      [86.91110992, 20.62194443],
      [86.91083527, 20.62194443],
      [86.91027832, 20.62138748],
      [86.90972137, 20.62140274],
      [86.90944672, 20.62111092],
      [86.90860748, 20.62111092],
      [86.90805817, 20.62055969],
      [86.90777588, 20.62055969],
      [86.90759277, 20.62037468],
      [86.90721893, 20.62000084],
      [86.90693665, 20.62000084],
      [86.90666962, 20.61972046],
      [86.90638733, 20.61972237],
      [86.90555573, 20.61888885],
      [86.90556335, 20.61861038],
      [86.90528107, 20.61833382],
      [86.90416718, 20.61833382],
      [86.90361023, 20.61777687],
      [86.9033432, 20.61778069],
      [86.90139008, 20.61582947],
      [86.90110779, 20.61583328],
      [86.90055847, 20.61527824],
      [86.90083313, 20.61499977],
      [86.90083313, 20.61471939],
      [86.90027618, 20.61416626],
      [86.89916992, 20.61417007],
      [86.89888763, 20.61388969],
      [86.8972168, 20.61220932],
      [86.89694214, 20.61222267],
      [86.89638519, 20.61166573],
      [86.89611053, 20.61166954],
      [86.89583588, 20.61139107],
      [86.89554596, 20.61139107],
      [86.89515686, 20.61099434],
      [86.89499664, 20.61083412],
      [86.89472198, 20.61083412],
      [86.89444733, 20.61055565],
      [86.89389038, 20.61055946],
      [86.89332581, 20.61000252],
      [86.89305878, 20.61000252],
      [86.89277649, 20.60971832],
      [86.89250183, 20.61000252],
      [86.89221954, 20.61000252],
      [86.89194489, 20.60972023],
      [86.89194489, 20.60944366],
      [86.89027405, 20.60778046],
      [86.88999939, 20.60778046],
      [86.88944244, 20.6072197],
      [86.8888855, 20.6072216],
      [86.88805389, 20.60638618],
      [86.88723755, 20.60606194],
      [86.8866272, 20.60611725],
      [86.88641357, 20.60699654],
      [86.88645172, 20.60822105],
      [86.88666534, 20.6091671],
      [86.88694763, 20.60943985],
      [86.88694763, 20.61000252],
      [86.88722229, 20.6102829],
      [86.88722229, 20.61166573],
      [86.88694763, 20.61194038],
      [86.88694763, 20.61250114],
      [86.88722229, 20.61277771],
      [86.88722229, 20.61333275],
      [86.88749695, 20.61361122],
      [86.88749695, 20.61388969],
      [86.88777924, 20.61416626],
      [86.88777924, 20.61721992],
      [86.88722229, 20.61778069],
      [86.88694763, 20.61778069],
      [86.88610077, 20.61695099],
      [86.88610077, 20.61667061],
      [86.88582611, 20.61639023],
      [86.88500214, 20.61639023],
      [86.88388824, 20.61527061],
      [86.88444519, 20.6147213],
      [86.88444519, 20.61444473],
      [86.88471985, 20.61416626],
      [86.88471985, 20.61361122],
      [86.88582611, 20.61250114],
      [86.88582611, 20.60806084],
      [86.88555908, 20.60749817],
      [86.8850708, 20.60630989],
      [86.88500214, 20.60583115],
      [86.88500214, 20.60527611],
      [86.88527679, 20.60499954],
      [86.88527679, 20.60472107],
      [86.88500214, 20.6044445],
      [86.88471985, 20.6044445],
      [86.88444519, 20.60416794],
      [86.8841629, 20.60416985],
      [86.88388824, 20.60388947],
      [86.88360596, 20.60388947],
      [86.8833313, 20.60361099],
      [86.88249969, 20.60361099],
      [86.88194275, 20.60305977],
      [86.88166809, 20.60305977],
      [86.8813858, 20.60277939],
      [86.88028717, 20.60277939],
      [86.87999725, 20.60250092],
      [86.8797226, 20.60250092],
      [86.87832642, 20.60111046],
      [86.87750244, 20.60111046],
      [86.87722015, 20.60083008],
      [86.87527466, 20.5988884],
      [86.875, 20.5988903],
      [86.87444305, 20.59832954],
      [86.87388611, 20.59833336],
      [86.87361145, 20.59861374],
      [86.87333679, 20.59833336],
      [86.87277985, 20.59833336],
      [86.87249756, 20.59805489],
      [86.8722229, 20.59805489],
      [86.87203979, 20.59786987],
      [86.87194824, 20.59777832],
      [86.87194824, 20.59750175],
      [86.87082672, 20.59639168],
      [86.87055206, 20.59639168],
      [86.87000275, 20.59583092],
      [86.8694458, 20.59583282],
      [86.86888885, 20.59527969],
      [86.86860657, 20.5952816],
      [86.86833191, 20.59499931],
      [86.86805725, 20.59499931],
      [86.86694336, 20.59389114],
      [86.8666687, 20.59389114],
      [86.86554718, 20.59278107],
      [86.86528015, 20.59278107],
      [86.86416626, 20.59167099],
      [86.8638916, 20.59167099],
      [86.86360931, 20.59139061],
      [86.86332703, 20.59139061],
      [86.8631897, 20.59124756],
      [86.86305237, 20.59110069],
      [86.86277771, 20.59111023],
      [86.86250305, 20.59083366],
      [86.86222076, 20.59083366],
      [86.86194611, 20.59055519],
      [86.86165619, 20.59056091],
      [86.86138916, 20.59028053],
      [86.86083221, 20.59028053],
      [86.86055756, 20.59000015],
      [86.86027527, 20.59000015],
      [86.86000061, 20.58972168],
      [86.85972595, 20.58972168],
      [86.85972595, 20.58944511],
      [86.85943604, 20.58917046],
      [86.85943604, 20.58889008],
      [86.85888672, 20.58833122],
      [86.85861206, 20.58833313],
      [86.85778046, 20.58749962],
      [86.85749817, 20.58749962],
      [86.85694122, 20.58694077],
      [86.85666656, 20.58694458],
      [86.85610962, 20.58638954],
      [86.85582733, 20.58638954],
      [86.85555267, 20.58666992],
      [86.85527802, 20.58666611],
      [86.85472107, 20.58722115],
      [86.85472107, 20.58777809],
      [86.85500336, 20.5880394],
      [86.85575867, 20.58814812],
      [86.85668945, 20.5883503],
      [86.85675812, 20.58862114],
      [86.85661316, 20.58903122],
      [86.85611725, 20.58916092],
      [86.85504913, 20.58909035],
      [86.85375977, 20.58909225],
      [86.85305023, 20.5883503],
      [86.85276794, 20.58794975],
      [86.85240173, 20.58727074],
      [86.85231781, 20.58653069],
      [86.85231781, 20.58579063],
      [86.85260773, 20.58484077],
      [86.85333252, 20.58416939],
      [86.85333252, 20.58389282],
      [86.8527832, 20.58333015],
      [86.85250092, 20.58333397],
      [86.85194397, 20.58277702],
      [86.85166931, 20.58278084],
      [86.85056305, 20.58167076],
      [86.84999084, 20.58167076],
      [86.84916687, 20.58082962],
      [86.84889221, 20.58083344],
      [86.84805298, 20.57999992],
      [86.84777832, 20.57999992],
      [86.84694672, 20.57916069],
      [86.84610748, 20.57916641],
      [86.84590149, 20.57895851],
      [86.8458252, 20.57894135],
      [86.84555817, 20.57888985],
      [86.84471893, 20.57806015],
      [86.84443665, 20.57806015],
      [86.84372711, 20.57733917],
      [86.84361267, 20.57722282],
      [86.84361267, 20.57694435],
      [86.84305573, 20.57638931],
      [86.84249878, 20.57694435],
      [86.84166718, 20.57694435],
      [86.83916473, 20.57444572],
      [86.83889008, 20.57444572],
      [86.83860779, 20.57416725],
      [86.83833313, 20.57417107],
      [86.83750153, 20.57332993],
      [86.83721924, 20.57333374],
      [86.83638763, 20.57250023],
      [86.83611298, 20.57221985],
      [86.83583069, 20.57222366],
      [86.83528137, 20.57166672],
      [86.83500671, 20.57167053],
      [86.83416748, 20.57082939],
      [86.83361053, 20.5708313],
      [86.83251953, 20.57007599],
      [86.83101654, 20.56906128],
      [86.8303833, 20.56865883],
      [86.82959747, 20.56851959],
      [86.82917023, 20.56805611],
      [86.82749939, 20.56638908],
      [86.8272171, 20.56638908],
      [86.82499695, 20.56415939],
      [86.82472229, 20.56416512],
      [86.82416534, 20.56361198],
      [86.82389069, 20.56389046],
      [86.82332611, 20.56389046],
      [86.82256317, 20.56311798],
      [86.82195282, 20.5625],
      [86.8216629, 20.5625],
      [86.81916809, 20.55999947],
      [86.81861115, 20.55999947],
      [86.81833649, 20.5597229],
      [86.8180542, 20.55972481],
      [86.81777954, 20.55944443],
      [86.81639099, 20.55944443],
      [86.81620026, 20.55925369],
      [86.81583405, 20.55888939],
      [86.81555176, 20.55888939],
      [86.8152771, 20.55861282],
      [86.81500244, 20.55861282],
      [86.81472015, 20.55833054],
      [86.81445313, 20.55833054],
      [86.81416321, 20.55861282],
      [86.81388092, 20.55861282],
      [86.8133316, 20.55805969],
      [86.81305695, 20.55805969],
      [86.81276703, 20.55778122],
      [86.8125, 20.55778122],
      [86.81240845, 20.55768967],
      [86.81223297, 20.55750275],
      [86.81194305, 20.55750275],
      [86.8116684, 20.55722046],
      [86.8116684, 20.55694389],
      [86.81222534, 20.55639076],
      [86.81194305, 20.55610847],
      [86.81194305, 20.55582809],
      [86.81138611, 20.55527687],
      [86.81111145, 20.55528069],
      [86.80999756, 20.55417061],
      [86.8097229, 20.55417061],
      [86.80860901, 20.55305099],
      [86.80833435, 20.55305481],
      [86.80805206, 20.55277634],
      [86.8077774, 20.55277824],
      [86.80750275, 20.55249977],
      [86.80722046, 20.55249977],
      [86.8069458, 20.55221939],
      [86.80638885, 20.5522213],
      [86.8061142, 20.55194473],
      [86.80583191, 20.55194473],
      [86.8041687, 20.55027771],
      [86.80388641, 20.55027962],
      [86.8033371, 20.54972076],
      [86.80305481, 20.54972267],
      [86.80288696, 20.5495491],
      [86.80278015, 20.5494442],
      [86.80194092, 20.5494442],
      [86.79972076, 20.54722214],
      [86.79944611, 20.54722214],
      [86.79916382, 20.54694366],
      [86.79888916, 20.54694366],
      [86.79833221, 20.54638863],
      [86.79805756, 20.54639053],
      [86.79676819, 20.54570007],
      [86.79634094, 20.5454998],
      [86.79531097, 20.54516029],
      [86.79453278, 20.54482079],
      [86.79387665, 20.54482079],
      [86.7934494, 20.54475021],
      [86.7928772, 20.54454994],
      [86.79216766, 20.5440712],
      [86.79166412, 20.54333115],
      [86.79166412, 20.54250145],
      [86.79110718, 20.54194069],
      [86.79111481, 20.54166603],
      [86.79083252, 20.54138756],
      [86.79083252, 20.54110909],
      [86.78943634, 20.53972054],
      [86.78916931, 20.53972244],
      [86.78694153, 20.53750038],
      [86.78639221, 20.53750038],
      [86.7858429, 20.53695107],
      [86.78555298, 20.53695107],
      [86.78444672, 20.53582954],
      [86.78444672, 20.53527832],
      [86.7841568, 20.53499985],
      [86.7841568, 20.53417969],
      [86.78221893, 20.53222275],
      [86.78221893, 20.53194427],
      [86.78194427, 20.5316658],
      [86.78153229, 20.53049278],
      [86.78055573, 20.52916718],
      [86.78028107, 20.52889061],
      [86.78028107, 20.52860832],
      [86.77999878, 20.52832985],
      [86.77999878, 20.52805519],
      [86.77944183, 20.52749825],
      [86.77944183, 20.52721977],
      [86.77972412, 20.52694511],
      [86.77971649, 20.52638817],
      [86.77889252, 20.52555084],
      [86.77889252, 20.52527618],
      [86.77861023, 20.52500916],
      [86.77861023, 20.52472115],
      [86.77805328, 20.52416611],
      [86.77805328, 20.52361107],
      [86.777771, 20.52333069],
      [86.77777863, 20.52305412],
      [86.77694702, 20.52222061],
      [86.77694702, 20.52083588],
      [86.77666473, 20.5205555],
      [86.7766571, 20.52028084],
      [86.77610779, 20.51972008],
      [86.77610779, 20.51888847],
      [86.77583313, 20.51861191],
      [86.77583313, 20.51805496],
      [86.77555847, 20.5177803],
      [86.77555847, 20.51721954],
      [86.77527618, 20.51694489],
      [86.77527618, 20.51472282],
      [86.77555847, 20.51444435],
      [86.77555847, 20.51389122],
      [86.77527618, 20.51361084],
      [86.77527618, 20.51306915],
      [86.77500153, 20.51278114],
      [86.77500153, 20.51167107],
      [86.77471161, 20.51139069],
      [86.77500153, 20.51111031],
      [86.77500153, 20.51082993],
      [86.77471924, 20.51055527],
      [86.77471161, 20.51028061],
      [86.77443695, 20.51000977],
      [86.77443695, 20.50945091],
      [86.77471161, 20.50917053],
      [86.77443695, 20.50889015],
      [86.77443695, 20.50832939],
      [86.77388763, 20.50777817],
      [86.77388763, 20.50749969],
      [86.77278137, 20.50638962],
      [86.77278137, 20.50611115],
      [86.77111053, 20.50444031],
      [86.76866913, 20.50444412],
      [86.76805878, 20.50444412],
      [86.76777649, 20.50416756],
      [86.76667023, 20.50416946],
      [86.76611328, 20.50361252],
      [86.7638855, 20.50361252],
      [86.76361084, 20.50333405],
      [86.76333618, 20.50333023],
      [86.76304626, 20.50361252],
      [86.76222229, 20.50361252],
      [86.76139069, 20.50444412],
      [86.76139069, 20.5047226],
      [86.76055908, 20.50555992],
      [86.75945282, 20.50555992],
      [86.75740051, 20.50266075],
      [86.75640106, 20.50124741],
      [86.75617981, 20.50064278],
      [86.75553894, 20.49956322],
      [86.7555542, 20.49916649],
      [86.75527954, 20.4991703],
      [86.75389099, 20.49777985],
      [86.75360107, 20.49777985],
      [86.75332642, 20.49749947],
      [86.75332642, 20.49722099],
      [86.75305176, 20.49694443],
      [86.75333405, 20.49666405],
      [86.75332642, 20.49555969],
      [86.75235748, 20.49458122],
      [86.7519455, 20.49416733],
      [86.75195313, 20.49389076],
      [86.75166321, 20.49361038],
      [86.75138855, 20.49155045],
      [86.75138855, 20.49000168],
      [86.75110626, 20.48971939],
      [86.75111389, 20.48944473],
      [86.7508316, 20.48916626],
      [86.7508316, 20.48887825],
      [86.75055695, 20.48860931],
      [86.75055695, 20.48722076],
      [86.7508316, 20.4869442],
      [86.7508316, 20.48611069],
      [86.75055695, 20.48583984],
      [86.75055695, 20.48500061],
      [86.75026703, 20.48472023],
      [86.75027466, 20.48416519],
      [86.75, 20.48389053],
      [86.75, 20.48332977],
      [86.74972534, 20.48305511],
      [86.74973297, 20.4822197],
      [86.74944305, 20.48194313],
      [86.74944305, 20.48167038],
      [86.7491684, 20.48139],
      [86.7491684, 20.48083115],
      [86.74888611, 20.48055649],
      [86.74888611, 20.48027992],
      [86.74861145, 20.47999954],
      [86.74861145, 20.47970963],
      [86.74833679, 20.4794445],
      [86.74833679, 20.4786129],
      [86.74804688, 20.47833061],
      [86.7480545, 20.47777748],
      [86.74778748, 20.47750282],
      [86.74777985, 20.47694206],
      [86.74749756, 20.4766674],
      [86.74749756, 20.47582817],
      [86.7472229, 20.47555542],
      [86.7472229, 20.47528076],
      [86.74666595, 20.47471809],
      [86.74666595, 20.47416878],
      [86.7463913, 20.47390175],
      [86.7463913, 20.47305107],
      [86.74555206, 20.47222137],
      [86.74555206, 20.47194481],
      [86.7452774, 20.47166634],
      [86.7452774, 20.47110939],
      [86.74500275, 20.47083282],
      [86.74500275, 20.47027969],
      [86.74472809, 20.47000122],
      [86.74472809, 20.46972084],
      [86.7436142, 20.46861076],
      [86.74276733, 20.46861076],
      [86.74250031, 20.46889114],
      [86.74222565, 20.46888924],
      [86.74166107, 20.46944046],
      [86.74166107, 20.47000122],
      [86.74138641, 20.47027969],
      [86.74138641, 20.47056007],
      [86.74166107, 20.47084045],
      [86.7416687, 20.47166634],
      [86.74138641, 20.47194099],
      [86.74138641, 20.47249985],
      [86.74111176, 20.47278023],
      [86.74111176, 20.47390175],
      [86.74055481, 20.47444534],
      [86.74054718, 20.47471809],
      [86.73999786, 20.47528076],
      [86.73999786, 20.47555923],
      [86.73916626, 20.47639275],
      [86.73916626, 20.4766674],
      [86.7388916, 20.47694206],
      [86.7388916, 20.47722244],
      [86.73832703, 20.47777939],
      [86.73832703, 20.47805977],
      [86.73777771, 20.4786129],
      [86.73750305, 20.4786129],
      [86.73722076, 20.47888947],
      [86.73665619, 20.47888947],
      [86.73638916, 20.47916985],
      [86.7361145, 20.47916603],
      [86.73583221, 20.4794445],
      [86.73416901, 20.4794445],
      [86.73388672, 20.47916603],
      [86.73388672, 20.4786129],
      [86.73472595, 20.4786129],
      [86.73500061, 20.47833061],
      [86.73583221, 20.47833061],
      [86.73638916, 20.47777748],
      [86.73665619, 20.47777939],
      [86.73777771, 20.47667122],
      [86.73777771, 20.47639275],
      [86.73805237, 20.47610855],
      [86.73805237, 20.47582817],
      [86.73860931, 20.47527695],
      [86.73860168, 20.47499847],
      [86.73832703, 20.47471809],
      [86.73860931, 20.47444534],
      [86.73860168, 20.47249985],
      [86.73832703, 20.47221947],
      [86.73833466, 20.46888924],
      [86.73805237, 20.46861076],
      [86.73803711, 20.46748161],
      [86.73777771, 20.46722221],
      [86.73777771, 20.46694374],
      [86.73750305, 20.46666718],
      [86.73750305, 20.46639061],
      [86.73722076, 20.46611023],
      [86.73722076, 20.46582985],
      [86.73694611, 20.46555519],
      [86.73694611, 20.46500015],
      [86.73665619, 20.46470833],
      [86.73666382, 20.46388817],
      [86.73638916, 20.4636116],
      [86.73638916, 20.46305466],
      [86.7361145, 20.46277809],
      [86.73610687, 20.46139145],
      [86.73583221, 20.46110916],
      [86.73583221, 20.46027756],
      [86.73555756, 20.45999908],
      [86.73555756, 20.45972061],
      [86.73527527, 20.45944405],
      [86.73527527, 20.4588871],
      [86.73500061, 20.45861053],
      [86.73500061, 20.45806122],
      [86.73472595, 20.45778084],
      [86.73472595, 20.45750046],
      [86.73443604, 20.45722008],
      [86.73444366, 20.45694351],
      [86.73388672, 20.45638847],
      [86.73388672, 20.45611],
      [86.73361206, 20.45583344],
      [86.73361206, 20.4552784],
      [86.73332977, 20.45499992],
      [86.73332977, 20.4543705],
      [86.73332977, 20.45388794],
      [86.73306274, 20.45360947],
      [86.73361206, 20.45305634],
      [86.73361206, 20.45249939],
      [86.73337555, 20.4522171],
      [86.73336029, 20.45205879],
      [86.73332977, 20.45166588],
      [86.73361206, 20.45139122],
      [86.73361206, 20.44889069],
      [86.73332977, 20.4486084],
      [86.73332977, 20.44778061],
      [86.73361206, 20.44749832],
      [86.73361206, 20.44696045],
      [86.73332977, 20.44667053],
      [86.73332977, 20.44638824],
      [86.73306274, 20.44610977],
      [86.73305511, 20.44527626],
      [86.73278809, 20.44499969],
      [86.73278046, 20.44444466],
      [86.73332977, 20.44388962],
      [86.73306274, 20.4435997],
      [86.73305511, 20.4430542],
      [86.73332977, 20.44277954],
      [86.73332977, 20.44166946],
      [86.73306274, 20.44139099],
      [86.73306274, 20.44028091],
      [86.73278809, 20.44000053],
      [86.73278046, 20.43972206],
      [86.73249817, 20.43944359],
      [86.73249817, 20.43916702],
      [86.73306274, 20.43861008],
      [86.73305511, 20.43805504],
      [86.73249817, 20.43750191],
      [86.73249817, 20.43639183],
      [86.73221588, 20.43611145],
      [86.73222351, 20.43500137],
      [86.73249817, 20.43473244],
      [86.73249817, 20.43222046],
      [86.73278046, 20.43194389],
      [86.73278046, 20.42666626],
      [86.73306274, 20.42638969],
      [86.73305511, 20.42611122],
      [86.73332977, 20.42583466],
      [86.73332977, 20.42499924],
      [86.73306274, 20.42472267],
      [86.73305511, 20.42361259],
      [86.73332977, 20.42333412],
      [86.73332977, 20.42305565],
      [86.73361206, 20.42279053],
      [86.73361206, 20.41860962],
      [86.73388672, 20.41833305],
      [86.73388672, 20.41777611],
      [86.73332977, 20.41722107],
      [86.73332977, 20.4169445],
      [86.73305511, 20.41666412],
      [86.73305511, 20.41638947],
      [86.73361206, 20.41583252],
      [86.73305511, 20.41527748],
      [86.73306274, 20.41472054],
      [86.73332977, 20.41444588],
      [86.73332977, 20.41388893],
      [86.73306274, 20.41362],
      [86.73306274, 20.41333008],
      [86.73278046, 20.41305542],
      [86.73278809, 20.41082954],
      [86.73305511, 20.41055489],
      [86.73249817, 20.41000175],
      [86.73278809, 20.40971947],
      [86.73222351, 20.40916634],
      [86.73221588, 20.40889168],
      [86.73194122, 20.40860939],
      [86.73194122, 20.40833282],
      [86.73166656, 20.40805626],
      [86.73166656, 20.40777969],
      [86.73139191, 20.40750122],
      [86.73105621, 20.40707016],
      [86.73000336, 20.40582085],
      [86.72943115, 20.40488052],
      [86.73107147, 20.40365982],
      [86.73171234, 20.40319252],
      [86.73271179, 20.40251732],
      [86.73278046, 20.40277863],
      [86.73332977, 20.40333366],
      [86.73361206, 20.40332985],
      [86.73388672, 20.40361023],
      [86.73416901, 20.40361023],
      [86.73583221, 20.40389061],
      [86.73610687, 20.40417099],
      [86.73638916, 20.40416527],
      [86.73649597, 20.40427589],
      [86.73666382, 20.40444374],
      [86.73750305, 20.40444374],
      [86.73777771, 20.40472221],
      [86.73944092, 20.40472031],
      [86.73972321, 20.40500069],
      [86.74054718, 20.40500069],
      [86.7408371, 20.40472031],
      [86.74111176, 20.40472031],
      [86.74138641, 20.40500069],
      [86.74305725, 20.40500069],
      [86.74333191, 20.40528107],
      [86.74360657, 20.40528107],
      [86.74388885, 20.40555954],
      [86.74416351, 20.40555573],
      [86.7444458, 20.4058342],
      [86.74500275, 20.4058342],
      [86.7452774, 20.40555573],
      [86.74555206, 20.40555954],
      [86.74582672, 20.40528107],
      [86.74666595, 20.40528107],
      [86.74694824, 20.40500069],
      [86.74833679, 20.40500069],
      [86.74888611, 20.40555954],
      [86.7491684, 20.40555954],
      [86.74973297, 20.40500069],
      [86.74972534, 20.40472221],
      [86.75055695, 20.4038887],
      [86.75055695, 20.40361023],
      [86.75195313, 20.40221977],
      [86.7519455, 20.40166664],
      [86.75222015, 20.40139008],
      [86.75222015, 20.40055084],
      [86.7519455, 20.40027809],
      [86.75195313, 20.39999962],
      [86.75027466, 20.3983326],
      [86.75, 20.3983326],
      [86.74972534, 20.39805794],
      [86.74944305, 20.39777756],
      [86.7491684, 20.39777946],
      [86.74888611, 20.39749908],
      [86.74861145, 20.39722252],
      [86.74833679, 20.39722252],
      [86.7480545, 20.39694405],
      [86.7472229, 20.39694405],
      [86.74694824, 20.39666748],
      [86.74610901, 20.39666939],
      [86.74582672, 20.39639282],
      [86.74472809, 20.39639282],
      [86.7444458, 20.39610863],
      [86.74388885, 20.39610863],
      [86.74360657, 20.39582825],
      [86.74277496, 20.39583397],
      [86.74250031, 20.3955555],
      [86.74194336, 20.39555931],
      [86.74166107, 20.39528084],
      [86.74111176, 20.39528084],
      [86.73999786, 20.39417076],
      [86.74028015, 20.39389038],
      [86.74054718, 20.39389038],
      [86.7408371, 20.39360809],
      [86.74111176, 20.39360809],
      [86.74124908, 20.39374733],
      [86.74138641, 20.39389038],
      [86.7416687, 20.39389038],
      [86.74192047, 20.39414024],
      [86.74222565, 20.39444351],
      [86.74250031, 20.39444351],
      [86.74277496, 20.39472008],
      [86.74388885, 20.39477539],
      [86.74415588, 20.39499855],
      [86.74582672, 20.39499855],
      [86.74610901, 20.39528084],
      [86.7463913, 20.39528084],
      [86.74666595, 20.39555931],
      [86.7472229, 20.3955555],
      [86.74749756, 20.39583397],
      [86.74833679, 20.39583397],
      [86.748703, 20.3962059],
      [86.74888611, 20.39639282],
      [86.74944305, 20.39639091],
      [86.74951935, 20.39646339],
      [86.74973297, 20.39666939],
      [86.75, 20.39666748],
      [86.75027466, 20.39694405],
      [86.75138855, 20.39694405],
      [86.75166321, 20.39722252],
      [86.75195313, 20.39722252],
      [86.75222015, 20.3975029],
      [86.75250244, 20.3975029],
      [86.7527771, 20.39777946],
      [86.75332642, 20.39777946],
      [86.75360107, 20.39805984],
      [86.75444794, 20.39805603],
      [86.7547226, 20.3983326],
      [86.75499725, 20.3983326],
      [86.75527954, 20.39862061],
      [86.75554657, 20.39862061],
      [86.75611115, 20.39916992],
      [86.75666809, 20.39916992],
      [86.75749969, 20.3983326],
      [86.75777435, 20.3983326],
      [86.75805664, 20.39805603],
      [86.7583313, 20.39805984],
      [86.75860596, 20.39777946],
      [86.75860596, 20.39582825],
      [86.7583313, 20.3955555],
      [86.7583313, 20.39528084],
      [86.75805664, 20.39499855],
      [86.75805664, 20.39360809],
      [86.75777435, 20.39333344],
      [86.75777435, 20.39222145],
      [86.75749969, 20.39194489],
      [86.75749969, 20.39138794],
      [86.75723267, 20.39110947],
      [86.75722504, 20.3908329],
      [86.75694275, 20.39055634],
      [86.75694275, 20.39027977],
      [86.75666809, 20.38999939],
      [86.75611115, 20.38943291],
      [86.75611115, 20.38916588],
      [86.75666809, 20.38861084],
      [86.75694275, 20.38861084],
      [86.75722504, 20.38888931],
      [86.75777435, 20.38944435],
      [86.75776672, 20.38972092],
      [86.75805664, 20.38999939],
      [86.75805664, 20.39027977],
      [86.7583313, 20.39055634],
      [86.7583313, 20.39110947],
      [86.75861359, 20.39138794],
      [86.75861359, 20.39194489],
      [86.75888824, 20.39221954],
      [86.75888824, 20.39305496],
      [86.7591629, 20.39333344],
      [86.7591629, 20.39444351],
      [86.75944519, 20.39472008],
      [86.75972748, 20.39499855],
      [86.75971985, 20.39527893],
      [86.76027679, 20.39582825],
      [86.76027679, 20.39610863],
      [86.7611084, 20.39694405],
      [86.76249695, 20.39694405],
      [86.76277924, 20.39722252],
      [86.76304626, 20.39722252],
      [86.76333618, 20.3975029],
      [86.76360321, 20.39755249],
      [86.7636795, 20.39756775],
      [86.76389313, 20.39777946],
      [86.76416779, 20.39777946],
      [86.76444244, 20.3975029],
      [86.76499939, 20.3975029],
      [86.76526642, 20.39777946],
      [86.76555634, 20.39777756],
      [86.76611328, 20.3983326],
      [86.76638794, 20.3983326],
      [86.76667023, 20.39805603],
      [86.76693726, 20.39805984],
      [86.76721954, 20.39777946],
      [86.76805878, 20.39777946],
      [86.76832581, 20.39805984],
      [86.76889801, 20.39805984],
      [86.76917267, 20.39777946],
      [86.76999664, 20.39777946],
      [86.77027893, 20.3975029],
      [86.77054596, 20.3975029],
      [86.77083588, 20.39722252],
      [86.77111053, 20.39722252],
      [86.77138519, 20.39694405],
      [86.77249908, 20.39694405],
      [86.77278137, 20.39666748],
      [86.77305603, 20.39666939],
      [86.77388763, 20.39582825],
      [86.77416992, 20.39583397],
      [86.77444458, 20.3955555],
      [86.77471161, 20.39555931],
      [86.77500153, 20.39528084],
      [86.77527618, 20.39528084],
      [86.77610779, 20.3944397],
      [86.77639008, 20.39444351],
      [86.77666473, 20.39416885],
      [86.77722168, 20.39417076],
      [86.77971649, 20.39167023],
      [86.77971649, 20.39138985],
      [86.77999878, 20.39110947],
      [86.77999878, 20.3908329],
      [86.78083038, 20.38999939],
      [86.78083038, 20.38972282],
      [86.78111267, 20.38944435],
      [86.78111267, 20.38888931],
      [86.78166962, 20.38833046],
      [86.78166962, 20.38777733],
      [86.78193665, 20.38750076],
      [86.78193665, 20.3866806],
      [86.78221893, 20.38639069],
      [86.78221893, 20.38582993],
      [86.78194427, 20.38555527],
      [86.78221893, 20.38528061],
      [86.78221893, 20.38362122],
      [86.78193665, 20.38332939],
      [86.78194427, 20.38305473],
      [86.78166962, 20.38277817],
      [86.78166962, 20.38221931],
      [86.78111267, 20.38166618],
      [86.78111267, 20.38139153],
      [86.77971649, 20.37999916],
      [86.77916718, 20.37999916],
      [86.77889252, 20.37972069],
      [86.77833557, 20.3797226],
      [86.77805328, 20.37944412],
      [86.77639008, 20.37944412],
      [86.77610779, 20.37916946],
      [86.77555847, 20.37916946],
      [86.77527618, 20.37889099],
      [86.77471924, 20.37888718],
      [86.77444458, 20.37861061],
      [86.77388763, 20.37861061],
      [86.77361298, 20.37833023],
      [86.77333069, 20.37833405],
      [86.77305603, 20.37805557],
      [86.77278137, 20.37805939],
      [86.77222443, 20.37749863],
      [86.77166748, 20.37693977],
      [86.77166748, 20.37666512],
      [86.76972198, 20.37471962],
      [86.76972198, 20.37444305],
      [86.76917267, 20.37388992],
      [86.76917267, 20.37360954],
      [86.76889038, 20.37333488],
      [86.76889038, 20.37305641],
      [86.76860809, 20.37277985],
      [86.76860809, 20.3722229],
      [86.76721954, 20.37083244],
      [86.76694489, 20.37083244],
      [86.76611328, 20.37000275],
      [86.76611328, 20.36972237],
      [86.76583099, 20.36944389],
      [86.76583099, 20.36889076],
      [86.76526642, 20.36832047],
      [86.76527405, 20.36805534],
      [86.76499939, 20.36778069],
      [86.76499939, 20.3674984],
      [86.7647171, 20.36721992],
      [86.76472473, 20.36694527],
      [86.76416779, 20.36638832],
      [86.76416779, 20.36582947],
      [86.76472473, 20.36527634],
      [86.76555634, 20.36610985],
      [86.76555634, 20.36638832],
      [86.76583099, 20.36667061],
      [86.76583099, 20.36694527],
      [86.76611328, 20.36721992],
      [86.76611328, 20.3674984],
      [86.76638794, 20.36778069],
      [86.76638794, 20.36805916],
      [86.76693726, 20.36860847],
      [86.76694489, 20.36889076],
      [86.76750183, 20.36944389],
      [86.76750183, 20.36972237],
      [86.76794434, 20.36998177],
      [86.76838684, 20.37033272],
      [86.7688446, 20.37068176],
      [86.76927948, 20.37105942],
      [86.76972961, 20.37138939],
      [86.77017212, 20.37170982],
      [86.77056122, 20.37207794],
      [86.77091217, 20.37244034],
      [86.7712326, 20.37275124],
      [86.771492, 20.37301826],
      [86.77172089, 20.37329102],
      [86.7719574, 20.37360191],
      [86.77222443, 20.37395668],
      [86.7725296, 20.37439156],
      [86.7728653, 20.37487793],
      [86.77321625, 20.3753624],
      [86.77379608, 20.37560463],
      [86.77444458, 20.37583351],
      [86.77471924, 20.37583351],
      [86.77500153, 20.37611198],
      [86.77527618, 20.37611008],
      [86.77555847, 20.37639046],
      [86.77583313, 20.37611008],
      [86.77597046, 20.37611008],
      [86.77639008, 20.37611198],
      [86.77666473, 20.37583351],
      [86.77777863, 20.37583351],
      [86.77805328, 20.37555695],
      [86.77861023, 20.37555695],
      [86.77889252, 20.37583351],
      [86.77944183, 20.37583351],
      [86.77955627, 20.37594414],
      [86.77972412, 20.37611198],
      [86.78028107, 20.37611008],
      [86.78056335, 20.37639046],
      [86.78083801, 20.37639046],
      [86.78134918, 20.37634087],
      [86.78188324, 20.37652969],
      [86.78250122, 20.37661743],
      [86.78312683, 20.37668037],
      [86.78376007, 20.37690163],
      [86.78436279, 20.37742043],
      [86.78495026, 20.37746239],
      [86.78552246, 20.37720108],
      [86.78607941, 20.37691498],
      [86.78637695, 20.37651253],
      [86.78623199, 20.3759613],
      [86.78598022, 20.37540627],
      [86.78573608, 20.37490082],
      [86.78553009, 20.37447357],
      [86.78522491, 20.37412071],
      [86.78491974, 20.37380028],
      [86.78464508, 20.37347984],
      [86.78457642, 20.37301636],
      [86.78472137, 20.37239075],
      [86.78465271, 20.37207031],
      [86.78446198, 20.37183952],
      [86.78427887, 20.37158585],
      [86.78410339, 20.37127113],
      [86.78380585, 20.37092972],
      [86.78343201, 20.37051964],
      [86.78301239, 20.37001991],
      [86.78256989, 20.36947441],
      [86.78208923, 20.36894035],
      [86.78166962, 20.36837959],
      [86.78125763, 20.36786652],
      [86.78085327, 20.36742973],
      [86.78056335, 20.36699104],
      [86.78027344, 20.36655998],
      [86.78000641, 20.36610985],
      [86.77971649, 20.36555099],
      [86.77972412, 20.36527634],
      [86.77944183, 20.36499977],
      [86.77944183, 20.36471939],
      [86.77916718, 20.36444473],
      [86.77916718, 20.36416626],
      [86.77889252, 20.36388969],
      [86.77889252, 20.36361122],
      [86.77861023, 20.36333084],
      [86.77861023, 20.36305428],
      [86.7783432, 20.36277962],
      [86.7783432, 20.36222076],
      [86.77805328, 20.3619442],
      [86.77805328, 20.36166573],
      [86.77783966, 20.36138344],
      [86.77777863, 20.36083412],
      [86.77749634, 20.36055565],
      [86.77749634, 20.35917091],
      [86.77722168, 20.35889053],
      [86.77749634, 20.35861015],
      [86.77749634, 20.35833359],
      [86.77757263, 20.3582592],
      [86.77777863, 20.35805511],
      [86.777771, 20.3555603],
      [86.77861023, 20.35471916],
      [86.77861023, 20.35528183],
      [86.77889252, 20.35555649],
      [86.77889252, 20.35611153],
      [86.77916718, 20.35638046],
      [86.77916718, 20.35667038],
      [86.77889252, 20.35695267],
      [86.77889252, 20.35889053],
      [86.77916718, 20.35917091],
      [86.77916718, 20.35944366],
      [86.77944183, 20.35972023],
      [86.77944183, 20.36028099],
      [86.77971649, 20.36055946],
      [86.77972412, 20.36166573],
      [86.77999878, 20.3619442],
      [86.77999878, 20.36222076],
      [86.78083038, 20.36305428],
      [86.78083801, 20.36361122],
      [86.78111267, 20.36388969],
      [86.78250122, 20.36527634],
      [86.78250122, 20.36555099],
      [86.78388977, 20.36694527],
      [86.7838974, 20.36721992],
      [86.78444672, 20.36778069],
      [86.78444672, 20.36805916],
      [86.78666687, 20.3702774],
      [86.78666687, 20.37083244],
      [86.78749847, 20.37166977],
      [86.78833008, 20.37083244],
      [86.78833008, 20.37055588],
      [86.78861237, 20.37028122],
      [86.78861237, 20.37000275],
      [86.78888702, 20.36972237],
      [86.78861237, 20.36944389],
      [86.78861237, 20.36805534],
      [86.78916931, 20.3674984],
      [86.78916931, 20.36721992],
      [86.78944397, 20.36694527],
      [86.78943634, 20.36667061],
      [86.78916931, 20.36638832],
      [86.78916931, 20.36610985],
      [86.78861237, 20.36555481],
      [86.78884125, 20.36532593],
      [86.78944397, 20.3647213],
      [86.78916931, 20.36444473],
      [86.78916931, 20.36361122],
      [86.79000092, 20.36277962],
      [86.79000092, 20.36111069],
      [86.78943634, 20.36055946],
      [86.78943634, 20.36028099],
      [86.789711, 20.36000061],
      [86.78943634, 20.35972023],
      [86.78971863, 20.35944366],
      [86.789711, 20.35917091],
      [86.78916931, 20.35861015],
      [86.78916931, 20.35833359],
      [86.78861237, 20.35777855],
      [86.78833008, 20.35750008],
      [86.78833008, 20.3572216],
      [86.78805542, 20.35694504],
      [86.78806305, 20.35667038],
      [86.78694153, 20.3555603],
      [86.78694153, 20.35528183],
      [86.78666687, 20.35500145],
      [86.78666687, 20.35471916],
      [86.78444672, 20.35250092],
      [86.78444672, 20.35222054],
      [86.78416443, 20.35194397],
      [86.78416443, 20.35166931],
      [86.7838974, 20.35139084],
      [86.7838974, 20.35111046],
      [86.78360748, 20.35083389],
      [86.78360748, 20.35055542],
      [86.78277588, 20.34972],
      [86.78277588, 20.34944534],
      [86.78221893, 20.3488903],
      [86.78221893, 20.34860992],
      [86.78194427, 20.34833336],
      [86.78194427, 20.34805489],
      [86.78166962, 20.34777832],
      [86.78166962, 20.34721947],
      [86.78111267, 20.34666634],
      [86.78111267, 20.34638977],
      [86.78083801, 20.34610939],
      [86.78083038, 20.34583473],
      [86.77777863, 20.34277725],
      [86.77749634, 20.34278107],
      [86.77722168, 20.34250069],
      [86.77694702, 20.34250069],
      [86.77610779, 20.34333038],
      [86.77610779, 20.34361267],
      [86.77583313, 20.34389114],
      [86.77610779, 20.3441658],
      [86.77610779, 20.34472275],
      [86.77583313, 20.34499931],
      [86.77583313, 20.34583473],
      [86.77555847, 20.34610939],
      [86.77555847, 20.34638977],
      [86.77527618, 20.34666634],
      [86.77527618, 20.34694481],
      [86.77475739, 20.34745979],
      [86.77443695, 20.34750938],
      [86.77419281, 20.34724617],
      [86.77388763, 20.34722137],
      [86.77361298, 20.34694481],
      [86.77361298, 20.34666634],
      [86.77388763, 20.34638977],
      [86.77388763, 20.34610939],
      [86.77471924, 20.34527779],
      [86.77471161, 20.34416962],
      [86.77443695, 20.34389114],
      [86.77443695, 20.34305954],
      [86.77416992, 20.34278107],
      [86.77416992, 20.3422184],
      [86.77471924, 20.34166718],
      [86.77471161, 20.34056091],
      [86.77500153, 20.34028053],
      [86.77500153, 20.33971977],
      [86.77471924, 20.33944511],
      [86.77471161, 20.33917046],
      [86.77443695, 20.33888817],
      [86.77443695, 20.33834076],
      [86.77471161, 20.33806038],
      [86.7743988, 20.33777809],
      [86.77416992, 20.33777809],
      [86.77249908, 20.33611107],
      [86.77249908, 20.33583069],
      [86.77138519, 20.33472252],
      [86.77138519, 20.33444595],
      [86.77111053, 20.33416748],
      [86.77111053, 20.33361053],
      [86.77054596, 20.33306122],
      [86.77054596, 20.33250046],
      [86.76917267, 20.33111191],
      [86.76889038, 20.33111382],
      [86.76860809, 20.33083344],
      [86.76833344, 20.33083344],
      [86.76805878, 20.33055496],
      [86.76777649, 20.33056068],
      [86.76721954, 20.33000183],
      [86.76693726, 20.33000183],
      [86.76667023, 20.32971954],
      [86.76638794, 20.32971954],
      [86.76611328, 20.32944489],
      [86.76583099, 20.32944489],
      [86.76472473, 20.32833481],
      [86.76416779, 20.32777977],
      [86.76389313, 20.32778168],
      [86.76333618, 20.32722092],
      [86.76305389, 20.32722282],
      [86.76277924, 20.32694435],
      [86.76249695, 20.32694435],
      [86.76222229, 20.32666588],
      [86.76194763, 20.32666969],
      [86.76167297, 20.32640076],
      [86.76139069, 20.32640076],
      [86.7611084, 20.32611084],
      [86.76082611, 20.32611084],
      [86.76027679, 20.32555962],
      [86.76000214, 20.32555962],
      [86.7591629, 20.32472038],
      [86.75888824, 20.32472229],
      [86.75879669, 20.32462883],
      [86.75861359, 20.32444382],
      [86.7583313, 20.32444382],
      [86.75819397, 20.3243084],
      [86.75805664, 20.32416534],
      [86.75776672, 20.32417107],
      [86.75749969, 20.32389069],
      [86.75723267, 20.32389069],
      [86.75694275, 20.32361031],
      [86.7563858, 20.32361031],
      [86.75583649, 20.32306099],
      [86.75554657, 20.32306099],
      [86.75499725, 20.32250023],
      [86.75332642, 20.32250023],
      [86.7527771, 20.32195091],
      [86.75222015, 20.32195091],
      [86.75166321, 20.32139015],
      [86.75138855, 20.32139015],
      [86.75, 20.31999969],
      [86.74972534, 20.31999969],
      [86.7491684, 20.31944466],
      [86.74888611, 20.31944466],
      [86.74861145, 20.31916618],
      [86.74833679, 20.31917],
      [86.74804688, 20.31888962],
      [86.74777985, 20.31888962],
      [86.74694824, 20.31805611],
      [86.74666595, 20.31805992],
      [86.74610901, 20.31750107],
      [86.74582672, 20.31750107],
      [86.74570465, 20.31737137],
      [86.74555206, 20.31720924],
      [86.7452774, 20.3172226],
      [86.74472046, 20.31666756],
      [86.74415588, 20.31611252],
      [86.74388885, 20.31611252],
      [86.74305725, 20.31528091],
      [86.74305725, 20.31500053],
      [86.74223328, 20.31417084],
      [86.74194336, 20.31417084],
      [86.74166107, 20.31389046],
      [86.74166107, 20.31360817],
      [86.74111176, 20.31305504],
      [86.74111176, 20.31278038],
      [86.73805237, 20.30972099],
      [86.73777771, 20.30944443],
      [86.73750305, 20.30944443],
      [86.73694611, 20.30888939],
      [86.73665619, 20.30888939],
      [86.73638916, 20.30861092],
      [86.73610687, 20.30861092],
      [86.73583221, 20.30833054],
      [86.73555756, 20.30833244],
      [86.73500061, 20.3077774],
      [86.73472595, 20.30778122],
      [86.73443604, 20.30750084],
      [86.73361206, 20.30750084],
      [86.73332977, 20.30722046],
      [86.73222351, 20.30722237],
      [86.73205566, 20.30705452],
      [86.73194122, 20.30694389],
      [86.72972107, 20.30694389],
      [86.72958374, 20.30680466],
      [86.72944641, 20.30666733],
      [86.72721863, 20.30667114],
      [86.72693634, 20.30639076],
      [86.72638702, 20.30638885],
      [86.72583008, 20.30694389],
      [86.72555542, 20.30694389],
      [86.72528076, 20.30722237],
      [86.72499847, 20.30722237],
      [86.72472382, 20.30694389],
      [86.72472382, 20.30666733],
      [86.72444153, 20.30639076],
      [86.72389221, 20.30639076],
      [86.72381592, 20.30631065],
      [86.72333527, 20.30583382],
      [86.72249603, 20.30583191],
      [86.72194672, 20.30639076],
      [86.7216568, 20.30639076],
      [86.72138977, 20.30611038],
      [86.72110748, 20.30611038],
      [86.72083282, 20.30583191],
      [86.72055817, 20.30583382],
      [86.72044373, 20.30572128],
      [86.72027588, 20.30555534],
      [86.72000122, 20.30556107],
      [86.71986389, 20.30541992],
      [86.71971893, 20.30527687],
      [86.71944427, 20.30527687],
      [86.71888733, 20.30472374],
      [86.71861267, 20.30472374],
      [86.71833038, 20.30444527],
      [86.71806335, 20.3044529],
      [86.71778107, 20.30417061],
      [86.71749878, 20.30417061],
      [86.71721649, 20.30389023],
      [86.71694183, 20.30389023],
      [86.71675873, 20.30370331],
      [86.71639252, 20.30333328],
      [86.71611023, 20.30333328],
      [86.71583557, 20.30305481],
      [86.71555328, 20.30305481],
      [86.71527863, 20.30277824],
      [86.71499634, 20.30278015],
      [86.71444702, 20.30220985],
      [86.71416473, 20.30221939],
      [86.71360779, 20.30166626],
      [86.71333313, 20.30167007],
      [86.71305847, 20.3013916],
      [86.71277618, 20.3013916],
      [86.71193695, 20.30055809],
      [86.71167755, 20.30055809],
      [86.71056366, 20.29944992],
      [86.70999908, 20.29888725],
      [86.7097168, 20.29889107],
      [86.70833588, 20.29750061],
      [86.70853424, 20.29389],
      [86.70861053, 20.29249954],
      [86.70944214, 20.29249954],
      [86.7097168, 20.29277992],
      [86.70999908, 20.29277992],
      [86.71028137, 20.29249954],
      [86.71221924, 20.29249954],
      [86.71250153, 20.29277992],
      [86.71277618, 20.29249954],
      [86.71305847, 20.29249954],
      [86.71333313, 20.29277992],
      [86.71360779, 20.29277992],
      [86.7141571, 20.29222107],
      [86.71444702, 20.29222488],
      [86.71472168, 20.2919445],
      [86.71555328, 20.2919426],
      [86.71639252, 20.29277992],
      [86.71721649, 20.29277992],
      [86.71806335, 20.2919426],
      [86.71805573, 20.29166603],
      [86.71848297, 20.2912426],
      [86.71861267, 20.2911129],
      [86.71888733, 20.2911129],
      [86.71888733, 20.29029083],
      [86.71861267, 20.29000282],
      [86.71861267, 20.28972244],
      [86.71833038, 20.28944397],
      [86.71833038, 20.28889084],
      [86.71806335, 20.28860855],
      [86.71805573, 20.28777885],
      [86.71721649, 20.28694916],
      [86.71694183, 20.28694916],
      [86.71639252, 20.2863903],
      [86.7158432, 20.2863903],
      [86.71527863, 20.28582954],
      [86.71472168, 20.28583336],
      [86.71444702, 20.28555489],
      [86.71416473, 20.28555489],
      [86.71360779, 20.28499985],
      [86.71305847, 20.28499985],
      [86.71277618, 20.28471947],
      [86.71250153, 20.28472137],
      [86.71221924, 20.28444481],
      [86.71194458, 20.28444481],
      [86.71180725, 20.28430557],
      [86.71166992, 20.28416634],
      [86.71138763, 20.28417015],
      [86.71111298, 20.28388977],
      [86.71083069, 20.28388977],
      [86.71028137, 20.28333092],
      [86.70972443, 20.28333282],
      [86.70944214, 20.28305435],
      [86.70889282, 20.28306007],
      [86.70861816, 20.28277969],
      [86.70804596, 20.28277969],
      [86.70777893, 20.28250122],
      [86.70722198, 20.28250122],
      [86.70708466, 20.28236008],
      [86.70694733, 20.28222084],
      [86.70666504, 20.28222275],
      [86.70639038, 20.28194427],
      [86.70610809, 20.28194427],
      [86.70583344, 20.2816658],
      [86.70527649, 20.28166962],
      [86.70500183, 20.28139114],
      [86.70471954, 20.28139114],
      [86.70443726, 20.28110123],
      [86.70388794, 20.28111076],
      [86.70361328, 20.2808342],
      [86.70333099, 20.2808342],
      [86.70277405, 20.28027725],
      [86.70194244, 20.28028107],
      [86.70166779, 20.28000069],
      [86.7013855, 20.28000069],
      [86.70111084, 20.27972221],
      [86.70083618, 20.27972221],
      [86.700737, 20.27962494],
      [86.70055389, 20.27944374],
      [86.70027924, 20.27944374],
      [86.69999695, 20.27916718],
      [86.69917297, 20.2791729],
      [86.69889069, 20.27889061],
      [86.69805908, 20.27889061],
      [86.69777679, 20.27861023],
      [86.69721985, 20.27861023],
      [86.69695282, 20.27833176],
      [86.69638824, 20.27833366],
      [86.69611359, 20.27805519],
      [86.6958313, 20.27806282],
      [86.69555664, 20.27778053],
      [86.69526672, 20.27778053],
      [86.69499969, 20.27750015],
      [86.69472504, 20.27750015],
      [86.69444275, 20.27722168],
      [86.6938858, 20.27722168],
      [86.69333649, 20.27666664],
      [86.69304657, 20.27667046],
      [86.69277954, 20.27639008],
      [86.69249725, 20.27639008],
      [86.6922226, 20.2761116],
      [86.69194794, 20.27583313],
      [86.69166565, 20.27583313],
      [86.69139099, 20.27555466],
      [86.6911087, 20.27555466],
      [86.6902771, 20.27472305],
      [86.69000244, 20.27472305],
      [86.68861389, 20.2733326],
      [86.68805695, 20.2733326],
      [86.68777466, 20.27305794],
      [86.68749237, 20.27305984],
      [86.68723297, 20.27277946],
      [86.6866684, 20.27277946],
      [86.68554688, 20.27166939],
      [86.68527985, 20.27194405],
      [86.6847229, 20.27194405],
      [86.6838913, 20.27111053],
      [86.68332672, 20.27111053],
      [86.68305206, 20.27083015],
      [86.6827774, 20.27083397],
      [86.68250275, 20.2705555],
      [86.68222046, 20.27056122],
      [86.6819458, 20.27028084],
      [86.68165588, 20.27028084],
      [86.68138885, 20.27000046],
      [86.68110657, 20.27000046],
      [86.68026733, 20.26916122],
      [86.68027496, 20.26888847],
      [86.68000031, 20.26861],
      [86.68000031, 20.26805305],
      [86.68026733, 20.2677803],
      [86.68026733, 20.26694107],
      [86.68000031, 20.26666641],
      [86.68000031, 20.26610184],
      [86.68027496, 20.26583481],
      [86.68027496, 20.26527786],
      [86.68055725, 20.26499939],
      [86.68055725, 20.26468086],
      [86.68055725, 20.26388931],
      [86.68000031, 20.26333237],
      [86.67973328, 20.26333237],
      [86.67888641, 20.26416588],
      [86.67888641, 20.26444054],
      [86.67805481, 20.26527786],
      [86.67804718, 20.26610184],
      [86.67749786, 20.26666641],
      [86.67749786, 20.26694489],
      [86.67722321, 20.26694489],
      [86.67707825, 20.26680374],
      [86.67694092, 20.26666641],
      [86.67666626, 20.26667023],
      [86.67610931, 20.26610184],
      [86.67555237, 20.26667023],
      [86.67527771, 20.26667023],
      [86.67416382, 20.2677784],
      [86.67416382, 20.26833344],
      [86.67388916, 20.26861191],
      [86.67388916, 20.26916695],
      [86.67360687, 20.2694397],
      [86.67360687, 20.27139091],
      [86.67333221, 20.27166939],
      [86.67333221, 20.27249908],
      [86.67305756, 20.27277946],
      [86.67305756, 20.27305984],
      [86.67416382, 20.27416611],
      [86.67415619, 20.27444077],
      [86.67472076, 20.27500153],
      [86.67472076, 20.27527809],
      [86.67500305, 20.27555466],
      [86.67500305, 20.27582932],
      [86.67583466, 20.27666664],
      [86.67582703, 20.27722168],
      [86.67610931, 20.27750015],
      [86.67610931, 20.27778053],
      [86.6763916, 20.27806282],
      [86.6763916, 20.27861023],
      [86.67582703, 20.2791729],
      [86.67555237, 20.2791729],
      [86.67500305, 20.27861023],
      [86.67500305, 20.27833176],
      [86.67444611, 20.27777863],
      [86.67444611, 20.27722168],
      [86.67416382, 20.27694511],
      [86.67415619, 20.27667046],
      [86.67388916, 20.27639008],
      [86.67388916, 20.2761116],
      [86.6736145, 20.27583313],
      [86.6736145, 20.27555466],
      [86.67305756, 20.27500153],
      [86.67305756, 20.27471924],
      [86.67277527, 20.27444458],
      [86.67277527, 20.27416611],
      [86.67250824, 20.27388763],
      [86.67250061, 20.2733326],
      [86.67222595, 20.27333069],
      [86.67193604, 20.27360916],
      [86.67111206, 20.27360916],
      [86.67056274, 20.27416992],
      [86.67028046, 20.27416992],
      [86.66928864, 20.27515984],
      [86.66957092, 20.27563286],
      [86.66999817, 20.27722168],
      [86.67028046, 20.27750015],
      [86.67028046, 20.27778053],
      [86.67055511, 20.27805519],
      [86.67055511, 20.27833366],
      [86.67193604, 20.27972031],
      [86.67193604, 20.28000069],
      [86.67222595, 20.28027725],
      [86.67222595, 20.28110123],
      [86.67194366, 20.28138924],
      [86.67194366, 20.28194427],
      [86.67166901, 20.28222275],
      [86.67111206, 20.28222275],
      [86.67082977, 20.28194427],
      [86.67082977, 20.2816658],
      [86.67056274, 20.28139114],
      [86.67056274, 20.28110123],
      [86.67028046, 20.2808342],
      [86.67028046, 20.28027725],
      [86.66999817, 20.28000069],
      [86.66999817, 20.27943993],
      [86.66972351, 20.27916718],
      [86.66971588, 20.27861023],
      [86.66944885, 20.27833176],
      [86.66944122, 20.27805519],
      [86.66916656, 20.27778053],
      [86.66777802, 20.2761116],
      [86.66666412, 20.27500153],
      [86.66665649, 20.27444077],
      [86.66638947, 20.27416611],
      [86.66638184, 20.27333069],
      [86.66694641, 20.27277946],
      [86.66694641, 20.27249908],
      [86.66722107, 20.27222824],
      [86.66750336, 20.27222824],
      [86.66832733, 20.27139091],
      [86.66832733, 20.27111053],
      [86.66860962, 20.27083015],
      [86.66860962, 20.2705555],
      [86.66889191, 20.27028084],
      [86.66889191, 20.27000046],
      [86.66916656, 20.26972008],
      [86.66944122, 20.26972198],
      [86.66972351, 20.26944351],
      [86.67055511, 20.26944351],
      [86.67082977, 20.26916695],
      [86.67111206, 20.26916695],
      [86.67250061, 20.2677784],
      [86.67250824, 20.26749992],
      [86.67277527, 20.26722145],
      [86.67277527, 20.26666641],
      [86.67360687, 20.2658329],
      [86.6736145, 20.26555634],
      [86.67333221, 20.26527977],
      [86.67333221, 20.26499939],
      [86.67305756, 20.26472282],
      [86.67305756, 20.26444435],
      [86.67277527, 20.26416588],
      [86.6727829, 20.26250076],
      [86.6736145, 20.26166725],
      [86.67388916, 20.26166916],
      [86.67444611, 20.2611084],
      [86.67472076, 20.2611084],
      [86.67500305, 20.26082993],
      [86.67555237, 20.26083374],
      [86.67583466, 20.26055527],
      [86.67610931, 20.26055908],
      [86.6763916, 20.26029015],
      [86.67666626, 20.26029015],
      [86.67694092, 20.25999832],
      [86.67778015, 20.25999832],
      [86.67804718, 20.25971985],
      [86.67972565, 20.25972176],
      [86.68027496, 20.25916862],
      [86.68055725, 20.25917053],
      [86.68055725, 20.25888824],
      [86.67973328, 20.25805092],
      [86.6783371, 20.25805473],
      [86.67805481, 20.25833321],
      [86.67694092, 20.25832939],
      [86.67591095, 20.25834274],
      [86.67539978, 20.25834846],
      [86.67404938, 20.25788116],
      [86.67183685, 20.25761032],
      [86.66932678, 20.25653076],
      [86.6682663, 20.25630379],
      [86.66712189, 20.25605392],
      [86.66388702, 20.2547226],
      [86.66204071, 20.25406837],
      [86.66068268, 20.25392914],
      [86.65860748, 20.25305557],
      [86.65619659, 20.25259972],
      [86.65333557, 20.25138855],
      [86.6516571, 20.25056267],
      [86.64977264, 20.25002098],
      [86.64733887, 20.24908066],
      [86.6446228, 20.24818993],
      [86.64319611, 20.24745369],
      [86.64095306, 20.24693871],
      [86.6388092, 20.24605942],
      [86.63630676, 20.24552155],
      [86.6348114, 20.24417114],
      [86.63210297, 20.2428894],
      [86.62953186, 20.2422142],
      [86.62617493, 20.24072838],
      [86.62333679, 20.23971939],
      [86.62220001, 20.23877335],
      [86.61977386, 20.23789978],
      [86.61776733, 20.23722267],
      [86.6166687, 20.23611259],
      [86.61638641, 20.23612022],
      [86.6158371, 20.23555946],
      [86.61554718, 20.23555946],
      [86.61527252, 20.23527908],
      [86.61472321, 20.23527908],
      [86.61444092, 20.23500061],
      [86.6138916, 20.23500061],
      [86.61360931, 20.23471832],
      [86.61333466, 20.23472214],
      [86.61305237, 20.23444366],
      [86.61277771, 20.23444366],
      [86.61222076, 20.23389053],
      [86.61194611, 20.23389053],
      [86.61165619, 20.23360825],
      [86.6111145, 20.23361206],
      [86.61055756, 20.23305511],
      [86.61027527, 20.23305511],
      [86.61000061, 20.23277664],
      [86.60972595, 20.23278046],
      [86.60943604, 20.23249817],
      [86.60916901, 20.23249817],
      [86.60694122, 20.23167038],
      [86.60639191, 20.23110962],
      [86.60610962, 20.23111153],
      [86.60555267, 20.2305584],
      [86.60527802, 20.2305603],
      [86.60500336, 20.23027992],
      [86.60472107, 20.23027992],
      [86.60444641, 20.22999001],
      [86.60416412, 20.22999954],
      [86.60388947, 20.22972298],
      [86.60361481, 20.22972298],
      [86.60333252, 20.2294445],
      [86.60277557, 20.2294445],
      [86.60227203, 20.22894287],
      [86.60194397, 20.22861099],
      [86.60166931, 20.22861099],
      [86.60111237, 20.22805977],
      [86.60083008, 20.22805977],
      [86.59999847, 20.22722054],
      [86.59972382, 20.22722244],
      [86.59944153, 20.22694206],
      [86.59916687, 20.22694969],
      [86.59860992, 20.22639084],
      [86.5983429, 20.22639084],
      [86.59777832, 20.22583008],
      [86.59750366, 20.22583389],
      [86.59722137, 20.22555542],
      [86.59610748, 20.22555542],
      [86.59583282, 20.22583389],
      [86.59555817, 20.22583008],
      [86.59527588, 20.22611046],
      [86.59500122, 20.22611046],
      [86.59471893, 20.22639084],
      [86.59416962, 20.22583008],
      [86.59388733, 20.22583389],
      [86.59361267, 20.22555733],
      [86.59333038, 20.22527695],
      [86.59306335, 20.22528076],
      [86.59278107, 20.22500038],
      [86.59221649, 20.22500038],
      [86.59199524, 20.22477722],
      [86.59194183, 20.22472191],
      [86.59083557, 20.22472382],
      [86.58963776, 20.22352982],
      [86.58916473, 20.22305489],
      [86.58889008, 20.22305489],
      [86.58750153, 20.22166634],
      [86.58721924, 20.22167015],
      [86.58693695, 20.22139168],
      [86.58666992, 20.22139168],
      [86.58555603, 20.22027969],
      [86.585289, 20.22027969],
      [86.58499908, 20.22000122],
      [86.5847168, 20.22000122],
      [86.58448792, 20.21976852],
      [86.58416748, 20.21972275],
      [86.58398438, 20.21953964],
      [86.58361053, 20.2191658],
      [86.58333588, 20.21916962],
      [86.58304596, 20.21889114],
      [86.58304596, 20.21861076],
      [86.58277893, 20.21833038],
      [86.58249664, 20.2183342],
      [86.58222198, 20.21805573],
      [86.58194733, 20.21805],
      [86.58139038, 20.21861076],
      [86.58082581, 20.21861076],
      [86.58055878, 20.21889114],
      [86.58055878, 20.21916962],
      [86.58027649, 20.2191658],
      [86.58000183, 20.21944427],
      [86.57971954, 20.21944427],
      [86.57955933, 20.21928406],
      [86.57917023, 20.21888733],
      [86.57861328, 20.21889114],
      [86.57833099, 20.21861076],
      [86.5772171, 20.21861076],
      [86.57694244, 20.21833038],
      [86.57555389, 20.2183342],
      [86.57527924, 20.21805573],
      [86.57499695, 20.21805573],
      [86.57472229, 20.21777534],
      [86.57417297, 20.21778107],
      [86.5736084, 20.21722031],
      [86.57333374, 20.21722221],
      [86.57305908, 20.21694374],
      [86.57277679, 20.21694374],
      [86.57263947, 20.2168045],
      [86.57250214, 20.21666527],
      [86.57221985, 20.21667099],
      [86.57211304, 20.21656036],
      [86.57194519, 20.2163887],
      [86.5716629, 20.21639061],
      [86.5707016, 20.21542549],
      [86.56972504, 20.21444321],
      [86.56944275, 20.21445084],
      [86.56916809, 20.21417046],
      [86.5680542, 20.21416664],
      [86.56749725, 20.21472168],
      [86.56694794, 20.21472168],
      [86.56666565, 20.21444321],
      [86.56639099, 20.21445084],
      [86.5661087, 20.21417046],
      [86.56582642, 20.21417046],
      [86.56535339, 20.2136898],
      [86.5652771, 20.2136097],
      [86.56500244, 20.2136116],
      [86.56472015, 20.21333313],
      [86.5644455, 20.21333313],
      [86.56416321, 20.21305466],
      [86.56388855, 20.21305466],
      [86.5633316, 20.21249962],
      [86.56305695, 20.21249962],
      [86.56194305, 20.21138954],
      [86.56194305, 20.21111107],
      [86.56111908, 20.21027946],
      [86.56111908, 20.2100029],
      [86.56027985, 20.20916939],
      [86.55999756, 20.20916939],
      [86.5597229, 20.20889091],
      [86.5588913, 20.20889091],
      [86.55860901, 20.20860863],
      [86.55832672, 20.20860863],
      [86.55805969, 20.20832825],
      [86.5577774, 20.20833397],
      [86.55750275, 20.2080555],
      [86.55722046, 20.20805931],
      [86.5569458, 20.20778084],
      [86.55638885, 20.20778084],
      [86.55610657, 20.20805931],
      [86.55527496, 20.2080555],
      [86.55473328, 20.20860863],
      [86.55472565, 20.20916748],
      [86.55444336, 20.20944405],
      [86.55444336, 20.20972252],
      [86.5541687, 20.2100029],
      [86.55361176, 20.2100029],
      [86.55347443, 20.20986176],
      [86.5533371, 20.20972252],
      [86.55305481, 20.20972252],
      [86.55249786, 20.20916748],
      [86.55222321, 20.20916939],
      [86.55110931, 20.20805931],
      [86.55110931, 20.20778084],
      [86.54944611, 20.20610809],
      [86.54916382, 20.20611191],
      [86.54777527, 20.20472145],
      [86.54777527, 20.20444489],
      [86.54750061, 20.20416641],
      [86.54750061, 20.20388985],
      [86.54582977, 20.20222092],
      [86.54582977, 20.20194435],
      [86.54611206, 20.20166588],
      [86.54582977, 20.20139122],
      [86.54614258, 20.20055008],
      [86.54564667, 20.19967079],
      [86.54556274, 20.19833183],
      [86.54555511, 20.19805527],
      [86.54471588, 20.19722176],
      [86.54389191, 20.19722176],
      [86.54360962, 20.19750023],
      [86.54277802, 20.19750023],
      [86.54222107, 20.19805527],
      [86.54222107, 20.19833183],
      [86.54194641, 20.19861031],
      [86.54194641, 20.19889069],
      [86.54165649, 20.19917107],
      [86.54165649, 20.20028114],
      [86.54164886, 20.20119858],
      [86.541008, 20.20187187],
      [86.53888702, 20.20111084],
      [86.53861237, 20.20111084],
      [86.53833008, 20.20083046],
      [86.53778076, 20.20083427],
      [86.53749847, 20.2005558],
      [86.53721619, 20.20055962],
      [86.53666687, 20.2000103],
      [86.53610992, 20.2000103],
      [86.53472137, 20.19861031],
      [86.53472137, 20.19833183],
      [86.53305817, 20.19666672],
      [86.53277588, 20.19667053],
      [86.53277588, 20.19639015],
      [86.53250122, 20.19611168],
      [86.53250122, 20.19555473],
      [86.53166962, 20.19472313],
      [86.53083038, 20.19472313],
      [86.52972412, 20.19360924],
      [86.52944183, 20.19360924],
      [86.52916718, 20.19387817],
      [86.52916718, 20.19416618],
      [86.52889252, 20.19444466],
      [86.52889252, 20.19471931],
      [86.52861023, 20.1950016],
      [86.52805328, 20.1950016],
      [86.52749634, 20.19444084],
      [86.52749634, 20.19416618],
      [86.5266571, 20.19333076],
      [86.52666473, 20.19305611],
      [86.52583313, 20.19222069],
      [86.52583313, 20.19138718],
      [86.52527618, 20.19082069],
      [86.52471924, 20.19083405],
      [86.52444458, 20.19055557],
      [86.52443695, 20.19028091],
      [86.52388763, 20.18972015],
      [86.52305603, 20.18972206],
      [86.52278137, 20.18944359],
      [86.52249908, 20.18944359],
      [86.52138519, 20.18833351],
      [86.52055359, 20.18833351],
      [86.51810455, 20.18728447],
      [86.51721954, 20.18638802],
      [86.51693726, 20.18610954],
      [86.51694489, 20.18583488],
      [86.51611328, 20.18499947],
      [86.51582336, 20.18499947],
      [86.51499939, 20.18416977],
      [86.51499939, 20.18388939],
      [86.51444244, 20.18333244],
      [86.51444244, 20.18305588],
      [86.51416779, 20.18278122],
      [86.51416779, 20.18250275],
      [86.51361084, 20.18193817],
      [86.51333618, 20.18194389],
      [86.51305389, 20.18166733],
      [86.51277924, 20.18166924],
      [86.51235962, 20.18125153],
      [86.51194763, 20.18082809],
      [86.51166534, 20.18083382],
      [86.51148224, 20.1806488],
      [86.51139069, 20.18055534],
      [86.51139069, 20.18028069],
      [86.5111084, 20.1799984],
      [86.5111084, 20.17860985],
      [86.51139069, 20.17833328],
      [86.51083374, 20.17777634],
      [86.51055908, 20.17805481],
      [86.50971985, 20.1772213],
      [86.50945282, 20.17721939],
      [86.5091629, 20.17749977],
      [86.50888824, 20.17721939],
      [86.50805664, 20.1772213],
      [86.50777435, 20.17694473],
      [86.50723267, 20.17749977],
      [86.50694275, 20.17749977],
      [86.5055542, 20.17888641],
      [86.5047226, 20.17888641],
      [86.50389099, 20.17972183],
      [86.5036087, 20.17944527],
      [86.50332642, 20.17944908],
      [86.50297546, 20.17908859],
      [86.5027771, 20.17888641],
      [86.50195312, 20.17888069],
      [86.50166321, 20.17917061],
      [86.50138855, 20.17917061],
      [86.50110626, 20.17944908],
      [86.50055695, 20.17944527],
      [86.50027466, 20.17972183],
      [86.49861145, 20.17971992],
      [86.49833679, 20.1799984],
      [86.49804688, 20.1799984],
      [86.49749756, 20.18055916],
      [86.49555206, 20.18055534],
      [86.4952774, 20.18083382],
      [86.49500275, 20.18082809],
      [86.49472046, 20.18110847],
      [86.4944458, 20.18110847],
      [86.49415588, 20.18139076],
      [86.49388885, 20.18139076],
      [86.49360657, 20.18166924],
      [86.49360657, 20.18278122],
      [86.49582672, 20.18499947],
      [86.49610901, 20.18499947],
      [86.49666595, 20.18556023],
      [86.49694824, 20.18555641],
      [86.4972229, 20.18583488],
      [86.49749756, 20.18583107],
      [86.4991684, 20.1875],
      [86.4991684, 20.18805313],
      [86.49944305, 20.18833351],
      [86.49944305, 20.18861198],
      [86.49973297, 20.18861008],
      [86.50027466, 20.18916512],
      [86.5, 20.18944359],
      [86.49972534, 20.18944359],
      [86.49944305, 20.18972206],
      [86.4991684, 20.18972015],
      [86.49888611, 20.19000053],
      [86.49861145, 20.19000053],
      [86.49833679, 20.19028091],
      [86.49749756, 20.19028091],
      [86.4972229, 20.19000053],
      [86.4972229, 20.18972015],
      [86.49777985, 20.18916512],
      [86.49804688, 20.18917084],
      [86.49833679, 20.18889046],
      [86.4972229, 20.18778038],
      [86.49694824, 20.18778038],
      [86.49610901, 20.18695068],
      [86.49582672, 20.18695068],
      [86.49555206, 20.1866703],
      [86.4952774, 20.1866703],
      [86.49417114, 20.1855545],
      [86.49305725, 20.18444252],
      [86.49277496, 20.18444443],
      [86.49277496, 20.18388939],
      [86.49223328, 20.18333244],
      [86.49194336, 20.18333244],
      [86.49194336, 20.18305588],
      [86.49223328, 20.18278122],
      [86.49223328, 20.18221855],
      [86.49250031, 20.18194389],
      [86.49250031, 20.18055534],
      [86.49276733, 20.18028069],
      [86.49276733, 20.1799984],
      [86.49333191, 20.17944908],
      [86.49360657, 20.17944908],
      [86.49388885, 20.17917061],
      [86.49415588, 20.17917061],
      [86.4944458, 20.17888069],
      [86.49472046, 20.17888641],
      [86.4952774, 20.17833328],
      [86.4952774, 20.17777634],
      [86.49500275, 20.17749977],
      [86.4952774, 20.17721939],
      [86.49500275, 20.17694473],
      [86.49472046, 20.17694473],
      [86.49388885, 20.17611122],
      [86.49388885, 20.17556],
      [86.49415588, 20.17527962],
      [86.49388885, 20.17500114],
      [86.49388885, 20.17472076],
      [86.49250031, 20.17333221],
      [86.49222565, 20.17333221],
      [86.49194336, 20.17305565],
      [86.4916687, 20.17305946],
      [86.49138641, 20.17278099],
      [86.49054718, 20.17278099],
      [86.49028015, 20.17250061],
      [86.48972321, 20.17250061],
      [86.48944092, 20.17222023],
      [86.48833466, 20.17222214],
      [86.48805237, 20.17194366],
      [86.48777771, 20.17194366],
      [86.48750305, 20.1716671],
      [86.48638916, 20.1716671],
      [86.48583221, 20.17222214],
      [86.48416901, 20.17222214],
      [86.48388672, 20.17194366],
      [86.48361206, 20.17194366],
      [86.48347473, 20.17180824],
      [86.48332977, 20.1716671],
      [86.48306274, 20.17167282],
      [86.4828949, 20.1715107],
      [86.48278046, 20.17138863],
      [86.48249817, 20.17139053],
      [86.48055267, 20.16945267],
      [86.48055267, 20.16861153],
      [86.47972107, 20.16777992],
      [86.47970581, 20.16749763],
      [86.47944641, 20.16721916],
      [86.47944641, 20.1669445],
      [86.47861481, 20.16610909],
      [86.47833252, 20.16610909],
      [86.47805786, 20.16583061],
      [86.47777557, 20.16583252],
      [86.47750092, 20.16555786],
      [86.47666931, 20.16555786],
      [86.47528076, 20.16694069],
      [86.47528076, 20.16889191],
      [86.47499847, 20.16917038],
      [86.47444153, 20.16917038],
      [86.47416687, 20.16889191],
      [86.47416687, 20.16778183],
      [86.47389984, 20.16749763],
      [86.47389221, 20.16638756],
      [86.47416687, 20.16610909],
      [86.47416687, 20.16583061],
      [86.47472382, 20.16527939],
      [86.47583008, 20.16417122],
      [86.47528076, 20.16361046],
      [86.47499847, 20.16361046],
      [86.47471619, 20.16333008],
      [86.47444153, 20.16333389],
      [86.47416687, 20.16305542],
      [86.47389984, 20.16306114],
      [86.47362518, 20.16278648],
      [86.47277832, 20.16194534],
      [86.47277832, 20.16167068],
      [86.47249603, 20.1613903],
      [86.47249603, 20.16110992],
      [86.47222137, 20.16083336],
      [86.47222137, 20.16055107],
      [86.47194672, 20.16027832],
      [86.47194672, 20.15888977],
      [86.4716568, 20.15860939],
      [86.47194672, 20.15833282],
      [86.47194672, 20.15805626],
      [86.47222137, 20.15777016],
      [86.47250366, 20.15777779],
      [86.47277832, 20.15749931],
      [86.47277832, 20.15722275],
      [86.47305298, 20.15694427],
      [86.47305298, 20.1566658],
      [86.4733429, 20.15639114],
      [86.4733429, 20.15500069],
      [86.47360992, 20.15472221],
      [86.47360992, 20.15305519],
      [86.4733429, 20.15278053],
      [86.4733429, 20.15221977],
      [86.47277832, 20.15166855],
      [86.47277832, 20.15138817],
      [86.47249603, 20.1511097],
      [86.47250366, 20.15055466],
      [86.47166443, 20.14972305],
      [86.47138977, 20.14972305],
      [86.47083282, 20.1491642],
      [86.47084045, 20.14890099],
      [86.47055817, 20.14890099],
      [86.46943665, 20.14777946],
      [86.46916962, 20.14777946],
      [86.46888733, 20.14750099],
      [86.46861267, 20.14750099],
      [86.46847534, 20.14736176],
      [86.46833038, 20.14722061],
      [86.4658432, 20.14722061],
      [86.46555328, 20.14750099],
      [86.46527863, 20.14750099],
      [86.46499634, 20.14777946],
      [86.46472168, 20.14777756],
      [86.46376801, 20.14855194],
      [86.46305847, 20.1511097],
      [86.46305847, 20.15138817],
      [86.46277618, 20.15167046],
      [86.46277618, 20.15194511],
      [86.46250153, 20.15221977],
      [86.46193695, 20.15389061],
      [86.46194458, 20.15416718],
      [86.46166992, 20.15443993],
      [86.46166992, 20.15500069],
      [86.46045685, 20.15732002],
      [86.46038055, 20.15812874],
      [86.46053314, 20.15906906],
      [86.46083069, 20.16084099],
      [86.46083069, 20.16194534],
      [86.46055603, 20.16222191],
      [86.45999908, 20.16222191],
      [86.45972443, 20.16194534],
      [86.4597168, 20.16167068],
      [86.45944214, 20.1613903],
      [86.45944214, 20.16084099],
      [86.45916748, 20.16055107],
      [86.45916748, 20.15777779],
      [86.45944214, 20.15749931],
      [86.45944214, 20.15639114],
      [86.4597168, 20.15611267],
      [86.4597168, 20.15527916],
      [86.45999908, 20.15500069],
      [86.45999908, 20.15389061],
      [86.46055603, 20.15332985],
      [86.46055603, 20.15305519],
      [86.46111298, 20.15249825],
      [86.46111298, 20.15221977],
      [86.46138763, 20.15194511],
      [86.46138763, 20.1511097],
      [86.46166992, 20.15083313],
      [86.46166992, 20.14999962],
      [86.46277618, 20.14833069],
      [86.46472168, 20.14638901],
      [86.46499634, 20.14639091],
      [86.46604156, 20.14599037],
      [86.46761322, 20.14603996],
      [86.46861267, 20.14611053],
      [86.46888733, 20.14611053],
      [86.46916962, 20.14639091],
      [86.46944427, 20.14638901],
      [86.47027588, 20.14722252],
      [86.47055817, 20.14722061],
      [86.47110748, 20.14777946],
      [86.47138977, 20.14777946],
      [86.47222137, 20.14861107],
      [86.47249603, 20.14861107],
      [86.47305298, 20.1491642],
      [86.47305298, 20.14944077],
      [86.47389221, 20.15027618],
      [86.47389221, 20.15055466],
      [86.47416687, 20.15083122],
      [86.47416687, 20.15138817],
      [86.47444153, 20.15167046],
      [86.47444153, 20.15194511],
      [86.47471619, 20.15221977],
      [86.47472382, 20.15278053],
      [86.47528076, 20.15332985],
      [86.47528076, 20.15360832],
      [86.47499847, 20.15389061],
      [86.47499847, 20.15416718],
      [86.47472382, 20.15444374],
      [86.47471619, 20.15583229],
      [86.47444153, 20.15611267],
      [86.47444153, 20.15777016],
      [86.47416687, 20.15805626],
      [86.47416687, 20.16110992],
      [86.47444153, 20.1613903],
      [86.47444153, 20.16167068],
      [86.47528076, 20.16250038],
      [86.47556305, 20.16250038],
      [86.47583008, 20.16278076],
      [86.47750092, 20.16278076],
      [86.4777832, 20.16306114],
      [86.47805786, 20.16305542],
      [86.47833252, 20.16333389],
      [86.47860718, 20.16333008],
      [86.48027802, 20.16500092],
      [86.48027802, 20.16527939],
      [86.48082733, 20.16583061],
      [86.48083496, 20.16610909],
      [86.48444366, 20.1697216],
      [86.48472595, 20.16970825],
      [86.48500061, 20.17000008],
      [86.4852829, 20.17000008],
      [86.48555756, 20.1697216],
      [86.48833466, 20.1669445],
      [86.48833466, 20.16638756],
      [86.48860931, 20.16610909],
      [86.48860931, 20.16583061],
      [86.4888916, 20.16555786],
      [86.48887634, 20.16500092],
      [86.48916626, 20.16472054],
      [86.48916626, 20.16361046],
      [86.48944092, 20.16333008],
      [86.48944092, 20.16277695],
      [86.48972321, 20.16250038],
      [86.48972321, 20.16222],
      [86.48916626, 20.16166687],
      [86.48916626, 20.16110992],
      [86.48944092, 20.16083336],
      [86.48944092, 20.16028023],
      [86.48972321, 20.15999985],
      [86.48972321, 20.15971947],
      [86.4908371, 20.1586113],
      [86.4908371, 20.15805626],
      [86.49111176, 20.15777016],
      [86.49111176, 20.15749931],
      [86.4908371, 20.15722275],
      [86.4908371, 20.1566658],
      [86.49054718, 20.15639114],
      [86.49054718, 20.15611267],
      [86.48972321, 20.15527916],
      [86.48972321, 20.15500069],
      [86.48944092, 20.15471077],
      [86.48944092, 20.15278053],
      [86.48972321, 20.15249825],
      [86.48972321, 20.15221977],
      [86.48944092, 20.15194511],
      [86.48944092, 20.15167046],
      [86.48916626, 20.15138817],
      [86.48916626, 20.1511097],
      [86.48805237, 20.14999962],
      [86.48777771, 20.14999962],
      [86.48750305, 20.14972115],
      [86.48722076, 20.14972305],
      [86.4861145, 20.14861107],
      [86.48610687, 20.14722061],
      [86.48583221, 20.14694595],
      [86.48583221, 20.14666748],
      [86.48555756, 20.14639091],
      [86.48555756, 20.14556122],
      [86.48443604, 20.1444397],
      [86.48361206, 20.14444351],
      [86.48332977, 20.14472389],
      [86.48249817, 20.14472389],
      [86.48240662, 20.14463043],
      [86.48222351, 20.14444351],
      [86.48194122, 20.14444351],
      [86.48180389, 20.14430618],
      [86.48166656, 20.14416695],
      [86.48139191, 20.14417076],
      [86.48110962, 20.14389038],
      [86.48082733, 20.14389038],
      [86.48059845, 20.14365959],
      [86.47972107, 20.14361191],
      [86.47944641, 20.14333344],
      [86.47888947, 20.14333344],
      [86.47861481, 20.14305496],
      [86.47805786, 20.14306259],
      [86.47798157, 20.14297867],
      [86.47777557, 20.1427784],
      [86.47694397, 20.1427784],
      [86.47666931, 20.14250183],
      [86.47611237, 20.14250183],
      [86.47538757, 20.14188957],
      [86.47460175, 20.14141846],
      [86.47445679, 20.14075089],
      [86.474823, 20.13953018],
      [86.47553253, 20.13838196],
      [86.4761734, 20.13791084],
      [86.47666931, 20.13805771],
      [86.47666931, 20.13833046],
      [86.47583008, 20.13916588],
      [86.47583008, 20.13944054],
      [86.47556305, 20.13972855],
      [86.47556305, 20.14055824],
      [86.47638702, 20.14139175],
      [86.47721863, 20.14139175],
      [86.47750092, 20.14167023],
      [86.47805786, 20.14166641],
      [86.47833252, 20.14194489],
      [86.47944641, 20.14194489],
      [86.47972107, 20.14222145],
      [86.48166656, 20.14222145],
      [86.48194122, 20.14250183],
      [86.48221588, 20.14250183],
      [86.48249817, 20.14222145],
      [86.48332977, 20.14222145],
      [86.48361206, 20.14194489],
      [86.48388672, 20.14194489],
      [86.48416901, 20.14166641],
      [86.48416901, 20.14082909],
      [86.48444366, 20.14055634],
      [86.48443604, 20.14027977],
      [86.48416901, 20.13999748],
      [86.48388672, 20.13999748],
      [86.48361206, 20.13972855],
      [86.48332977, 20.13972855],
      [86.48221588, 20.13861084],
      [86.48166656, 20.13861084],
      [86.48110962, 20.13805962],
      [86.48055267, 20.13805962],
      [86.48000336, 20.13750076],
      [86.48000336, 20.13722038],
      [86.47888947, 20.13611031],
      [86.47888947, 20.13582993],
      [86.47861481, 20.13555527],
      [86.47860718, 20.13528061],
      [86.47833252, 20.13500023],
      [86.47833252, 20.13278008],
      [86.47888947, 20.13222122],
      [86.47916412, 20.13222313],
      [86.48000336, 20.13138962],
      [86.48000336, 20.1297226],
      [86.48027802, 20.12944412],
      [86.48027802, 20.12639046],
      [86.48000336, 20.12610817],
      [86.48000336, 20.12583351],
      [86.47888947, 20.12472153],
      [86.47666931, 20.12472916],
      [86.47638702, 20.12445068],
      [86.47471619, 20.12445068],
      [86.47444153, 20.12472916],
      [86.47416687, 20.12472916],
      [86.47360992, 20.1241703],
      [86.4733429, 20.1241703],
      [86.4730072, 20.12383461],
      [86.47250366, 20.12333298],
      [86.47222137, 20.12333298],
      [86.47194672, 20.12305832],
      [86.4716568, 20.12306023],
      [86.4716568, 20.12277985],
      [86.47110748, 20.12222099],
      [86.47110748, 20.12194443],
      [86.47083282, 20.12194443],
      [86.47083282, 20.12166405],
      [86.47000122, 20.12083244],
      [86.46971893, 20.12083244],
      [86.46944427, 20.12055588],
      [86.46916962, 20.12055969],
      [86.46888733, 20.12028122],
      [86.46861267, 20.12028122],
      [86.46833038, 20.12000084],
      [86.46805573, 20.12000084],
      [86.46749878, 20.11944389],
      [86.46722412, 20.11944389],
      [86.46694183, 20.11916733],
      [86.46639252, 20.11916733],
      [86.46583557, 20.11972237],
      [86.46555328, 20.11972237],
      [86.46527863, 20.11944389],
      [86.46527863, 20.11888885],
      [86.46499634, 20.11860275],
      [86.46499634, 20.11777687],
      [86.46470642, 20.11750031],
      [86.46470642, 20.11722183],
      [86.46333313, 20.11583328],
      [86.46305847, 20.11583328],
      [86.46277618, 20.11555481],
      [86.46277618, 20.11528015],
      [86.46221924, 20.11471939],
      [86.46221924, 20.11444473],
      [86.46250153, 20.11416626],
      [86.46250153, 20.11361122],
      [86.46277618, 20.11333084],
      [86.46277618, 20.1119442],
      [86.46305847, 20.11166573],
      [86.46305847, 20.11111069],
      [86.46277618, 20.11083031],
      [86.46277618, 20.11000061],
      [86.46250153, 20.10972023],
      [86.46250153, 20.10916519],
      [86.46277618, 20.10889053],
      [86.46277618, 20.10861015],
      [86.46333313, 20.10805321],
      [86.46470642, 20.10666084],
      [86.46472168, 20.10638809],
      [86.4658432, 20.10527992],
      [86.4658432, 20.10499954],
      [86.46611023, 20.10472107],
      [86.46611023, 20.1044445],
      [86.46694183, 20.1036129],
      [86.46721649, 20.10362053],
      [86.46749878, 20.10333252],
      [86.46833038, 20.10333252],
      [86.46861267, 20.10305595],
      [86.46916962, 20.10305977],
      [86.46943665, 20.10277939],
      [86.46971893, 20.10277939],
      [86.47000122, 20.10250282],
      [86.47027588, 20.10250282],
      [86.47084045, 20.10193825],
      [86.47083282, 20.10083389],
      [86.47055817, 20.10055542],
      [86.47055817, 20.10028076],
      [86.47000122, 20.09971809],
      [86.47000122, 20.09944534],
      [86.46943665, 20.0988903],
      [86.46971893, 20.09860992],
      [86.46971893, 20.09833336],
      [86.46944427, 20.09805489],
      [86.46944427, 20.09777641],
      [86.46971893, 20.09749985],
      [86.47138977, 20.09749985],
      [86.47194672, 20.09805489],
      [86.47222137, 20.09805107],
      [86.47333527, 20.09916878],
      [86.47250366, 20.09972191],
      [86.47222137, 20.09944534],
      [86.47138977, 20.09944534],
      [86.47138977, 20.09971809],
      [86.4716568, 20.10000038],
      [86.4716568, 20.10028076],
      [86.47194672, 20.10055542],
      [86.47194672, 20.10082817],
      [86.47222137, 20.10110855],
      [86.47222137, 20.10139084],
      [86.47250366, 20.1016674],
      [86.47250366, 20.10194397],
      [86.47222137, 20.10221863],
      [86.47222137, 20.10277748],
      [86.47166443, 20.10333252],
      [86.47138977, 20.10333252],
      [86.47110748, 20.10362053],
      [86.47083282, 20.1036129],
      [86.46983337, 20.10461044],
      [86.46971893, 20.10472107],
      [86.46944427, 20.1044445],
      [86.46861267, 20.1044445],
      [86.46806335, 20.10499954],
      [86.46749878, 20.10499954],
      [86.46721649, 20.10527992],
      [86.46722412, 20.10555649],
      [86.46694183, 20.10583305],
      [86.46694183, 20.10610962],
      [86.46555328, 20.10750008],
      [86.46555328, 20.10777855],
      [86.46499634, 20.10833359],
      [86.46499634, 20.10861015],
      [86.46470642, 20.10889053],
      [86.46470642, 20.11000061],
      [86.46527863, 20.11055565],
      [86.46499634, 20.11083031],
      [86.46499634, 20.11111069],
      [86.46444702, 20.11166954],
      [86.46444702, 20.11250114],
      [86.46416473, 20.11277962],
      [86.4641571, 20.11333084],
      [86.46389008, 20.11361122],
      [86.46389008, 20.11471939],
      [86.46499634, 20.11583328],
      [86.46527863, 20.11582947],
      [86.46611023, 20.11666679],
      [86.46611023, 20.11722183],
      [86.46694183, 20.11806107],
      [86.46778107, 20.11805534],
      [86.46805573, 20.11833382],
      [86.46888733, 20.11833382],
      [86.46916962, 20.11861038],
      [86.46971893, 20.11860275],
      [86.47000122, 20.11889076],
      [86.47027588, 20.11889076],
      [86.47055817, 20.11917114],
      [86.47084045, 20.11917114],
      [86.47444153, 20.12277985],
      [86.47556305, 20.12277985],
      [86.47583008, 20.12249947],
      [86.47666931, 20.12249947],
      [86.47693634, 20.12277985],
      [86.47805786, 20.12277985],
      [86.47833252, 20.12306023],
      [86.47888947, 20.12306023],
      [86.48139191, 20.12555504],
      [86.48139191, 20.12583351],
      [86.48166656, 20.12610817],
      [86.48166656, 20.13138962],
      [86.48139191, 20.13166618],
      [86.48139191, 20.13194466],
      [86.48110962, 20.13222122],
      [86.48110962, 20.13249969],
      [86.48083496, 20.13277817],
      [86.48083496, 20.13333321],
      [86.48472595, 20.13722229],
      [86.48500061, 20.13722229],
      [86.48518372, 20.1374073],
      [86.48555756, 20.13778114],
      [86.48583984, 20.13778114],
      [86.48665619, 20.13861084],
      [86.48666382, 20.13888741],
      [86.48722076, 20.13944054],
      [86.48722076, 20.14027977],
      [86.48750305, 20.14055824],
      [86.48777771, 20.14055824],
      [86.48832703, 20.13999748],
      [86.48832703, 20.13972855],
      [86.48887634, 20.13916969],
      [86.48887634, 20.13833046],
      [86.48916626, 20.13805771],
      [86.48916626, 20.13667107],
      [86.48944092, 20.13639069],
      [86.48944092, 20.13556099],
      [86.48972321, 20.13528061],
      [86.48972321, 20.13471985],
      [86.48944092, 20.13444328],
      [86.48944092, 20.13389015],
      [86.48999786, 20.13332939],
      [86.49028015, 20.13333321],
      [86.49055481, 20.13361168],
      [86.4908371, 20.13361168],
      [86.49111176, 20.13333321],
      [86.49111176, 20.13305473],
      [86.49028015, 20.13222313],
      [86.48999786, 20.13222313],
      [86.4888916, 20.13111115],
      [86.48887634, 20.13083267],
      [86.48805237, 20.12999916],
      [86.48805237, 20.1297226],
      [86.48777771, 20.12944412],
      [86.48777771, 20.12861061],
      [86.48750305, 20.12832832],
      [86.48750305, 20.12750053],
      [86.48722076, 20.12721825],
      [86.48722076, 20.12694359],
      [86.48750305, 20.12666893],
      [86.48750305, 20.12556076],
      [86.48777771, 20.12528038],
      [86.48777771, 20.12333107],
      [86.48805237, 20.12305832],
      [86.48777771, 20.12277985],
      [86.48777771, 20.12222099],
      [86.48750305, 20.12194443],
      [86.48750305, 20.12166405],
      [86.48833466, 20.12083244],
      [86.48860931, 20.12083054],
      [86.48916626, 20.12138939],
      [86.48916626, 20.12194443],
      [86.48944092, 20.12222099],
      [86.48944092, 20.12499809],
      [86.48916626, 20.12528038],
      [86.48916626, 20.12555504],
      [86.48887634, 20.1258297],
      [86.48887634, 20.12639046],
      [86.48860931, 20.12666893],
      [86.48860931, 20.12694359],
      [86.48832703, 20.12721825],
      [86.48832703, 20.12750053],
      [86.48860931, 20.12779045],
      [86.48887634, 20.12779045],
      [86.4888916, 20.12805557],
      [86.48860931, 20.12832832],
      [86.48860931, 20.12861061],
      [86.48887634, 20.12888908],
      [86.4888916, 20.12916756],
      [86.49055481, 20.13083267],
      [86.49111176, 20.13083267],
      [86.4916687, 20.13138962],
      [86.4916687, 20.13166618],
      [86.49194336, 20.13194466],
      [86.49194336, 20.13222313],
      [86.49222565, 20.13249969],
      [86.49222565, 20.13333321],
      [86.49194336, 20.13360023],
      [86.49194336, 20.13389015],
      [86.4916687, 20.13417053],
      [86.4916687, 20.13444328],
      [86.49138641, 20.13471985],
      [86.49138641, 20.13500023],
      [86.49111176, 20.13528061],
      [86.49111176, 20.13611031],
      [86.49138641, 20.13639069],
      [86.49138641, 20.13666534],
      [86.4916687, 20.13694382],
      [86.4916687, 20.13722038],
      [86.49194336, 20.13750076],
      [86.49194336, 20.13805771],
      [86.49223328, 20.13833046],
      [86.49222565, 20.13916588],
      [86.49250031, 20.13944054],
      [86.49222565, 20.13972282],
      [86.49223328, 20.13999748],
      [86.49194336, 20.14027977],
      [86.49194336, 20.14055634],
      [86.49222565, 20.1408329],
      [86.49223328, 20.14111137],
      [86.49194336, 20.14139175],
      [86.49194336, 20.14167023],
      [86.49111176, 20.14250183],
      [86.48999786, 20.14250183],
      [86.48972321, 20.14222145],
      [86.4888916, 20.14305496],
      [86.48916626, 20.14333344],
      [86.48944092, 20.14333344],
      [86.48972321, 20.14361191],
      [86.48999786, 20.14361191],
      [86.49028015, 20.14389038],
      [86.49194336, 20.14389038],
      [86.49223328, 20.14417076],
      [86.49276733, 20.14417076],
      [86.49500275, 20.14639091],
      [86.49500275, 20.14666748],
      [86.4952774, 20.14694023],
      [86.4952774, 20.14750099],
      [86.49582672, 20.14805984],
      [86.49582672, 20.14861107],
      [86.49555206, 20.14888954],
      [86.49555206, 20.14972115],
      [86.4952774, 20.14999962],
      [86.4952774, 20.15027618],
      [86.49500275, 20.15055466],
      [86.49500275, 20.15083313],
      [86.49472046, 20.1511116],
      [86.49472046, 20.15221977],
      [86.4944458, 20.15249825],
      [86.49472046, 20.15278053],
      [86.49472046, 20.15332985],
      [86.49500275, 20.15360832],
      [86.49500275, 20.15389061],
      [86.49610901, 20.15500069],
      [86.49666595, 20.15500069],
      [86.49694824, 20.15527916],
      [86.49749756, 20.15527916],
      [86.49777985, 20.15555954],
      [86.49804688, 20.15555954],
      [86.49861145, 20.15611267],
      [86.49888611, 20.15611267],
      [86.4991684, 20.15639114],
      [86.49944305, 20.15638924],
      [86.5, 20.15694427],
      [86.50027466, 20.15694427],
      [86.50111389, 20.15777779],
      [86.50138855, 20.15777779],
      [86.50222015, 20.15860939],
      [86.50222015, 20.15888977],
      [86.50250244, 20.15916634],
      [86.50250244, 20.15944481],
      [86.5027771, 20.15971947],
      [86.5027771, 20.16028023],
      [86.50333405, 20.16083336],
      [86.50332642, 20.16110992],
      [86.5036087, 20.1613884],
      [86.5036087, 20.16222],
      [86.50388336, 20.16250038],
      [86.50388336, 20.16306114],
      [86.50332642, 20.16361046],
      [86.50389099, 20.1641655],
      [86.50388336, 20.16444016],
      [86.50554657, 20.16610909],
      [86.5055542, 20.16638756],
      [86.50749969, 20.16833305],
      [86.50776672, 20.16832924],
      [86.50860596, 20.16917038],
      [86.50888824, 20.16916656],
      [86.50944519, 20.1697216],
      [86.51000977, 20.16970825],
      [86.51027679, 20.17000008],
      [86.51055908, 20.17000008],
      [86.51082611, 20.17028046],
      [86.5111084, 20.17027855],
      [86.51304626, 20.17222023],
      [86.51304626, 20.17250061],
      [86.51416779, 20.17361069],
      [86.51416779, 20.17389107],
      [86.51444244, 20.17416954],
      [86.51527405, 20.17416573],
      [86.51555634, 20.1744442],
      [86.51611328, 20.1744442],
      [86.51638794, 20.17472267],
      [86.51667023, 20.17472076],
      [86.51689148, 20.17494392],
      [86.51777649, 20.17584038],
      [86.51805878, 20.17584038],
      [86.51832581, 20.17611122],
      [86.51860809, 20.17611122],
      [86.51888275, 20.17638969],
      [86.51916504, 20.17638969],
      [86.51935577, 20.17657661],
      [86.51944733, 20.17667007],
      [86.51972198, 20.17666626],
      [86.51999664, 20.17694473],
      [86.52027893, 20.17694092],
      [86.52083588, 20.17749977],
      [86.52111053, 20.17749977],
      [86.52139282, 20.17778015],
      [86.52222443, 20.17777634],
      [86.52278137, 20.17833328],
      [86.52305603, 20.17833328],
      [86.52361298, 20.17888641],
      [86.52388763, 20.17888069],
      [86.52416992, 20.17917061],
      [86.52416992, 20.17944527],
      [86.52443695, 20.17971992],
      [86.52444458, 20.1799984],
      [86.52527618, 20.18083382],
      [86.52555847, 20.18083382],
      [86.52694702, 20.18221855],
      [86.52694702, 20.18250275],
      [86.52749634, 20.18305588],
      [86.52749634, 20.18333244],
      [86.52777863, 20.18361282],
      [86.52777863, 20.18388939],
      [86.52861023, 20.1847229],
      [86.52861023, 20.18499947],
      [86.52889252, 20.18527985],
      [86.52889252, 20.18555641],
      [86.52916718, 20.18583488],
      [86.53028107, 20.18583488],
      [86.53055573, 20.18611145],
      [86.53082275, 20.18610954],
      [86.53111267, 20.18638992],
      [86.53166962, 20.18638992],
      [86.53221893, 20.18695068],
      [86.53250122, 20.18694305],
      [86.53277588, 20.18722153],
      [86.53305817, 20.18721962],
      [86.53333282, 20.1875],
      [86.53360748, 20.1875],
      [86.5341568, 20.1880703],
      [86.53472137, 20.18805313],
      [86.53500366, 20.18833351],
      [86.53500366, 20.18861198],
      [86.53555298, 20.18861198],
      [86.53610992, 20.18805313],
      [86.53666687, 20.18805313],
      [86.53682709, 20.18821716],
      [86.53694153, 20.18833351],
      [86.53722382, 20.18805313],
      [86.53794098, 20.18864632],
      [86.53881073, 20.18944359],
      [86.53902435, 20.18972206],
      [86.5393219, 20.19041061],
      [86.54081726, 20.1917305],
      [86.54059601, 20.19219971],
      [86.53959656, 20.19226074],
      [86.53916931, 20.19308281],
      [86.53939056, 20.1936264],
      [86.53959656, 20.1937027],
      [86.53965759, 20.1938324],
      [86.53986359, 20.19382858],
      [86.54000092, 20.19388962],
      [86.54055786, 20.19388962],
      [86.54083252, 20.19360924],
      [86.54161072, 20.19333839],
      [86.54167938, 20.19330406],
      [86.54201508, 20.19311523],
      [86.54250336, 20.19333267],
      [86.54277802, 20.19333076],
      [86.54305267, 20.19360924],
      [86.54360962, 20.19360924],
      [86.54389191, 20.19333076],
      [86.54416656, 20.19360924],
      [86.54444122, 20.19360924],
      [86.54472351, 20.19388962],
      [86.54499817, 20.19388962],
      [86.54528046, 20.19360924],
      [86.54694366, 20.19360924],
      [86.54722595, 20.19388962],
      [86.5477829, 20.19387817],
      [86.54805756, 20.19416809],
      [86.54833221, 20.19416618],
      [86.5486145, 20.19444466],
      [86.54916382, 20.19444466],
      [86.54944611, 20.19472313],
      [86.55000305, 20.19472313],
      [86.55110931, 20.19583321],
      [86.5513916, 20.19582939],
      [86.55304718, 20.19750023],
      [86.55305481, 20.1977787],
      [86.55361176, 20.19833183],
      [86.55361176, 20.19861031],
      [86.55472565, 20.19972229],
      [86.55499268, 20.19972038],
      [86.55526733, 20.2000103],
      [86.55555725, 20.2000103],
      [86.55722046, 20.20166969],
      [86.5577774, 20.20166969],
      [86.5588913, 20.20055962],
      [86.5588913, 20.20028114],
      [86.55805969, 20.19944191],
      [86.5577774, 20.19944382],
      [86.55750275, 20.19916725],
      [86.55722046, 20.19917107],
      [86.55444336, 20.19639015],
      [86.5541687, 20.19639015],
      [86.55361176, 20.19582939],
      [86.5533371, 20.19583321],
      [86.55278015, 20.19527817],
      [86.55249786, 20.19528008],
      [86.55110931, 20.19387817],
      [86.55083466, 20.19388962],
      [86.55027771, 20.19333267],
      [86.55000305, 20.19333267],
      [86.5499115, 20.19324112],
      [86.54972076, 20.19305611],
      [86.54915619, 20.19305992],
      [86.54888916, 20.19277954],
      [86.54860687, 20.19277954],
      [86.5477829, 20.19194031],
      [86.54750061, 20.19194412],
      [86.54611206, 20.19055557],
      [86.54582977, 20.19055939],
      [86.54471588, 20.18943977],
      [86.54389191, 20.18944359],
      [86.54222107, 20.18777847],
      [86.54110718, 20.18778038],
      [86.54055786, 20.18721962],
      [86.54027557, 20.18722153],
      [86.53944397, 20.18638802],
      [86.53916931, 20.18638992],
      [86.53861237, 20.18583107],
      [86.53833008, 20.18583488],
      [86.53694153, 20.18444443],
      [86.53666687, 20.18444443],
      [86.53444672, 20.18222237],
      [86.53416443, 20.18222237],
      [86.53388977, 20.18194389],
      [86.53360748, 20.18194389],
      [86.53138733, 20.17972183],
      [86.53111267, 20.17972183],
      [86.52722168, 20.17583275],
      [86.52694702, 20.17584038],
      [86.52639008, 20.17527962],
      [86.52610779, 20.17527962],
      [86.52555847, 20.17472076],
      [86.52555847, 20.1744442],
      [86.52249908, 20.17138863],
      [86.52249908, 20.17111015],
      [86.52055359, 20.16916656],
      [86.52054596, 20.16889191],
      [86.51999664, 20.16832924],
      [86.51999664, 20.16805649],
      [86.51917267, 20.16721916],
      [86.51916504, 20.1669445],
      [86.51833344, 20.16610909],
      [86.51832581, 20.16583061],
      [86.51805878, 20.16555786],
      [86.51805878, 20.16527939],
      [86.51777649, 20.16500092],
      [86.51777649, 20.16472054],
      [86.51667023, 20.16361046],
      [86.51667023, 20.16333008],
      [86.51555634, 20.16222191],
      [86.51555634, 20.16194534],
      [86.51526642, 20.16167068],
      [86.51526642, 20.1613903],
      [86.51499939, 20.16110992],
      [86.51499939, 20.16083336],
      [86.51444244, 20.16028023],
      [86.51444244, 20.15999985],
      [86.51389313, 20.1594429],
      [86.5138855, 20.15888977],
      [86.51361084, 20.1586113],
      [86.51361084, 20.15833282],
      [86.51249695, 20.15722275],
      [86.51249695, 20.1566658],
      [86.51222229, 20.15639114],
      [86.51222229, 20.15611267],
      [86.51139069, 20.15527916],
      [86.51139069, 20.15500069],
      [86.5111084, 20.15471077],
      [86.5111084, 20.15444374],
      [86.5091629, 20.15249825],
      [86.5091629, 20.15195847],
      [86.50888824, 20.15167046],
      [86.50888824, 20.15138817],
      [86.50860596, 20.1511097],
      [86.50861359, 20.15055466],
      [86.5083313, 20.15027618],
      [86.5083313, 20.14972115],
      [86.50805664, 20.14944458],
      [86.50805664, 20.1491642],
      [86.50776672, 20.14890099],
      [86.50776672, 20.14861107],
      [86.50723267, 20.14805984],
      [86.50723267, 20.14777946],
      [86.50666809, 20.14722061],
      [86.50666809, 20.14694595],
      [86.5063858, 20.14666748],
      [86.5063858, 20.14611053],
      [86.50611115, 20.14583015],
      [86.50611115, 20.1455555],
      [86.50583649, 20.14528084],
      [86.50583649, 20.14500046],
      [86.50554657, 20.14472008],
      [86.5055542, 20.14416695],
      [86.5047226, 20.14332962],
      [86.50444794, 20.14333344],
      [86.50416565, 20.14305496],
      [86.50388336, 20.14306259],
      [86.5036087, 20.14277077],
      [86.5036087, 20.14222145],
      [86.50416565, 20.14166641],
      [86.5036087, 20.14111137],
      [86.50250244, 20.14111137],
      [86.5022583, 20.14086914],
      [86.5008316, 20.13944435],
      [86.50055695, 20.13944435],
      [86.50048065, 20.13936806],
      [86.50027466, 20.13916588],
      [86.5, 20.13916969],
      [86.49989319, 20.13905907],
      [86.49972534, 20.13888741],
      [86.49973297, 20.13861084],
      [86.50055695, 20.13777542],
      [86.49973297, 20.13694],
      [86.49972534, 20.13638878],
      [86.49944305, 20.13611031],
      [86.49944305, 20.13582993],
      [86.49777985, 20.13416672],
      [86.49777985, 20.13389015],
      [86.49749756, 20.13360023],
      [86.49749756, 20.13305473],
      [86.4972229, 20.13277817],
      [86.4972229, 20.13249969],
      [86.49694824, 20.13222122],
      [86.49694824, 20.13138962],
      [86.49666595, 20.13111115],
      [86.49666595, 20.1297226],
      [86.4963913, 20.12944412],
      [86.4963913, 20.12916756],
      [86.49666595, 20.12888908],
      [86.49666595, 20.12861061],
      [86.4963913, 20.12832832],
      [86.49610901, 20.12833405],
      [86.49583435, 20.12805557],
      [86.49582672, 20.12779045],
      [86.4952774, 20.12721825],
      [86.4952774, 20.12639046],
      [86.49472046, 20.1258297],
      [86.49472046, 20.12555504],
      [86.49415588, 20.12499809],
      [86.49415588, 20.12472916],
      [86.49388885, 20.12445068],
      [86.49388885, 20.12360954],
      [86.49277496, 20.12249947],
      [86.49250031, 20.12249947],
      [86.49223328, 20.12222099],
      [86.49194336, 20.1222229],
      [86.4908371, 20.12111092],
      [86.4908371, 20.12083054],
      [86.49055481, 20.12055588],
      [86.49028015, 20.12028122],
      [86.49028015, 20.12000084],
      [86.48999786, 20.11972046],
      [86.48972321, 20.11972237],
      [86.48944092, 20.11944389],
      [86.48916626, 20.11944389],
      [86.48833466, 20.11861038],
      [86.48777771, 20.11861038],
      [86.48777771, 20.11777687],
      [86.48722076, 20.11722183],
      [86.48694611, 20.11722374],
      [86.486763, 20.11703873],
      [86.48638916, 20.11666679],
      [86.48638916, 20.11639023],
      [86.48665619, 20.11611176],
      [86.48666382, 20.11583328],
      [86.48638916, 20.11555481],
      [86.48603058, 20.11522293],
      [86.48574829, 20.11474991],
      [86.48532104, 20.11421013],
      [86.48500061, 20.11388969],
      [86.48332977, 20.11555481],
      [86.48332977, 20.11611176],
      [86.48388672, 20.11666679],
      [86.48388672, 20.11722183],
      [86.48416901, 20.11750031],
      [86.48416901, 20.11777687],
      [86.48472595, 20.11833191],
      [86.48416901, 20.11889076],
      [86.48388672, 20.11889076],
      [86.48332977, 20.11833191],
      [86.48332977, 20.11805534],
      [86.48278046, 20.11750031],
      [86.48278046, 20.11722183],
      [86.48249817, 20.11694527],
      [86.48249817, 20.11667061],
      [86.48110962, 20.11528015],
      [86.48110962, 20.11500168],
      [86.48082733, 20.11471939],
      [86.48083496, 20.11416626],
      [86.48110962, 20.11388969],
      [86.48110962, 20.11361122],
      [86.48221588, 20.11250114],
      [86.48306274, 20.11250114],
      [86.48332977, 20.11222076],
      [86.48332977, 20.1119442],
      [86.48361206, 20.11166573],
      [86.48361206, 20.11111069],
      [86.48388672, 20.11083031],
      [86.48388672, 20.11027527],
      [86.48332977, 20.10972023],
      [86.48278046, 20.11028099],
      [86.48249817, 20.11028099],
      [86.48139191, 20.10917091],
      [86.48139191, 20.10889053],
      [86.47970581, 20.1072197],
      [86.47944641, 20.1072197],
      [86.47915649, 20.10750008],
      [86.47888947, 20.10750008],
      [86.47888947, 20.10778046],
      [86.47916412, 20.10805321],
      [86.47916412, 20.10833359],
      [86.47944641, 20.10861015],
      [86.47944641, 20.10889053],
      [86.47972107, 20.10916519],
      [86.47972107, 20.10944366],
      [86.48000336, 20.10972023],
      [86.48000336, 20.11000061],
      [86.48027802, 20.11028099],
      [86.48055267, 20.11028099],
      [86.48082733, 20.11055946],
      [86.48139191, 20.11055946],
      [86.48194122, 20.11111069],
      [86.48139191, 20.11166573],
      [86.48083496, 20.11222267],
      [86.48055267, 20.11222267],
      [86.48000336, 20.11166573],
      [86.47970581, 20.11166954],
      [86.47915649, 20.11111069],
      [86.47860718, 20.11111069],
      [86.47833252, 20.11083031],
      [86.47777557, 20.11083412],
      [86.47721863, 20.11027527],
      [86.47721863, 20.11000061],
      [86.47693634, 20.10972023],
      [86.47694397, 20.10944366],
      [86.47666931, 20.10916519],
      [86.47666931, 20.10750008],
      [86.47638702, 20.1072197],
      [86.47638702, 20.10638809],
      [86.47611237, 20.10610962],
      [86.47611237, 20.10583305],
      [86.47583008, 20.10555649],
      [86.47583008, 20.10527992],
      [86.47528076, 20.10472107],
      [86.47528076, 20.1044445],
      [86.47499847, 20.10416603],
      [86.47499847, 20.10388947],
      [86.47556305, 20.10333252],
      [86.47583008, 20.10333252],
      [86.47611237, 20.10305595],
      [86.47638702, 20.10305977],
      [86.47666931, 20.10277939],
      [86.47666931, 20.10221863],
      [86.47638702, 20.10194397],
      [86.47638702, 20.1016674],
      [86.47611237, 20.10139084],
      [86.47611237, 20.10110855],
      [86.47583008, 20.10082817],
      [86.47583008, 20.10055542],
      [86.47471619, 20.09944916],
      [86.4716568, 20.09638977],
      [86.47138977, 20.09638977],
      [86.47138977, 20.09610939],
      [86.47110748, 20.09583282],
      [86.47110748, 20.09555435],
      [86.47055817, 20.09500122],
      [86.47027588, 20.09500122],
      [86.47027588, 20.09472084],
      [86.46250153, 20.08694458],
      [86.46250153, 20.08666611],
      [86.46138763, 20.08555984],
      [86.46083069, 20.08555984],
      [86.45999908, 20.08472061],
      [86.45972443, 20.08472252],
      [86.45916748, 20.08416557],
      [86.45861053, 20.08416939],
      [86.45777893, 20.08333015],
      [86.45777893, 20.0830555],
      [86.45749664, 20.08278084],
      [86.45749664, 20.08249092],
      [86.45694733, 20.08194351],
      [86.45694733, 20.08111191],
      [86.45500183, 20.07916641],
      [86.45471954, 20.07917023],
      [86.45443726, 20.07888985],
      [86.45417023, 20.07888985],
      [86.45388794, 20.07860947],
      [86.45388794, 20.07805634],
      [86.45333099, 20.07749939],
      [86.4522171, 20.07749939],
      [86.45166779, 20.07694244],
      [86.45121765, 20.07666969],
      [86.45072937, 20.07636833],
      [86.4502182, 20.07605553],
      [86.44970703, 20.07563972],
      [86.4493866, 20.07496071],
      [86.44908142, 20.07432938],
      [86.44863892, 20.07389069],
      [86.44819641, 20.07354927],
      [86.44783783, 20.0732708],
      [86.44754028, 20.07299232],
      [86.44728088, 20.07270622],
      [86.44700623, 20.07242966],
      [86.44673157, 20.07215309],
      [86.44642639, 20.0718174],
      [86.4460907, 20.07143974],
      [86.44564819, 20.07113075],
      [86.44439697, 20.0706234],
      [86.44368744, 20.0699482],
      [86.44288635, 20.06867027],
      [86.44248962, 20.06837845],
      [86.44207001, 20.06805992],
      [86.44164276, 20.06772995],
      [86.44124603, 20.06735992],
      [86.44082642, 20.06694984],
      [86.4404068, 20.06653023],
      [86.43990326, 20.06610107],
      [86.43937683, 20.06565857],
      [86.43898773, 20.06508064],
      [86.43869781, 20.06443024],
      [86.43849182, 20.0638504],
      [86.43818665, 20.06339073],
      [86.43785858, 20.06301117],
      [86.43754578, 20.06255913],
      [86.43730164, 20.06196213],
      [86.43708038, 20.06139946],
      [86.43678284, 20.0609684],
      [86.43641663, 20.06067085],
      [86.43601227, 20.06049538],
      [86.43559265, 20.06032944],
      [86.43512726, 20.06018066],
      [86.43466949, 20.05999756],
      [86.43412018, 20.05985069],
      [86.43344116, 20.05983353],
      [86.43273163, 20.05989456],
      [86.43224335, 20.05978012],
      [86.43179321, 20.05955124],
      [86.4312973, 20.05919266],
      [86.43078613, 20.05879021],
      [86.43032837, 20.05827332],
      [86.43000031, 20.05768776],
      [86.42973328, 20.05711174],
      [86.42951202, 20.05656433],
      [86.42945099, 20.05602455],
      [86.42944336, 20.0555191],
      [86.42942047, 20.05507088],
      [86.42935181, 20.05467987],
      [86.42919159, 20.05438232],
      [86.4283371, 20.05361176],
      [86.42805481, 20.05361176],
      [86.42694092, 20.05471992],
      [86.42694092, 20.05500031],
      [86.42583466, 20.05611038],
      [86.42555237, 20.05611038],
      [86.42472076, 20.05694389],
      [86.42444611, 20.05694389],
      [86.42416382, 20.05722237],
      [86.42388916, 20.05722046],
      [86.42360687, 20.05750084],
      [86.42193604, 20.05750084],
      [86.42166901, 20.05778122],
      [86.41971588, 20.05778122],
      [86.41944122, 20.05750084],
      [86.41889191, 20.05750084],
      [86.41860962, 20.05722046],
      [86.41805267, 20.05722237],
      [86.41777802, 20.05694389],
      [86.41722107, 20.05694389],
      [86.41708374, 20.05680466],
      [86.41694641, 20.05666542],
      [86.41638947, 20.05667114],
      [86.41610718, 20.05639076],
      [86.41500092, 20.05639076],
      [86.41471863, 20.05611038],
      [86.41388702, 20.05611038],
      [86.41362, 20.05583],
      [86.41176605, 20.05554008],
      [86.41176605, 20.05513954],
      [86.41311646, 20.05500031],
      [86.41610718, 20.05500031],
      [86.41638947, 20.05528069],
      [86.41722107, 20.05527687],
      [86.41733551, 20.0553894],
      [86.41750336, 20.05555534],
      [86.41916656, 20.05555534],
      [86.41944122, 20.05583382],
      [86.42028046, 20.05583382],
      [86.42055511, 20.05611038],
      [86.42166901, 20.05611038],
      [86.42193604, 20.05639076],
      [86.42250061, 20.05639076],
      [86.42279053, 20.05611038],
      [86.42388916, 20.05611038],
      [86.42556, 20.05445099],
      [86.42556, 20.05332947],
      [86.42583466, 20.05305481],
      [86.42610931, 20.05305481],
      [86.4263916, 20.05277824],
      [86.4263916, 20.05251122],
      [86.42722321, 20.05167007],
      [86.42694092, 20.05138969],
      [86.42694092, 20.05027962],
      [86.42722321, 20.05000114],
      [86.42722321, 20.04972267],
      [86.42694092, 20.0494442],
      [86.42694092, 20.04889107],
      [86.4263916, 20.0483284],
      [86.4263916, 20.04750061],
      [86.42610931, 20.04721832],
      [86.42556, 20.04721832],
      [86.42527771, 20.04750061],
      [86.42527771, 20.04777908],
      [86.42500305, 20.04805565],
      [86.42500305, 20.0483284],
      [86.42388916, 20.04945183],
      [86.42360687, 20.04945183],
      [86.42360687, 20.04916954],
      [86.42166901, 20.04721832],
      [86.42138672, 20.04722214],
      [86.42111206, 20.04694366],
      [86.42056274, 20.04693985],
      [86.41971588, 20.04777908],
      [86.41972351, 20.04805565],
      [86.41944122, 20.0483284],
      [86.41944122, 20.04861069],
      [86.41916656, 20.04889107],
      [86.41898346, 20.04870415],
      [86.41860962, 20.04833412],
      [86.41860962, 20.04611206],
      [86.41833496, 20.04583359],
      [86.41833496, 20.04555511],
      [86.41805267, 20.04528046],
      [86.41718292, 20.04488182],
      [86.41660309, 20.04447746],
      [86.41574097, 20.04346085],
      [86.4153595, 20.04224396],
      [86.41511536, 20.04189873],
      [86.41488647, 20.04152107],
      [86.41471863, 20.04111099],
      [86.41443634, 20.04083061],
      [86.41444397, 20.04055405],
      [86.41471863, 20.04027939],
      [86.41471863, 20.03833008],
      [86.41444397, 20.03805542],
      [86.41443634, 20.03722191],
      [86.41471863, 20.03694534],
      [86.41471863, 20.03667068],
      [86.41500092, 20.0363903],
      [86.4152832, 20.0363903],
      [86.41610718, 20.03554916],
      [86.41611481, 20.03500175],
      [86.41638947, 20.03472137],
      [86.41638947, 20.03416634],
      [86.41610718, 20.03388977],
      [86.41610718, 20.03305817],
      [86.41583252, 20.03277969],
      [86.41583252, 20.03222084],
      [86.41555786, 20.03194427],
      [86.41583252, 20.0316658],
      [86.41583252, 20.03111076],
      [86.41500092, 20.03028107],
      [86.41361237, 20.0316658],
      [86.41361237, 20.03194427],
      [86.41333008, 20.03222084],
      [86.41333008, 20.03388977],
      [86.41361237, 20.03416634],
      [86.41361237, 20.03444481],
      [86.41305542, 20.03500175],
      [86.41305542, 20.03555489],
      [86.41278076, 20.03583336],
      [86.41278076, 20.03611183],
      [86.41249847, 20.0363903],
      [86.41249847, 20.03667068],
      [86.41221619, 20.03695107],
      [86.41166687, 20.0363903],
      [86.41166687, 20.03554916],
      [86.41156769, 20.03492165],
      [86.41152954, 20.03435707],
      [86.41160583, 20.03380966],
      [86.41158295, 20.03329277],
      [86.41143799, 20.03277016],
      [86.41133881, 20.03221893],
      [86.41114807, 20.0316925],
      [86.41101837, 20.03117943],
      [86.41086578, 20.03070068],
      [86.41078186, 20.03024864],
      [86.41065216, 20.02981949],
      [86.41065979, 20.02938461],
      [86.41077423, 20.02890968],
      [86.41078186, 20.02837944],
      [86.41069031, 20.02779007],
      [86.41059113, 20.02715492],
      [86.41054535, 20.0264473],
      [86.41046143, 20.02572823],
      [86.4101944, 20.02513695],
      [86.40983582, 20.02472115],
      [86.40950012, 20.02436066],
      [86.40923309, 20.02399063],
      [86.4090271, 20.02358627],
      [86.40888214, 20.0231781],
      [86.40872955, 20.02279282],
      [86.40857697, 20.02240944],
      [86.40840912, 20.02202988],
      [86.40827179, 20.02158737],
      [86.4080658, 20.02116013],
      [86.40782928, 20.02073097],
      [86.40750122, 20.02028084],
      [86.40750122, 20.02000046],
      [86.40721893, 20.01971817],
      [86.40721893, 20.01861191],
      [86.40777588, 20.01805496],
      [86.40777588, 20.01749992],
      [86.40805817, 20.01721954],
      [86.40805817, 20.01694679],
      [86.40833282, 20.01666641],
      [86.40860748, 20.01667023],
      [86.4091568, 20.01610947],
      [86.40972137, 20.01611137],
      [86.41027832, 20.01555443],
      [86.41027832, 20.01527977],
      [86.41085052, 20.01472092],
      [86.41083527, 20.01444435],
      [86.41139221, 20.01388931],
      [86.41110992, 20.01361084],
      [86.41110992, 20.01305962],
      [86.41139221, 20.01278114],
      [86.41194153, 20.01278114],
      [86.41221619, 20.01305962],
      [86.41249847, 20.01278114],
      [86.41249847, 20.01250076],
      [86.41278076, 20.01222038],
      [86.41278076, 20.01083374],
      [86.41249847, 20.01055527],
      [86.41249847, 20.01000023],
      [86.41221619, 20.00972176],
      [86.41222382, 20.00916672],
      [86.41194153, 20.00861168],
      [86.41194153, 20.00805473],
      [86.41166687, 20.00777817],
      [86.41166687, 20.0075016],
      [86.41194153, 20.00720978],
      [86.41194153, 20.00694466],
      [86.41166687, 20.00666618],
      [86.41194153, 20.00638962],
      [86.41194153, 20.00555992],
      [86.41166687, 20.00527954],
      [86.41166687, 20.00361061],
      [86.41139221, 20.00333023],
      [86.41139221, 20.00222206],
      [86.41110992, 20.00194359],
      [86.41110992, 20],
      [86.40999603, 19.99945068],
      [86.40833282, 19.99777985],
      [86.40833282, 19.99749947],
      [86.40805817, 19.9972229],
      [86.40805817, 19.99583244],
      [86.40750122, 19.9952774],
      [86.40721893, 19.9952774],
      [86.40638733, 19.99444389],
      [86.40611267, 19.99444389],
      [86.40583038, 19.99416733],
      [86.40556335, 19.99416924],
      [86.40537262, 19.99398232],
      [86.40528107, 19.99389076],
      [86.40499878, 19.99389076],
      [86.40442657, 19.99332809],
      [86.40416718, 19.99333382],
      [86.40361023, 19.99277878],
      [86.4033432, 19.99278069],
      [86.40110779, 19.99055099],
      [86.40083313, 19.99055481],
      [86.39916992, 19.98888969],
      [86.39916992, 19.98861122],
      [86.39749908, 19.98694038],
      [86.39749908, 19.98666573],
      [86.3972168, 19.98666954],
      [86.39694214, 19.98639107],
      [86.39666748, 19.98639107],
      [86.39601898, 19.98574066],
      [86.39472198, 19.98444176],
      [86.39444733, 19.9841671],
      [86.39417267, 19.98417282],
      [86.39398193, 19.98398209],
      [86.39389038, 19.98389053],
      [86.39360809, 19.98389053],
      [86.39305878, 19.98333168],
      [86.39305878, 19.98277855],
      [86.39221954, 19.98195267],
      [86.39167023, 19.98195267],
      [86.39167023, 19.98167038],
      [86.39055634, 19.9805603],
      [86.39055634, 19.98028946],
      [86.38999939, 19.97971916],
      [86.38999939, 19.97888947],
      [86.3897171, 19.97860909],
      [86.38970184, 19.97832108],
      [86.38916779, 19.97777939],
      [86.38916779, 19.97750092],
      [86.38889313, 19.97722816],
      [86.38861084, 19.97722816],
      [86.38833618, 19.97694016],
      [86.38805389, 19.97694397],
      [86.38749695, 19.97638893],
      [86.38722229, 19.97639084],
      [86.38694763, 19.97611046],
      [86.38640594, 19.97611046],
      [86.38360596, 19.97611046],
      [86.3833313, 19.97639084],
      [86.38223267, 19.97639084],
      [86.38194275, 19.97667122],
      [86.3813858, 19.97667122],
      [86.38083649, 19.97722816],
      [86.38054657, 19.97722816],
      [86.38027954, 19.97750092],
      [86.3797226, 19.97750092],
      [86.37917328, 19.97805977],
      [86.37889099, 19.97805977],
      [86.37832642, 19.97860909],
      [86.37805176, 19.97860909],
      [86.3777771, 19.97888947],
      [86.3777771, 19.9794445],
      [86.37805176, 19.97971916],
      [86.37805176, 19.97999763],
      [86.37833405, 19.98027992],
      [86.37833405, 19.98083305],
      [86.3786087, 19.98111153],
      [86.3786087, 19.98139191],
      [86.37889099, 19.98166656],
      [86.37889099, 19.9822216],
      [86.37917328, 19.98250008],
      [86.37916565, 19.98277855],
      [86.3797226, 19.98333359],
      [86.3797226, 19.98361206],
      [86.38083649, 19.98472023],
      [86.38083649, 19.98611069],
      [86.38111115, 19.98639107],
      [86.38083649, 19.98666954],
      [86.38083649, 19.98750114],
      [86.38054657, 19.98777962],
      [86.3805542, 19.98916435],
      [86.38083649, 19.98944092],
      [86.38083649, 19.98999977],
      [86.3813858, 19.99055481],
      [86.38166809, 19.99055481],
      [86.38283539, 19.99083328],
      [86.3833313, 19.99083328],
      [86.38360596, 19.99110985],
      [86.38384247, 19.99115753],
      [86.38500214, 19.99139023],
      [86.38582611, 19.99139023],
      [86.3861084, 19.99167061],
      [86.38639069, 19.99167061],
      [86.3866806, 19.99194908],
      [86.38694763, 19.99194527],
      [86.38722229, 19.99221039],
      [86.38722229, 19.99250031],
      [86.38694763, 19.99278069],
      [86.38581085, 19.99250031],
      [86.38424683, 19.99229813],
      [86.38276672, 19.99250031],
      [86.38249969, 19.99305534],
      [86.38243103, 19.99526978],
      [86.38223267, 19.99555969],
      [86.38166809, 19.99555969],
      [86.3813858, 19.99526978],
      [86.3813858, 19.99500084],
      [86.38111115, 19.99471855],
      [86.38111115, 19.99333382],
      [86.3805542, 19.99277878],
      [86.38054657, 19.99250031],
      [86.37982178, 19.99176979],
      [86.37895203, 19.99115944],
      [86.37853241, 19.99048424],
      [86.37838745, 19.98892784],
      [86.37853241, 19.98805046],
      [86.37889099, 19.98690033],
      [86.37889099, 19.98500061],
      [86.37832642, 19.98444176],
      [86.37805176, 19.98444366],
      [86.3777771, 19.9841671],
      [86.3777771, 19.98389053],
      [86.37748718, 19.98361015],
      [86.37750244, 19.98333359],
      [86.37666321, 19.98250008],
      [86.37666321, 19.9822216],
      [86.37611389, 19.98166656],
      [86.37610626, 19.98139191],
      [86.37526703, 19.9805603],
      [86.375, 19.9805603],
      [86.3747406, 19.98028946],
      [86.3741684, 19.98028946],
      [86.37384796, 19.97999763],
      [86.37249756, 19.97999763],
      [86.3722229, 19.97971916],
      [86.37166595, 19.97971916],
      [86.3713913, 19.97999763],
      [86.3702774, 19.97999763],
      [86.37000275, 19.98028946],
      [86.36915588, 19.98028946],
      [86.36915588, 19.97999763],
      [86.36888885, 19.97971916],
      [86.3686142, 19.97972298],
      [86.36833191, 19.9794445],
      [86.36777496, 19.9794445],
      [86.36750031, 19.97972298],
      [86.36723328, 19.97971916],
      [86.36694336, 19.97999763],
      [86.36360931, 19.97999763],
      [86.36332703, 19.98028946],
      [86.36222076, 19.98028946],
      [86.36194611, 19.9805603],
      [86.36165619, 19.98028946],
      [86.36055756, 19.98028946],
      [86.3602829, 19.97999763],
      [86.35972595, 19.97999763],
      [86.35945129, 19.98028946],
      [86.35888672, 19.98028946],
      [86.35861206, 19.9805603],
      [86.35832977, 19.98055649],
      [86.35805511, 19.98083305],
      [86.35527802, 19.98083305],
      [86.35500336, 19.98055649],
      [86.35444641, 19.98111153],
      [86.35360718, 19.98111153],
      [86.35331726, 19.98139191],
      [86.35256958, 19.98139191],
      [86.35221863, 19.98139191],
      [86.35193634, 19.98167038],
      [86.35166931, 19.98167038],
      [86.35138702, 19.98195267],
      [86.35111237, 19.98194504],
      [86.35057068, 19.98250008],
      [86.35055542, 19.98277855],
      [86.34999847, 19.98333359],
      [86.34999847, 19.98360062],
      [86.34999847, 19.98444366],
      [86.34971619, 19.98472023],
      [86.34971619, 19.98500061],
      [86.34999847, 19.9852829],
      [86.34999847, 19.98611069],
      [86.35028076, 19.98639107],
      [86.35028076, 19.98666573],
      [86.35012054, 19.98682785],
      [86.34972382, 19.98722267],
      [86.34944153, 19.9869442],
      [86.34916687, 19.98666573],
      [86.34916687, 19.98639107],
      [86.34889221, 19.98611069],
      [86.34889221, 19.98583031],
      [86.34833527, 19.98527718],
      [86.3483429, 19.98472023],
      [86.34860992, 19.98444366],
      [86.34860992, 19.98388863],
      [86.34889221, 19.98361015],
      [86.34889221, 19.98277855],
      [86.34944153, 19.9822216],
      [86.34944153, 19.98166656],
      [86.34916687, 19.98139191],
      [86.34860992, 19.98195267],
      [86.34805298, 19.98194504],
      [86.34777832, 19.9822216],
      [86.34751129, 19.9822216],
      [86.34722137, 19.98250008],
      [86.34722137, 19.98278046],
      [86.34583282, 19.98417282],
      [86.34555817, 19.9841671],
      [86.34500122, 19.98472214],
      [86.34471893, 19.98472214],
      [86.34455872, 19.98488235],
      [86.34333038, 19.98611069],
      [86.34306335, 19.98611069],
      [86.34278107, 19.98639107],
      [86.34221649, 19.98639107],
      [86.34127045, 19.98746872],
      [86.340271, 19.98888969],
      [86.33999634, 19.98916435],
      [86.33999634, 19.98944473],
      [86.33972168, 19.98971939],
      [86.33972168, 19.98999977],
      [86.33944702, 19.99027634],
      [86.33944702, 19.99110985],
      [86.33946991, 19.99309921],
      [86.33961487, 19.99472427],
      [86.33996582, 19.99567223],
      [86.34017944, 19.99682236],
      [86.34011078, 19.99763107],
      [86.33994293, 19.99830055],
      [86.3391571, 20],
      [86.33889008, 20.00036049],
      [86.33883667, 20.00071716],
      [86.33866119, 20.00106049],
      [86.33863068, 20.00144958],
      [86.33860779, 20.0018425],
      [86.33860779, 20.00218582],
      [86.33857727, 20.00251961],
      [86.33843994, 20.00283051],
      [86.33831787, 20.00317955],
      [86.33812714, 20.00354004],
      [86.33770752, 20.00486755],
      [86.33728027, 20.00608063],
      [86.33692169, 20.00777054],
      [86.33656311, 20.00898552],
      [86.33621216, 20.01066971],
      [86.33528137, 20.01250076],
      [86.33499908, 20.01278114],
      [86.33499908, 20.01305962],
      [86.33444214, 20.01361084],
      [86.33444214, 20.01444435],
      [86.33389282, 20.01499939],
      [86.33389282, 20.01527977],
      [86.33361053, 20.01555443],
      [86.33361053, 20.01583099],
      [86.33277893, 20.01667023],
      [86.33241272, 20.01703644],
      [86.33207703, 20.01736069],
      [86.3316803, 20.01768112],
      [86.33129883, 20.01802444],
      [86.3309021, 20.01832199],
      [86.33048248, 20.01857185],
      [86.3300705, 20.01881981],
      [86.3298111, 20.0191803],
      [86.32956696, 20.01958084],
      [86.3292923, 20.01998711],
      [86.32900238, 20.02042961],
      [86.32888031, 20.02102089],
      [86.32874298, 20.02160263],
      [86.32849121, 20.02207947],
      [86.32832336, 20.02256966],
      [86.32832336, 20.02312279],
      [86.32833862, 20.02370071],
      [86.32833099, 20.02416611],
      [86.32861328, 20.02444267],
      [86.32861328, 20.02499962],
      [86.32888794, 20.02527809],
      [86.32888794, 20.02583313],
      [86.32861328, 20.0261097],
      [86.32861328, 20.02695084],
      [86.32853699, 20.02816963],
      [86.32818604, 20.02952003],
      [86.32740021, 20.03067017],
      [86.32640076, 20.03180885],
      [86.32575226, 20.03275108],
      [86.3248291, 20.03369904],
      [86.32418823, 20.03437042],
      [86.32290649, 20.03469086],
      [86.32242584, 20.03500175],
      [86.32195282, 20.03524971],
      [86.3214798, 20.03540993],
      [86.32111359, 20.03569603],
      [86.32076263, 20.03604126],
      [86.32034302, 20.03637123],
      [86.31986237, 20.03666496],
      [86.3194046, 20.03692818],
      [86.31897736, 20.03715134],
      [86.31863403, 20.03741264],
      [86.31838226, 20.03772354],
      [86.3181076, 20.03800964],
      [86.31787109, 20.03832245],
      [86.31759644, 20.03860283],
      [86.31726074, 20.03885841],
      [86.31695557, 20.03917122],
      [86.31667328, 20.03955078],
      [86.31638336, 20.03993034],
      [86.31600189, 20.04017067],
      [86.31556702, 20.04031944],
      [86.31517029, 20.04050064],
      [86.31475067, 20.04061127],
      [86.31437683, 20.04079056],
      [86.31391907, 20.04092979],
      [86.31343842, 20.04109192],
      [86.31292725, 20.04124069],
      [86.31238556, 20.04137611],
      [86.31183624, 20.04150963],
      [86.31136322, 20.04171181],
      [86.31095886, 20.04195786],
      [86.31059265, 20.04222107],
      [86.31021881, 20.04248047],
      [86.30983734, 20.04265022],
      [86.3094101, 20.04278946],
      [86.30898285, 20.04294968],
      [86.30857086, 20.04311562],
      [86.30819702, 20.0433197],
      [86.30784607, 20.04354095],
      [86.30749512, 20.04372787],
      [86.30715942, 20.04390335],
      [86.3068161, 20.04408073],
      [86.30641174, 20.04421043],
      [86.30600739, 20.0443573],
      [86.30552673, 20.04447365],
      [86.30503845, 20.04467964],
      [86.30451965, 20.04491043],
      [86.30400085, 20.04510117],
      [86.30356598, 20.0453968],
      [86.30310059, 20.04561806],
      [86.30265045, 20.04585075],
      [86.30223846, 20.04611206],
      [86.30183411, 20.04639053],
      [86.30145264, 20.04667091],
      [86.30108643, 20.04700089],
      [86.30081177, 20.04738998],
      [86.30053711, 20.04777908],
      [86.30027771, 20.04817963],
      [86.30002594, 20.04862022],
      [86.29985809, 20.0491066],
      [86.29960632, 20.04951477],
      [86.29930878, 20.04975319],
      [86.29905701, 20.04999161],
      [86.29884338, 20.05022049],
      [86.2986145, 20.05055618],
      [86.29694366, 20.0522213],
      [86.29666901, 20.05221939],
      [86.29611206, 20.05278015],
      [86.29564667, 20.05308723],
      [86.29528809, 20.05328178],
      [86.29483795, 20.05336761],
      [86.29445648, 20.05360985],
      [86.29409027, 20.05391502],
      [86.29373932, 20.05433846],
      [86.293396, 20.05489922],
      [86.29305267, 20.05539894],
      [86.29270935, 20.05589485],
      [86.29244232, 20.05639076],
      [86.29213715, 20.05677795],
      [86.29189301, 20.05714035],
      [86.29164886, 20.05743027],
      [86.29147339, 20.05767822],
      [86.29139709, 20.05797958],
      [86.29138184, 20.05830002],
      [86.29133606, 20.05861092],
      [86.29122925, 20.05891991],
      [86.29113007, 20.05925941],
      [86.2910614, 20.05965042],
      [86.29099274, 20.06007957],
      [86.29087067, 20.06043053],
      [86.2906723, 20.06072044],
      [86.29046631, 20.06098175],
      [86.29007721, 20.06113052],
      [86.28987122, 20.06081009],
      [86.28978729, 20.06034851],
      [86.28983307, 20.05991554],
      [86.28991699, 20.05952072],
      [86.28990936, 20.05920219],
      [86.28981781, 20.05895233],
      [86.289711, 20.05872154],
      [86.28951263, 20.05859756],
      [86.28922272, 20.05855942],
      [86.2888031, 20.05854416],
      [86.28826141, 20.05852127],
      [86.28749847, 20.05861092],
      [86.28749847, 20.05750084],
      [86.28778076, 20.05722046],
      [86.28944397, 20.05722237],
      [86.29194641, 20.05472183],
      [86.29194641, 20.05416679],
      [86.29222107, 20.05389023],
      [86.29165649, 20.05332947],
      [86.29138947, 20.05333328],
      [86.29083252, 20.05277824],
      [86.29110718, 20.05251122],
      [86.29055786, 20.05194283],
      [86.29027557, 20.05194473],
      [86.29000092, 20.05166626],
      [86.29000092, 20.05138969],
      [86.28971863, 20.05111122],
      [86.28916931, 20.05111122],
      [86.28861237, 20.0505619],
      [86.28833008, 20.0505619],
      [86.28806305, 20.05027962],
      [86.28778076, 20.05027962],
      [86.28768921, 20.05018616],
      [86.28722382, 20.04972267],
      [86.28610992, 20.04972267],
      [86.2858429, 20.05000114],
      [86.28527832, 20.05000114],
      [86.28499603, 20.05027962],
      [86.28417206, 20.05027962],
      [86.28333282, 20.05111122],
      [86.28333282, 20.05166626],
      [86.28388977, 20.05221939],
      [86.28388977, 20.05249977],
      [86.28305817, 20.05333328],
      [86.28221893, 20.05332947],
      [86.28166962, 20.05389023],
      [86.28138733, 20.05389023],
      [86.28109741, 20.05417061],
      [86.28083038, 20.05417061],
      [86.28055573, 20.05388832],
      [86.27889252, 20.05555534],
      [86.27861023, 20.05554962],
      [86.2766571, 20.05750084],
      [86.27666473, 20.05777931],
      [86.27630615, 20.05930328],
      [86.27581024, 20.06011009],
      [86.27445221, 20.06160164],
      [86.27381134, 20.0617981],
      [86.27194214, 20.06195259],
      [86.27083588, 20.06194496],
      [86.27055359, 20.06222153],
      [86.26999664, 20.06222153],
      [86.26972198, 20.06250191],
      [86.26944733, 20.06250191],
      [86.26915741, 20.06278038],
      [86.26805878, 20.06278038],
      [86.26777649, 20.06250191],
      [86.26721954, 20.06250191],
      [86.26693726, 20.06278038],
      [86.26609802, 20.06278038],
      [86.26583099, 20.06250191],
      [86.26444244, 20.06250191],
      [86.26416779, 20.06222153],
      [86.26361084, 20.06222153],
      [86.26333618, 20.06194496],
      [86.26249695, 20.06195259],
      [86.26222229, 20.0616703],
      [86.26194763, 20.0616703],
      [86.26167297, 20.06139183],
      [86.26139069, 20.06139183],
      [86.2611084, 20.06111145],
      [86.26077271, 20.06113243],
      [86.26042938, 20.06096268],
      [86.26006317, 20.06063652],
      [86.25959778, 20.06031036],
      [86.25905609, 20.05999756],
      [86.25850677, 20.05971909],
      [86.25797272, 20.05944061],
      [86.25746918, 20.05916977],
      [86.25699615, 20.05892944],
      [86.25649261, 20.05879974],
      [86.25602722, 20.05859947],
      [86.25562286, 20.05837631],
      [86.25528717, 20.05811119],
      [86.25499725, 20.05784035],
      [86.25469208, 20.05760956],
      [86.25435638, 20.05741119],
      [86.2539978, 20.05719948],
      [86.25358582, 20.05698586],
      [86.25309753, 20.05682945],
      [86.25259399, 20.05665779],
      [86.25211334, 20.05647087],
      [86.2516098, 20.05633926],
      [86.25121307, 20.05611992],
      [86.2508316, 20.05592537],
      [86.25047302, 20.05574036],
      [86.25016785, 20.05553627],
      [86.24989319, 20.05528069],
      [86.24961853, 20.05500031],
      [86.24932098, 20.0547142],
      [86.24900055, 20.05439949],
      [86.248703, 20.0539875],
      [86.24829865, 20.05359268],
      [86.24785614, 20.05318832],
      [86.24752045, 20.05264091],
      [86.24726868, 20.05207253],
      [86.24716949, 20.05151176],
      [86.24698639, 20.05101967],
      [86.24687958, 20.05046844],
      [86.24677277, 20.04986191],
      [86.24689484, 20.0492897],
      [86.24685669, 20.04873085],
      [86.24669647, 20.04823112],
      [86.24666595, 20.04772949],
      [86.24668884, 20.04725075],
      [86.24675751, 20.04679871],
      [86.24668884, 20.04636002],
      [86.24666595, 20.04590034],
      [86.24666595, 20.0454464],
      [86.24664307, 20.04499817],
      [86.24650574, 20.04456902],
      [86.24636841, 20.04409981],
      [86.24632263, 20.04360008],
      [86.24623108, 20.0431881],
      [86.24610901, 20.04284096],
      [86.24598694, 20.0425396],
      [86.24585724, 20.04228973],
      [86.24582672, 20.04199982],
      [86.24580383, 20.04173088],
      [86.24568176, 20.04150963],
      [86.24555206, 20.04138947],
      [86.24555206, 20.04083061],
      [86.24500275, 20.04027748],
      [86.24472046, 20.04027939],
      [86.2444458, 20.04000092],
      [86.24388885, 20.04000092],
      [86.24333191, 20.03944016],
      [86.24305725, 20.03944206],
      [86.24277496, 20.0391674],
      [86.24250031, 20.03917122],
      [86.2419281, 20.03861046],
      [86.2408371, 20.03861046],
      [86.24054718, 20.03833008],
      [86.24028015, 20.03833389],
      [86.23916626, 20.03722382],
      [86.23916626, 20.03694534],
      [86.23972321, 20.0363903],
      [86.24028015, 20.0363903],
      [86.24111176, 20.03554916],
      [86.24138641, 20.03555489],
      [86.24194336, 20.03500175],
      [86.24250031, 20.03500175],
      [86.24276733, 20.03472137],
      [86.24333191, 20.03472137],
      [86.24333191, 20.03444481],
      [86.2436142, 20.03416634],
      [86.24360657, 20.03360748],
      [86.24305725, 20.03305626],
      [86.24305725, 20.03277969],
      [86.24250031, 20.03222084],
      [86.24250031, 20.03194427],
      [86.24222565, 20.0316658],
      [86.24221802, 20.0313797],
      [86.2419281, 20.03108025],
      [86.24194336, 20.0308342],
      [86.24055481, 20.02944374],
      [86.23999786, 20.03000069],
      [86.23944092, 20.03000069],
      [86.23918152, 20.02972031],
      [86.2388916, 20.02972221],
      [86.23860931, 20.02944374],
      [86.23833466, 20.02944374],
      [86.23805237, 20.02916527],
      [86.23777771, 20.02917099],
      [86.23750305, 20.02889061],
      [86.23694611, 20.02889061],
      [86.23665619, 20.02861023],
      [86.23665619, 20.02695084],
      [86.23972321, 20.02695084],
      [86.23999786, 20.02667046],
      [86.2419281, 20.02667046],
      [86.24221802, 20.02639008],
      [86.24254608, 20.02637291],
      [86.24291229, 20.02625084],
      [86.24323273, 20.02607918],
      [86.24352264, 20.02583122],
      [86.2437973, 20.02555275],
      [86.244133, 20.02526283],
      [86.24449921, 20.02495766],
      [86.24489594, 20.02465057],
      [86.24519348, 20.02425766],
      [86.24549866, 20.02388954],
      [86.24581909, 20.02353859],
      [86.24614716, 20.0231781],
      [86.24655914, 20.02287865],
      [86.24698639, 20.02254105],
      [86.24742126, 20.02219963],
      [86.24780273, 20.02177048],
      [86.24819946, 20.0214138],
      [86.24861145, 20.02115631],
      [86.24907684, 20.02114105],
      [86.24954987, 20.02130127],
      [86.25003815, 20.02133179],
      [86.25058746, 20.02116013],
      [86.25120544, 20.02108765],
      [86.2518158, 20.0209198],
      [86.25233459, 20.02060127],
      [86.25283051, 20.02033806],
      [86.25328827, 20.02013969],
      [86.25369263, 20.01991272],
      [86.25400543, 20.01964951],
      [86.25424194, 20.01927376],
      [86.25455475, 20.01893044],
      [86.25495911, 20.01863289],
      [86.25543976, 20.01839066],
      [86.25598145, 20.01833534],
      [86.25649261, 20.01832962],
      [86.25691986, 20.01833344],
      [86.25732422, 20.01833344],
      [86.25772858, 20.01832008],
      [86.25813293, 20.01823997],
      [86.25852966, 20.01813126],
      [86.2589798, 20.01828957],
      [86.25942993, 20.01828957],
      [86.25992584, 20.01810074],
      [86.26049805, 20.01805878],
      [86.26107788, 20.01806068],
      [86.2616272, 20.01806068],
      [86.26212311, 20.01805115],
      [86.26254272, 20.01799965],
      [86.26287079, 20.01781273],
      [86.26317596, 20.01775551],
      [86.26335144, 20.0177803],
      [86.26361084, 20.01749992],
      [86.26416779, 20.01749992],
      [86.26444244, 20.01721954],
      [86.26472473, 20.01722145],
      [86.26499939, 20.01694679],
      [86.26555634, 20.01694679],
      [86.26583099, 20.01722145],
      [86.26667023, 20.01721954],
      [86.26693726, 20.01749992],
      [86.26721954, 20.01749992],
      [86.26750183, 20.0177803],
      [86.26889038, 20.0177803],
      [86.26915741, 20.01749992],
      [86.26999664, 20.01749992],
      [86.27027893, 20.0177803],
      [86.27223206, 20.0177803],
      [86.27259064, 20.0177803],
      [86.27303314, 20.0177803],
      [86.27349091, 20.01777077],
      [86.27394867, 20.01772499],
      [86.27435303, 20.01754951],
      [86.27471924, 20.01753426],
      [86.27507019, 20.0176506],
      [86.27542114, 20.01776123],
      [86.27581024, 20.0178299],
      [86.27615356, 20.01800537],
      [86.27659607, 20.01805305],
      [86.27706146, 20.01808167],
      [86.27749634, 20.01819992],
      [86.27790833, 20.01834869],
      [86.27826691, 20.01852417],
      [86.27863312, 20.01866341],
      [86.27894592, 20.01885986],
      [86.27932739, 20.01903915],
      [86.27973175, 20.01920128],
      [86.28011322, 20.01942062],
      [86.28050232, 20.01960182],
      [86.2808609, 20.01986122],
      [86.28125763, 20.02013016],
      [86.28172302, 20.02038002],
      [86.28222656, 20.02060127],
      [86.28268433, 20.02090263],
      [86.28304291, 20.02127075],
      [86.28333282, 20.02164078],
      [86.28361511, 20.02193642],
      [86.28388214, 20.02219009],
      [86.28411865, 20.02243042],
      [86.28436279, 20.02260971],
      [86.28453827, 20.02287102],
      [86.28471375, 20.02317047],
      [86.28494263, 20.02351952],
      [86.28530121, 20.02388954],
      [86.28582764, 20.02416992],
      [86.28636932, 20.02434921],
      [86.28687286, 20.02420807],
      [86.287323, 20.0241909],
      [86.28774261, 20.02433968],
      [86.28818512, 20.02456856],
      [86.28868103, 20.02461243],
      [86.28920746, 20.02451515],
      [86.28967285, 20.02452087],
      [86.29006958, 20.02466965],
      [86.29049683, 20.02472115],
      [86.2909317, 20.02472687],
      [86.29133606, 20.02477074],
      [86.29171753, 20.02495003],
      [86.29211426, 20.02500534],
      [86.29234314, 20.02503014],
      [86.29252625, 20.02504921],
      [86.29296875, 20.02510834],
      [86.29344177, 20.02499008],
      [86.29393005, 20.02478981],
      [86.29444885, 20.02461243],
      [86.29494476, 20.02441978],
      [86.29532623, 20.02407837],
      [86.2954483, 20.02331352],
      [86.29568481, 20.02288055],
      [86.29576111, 20.0227356],
      [86.29608154, 20.02220917],
      [86.29640198, 20.0217495],
      [86.29670715, 20.02135086],
      [86.29693604, 20.02095985],
      [86.29689026, 20.02046013],
      [86.29699707, 20.02008438],
      [86.29720306, 20.01973152],
      [86.29738617, 20.01930809],
      [86.29766846, 20.01876068],
      [86.29798126, 20.01809311],
      [86.29836273, 20.01753044],
      [86.29884338, 20.01703072],
      [86.29940796, 20.0166893],
      [86.29992676, 20.01638031],
      [86.30036926, 20.01605988],
      [86.30072021, 20.01567841],
      [86.30104828, 20.01534081],
      [86.30139923, 20.0150528],
      [86.30178833, 20.01486969],
      [86.30217743, 20.01470184],
      [86.3025589, 20.01451111],
      [86.30297852, 20.01445198],
      [86.30343628, 20.01442337],
      [86.30396271, 20.01435471],
      [86.30454254, 20.01424026],
      [86.30516052, 20.01416206],
      [86.30540466, 20.01388931],
      [86.30561066, 20.01357841],
      [86.30587769, 20.01327133],
      [86.30612946, 20.01289177],
      [86.30639648, 20.01250076],
      [86.30661774, 20.01210976],
      [86.30665588, 20.01167107],
      [86.3069458, 20.01139069],
      [86.3069458, 20.01083183],
      [86.30722046, 20.01055527],
      [86.30722046, 20.01026917],
      [86.30833435, 20.00916672],
      [86.30832672, 20.00889015],
      [86.3088913, 20.00832939],
      [86.3088913, 20.00805473],
      [86.30916595, 20.00777817],
      [86.30916595, 20.0075016],
      [86.30944824, 20.00720978],
      [86.3105545, 20.00611115],
      [86.31054688, 20.00599289],
      [86.31079865, 20.00571632],
      [86.31098175, 20.00539017],
      [86.31114197, 20.00501251],
      [86.31141663, 20.00467491],
      [86.31178284, 20.00438118],
      [86.31195831, 20.00396919],
      [86.31175232, 20.00333977],
      [86.31170654, 20.00285339],
      [86.31179047, 20.00245857],
      [86.31172943, 20.00196838],
      [86.31171417, 20.0014267],
      [86.31181335, 20.00085068],
      [86.3122406, 20.0003891],
      [86.3125, 20],
      [86.31333923, 19.99916077],
      [86.31383514, 19.99882317],
      [86.31399536, 19.9985466],
      [86.3144455, 19.99777794],
      [86.31446075, 19.99749947],
      [86.3152771, 19.99666595],
      [86.3152771, 19.99638939],
      [86.31582642, 19.99583244],
      [86.31583405, 19.9952774],
      [86.3161087, 19.99500084],
      [86.3161087, 19.99471855],
      [86.31639099, 19.99444389],
      [86.31639099, 19.99416733],
      [86.31777954, 19.99278069],
      [86.31777954, 19.99250031],
      [86.31804657, 19.99221039],
      [86.3180542, 19.99194527],
      [86.31833649, 19.99167061],
      [86.31833649, 19.99028015],
      [86.31861115, 19.98999977],
      [86.31861115, 19.98944092],
      [86.31916809, 19.98888969],
      [86.31916809, 19.98861122],
      [86.31973267, 19.98806],
      [86.31973267, 19.98777962],
      [86.31999969, 19.98750114],
      [86.31999969, 19.98722076],
      [86.3216629, 19.98555565],
      [86.3216629, 19.98472023],
      [86.32194519, 19.98444366],
      [86.32194519, 19.98388863],
      [86.32138824, 19.98333168],
      [86.32138824, 19.98305511],
      [86.32013702, 19.98180962],
      [86.31906891, 19.98101044],
      [86.31715393, 19.98060417],
      [86.31646729, 19.98069954],
      [86.31518555, 19.98070526],
      [86.31414795, 19.98111153],
      [86.31181335, 19.98180962],
      [86.31130981, 19.9820652],
      [86.30995178, 19.9824028],
      [86.30887604, 19.98294067],
      [86.30802917, 19.98314857],
      [86.30690002, 19.98351097],
      [86.30487061, 19.9838829],
      [86.30390167, 19.98400116],
      [86.30323792, 19.98408318],
      [86.30266571, 19.98408318],
      [86.30252075, 19.98401642],
      [86.30252075, 19.98381042],
      [86.30266571, 19.9835434],
      [86.30308533, 19.9832592],
      [86.30349731, 19.98329544],
      [86.30388641, 19.98333359],
      [86.3041687, 19.98305511],
      [86.30473328, 19.98306274],
      [86.30500031, 19.98278046],
      [86.3069458, 19.98278046],
      [86.30722046, 19.98250008],
      [86.3077774, 19.98250008],
      [86.30860901, 19.98167038],
      [86.30916595, 19.98167038],
      [86.30944824, 19.98139191],
      [86.31027985, 19.98139191],
      [86.31054688, 19.98111153],
      [86.31138611, 19.98111153],
      [86.31359863, 19.98013496],
      [86.3157959, 19.9798584],
      [86.31711578, 19.97971916],
      [86.31789398, 19.97991562],
      [86.31839752, 19.97998047],
      [86.31907654, 19.98023033],
      [86.31999969, 19.9805603],
      [86.32097626, 19.98103905],
      [86.32168579, 19.98171616],
      [86.32195282, 19.9822216],
      [86.32277679, 19.98305511],
      [86.32315063, 19.98347282],
      [86.32327271, 19.98361206],
      [86.32389069, 19.98361206],
      [86.32472229, 19.98277855],
      [86.32472229, 19.98250008],
      [86.32640076, 19.98082924],
      [86.3263855, 19.98055649],
      [86.32694244, 19.97999763],
      [86.3272171, 19.97999763],
      [86.32861328, 19.97860909],
      [86.32888794, 19.97860909],
      [86.32917023, 19.97833061],
      [86.32944489, 19.97833252],
      [86.32971954, 19.97805786],
      [86.33055878, 19.97805977],
      [86.33082581, 19.97777939],
      [86.33110809, 19.97777939],
      [86.33139038, 19.97750092],
      [86.33167267, 19.97750092],
      [86.33194733, 19.97722244],
      [86.33304596, 19.97722816],
      [86.33333588, 19.97694016],
      [86.33444214, 19.97694397],
      [86.33472443, 19.9766655],
      [86.33528137, 19.97667122],
      [86.33557129, 19.97639084],
      [86.33583069, 19.97639084],
      [86.33611298, 19.97611046],
      [86.33638763, 19.97611046],
      [86.33666992, 19.97583008],
      [86.33721924, 19.9758358],
      [86.33750153, 19.97555542],
      [86.33777618, 19.97556114],
      [86.33805847, 19.97528076],
      [86.33834076, 19.97528076],
      [86.33917999, 19.97454071],
      [86.3397522, 19.97393036],
      [86.34060669, 19.97284889],
      [86.34102631, 19.97237968],
      [86.34137726, 19.97116089],
      [86.34137726, 19.97056007],
      [86.34027863, 19.96944237],
      [86.33999634, 19.96944427],
      [86.33972168, 19.9691658],
      [86.33944702, 19.96916962],
      [86.3391571, 19.96889114],
      [86.33889008, 19.96889114],
      [86.33834076, 19.96832848],
      [86.33805847, 19.9683342],
      [86.33792114, 19.96819687],
      [86.33777618, 19.96805573],
      [86.33777618, 19.96777916],
      [86.33583069, 19.96582985],
      [86.33472443, 19.96388626],
      [86.3347168, 19.9636097],
      [86.33305359, 19.96194458],
      [86.33277893, 19.96194458],
      [86.33073425, 19.95990181],
      [86.32944489, 19.95861053],
      [86.32943726, 19.95778084],
      [86.32953644, 19.95768166],
      [86.32971954, 19.95750046],
      [86.3290329, 19.95681381],
      [86.32888794, 19.95666695],
      [86.32888794, 19.95656013],
      [86.32888794, 19.95638847],
      [86.32863617, 19.9561367],
      [86.32805634, 19.95555496],
      [86.32776642, 19.95556259],
      [86.32749939, 19.9552803],
      [86.32694244, 19.9552803],
      [86.32666779, 19.95500183],
      [86.3263855, 19.95500183],
      [86.32583618, 19.95444489],
      [86.32555389, 19.95444489],
      [86.32499695, 19.95388985],
      [86.32472229, 19.95389175],
      [86.32463074, 19.95379448],
      [86.32417297, 19.95332909],
      [86.32389069, 19.9533329],
      [86.3236084, 19.95305634],
      [86.32305908, 19.95305824],
      [86.32277679, 19.95277977],
      [86.32250214, 19.95277977],
      [86.32221985, 19.95249748],
      [86.3216629, 19.95249748],
      [86.32138824, 19.95222092],
      [86.32111359, 19.95222282],
      [86.3208313, 19.95194435],
      [86.32027435, 19.95194435],
      [86.31999969, 19.95166779],
      [86.31944275, 19.95166969],
      [86.31916809, 19.95139122],
      [86.3188858, 19.95139122],
      [86.31861115, 19.95111084],
      [86.31833649, 19.95111084],
      [86.31720734, 19.95000076],
      [86.31694794, 19.94972038],
      [86.31749725, 19.94916534],
      [86.31833649, 19.94916534],
      [86.31847382, 19.94930458],
      [86.31861115, 19.94944382],
      [86.3188858, 19.94944382],
      [86.31916809, 19.94972229],
      [86.31944275, 19.94972038],
      [86.31999969, 19.95029068],
      [86.32027435, 19.95027542],
      [86.3208313, 19.95083427],
      [86.32110596, 19.95083046],
      [86.32138824, 19.95111084],
      [86.3216629, 19.95111084],
      [86.32195282, 19.95139122],
      [86.32221985, 19.95139122],
      [86.32250214, 19.95166969],
      [86.32305908, 19.95166779],
      [86.32333374, 19.95194435],
      [86.32389069, 19.95194435],
      [86.32416534, 19.95222282],
      [86.32444763, 19.95222092],
      [86.32499695, 19.95277977],
      [86.32527924, 19.95277977],
      [86.32554626, 19.95305824],
      [86.32611084, 19.95305824],
      [86.32694244, 19.95389175],
      [86.32722473, 19.95388985],
      [86.32749939, 19.95416641],
      [86.32777405, 19.95416641],
      [86.32805634, 19.95444489],
      [86.32833099, 19.95444489],
      [86.33110809, 19.95722008],
      [86.33416748, 19.96055984],
      [86.33444214, 19.96055412],
      [86.33472443, 19.9608326],
      [86.33499908, 19.96083069],
      [86.33666992, 19.96249962],
      [86.33777618, 19.96499825],
      [86.33777618, 19.96528816],
      [86.33805847, 19.96555519],
      [86.33805847, 19.96583366],
      [86.33834076, 19.96610832],
      [86.33834076, 19.96639061],
      [86.33944702, 19.96750069],
      [86.33972168, 19.96750069],
      [86.33999634, 19.96777916],
      [86.34027863, 19.96777725],
      [86.34135437, 19.96863747],
      [86.34243774, 19.9695034],
      [86.34331512, 19.96925926],
      [86.34381104, 19.9691906],
      [86.34467316, 19.96865082],
      [86.3451767, 19.96858025],
      [86.34554291, 19.96813583],
      [86.34632111, 19.96759033],
      [86.34696198, 19.96711922],
      [86.34767151, 19.96651077],
      [86.34806824, 19.96610832],
      [86.34913635, 19.96547127],
      [86.3500061, 19.96467972],
      [86.35086823, 19.9642086],
      [86.3514328, 19.96393967],
      [86.35164642, 19.96372986],
      [86.35165405, 19.96339417],
      [86.35111237, 19.96333122],
      [86.35083008, 19.96333313],
      [86.34899902, 19.96389961],
      [86.34814453, 19.96430969],
      [86.34714508, 19.96485138],
      [86.34671783, 19.96512032],
      [86.34622192, 19.96533012],
      [86.34593964, 19.96533012],
      [86.34586334, 19.96512032],
      [86.34593964, 19.96478462],
      [86.34650421, 19.96437645],
      [86.34694672, 19.96417046],
      [86.34751129, 19.9636097],
      [86.34777832, 19.9636116],
      [86.34833527, 19.96305466],
      [86.34860992, 19.96305466],
      [86.34889221, 19.96277618],
      [86.34971619, 19.96278],
      [86.34999847, 19.96249962],
      [86.35083008, 19.96249962],
      [86.35111237, 19.96223068],
      [86.35250092, 19.96223068],
      [86.3527832, 19.96194077],
      [86.35361481, 19.96194458],
      [86.35388947, 19.9616642],
      [86.35500336, 19.96166992],
      [86.35527802, 19.96138954],
      [86.35584259, 19.96138954],
      [86.35610962, 19.96111107],
      [86.35666656, 19.96111107],
      [86.35694122, 19.96083069],
      [86.35749817, 19.9608326],
      [86.35778046, 19.96055412],
      [86.35832977, 19.96055984],
      [86.35861206, 19.96027946],
      [86.35888672, 19.96027946],
      [86.35945129, 19.95972061],
      [86.35972595, 19.95972252],
      [86.36000061, 19.95944214],
      [86.36027527, 19.95944214],
      [86.36055756, 19.95916748],
      [86.3611145, 19.95916748],
      [86.36138916, 19.95888901],
      [86.36222076, 19.95889091],
      [86.36251068, 19.95861053],
      [86.36277771, 19.95861053],
      [86.36305237, 19.95833015],
      [86.3638916, 19.95833206],
      [86.36416626, 19.9580555],
      [86.36472321, 19.95806122],
      [86.36499786, 19.95778084],
      [86.36554718, 19.95778084],
      [86.3658371, 19.95750046],
      [86.3658371, 19.95583153],
      [86.3655777, 19.9551506],
      [86.36422729, 19.95372963],
      [86.36266327, 19.95277977],
      [86.36065674, 19.9522419],
      [86.35788727, 19.95156288],
      [86.35634613, 19.95125961],
      [86.35546875, 19.95108795],
      [86.35169983, 19.95055008],
      [86.34992218, 19.95013046],
      [86.34664917, 19.94966888],
      [86.34500885, 19.94872284],
      [86.34395599, 19.94819069],
      [86.34261322, 19.94819069],
      [86.34131622, 19.94765091],
      [86.3395462, 19.9474411],
      [86.33691406, 19.94683456],
      [86.33413696, 19.94636154],
      [86.33200073, 19.94602013],
      [86.33113861, 19.94584656],
      [86.32971954, 19.94555473],
      [86.32746887, 19.94473839],
      [86.32462311, 19.94412994],
      [86.32398224, 19.94406128],
      [86.32319641, 19.94392395],
      [86.32205963, 19.94372368],
      [86.32149506, 19.94350624],
      [86.32042694, 19.94299126],
      [86.31977844, 19.94279289],
      [86.318573, 19.94218063],
      [86.31742859, 19.94197083],
      [86.31593323, 19.94190025],
      [86.31443787, 19.94123077],
      [86.31279755, 19.94067955],
      [86.31180573, 19.94047737],
      [86.31059265, 19.93988991],
      [86.30912781, 19.93950081],
      [86.30713654, 19.93917084],
      [86.30535889, 19.93856239],
      [86.30420685, 19.93802071],
      [86.30172729, 19.93721008],
      [86.3003006, 19.93659019],
      [86.29880524, 19.93585396],
      [86.29766846, 19.93564987],
      [86.29611206, 19.93551064],
      [86.29475403, 19.93497276],
      [86.29375458, 19.93463516],
      [86.2926178, 19.93367958],
      [86.29192352, 19.93335724],
      [86.2903595, 19.9327507],
      [86.28956604, 19.93227005],
      [86.28843689, 19.93193626],
      [86.28730011, 19.93112564],
      [86.28565979, 19.93085289],
      [86.28424072, 19.9303112],
      [86.28309631, 19.92963982],
      [86.28217316, 19.92923164],
      [86.2806778, 19.92821884],
      [86.27867889, 19.92733955],
      [86.2770462, 19.92658806],
      [86.2736969, 19.92496872],
      [86.27220917, 19.92436028],
      [86.26999664, 19.92307091],
      [86.26717377, 19.9215641],
      [86.26574707, 19.92068481],
      [86.26475525, 19.92020988],
      [86.26347351, 19.91926193],
      [86.26183319, 19.91892242],
      [86.25930023, 19.91778755],
      [86.25666809, 19.91642952],
      [86.25421143, 19.91516685],
      [86.25258636, 19.91434097],
      [86.25016785, 19.91270828],
      [86.24867249, 19.91183662],
      [86.24739075, 19.91123009],
      [86.24617767, 19.91115952],
      [86.24524689, 19.91115952],
      [86.2444458, 19.91194534],
      [86.24278259, 19.91250038],
      [86.24138641, 19.91389084],
      [86.24138641, 19.9141674],
      [86.2408371, 19.91471863],
      [86.2408371, 19.91500092],
      [86.24028015, 19.91555977],
      [86.24028015, 19.9161129],
      [86.2416687, 19.91749954],
      [86.2419281, 19.91749954],
      [86.24221802, 19.91777992],
      [86.24250031, 19.91777802],
      [86.24333191, 19.91860962],
      [86.24305725, 19.91889],
      [86.24305725, 19.91944313],
      [86.24278259, 19.9197197],
      [86.24278259, 19.92028046],
      [86.24250031, 19.92055321],
      [86.24250031, 19.92083359],
      [86.24222565, 19.92111206],
      [86.24175262, 19.92205048],
      [86.24160004, 19.92205048],
      [86.24138641, 19.92192268],
      [86.24138641, 19.92099762],
      [86.24173737, 19.92004967],
      [86.24173737, 19.91917038],
      [86.24102783, 19.91822052],
      [86.24010468, 19.91727448],
      [86.2388916, 19.91638947],
      [86.2388916, 19.9159584],
      [86.2388916, 19.91583252],
      [86.23944092, 19.91527748],
      [86.23944092, 19.91500092],
      [86.23972321, 19.91471863],
      [86.23972321, 19.9141674],
      [86.23999786, 19.91389084],
      [86.23999786, 19.91361046],
      [86.24028015, 19.91332817],
      [86.23999786, 19.91305542],
      [86.23999786, 19.91278076],
      [86.23972321, 19.91250038],
      [86.23832703, 19.91389084],
      [86.23805237, 19.91389084],
      [86.23750305, 19.91332817],
      [86.23750305, 19.91222191],
      [86.23722076, 19.91194534],
      [86.23722076, 19.91167068],
      [86.23694611, 19.9113903],
      [86.23694611, 19.91082954],
      [86.23583221, 19.90972137],
      [86.23583221, 19.90916443],
      [86.23443604, 19.90777969],
      [86.23388672, 19.90777969],
      [86.23332977, 19.90722084],
      [86.23305511, 19.90722275],
      [86.23278046, 19.90694427],
      [86.23222351, 19.90694427],
      [86.23194122, 19.9066658],
      [86.23166656, 19.90666962],
      [86.23139191, 19.90639114],
      [86.23110962, 19.90639114],
      [86.22972107, 19.90501022],
      [86.22944641, 19.90501022],
      [86.22833252, 19.90389061],
      [86.2277832, 19.90361023],
      [86.22666931, 19.90361023],
      [86.22638702, 19.90389061],
      [86.22583008, 19.9038887],
      [86.22499847, 19.90472031],
      [86.22499847, 19.90501022],
      [86.22360992, 19.90639114],
      [86.22360992, 19.9066658],
      [86.22333527, 19.90694427],
      [86.22333527, 19.90722275],
      [86.22250366, 19.90805435],
      [86.22249603, 19.90833092],
      [86.22166443, 19.90916443],
      [86.22166443, 19.90944672],
      [86.22138977, 19.90972137],
      [86.22138977, 19.91055489],
      [86.22110748, 19.91082954],
      [86.22110748, 19.91138649],
      [86.22305298, 19.91332817],
      [86.22305298, 19.91361046],
      [86.22389221, 19.91444397],
      [86.22389221, 19.91471863],
      [86.22528076, 19.9161129],
      [86.22556305, 19.9161129],
      [86.22606659, 19.91662025],
      [86.22634125, 19.91731071],
      [86.22657013, 19.91855049],
      [86.22657013, 19.91949844],
      [86.22632599, 19.92052078],
      [86.22606659, 19.92087936],
      [86.22583008, 19.92111015],
      [86.22551727, 19.9217701],
      [86.22480774, 19.92251015],
      [86.22431183, 19.92332268],
      [86.2237854, 19.9246521],
      [86.22354126, 19.92498016],
      [86.22328186, 19.92533112],
      [86.22289276, 19.92562103],
      [86.22252655, 19.92562103],
      [86.22249603, 19.92547607],
      [86.22246552, 19.92534447],
      [86.2227478, 19.92480278],
      [86.22305298, 19.92416954],
      [86.22354889, 19.9229393],
      [86.22397614, 19.92213058],
      [86.22421265, 19.9218998],
      [86.22505951, 19.92095184],
      [86.22556305, 19.92033958],
      [86.22576904, 19.91966438],
      [86.22555542, 19.91861153],
      [86.22528076, 19.91833305],
      [86.22528076, 19.91805649],
      [86.22499847, 19.91777992],
      [86.22499847, 19.91749954],
      [86.22389221, 19.91638947],
      [86.22389221, 19.9161129],
      [86.22081757, 19.91305923],
      [86.22081757, 19.91278076],
      [86.22055817, 19.91250038],
      [86.22055817, 19.91221809],
      [86.22027588, 19.91194534],
      [86.22027588, 19.91167068],
      [86.22000122, 19.9113903],
      [86.21943665, 19.91194916],
      [86.21888733, 19.91194916],
      [86.21868134, 19.91214943],
      [86.21775818, 19.91290092],
      [86.21720123, 19.91323662],
      [86.21689606, 19.91332054],
      [86.21671295, 19.91337013],
      [86.21656036, 19.9134407],
      [86.21627808, 19.913311],
      [86.21613312, 19.9131012],
      [86.21606445, 19.91269875],
      [86.21556091, 19.91242027],
      [86.21472168, 19.91222191],
      [86.21416473, 19.91222191],
      [86.21389008, 19.91194534],
      [86.21360779, 19.91194916],
      [86.21298218, 19.91131973],
      [86.21277618, 19.91110992],
      [86.21250153, 19.91111183],
      [86.21250153, 19.91083336],
      [86.21221924, 19.91055489],
      [86.21221924, 19.90916443],
      [86.21250153, 19.90888977],
      [86.21221924, 19.90833092],
      [86.21250153, 19.90805435],
      [86.21250153, 19.90611076],
      [86.21221924, 19.90583038],
      [86.21221924, 19.90555573],
      [86.21138763, 19.90472031],
      [86.20944214, 19.90472031],
      [86.20887756, 19.90528107],
      [86.20887756, 19.90555954],
      [86.20848083, 19.90600014],
      [86.20812988, 19.90607071],
      [86.20791626, 19.90607071],
      [86.20791626, 19.90553093],
      [86.20804596, 19.90472031],
      [86.20944214, 19.90333366],
      [86.21111298, 19.90333176],
      [86.21138763, 19.90361023],
      [86.21194458, 19.90361023],
      [86.21305847, 19.90472031],
      [86.21305847, 19.90501022],
      [86.21360779, 19.90555573],
      [86.21360779, 19.90583038],
      [86.21389008, 19.90611076],
      [86.21389008, 19.90639114],
      [86.21416473, 19.9066658],
      [86.21416473, 19.90694427],
      [86.21389008, 19.90722275],
      [86.21389008, 19.90833282],
      [86.21360779, 19.90860939],
      [86.21360779, 19.90888977],
      [86.21389008, 19.90916443],
      [86.21389008, 19.90971947],
      [86.2141571, 19.90999985],
      [86.21416473, 19.91027641],
      [86.21444702, 19.91055489],
      [86.21444702, 19.91082954],
      [86.2150116, 19.9113903],
      [86.21555328, 19.91138649],
      [86.21565247, 19.91148567],
      [86.2158432, 19.91167068],
      [86.21678925, 19.91166878],
      [86.21721649, 19.91167068],
      [86.21749878, 19.9113903],
      [86.21778107, 19.9113903],
      [86.21833038, 19.91082954],
      [86.21888733, 19.91083336],
      [86.21916962, 19.91055489],
      [86.21916962, 19.91027641],
      [86.21971893, 19.90971947],
      [86.21971893, 19.90888977],
      [86.22000122, 19.90860939],
      [86.22000122, 19.90805435],
      [86.22027588, 19.90777779],
      [86.22027588, 19.90722084],
      [86.22110748, 19.90638924],
      [86.22138977, 19.90639114],
      [86.22194672, 19.90583038],
      [86.22194672, 19.90527725],
      [86.22305298, 19.9041729],
      [86.22305298, 19.90389061],
      [86.22471619, 19.90222168],
      [86.22558594, 19.9016819],
      [86.22657776, 19.90160942],
      [86.22735596, 19.90175056],
      [86.22807312, 19.90184021],
      [86.22838593, 19.90184021],
      [86.22867584, 19.90157127],
      [86.22796631, 19.90088081],
      [86.22753143, 19.90075493],
      [86.2247467, 19.89953995],
      [86.22238922, 19.89898109],
      [86.2199707, 19.89810562],
      [86.21837616, 19.89743614],
      [86.21705627, 19.89688492],
      [86.21613312, 19.89689064],
      [86.21440887, 19.89621162],
      [86.21250153, 19.89546013],
      [86.20986938, 19.89458275],
      [86.20710754, 19.89394379],
      [86.20603943, 19.89347076],
      [86.20461273, 19.89286041],
      [86.20226288, 19.89204979],
      [86.19906616, 19.8908329],
      [86.19629669, 19.89007187],
      [86.19473267, 19.88966751],
      [86.19210052, 19.88879013],
      [86.19061279, 19.88796806],
      [86.18804932, 19.8872261],
      [86.18527985, 19.88628006],
      [86.18392181, 19.88594055],
      [86.18171692, 19.88525963],
      [86.17887115, 19.88438225],
      [86.17778015, 19.88388634],
      [86.1763916, 19.88376617],
      [86.17575073, 19.88343239],
      [86.17489624, 19.88295174],
      [86.17440033, 19.88288498],
      [86.1727829, 19.88278008],
      [86.17082977, 19.88217926],
      [86.16696167, 19.88101006],
      [86.1651001, 19.88030052],
      [86.16268158, 19.8796196],
      [86.1607666, 19.87887955],
      [86.15888977, 19.87833023],
      [86.15833282, 19.87833214],
      [86.15777588, 19.8777771],
      [86.1550827, 19.87730026],
      [86.15399933, 19.87686729],
      [86.15248871, 19.87615013],
      [86.15019989, 19.87568092],
      [86.14985657, 19.87568092],
      [86.14885712, 19.87528992],
      [86.14705658, 19.87456322],
      [86.14585114, 19.87415886],
      [86.14408112, 19.87401962],
      [86.14266968, 19.87341499],
      [86.14111328, 19.87267113],
      [86.14017487, 19.87246704],
      [86.13939667, 19.87220001],
      [86.13752747, 19.87181091],
      [86.1366806, 19.87140656],
      [86.13553619, 19.87141037],
      [86.13446808, 19.87072945],
      [86.13340759, 19.87053108],
      [86.13247681, 19.87005997],
      [86.13166809, 19.86972046],
      [86.1305542, 19.86972237],
      [86.12809753, 19.8693161],
      [86.12696075, 19.86890984],
      [86.12539673, 19.86844254],
      [86.12412262, 19.86796188],
      [86.12247467, 19.86750031],
      [86.1222229, 19.86750031],
      [86.12076569, 19.86750412],
      [86.12032318, 19.8674736],
      [86.11977386, 19.86743736],
      [86.1182785, 19.86668968],
      [86.11685181, 19.86655617],
      [86.1155014, 19.8658123],
      [86.11351776, 19.86547279],
      [86.11138916, 19.8647213],
      [86.11055756, 19.86444473],
      [86.11000061, 19.86416626],
      [86.10961151, 19.86404037],
      [86.10845947, 19.86347961],
      [86.10726166, 19.86347961],
      [86.10610962, 19.86361122],
      [86.10584259, 19.86333275],
      [86.10444641, 19.86333275],
      [86.10416412, 19.86305618],
      [86.10305786, 19.8630619],
      [86.1027832, 19.86277962],
      [86.10250092, 19.86277962],
      [86.10221863, 19.86250114],
      [86.10138702, 19.86250114],
      [86.10124969, 19.86236191],
      [86.10111237, 19.86222267],
      [86.10083008, 19.86222267],
      [86.10055542, 19.8619442],
      [86.09999847, 19.8619442],
      [86.09972382, 19.86166573],
      [86.09916687, 19.86166954],
      [86.09770966, 19.86129951],
      [86.0966568, 19.86055946],
      [86.09514618, 19.85991096],
      [86.09465027, 19.85984612],
      [86.09278107, 19.85971069],
      [86.09194183, 19.85972214],
      [86.09166718, 19.85944366],
      [86.09083557, 19.85944366],
      [86.09055328, 19.8591671],
      [86.09027863, 19.85917091],
      [86.08999634, 19.85889053],
      [86.08947754, 19.85889053],
      [86.08777618, 19.85847092],
      [86.08586121, 19.85819817],
      [86.08436584, 19.85773277],
      [86.08296967, 19.85741043],
      [86.08062744, 19.85713959],
      [86.07920074, 19.85674095],
      [86.07861328, 19.85667038],
      [86.07805634, 19.85666847],
      [86.07777405, 19.85694695],
      [86.07722473, 19.85694695],
      [86.07694244, 19.8572216],
      [86.0763855, 19.8572216],
      [86.07611084, 19.85694695],
      [86.07583618, 19.85694695],
      [86.07555389, 19.85666847],
      [86.07333374, 19.85666847],
      [86.07305908, 19.85694695],
      [86.07250214, 19.85694695],
      [86.07221985, 19.85666847],
      [86.0716629, 19.85667038],
      [86.07138824, 19.85639],
      [86.07054901, 19.85639],
      [86.06999969, 19.85583115],
      [86.06972504, 19.85611153],
      [86.06833649, 19.85611153],
      [86.0680542, 19.85583305],
      [86.0661087, 19.85583305],
      [86.06583405, 19.85555458],
      [86.0652771, 19.8555603],
      [86.06500244, 19.85527992],
      [86.06472015, 19.85527992],
      [86.06445313, 19.85499954],
      [86.0633316, 19.85499954],
      [86.06305695, 19.85472107],
      [86.06222534, 19.85472488],
      [86.06138611, 19.85388947],
      [86.06111145, 19.85390091],
      [86.06083679, 19.85416985],
      [86.05999756, 19.85416985],
      [86.0597229, 19.85390091],
      [86.05916595, 19.85390091],
      [86.0588913, 19.85416985],
      [86.05665588, 19.85416985],
      [86.05464172, 19.85373116],
      [86.05349731, 19.85338974],
      [86.05193329, 19.8531208],
      [86.05076599, 19.8526783],
      [86.04865265, 19.85222054],
      [86.04833221, 19.85222244],
      [86.04805756, 19.85194206],
      [86.04777527, 19.85194206],
      [86.04750061, 19.8516674],
      [86.04360962, 19.8516674],
      [86.04333496, 19.85194206],
      [86.04222107, 19.85194206],
      [86.04194641, 19.85222244],
      [86.04138947, 19.85222054],
      [86.04110718, 19.85250092],
      [86.04083252, 19.85250092],
      [86.0402832, 19.85305977],
      [86.03971863, 19.85305977],
      [86.03916931, 19.85361099],
      [86.03888702, 19.85361099],
      [86.03805542, 19.8544445],
      [86.03778076, 19.8544445],
      [86.03749847, 19.85472488],
      [86.03721619, 19.85472107],
      [86.03694153, 19.85499954],
      [86.03666687, 19.85499954],
      [86.03639221, 19.85527992],
      [86.03610992, 19.85527992],
      [86.0358429, 19.85499954],
      [86.03555298, 19.85499954],
      [86.03527832, 19.85527992],
      [86.03499603, 19.85527992],
      [86.03472137, 19.85499954],
      [86.03414917, 19.85499954],
      [86.03392029, 19.85475159],
      [86.03333282, 19.85472488],
      [86.03305817, 19.8544445],
      [86.03250122, 19.8544445],
      [86.03236389, 19.85430527],
      [86.03221893, 19.85416412],
      [86.03193665, 19.85416985],
      [86.03098297, 19.85288048],
      [86.03044128, 19.85232353],
      [86.03044128, 19.85198402],
      [86.03083801, 19.85174561],
      [86.03091431, 19.85153961],
      [86.03090668, 19.8512001],
      [86.03055573, 19.85059357],
      [86.03055573, 19.85027695],
      [86.03028107, 19.85000038],
      [86.03028107, 19.84972191],
      [86.02999878, 19.84944534],
      [86.02999878, 19.84917259],
      [86.02916718, 19.84833145],
      [86.02889252, 19.84833336],
      [86.02805328, 19.84750175],
      [86.02805328, 19.84722137],
      [86.02777863, 19.84694481],
      [86.02777863, 19.84666634],
      [86.02668762, 19.84560013],
      [86.02611542, 19.84519768],
      [86.02526093, 19.84513092],
      [86.02481842, 19.84500122],
      [86.02353668, 19.84487724],
      [86.02353668, 19.84453964],
      [86.02371216, 19.84424973],
      [86.02464294, 19.84408951],
      [86.02527618, 19.84416962],
      [86.02583313, 19.84416771],
      [86.02607727, 19.84428787],
      [86.02694702, 19.84472275],
      [86.02722168, 19.8447094],
      [86.02777863, 19.84527969],
      [86.02805328, 19.84527969],
      [86.02833557, 19.84555626],
      [86.0283432, 19.84583092],
      [86.02889252, 19.84638977],
      [86.02916718, 19.84638977],
      [86.03083038, 19.84805489],
      [86.03083038, 19.84833336],
      [86.03111267, 19.84861183],
      [86.03111267, 19.8488903],
      [86.03166962, 19.84944534],
      [86.03166962, 19.84972191],
      [86.03193665, 19.85000038],
      [86.03193665, 19.85056114],
      [86.03221893, 19.85083961],
      [86.03221893, 19.85111046],
      [86.03250122, 19.85138893],
      [86.03250122, 19.85194206],
      [86.03277588, 19.85222054],
      [86.03277588, 19.85250092],
      [86.03414917, 19.85390091],
      [86.03666687, 19.85390091],
      [86.03694153, 19.85361099],
      [86.0375061, 19.85361099],
      [86.03778076, 19.85333252],
      [86.03805542, 19.85333252],
      [86.03861237, 19.85277748],
      [86.03888702, 19.85277939],
      [86.04057312, 19.85111046],
      [86.04111481, 19.85111046],
      [86.04334259, 19.8488903],
      [86.04416656, 19.84804916],
      [86.04499054, 19.84777832],
      [86.04548645, 19.84757614],
      [86.04563141, 19.84737015],
      [86.04563141, 19.84723663],
      [86.04556274, 19.84710121],
      [86.0450592, 19.84691048],
      [86.04432678, 19.84682274],
      [86.04403687, 19.84674072],
      [86.04302216, 19.84657097],
      [86.04189301, 19.84622955],
      [86.04142761, 19.84614944],
      [86.04029846, 19.84601784],
      [86.03845215, 19.84534073],
      [86.0378418, 19.84528923],
      [86.03620148, 19.84507942],
      [86.03499603, 19.84454346],
      [86.03356934, 19.84427071],
      [86.03214264, 19.84406853],
      [86.03050995, 19.8439312],
      [86.02980804, 19.84362602],
      [86.02752686, 19.84329033],
      [86.02632904, 19.84282112],
      [86.02490234, 19.84262085],
      [86.02348328, 19.84249115],
      [86.02134705, 19.84227943],
      [86.02068329, 19.84214592],
      [86.01847839, 19.84160614],
      [86.01641083, 19.84086037],
      [86.01486969, 19.84062576],
      [86.01394653, 19.84049034],
      [86.01251984, 19.8402195],
      [86.01145935, 19.84008026],
      [86.00999451, 19.83998108],
      [86.00914001, 19.83991432],
      [86.00821686, 19.83984947],
      [86.00737, 19.83937073],
      [86.00658417, 19.83910179],
      [86.00543976, 19.83910179],
      [86.00479889, 19.83883286],
      [86.0031662, 19.83835793],
      [86.0017395, 19.83829117],
      [86, 19.83805275],
      [85.99822998, 19.83774948],
      [85.99749756, 19.83778],
      [85.99555206, 19.83778],
      [85.99500275, 19.83749962],
      [85.99472046, 19.83749962],
      [85.9944458, 19.83722115],
      [85.99416351, 19.83722115],
      [85.99388885, 19.83694458],
      [85.99111176, 19.83694458],
      [85.99055481, 19.83638954],
      [85.9888916, 19.83638954],
      [85.98860931, 19.83611107],
      [85.98583221, 19.83611107],
      [85.98555756, 19.8358326],
      [85.98500061, 19.8358326],
      [85.98472595, 19.83555603],
      [85.98361206, 19.83556175],
      [85.98332977, 19.83527946],
      [85.98278046, 19.83527946],
      [85.98249817, 19.83499908],
      [85.98110962, 19.83499908],
      [85.98084259, 19.83472061],
      [85.97915649, 19.83472061],
      [85.97888947, 19.83499908],
      [85.97860718, 19.83499908],
      [85.9783783, 19.83476639],
      [85.97805786, 19.83472252],
      [85.97750092, 19.83416748],
      [85.97638702, 19.83416939],
      [85.97611237, 19.83389091],
      [85.97583008, 19.83389091],
      [85.97556305, 19.83361053],
      [85.97499847, 19.83361053],
      [85.97471619, 19.83389091],
      [85.97444153, 19.83389091],
      [85.97416687, 19.83361053],
      [85.97360992, 19.83361053],
      [85.9733429, 19.83332825],
      [85.97305298, 19.83361053],
      [85.97277832, 19.83361053],
      [85.97249603, 19.83332825],
      [85.97166443, 19.83333397],
      [85.97138977, 19.8330555],
      [85.97055817, 19.83305931],
      [85.97027588, 19.83276939],
      [85.96861267, 19.83277893],
      [85.96833038, 19.83250046],
      [85.96749878, 19.83250046],
      [85.96721649, 19.83221817],
      [85.96694183, 19.83222198],
      [85.96666718, 19.83194351],
      [85.96444702, 19.83194351],
      [85.96416473, 19.83166885],
      [85.96193695, 19.83167076],
      [85.96166992, 19.83139038],
      [85.96028137, 19.83139038],
      [85.96014404, 19.83124733],
      [85.95999908, 19.83110809],
      [85.95916748, 19.83111191],
      [85.95888519, 19.83083344],
      [85.95833588, 19.83083344],
      [85.95805359, 19.83055687],
      [85.95749664, 19.83056068],
      [85.95722198, 19.8302803],
      [85.95527649, 19.8302803],
      [85.95500183, 19.82999992],
      [85.95333099, 19.82999992],
      [85.95305634, 19.82973099],
      [85.95276642, 19.82973099],
      [85.95249939, 19.82944107],
      [85.9513855, 19.82944679],
      [85.95111084, 19.82916451],
      [85.94999695, 19.82917023],
      [85.94972229, 19.82888985],
      [85.9486084, 19.82888985],
      [85.94832611, 19.82860947],
      [85.94777679, 19.82861137],
      [85.94750214, 19.8283329],
      [85.9466629, 19.8283329],
      [85.94657135, 19.82824135],
      [85.94638824, 19.82805443],
      [85.94499969, 19.82806015],
      [85.94489288, 19.82795143],
      [85.94473267, 19.82777977],
      [85.94444275, 19.82777977],
      [85.94416046, 19.82749939],
      [85.94277954, 19.82749939],
      [85.94249725, 19.82722092],
      [85.9422226, 19.82722282],
      [85.94194794, 19.82694435],
      [85.94083405, 19.82694435],
      [85.94055176, 19.82666588],
      [85.93888855, 19.82666969],
      [85.93860626, 19.82639122],
      [85.93776703, 19.82639122],
      [85.9375, 19.82611084],
      [85.93583679, 19.82611084],
      [85.93554688, 19.82583046],
      [85.93527985, 19.82583237],
      [85.93499756, 19.8255558],
      [85.93444824, 19.82555962],
      [85.9338913, 19.82500076],
      [85.93110657, 19.82444191],
      [85.92972565, 19.82444382],
      [85.9291687, 19.82388878],
      [85.92749786, 19.82389069],
      [85.92722321, 19.82360077],
      [85.92694092, 19.82361031],
      [85.92666626, 19.82333374],
      [85.92610931, 19.82333374],
      [85.92597961, 19.82320213],
      [85.92583466, 19.82305527],
      [85.92472076, 19.82305527],
      [85.92455292, 19.82322693],
      [85.92444611, 19.82333374],
      [85.92416382, 19.82305527],
      [85.9236145, 19.82305527],
      [85.92333221, 19.82333374],
      [85.92138672, 19.82333374],
      [85.92082977, 19.8227787],
      [85.92028046, 19.82278061],
      [85.91999054, 19.82250023],
      [85.91971588, 19.82250023],
      [85.91944122, 19.82222176],
      [85.91750336, 19.82222176],
      [85.91722107, 19.82250023],
      [85.91694641, 19.82222176],
      [85.91638947, 19.82222176],
      [85.91611481, 19.82250023],
      [85.91416931, 19.82250023],
      [85.91361237, 19.82194519],
      [85.91333008, 19.82195282],
      [85.91306305, 19.82167053],
      [85.91278076, 19.82167053],
      [85.91221619, 19.82111168],
      [85.91083527, 19.82111168],
      [85.91027832, 19.82055473],
      [85.90972137, 19.82111168],
      [85.90972137, 19.82139015],
      [85.90860748, 19.82250023],
      [85.90860748, 19.82360077],
      [85.90833282, 19.82388878],
      [85.90833282, 19.82472038],
      [85.90805817, 19.82500076],
      [85.90805054, 19.82583046],
      [85.90777588, 19.82611084],
      [85.90777588, 19.82694435],
      [85.90750122, 19.82722092],
      [85.90750122, 19.82777977],
      [85.90721893, 19.82805443],
      [85.90721893, 19.82860947],
      [85.90694427, 19.82888794],
      [85.90693665, 19.82944107],
      [85.90666962, 19.82973099],
      [85.90666962, 19.82999992],
      [85.90694427, 19.8302784],
      [85.90638733, 19.83083344],
      [85.90638733, 19.83110809],
      [85.90583038, 19.83166885],
      [85.90583038, 19.83194351],
      [85.90555573, 19.83222198],
      [85.90499878, 19.83222198],
      [85.90460205, 19.83182716],
      [85.90444183, 19.83166885],
      [85.90416718, 19.83167076],
      [85.90416718, 19.82973099],
      [85.90471649, 19.82917023],
      [85.90471649, 19.82888985],
      [85.90500641, 19.82860947],
      [85.90499878, 19.82805443],
      [85.90528107, 19.82777977],
      [85.90528107, 19.82500076],
      [85.90556335, 19.82472038],
      [85.90555573, 19.82305527],
      [85.90583038, 19.82278061],
      [85.90583038, 19.82222176],
      [85.90611267, 19.82194519],
      [85.90611267, 19.82111168],
      [85.90638733, 19.82083321],
      [85.90638733, 19.82055473],
      [85.90777588, 19.81916618],
      [85.90834045, 19.81858063],
      [85.9080658, 19.8178997],
      [85.90721893, 19.81777954],
      [85.90596771, 19.81768036],
      [85.90338898, 19.81699944],
      [85.90084076, 19.81604958],
      [85.89813995, 19.81524086],
      [85.89514923, 19.81456184],
      [85.893013, 19.81402016],
      [85.8897171, 19.81278038],
      [85.88861084, 19.81278038],
      [85.88833618, 19.8125],
      [85.88639069, 19.8125],
      [85.8861084, 19.81221962],
      [85.88555908, 19.81222153],
      [85.88527679, 19.81194305],
      [85.88500214, 19.81195068],
      [85.88471985, 19.81166267],
      [85.8841629, 19.81166649],
      [85.88388824, 19.81138802],
      [85.88360596, 19.81138992],
      [85.8833313, 19.81110954],
      [85.88249969, 19.81111145],
      [85.88222504, 19.81083107],
      [85.88083649, 19.81083107],
      [85.8805542, 19.81055641],
      [85.87944794, 19.81056023],
      [85.8793335, 19.81044769],
      [85.87917328, 19.81027985],
      [85.87889099, 19.81027985],
      [85.8786087, 19.80999947],
      [85.8769455, 19.80999947],
      [85.87666321, 19.8097229],
      [85.87611389, 19.8097229],
      [85.8758316, 19.80944443],
      [85.87472534, 19.80944443],
      [85.87444305, 19.80916595],
      [85.8741684, 19.80916977],
      [85.87388611, 19.80888939],
      [85.87277985, 19.80888939],
      [85.87249756, 19.80859756],
      [85.8722229, 19.80861282],
      [85.87194824, 19.80833244],
      [85.8713913, 19.80833244],
      [85.87110901, 19.80805588],
      [85.8702774, 19.80805969],
      [85.87000275, 19.80777931],
      [85.86972046, 19.80777931],
      [85.8694458, 19.80750084],
      [85.86805725, 19.80750084],
      [85.86778259, 19.80721855],
      [85.8666687, 19.80722237],
      [85.86638641, 19.80694389],
      [85.86555481, 19.80694389],
      [85.86528015, 19.80666733],
      [85.86499786, 19.80666924],
      [85.86472321, 19.80639076],
      [85.86444092, 19.80639076],
      [85.86416626, 19.80611038],
      [85.86360931, 19.80611038],
      [85.86305237, 19.80555916],
      [85.86194611, 19.80555916],
      [85.86165619, 19.80528069],
      [85.86055756, 19.80528069],
      [85.85943604, 19.80417061],
      [85.85888672, 19.80389023],
      [85.85861206, 19.80360985],
      [85.85749817, 19.80361176],
      [85.85562897, 19.80340576],
      [85.85475922, 19.80258751],
      [85.85391998, 19.80273056],
      [85.8527832, 19.80273056],
      [85.85106659, 19.80165291],
      [85.84951019, 19.80165291],
      [85.84908295, 19.80111122],
      [85.84751129, 19.80070114],
      [85.8466568, 19.80124092],
      [85.84552002, 19.80124092],
      [85.8445282, 19.80056],
      [85.8431015, 19.80042839],
      [85.84139252, 19.79948044],
      [85.8395462, 19.7992115],
      [85.83798218, 19.79879951],
      [85.83750153, 19.79805565],
      [85.83444214, 19.79722023],
      [85.83346558, 19.79723167],
      [85.83190918, 19.79684448],
      [85.83112335, 19.79664993],
      [85.82900238, 19.79570007],
      [85.82685089, 19.79556465],
      [85.82492828, 19.79479408],
      [85.82234955, 19.7940731],
      [85.82006836, 19.79312515],
      [85.81838226, 19.79236031],
      [85.8169632, 19.79236031],
      [85.81321716, 19.79145813],
      [85.81027985, 19.79027557],
      [85.80999756, 19.79055977],
      [85.80916595, 19.79055977],
      [85.8088913, 19.79027939],
      [85.80832672, 19.79027939],
      [85.80722046, 19.78917122],
      [85.8069458, 19.78917122],
      [85.80665588, 19.78889084],
      [85.80528259, 19.78889084],
      [85.80375671, 19.78812027],
      [85.80119324, 19.78730965],
      [85.79934692, 19.78689957],
      [85.7977829, 19.7863903],
      [85.79750061, 19.78610992],
      [85.79524231, 19.78536606],
      [85.79296112, 19.78428078],
      [85.79138947, 19.7836113],
      [85.78900909, 19.78355408],
      [85.78843689, 19.78288078],
      [85.78701019, 19.7824707],
      [85.78527832, 19.78277969],
      [85.7841568, 19.78277969],
      [85.78388977, 19.78250122],
      [85.78333282, 19.78250122],
      [85.7831955, 19.78236008],
      [85.78305817, 19.78222275],
      [85.78250122, 19.78222275],
      [85.78221893, 19.78194427],
      [85.78193665, 19.78194237],
      [85.78138733, 19.78250122],
      [85.78138733, 19.78277779],
      [85.78083038, 19.78333282],
      [85.78083038, 19.7836113],
      [85.78055573, 19.78388977],
      [85.78083038, 19.78444481],
      [85.78083038, 19.78472137],
      [85.78055573, 19.78499985],
      [85.77993011, 19.78504181],
      [85.77983856, 19.78499794],
      [85.77964783, 19.78490639],
      [85.77964783, 19.78403282],
      [85.77971649, 19.78360939],
      [85.77972412, 19.78333282],
      [85.77999878, 19.78305626],
      [85.77999878, 19.78055954],
      [85.77971649, 19.78027916],
      [85.77971649, 19.7797184],
      [85.77944183, 19.77944374],
      [85.77916718, 19.77944374],
      [85.77889252, 19.77916718],
      [85.77861786, 19.77916908],
      [85.7783432, 19.77889061],
      [85.77805328, 19.77889061],
      [85.77722168, 19.77806091],
      [85.77694702, 19.77806091],
      [85.77639008, 19.77750015],
      [85.77555847, 19.77750015],
      [85.77527618, 19.77721977],
      [85.77500153, 19.77722168],
      [85.77471924, 19.77694511],
      [85.77444458, 19.77722168],
      [85.77388763, 19.77722168],
      [85.77333069, 19.77666855],
      [85.77305603, 19.77667046],
      [85.77249146, 19.7761097],
      [85.77222443, 19.7761116],
      [85.77194214, 19.77589989],
      [85.77027893, 19.77583313],
      [85.76999664, 19.77555466],
      [85.76972198, 19.77556038],
      [85.76944733, 19.77528],
      [85.76917267, 19.77528],
      [85.76889038, 19.77499962],
      [85.76721954, 19.77499962],
      [85.76693726, 19.77472115],
      [85.76667023, 19.77472305],
      [85.76638794, 19.77444649],
      [85.76611328, 19.77472305],
      [85.76438141, 19.77413177],
      [85.76281738, 19.77359009],
      [85.76027679, 19.77277946],
      [85.76000214, 19.77248955],
      [85.75971985, 19.77249908],
      [85.75944519, 19.77222252],
      [85.75861359, 19.77222252],
      [85.7583313, 19.77194214],
      [85.75805664, 19.77194214],
      [85.75777435, 19.77166748],
      [85.75749207, 19.77166939],
      [85.75694275, 19.77111053],
      [85.75554657, 19.77111053],
      [85.75527954, 19.77083015],
      [85.75499725, 19.7705555],
      [85.75444794, 19.77056122],
      [85.75258636, 19.76982117],
      [85.75131226, 19.76928139],
      [85.74833679, 19.76861191],
      [85.7480545, 19.76833344],
      [85.74749756, 19.76833344],
      [85.74739075, 19.76822662],
      [85.7472229, 19.76805496],
      [85.74666595, 19.76806259],
      [85.7463913, 19.7677803],
      [85.74610901, 19.7677803],
      [85.74582672, 19.76750183],
      [85.74555206, 19.76750183],
      [85.7452774, 19.76722145],
      [85.74472046, 19.76722145],
      [85.7444458, 19.76694489],
      [85.74305725, 19.76694489],
      [85.74250031, 19.76638985],
      [85.7416687, 19.76638985],
      [85.7408371, 19.76555824],
      [85.74054718, 19.76555824],
      [85.74028015, 19.76527977],
      [85.73860931, 19.76527977],
      [85.7360611, 19.76436806],
      [85.73420715, 19.76355934],
      [85.73264313, 19.76275063],
      [85.73065186, 19.7620697],
      [85.72805786, 19.76166534],
      [85.7277832, 19.76139069],
      [85.72750854, 19.76139069],
      [85.72721863, 19.76111031],
      [85.72638702, 19.76111031],
      [85.72583008, 19.76054955],
      [85.72555542, 19.76055336],
      [85.72528076, 19.7602787],
      [85.72444153, 19.76028061],
      [85.72055817, 19.75889015],
      [85.71611023, 19.75749016],
      [85.71555328, 19.75749969],
      [85.71527863, 19.75722122],
      [85.71499634, 19.75722122],
      [85.71472168, 19.75694466],
      [85.71444702, 19.75694466],
      [85.71416473, 19.75666618],
      [85.71389008, 19.75667191],
      [85.71305847, 19.75583267],
      [85.71069336, 19.75563812],
      [85.70897675, 19.75510025],
      [85.70767975, 19.75472069],
      [85.70639801, 19.75445175],
      [85.70582581, 19.75445175],
      [85.70555878, 19.75416946],
      [85.70527649, 19.75416946],
      [85.70500183, 19.75388908],
      [85.70443726, 19.75388908],
      [85.70313263, 19.75331116],
      [85.70198822, 19.75275993],
      [85.70027924, 19.75222206],
      [85.69999695, 19.75194359],
      [85.69972229, 19.75194359],
      [85.69889069, 19.75111198],
      [85.6986084, 19.75111198],
      [85.69851685, 19.75101852],
      [85.69833374, 19.75083351],
      [85.69721985, 19.75083351],
      [85.69704437, 19.75065422],
      [85.69694519, 19.75055695],
      [85.69610596, 19.75056076],
      [85.6958313, 19.75028038],
      [85.69526672, 19.75028038],
      [85.69499969, 19.75],
      [85.69444275, 19.75],
      [85.6938858, 19.74945068],
      [85.69304657, 19.74945068],
      [85.69277954, 19.7491703],
      [85.69249725, 19.7491703],
      [85.6922226, 19.74888992],
      [85.69194794, 19.74888992],
      [85.69167328, 19.74860954],
      [85.6911087, 19.74861145],
      [85.69083405, 19.74833298],
      [85.6902771, 19.74833298],
      [85.68999481, 19.74811172],
      [85.68945313, 19.74806023],
      [85.68926239, 19.74787521],
      [85.68916321, 19.74777794],
      [85.68860626, 19.74777985],
      [85.6883316, 19.74749947],
      [85.6875, 19.74749947],
      [85.68723297, 19.74722099],
      [85.68694305, 19.74722481],
      [85.6866684, 19.74694443],
      [85.68611145, 19.74694443],
      [85.68583679, 19.74666405],
      [85.68499756, 19.74666977],
      [85.6847229, 19.74638939],
      [85.68444824, 19.74638939],
      [85.68248749, 19.74596977],
      [85.68049622, 19.74570084],
      [85.67888641, 19.74472046],
      [85.6783371, 19.74416733],
      [85.67804718, 19.74417114],
      [85.67778015, 19.74389076],
      [85.67694092, 19.74389076],
      [85.67582703, 19.74278069],
      [85.67527771, 19.74278069],
      [85.67500305, 19.74250984],
      [85.67388916, 19.74250984],
      [85.67360687, 19.74222183],
      [85.67277527, 19.74222183],
      [85.67250061, 19.74194527],
      [85.67193604, 19.7419529],
      [85.67166901, 19.74167252],
      [85.67138672, 19.74167252],
      [85.67111206, 19.74139023],
      [85.67082977, 19.74139023],
      [85.67056274, 19.74111176],
      [85.67028046, 19.74111176],
      [85.66999817, 19.74083328],
      [85.66916656, 19.74083328],
      [85.66889191, 19.74055481],
      [85.66860962, 19.74055481],
      [85.66833496, 19.74027824],
      [85.66777802, 19.74028015],
      [85.66768646, 19.7401886],
      [85.66722107, 19.7397213],
      [85.66666412, 19.7397213],
      [85.66638947, 19.73944473],
      [85.66471863, 19.73944855],
      [85.66443634, 19.73916817],
      [85.66361237, 19.73916817],
      [85.66305542, 19.73860741],
      [85.66278076, 19.73833275],
      [85.66278076, 19.73805618],
      [85.66249847, 19.73777962],
      [85.66249847, 19.73666954],
      [85.66221619, 19.73637962],
      [85.66222382, 19.73555756],
      [85.66249847, 19.73528099],
      [85.66221619, 19.73500061],
      [85.66221619, 19.73472023],
      [85.66194153, 19.73444366],
      [85.66194153, 19.73388863],
      [85.66166687, 19.73361015],
      [85.66166687, 19.73333359],
      [85.66027832, 19.73194313],
      [85.65999603, 19.73195076],
      [85.65972137, 19.73167038],
      [85.65944672, 19.73195076],
      [85.65888977, 19.73195076],
      [85.65805817, 19.73110962],
      [85.65750122, 19.73110962],
      [85.65666962, 19.73195076],
      [85.65555573, 19.73194313],
      [85.65528107, 19.7322216],
      [85.65472412, 19.7322216],
      [85.65444183, 19.73195076],
      [85.65361023, 19.73195076],
      [85.6533432, 19.73167038],
      [85.65277863, 19.73167038],
      [85.65194702, 19.73083115],
      [85.65166473, 19.73083115],
      [85.65110779, 19.73027802],
      [85.65083313, 19.73027992],
      [85.65055847, 19.73000145],
      [85.65000153, 19.7294445],
      [85.64971924, 19.7294445],
      [85.64944458, 19.72972107],
      [85.64888763, 19.72972107],
      [85.64861298, 19.7294445],
      [85.64749908, 19.7294445],
      [85.64722443, 19.72916603],
      [85.64694214, 19.72916985],
      [85.64666748, 19.72888947],
      [85.64638519, 19.72888947],
      [85.64611053, 19.7286129],
      [85.64554596, 19.7286129],
      [85.64527893, 19.7283287],
      [85.64472198, 19.72833252],
      [85.64444733, 19.72805595],
      [85.64417267, 19.72806168],
      [85.64398193, 19.72787094],
      [85.64389038, 19.72777748],
      [85.64360809, 19.72777939],
      [85.64332581, 19.72751045],
      [85.64305878, 19.72751045],
      [85.64266205, 19.72710228],
      [85.64250183, 19.72694397],
      [85.64221954, 19.72694397],
      [85.64111328, 19.72583389],
      [85.64055634, 19.72583389],
      [85.64027405, 19.72555542],
      [85.6397171, 19.72555923],
      [85.63944244, 19.72528076],
      [85.63916779, 19.72528076],
      [85.63833618, 19.72500038],
      [85.63777924, 19.72500038],
      [85.63722229, 19.72443962],
      [85.63694763, 19.72444534],
      [85.63666534, 19.72416878],
      [85.63639069, 19.72417068],
      [85.63582611, 19.72360992],
      [85.63527679, 19.72361183],
      [85.63500214, 19.72333336],
      [85.63444519, 19.72333336],
      [85.6341629, 19.72305679],
      [85.63388824, 19.72333336],
      [85.63305664, 19.72249985],
      [85.63166809, 19.72249985],
      [85.6313858, 19.72221947],
      [85.63083649, 19.72222137],
      [85.62944794, 19.72083282],
      [85.62916565, 19.72083282],
      [85.62897491, 19.72063637],
      [85.62889099, 19.72055435],
      [85.6277771, 19.72056007],
      [85.62750244, 19.72027969],
      [85.62722015, 19.72027969],
      [85.62695313, 19.72000122],
      [85.62666321, 19.72000122],
      [85.62638855, 19.71972084],
      [85.62611389, 19.71972275],
      [85.6258316, 19.71944427],
      [85.62555695, 19.71944427],
      [85.62527466, 19.7191658],
      [85.62499237, 19.71916962],
      [85.62481689, 19.7189846],
      [85.62473297, 19.71889114],
      [85.62444305, 19.71889114],
      [85.6241684, 19.71861076],
      [85.62388611, 19.71861076],
      [85.62277985, 19.71750069],
      [85.62249756, 19.71750069],
      [85.6222229, 19.71722031],
      [85.61937714, 19.71676636],
      [85.6186676, 19.71635818],
      [85.61781311, 19.71581841],
      [85.61638641, 19.71528053],
      [85.61532593, 19.7148304],
      [85.61434174, 19.71442032],
      [85.61333466, 19.7136116],
      [85.61305237, 19.71333313],
      [85.61250305, 19.71333313],
      [85.61222076, 19.71305466],
      [85.61194611, 19.71305466],
      [85.6111145, 19.71222305],
      [85.60892487, 19.71161652],
      [85.60769653, 19.71083832],
      [85.60749817, 19.71055984],
      [85.60721588, 19.71027946],
      [85.60666656, 19.71027946],
      [85.60639191, 19.71000099],
      [85.60610962, 19.71000099],
      [85.60582733, 19.70972061],
      [85.60500336, 19.70972252],
      [85.60472107, 19.70944595],
      [85.60444641, 19.70944595],
      [85.60388947, 19.7088871],
      [85.60360718, 19.70889091],
      [85.60321808, 19.70849419],
      [85.60305786, 19.70833588],
      [85.60277557, 19.70833588],
      [85.60227203, 19.70783043],
      [85.60194397, 19.70750046],
      [85.60111237, 19.70750046],
      [85.60082245, 19.70722008],
      [85.60055542, 19.70722198],
      [85.60015869, 19.70682716],
      [85.59999847, 19.70666695],
      [85.59944153, 19.70667076],
      [85.59916687, 19.70639992],
      [85.59889221, 19.70639992],
      [85.59864807, 19.70615005],
      [85.59805298, 19.70611191],
      [85.59727478, 19.70558739],
      [85.59649658, 19.70506859],
      [85.59616089, 19.70471954],
      [85.59604645, 19.7044239],
      [85.59541321, 19.70441628],
      [85.5949173, 19.70428085],
      [85.59401703, 19.70407867],
      [85.59361267, 19.70360947],
      [85.59333038, 19.70361137],
      [85.59305573, 19.7033329],
      [85.59278107, 19.70334053],
      [85.59249878, 19.70306015],
      [85.59194946, 19.70306015],
      [85.59175873, 19.70286942],
      [85.59166718, 19.70277786],
      [85.59139252, 19.70277977],
      [85.5908432, 19.70222282],
      [85.59055328, 19.70222282],
      [85.59027863, 19.70194435],
      [85.58999634, 19.70194435],
      [85.58916473, 19.70111084],
      [85.58888245, 19.70111084],
      [85.58869934, 19.70092583],
      [85.58860779, 19.70083427],
      [85.58833313, 19.70083427],
      [85.5881958, 19.70069504],
      [85.58805847, 19.7005558],
      [85.58721924, 19.70055962],
      [85.58693695, 19.7002697],
      [85.58666992, 19.70027733],
      [85.58638763, 19.70000076],
      [85.58611298, 19.70000076],
      [85.58582306, 19.69971848],
      [85.58555603, 19.69972229],
      [85.58528137, 19.69944382],
      [85.58499908, 19.69944382],
      [85.58472443, 19.69916725],
      [85.58444214, 19.69916916],
      [85.58389282, 19.69861031],
      [85.58361053, 19.69861031],
      [85.58333588, 19.69832993],
      [85.58305359, 19.69833374],
      [85.58277893, 19.69805527],
      [85.58249664, 19.69805908],
      [85.58194733, 19.69750023],
      [85.58166504, 19.69750023],
      [85.58118439, 19.69728279],
      [85.58062744, 19.69742012],
      [85.57943726, 19.6972599],
      [85.57830048, 19.69671059],
      [85.57688141, 19.69617271],
      [85.57545471, 19.69536018],
      [85.57460022, 19.69536018],
      [85.57250214, 19.69444084],
      [85.57221985, 19.69416428],
      [85.57195282, 19.69417],
      [85.5716629, 19.69388962],
      [85.57138824, 19.69388962],
      [85.57110596, 19.69361115],
      [85.57081604, 19.69361115],
      [85.56999969, 19.69277954],
      [85.56916809, 19.69277954],
      [85.5688858, 19.69250107],
      [85.56861115, 19.69250107],
      [85.56833649, 19.69222069],
      [85.56804657, 19.69250107],
      [85.56667328, 19.69111061],
      [85.56639099, 19.69111061],
      [85.56582642, 19.69055939],
      [85.5652771, 19.69000053],
      [85.56500244, 19.69000053],
      [85.56472015, 19.68972015],
      [85.5644455, 19.68972015],
      [85.56416321, 19.68944359],
      [85.56388855, 19.68944359],
      [85.56361389, 19.68916702],
      [85.5633316, 19.68917274],
      [85.56305695, 19.68889046],
      [85.56276703, 19.68889046],
      [85.56252289, 19.68863487],
      [85.56223297, 19.6883316],
      [85.5616684, 19.68833351],
      [85.56111145, 19.68777847],
      [85.56054688, 19.68778038],
      [85.56027985, 19.68750191],
      [85.5597229, 19.68750191],
      [85.55944824, 19.68722153],
      [85.5588913, 19.68722153],
      [85.55833435, 19.68666649],
      [85.55805206, 19.6866684],
      [85.55750275, 19.68610764],
      [85.5569458, 19.68611145],
      [85.55666351, 19.68583298],
      [85.55638885, 19.68583298],
      [85.55583191, 19.68527985],
      [85.55555725, 19.68527985],
      [85.55527496, 19.68499947],
      [85.55500031, 19.68499947],
      [85.55473328, 19.68472099],
      [85.55388641, 19.6847229],
      [85.55361176, 19.68444443],
      [85.5533371, 19.68444443],
      [85.55278015, 19.68388939],
      [85.55249786, 19.68388939],
      [85.55222321, 19.68361092],
      [85.55194092, 19.68361092],
      [85.54971313, 19.68250084],
      [85.54944611, 19.68250084],
      [85.54888916, 19.68194008],
      [85.5486145, 19.6819458],
      [85.54833221, 19.68166542],
      [85.54805756, 19.68167114],
      [85.5477829, 19.68139076],
      [85.54722595, 19.68139076],
      [85.54693604, 19.68111038],
      [85.54252625, 19.67981911],
      [85.53954315, 19.67819023],
      [85.53639221, 19.67638969],
      [85.53362274, 19.67556953],
      [85.53092194, 19.6746254],
      [85.52892303, 19.67272949],
      [85.52536774, 19.6715107],
      [85.52279663, 19.66988945],
      [85.51893616, 19.66802979],
      [85.51638031, 19.66723061],
      [85.51352692, 19.66546059],
      [85.51139832, 19.66423988],
      [85.50926208, 19.66316032],
      [85.50694275, 19.66167259],
      [85.5027771, 19.65916824],
      [85.50138855, 19.65888977],
      [85.50110626, 19.65888977],
      [85.50026703, 19.65805817],
      [85.49944305, 19.65805817],
      [85.4991684, 19.65777969],
      [85.49888611, 19.65777969],
      [85.49859619, 19.65750122],
      [85.49833679, 19.65750122],
      [85.49804688, 19.65722084],
      [85.49777985, 19.65722275],
      [85.49749756, 19.65694427],
      [85.4972229, 19.65694427],
      [85.49694824, 19.65666771],
      [85.4963913, 19.65666962],
      [85.49610901, 19.65639114],
      [85.49582672, 19.65639114],
      [85.49555969, 19.65611076],
      [85.4952774, 19.65611076],
      [85.49472046, 19.65555954],
      [85.4944458, 19.65555954],
      [85.49388885, 19.65500069],
      [85.49360657, 19.65500069],
      [85.49276733, 19.65417099],
      [85.49250031, 19.65417099],
      [85.49239349, 19.65405846],
      [85.49222565, 19.6538887],
      [85.4916687, 19.65389061],
      [85.49138641, 19.65361023],
      [85.49111176, 19.65361023],
      [85.49054718, 19.65306091],
      [85.49028015, 19.65306091],
      [85.48999786, 19.65278053],
      [85.48916626, 19.65278053],
      [85.4888916, 19.65250015],
      [85.48860931, 19.65250015],
      [85.48832703, 19.65223122],
      [85.48805237, 19.65223122],
      [85.48777771, 19.65195084],
      [85.48694611, 19.65195084],
      [85.48665619, 19.65167046],
      [85.48555756, 19.65167046],
      [85.4852829, 19.65139008],
      [85.48500061, 19.65139008],
      [85.48486328, 19.65124893],
      [85.48472595, 19.6511116],
      [85.48416901, 19.6511116],
      [85.48388672, 19.65083122],
      [85.48332977, 19.65083122],
      [85.48305511, 19.65055466],
      [85.48278046, 19.65055466],
      [85.48249817, 19.65027809],
      [85.48221588, 19.65028],
      [85.48194122, 19.64999962],
      [85.48166656, 19.64999962],
      [85.48139191, 19.64972115],
      [85.48110962, 19.64972115],
      [85.48055267, 19.64916611],
      [85.48027802, 19.64916611],
      [85.48000336, 19.64888954],
      [85.47972107, 19.64888954],
      [85.47944641, 19.64861107],
      [85.47888947, 19.64861107],
      [85.47860718, 19.6483326],
      [85.47833252, 19.6483326],
      [85.47805786, 19.64805603],
      [85.4777832, 19.64806175],
      [85.47744751, 19.64772224],
      [85.47693634, 19.64722061],
      [85.47638702, 19.64722252],
      [85.47611237, 19.64694405],
      [85.47499847, 19.64694405],
      [85.47444153, 19.64639091],
      [85.47389221, 19.64639091],
      [85.47360992, 19.646101],
      [85.47333527, 19.64611053],
      [85.47305298, 19.64583397],
      [85.47277832, 19.64583397],
      [85.47250366, 19.6455555],
      [85.47222137, 19.64555931],
      [85.47194672, 19.64528084],
      [85.47138977, 19.64528084],
      [85.47101593, 19.64490509],
      [85.47083282, 19.64471817],
      [85.47027588, 19.64472198],
      [85.46971893, 19.64416885],
      [85.46943665, 19.64417076],
      [85.46888733, 19.64360809],
      [85.46749878, 19.64361191],
      [85.46722412, 19.64333344],
      [85.46694183, 19.64333344],
      [85.46683502, 19.64322472],
      [85.46666718, 19.64305687],
      [85.46639252, 19.64306068],
      [85.46615601, 19.64282036],
      [85.4658432, 19.64249992],
      [85.46555328, 19.64249992],
      [85.46527863, 19.64222145],
      [85.46472168, 19.64222145],
      [85.46444702, 19.64194679],
      [85.46416473, 19.64194679],
      [85.46389008, 19.64166451],
      [85.46360779, 19.64167023],
      [85.46333313, 19.64138985],
      [85.46277618, 19.64138985],
      [85.46248627, 19.64110947],
      [85.46221924, 19.64111137],
      [85.46166992, 19.64055443],
      [85.46111298, 19.64056015],
      [85.46083069, 19.64029121],
      [85.4597168, 19.64029121],
      [85.45942688, 19.63999939],
      [85.45888519, 19.63999939],
      [85.45833588, 19.63944435],
      [85.45805359, 19.63944435],
      [85.45749664, 19.63888931],
      [85.45722198, 19.63889122],
      [85.45694733, 19.63861084],
      [85.4566803, 19.63889122],
      [85.45582581, 19.63889122],
      [85.45500183, 19.63805962],
      [85.45471954, 19.63805962],
      [85.45443726, 19.63778114],
      [85.45417023, 19.63778114],
      [85.45388794, 19.63750076],
      [85.45361328, 19.63750076],
      [85.45333099, 19.63722038],
      [85.45305634, 19.63722229],
      [85.45277405, 19.63694382],
      [85.45249939, 19.63694382],
      [85.45222473, 19.63666725],
      [85.45111084, 19.63667107],
      [85.45083618, 19.63639069],
      [85.44972229, 19.63639069],
      [85.44944763, 19.63611031],
      [85.44796753, 19.63562012],
      [85.4478302, 19.63563156],
      [85.44647217, 19.63495636],
      [85.44576263, 19.63475227],
      [85.44497681, 19.63455009],
      [85.4438858, 19.63389015],
      [85.44361115, 19.63416672],
      [85.44333649, 19.63416672],
      [85.44166565, 19.63249969],
      [85.44139099, 19.63249969],
      [85.4411087, 19.63222122],
      [85.44055176, 19.63222313],
      [85.4402771, 19.63194466],
      [85.43916321, 19.63194466],
      [85.43888855, 19.63166618],
      [85.43860626, 19.63166809],
      [85.43805695, 19.63112068],
      [85.43776703, 19.63112068],
      [85.4375, 19.63083076],
      [85.43722534, 19.63083267],
      [85.43275452, 19.62917519],
      [85.4310379, 19.62823105],
      [85.42904663, 19.62742043],
      [85.42549896, 19.62552071],
      [85.42378998, 19.62457275],
      [85.42222595, 19.62416649],
      [85.41919708, 19.62306976],
      [85.41777802, 19.62198639],
      [85.41635132, 19.62117958],
      [85.41471863, 19.62027931],
      [85.41443634, 19.62027931],
      [85.41416931, 19.62000084],
      [85.41388702, 19.62000084],
      [85.41361237, 19.61972046],
      [85.41305542, 19.61972237],
      [85.41047668, 19.6183548],
      [85.40919495, 19.61767769],
      [85.40721893, 19.61667061],
      [85.40486908, 19.61561012],
      [85.40344238, 19.61466789],
      [85.40159607, 19.61384964],
      [85.40045166, 19.61317635],
      [85.39888763, 19.61250114],
      [85.39787292, 19.61166191],
      [85.39743805, 19.61125565],
      [85.39573669, 19.61071014],
      [85.39431, 19.61017227],
      [85.39260101, 19.60909081],
      [85.39103699, 19.60855293],
      [85.38976288, 19.60801125],
      [85.3887558, 19.60705948],
      [85.38719177, 19.60638046],
      [85.38555908, 19.60611153],
      [85.38471985, 19.60527992],
      [85.38446045, 19.60527992],
      [85.38431549, 19.60514832],
      [85.3841629, 19.60499954],
      [85.38414764, 19.60472107],
      [85.38249969, 19.60305786],
      [85.38140106, 19.60305977],
      [85.38083649, 19.60250092],
      [85.38054657, 19.60250092],
      [85.37999725, 19.60194016],
      [85.37944794, 19.60194588],
      [85.37916565, 19.6016674],
      [85.3786087, 19.60167122],
      [85.3777771, 19.60083008],
      [85.37750244, 19.6008358],
      [85.37722015, 19.60055351],
      [85.37666321, 19.60056114],
      [85.37638855, 19.60028076],
      [85.37610626, 19.60028076],
      [85.37563324, 19.59980011],
      [85.37555695, 19.59972191],
      [85.375, 19.59972191],
      [85.37472534, 19.59944344],
      [85.3741684, 19.59945107],
      [85.37388611, 19.59917068],
      [85.37361145, 19.59917068],
      [85.3730545, 19.59860992],
      [85.37249756, 19.59805107],
      [85.37194824, 19.59805489],
      [85.37166595, 19.59777832],
      [85.37110901, 19.59778023],
      [85.37082672, 19.59749985],
      [85.3702774, 19.59749985],
      [85.36794281, 19.59664917],
      [85.36672211, 19.59576988],
      [85.36553192, 19.59543037],
      [85.3640976, 19.59461975],
      [85.36267853, 19.59338951],
      [85.36011505, 19.59231949],
      [85.35868835, 19.59123611],
      [85.35639191, 19.59000015],
      [85.35582733, 19.58945084],
      [85.35555267, 19.58945084],
      [85.35527802, 19.58917046],
      [85.35500336, 19.58917046],
      [85.3549118, 19.589077],
      [85.35472107, 19.58888626],
      [85.35444641, 19.58888626],
      [85.35416412, 19.5886116],
      [85.35388947, 19.5886116],
      [85.35333252, 19.58805656],
      [85.35305786, 19.58805656],
      [85.35277557, 19.58777618],
      [85.35250092, 19.58778],
      [85.35193634, 19.58722115],
      [85.35166931, 19.58722305],
      [85.35138702, 19.58694649],
      [85.35111237, 19.58694649],
      [85.35083008, 19.5866642],
      [85.35056305, 19.58666992],
      [85.35028076, 19.58638954],
      [85.34999847, 19.58638954],
      [85.34971619, 19.58666992],
      [85.34944153, 19.58666992],
      [85.34889221, 19.58611107],
      [85.34860992, 19.58611107],
      [85.34749603, 19.58500099],
      [85.34749603, 19.58472061],
      [85.34722137, 19.58472252],
      [85.34694672, 19.58444214],
      [85.34666443, 19.58444214],
      [85.34555817, 19.58333206],
      [85.34527588, 19.58333206],
      [85.34471893, 19.58277702],
      [85.3422699, 19.58205032],
      [85.34085083, 19.58135986],
      [85.33860779, 19.5802803],
      [85.33805847, 19.57971764],
      [85.33750153, 19.57972145],
      [85.33666992, 19.57888985],
      [85.33638763, 19.57888985],
      [85.33583069, 19.57832909],
      [85.33555603, 19.5783329],
      [85.33528137, 19.57805824],
      [85.33499908, 19.57777977],
      [85.3347168, 19.57777977],
      [85.33444214, 19.57749939],
      [85.33222198, 19.57528114],
      [85.33167267, 19.57528114],
      [85.33139038, 19.57500076],
      [85.33110809, 19.57500076],
      [85.33082581, 19.57472038],
      [85.33027649, 19.57472229],
      [85.33000183, 19.57444572],
      [85.32971954, 19.57444572],
      [85.32888794, 19.57361031],
      [85.32861328, 19.57361031],
      [85.32749939, 19.57250023],
      [85.3272171, 19.57250023],
      [85.32666779, 19.57195091],
      [85.32639313, 19.57195091],
      [85.32554626, 19.57110977],
      [85.32499695, 19.57111168],
      [85.32472229, 19.5708313],
      [85.32416534, 19.5708313],
      [85.3236084, 19.57027817],
      [85.32277679, 19.57028008],
      [85.32249451, 19.56999969],
      [85.32221985, 19.56972122],
      [85.32221985, 19.56944466],
      [85.3208313, 19.56805611],
      [85.32055664, 19.56806946],
      [85.32026672, 19.56777954],
      [85.31999969, 19.56777954],
      [85.31944275, 19.56722069],
      [85.3188858, 19.5672226],
      [85.31861115, 19.56694412],
      [85.31773376, 19.56694221],
      [85.31658936, 19.56653595],
      [85.31555176, 19.56528091],
      [85.31555176, 19.56500053],
      [85.3152771, 19.56471825],
      [85.3152771, 19.56444359],
      [85.31500244, 19.56416893],
      [85.31388855, 19.56417084],
      [85.3133316, 19.56361008],
      [85.31305695, 19.56361198],
      [85.31277466, 19.56333351],
      [85.3125, 19.56333351],
      [85.31194305, 19.56277847],
      [85.3116684, 19.56278038],
      [85.31138611, 19.5625],
      [85.30944824, 19.5625],
      [85.3088913, 19.56194115],
      [85.30860901, 19.56194687],
      [85.3077774, 19.56111145],
      [85.3077774, 19.56083488],
      [85.30716705, 19.56039047],
      [85.30666351, 19.55944633],
      [85.3061142, 19.55944633],
      [85.30583191, 19.55916405],
      [85.30526733, 19.55916977],
      [85.30446625, 19.55900955],
      [85.30361176, 19.55833054],
      [85.30194092, 19.55666733],
      [85.3013916, 19.55667114],
      [85.30110931, 19.55639076],
      [85.30082703, 19.55639076],
      [85.30055237, 19.55611038],
      [85.30027771, 19.55611038],
      [85.29998779, 19.55583954],
      [85.29943848, 19.55583954],
      [85.29753113, 19.55472183],
      [85.29528809, 19.55361176],
      [85.29244232, 19.55159569],
      [85.29045105, 19.55051041],
      [85.28902435, 19.54970169],
      [85.28778076, 19.54888916],
      [85.28721619, 19.54833031],
      [85.28694153, 19.54833412],
      [85.28666687, 19.54805756],
      [85.28639221, 19.54805946],
      [85.28639221, 19.54778099],
      [85.28527832, 19.54667091],
      [85.28501129, 19.54667091],
      [85.28444672, 19.54611015],
      [85.28416443, 19.54611206],
      [85.28360748, 19.54555321],
      [85.28333282, 19.54556084],
      [85.28305817, 19.54528046],
      [85.28277588, 19.54528046],
      [85.28166962, 19.54417038],
      [85.28111267, 19.54417038],
      [85.28095245, 19.54400826],
      [85.28055573, 19.54361153],
      [85.28028107, 19.54361153],
      [85.27916718, 19.54249954],
      [85.27887726, 19.54249954],
      [85.27861023, 19.54222107],
      [85.27805328, 19.54222107],
      [85.27666473, 19.54083252],
      [85.27639008, 19.54084015],
      [85.27610779, 19.54056168],
      [85.27456665, 19.53973961],
      [85.27222443, 19.53777885],
      [85.27194214, 19.53777885],
      [85.27141571, 19.53725052],
      [85.27055359, 19.5363884],
      [85.27027893, 19.5363903],
      [85.26917267, 19.53528023],
      [85.26917267, 19.53499985],
      [85.26889038, 19.53499985],
      [85.26860809, 19.53470993],
      [85.26833344, 19.53472137],
      [85.26694489, 19.53333282],
      [85.26667023, 19.53333282],
      [85.26499939, 19.5316658],
      [85.26444244, 19.53166962],
      [85.26416779, 19.53139114],
      [85.26333618, 19.53139114],
      [85.26304626, 19.53111076],
      [85.26277924, 19.53111076],
      [85.2611084, 19.52944183],
      [85.26083374, 19.52944374],
      [85.25777435, 19.52638817],
      [85.25694275, 19.52638817],
      [85.2568512, 19.52629662],
      [85.25666809, 19.5261116],
      [85.2563858, 19.5261116],
      [85.25611115, 19.52583313],
      [85.25583649, 19.52583313],
      [85.2555542, 19.52555275],
      [85.25527954, 19.52527809],
      [85.25499725, 19.52527809],
      [85.25332642, 19.52361107],
      [85.25305176, 19.52361107],
      [85.25222015, 19.52276993],
      [85.25166321, 19.52277756],
      [85.25138855, 19.52249718],
      [85.25110626, 19.52250099],
      [85.25087738, 19.5222683],
      [85.25055695, 19.52222252],
      [85.25, 19.52166748],
      [85.24973297, 19.52166939],
      [85.24822998, 19.5201683],
      [85.2472229, 19.51917076],
      [85.24694824, 19.51917076],
      [85.2465744, 19.51879692],
      [85.2463913, 19.51861382],
      [85.24610901, 19.51861382],
      [85.24583435, 19.51833344],
      [85.24555206, 19.51833344],
      [85.24500275, 19.5177784],
      [85.24472046, 19.5177803],
      [85.24360657, 19.51667023],
      [85.24333191, 19.51667023],
      [85.24305725, 19.51638985],
      [85.24278259, 19.51638985],
      [85.24111176, 19.51472282],
      [85.23999786, 19.51472282],
      [85.23972321, 19.51444435],
      [85.23944092, 19.51444435],
      [85.23916626, 19.51416588],
      [85.2388916, 19.5141716],
      [85.23860931, 19.51388931],
      [85.23832703, 19.51388931],
      [85.23777771, 19.51333046],
      [85.23777771, 19.5130558],
      [85.23722076, 19.51250076],
      [85.23694611, 19.51250076],
      [85.23638916, 19.51194382],
      [85.2361145, 19.51194382],
      [85.23555756, 19.51138878],
      [85.2352829, 19.51139069],
      [85.23472595, 19.51082993],
      [85.23444366, 19.51083374],
      [85.23352051, 19.50991058],
      [85.23305511, 19.50944519],
      [85.23278046, 19.50945091],
      [85.23194122, 19.50860977],
      [85.23166656, 19.50861168],
      [85.23139191, 19.50833321],
      [85.23110962, 19.50833321],
      [85.23027802, 19.50749969],
      [85.23000336, 19.50749969],
      [85.22972107, 19.50722122],
      [85.22944641, 19.50722313],
      [85.22916412, 19.50694656],
      [85.22888947, 19.50694656],
      [85.22833252, 19.50638962],
      [85.22776794, 19.50638962],
      [85.22732544, 19.50594139],
      [85.22721863, 19.50583267],
      [85.22694397, 19.50583267],
      [85.22583008, 19.5047226],
      [85.22556305, 19.50473022],
      [85.22470856, 19.5038929],
      [85.22470856, 19.50361061],
      [85.22444153, 19.50333023],
      [85.22416687, 19.50333214],
      [85.22333527, 19.50250053],
      [85.22277832, 19.50250053],
      [85.22222137, 19.50194168],
      [85.22194672, 19.50194359],
      [85.22083282, 19.50083351],
      [85.22083282, 19.50055504],
      [85.22055817, 19.50028038],
      [85.22027588, 19.50028038],
      [85.22000122, 19.50000191],
      [85.21971893, 19.50000191],
      [85.21943665, 19.49971962],
      [85.21916962, 19.49972153],
      [85.21888733, 19.49944496],
      [85.21861267, 19.49945068],
      [85.21806335, 19.49888992],
      [85.21778107, 19.49888992],
      [85.21611023, 19.49722099],
      [85.21583557, 19.4972229],
      [85.21527863, 19.49666786],
      [85.21499634, 19.49666977],
      [85.2141571, 19.49583054],
      [85.21389008, 19.49583244],
      [85.21360779, 19.49555779],
      [85.21333313, 19.49555969],
      [85.21276855, 19.49500084],
      [85.21250153, 19.49500084],
      [85.21193695, 19.49444008],
      [85.21166992, 19.4944458],
      [85.21153259, 19.49430656],
      [85.21138763, 19.49416733],
      [85.21111298, 19.49417114],
      [85.21055603, 19.49361038],
      [85.21028137, 19.49361038],
      [85.20833588, 19.49167061],
      [85.20804596, 19.49167061],
      [85.20777893, 19.49139023],
      [85.20749664, 19.49139023],
      [85.20667267, 19.4905529],
      [85.20639038, 19.49055481],
      [85.20527649, 19.48999977],
      [85.20359802, 19.48833275],
      [85.20333099, 19.48833275],
      [85.20277405, 19.48777771],
      [85.20249939, 19.48777962],
      [85.2022171, 19.48749924],
      [85.20194244, 19.48749924],
      [85.20139313, 19.48693848],
      [85.20111084, 19.4869442],
      [85.20083618, 19.48666573],
      [85.20055389, 19.48666573],
      [85.20027924, 19.48638916],
      [85.19999695, 19.48638916],
      [85.19832611, 19.48472023],
      [85.19805908, 19.48472214],
      [85.19777679, 19.48444366],
      [85.19721985, 19.48444366],
      [85.19611359, 19.48333359],
      [85.1958313, 19.48333359],
      [85.19472504, 19.4822216],
      [85.19416809, 19.4822216],
      [85.1938858, 19.48194695],
      [85.19361115, 19.48195076],
      [85.19277954, 19.48110962],
      [85.19249725, 19.48111153],
      [85.1911087, 19.47972488],
      [85.19083405, 19.47972488],
      [85.18972015, 19.47861099],
      [85.1894455, 19.47861099],
      [85.18916321, 19.47833252],
      [85.18888855, 19.47833252],
      [85.18861389, 19.47805405],
      [85.1883316, 19.47805977],
      [85.18776703, 19.47750092],
      [85.1875, 19.47750092],
      [85.18723297, 19.47722054],
      [85.18694305, 19.47722244],
      [85.18638611, 19.4766674],
      [85.18611145, 19.47667122],
      [85.18583679, 19.47638893],
      [85.18556213, 19.47639275],
      [85.18527985, 19.47611046],
      [85.18499756, 19.47611046],
      [85.18444824, 19.47556114],
      [85.18416595, 19.47556114],
      [85.18360901, 19.47500038],
      [85.18302155, 19.47435951],
      [85.18131256, 19.4735527],
      [85.17722321, 19.47000122],
      [85.17694092, 19.47000122],
      [85.17665863, 19.46972084],
      [85.1763916, 19.46972275],
      [85.17610931, 19.46944427],
      [85.17555237, 19.46944427],
      [85.17500305, 19.46888924],
      [85.17472076, 19.46889114],
      [85.17415619, 19.46833038],
      [85.17388916, 19.4683342],
      [85.17333221, 19.46777534],
      [85.17305756, 19.46778107],
      [85.17166901, 19.46639061],
      [85.17138672, 19.46639061],
      [85.16971588, 19.46471977],
      [85.16944122, 19.46472168],
      [85.16916656, 19.46444321],
      [85.16889191, 19.46445084],
      [85.16860962, 19.46417046],
      [85.16832733, 19.46417046],
      [85.16722107, 19.46305275],
      [85.16694641, 19.46305466],
      [85.16661072, 19.46271896],
      [85.16611481, 19.46222115],
      [85.16555786, 19.46222115],
      [85.16499329, 19.46171761],
      [85.16471863, 19.46167183],
      [85.16442871, 19.46138954],
      [85.16416931, 19.46138954],
      [85.16388702, 19.46110916],
      [85.16361237, 19.46110916],
      [85.16306305, 19.46056175],
      [85.16278076, 19.46056175],
      [85.16221619, 19.45999908],
      [85.16179657, 19.45946121],
      [85.15849304, 19.45721054],
      [85.15499878, 19.45416451],
      [85.15471649, 19.45417023],
      [85.15444183, 19.45388985],
      [85.15416718, 19.45388985],
      [85.15277863, 19.45249939],
      [85.15248871, 19.45249939],
      [85.15221405, 19.45227051],
      [85.15194702, 19.45222473],
      [85.14638519, 19.44833374],
      [85.14611053, 19.44805527],
      [85.14583588, 19.4480629],
      [85.14221954, 19.44527817],
      [85.14167023, 19.44472122],
      [85.14138794, 19.44472313],
      [85.14083099, 19.44416618],
      [85.14025879, 19.44416809],
      [85.13944244, 19.44333076],
      [85.13555908, 19.44000053],
      [85.13388824, 19.43832016],
      [85.13201141, 19.43688393],
      [85.12916565, 19.43444443],
      [85.12805176, 19.43333244],
      [85.1277771, 19.43333244],
      [85.12638855, 19.43194389],
      [85.12611389, 19.43194389],
      [85.1258316, 19.43166733],
      [85.12554932, 19.43166924],
      [85.12296295, 19.4300499],
      [85.12182617, 19.42922974],
      [85.12068939, 19.42814827],
      [85.11869812, 19.42654037],
      [85.11727142, 19.42543983],
      [85.11585236, 19.42381859],
      [85.11443329, 19.42246056],
      [85.11250305, 19.42111015],
      [85.11166382, 19.42027855],
      [85.11165619, 19.42000008],
      [85.11083221, 19.41916847],
      [85.10785675, 19.41669273],
      [85.10558319, 19.41480064],
      [85.1047287, 19.41377068],
      [85.10430145, 19.4132328],
      [85.10380554, 19.41255569],
      [85.10324097, 19.41188049],
      [85.10280609, 19.41134071],
      [85.10231018, 19.41073036],
      [85.10195923, 19.41018867],
      [85.10131836, 19.40950966],
      [85.10108948, 19.40917015],
      [85.1006012, 19.40842438],
      [85.10045624, 19.40794945],
      [85.09989166, 19.4075489],
      [85.09954071, 19.40700912],
      [85.09916687, 19.40638924],
      [85.0983429, 19.40557289],
      [85.0983429, 19.40527916],
      [85.09805298, 19.40500069],
      [85.09805298, 19.40472031],
      [85.09722137, 19.4038887],
      [85.09722137, 19.40361023],
      [85.09694672, 19.40332985],
      [85.09694672, 19.40305519],
      [85.09638977, 19.40250015],
      [85.09638977, 19.40195084],
      [85.09610748, 19.40167046],
      [85.09610748, 19.40139008],
      [85.09388733, 19.39916992],
      [85.09361267, 19.39916992],
      [85.09361267, 19.39888954],
      [85.09278107, 19.39805984],
      [85.09278107, 19.39777946],
      [85.09220886, 19.39722061],
      [85.09222412, 19.39666748],
      [85.09166718, 19.39611053],
      [85.09166718, 19.39556122],
      [85.09139252, 19.39528275],
      [85.09139252, 19.39500046],
      [85.09111023, 19.39472008],
      [85.09083557, 19.39472008],
      [85.09023285, 19.39265442],
      [85.08740997, 19.38991737],
      [85.08612823, 19.38775063],
      [85.08526611, 19.38585091],
      [85.0851059, 19.38446426],
      [85.08389282, 19.38276672],
      [85.08304596, 19.38195038],
      [85.08175659, 19.38100433],
      [85.08055878, 19.38027954],
      [85.07971954, 19.3794384],
      [85.07971954, 19.37916756],
      [85.07776642, 19.37722015],
      [85.07611084, 19.37888908],
      [85.07554626, 19.37888908],
      [85.07446289, 19.37778091],
      [85.07417297, 19.37778091],
      [85.07389069, 19.37750053],
      [85.0736084, 19.37750053],
      [85.07332611, 19.37722015],
      [85.07305908, 19.37722206],
      [85.07277679, 19.37694359],
      [85.07250214, 19.37694359],
      [85.0716629, 19.37611198],
      [85.0716629, 19.37583351],
      [85.0688858, 19.37305641],
      [85.06861115, 19.37306023],
      [85.06804657, 19.37249947],
      [85.06777954, 19.37249947],
      [85.06749725, 19.37220955],
      [85.0663681, 19.37176132],
      [85.066185, 19.37166214],
      [85.06562042, 19.36904335],
      [85.06506348, 19.36691666],
      [85.06516266, 19.36689758],
      [85.06594086, 19.36675453],
      [85.06622314, 19.36648369],
      [85.06622314, 19.36580658],
      [85.06600952, 19.36553383],
      [85.06536865, 19.36499214],
      [85.06472015, 19.36444473],
      [85.06415558, 19.36414528],
      [85.063797, 19.36380959],
      [85.06310272, 19.36367035],
      [85.0625, 19.36333275],
      [85.0616684, 19.36333275],
      [85.06157684, 19.3632412],
      [85.06138611, 19.36305618],
      [85.06083679, 19.36305809],
      [85.06054688, 19.36277962],
      [85.06027985, 19.36277962],
      [85.05999756, 19.36250114],
      [85.0597229, 19.36250114],
      [85.05945587, 19.36222076],
      [85.05916595, 19.36222267],
      [85.05860901, 19.36166763],
      [85.05832672, 19.36166954],
      [85.05805206, 19.36139107],
      [85.05750275, 19.36139107],
      [85.05722046, 19.36111069],
      [85.0569458, 19.36111069],
      [85.05665588, 19.36083031],
      [85.05638885, 19.36083412],
      [85.05583191, 19.36027527],
      [85.05555725, 19.36000061],
      [85.05555725, 19.35972023],
      [85.05527496, 19.35944557],
      [85.05500031, 19.35944557],
      [85.05472565, 19.35916519],
      [85.05332947, 19.35917091],
      [85.05303955, 19.35889053],
      [85.05278015, 19.35889053],
      [85.05249786, 19.35861015],
      [85.05222321, 19.35861206],
      [85.05166626, 19.35805321],
      [85.0513916, 19.35806084],
      [85.05082703, 19.35750008],
      [85.05027771, 19.35750008],
      [85.05000305, 19.3572216],
      [85.04944611, 19.35723114],
      [85.04915619, 19.35695076],
      [85.04860687, 19.35695076],
      [85.04833221, 19.35667038],
      [85.04805756, 19.35667038],
      [85.04768372, 19.35629654],
      [85.04750061, 19.35610962],
      [85.04722595, 19.35611153],
      [85.04694366, 19.35583115],
      [85.04666901, 19.35583115],
      [85.04657745, 19.3557415],
      [85.04638672, 19.35555649],
      [85.04611206, 19.3555603],
      [85.04582977, 19.35528183],
      [85.04556274, 19.35528183],
      [85.04499817, 19.35472107],
      [85.04472351, 19.35472107],
      [85.04389191, 19.35388947],
      [85.04360962, 19.35388947],
      [85.04305267, 19.35333061],
      [85.04277802, 19.35333252],
      [85.04222107, 19.35277748],
      [85.04194641, 19.35277939],
      [85.04109955, 19.35193825],
      [85.04027557, 19.35194397],
      [85.03971863, 19.35138893],
      [85.03943634, 19.35139084],
      [85.03929901, 19.35124397],
      [85.03916931, 19.35111046],
      [85.03888702, 19.35111046],
      [85.03853607, 19.35076141],
      [85.03833008, 19.35055542],
      [85.03806305, 19.35055923],
      [85.03796387, 19.35046577],
      [85.03778076, 19.35028076],
      [85.03749847, 19.35028076],
      [85.03721619, 19.35000038],
      [85.03639221, 19.35000038],
      [85.03610992, 19.34971809],
      [85.03555298, 19.34972191],
      [85.03500366, 19.34916878],
      [85.03444672, 19.34860992],
      [85.03416443, 19.34861183],
      [85.03360748, 19.34805679],
      [85.03333282, 19.34805679],
      [85.03305817, 19.34777832],
      [85.03250122, 19.34778023],
      [85.03222656, 19.34749985],
      [85.03166962, 19.34749985],
      [85.03138733, 19.34721947],
      [85.03111267, 19.34722137],
      [85.03055573, 19.34666443],
      [85.03028107, 19.34667015],
      [85.02915955, 19.34556007],
      [85.02889252, 19.34556007],
      [85.0283432, 19.34500122],
      [85.02805328, 19.34500122],
      [85.02777863, 19.34472084],
      [85.02749634, 19.34472466],
      [85.02722168, 19.34444237],
      [85.02666473, 19.34444237],
      [85.02639008, 19.3441658],
      [85.02610016, 19.34416962],
      [85.02583313, 19.34389114],
      [85.02555847, 19.34389114],
      [85.02443695, 19.34278107],
      [85.02416992, 19.34278107],
      [85.02388763, 19.34250069],
      [85.02361298, 19.34250069],
      [85.02305603, 19.34194183],
      [85.02278137, 19.34194374],
      [85.02264404, 19.34180641],
      [85.02249908, 19.34166718],
      [85.0222168, 19.3416729],
      [85.02166748, 19.34111023],
      [85.02138519, 19.34111023],
      [85.02083588, 19.34055519],
      [85.02054596, 19.34056282],
      [85.01999664, 19.34000015],
      [85.01944733, 19.34000015],
      [85.01917267, 19.33972168],
      [85.01889038, 19.33972168],
      [85.01833344, 19.33916664],
      [85.01805878, 19.33916664],
      [85.01784515, 19.33895683],
      [85.01776886, 19.33894157],
      [85.01750183, 19.33888817],
      [85.01692963, 19.33833122],
      [85.01667023, 19.33833313],
      [85.01638794, 19.33805275],
      [85.01611328, 19.33777809],
      [85.01583099, 19.33777809],
      [85.01555634, 19.33749962],
      [85.01526642, 19.33749962],
      [85.0150528, 19.33727455],
      [85.01499939, 19.33722115],
      [85.01472473, 19.33722305],
      [85.01416779, 19.33666611],
      [85.01389313, 19.33666992],
      [85.01361084, 19.33638954],
      [85.01333618, 19.33638954],
      [85.01167297, 19.33472061],
      [85.01139069, 19.33472252],
      [85.01129913, 19.33463287],
      [85.01083374, 19.33416748],
      [85.01055908, 19.33416939],
      [85.01027679, 19.33389091],
      [85.01000214, 19.33389091],
      [85.00971985, 19.33361053],
      [85.0091629, 19.33361053],
      [85.00888824, 19.33333015],
      [85.00861359, 19.33333588],
      [85.0083313, 19.33305359],
      [85.00805664, 19.33306122],
      [85.00694275, 19.3319397],
      [85.00666809, 19.33194351],
      [85.0063858, 19.33166695],
      [85.00583649, 19.33167076],
      [85.00554657, 19.33139038],
      [85.00527954, 19.33139038],
      [85.0047226, 19.33082962],
      [85.00444794, 19.33083534],
      [85.0036087, 19.32999992],
      [85.00332642, 19.32999992],
      [85.00013733, 19.32866859],
      [85.00013733, 19.32875061],
      [84.99986267, 19.32875061],
      [84.99986267, 19.32847023],
      [84.99958038, 19.32847023],
      [84.99958038, 19.32819366],
      [84.99903107, 19.32819366],
      [84.99903107, 19.3279171],
      [84.99846649, 19.32792282],
      [84.99846649, 19.32764053],
      [84.99764252, 19.32764053],
      [84.99764252, 19.32709122],
      [84.99736023, 19.32709122],
      [84.99736023, 19.32681274],
      [84.9970932, 19.32681274],
      [84.9970932, 19.32625008],
      [84.99652863, 19.32625008],
      [84.99652863, 19.3259697],
      [84.99597168, 19.3259716],
      [84.99597168, 19.32569504],
      [84.99569702, 19.32570076],
      [84.99569702, 19.32541847],
      [84.99514008, 19.32541847],
      [84.99514008, 19.32513809],
      [84.99485779, 19.32513809],
      [84.99485779, 19.32485962],
      [84.99430847, 19.32486153],
      [84.99430847, 19.32402992],
      [84.99375153, 19.32402992],
      [84.99375153, 19.32374954],
      [84.99346924, 19.32374954],
      [84.99346924, 19.32347107],
      [84.99263763, 19.32347298],
      [84.99263763, 19.3231945],
      [84.99236298, 19.3231945],
      [84.99236298, 19.32291794],
      [84.99153137, 19.32291985],
      [84.99153137, 19.32263947],
      [84.99124908, 19.32263947],
      [84.99124908, 19.32208061],
      [84.99069214, 19.32208252],
      [84.99069214, 19.32180786],
      [84.99041748, 19.32180977],
      [84.99041748, 19.32152939],
      [84.99015045, 19.32152939],
      [84.99015045, 19.320961],
      [84.98958588, 19.32097244],
      [84.98958588, 19.3204174],
      [84.98847198, 19.32042122],
      [84.98847198, 19.32014084],
      [84.98735809, 19.32014084],
      [84.98735809, 19.31986046],
      [84.98680878, 19.31986046],
      [84.98680878, 19.31958008],
      [84.98625183, 19.3195858],
      [84.98625183, 19.31930351],
      [84.98568726, 19.31931114],
      [84.98568726, 19.31903076],
      [84.98513794, 19.31903076],
      [84.98513794, 19.31847],
      [84.98486328, 19.31847382],
      [84.98486328, 19.31819344],
      [84.98430634, 19.31819344],
      [84.98430634, 19.3176384],
      [84.98401642, 19.3176403],
      [84.98401642, 19.31735992],
      [84.98374939, 19.31736374],
      [84.98374939, 19.31708145],
      [84.98347473, 19.31708145],
      [84.98347473, 19.31680489],
      [84.98291779, 19.31680489],
      [84.98291779, 19.31652832],
      [84.98236084, 19.31653023],
      [84.98236084, 19.31624985],
      [84.98152924, 19.31624985],
      [84.98152924, 19.31596947],
      [84.98097229, 19.31597137],
      [84.98097229, 19.31569481],
      [84.98041534, 19.31569481],
      [84.98041534, 19.31541634],
      [84.98014069, 19.31542015],
      [84.98014069, 19.31458282],
      [84.97958374, 19.31458282],
      [84.97958374, 19.31402779],
      [84.97875214, 19.31402969],
      [84.97875214, 19.31347084],
      [84.97819519, 19.31347275],
      [84.97819519, 19.31319427],
      [84.97763824, 19.31319427],
      [84.97763824, 19.3129158],
      [84.97735596, 19.31292152],
      [84.97735596, 19.31263924],
      [84.97680664, 19.31263924],
      [84.97680664, 19.31209183],
      [84.97598267, 19.31209183],
      [84.97598267, 19.31181145],
      [84.97569275, 19.31181145],
      [84.97569275, 19.31152916],
      [84.9751358, 19.31152916],
      [84.9751358, 19.31097031],
      [84.97486115, 19.31097221],
      [84.97486115, 19.31041718],
      [84.97458649, 19.31041908],
      [84.97458649, 19.31014061],
      [84.97429657, 19.31014061],
      [84.97429657, 19.30986023],
      [84.97402954, 19.30986023],
      [84.97402954, 19.30957985],
      [84.97319794, 19.30958366],
      [84.97319794, 19.30930519],
      [84.97292328, 19.30931091],
      [84.97292328, 19.309021],
      [84.97264099, 19.30902863],
      [84.97264099, 19.30875015],
      [84.97207642, 19.30875015],
      [84.97207642, 19.30846977],
      [84.9715271, 19.30847168],
      [84.9715271, 19.30819511],
      [84.97125244, 19.30820084],
      [84.97125244, 19.30792046],
      [84.97041321, 19.30792046],
      [84.97041321, 19.30764008],
      [84.97013855, 19.30764008],
      [84.97013855, 19.3073597],
      [84.96986389, 19.3073616],
      [84.96986389, 19.30708313],
      [84.9695816, 19.30708313],
      [84.9695816, 19.30652809],
      [84.96930695, 19.30653],
      [84.96930695, 19.30624962],
      [84.96875, 19.30624962],
      [84.96875, 19.3059597],
      [84.96847534, 19.30597305],
      [84.96847534, 19.30569649],
      [84.9679184, 19.30569649],
      [84.9679184, 19.3054142],
      [84.96763611, 19.30541992],
      [84.96763611, 19.30486107],
      [84.96736145, 19.30486107],
      [84.96736145, 19.30430984],
      [84.96708679, 19.30430984],
      [84.96708679, 19.30402946],
      [84.96679688, 19.30402946],
      [84.96679688, 19.3037529],
      [84.96652985, 19.3037529],
      [84.96652985, 19.30347061],
      [84.9668045, 19.30347443],
      [84.9668045, 19.30291748],
      [84.96708679, 19.30291939],
      [84.96708679, 19.30236053],
      [84.96736145, 19.30236053],
      [84.96736145, 19.30208015],
      [84.96763611, 19.30208206],
      [84.96763611, 19.30152702],
      [84.9679184, 19.30153275],
      [84.9679184, 19.30125046],
      [84.96652985, 19.30125046],
      [84.96652985, 19.30097008],
      [84.9668045, 19.30097008],
      [84.9668045, 19.30069351],
      [84.96708679, 19.30069351],
      [84.96708679, 19.30041695],
      [84.96736145, 19.30042267],
      [84.96736145, 19.30015182],
      [84.96763611, 19.30015182],
      [84.96763611, 19.29957771],
      [84.9679184, 19.29958344],
      [84.9679184, 19.29930496],
      [84.96848297, 19.29931068],
      [84.96848297, 19.2990284],
      [84.96901703, 19.2990284],
      [84.96901703, 19.29846954],
      [84.96736145, 19.29846954],
      [84.96736145, 19.29874992],
      [84.96708679, 19.29874992],
      [84.96708679, 19.2991581],
      [84.96708679, 19.29931068],
      [84.9668045, 19.29930496],
      [84.9668045, 19.29958344],
      [84.96624756, 19.29958344],
      [84.96624756, 19.29986191],
      [84.96598053, 19.29986191],
      [84.96598053, 19.30015182],
      [84.96569824, 19.30015182],
      [84.96569824, 19.30042267],
      [84.96541595, 19.30041695],
      [84.96541595, 19.30097008],
      [84.9651413, 19.30097008],
      [84.9651413, 19.30125046],
      [84.96485901, 19.30125046],
      [84.96485901, 19.30153275],
      [84.96457672, 19.30153275],
      [84.96457672, 19.30125046],
      [84.96430206, 19.30125046],
      [84.96430206, 19.30097008],
      [84.9640274, 19.30097008],
      [84.9640274, 19.30069542],
      [84.96375275, 19.3006916],
      [84.96375275, 19.30041695],
      [84.96347046, 19.30042267],
      [84.96347046, 19.30015182],
      [84.9631958, 19.30015182],
      [84.9631958, 19.29986191],
      [84.96291351, 19.29986191],
      [84.96291351, 19.29958344],
      [84.96263885, 19.29958344],
      [84.96263885, 19.29930496],
      [84.96235657, 19.29931068],
      [84.96235657, 19.2990284],
      [84.96180725, 19.2990284],
      [84.96180725, 19.29874992],
      [84.96151733, 19.29874992],
      [84.96151733, 19.29846954],
      [84.96125031, 19.29847145],
      [84.96125031, 19.29819489],
      [84.96097565, 19.29819489],
      [84.96097565, 19.29791641],
      [84.9604187, 19.29791832],
      [84.9604187, 19.29763985],
      [84.96013641, 19.29763985],
      [84.96013641, 19.29735947],
      [84.95986176, 19.29736137],
      [84.95986176, 19.2970829],
      [84.95903015, 19.2970829],
      [84.95903015, 19.29680634],
      [84.95847321, 19.29680824],
      [84.95847321, 19.29652977],
      [84.95819092, 19.29652977],
      [84.95819092, 19.29624939],
      [84.9576416, 19.29624939],
      [84.9576416, 19.29597092],
      [84.95652771, 19.29597282],
      [84.95652771, 19.29569435],
      [84.95569611, 19.29569435],
      [84.95569611, 19.29541779],
      [84.95540619, 19.29541969],
      [84.95540619, 19.29514122],
      [84.95485687, 19.29514122],
      [84.95485687, 19.29458046],
      [84.95458221, 19.29458427],
      [84.95458221, 19.29430771],
      [84.95404053, 19.29430962],
      [84.95404053, 19.2940197],
      [84.95375061, 19.29402733],
      [84.95375061, 19.29375076],
      [84.95347595, 19.29375076],
      [84.95347595, 19.29347038],
      [84.95319366, 19.29347229],
      [84.95319366, 19.29291725],
      [84.95291901, 19.29292107],
      [84.95291901, 19.29264069],
      [84.95207977, 19.29264069],
      [84.95207977, 19.29236031],
      [84.95180511, 19.29236031],
      [84.95180511, 19.29208565],
      [84.95124817, 19.29208565],
      [84.95124817, 19.29180336],
      [84.95096588, 19.29181099],
      [84.95096588, 19.29153061],
      [84.95041656, 19.29153061],
      [84.95041656, 19.29125023],
      [84.94985962, 19.29125023],
      [84.94985962, 19.29097939],
      [84.94930267, 19.29097939],
      [84.94930267, 19.29070091],
      [84.94902802, 19.29070091],
      [84.94902802, 19.29042053],
      [84.94875336, 19.29042053],
      [84.94875336, 19.29014015],
      [84.94847107, 19.29014015],
      [84.94847107, 19.28985977],
      [84.94791412, 19.28986359],
      [84.94791412, 19.28902817],
      [84.94763947, 19.28903008],
      [84.94763947, 19.28874969],
      [84.94708252, 19.28874969],
      [84.94708252, 19.28847122],
      [84.94625092, 19.28847122],
      [84.94625092, 19.28791618],
      [84.94568634, 19.28792191],
      [84.94568634, 19.28763962],
      [84.94513702, 19.28763962],
      [84.94513702, 19.28735924],
      [84.94458008, 19.28735924],
      [84.94458008, 19.28708076],
      [84.94430542, 19.28708267],
      [84.94430542, 19.28652763],
      [84.94403076, 19.28652954],
      [84.94403076, 19.28624916],
      [84.94374847, 19.28624916],
      [84.94374847, 19.28597069],
      [84.94319153, 19.2859726],
      [84.94319153, 19.28569412],
      [84.94291687, 19.28569412],
      [84.94291687, 19.28541756],
      [84.94210052, 19.28541946],
      [84.94210052, 19.28513908],
      [84.94181061, 19.28513908],
      [84.94181061, 19.28486061],
      [84.94152832, 19.28486061],
      [84.94152832, 19.28457832],
      [84.94097137, 19.28458405],
      [84.94097137, 19.28430557],
      [84.94069672, 19.28430748],
      [84.94069672, 19.28403091],
      [84.94013977, 19.28403091],
      [84.94013977, 19.28375053],
      [84.93985748, 19.28375053],
      [84.93985748, 19.28347015],
      [84.93958282, 19.28347206],
      [84.93958282, 19.28319359],
      [84.93930817, 19.28319359],
      [84.93930817, 19.28291893],
      [84.93902588, 19.28292084],
      [84.93902588, 19.28264046],
      [84.93846893, 19.28264046],
      [84.93846893, 19.28236008],
      [84.93819427, 19.28236198],
      [84.93819427, 19.28208351],
      [84.93791962, 19.28208351],
      [84.93791962, 19.28180695],
      [84.93763733, 19.28181076],
      [84.93763733, 19.28153038],
      [84.93736267, 19.28153038],
      [84.93736267, 19.28125],
      [84.93680573, 19.28125],
      [84.93680573, 19.28097153],
      [84.93624878, 19.28097153],
      [84.93624878, 19.28069687],
      [84.93596649, 19.28070068],
      [84.93596649, 19.2804203],
      [84.93567657, 19.2804203],
      [84.93567657, 19.28013992],
      [84.93541718, 19.28013992],
      [84.93541718, 19.27985954],
      [84.93514252, 19.27986145],
      [84.93514252, 19.27958488],
      [84.93486023, 19.27958488],
      [84.93486023, 19.27930641],
      [84.93430328, 19.27931023],
      [84.93430328, 19.27903938],
      [84.93402863, 19.27903938],
      [84.93402863, 19.27874947],
      [84.93374634, 19.27874947],
      [84.93374634, 19.27847099],
      [84.93347168, 19.27847481],
      [84.93347168, 19.27819252],
      [84.93291473, 19.27819252],
      [84.93291473, 19.27791405],
      [84.93235779, 19.27791977],
      [84.93235779, 19.27763939],
      [84.93208313, 19.27763939],
      [84.93208313, 19.27736092],
      [84.93152618, 19.27736092],
      [84.93152618, 19.27708054],
      [84.93125153, 19.27708244],
      [84.93125153, 19.27680588],
      [84.93096924, 19.27680969],
      [84.93096924, 19.27653122],
      [84.93068695, 19.27653122],
      [84.93068695, 19.27625275],
      [84.93041992, 19.27625275],
      [84.93041992, 19.27598],
      [84.93013763, 19.27598],
      [84.93013763, 19.27569008],
      [84.92986298, 19.27569389],
      [84.92986298, 19.27541733],
      [84.92958069, 19.27542114],
      [84.92958069, 19.27458191],
      [84.92930603, 19.27458382],
      [84.92930603, 19.27430534],
      [84.92903137, 19.27431107],
      [84.92903137, 19.27403259],
      [84.92874908, 19.27403259],
      [84.92874908, 19.27375031],
      [84.9284668, 19.27375031],
      [84.9284668, 19.27347183],
      [84.92819214, 19.27347183],
      [84.92819214, 19.27319527],
      [84.92791748, 19.2732029],
      [84.92791748, 19.27290916],
      [84.92763519, 19.27291679],
      [84.92763519, 19.27263832],
      [84.92736053, 19.27263832],
      [84.92736053, 19.27235985],
      [84.92708588, 19.27236176],
      [84.92708588, 19.27208328],
      [84.92652893, 19.27208328],
      [84.92652893, 19.27180481],
      [84.92597198, 19.27180481],
      [84.92597198, 19.27152824],
      [84.92542267, 19.27152824],
      [84.92542267, 19.27124977],
      [84.92485809, 19.27124977],
      [84.92485809, 19.27096748],
      [84.92458344, 19.2709713],
      [84.92458344, 19.27069473],
      [84.92430878, 19.27069473],
      [84.92430878, 19.27041626],
      [84.92373657, 19.27041817],
      [84.92373657, 19.27013969],
      [84.92346954, 19.27013969],
      [84.92346954, 19.26984978],
      [84.92319489, 19.26986122],
      [84.92319489, 19.26958275],
      [84.92292023, 19.26958275],
      [84.92292023, 19.26930618],
      [84.92263794, 19.26930809],
      [84.92263794, 19.26875114],
      [84.92236328, 19.26875114],
      [84.92236328, 19.26847076],
      [84.92208099, 19.26847267],
      [84.92208099, 19.2681942],
      [84.92180634, 19.2681942],
      [84.92180634, 19.26791763],
      [84.92124939, 19.26791954],
      [84.92124939, 19.26764107],
      [84.9209671, 19.26764107],
      [84.9209671, 19.26708031],
      [84.92069244, 19.26708412],
      [84.92069244, 19.26647949],
      [84.92069244, 19.26597214],
      [84.92041779, 19.26597214],
      [84.92041779, 19.26569557],
      [84.91986084, 19.26569557],
      [84.91986084, 19.2654171],
      [84.91958618, 19.26542091],
      [84.91958618, 19.26514053],
      [84.91905212, 19.2651329],
      [84.91902924, 19.26486206],
      [84.91874695, 19.26486206],
      [84.91874695, 19.26458549],
      [84.91832733, 19.26458549],
      [84.91819763, 19.26458549],
      [84.91819763, 19.26430321],
      [84.91794586, 19.26430321],
      [84.91570282, 19.26431084],
      [84.91570282, 19.26403999],
      [84.9154129, 19.26403999],
      [84.9154129, 19.26375008],
      [84.91401672, 19.26375008],
      [84.91401672, 19.2634697],
      [84.91347504, 19.2634716],
      [84.91347504, 19.26319313],
      [84.91319275, 19.26320076],
      [84.91319275, 19.26292038],
      [84.91291809, 19.26292038],
      [84.91291809, 19.26264191],
      [84.9126358, 19.26264191],
      [84.9126358, 19.26235962],
      [84.91236115, 19.26236153],
      [84.91236115, 19.26208115],
      [84.91124725, 19.26208115],
      [84.91124725, 19.26236153],
      [84.91042328, 19.26235962],
      [84.91042328, 19.26264191],
      [84.9098587, 19.26264191],
      [84.9098587, 19.26292038],
      [84.9090271, 19.26292038],
      [84.9090271, 19.26320076],
      [84.90847015, 19.26319313],
      [84.90847015, 19.2634716],
      [84.90820313, 19.2634697],
      [84.90820313, 19.26431084],
      [84.90791321, 19.26430321],
      [84.90791321, 19.26708412],
      [84.90763855, 19.26708031],
      [84.90763855, 19.26930809],
      [84.90736389, 19.26930618],
      [84.90736389, 19.26986122],
      [84.90625, 19.26986122],
      [84.90625, 19.26958275],
      [84.90569305, 19.26958275],
      [84.90569305, 19.26930618],
      [84.9054184, 19.26930809],
      [84.9054184, 19.26847076],
      [84.90513611, 19.26847267],
      [84.90513611, 19.2681942],
      [84.90486145, 19.2681942],
      [84.90486145, 19.26791763],
      [84.90458679, 19.26791954],
      [84.90458679, 19.26708031],
      [84.90486145, 19.26708412],
      [84.90486145, 19.26680565],
      [84.90513611, 19.26680946],
      [84.90513611, 19.26653099],
      [84.9054184, 19.26653099],
      [84.9054184, 19.26625061],
      [84.90570068, 19.26625061],
      [84.90569305, 19.2634716],
      [84.90597534, 19.2634716],
      [84.90597534, 19.26319313],
      [84.90625, 19.26320076],
      [84.90625, 19.26264191],
      [84.90735626, 19.26264191],
      [84.90735626, 19.26235962],
      [84.90763855, 19.26236153],
      [84.90763855, 19.26208115],
      [84.9081955, 19.26208115],
      [84.9081955, 19.26152802],
      [84.90847015, 19.26153183],
      [84.90847015, 19.26124954],
      [84.90957642, 19.26124954],
      [84.90957642, 19.26097107],
      [84.91041565, 19.26097107],
      [84.91041565, 19.2606945],
      [84.91152954, 19.2606945],
      [84.91152954, 19.26041603],
      [84.91236115, 19.26042175],
      [84.91236877, 19.26013947],
      [84.9126358, 19.26013947],
      [84.9126358, 19.25958061],
      [84.91236115, 19.25958252],
      [84.91236115, 19.25930595],
      [84.91179657, 19.25931168],
      [84.91179657, 19.25902939],
      [84.91152954, 19.25902939],
      [84.91152954, 19.25875092],
      [84.91124725, 19.25875092],
      [84.91124725, 19.25847054],
      [84.9109726, 19.25847244],
      [84.9109726, 19.25819397],
      [84.91041565, 19.25819397],
      [84.91041565, 19.2579174],
      [84.91014099, 19.2579174],
      [84.91014099, 19.25763893],
      [84.9098587, 19.25764084],
      [84.9098587, 19.25736046],
      [84.90957642, 19.25736046],
      [84.90957642, 19.25707817],
      [84.90930176, 19.25708389],
      [84.90930176, 19.25680542],
      [84.9090271, 19.25681114],
      [84.9090271, 19.25653076],
      [84.90873718, 19.25653076],
      [84.90873718, 19.25625038],
      [84.90847015, 19.25625038],
      [84.90847015, 19.25597],
      [84.9081955, 19.25597191],
      [84.9081955, 19.25569534],
      [84.90791321, 19.25570107],
      [84.90791321, 19.25542068],
      [84.90763855, 19.25542068],
      [84.90763855, 19.2551403],
      [84.90735626, 19.2551403],
      [84.90735626, 19.25457954],
      [84.90680695, 19.25458336],
      [84.90680695, 19.25430679],
      [84.90652466, 19.25430679],
      [84.90652466, 19.25402832],
      [84.90625, 19.25403023],
      [84.90625, 19.25374985],
      [84.90597534, 19.25374985],
      [84.90597534, 19.25319672],
      [84.90569305, 19.25319672],
      [84.90569305, 19.25291443],
      [84.9054184, 19.25292015],
      [84.9054184, 19.25263977],
      [84.90486145, 19.25263977],
      [84.90486145, 19.25235939],
      [84.90374756, 19.2523613],
      [84.90374756, 19.25208473],
      [84.90319824, 19.25208473],
      [84.90319061, 19.25152779],
      [84.90293121, 19.25152969],
      [84.90293121, 19.25125122],
      [84.9026413, 19.25125122],
      [84.9026413, 19.25069046],
      [84.90235901, 19.25069237],
      [84.90235901, 19.2504158],
      [84.90207672, 19.25041962],
      [84.90207672, 19.25014114],
      [84.9015274, 19.25014114],
      [84.9015274, 19.24986076],
      [84.90125275, 19.24986076],
      [84.90125275, 19.24958038],
      [84.90097046, 19.24958229],
      [84.90097046, 19.24930573],
      [84.9006958, 19.24930954],
      [84.9006958, 19.24903107],
      [84.90040588, 19.24903107],
      [84.90040588, 19.24847031],
      [84.89958191, 19.24847031],
      [84.89958191, 19.24819374],
      [84.89875031, 19.24819374],
      [84.89875031, 19.24791718],
      [84.89848328, 19.2479229],
      [84.89848328, 19.24764252],
      [84.89816284, 19.24764252],
      [84.8979187, 19.24764252],
      [84.8979187, 19.24736023],
      [84.89783478, 19.24736023],
      [84.89736176, 19.24736023],
      [84.89736176, 19.24708176],
      [84.8970871, 19.24708366],
      [84.8970871, 19.24680519],
      [84.89653015, 19.24681282],
      [84.89653015, 19.24653244],
      [84.89624786, 19.24653244],
      [84.89624786, 19.24625015],
      [84.89597321, 19.24625015],
      [84.89597321, 19.24596977],
      [84.89569092, 19.24597168],
      [84.89569092, 19.24569511],
      [84.89541626, 19.24570084],
      [84.89541626, 19.24541855],
      [84.8951416, 19.24541855],
      [84.8951416, 19.24513817],
      [84.89457703, 19.24513817],
      [84.89457703, 19.24458122],
      [84.89430237, 19.24458313],
      [84.89430237, 19.24430466],
      [84.89402771, 19.24430466],
      [84.89402771, 19.24402809],
      [84.89376068, 19.24402809],
      [84.89376068, 19.24377251],
      [84.89347076, 19.24374962],
      [84.89342499, 19.24352837],
      [84.89335632, 19.24347305],
      [84.89319611, 19.24347305],
      [84.89319611, 19.24332047],
      [84.89319611, 19.24319458],
      [84.89305115, 19.24319458],
      [84.89263916, 19.24319458],
      [84.89263916, 19.24291611],
      [84.89208221, 19.24291611],
      [84.89208221, 19.24263954],
      [84.89180756, 19.24263954],
      [84.89180756, 19.24236107],
      [84.89152527, 19.24236107],
      [84.89152527, 19.2420826],
      [84.89125061, 19.2420826],
      [84.89125061, 19.24180794],
      [84.89070129, 19.24180984],
      [84.89070129, 19.24152946],
      [84.89041901, 19.24152946],
      [84.89041901, 19.24097061],
      [84.89013672, 19.24097252],
      [84.89013672, 19.24069595],
      [84.88986206, 19.24069595],
      [84.88986206, 19.24041748],
      [84.88957977, 19.24041939],
      [84.88954926, 19.24014091],
      [84.88903046, 19.24014091],
      [84.88903046, 19.23987007],
      [84.88874817, 19.23987007],
      [84.88874817, 19.23958015],
      [84.88847351, 19.23958588],
      [84.88847351, 19.23930359],
      [84.88819122, 19.23931122],
      [84.88819122, 19.23903084],
      [84.88791656, 19.23903084],
      [84.88791656, 19.23875046],
      [84.88762665, 19.23875046],
      [84.88762665, 19.23847961],
      [84.88709259, 19.23847008],
      [84.88708496, 19.23819542],
      [84.88680267, 19.2381897],
      [84.88680267, 19.23791695],
      [84.88625336, 19.23792076],
      [84.88625336, 19.23764038],
      [84.88597107, 19.23764038],
      [84.88597107, 19.23736],
      [84.88569641, 19.23736382],
      [84.88569641, 19.23708153],
      [84.88541412, 19.23708153],
      [84.88541412, 19.23680305],
      [84.88513947, 19.23681068],
      [84.88513947, 19.2365303],
      [84.88485718, 19.2365303],
      [84.88485718, 19.23624992],
      [84.88456726, 19.23624992],
      [84.88458252, 19.23583031],
      [84.88458252, 19.23569489],
      [84.8844223, 19.23569489],
      [84.88430786, 19.23569489],
      [84.88430786, 19.23541641],
      [84.88402557, 19.23541641],
      [84.88402557, 19.23533058],
      [84.88402557, 19.23513985],
      [84.88375092, 19.23513985],
      [84.88375092, 19.23485947],
      [84.88346863, 19.23486137],
      [84.88346863, 19.2345829],
      [84.88319397, 19.2345829],
      [84.88319397, 19.23430634],
      [84.88291931, 19.23431015],
      [84.88291931, 19.23373985],
      [84.88263702, 19.23374939],
      [84.88263702, 19.23347282],
      [84.88208008, 19.23347282],
      [84.88208008, 19.23319435],
      [84.88192749, 19.23319435],
      [84.88180542, 19.23319435],
      [84.88180542, 19.23291588],
      [84.88172913, 19.23291588],
      [84.88153076, 19.2329216],
      [84.88153076, 19.23263931],
      [84.88124847, 19.23263931],
      [84.88124847, 19.2322197],
      [84.88124847, 19.23208427],
      [84.88041687, 19.23208427],
      [84.88041687, 19.23152733],
      [84.88014221, 19.23152924],
      [84.88014221, 19.23125076],
      [84.87985992, 19.23125076],
      [84.87985992, 19.23097038],
      [84.87958527, 19.23097229],
      [84.87958527, 19.23069382],
      [84.87902832, 19.23069382],
      [84.87902832, 19.23041725],
      [84.87876129, 19.23041916],
      [84.87876129, 19.23014069],
      [84.87847137, 19.23014069],
      [84.87847137, 19.22957993],
      [84.87791443, 19.22958374],
      [84.87791443, 19.2290287],
      [84.87763977, 19.22903061],
      [84.87763977, 19.22875023],
      [84.87708282, 19.22875023],
      [84.87708282, 19.22846985],
      [84.87680817, 19.22847176],
      [84.87680817, 19.22819519],
      [84.87652588, 19.22820091],
      [84.87652588, 19.22792816],
      [84.87625122, 19.22792816],
      [84.87625122, 19.22764015],
      [84.87596893, 19.22764015],
      [84.87596893, 19.22707939],
      [84.87569427, 19.22708321],
      [84.87569427, 19.22680664],
      [84.87513733, 19.22680664],
      [84.87513733, 19.22652817],
      [84.87458038, 19.22653008],
      [84.87458038, 19.22597122],
      [84.87430573, 19.22597313],
      [84.87430573, 19.22569656],
      [84.87403107, 19.22569656],
      [84.87403107, 19.22541428],
      [84.87346649, 19.22542],
      [84.87346649, 19.22513962],
      [84.87291718, 19.22513962],
      [84.87291718, 19.22487068],
      [84.87262726, 19.22487068],
      [84.87262726, 19.22430992],
      [84.87236023, 19.22430992],
      [84.87236023, 19.22402954],
      [84.8720932, 19.22402954],
      [84.8720932, 19.22375107],
      [84.87152863, 19.22375107],
      [84.87152863, 19.22319031],
      [84.87124634, 19.22319221],
      [84.87124634, 19.22291756],
      [84.87097168, 19.22291946],
      [84.87097168, 19.2226429],
      [84.87069702, 19.2226429],
      [84.87069702, 19.22236061],
      [84.8704071, 19.22236061],
      [84.8704071, 19.22208023],
      [84.87014008, 19.22208214],
      [84.87014008, 19.22180557],
      [84.86985779, 19.22180557],
      [84.86985779, 19.2215271],
      [84.86902618, 19.22153282],
      [84.86902618, 19.22125053],
      [84.86875153, 19.22125053],
      [84.86875153, 19.22097015],
      [84.86846924, 19.22097015],
      [84.86846924, 19.22069359],
      [84.86791992, 19.22069359],
      [84.86791992, 19.22041702],
      [84.86763763, 19.22042274],
      [84.86763763, 19.22014046],
      [84.86736298, 19.22014046],
      [84.86736298, 19.2195816],
      [84.86680603, 19.21958351],
      [84.86680603, 19.21902847],
      [84.86653137, 19.21902847],
      [84.86653137, 19.21874046],
      [84.86624908, 19.21874809],
      [84.86624908, 19.21847153],
      [84.86597443, 19.21847153],
      [84.86597443, 19.21819496],
      [84.86569214, 19.21820068],
      [84.86569214, 19.2179184],
      [84.86541748, 19.2179184],
      [84.86541748, 19.21763992],
      [84.86514282, 19.21763992],
      [84.86514282, 19.21708107],
      [84.86486053, 19.21708298],
      [84.86486053, 19.21652985],
      [84.86458588, 19.21652985],
      [84.86458588, 19.21624947],
      [84.86429596, 19.21624947],
      [84.86429596, 19.21597099],
      [84.86402893, 19.2159729],
      [84.86402893, 19.21569443],
      [84.86374664, 19.21569824],
      [84.86374664, 19.21541977],
      [84.86347198, 19.21541977],
      [84.86347198, 19.21513939],
      [84.86319733, 19.21513939],
      [84.86319733, 19.21486092],
      [84.86291504, 19.21486092],
      [84.86291504, 19.21430779],
      [84.86264038, 19.21430969],
      [84.86264038, 19.21403122],
      [84.86207581, 19.21403122],
      [84.86207581, 19.21375084],
      [84.86152649, 19.21375084],
      [84.86152649, 19.21347046],
      [84.86125183, 19.21347237],
      [84.86125183, 19.2131958],
      [84.86096954, 19.2131958],
      [84.86096954, 19.21291733],
      [84.86068726, 19.21293068],
      [84.86068726, 19.21264076],
      [84.86042023, 19.21264076],
      [84.86042023, 19.21236038],
      [84.86013794, 19.21236038],
      [84.86013794, 19.21208],
      [84.85986328, 19.21208572],
      [84.85986328, 19.21180344],
      [84.85930634, 19.21181107],
      [84.85930634, 19.21153069],
      [84.85901642, 19.21153069],
      [84.85901642, 19.21125031],
      [84.85874939, 19.21125031],
      [84.85874939, 19.21070099],
      [84.8584671, 19.21070099],
      [84.8584671, 19.21042061],
      [84.85819244, 19.21042061],
      [84.85819244, 19.20985985],
      [84.85791779, 19.20986366],
      [84.85791779, 19.20958138],
      [84.8576355, 19.20958138],
      [84.8576355, 19.20930481],
      [84.85708618, 19.20930481],
      [84.85708618, 19.20874977],
      [84.85679626, 19.20874977],
      [84.85679626, 19.20846939],
      [84.85652924, 19.2084713],
      [84.85652924, 19.20819473],
      [84.85624695, 19.20819473],
      [84.85624695, 19.20791626],
      [84.85597229, 19.20792007],
      [84.85597229, 19.20763969],
      [84.85569763, 19.20763969],
      [84.85569763, 19.20735931],
      [84.85541534, 19.20735931],
      [84.85541534, 19.20708275],
      [84.85514069, 19.20708275],
      [84.85514069, 19.20680618],
      [84.8548584, 19.20680618],
      [84.8548584, 19.20652771],
      [84.85459137, 19.20652962],
      [84.85459137, 19.20624924],
      [84.85430908, 19.20624924],
      [84.85430908, 19.20597076],
      [84.85375214, 19.20597267],
      [84.85375214, 19.2056942],
      [84.85346985, 19.2056942],
      [84.85346985, 19.20541763],
      [84.85320282, 19.20542145],
      [84.85320282, 19.20513916],
      [84.8529129, 19.20513916],
      [84.8529129, 19.20486069],
      [84.85263824, 19.20486069],
      [84.85263824, 19.20458031],
      [84.85236359, 19.20458412],
      [84.85236359, 19.20402718],
      [84.8520813, 19.20402908],
      [84.8520813, 19.20375824],
      [84.85153198, 19.20375824],
      [84.85153198, 19.20318985],
      [84.85124969, 19.20319366],
      [84.85124969, 19.2029171],
      [84.85098267, 19.20292091],
      [84.85098267, 19.20264053],
      [84.85069275, 19.20264053],
      [84.85069275, 19.20207977],
      [84.85041809, 19.20208359],
      [84.85041809, 19.20180511],
      [84.84958649, 19.20181084],
      [84.84958649, 19.20153046],
      [84.84929657, 19.20153046],
      [84.84929657, 19.20125008],
      [84.84902954, 19.20125008],
      [84.84902954, 19.2009697],
      [84.84874725, 19.2009716],
      [84.84874725, 19.20069695],
      [84.84845734, 19.20070076],
      [84.84845734, 19.20042038],
      [84.84819794, 19.20042038],
      [84.84819794, 19.19985962],
      [84.84791565, 19.19986153],
      [84.84791565, 19.19958305],
      [84.84764099, 19.19958305],
      [84.84764099, 19.19930649],
      [84.8473587, 19.1993103],
      [84.8473587, 19.19902992],
      [84.84707642, 19.19902992],
      [84.84707642, 19.19847107],
      [84.84680176, 19.19847488],
      [84.84680176, 19.1981926],
      [84.8465271, 19.1981926],
      [84.8465271, 19.19763947],
      [84.84625244, 19.19763947],
      [84.84625244, 19.1973629],
      [84.84617615, 19.1973629],
      [84.84597015, 19.1973629],
      [84.84597015, 19.19715118],
      [84.84541321, 19.19708252],
      [84.84541321, 19.19680405],
      [84.84485626, 19.19680977],
      [84.84485626, 19.19652939],
      [84.8445816, 19.19652939],
      [84.8445816, 19.19625282],
      [84.84430695, 19.19625282],
      [84.84430695, 19.19569016],
      [84.84402466, 19.19569206],
      [84.84402466, 19.19513893],
      [84.84348297, 19.19514275],
      [84.84346771, 19.19458961],
      [84.8429184, 19.19457054],
      [84.8429184, 19.19430542],
      [84.84265137, 19.19431114],
      [84.84265137, 19.19403267],
      [84.84236145, 19.19403267],
      [84.84236145, 19.19375038],
      [84.84208679, 19.19375038],
      [84.84208679, 19.19347191],
      [84.8418045, 19.19347191],
      [84.8418045, 19.19319534],
      [84.84152985, 19.19320107],
      [84.84152985, 19.19235992],
      [84.84124756, 19.19236183],
      [84.84124756, 19.19192123],
      [84.84124756, 19.19180489],
      [84.8409729, 19.19180489],
      [84.8409729, 19.19152832],
      [84.84069824, 19.19152832],
      [84.84066772, 19.19125938],
      [84.8401413, 19.19124985],
      [84.8401413, 19.19096947],
      [84.83985901, 19.19097137],
      [84.83985901, 19.19069481],
      [84.83958435, 19.19069481],
      [84.83958435, 19.19041634],
      [84.83930206, 19.19041824],
      [84.83930206, 19.19013977],
      [84.8390274, 19.19013977],
      [84.8390274, 19.18985939],
      [84.83875275, 19.1898613],
      [84.83875275, 19.18958282],
      [84.83847046, 19.18958282],
      [84.83847046, 19.18902779],
      [84.8381958, 19.18902969],
      [84.8381958, 19.18876076],
      [84.83790588, 19.18876076],
      [84.83791351, 19.18836021],
      [84.83791351, 19.18819427],
      [84.8377533, 19.18819427],
      [84.83763885, 19.18819427],
      [84.83763885, 19.18806839],
      [84.83763885, 19.18763924],
      [84.83735657, 19.18764114],
      [84.83735657, 19.18708038],
      [84.83708191, 19.1870842],
      [84.83708191, 19.18652725],
      [84.83680725, 19.18653107],
      [84.83680725, 19.18625069],
      [84.83651733, 19.18625069],
      [84.83651733, 19.18597031],
      [84.83569336, 19.18597221],
      [84.83569336, 19.18569565],
      [84.83538055, 19.18569565],
      [84.83513641, 19.18569565],
      [84.83513641, 19.18543053],
      [84.83513641, 19.18486023],
      [84.83486176, 19.18486023],
      [84.83486176, 19.18457985],
      [84.8345871, 19.18458557],
      [84.8345871, 19.18430328],
      [84.83429718, 19.18431091],
      [84.83429718, 19.18375015],
      [84.83403015, 19.18375015],
      [84.83403015, 19.18320084],
      [84.83374786, 19.18320084],
      [84.83374786, 19.18292046],
      [84.83345795, 19.18292046],
      [84.83345795, 19.18263054],
      [84.83319092, 19.18263817],
      [84.83319092, 19.18236351],
      [84.83291626, 19.18236351],
      [84.83291626, 19.18208122],
      [84.8326416, 19.18208122],
      [84.8326416, 19.18180466],
      [84.83208466, 19.18180466],
      [84.83208466, 19.18152809],
      [84.83180237, 19.18153191],
      [84.83180237, 19.18124962],
      [84.83152771, 19.18124962],
      [84.83152771, 19.18069077],
      [84.83125305, 19.18069458],
      [84.83125305, 19.18041611],
      [84.83097076, 19.18042183],
      [84.83097076, 19.18013954],
      [84.83069611, 19.18013954],
      [84.83069611, 19.18000984],
      [84.83069611, 19.17985916],
      [84.83041382, 19.17985916],
      [84.83041382, 19.1795826],
      [84.83013916, 19.17959023],
      [84.83013916, 19.17931175],
      [84.82985687, 19.17931175],
      [84.82985687, 19.17902946],
      [84.82958221, 19.17902946],
      [84.82958221, 19.17874908],
      [84.82930756, 19.17874908],
      [84.82930756, 19.17847061],
      [84.82902527, 19.17847252],
      [84.82902527, 19.17791748],
      [84.82875061, 19.1779213],
      [84.82875061, 19.17708015],
      [84.82819366, 19.17708397],
      [84.82819366, 19.17652893],
      [84.82791901, 19.17653084],
      [84.82791138, 19.17625809],
      [84.82736206, 19.17625046],
      [84.82736206, 19.17597008],
      [84.82653046, 19.17597198],
      [84.82653046, 19.17541885],
      [84.82624817, 19.17542076],
      [84.82624817, 19.17515945],
      [84.82596588, 19.17514038],
      [84.82595825, 19.17487907],
      [84.82569122, 19.17486],
      [84.82569122, 19.17458344],
      [84.82541656, 19.17458344],
      [84.82541656, 19.17427254],
      [84.82541656, 19.1740284],
      [84.82521057, 19.1740303],
      [84.82514191, 19.17374992],
      [84.82496643, 19.17374992],
      [84.82457733, 19.17374992],
      [84.82457733, 19.17346954],
      [84.82375336, 19.17347145],
      [84.82375336, 19.17291641],
      [84.82347107, 19.17292023],
      [84.82347107, 19.17235947],
      [84.82319641, 19.17236137],
      [84.82319641, 19.17180634],
      [84.82290649, 19.17181015],
      [84.82290649, 19.17152977],
      [84.82263947, 19.17152977],
      [84.82260895, 19.17098045],
      [84.82236481, 19.17097473],
      [84.82236481, 19.17069244],
      [84.82180786, 19.17069244],
      [84.82180786, 19.17041588],
      [84.82151794, 19.17041969],
      [84.82151794, 19.17014122],
      [84.82125092, 19.17014122],
      [84.82125092, 19.16986084],
      [84.82096863, 19.16986084],
      [84.82096863, 19.16958046],
      [84.82069397, 19.16958237],
      [84.82069397, 19.16902733],
      [84.82041931, 19.16903114],
      [84.82041931, 19.16847038],
      [84.82013702, 19.16847038],
      [84.82013702, 19.16819382],
      [84.81986237, 19.16819382],
      [84.81986237, 19.1678009],
      [84.81986237, 19.16763878],
      [84.81973267, 19.16763878],
      [84.81958008, 19.16765022],
      [84.81958008, 19.16747093],
      [84.81958008, 19.16708374],
      [84.81930542, 19.16708374],
      [84.81930542, 19.16680527],
      [84.81903076, 19.1668129],
      [84.81903076, 19.16653252],
      [84.81874847, 19.16651917],
      [84.81874847, 19.16597176],
      [84.81847382, 19.16597176],
      [84.81847382, 19.16569519],
      [84.81819153, 19.16570091],
      [84.81819153, 19.16513824],
      [84.81791687, 19.16513824],
      [84.81791687, 19.16459084],
      [84.81764221, 19.16459084],
      [84.81764221, 19.16430092],
      [84.81735992, 19.16430473],
      [84.81735992, 19.16374969],
      [84.81708527, 19.16374969],
      [84.81708527, 19.16347313],
      [84.81680298, 19.16347313],
      [84.81680298, 19.16319466],
      [84.81652832, 19.16319466],
      [84.81652832, 19.16291618],
      [84.81614685, 19.16291618],
      [84.81597137, 19.16291809],
      [84.81597137, 19.16264915],
      [84.81569672, 19.16263962],
      [84.81569672, 19.16236115],
      [84.81541443, 19.16236115],
      [84.81541443, 19.16208267],
      [84.81513977, 19.16208267],
      [84.81513977, 19.16152763],
      [84.81485748, 19.16152954],
      [84.81485748, 19.16125107],
      [84.81458282, 19.16125107],
      [84.81458282, 19.16097069],
      [84.81430817, 19.1609726],
      [84.81430817, 19.16041756],
      [84.81402588, 19.16041946],
      [84.81402588, 19.16014099],
      [84.81375122, 19.16014099],
      [84.81375122, 19.15986061],
      [84.81346893, 19.15986061],
      [84.81346893, 19.15930939],
      [84.81291962, 19.15930939],
      [84.81291962, 19.15875053],
      [84.81263733, 19.15875053],
      [84.81263733, 19.15846062],
      [84.81236267, 19.15847206],
      [84.81236267, 19.1581955],
      [84.81208038, 19.1581955],
      [84.81208038, 19.15763855],
      [84.81181335, 19.15764046],
      [84.81181335, 19.15736008],
      [84.81124878, 19.15736389],
      [84.81124878, 19.15708542],
      [84.81097412, 19.15708542],
      [84.81097412, 19.15680313],
      [84.81069183, 19.15681076],
      [84.81069183, 19.15653038],
      [84.81041718, 19.15653038],
      [84.81041718, 19.15625],
      [84.81014252, 19.15625],
      [84.81014252, 19.15596962],
      [84.80986023, 19.15597153],
      [84.80986023, 19.15569305],
      [84.80957794, 19.15571022],
      [84.80957794, 19.1554203],
      [84.80928802, 19.1554203],
      [84.80928802, 19.15514183],
      [84.80902863, 19.15514183],
      [84.80902863, 19.15485954],
      [84.80874634, 19.15486145],
      [84.80874634, 19.15402794],
      [84.80847168, 19.15403175],
      [84.80847168, 19.15236092],
      [84.80819702, 19.15236092],
      [84.80819702, 19.15152931],
      [84.8079071, 19.15152931],
      [84.80791473, 19.15117836],
      [84.80791473, 19.15097237],
      [84.80764008, 19.15097237],
      [84.80764008, 19.15041733],
      [84.80735779, 19.15041924],
      [84.80735779, 19.15014076],
      [84.80696106, 19.15014076],
      [84.80680847, 19.15014076],
      [84.80680847, 19.14997101],
      [84.80680847, 19.14958382],
      [84.80654144, 19.14958954],
      [84.80654144, 19.14931107],
      [84.80619812, 19.14931107],
      [84.80596924, 19.14931107],
      [84.80596161, 19.14903831],
      [84.80568695, 19.14903069],
      [84.80568695, 19.14875031],
      [84.80541992, 19.14875031],
      [84.80541992, 19.14845085],
      [84.80541992, 19.14819527],
      [84.80516052, 19.14820099],
      [84.80513763, 19.14792061],
      [84.80486298, 19.14791679],
      [84.80486298, 19.14782715],
      [84.80486298, 19.14652824],
      [84.80458069, 19.14652824],
      [84.80458069, 19.14569664],
      [84.80430603, 19.14569664],
      [84.80430603, 19.14513969],
      [84.80403137, 19.14513969],
      [84.80403137, 19.14486122],
      [84.80374908, 19.14486122],
      [84.80374908, 19.14458084],
      [84.80319214, 19.14458466],
      [84.80319214, 19.14402771],
      [84.80291748, 19.14402962],
      [84.80291748, 19.14346123],
      [84.80263519, 19.14347458],
      [84.80263519, 19.14291763],
      [84.80236053, 19.14291954],
      [84.80236053, 19.14208031],
      [84.80208588, 19.14208221],
      [84.80208588, 19.14152718],
      [84.80179596, 19.1415329],
      [84.80179596, 19.14097023],
      [84.80152893, 19.14097023],
      [84.80152893, 19.1404171],
      [84.80124664, 19.14042282],
      [84.80124664, 19.14014244],
      [84.80097198, 19.14014244],
      [84.80097198, 19.13958168],
      [84.80069733, 19.13958359],
      [84.80069733, 19.13930511],
      [84.80040741, 19.13931274],
      [84.80040741, 19.13874817],
      [84.79985809, 19.13874817],
      [84.79985809, 19.1384697],
      [84.79958344, 19.1384716],
      [84.79958344, 19.13763809],
      [84.79930878, 19.13763809],
      [84.79930878, 19.1368084],
      [84.79902649, 19.1368084],
      [84.79902649, 19.13624954],
      [84.79875183, 19.13624954],
      [84.79875183, 19.13569069],
      [84.79846954, 19.1356945],
      [84.79846954, 19.13513947],
      [84.79792023, 19.13513947],
      [84.79792023, 19.13375092],
      [84.79763794, 19.13375092],
      [84.79763794, 19.13347054],
      [84.79736328, 19.13347244],
      [84.79736328, 19.13319588],
      [84.79708099, 19.13319588],
      [84.79708099, 19.13125038],
      [84.79680634, 19.13125038],
      [84.79680634, 19.13042068],
      [84.79651642, 19.13042068],
      [84.79651642, 19.12930107],
      [84.79624939, 19.12930489],
      [84.79624939, 19.12874985],
      [84.7959671, 19.12874985],
      [84.7959671, 19.12848091],
      [84.79569244, 19.12848091],
      [84.79569244, 19.1281929],
      [84.7951355, 19.12819481],
      [84.7951355, 19.12791634],
      [84.79486084, 19.12792015],
      [84.79486084, 19.12763977],
      [84.79402924, 19.12763977],
      [84.79402924, 19.12708092],
      [84.79374695, 19.12708282],
      [84.79374695, 19.12680626],
      [84.79319763, 19.12681007],
      [84.79319763, 19.1265316],
      [84.79264069, 19.1265316],
      [84.79264069, 19.12624931],
      [84.79207611, 19.12624931],
      [84.79207611, 19.12597084],
      [84.79180908, 19.12597275],
      [84.79180908, 19.12569427],
      [84.79152679, 19.12569427],
      [84.79152679, 19.1254158],
      [84.79103851, 19.12542152],
      [84.79099274, 19.12524414],
      [84.79096985, 19.12513924],
      [84.78985596, 19.12513924],
      [84.78985596, 19.12458038],
      [84.7895813, 19.1245842],
      [84.7895813, 19.12402725],
      [84.78930664, 19.12402916],
      [84.78930664, 19.12375069],
      [84.78901672, 19.12375069],
      [84.78901672, 19.12318993],
      [84.78874969, 19.12319374],
      [84.78874969, 19.12291718],
      [84.78846741, 19.12291908],
      [84.78846741, 19.12264061],
      [84.78817749, 19.12264061],
      [84.78817749, 19.12207985],
      [84.78791809, 19.12208366],
      [84.78791809, 19.12180519],
      [84.7876358, 19.12181091],
      [84.7876358, 19.12153053],
      [84.78708649, 19.12153053],
      [84.78708649, 19.12125015],
      [84.78624725, 19.12125015],
      [84.78624725, 19.12096977],
      [84.7859726, 19.12097168],
      [84.7859726, 19.12069511],
      [84.78569794, 19.12070084],
      [84.78569794, 19.12042046],
      [84.78540802, 19.12042046],
      [84.78540802, 19.12014008],
      [84.78514099, 19.12014008],
      [84.78514099, 19.1198597],
      [84.7848587, 19.1198616],
      [84.7848587, 19.11930656],
      [84.78458405, 19.11930656],
      [84.78458405, 19.11902809],
      [84.78430176, 19.11903],
      [84.78430176, 19.11874962],
      [84.7840271, 19.11874962],
      [84.7840271, 19.11819077],
      [84.78375244, 19.11819267],
      [84.78375244, 19.1179142],
      [84.78347015, 19.11791992],
      [84.78347015, 19.11736107],
      [84.7831955, 19.11736107],
      [84.7831955, 19.1170845],
      [84.78291321, 19.1170845],
      [84.78291321, 19.11680412],
      [84.78263855, 19.11680984],
      [84.78263855, 19.1162529],
      [84.78237152, 19.1162529],
      [84.78237152, 19.11597061],
      [84.7820816, 19.11597443],
      [84.7820816, 19.11541748],
      [84.78291321, 19.11541939],
      [84.78291321, 19.11514282],
      [84.78320313, 19.11514282],
      [84.78320313, 19.11403275],
      [84.78291321, 19.11403275],
      [84.78291321, 19.11375046],
      [84.78263855, 19.11375046],
      [84.78263855, 19.11347961],
      [84.78237152, 19.11347961],
      [84.78237152, 19.11320114],
      [84.7804184, 19.1131916],
      [84.7804184, 19.11291695],
      [84.78013611, 19.11292267],
      [84.78013611, 19.11235809],
      [84.77986145, 19.11236191],
      [84.77986145, 19.11208344],
      [84.77958679, 19.11208344],
      [84.77958679, 19.11180496],
      [84.77931213, 19.11181068],
      [84.77931213, 19.1115284],
      [84.77874756, 19.1115284],
      [84.77874756, 19.11124992],
      [84.7784729, 19.11124992],
      [84.7784729, 19.11069107],
      [84.77819824, 19.11069489],
      [84.77819061, 19.11041641],
      [84.77791595, 19.11041641],
      [84.77791595, 19.11013985],
      [84.77735901, 19.11013985],
      [84.77735901, 19.10985947],
      [84.7765274, 19.10986137],
      [84.7765274, 19.1095829],
      [84.77597046, 19.1095829],
      [84.77597046, 19.10986137],
      [84.77541351, 19.10986137],
      [84.77541351, 19.1095829],
      [84.77402496, 19.1095829],
      [84.77402496, 19.10986137],
      [84.77375031, 19.10986137],
      [84.77375031, 19.1095829],
      [84.77263641, 19.1095829],
      [84.77263641, 19.10930634],
      [84.76930237, 19.10930634],
      [84.76930237, 19.1095829],
      [84.76902771, 19.1095829],
      [84.76902771, 19.10930634],
      [84.76819611, 19.10930634],
      [84.76819611, 19.1095829],
      [84.76763916, 19.1095829],
      [84.76763916, 19.10986137],
      [84.76737213, 19.10985947],
      [84.76737213, 19.11013985],
      [84.76708221, 19.11013985],
      [84.76708221, 19.10985947],
      [84.76680756, 19.10985947],
      [84.76680756, 19.11013985],
      [84.76569366, 19.11013985],
      [84.76569366, 19.11041641],
      [84.76541901, 19.11041641],
      [84.76541901, 19.11069489],
      [84.76457977, 19.11069107],
      [84.76457977, 19.1115284],
      [84.76264191, 19.1115284],
      [84.76264191, 19.11181068],
      [84.76208496, 19.11180496],
      [84.76208496, 19.11208344],
      [84.76180267, 19.11208344],
      [84.76180267, 19.11236191],
      [84.76152802, 19.11235809],
      [84.76152802, 19.11264038],
      [84.76097107, 19.11264038],
      [84.76097107, 19.11292267],
      [84.76069641, 19.11291695],
      [84.76069641, 19.1131916],
      [84.75985718, 19.11320114],
      [84.75985718, 19.11347961],
      [84.75930786, 19.11347961],
      [84.75930786, 19.11375046],
      [84.75902557, 19.11375046],
      [84.75902557, 19.11347008],
      [84.75875092, 19.11347961],
      [84.75875092, 19.11320114],
      [84.7582016, 19.11320114],
      [84.7582016, 19.11347961],
      [84.75763702, 19.11347961],
      [84.75763702, 19.11375046],
      [84.75736237, 19.11375046],
      [84.75736237, 19.11403275],
      [84.75763702, 19.11403275],
      [84.75763702, 19.11486053],
      [84.75736237, 19.11486053],
      [84.75736237, 19.11514282],
      [84.75708008, 19.11514282],
      [84.75708008, 19.11541939],
      [84.75653076, 19.11541939],
      [84.75653076, 19.11514282],
      [84.75596619, 19.11514282],
      [84.75596619, 19.11486053],
      [84.75569153, 19.11486053],
      [84.75569153, 19.11458015],
      [84.75541687, 19.11458206],
      [84.75541687, 19.1143055],
      [84.75569153, 19.11431122],
      [84.75569153, 19.11375046],
      [84.75596619, 19.11375046],
      [84.75596619, 19.11264038],
      [84.75569153, 19.11264038],
      [84.75569153, 19.11207962],
      [84.75541687, 19.11208344],
      [84.75541687, 19.11180496],
      [84.75485992, 19.11181068],
      [84.75485992, 19.11124992],
      [84.75430298, 19.11124992],
      [84.75430298, 19.1115284],
      [84.75374603, 19.1115284],
      [84.75374603, 19.11124992],
      [84.75347137, 19.11124992],
      [84.75347137, 19.1115284],
      [84.75319672, 19.1115284],
      [84.75319672, 19.11292267],
      [84.75430298, 19.11292267],
      [84.75430298, 19.11375046],
      [84.75402832, 19.11375046],
      [84.75402832, 19.11431122],
      [84.75347137, 19.1143055],
      [84.75347137, 19.11458206],
      [84.7529068, 19.11458015],
      [84.7529068, 19.11514282],
      [84.75263977, 19.11513901],
      [84.75263977, 19.11569214],
      [84.75208282, 19.11569214],
      [84.75208282, 19.11597443],
      [84.75096893, 19.11597061],
      [84.75096893, 19.1162529],
      [84.74986267, 19.1162529],
      [84.74986267, 19.11654091],
      [84.74958038, 19.11654091],
      [84.74958038, 19.11680984],
      [84.74930573, 19.11680412],
      [84.74930573, 19.1170845],
      [84.74764252, 19.11708069],
      [84.74764252, 19.11736107],
      [84.74708557, 19.11736107],
      [84.7470932, 19.11763954],
      [84.74569702, 19.11763954],
      [84.74569702, 19.11736107],
      [84.7454071, 19.11736107],
      [84.7454071, 19.11708069],
      [84.74514008, 19.1170845],
      [84.74514008, 19.11680412],
      [84.74485779, 19.11680984],
      [84.74485779, 19.11654091],
      [84.74430847, 19.11654091],
      [84.74430847, 19.1162529],
      [84.74402618, 19.1162529],
      [84.74402618, 19.11597061],
      [84.74375153, 19.11597443],
      [84.74375153, 19.11569214],
      [84.74346924, 19.11569214],
      [84.74346924, 19.11541748],
      [84.74291992, 19.11541939],
      [84.74291992, 19.11514282],
      [84.74263763, 19.11514282],
      [84.74263763, 19.11486053],
      [84.74208069, 19.11486053],
      [84.74208069, 19.11458015],
      [84.74124908, 19.11458206],
      [84.74124908, 19.1143055],
      [84.7409668, 19.11431122],
      [84.7409668, 19.11403275],
      [84.74069214, 19.11403275],
      [84.74069214, 19.11375046],
      [84.74041748, 19.11375046],
      [84.74041748, 19.11320114],
      [84.74014282, 19.1131916],
      [84.74013519, 19.11291695],
      [84.73986053, 19.11292267],
      [84.73986053, 19.11264038],
      [84.73958588, 19.11264038],
      [84.73958588, 19.11235809],
      [84.73847198, 19.11236191],
      [84.73847198, 19.11180496],
      [84.73819733, 19.11181068],
      [84.73819733, 19.11096954],
      [84.73791504, 19.11097145],
      [84.73791504, 19.11069489],
      [84.73708344, 19.11069489],
      [84.73708344, 19.11041641],
      [84.73680878, 19.11041641],
      [84.73680878, 19.11013985],
      [84.73652649, 19.11013985],
      [84.73652649, 19.10930824],
      [84.73625183, 19.10930824],
      [84.73625183, 19.10874939],
      [84.73652649, 19.10874939],
      [84.73652649, 19.1073494],
      [84.73625183, 19.10736084],
      [84.73625183, 19.10680771],
      [84.73596954, 19.10680962],
      [84.73596954, 19.10653114],
      [84.73568726, 19.10653114],
      [84.73568726, 19.10625076],
      [84.73542023, 19.10625076],
      [84.73542023, 19.10597038],
      [84.73513794, 19.10597229],
      [84.73513794, 19.10569572],
      [84.73486328, 19.10569572],
      [84.73486328, 19.10347366],
      [84.73458099, 19.10347366],
      [84.73458099, 19.10319328],
      [84.73486328, 19.10320091],
      [84.73486328, 19.10235977],
      [84.73513794, 19.10236359],
      [84.73513794, 19.10124969],
      [84.73486328, 19.10124969],
      [84.73486328, 19.10097122],
      [84.73458099, 19.10097122],
      [84.73458099, 19.10069466],
      [84.73374939, 19.10069466],
      [84.73374939, 19.10013962],
      [84.7334671, 19.10013962],
      [84.7334671, 19.09985924],
      [84.73319244, 19.09985924],
      [84.73319244, 19.09958076],
      [84.73291779, 19.09958267],
      [84.73291779, 19.09902763],
      [84.73264313, 19.09903145],
      [84.73264313, 19.09818077],
      [84.73236084, 19.09819412],
      [84.73236084, 19.09791756],
      [84.73208618, 19.09792137],
      [84.73208618, 19.09763908],
      [84.73152924, 19.09763908],
      [84.73152924, 19.09736061],
      [84.73124695, 19.09736061],
      [84.73124695, 19.09708405],
      [84.73097229, 19.09708405],
      [84.73097229, 19.09680557],
      [84.73042297, 19.09680748],
      [84.73042297, 19.09653091],
      [84.72875214, 19.09653091],
      [84.72875214, 19.09625053],
      [84.72846985, 19.09625053],
      [84.72846985, 19.09597015],
      [84.7279129, 19.09597206],
      [84.7279129, 19.09569359],
      [84.72736359, 19.09569359],
      [84.72736359, 19.09597206],
      [84.72680664, 19.09597015],
      [84.72680664, 19.09625053],
      [84.72624969, 19.09625053],
      [84.72624969, 19.09653091],
      [84.72541809, 19.09653091],
      [84.72541809, 19.09736061],
      [84.72512817, 19.09736061],
      [84.72512817, 19.09792137],
      [84.72569275, 19.09791756],
      [84.72569275, 19.0984726],
      [84.72597504, 19.0984726],
      [84.72598267, 19.10013962],
      [84.72569275, 19.10013962],
      [84.72569275, 19.10264015],
      [84.72598267, 19.10264015],
      [84.72598267, 19.10320091],
      [84.72624969, 19.10320091],
      [84.72624969, 19.10375023],
      [84.72651672, 19.10375023],
      [84.72651672, 19.10403061],
      [84.72680664, 19.10403061],
      [84.72680664, 19.10431099],
      [84.7270813, 19.10430336],
      [84.7270813, 19.10458565],
      [84.72763824, 19.10457993],
      [84.72763824, 19.10486031],
      [84.7279129, 19.10486031],
      [84.7279129, 19.10542107],
      [84.72819519, 19.10541725],
      [84.72819519, 19.10569572],
      [84.72930908, 19.10569],
      [84.72930908, 19.10653114],
      [84.72958374, 19.10652733],
      [84.72958374, 19.10708237],
      [84.7298584, 19.10708427],
      [84.7298584, 19.10736084],
      [84.73014069, 19.1073494],
      [84.73014069, 19.10764122],
      [84.73042297, 19.10764122],
      [84.73042297, 19.10791969],
      [84.73069763, 19.10791779],
      [84.73069763, 19.10819435],
      [84.73097229, 19.10819435],
      [84.73097229, 19.10847282],
      [84.73152924, 19.10847092],
      [84.73152924, 19.10930824],
      [84.73180389, 19.10930634],
      [84.73180389, 19.1095829],
      [84.73208618, 19.10958099],
      [84.73208618, 19.11013985],
      [84.73236084, 19.11013985],
      [84.73236084, 19.11041641],
      [84.7326355, 19.11041641],
      [84.7326355, 19.11097145],
      [84.73291779, 19.11096954],
      [84.73291779, 19.11181068],
      [84.73264313, 19.11181068],
      [84.73264313, 19.11264038],
      [84.73291779, 19.11264038],
      [84.73291779, 19.11292267],
      [84.73400879, 19.11292267],
      [84.73400879, 19.11264038],
      [84.73432159, 19.11264038],
      [84.73432159, 19.11235809],
      [84.73458099, 19.11236191],
      [84.73458099, 19.11208344],
      [84.73513794, 19.11208344],
      [84.73513794, 19.11180496],
      [84.73542023, 19.11181068],
      [84.73542023, 19.1115284],
      [84.73568726, 19.1115284],
      [84.73568726, 19.11096954],
      [84.73652649, 19.11097145],
      [84.73652649, 19.11208344],
      [84.73708344, 19.11208344],
      [84.73708344, 19.11236191],
      [84.73792267, 19.11235809],
      [84.73792267, 19.11264038],
      [84.73819733, 19.11263847],
      [84.73819733, 19.1131916],
      [84.73706818, 19.11320114],
      [84.73706818, 19.11347961],
      [84.73680878, 19.11347008],
      [84.73680878, 19.11458206],
      [84.73652649, 19.11458206],
      [84.73652649, 19.1143055],
      [84.73625183, 19.11431122],
      [84.73625183, 19.11403275],
      [84.73596954, 19.11402702],
      [84.73596954, 19.11569214],
      [84.73568726, 19.11569023],
      [84.73568726, 19.1162529],
      [84.73542023, 19.1162529],
      [84.73542023, 19.11654091],
      [84.73513794, 19.11654091],
      [84.73513794, 19.11680984],
      [84.73486328, 19.11680984],
      [84.73486328, 19.11736107],
      [84.73513794, 19.11736107],
      [84.73513794, 19.11791992],
      [84.73542023, 19.11791992],
      [84.73542023, 19.11763954],
      [84.73596954, 19.11763954],
      [84.73596954, 19.11791992],
      [84.73625183, 19.1179142],
      [84.73625183, 19.11958313],
      [84.73596954, 19.11958313],
      [84.73596954, 19.1198616],
      [84.73568726, 19.1198597],
      [84.73568726, 19.12014008],
      [84.73486328, 19.12014008],
      [84.73486328, 19.12042046],
      [84.73432159, 19.12042046],
      [84.73432159, 19.12014008],
      [84.73374939, 19.12014008],
      [84.73374939, 19.12070084],
      [84.73347473, 19.12069511],
      [84.73347473, 19.12208366],
      [84.73374939, 19.12208366],
      [84.73374939, 19.12236023],
      [84.73542023, 19.12236023],
      [84.73542023, 19.12208366],
      [84.73569489, 19.12208366],
      [84.73569489, 19.12180519],
      [84.73708344, 19.12180519],
      [84.73708344, 19.12208366],
      [84.73735809, 19.12207985],
      [84.73735809, 19.12291908],
      [84.73708344, 19.12291718],
      [84.73708344, 19.12319374],
      [84.73680878, 19.12319374],
      [84.73680878, 19.12347221],
      [84.73596954, 19.12347031],
      [84.73596954, 19.12375069],
      [84.73568726, 19.12375069],
      [84.73568726, 19.12402916],
      [84.73542023, 19.12402916],
      [84.73542023, 19.12486076],
      [84.73596954, 19.12486076],
      [84.73596954, 19.12458038],
      [84.73847198, 19.1245842],
      [84.73847198, 19.12402725],
      [84.73874664, 19.12402916],
      [84.73874664, 19.12375069],
      [84.73929596, 19.12375069],
      [84.73929596, 19.12347031],
      [84.74013519, 19.12347221],
      [84.74013519, 19.12319374],
      [84.74097443, 19.12319374],
      [84.74097443, 19.12291718],
      [84.74153137, 19.12291908],
      [84.74153137, 19.12264061],
      [84.74180603, 19.12264061],
      [84.74180603, 19.12235069],
      [84.74236298, 19.12235069],
      [84.74236298, 19.12264061],
      [84.74346924, 19.12264061],
      [84.74346924, 19.12235069],
      [84.74375153, 19.12236023],
      [84.74375153, 19.12208366],
      [84.74402618, 19.12208366],
      [84.74402618, 19.12152863],
      [84.74458313, 19.12153053],
      [84.74458313, 19.12096977],
      [84.74541473, 19.12097168],
      [84.74541473, 19.12069511],
      [84.74569702, 19.12069511],
      [84.74569702, 19.12097168],
      [84.74597168, 19.12096977],
      [84.74597168, 19.12125015],
      [84.7462616, 19.12125015],
      [84.7462616, 19.12153053],
      [84.74652863, 19.12152863],
      [84.74652863, 19.12319374],
      [84.74624634, 19.12319374],
      [84.7462616, 19.12375069],
      [84.74597168, 19.12375069],
      [84.74597168, 19.12431145],
      [84.7454071, 19.12431145],
      [84.7454071, 19.12486076],
      [84.74514008, 19.12486076],
      [84.74514008, 19.12513924],
      [84.74430847, 19.12513924],
      [84.74430847, 19.12624931],
      [84.74402618, 19.12624931],
      [84.74402618, 19.12708282],
      [84.74430847, 19.12708092],
      [84.74430847, 19.12792015],
      [84.74458313, 19.12791634],
      [84.74458313, 19.12819481],
      [84.74485779, 19.1281929],
      [84.74485779, 19.12848091],
      [84.74569702, 19.12848091],
      [84.74569702, 19.12874985],
      [84.7462616, 19.12874985],
      [84.7462616, 19.12903023],
      [84.74652863, 19.12902832],
      [84.74652863, 19.12930489],
      [84.74680328, 19.12930489],
      [84.74680328, 19.12958145],
      [84.74652863, 19.12958145],
      [84.74652863, 19.12986374],
      [84.7462616, 19.12985992],
      [84.7462616, 19.13070107],
      [84.74652863, 19.13069534],
      [84.74652863, 19.13097382],
      [84.74680328, 19.13097],
      [84.74680328, 19.13181114],
      [84.74541473, 19.13180351],
      [84.74541473, 19.1320858],
      [84.74485779, 19.13208008],
      [84.74485779, 19.13236046],
      [84.74458313, 19.13236046],
      [84.74458313, 19.13292122],
      [84.74485779, 19.1329174],
      [84.74485779, 19.13319588],
      [84.74514008, 19.13319588],
      [84.74514008, 19.13347244],
      [84.7454071, 19.13347054],
      [84.7454071, 19.13402939],
      [84.7470932, 19.13402939],
      [84.7470932, 19.13430977],
      [84.74764252, 19.13430786],
      [84.74764252, 19.13458252],
      [84.74791718, 19.13458061],
      [84.74791718, 19.13486099],
      [84.74819183, 19.13486099],
      [84.74819183, 19.13513947],
      [84.74847412, 19.13513947],
      [84.74847412, 19.1356945],
      [84.74874878, 19.1356945],
      [84.74874878, 19.13597298],
      [84.75041962, 19.13597107],
      [84.75041962, 19.13652992],
      [84.75013733, 19.13652992],
      [84.75013733, 19.13708305],
      [84.74986267, 19.13708115],
      [84.74986267, 19.14042282],
      [84.74958038, 19.14042282],
      [84.74958038, 19.14125061],
      [84.7492981, 19.14125061],
      [84.7492981, 19.14180946],
      [84.74903107, 19.14180565],
      [84.74903107, 19.14208221],
      [84.74874878, 19.14208031],
      [84.74874878, 19.14236069],
      [84.74819183, 19.14236069],
      [84.74819183, 19.14264107],
      [84.74736023, 19.14263916],
      [84.74736023, 19.14319229],
      [84.74708557, 19.14319229],
      [84.74708557, 19.14347458],
      [84.74514008, 19.14346123],
      [84.74514008, 19.14375114],
      [84.74485779, 19.14375114],
      [84.74485779, 19.14402962],
      [84.74458313, 19.14402771],
      [84.74458313, 19.14458466],
      [84.74375153, 19.14458466],
      [84.74375153, 19.14430618],
      [84.74346924, 19.14431],
      [84.74346924, 19.14402962],
      [84.74014282, 19.14402962],
      [84.74014282, 19.14375114],
      [84.73986053, 19.14375114],
      [84.73986053, 19.14346123],
      [84.73958588, 19.14347458],
      [84.73958588, 19.14291763],
      [84.73929596, 19.14291954],
      [84.73929596, 19.14236069],
      [84.73819733, 19.14236069],
      [84.73819733, 19.14264107],
      [84.73792267, 19.14264107],
      [84.73792267, 19.14291954],
      [84.73764038, 19.14291763],
      [84.73764038, 19.14319229],
      [84.73708344, 19.14319229],
      [84.73708344, 19.14347458],
      [84.73680878, 19.14346123],
      [84.73680878, 19.14375114],
      [84.73542023, 19.14375114],
      [84.73542023, 19.14346123],
      [84.73458099, 19.14347458],
      [84.73458099, 19.14319229],
      [84.73430634, 19.14319229],
      [84.73430634, 19.14291763],
      [84.7334671, 19.14291954],
      [84.7334671, 19.14264107],
      [84.73291779, 19.14264107],
      [84.73291779, 19.14208031],
      [84.73236084, 19.14208221],
      [84.73236084, 19.14180565],
      [84.73097229, 19.14180946],
      [84.73097229, 19.1415329],
      [84.7298584, 19.1415329],
      [84.7298584, 19.14125061],
      [84.72957611, 19.14125061],
      [84.72957611, 19.14097023],
      [84.72902679, 19.14097023],
      [84.72902679, 19.14069366],
      [84.72875214, 19.14069366],
      [84.72875214, 19.1404171],
      [84.72846985, 19.14042282],
      [84.72846985, 19.14014244],
      [84.72820282, 19.14014244],
      [84.72820282, 19.13986015],
      [84.72736359, 19.13986015],
      [84.72736359, 19.13958359],
      [84.72680664, 19.13958359],
      [84.72680664, 19.13986015],
      [84.72624969, 19.13986015],
      [84.72624969, 19.14014244],
      [84.72486115, 19.14014244],
      [84.72486115, 19.14042282],
      [84.72374725, 19.14042282],
      [84.72374725, 19.14014244],
      [84.7234726, 19.14014244],
      [84.7234726, 19.13986015],
      [84.72264099, 19.13986015],
      [84.72264099, 19.13958359],
      [84.7223587, 19.13958359],
      [84.7223587, 19.13930511],
      [84.72206879, 19.13931274],
      [84.72206879, 19.1384697],
      [84.72180176, 19.1384716],
      [84.72180176, 19.13819504],
      [84.7215271, 19.13820076],
      [84.7215271, 19.13791847],
      [84.72125244, 19.13791847],
      [84.72125244, 19.13763809],
      [84.72097015, 19.13763809],
      [84.72097015, 19.13735962],
      [84.7206955, 19.13736153],
      [84.7206955, 19.13680649],
      [84.72097015, 19.13680077],
      [84.72097015, 19.13652992],
      [84.72125244, 19.13652992],
      [84.72125244, 19.13597107],
      [84.7215271, 19.13597298],
      [84.7215271, 19.13513947],
      [84.72125244, 19.13513947],
      [84.72125244, 19.13458061],
      [84.7215271, 19.13458252],
      [84.7215271, 19.13402748],
      [84.72180176, 19.13402939],
      [84.72180176, 19.13347054],
      [84.72125244, 19.13347244],
      [84.72125244, 19.13319588],
      [84.72097015, 19.13319588],
      [84.72097015, 19.1329174],
      [84.72041321, 19.13292122],
      [84.72041321, 19.13264084],
      [84.72013855, 19.13264084],
      [84.72013855, 19.13236046],
      [84.71985626, 19.13236046],
      [84.71985626, 19.13181114],
      [84.7195816, 19.13181114],
      [84.7195816, 19.13125038],
      [84.71985626, 19.13125038],
      [84.71985626, 19.13097],
      [84.7195816, 19.13097382],
      [84.7195816, 19.13069534],
      [84.7193222, 19.13070107],
      [84.7193222, 19.1301403],
      [84.71903229, 19.1301403],
      [84.71903229, 19.12985992],
      [84.7179184, 19.12986374],
      [84.7179184, 19.12958145],
      [84.71736145, 19.12958145],
      [84.71736145, 19.12930489],
      [84.71708679, 19.12930489],
      [84.71708679, 19.12902832],
      [84.71679688, 19.12903023],
      [84.71679688, 19.12874985],
      [84.71624756, 19.12874985],
      [84.71624756, 19.12847137],
      [84.71569824, 19.12848091],
      [84.71569061, 19.1281929],
      [84.71541595, 19.12819481],
      [84.71541595, 19.12791634],
      [84.7140274, 19.12792015],
      [84.7140274, 19.12763977],
      [84.71375275, 19.12763977],
      [84.71375275, 19.12735939],
      [84.71347046, 19.1273613],
      [84.71347046, 19.12708282],
      [84.7131958, 19.12708282],
      [84.7131958, 19.12652779],
      [84.71289825, 19.1265316],
      [84.71289825, 19.12624931],
      [84.71263885, 19.12624931],
      [84.71263885, 19.12597084],
      [84.7123642, 19.12597275],
      [84.7123642, 19.1254158],
      [84.71208191, 19.12542152],
      [84.71208191, 19.12486076],
      [84.71180725, 19.12486076],
      [84.71180725, 19.12458038],
      [84.71152496, 19.1245842],
      [84.71152496, 19.12347221],
      [84.71097565, 19.12347221],
      [84.71097565, 19.12319374],
      [84.71069336, 19.12319374],
      [84.71069336, 19.12291718],
      [84.7104187, 19.12291908],
      [84.7104187, 19.12235069],
      [84.70847321, 19.12236023],
      [84.70847321, 19.12208366],
      [84.70680237, 19.12208366],
      [84.70680237, 19.12180519],
      [84.70652771, 19.12181091],
      [84.70652771, 19.12153053],
      [84.70569611, 19.12153053],
      [84.70569611, 19.12096977],
      [84.70541382, 19.12097168],
      [84.70541382, 19.12041664],
      [84.70402527, 19.12041664],
      [84.70402527, 19.12097168],
      [84.70347595, 19.12097168],
      [84.70347595, 19.12069511],
      [84.70318604, 19.12070084],
      [84.70318604, 19.1198597],
      [84.70291901, 19.1198616],
      [84.70291901, 19.11874962],
      [84.70318604, 19.11874962],
      [84.70318604, 19.11763954],
      [84.70347595, 19.11763954],
      [84.70347595, 19.11680984],
      [84.70375061, 19.11680984],
      [84.70375061, 19.1162529],
      [84.7040329, 19.1162529],
      [84.7040329, 19.11597061],
      [84.70430756, 19.11597443],
      [84.70430756, 19.11458206],
      [84.7048645, 19.11458206],
      [84.7048645, 19.1143055],
      [84.70540619, 19.11431122],
      [84.70540619, 19.11403275],
      [84.70569611, 19.11403275],
      [84.70569611, 19.11375046],
      [84.70652771, 19.11375046],
      [84.70652771, 19.11320114],
      [84.70597076, 19.1131916],
      [84.70597076, 19.11124992],
      [84.70625305, 19.11124992],
      [84.70625305, 19.11096954],
      [84.70652771, 19.11097145],
      [84.70652771, 19.11069489],
      [84.70680237, 19.11069489],
      [84.70680237, 19.11013985],
      [84.70709229, 19.11013985],
      [84.70709229, 19.10902977],
      [84.70680237, 19.10902977],
      [84.70680237, 19.10874939],
      [84.70652771, 19.10874939],
      [84.70652771, 19.10847092],
      [84.70680237, 19.10847282],
      [84.70680237, 19.10819435],
      [84.70708466, 19.10819435],
      [84.70708466, 19.10791779],
      [84.70791626, 19.10791779],
      [84.70791626, 19.10819435],
      [84.71013641, 19.10819435],
      [84.71013641, 19.10847282],
      [84.7104187, 19.10847282],
      [84.7104187, 19.10819435],
      [84.71097565, 19.10819435],
      [84.71097565, 19.10763931],
      [84.71125031, 19.10764122],
      [84.71125031, 19.1073494],
      [84.71152496, 19.10736084],
      [84.71152496, 19.10708427],
      [84.71125031, 19.10708427],
      [84.71125031, 19.10652733],
      [84.71153259, 19.10653114],
      [84.71153259, 19.10625076],
      [84.71180725, 19.10625076],
      [84.71180725, 19.10514069],
      [84.71208191, 19.10514069],
      [84.71208191, 19.10486031],
      [84.71235657, 19.10486031],
      [84.71235657, 19.10457993],
      [84.71291351, 19.10458565],
      [84.71291351, 19.10375023],
      [84.71318817, 19.10375023],
      [84.71318817, 19.10320091],
      [84.71347046, 19.10320091],
      [84.71347046, 19.10292053],
      [84.71375275, 19.10292053],
      [84.71375275, 19.10042191],
      [84.7140274, 19.10042191],
      [84.7140274, 19.09958076],
      [84.71430206, 19.09958267],
      [84.71430206, 19.09930611],
      [84.71457672, 19.09931183],
      [84.71457672, 19.09903145],
      [84.71626282, 19.09903145],
      [84.71626282, 19.09874916],
      [84.71652985, 19.09874916],
      [84.71652985, 19.09792137],
      [84.71679688, 19.09792137],
      [84.71679688, 19.09763908],
      [84.71708679, 19.09763908],
      [84.71708679, 19.09736061],
      [84.71763611, 19.09736061],
      [84.71763611, 19.09708023],
      [84.71875, 19.09708405],
      [84.71875, 19.09680557],
      [84.71903229, 19.09680748],
      [84.71903229, 19.09653091],
      [84.71875, 19.09653091],
      [84.71875, 19.09625053],
      [84.71903229, 19.09625053],
      [84.71903229, 19.09584427],
      [84.71185303, 19.1000309],
      [84.69754791, 19.10881805],
      [84.69832611, 19.1103096],
      [84.69776917, 19.1156311],
      [84.69381714, 19.11952019],
      [84.69391632, 19.12155151],
      [84.69731903, 19.1251297],
      [84.70648193, 19.14356041],
      [84.70522308, 19.1490097],
      [84.7020874, 19.15114975],
      [84.69756317, 19.15029144],
      [84.69213867, 19.15170097],
      [84.68141174, 19.14974976],
      [84.67807007, 19.15346909],
      [84.67333221, 19.15689087],
      [84.66929626, 19.15568161],
      [84.66501617, 19.15673065],
      [84.66326904, 19.14748001],
      [84.6654129, 19.14470291],
      [84.66590118, 19.13527107],
      [84.66451263, 19.12815094],
      [84.66117096, 19.1218605],
      [84.65731812, 19.12138939],
      [84.65342712, 19.12290955],
      [84.64729309, 19.12037086],
      [84.64170837, 19.12478828],
      [84.64037323, 19.12223816],
      [84.62931824, 19.12070084],
      [84.62554932, 19.11827087],
      [84.60939789, 19.11745071],
      [84.59464264, 19.12354851],
      [84.59184265, 19.12384033],
      [84.59168243, 19.12112045],
      [84.59577179, 19.11520958],
      [84.60293579, 19.09657097],
      [84.6055603, 19.09263039],
      [84.60962677, 19.09089279],
      [84.6144104, 19.09231758],
      [84.61678314, 19.09000015],
      [84.61656952, 19.08520126],
      [84.61982727, 19.07650948],
      [84.62444305, 19.07442093],
      [84.63144684, 19.07262039],
      [84.63360596, 19.07463837],
      [84.63474274, 19.07868958],
      [84.64460754, 19.08445168],
      [84.64871216, 19.08419991],
      [84.65416718, 19.08101273],
      [84.65962219, 19.07093048],
      [84.66458893, 19.06629181],
      [84.66320038, 19.06445122],
      [84.65901184, 19.06364822],
      [84.65453339, 19.05231094],
      [84.64772797, 19.0560894],
      [84.63683319, 19.05714989],
      [84.63437653, 19.05554008],
      [84.63005829, 19.04959106],
      [84.62608337, 19.05029106],
      [84.62541199, 19.05278015],
      [84.62329102, 19.05566025],
      [84.61271667, 19.05483055],
      [84.61152649, 19.05373001],
      [84.61235809, 19.04973984],
      [84.61418915, 19.0467205],
      [84.60946655, 19.04673958],
      [84.60756683, 19.04540825],
      [84.60048676, 19.03293037],
      [84.59697723, 19.01974106],
      [84.59011841, 19.01338005],
      [84.58769226, 19.01299095],
      [84.57813263, 19.01890182],
      [84.57531738, 19.02719116],
      [84.57364655, 19.03511047],
      [84.57509613, 19.03753853],
      [84.57811737, 19.06212807],
      [84.57644653, 19.06456184],
      [84.57060242, 19.06041145],
      [84.56852722, 19.06064034],
      [84.56797028, 19.06347084],
      [84.56613922, 19.06503868],
      [84.5597229, 19.05956078],
      [84.5535965, 19.04740906],
      [84.54772186, 19.04609108],
      [84.53481293, 19.04536057],
      [84.53147125, 19.0441494],
      [84.52715302, 19.03871918],
      [84.50991821, 19.03746033],
      [84.5071106, 19.03544807],
      [84.5030899, 19.02926254],
      [84.49777985, 19.02487946],
      [84.48938751, 19.02095032],
      [84.48206329, 19.00990868],
      [84.47782898, 19.0078907],
      [84.47551727, 18.99603271],
      [84.47569275, 18.99173927],
      [84.47306061, 18.98264122],
      [84.4716568, 18.98122025],
      [84.46282959, 18.98328972],
      [84.45218658, 18.98833084],
      [84.44815063, 18.99173927],
      [84.44406128, 18.99455833],
      [84.43739319, 18.99745941],
      [84.43297577, 19.00185966],
      [84.42980194, 19.00118065],
      [84.42218781, 19.00853157],
      [84.42002106, 19.01251984],
      [84.41513062, 19.0138607],
      [84.41464996, 19.01235962],
      [84.4154129, 19.00813293],
      [84.41803741, 19.00498962],
      [84.42003632, 19.00084114],
      [84.42526245, 18.99534035],
      [84.42610931, 18.99176979],
      [84.42555237, 18.989151],
      [84.42640686, 18.98397064],
      [84.42796326, 18.98264122],
      [84.4347229, 18.97682953],
      [84.44064331, 18.96693039],
      [84.43772888, 18.96570969],
      [84.43454742, 18.96315956],
      [84.42030334, 18.94935989],
      [84.41559601, 18.93803978],
      [84.41713715, 18.93330193],
      [84.41998291, 18.92971039],
      [84.42938995, 18.91368294],
      [84.42032623, 18.90487099],
      [84.41796875, 18.90065956],
      [84.40956879, 18.90810013],
      [84.40615082, 18.9061203],
      [84.40769958, 18.90112114],
      [84.41072083, 18.89825821],
      [84.41208649, 18.89393997],
      [84.40981293, 18.89063072],
      [84.39782715, 18.88611984],
      [84.39396667, 18.88586998],
      [84.38687134, 18.88986969],
      [84.37845612, 18.89324188],
      [84.37406921, 18.89402962],
      [84.36976624, 18.89581108],
      [84.3653183, 18.89625931],
      [84.36490631, 18.8932209],
      [84.36767578, 18.88932991],
      [84.37422943, 18.8888607],
      [84.37903595, 18.88546944],
      [84.38609314, 18.88302994],
      [84.38762665, 18.87766838],
      [84.38622284, 18.87170982],
      [84.38323212, 18.86927986],
      [84.37808228, 18.87409973],
      [84.37406921, 18.87649918],
      [84.37135315, 18.8754406],
      [84.35473633, 18.88293076],
      [84.35144806, 18.88319969],
      [84.35183716, 18.87779045],
      [84.35382843, 18.87506294],
      [84.35962677, 18.87190056],
      [84.3613205, 18.87268066],
      [84.36624908, 18.87170982],
      [84.37156677, 18.86721992],
      [84.37146759, 18.86338234],
      [84.36933899, 18.85897064],
      [84.3624115, 18.85106087],
      [84.3583374, 18.85134125],
      [84.35521698, 18.85264015],
      [84.35411835, 18.85149956],
      [84.35146332, 18.85090065],
      [84.3469696, 18.85997963],
      [84.34438324, 18.86276245],
      [84.33979034, 18.86592102],
      [84.33602142, 18.86671066],
      [84.33442688, 18.86384964],
      [84.33413696, 18.85799026],
      [84.33413696, 18.85156059],
      [84.33598328, 18.84142113],
      [84.3380661, 18.83621979],
      [84.34435272, 18.8327198],
      [84.34516907, 18.82678032],
      [84.3442688, 18.8227005],
      [84.34243011, 18.82150841],
      [84.34075928, 18.81825066],
      [84.34410095, 18.81194115],
      [84.32932281, 18.80899811],
      [84.32952881, 18.8045311],
      [84.33358765, 18.79505157],
      [84.33222198, 18.79202271],
      [84.3269577, 18.78967285],
      [84.3157196, 18.78617096],
      [84.31292725, 18.78218079],
      [84.31249237, 18.77919006],
      [84.30885315, 18.77841949],
      [84.30716705, 18.78166008],
      [84.30432129, 18.78071976],
      [84.30036926, 18.78174019],
      [84.28392029, 18.79082108],
      [84.27854919, 18.78968048],
      [84.27533722, 18.78718185],
      [84.27321625, 18.78248978],
      [84.26818848, 18.78030968],
      [84.26422119, 18.78051949],
      [84.25888824, 18.77482986],
      [84.25218964, 18.77248192],
      [84.24603271, 18.7734108],
      [84.23116302, 18.78012085],
      [84.23069, 18.78564072],
      [84.22698975, 18.78716087],
      [84.22429657, 18.78426933],
      [84.22145844, 18.78647995],
      [84.21671295, 18.78728104],
      [84.2038269, 18.78264046],
      [84.19033813, 18.77673912],
      [84.18651581, 18.77960014],
      [84.18151093, 18.77752113],
      [84.1798172, 18.76897049],
      [84.16703033, 18.77013969],
      [84.16262817, 18.76856995],
      [84.15818787, 18.76918983],
      [84.16027069, 18.77231979],
      [84.15934753, 18.77503967],
      [84.15493011, 18.77601051],
      [84.15136719, 18.77576065],
      [84.14811707, 18.77176094],
      [84.14042664, 18.76769066],
      [84.13497925, 18.76678848],
      [84.13136292, 18.76480293],
      [84.12793732, 18.76126862],
      [84.12948608, 18.75286293],
      [84.12294006, 18.74826813],
      [84.118927, 18.75303078],
      [84.11406708, 18.7551403],
      [84.10758972, 18.7548008],
      [84.10072327, 18.75267982],
      [84.09163666, 18.74523926],
      [84.08878326, 18.74711037],
      [84.08557892, 18.74518013],
      [84.08177948, 18.74464035],
      [84.07933044, 18.74611092],
      [84.07788086, 18.74888039],
      [84.06989288, 18.74975967],
      [84.06577301, 18.75169945],
      [84.06046295, 18.76267052],
      [84.05860901, 18.76810074],
      [84.05355835, 18.76836967],
      [84.04920197, 18.77065086],
      [84.04804993, 18.77440262],
      [84.04972076, 18.77730942],
      [84.04760742, 18.77987289],
      [84.04794312, 18.78347969],
      [84.03871918, 18.78742981],
      [84.0342865, 18.79012108],
      [84.03077698, 18.79544067],
      [84.03071594, 18.80027962],
      [84.02706146, 18.80220032],
      [84.02372742, 18.80115128],
      [84.02231598, 18.79862976],
      [84.01966095, 18.79762268],
      [84.01586914, 18.80141068],
      [84.01249695, 18.80063057],
      [84.00739288, 18.80421066],
      [83.99997711, 18.8047905],
      [83.991539, 18.8046627],
      [83.98232269, 18.80001068],
      [83.96938324, 18.80382919],
      [83.9640274, 18.80410957],
      [83.95561218, 18.80306053],
      [83.95121002, 18.8000412],
      [83.94902039, 18.79704094],
      [83.94368744, 18.79628754],
      [83.93978882, 18.79660034],
      [83.93657684, 18.79920006],
      [83.93572235, 18.8031292],
      [83.93358612, 18.80685806],
      [83.9263916, 18.80656052],
      [83.9226532, 18.80853271],
      [83.92059326, 18.81157112],
      [83.90775299, 18.81638908],
      [83.90197754, 18.81692123],
      [83.89855194, 18.81644058],
      [83.89547729, 18.81487846],
      [83.89064026, 18.80686951],
      [83.88687897, 18.80540848],
      [83.88535309, 18.81004143],
      [83.88463593, 18.8178196],
      [83.88323975, 18.8196907],
      [83.87391663, 18.8177681],
      [83.87017822, 18.81842995],
      [83.86686707, 18.82345009],
      [83.86434174, 18.83904076],
      [83.85906982, 18.84301186],
      [83.84797668, 18.8476181],
      [83.84366608, 18.85140038],
      [83.83709717, 18.86470985],
      [83.83600616, 18.8691082],
      [83.83669281, 18.87272072],
      [83.8423233, 18.88824081],
      [83.84163666, 18.89763069],
      [83.840271, 18.90406036],
      [83.83772278, 18.90860939],
      [83.83487701, 18.90929985],
      [83.82305145, 18.90546036],
      [83.81845093, 18.90711975],
      [83.81693268, 18.90940285],
      [83.82116699, 18.92196083],
      [83.81890106, 18.92609978],
      [83.814888, 18.93003845],
      [83.8134079, 18.9335804],
      [83.81606293, 18.9486599],
      [83.81476593, 18.95317841],
      [83.81063843, 18.9648304],
      [83.80809021, 18.96840286],
      [83.80253601, 18.98180962],
      [83.79116821, 18.99235916],
      [83.78894806, 18.99833107],
      [83.78830719, 19.00781059],
      [83.78704071, 19.00836945],
      [83.7859726, 19.00878906],
      [83.78486633, 19.00909042],
      [83.78375244, 19.00933075],
      [83.78263855, 19.00957108],
      [83.78148651, 19.00979042],
      [83.78037262, 19.0099411],
      [83.77887726, 19.01000977],
      [83.77774048, 19.00988007],
      [83.77664948, 19.00951958],
      [83.77565002, 19.00896072],
      [83.77474213, 19.00824928],
      [83.77392578, 19.00745964],
      [83.77320099, 19.00658989],
      [83.77259827, 19.00566101],
      [83.77204895, 19.0046711],
      [83.7715683, 19.00366974],
      [83.77111816, 19.00263977],
      [83.7706604, 19.0016098],
      [83.77021027, 19.00059128],
      [83.76941681, 18.99977112],
      [83.76850128, 18.99908066],
      [83.76747894, 18.99842834],
      [83.7664032, 18.99795914],
      [83.7652359, 18.99760056],
      [83.76405334, 18.99731064],
      [83.76288605, 18.99705124],
      [83.76171112, 18.99677849],
      [83.76056671, 18.9964695],
      [83.75946808, 18.99605942],
      [83.75845337, 18.99547005],
      [83.75778961, 18.99455833],
      [83.75695801, 18.99390984],
      [83.75582886, 18.99361038],
      [83.75469208, 18.99337006],
      [83.75354767, 18.99316978],
      [83.75242615, 18.99333191],
      [83.75128174, 18.99328041],
      [83.75061798, 18.99241829],
      [83.74923706, 18.99067116],
      [83.74108124, 18.98389053],
      [83.73956299, 18.97844124],
      [83.74172211, 18.96705818],
      [83.74597168, 18.9632206],
      [83.74900055, 18.95844078],
      [83.74836731, 18.95137024],
      [83.75036621, 18.94437027],
      [83.75520325, 18.94202995],
      [83.76148224, 18.94460106],
      [83.76525116, 18.93642044],
      [83.76986694, 18.93297005],
      [83.77715302, 18.9190712],
      [83.78006744, 18.90772057],
      [83.77739716, 18.90439987],
      [83.77127075, 18.90130043],
      [83.77050018, 18.90159035],
      [83.76750183, 18.90236282],
      [83.7628479, 18.90653992],
      [83.75614929, 18.91669083],
      [83.74582672, 18.91817284],
      [83.74214935, 18.92313957],
      [83.74140167, 18.93029022],
      [83.73992157, 18.93406105],
      [83.73649597, 18.93897057],
      [83.73002625, 18.9601593],
      [83.72746277, 18.96607971],
      [83.7263031, 18.97556114],
      [83.71689606, 18.98253822],
      [83.71140289, 18.98495102],
      [83.70880127, 18.9838028],
      [83.70574188, 18.98524094],
      [83.70484924, 18.99200821],
      [83.70469666, 18.99248123],
      [83.70436096, 18.99530983],
      [83.70500183, 18.99946976],
      [83.70941925, 19.00195122],
      [83.71478271, 19.00213051],
      [83.71984863, 19.00428963],
      [83.72325134, 19.01189995],
      [83.72128296, 19.0134182],
      [83.71682739, 19.01444817],
      [83.69983673, 19.01503181],
      [83.69702148, 19.01647949],
      [83.69415283, 19.02114105],
      [83.68438721, 19.04393005],
      [83.67637634, 19.05245018],
      [83.66673279, 19.06446075],
      [83.66261292, 19.06649017],
      [83.65969849, 19.0697403],
      [83.65685272, 19.07493973],
      [83.65809631, 19.08210945],
      [83.65748596, 19.08777237],
      [83.65026855, 19.10009956],
      [83.6479187, 19.10225105],
      [83.647789, 19.10594177],
      [83.64588165, 19.10873032],
      [83.64472961, 19.11329079],
      [83.64627075, 19.11449814],
      [83.64974976, 19.11482048],
      [83.65072632, 19.11840057],
      [83.64943695, 19.12187958],
      [83.64556122, 19.12310028],
      [83.63331604, 19.1244297],
      [83.62983704, 19.12699127],
      [83.62844086, 19.13150024],
      [83.63018799, 19.14479065],
      [83.62850952, 19.14974022],
      [83.62328339, 19.15363121],
      [83.62116241, 19.15192032],
      [83.62023926, 19.14862823],
      [83.62271881, 19.14468765],
      [83.619133, 19.14188194],
      [83.61408997, 19.13381958],
      [83.61060333, 19.13093758],
      [83.60521698, 19.13103294],
      [83.60051727, 19.12770081],
      [83.59429932, 19.12033081],
      [83.59365082, 19.11548996],
      [83.59429932, 19.11058044],
      [83.59677887, 19.10519981],
      [83.60440063, 19.09287071],
      [83.60441589, 19.08818054],
      [83.59986115, 19.08480263],
      [83.59320831, 19.08489037],
      [83.58763123, 19.08307076],
      [83.58444977, 19.07973099],
      [83.5821991, 19.07563972],
      [83.58178711, 19.06277275],
      [83.58078003, 19.05514908],
      [83.57876587, 19.04978943],
      [83.56735992, 19.05225945],
      [83.56105042, 19.05038834],
      [83.55766296, 19.05138016],
      [83.556427, 19.05583954],
      [83.55710602, 19.05998993],
      [83.56038666, 19.06396103],
      [83.56067657, 19.06622124],
      [83.55497742, 19.07813072],
      [83.55149078, 19.07785034],
      [83.5464325, 19.07382965],
      [83.54485321, 19.06940269],
      [83.54486847, 19.06208038],
      [83.55148315, 19.05038071],
      [83.5522995, 19.04718971],
      [83.54772949, 19.04496956],
      [83.54277802, 19.03684044],
      [83.53578949, 19.03034973],
      [83.53482819, 19.02861977],
      [83.53309631, 19.01938248],
      [83.53311157, 19.01246071],
      [83.53138733, 19.00542068],
      [83.51529694, 19.00490952],
      [83.50460052, 19.01009941],
      [83.49539948, 19.01109123],
      [83.49292755, 19.01803017],
      [83.49263, 19.02398109],
      [83.49355316, 19.02680016],
      [83.49931335, 19.02708054],
      [83.50392914, 19.02620125],
      [83.50817871, 19.02197075],
      [83.51367188, 19.02478027],
      [83.51322937, 19.03032112],
      [83.51008606, 19.03617287],
      [83.50979614, 19.04008102],
      [83.51074982, 19.04800034],
      [83.50847626, 19.0499115],
      [83.50617981, 19.05430984],
      [83.50538635, 19.06477165],
      [83.50287628, 19.06466103],
      [83.50036621, 19.06208038],
      [83.49633026, 19.05550957],
      [83.49362946, 19.05308914],
      [83.49249268, 19.05495071],
      [83.48043823, 19.06648064],
      [83.47817993, 19.07022095],
      [83.47698975, 19.07533073],
      [83.47323608, 19.0777626],
      [83.47202301, 19.07274055],
      [83.47027588, 19.07032013],
      [83.46746826, 19.06987953],
      [83.46382904, 19.0714016],
      [83.45939636, 19.06967163],
      [83.45826721, 19.06713295],
      [83.45913696, 19.06348991],
      [83.45736694, 19.0598011],
      [83.45670319, 19.05611038],
      [83.46327209, 19.05260086],
      [83.46279907, 19.04395103],
      [83.46543884, 19.03619003],
      [83.47735596, 19.02551842],
      [83.47796631, 19.02119064],
      [83.47511292, 19.01152992],
      [83.47663879, 19.00407028],
      [83.47460175, 19.00031281],
      [83.4718399, 18.99897957],
      [83.46750641, 18.99891853],
      [83.45848083, 19.00209808],
      [83.45327759, 19.00246048],
      [83.45017242, 19.00188828],
      [83.44851685, 18.99963188],
      [83.44822693, 18.99594116],
      [83.44908905, 18.99261093],
      [83.45022583, 18.98242188],
      [83.4577179, 18.98250008],
      [83.46029663, 18.98077965],
      [83.45831299, 18.97247124],
      [83.45894623, 18.97044945],
      [83.46147156, 18.9679203],
      [83.46224213, 18.96359062],
      [83.46227264, 18.95922089],
      [83.45548248, 18.94557953],
      [83.45120239, 18.94445992],
      [83.44684601, 18.94564819],
      [83.44267273, 18.94774055],
      [83.44001007, 18.95022011],
      [83.43647766, 18.95787811],
      [83.43292236, 18.96139908],
      [83.4148407, 18.97787285],
      [83.40878296, 18.97989082],
      [83.41142273, 18.98871994],
      [83.41057587, 18.99261093],
      [83.40885162, 18.99619102],
      [83.4068985, 18.99508095],
      [83.4046402, 18.99320984],
      [83.40231323, 18.99516106],
      [83.40142059, 18.99638176],
      [83.39605713, 18.99583054],
      [83.39295959, 18.99912834],
      [83.3887558, 19.00001907],
      [83.38471985, 18.99727249],
      [83.37719727, 18.99472046],
      [83.36747742, 18.99361992],
      [83.36232758, 18.99473],
      [83.35800934, 19.00613976],
      [83.35498047, 19.00938988],
      [83.34633636, 19.0098896],
      [83.34091187, 19.0091629],
      [83.3349762, 19.00671959],
      [83.33271027, 18.99983978],
      [83.33067322, 18.99842834],
      [83.33010101, 18.99695969],
      [83.33075714, 18.99257088],
      [83.33120728, 18.99007034],
      [83.32917786, 18.98818016],
      [83.32537842, 18.98880959],
      [83.31643677, 18.99040031],
      [83.30783844, 18.99094963],
      [83.30526733, 18.98968124],
      [83.30432892, 18.98698997],
      [83.30774689, 18.98324966],
      [83.30914307, 18.98035049],
      [83.30961609, 18.9768219],
      [83.31334686, 18.96663094],
      [83.31980896, 18.96133041],
      [83.32055664, 18.96103096],
      [83.32556152, 18.95897102],
      [83.32691956, 18.95734024],
      [83.32565308, 18.95292282],
      [83.32601166, 18.95016289],
      [83.32704163, 18.94790077],
      [83.32888794, 18.94682121],
      [83.33104706, 18.94581032],
      [83.3324585, 18.94337082],
      [83.33302307, 18.93931961],
      [83.33113098, 18.93763924],
      [83.32119751, 18.93602943],
      [83.3184967, 18.93362045],
      [83.31887817, 18.92843819],
      [83.31991577, 18.92581749],
      [83.32202911, 18.9232502],
      [83.32901001, 18.91690826],
      [83.33454132, 18.91582108],
      [83.33905792, 18.91620064],
      [83.34136963, 18.91839981],
      [83.33957672, 18.92620087],
      [83.33953857, 18.9294796],
      [83.33952332, 18.93191147],
      [83.33972931, 18.93275261],
      [83.33983612, 18.93321991],
      [83.34326172, 18.93516159],
      [83.34480286, 18.94524956],
      [83.34501648, 18.95630074],
      [83.34575653, 18.95709991],
      [83.34903717, 18.95609093],
      [83.35440826, 18.95445061],
      [83.35890198, 18.95471191],
      [83.36022949, 18.95389938],
      [83.36106873, 18.95340157],
      [83.36084747, 18.94811058],
      [83.36389923, 18.93793106],
      [83.36741638, 18.93349075],
      [83.38022614, 18.92328262],
      [83.37966156, 18.92152023],
      [83.37634277, 18.91777039],
      [83.37384033, 18.91679192],
      [83.3710022, 18.91708946],
      [83.36406708, 18.92278862],
      [83.36276245, 18.93007088],
      [83.35523987, 18.93303108],
      [83.34902954, 18.9338398],
      [83.34792328, 18.93214035],
      [83.34661102, 18.92687035],
      [83.34768677, 18.92098808],
      [83.35016632, 18.91592979],
      [83.35649872, 18.90480995],
      [83.35777283, 18.9036808],
      [83.36052704, 18.90117073],
      [83.36631012, 18.9004612],
      [83.36715698, 18.90036011],
      [83.37684631, 18.90246964],
      [83.3809967, 18.90182114],
      [83.38391113, 18.89969063],
      [83.38326263, 18.89723969],
      [83.37444305, 18.89194107],
      [83.37248993, 18.88983917],
      [83.37390137, 18.88591194],
      [83.37890625, 18.88027954],
      [83.38568115, 18.87549019],
      [83.38539124, 18.87326241],
      [83.38780975, 18.87103081],
      [83.39425659, 18.8682003],
      [83.40177917, 18.86707115],
      [83.40432739, 18.86598015],
      [83.40585327, 18.86532974],
      [83.41133881, 18.85934067],
      [83.41073608, 18.85676193],
      [83.40875244, 18.85445023],
      [83.40680695, 18.85367966],
      [83.39985657, 18.85365105],
      [83.39787292, 18.85259056],
      [83.39694214, 18.84921074],
      [83.39871216, 18.83352852],
      [83.39630127, 18.83070946],
      [83.38648987, 18.82576942],
      [83.38313293, 18.82607079],
      [83.3763504, 18.82195091],
      [83.37283325, 18.82121086],
      [83.36226654, 18.83210945],
      [83.36032104, 18.83204079],
      [83.35887909, 18.83200073],
      [83.35349274, 18.82748985],
      [83.35011292, 18.82163811],
      [83.35999298, 18.80735016],
      [83.36022949, 18.80447006],
      [83.35848236, 18.80179977],
      [83.35629272, 18.8001194],
      [83.3524704, 18.80018997],
      [83.34780884, 18.80125809],
      [83.34429169, 18.80293083],
      [83.34073639, 18.8035202],
      [83.33927917, 18.80309105],
      [83.33812714, 18.80035019],
      [83.33761597, 18.79570007],
      [83.33687592, 18.79465294],
      [83.33587646, 18.79321289],
      [83.33333588, 18.7926712],
      [83.32978821, 18.79372978],
      [83.32614136, 18.79482079],
      [83.32302094, 18.79828072],
      [83.31826019, 18.79584122],
      [83.31404114, 18.79580116],
      [83.30422211, 18.79887009],
      [83.30036926, 18.79791069],
      [83.29210663, 18.79253006],
      [83.27950287, 18.78960037],
      [83.2752533, 18.78536987],
      [83.26992035, 18.77528763],
      [83.26914978, 18.77075958],
      [83.27124786, 18.76732063],
      [83.27171326, 18.76317978],
      [83.26991272, 18.75980949],
      [83.26609802, 18.75700951],
      [83.2593689, 18.75760078],
      [83.25541687, 18.75881958],
      [83.24508667, 18.75808907],
      [83.23956299, 18.75878143],
      [83.22931671, 18.76414108],
      [83.22651672, 18.76490021],
      [83.22271729, 18.76593018],
      [83.22103119, 18.76587105],
      [83.21939087, 18.76581955],
      [83.21707916, 18.76356125],
      [83.21733093, 18.75895119],
      [83.22189331, 18.75202179],
      [83.22268677, 18.74861908],
      [83.2230072, 18.74798012],
      [83.22235107, 18.74505043],
      [83.22033691, 18.74204826],
      [83.21446228, 18.73719978],
      [83.21363831, 18.73602104],
      [83.2125473, 18.7344799],
      [83.20918274, 18.72454071],
      [83.20677948, 18.71749115],
      [83.20506287, 18.71586037],
      [83.20332336, 18.7195797],
      [83.20366669, 18.72350121],
      [83.20641327, 18.72742081],
      [83.20633698, 18.72803116],
      [83.20626068, 18.7287426],
      [83.2024231, 18.73254967],
      [83.20188904, 18.73613167],
      [83.20342255, 18.74035263],
      [83.20170593, 18.74222946],
      [83.19181824, 18.74178123],
      [83.1853714, 18.74414253],
      [83.18080139, 18.74825096],
      [83.17701721, 18.75440979],
      [83.17394257, 18.75676918],
      [83.16854858, 18.75906944],
      [83.15622711, 18.75731087],
      [83.15083313, 18.75868988],
      [83.14686584, 18.76317978],
      [83.14491272, 18.76690102],
      [83.13884735, 18.77113914],
      [83.13295746, 18.77244949],
      [83.11580658, 18.76208115],
      [83.11219025, 18.7582798],
      [83.11752319, 18.75048065],
      [83.11650085, 18.74797058],
      [83.10600281, 18.73849106],
      [83.09921265, 18.72689056],
      [83.09546661, 18.72309113],
      [83.09259033, 18.7217617],
      [83.09079742, 18.71925926],
      [83.08960724, 18.71627045],
      [83.0880661, 18.71240044],
      [83.08574677, 18.70998001],
      [83.07788086, 18.70533943],
      [83.07073212, 18.69719124],
      [83.0690918, 18.69405174],
      [83.06987762, 18.69188118],
      [83.07080841, 18.68930054],
      [83.05584717, 18.66714096],
      [83.05146027, 18.6632309],
      [83.05097198, 18.65415955],
      [83.03616333, 18.64768982],
      [83.0226059, 18.64435959],
      [83.01154327, 18.63903046],
      [83.0096817, 18.63652992],
      [83.00948334, 18.63354111],
      [83.00934601, 18.63148117],
      [83.01132202, 18.62771034],
      [83.01461029, 18.62626076],
      [83.01737976, 18.62339973],
      [83.02126312, 18.61045074],
      [83.02030182, 18.60541153],
      [83.00948334, 18.5914402],
      [83.00804138, 18.58842087],
      [83.01586914, 18.58477974],
      [83.02770233, 18.58227158],
      [83.03114319, 18.58358955],
      [83.03781891, 18.58871269],
      [83.03970337, 18.59207916],
      [83.04065704, 18.59526253],
      [83.04110718, 18.59673119],
      [83.04891968, 18.59526253],
      [83.04946899, 18.59486961],
      [83.05208588, 18.59305954],
      [83.05297852, 18.58993912],
      [83.05394745, 18.58646965],
      [83.0511322, 18.58307076],
      [83.0447464, 18.57817078],
      [83.04203796, 18.57730293],
      [83.04171753, 18.5773201],
      [83.03650665, 18.57757187],
      [83.03376007, 18.57571983],
      [83.03114319, 18.5717907],
      [83.0268631, 18.56796074],
      [83.02545929, 18.56430054],
      [83.03370667, 18.55829048],
      [83.03247833, 18.54788971],
      [83.04023743, 18.54489136],
      [83.04509735, 18.53932953],
      [83.05117798, 18.5349102],
      [83.05414581, 18.53479958],
      [83.05735779, 18.53469086],
      [83.0714798, 18.54249191],
      [83.08705139, 18.54105949],
      [83.08933258, 18.53791046],
      [83.08850861, 18.53276062],
      [83.08230591, 18.52717018],
      [83.07315826, 18.51473999],
      [83.06881714, 18.50202942],
      [83.06511688, 18.4975605],
      [83.06035614, 18.49442101],
      [83.05517578, 18.48812294],
      [83.05179596, 18.47748947],
      [83.04384613, 18.47211075],
      [83.03701782, 18.46313095],
      [83.03414917, 18.46076965],
      [83.02672577, 18.45692062],
      [83.02455902, 18.45369911],
      [83.01644897, 18.44767952],
      [83.01541138, 18.4444294],
      [83.01803589, 18.44040108],
      [83.02484131, 18.43666077],
      [83.032341, 18.43618965],
      [83.037323, 18.43482971],
      [83.03900909, 18.43204117],
      [83.03819275, 18.42671013],
      [83.03330231, 18.42333984],
      [83.03555298, 18.42007065],
      [83.03743744, 18.41894913],
      [83.04261017, 18.41829109],
      [83.0485611, 18.42486],
      [83.05825806, 18.42905998],
      [83.05982971, 18.42863274],
      [83.061203, 18.42394066],
      [83.06208801, 18.42088127],
      [83.06062317, 18.41924286],
      [83.06046295, 18.41348839],
      [83.06035614, 18.41337967],
      [83.05531311, 18.40811157],
      [83.05581665, 18.40436935],
      [83.05903625, 18.3990097],
      [83.06373596, 18.39570999],
      [83.06582642, 18.39315987],
      [83.0664978, 18.3922596],
      [83.06582642, 18.38940048],
      [83.05619812, 18.37726021],
      [83.0533905, 18.37529945],
      [83.03952026, 18.37008095],
      [83.03697968, 18.36984253],
      [83.03188324, 18.3732605],
      [83.02375793, 18.37644958],
      [83.02005005, 18.38348007],
      [83.0171814, 18.3845005],
      [83.01161194, 18.37821007],
      [83.00348663, 18.3769207],
      [82.99711609, 18.37486076],
      [82.99286652, 18.37482071],
      [82.99196625, 18.37499809],
      [82.98761749, 18.36950111],
      [82.98610687, 18.36590195],
      [82.9812088, 18.36581993],
      [82.9784317, 18.36098862],
      [82.97644806, 18.3549118],
      [82.96878052, 18.35758018],
      [82.95979309, 18.35724068],
      [82.95597076, 18.35581017],
      [82.95204926, 18.35434914],
      [82.94816589, 18.35206032],
      [82.94550323, 18.35046959],
      [82.93268585, 18.35136986],
      [82.9276123, 18.36098862],
      [82.9200592, 18.3597393],
      [82.91513062, 18.35639954],
      [82.91166687, 18.35737991],
      [82.90838623, 18.35943031],
      [82.90274811, 18.35591125],
      [82.89852142, 18.35930061],
      [82.89186096, 18.36688042],
      [82.88490295, 18.37862015],
      [82.89095306, 18.39221954],
      [82.89077759, 18.39859962],
      [82.88835907, 18.40625954],
      [82.88461304, 18.41275978],
      [82.87935638, 18.41464043],
      [82.87706757, 18.4118309],
      [82.87001038, 18.40620041],
      [82.86296844, 18.40792084],
      [82.85597992, 18.41080093],
      [82.84863281, 18.41500854],
      [82.84490967, 18.40977287],
      [82.83978271, 18.41177177],
      [82.83698273, 18.4142704],
      [82.83634186, 18.41954041],
      [82.83576965, 18.42423058],
      [82.83014679, 18.42612076],
      [82.82417297, 18.4299202],
      [82.81874847, 18.43337059],
      [82.81835938, 18.43852997],
      [82.79917145, 18.43940163],
      [82.79197693, 18.4331398],
      [82.78710938, 18.42674065],
      [82.77622223, 18.41969109],
      [82.77620697, 18.41551971],
      [82.77845764, 18.40947914],
      [82.78160858, 18.4042511],
      [82.78118134, 18.3994503],
      [82.78739166, 18.39982033],
      [82.79329681, 18.39496994],
      [82.78748322, 18.3902092],
      [82.78675842, 18.38458061],
      [82.78761292, 18.37816048],
      [82.78096771, 18.37252045],
      [82.77790833, 18.37092018],
      [82.78421783, 18.35415077],
      [82.7714386, 18.33967972],
      [82.7670517, 18.33107948],
      [82.76184845, 18.32781029],
      [82.75849915, 18.32718086],
      [82.7544632, 18.32982063],
      [82.7510376, 18.3283329],
      [82.7506485, 18.32329941],
      [82.74642944, 18.31985092],
      [82.73781586, 18.31990051],
      [82.73280334, 18.3178997],
      [82.73432922, 18.3132515],
      [82.72531128, 18.31157112],
      [82.71726227, 18.31482124],
      [82.71090698, 18.31282043],
      [82.70670319, 18.31063271],
      [82.7025528, 18.30846977],
      [82.70169067, 18.30484009],
      [82.70050049, 18.29978943],
      [82.69809723, 18.29627037],
      [82.69374084, 18.29733086],
      [82.69032288, 18.29706955],
      [82.68177795, 18.29277992],
      [82.67539978, 18.29236984],
      [82.6725769, 18.29220009],
      [82.66493988, 18.2906208],
      [82.65883636, 18.28660965],
      [82.65648651, 18.27880096],
      [82.65222931, 18.2721405],
      [82.64910889, 18.26383972],
      [82.64485931, 18.25707817],
      [82.64758301, 18.2447319],
      [82.64530945, 18.24044991],
      [82.63716888, 18.23493004],
      [82.63165283, 18.22853088],
      [82.6288681, 18.22866058],
      [82.62687683, 18.22874069],
      [82.62039185, 18.23637009],
      [82.6164093, 18.23812103],
      [82.61341858, 18.23942947],
      [82.61103058, 18.24384117],
      [82.61181641, 18.24651146],
      [82.60948181, 18.24925041],
      [82.60076904, 18.25201988],
      [82.5982666, 18.25447273],
      [82.58920288, 18.25672913],
      [82.58866882, 18.26211929],
      [82.58724976, 18.2657795],
      [82.58547211, 18.26612091],
      [82.58318329, 18.26655006],
      [82.58074188, 18.27009964],
      [82.58428192, 18.27322006],
      [82.58846283, 18.27504921],
      [82.58840179, 18.27833939],
      [82.58722687, 18.27943993],
      [82.58558655, 18.28096008],
      [82.58757782, 18.2847805],
      [82.59031677, 18.28778076],
      [82.59095001, 18.29195976],
      [82.58783722, 18.3020401],
      [82.58270264, 18.30080032],
      [82.58107758, 18.30093956],
      [82.57978058, 18.30104065],
      [82.5764389, 18.30681038],
      [82.57805634, 18.30935097],
      [82.58325195, 18.3114109],
      [82.58596039, 18.3159008],
      [82.58863831, 18.31847954],
      [82.58966827, 18.3194828],
      [82.59229279, 18.31792068],
      [82.59568787, 18.3159008],
      [82.60214233, 18.31726074],
      [82.60382843, 18.32037163],
      [82.60031891, 18.33076096],
      [82.59757996, 18.33361816],
      [82.59651184, 18.3408699],
      [82.59783173, 18.34164047],
      [82.59915161, 18.34242058],
      [82.60787964, 18.34167099],
      [82.61128235, 18.34646034],
      [82.60865784, 18.35123062],
      [82.60566711, 18.35280991],
      [82.59857941, 18.35401154],
      [82.5976181, 18.35621834],
      [82.59931183, 18.35997009],
      [82.59768677, 18.3625927],
      [82.60087585, 18.36605072],
      [82.60050964, 18.36870956],
      [82.59902191, 18.37161827],
      [82.59364319, 18.37049103],
      [82.58917236, 18.37713814],
      [82.58080292, 18.37885094],
      [82.57733154, 18.37732124],
      [82.57556152, 18.37986946],
      [82.57060242, 18.38418961],
      [82.56521606, 18.38398933],
      [82.55603027, 18.38850975],
      [82.55172729, 18.38708115],
      [82.54944611, 18.38351059],
      [82.54234314, 18.38904953],
      [82.53515625, 18.3895607],
      [82.53230286, 18.39344978],
      [82.5326004, 18.39682007],
      [82.54032135, 18.40187073],
      [82.54057312, 18.40343094],
      [82.53968811, 18.40362167],
      [82.53697968, 18.4041996],
      [82.53591156, 18.40831947],
      [82.5376358, 18.41222954],
      [82.53968048, 18.41686821],
      [82.54512024, 18.41724968],
      [82.54605865, 18.42414284],
      [82.55021667, 18.42650032],
      [82.55107117, 18.43390083],
      [82.55377197, 18.43765068],
      [82.55011749, 18.43889046],
      [82.54772949, 18.44178963],
      [82.54421234, 18.44129944],
      [82.5383606, 18.44318008],
      [82.53900909, 18.43808937],
      [82.53560638, 18.43665123],
      [82.53308105, 18.43406105],
      [82.53015137, 18.43431091],
      [82.52879333, 18.44034004],
      [82.5293808, 18.44293976],
      [82.52670288, 18.44730949],
      [82.52316284, 18.44577026],
      [82.52120209, 18.44711113],
      [82.52043915, 18.45053291],
      [82.52319336, 18.45277977],
      [82.51613617, 18.45588112],
      [82.51676178, 18.45721054],
      [82.51774597, 18.45928955],
      [82.52176666, 18.46175957],
      [82.5275116, 18.46821976],
      [82.52572632, 18.470541],
      [82.52101135, 18.47237015],
      [82.52752686, 18.47772026],
      [82.52448273, 18.48284912],
      [82.51951599, 18.48251152],
      [82.51979065, 18.48488998],
      [82.52005768, 18.48726082],
      [82.52449799, 18.48978043],
      [82.52321625, 18.4935112],
      [82.52202606, 18.4969902],
      [82.52390289, 18.49929047],
      [82.52313232, 18.50392914],
      [82.51751709, 18.50563812],
      [82.51426697, 18.50363922],
      [82.50740814, 18.50807953],
      [82.50418091, 18.50549126],
      [82.50202179, 18.50173187],
      [82.49915314, 18.50175095],
      [82.49880981, 18.5032711],
      [82.49820709, 18.50604057],
      [82.48145294, 18.51506042],
      [82.47573853, 18.5294075],
      [82.47783661, 18.53153038],
      [82.48191833, 18.53271103],
      [82.48532104, 18.53370094],
      [82.48161316, 18.53845978],
      [82.47400665, 18.53683281],
      [82.45342255, 18.51702118],
      [82.44993591, 18.5114212],
      [82.43712616, 18.4973011],
      [82.42913818, 18.48514175],
      [82.42687225, 18.47699165],
      [82.42032623, 18.46988106],
      [82.41220093, 18.46467018],
      [82.39737701, 18.45160103],
      [82.38227081, 18.43169975],
      [82.38065338, 18.42642975],
      [82.37760162, 18.42198944],
      [82.37174225, 18.42016983],
      [82.36702728, 18.41716957],
      [82.36599731, 18.41301155],
      [82.36128998, 18.4099102],
      [82.36599731, 18.40335274],
      [82.37251282, 18.39809036],
      [82.37490845, 18.39281082],
      [82.37523651, 18.38306046],
      [82.37841034, 18.37813187],
      [82.38443756, 18.37240982],
      [82.38442993, 18.36911011],
      [82.38109589, 18.36668968],
      [82.37879944, 18.3616581],
      [82.37970734, 18.3574295],
      [82.3763504, 18.35326958],
      [82.36947632, 18.35036278],
      [82.36013794, 18.34370041],
      [82.35656738, 18.33357048],
      [82.35021973, 18.32891083],
      [82.34542084, 18.32181931],
      [82.33641815, 18.32011986],
      [82.33444214, 18.31713295],
      [82.34101105, 18.31065941],
      [82.34290314, 18.30734062],
      [82.34874725, 18.30615044],
      [82.35456085, 18.309021],
      [82.35797119, 18.30824089],
      [82.36524963, 18.31642914],
      [82.3691864, 18.31775284],
      [82.37510681, 18.31800079],
      [82.38049316, 18.31931114],
      [82.3843689, 18.31870079],
      [82.38842773, 18.3112011],
      [82.393013, 18.30644035],
      [82.38735199, 18.30062294],
      [82.38540649, 18.28909111],
      [82.3822403, 18.28429031],
      [82.37187958, 18.27410126],
      [82.3670578, 18.27094078],
      [82.34365082, 18.27165031],
      [82.3368988, 18.26624107],
      [82.33390045, 18.25229073],
      [82.33180237, 18.24679184],
      [82.33203888, 18.24012947],
      [82.33100891, 18.23336029],
      [82.33022308, 18.23134041],
      [82.32253265, 18.22812843],
      [82.32131958, 18.22245979],
      [82.32673645, 18.21623039],
      [82.33281708, 18.21578979],
      [82.31973267, 18.20104027],
      [82.30609894, 18.19565964],
      [82.30943298, 18.18938065],
      [82.30821991, 18.18128014],
      [82.31030273, 18.17460251],
      [82.31931305, 18.17143059],
      [82.32479095, 18.16641998],
      [82.33302307, 18.16341972],
      [82.34160614, 18.16343117],
      [82.34873199, 18.16183281],
      [82.35897827, 18.1491394],
      [82.36515808, 18.13966942],
      [82.36429596, 18.13485909],
      [82.361763, 18.12919807],
      [82.35756683, 18.12510109],
      [82.34487152, 18.13462067],
      [82.33825684, 18.13796043],
      [82.33336639, 18.14237976],
      [82.33535767, 18.1272583],
      [82.33030701, 18.11899948],
      [82.33248901, 18.10704994],
      [82.33216095, 18.09412193],
      [82.33466339, 18.08988762],
      [82.34055328, 18.08708954],
      [82.34316254, 18.08247948],
      [82.34362793, 18.07499123],
      [82.34194183, 18.07130051],
      [82.3431778, 18.0678196],
      [82.33692932, 18.04910088],
      [82.33634949, 18.04726028],
      [82.32977295, 18.040411],
      [82.32491302, 18.04269028],
      [82.3261795, 18.0465107],
      [82.32182312, 18.05018044],
      [82.31964874, 18.04857063],
      [82.31730652, 18.04853058],
      [82.31572723, 18.04668045],
      [82.31299591, 18.04519081],
      [82.3169632, 18.03602028],
      [82.31320953, 18.03475761],
      [82.30961609, 18.03842926],
      [82.30552673, 18.03780937],
      [82.30441284, 18.03961945],
      [82.30634308, 18.04499054],
      [82.30105591, 18.04404068],
      [82.2964859, 18.04614067],
      [82.29280853, 18.05433273],
      [82.29640198, 18.05755806],
      [82.29789734, 18.06196022],
      [82.28777313, 18.06056023],
      [82.28505707, 18.0594101],
      [82.28302765, 18.05577087],
      [82.27894592, 18.05492973],
      [82.26695251, 18.04827118],
      [82.2651062, 18.0430603],
      [82.26460266, 18.039011],
      [82.26220703, 18.03630066],
      [82.25815582, 18.03487015],
      [82.26624298, 18.02569008],
      [82.26893616, 18.01993942],
      [82.27081299, 18.01066971],
      [82.27052307, 17.9977417],
      [82.26885223, 17.99198914],
      [82.26725006, 17.98644066],
      [82.26107788, 17.98240089],
      [82.25318909, 17.98019028],
      [82.24170685, 17.97976112],
      [82.2383194, 17.98390007],
      [82.23699188, 17.99201012],
      [82.23079681, 17.99370956],
      [82.22297668, 17.99287987],
      [82.2181778, 17.99419022],
      [82.20775604, 18.00070953],
      [82.1969223, 18.00868988],
      [82.19101715, 18.00895119],
      [82.18508911, 18.00735283],
      [82.17740631, 18.01057053],
      [82.17424774, 18.01550293],
      [82.17694092, 18.02089119],
      [82.17314911, 18.0255928],
      [82.16374969, 18.04162025],
      [82.16034698, 18.04359818],
      [82.14730835, 18.04042053],
      [82.14229584, 18.04356956],
      [82.13237, 18.03561974],
      [82.13041687, 18.04090118],
      [82.12924194, 18.04866982],
      [82.12708282, 18.0510006],
      [82.1193161, 18.05120087],
      [82.11326599, 18.05782127],
      [82.11058807, 18.05766106],
      [82.10797882, 18.05401993],
      [82.09236145, 18.05276108],
      [82.0891571, 18.05741119],
      [82.08006287, 18.0604496],
      [82.07221222, 18.06563759],
      [82.06989288, 18.06043053],
      [82.06440735, 18.05837059],
      [82.05898285, 18.0584507],
      [82.06098938, 18.05398178],
      [82.06546783, 18.05130005],
      [82.06564331, 18.04891968],
      [82.0597229, 18.04511833],
      [82.05085754, 18.0444603],
      [82.04516602, 18.04558945],
      [82.03263855, 18.05171013],
      [82.02986145, 18.05653191],
      [82.0246582, 18.0578804],
      [82.01677704, 18.0551281],
      [82.00663757, 18.04919815],
      [82.00157928, 18.04481125],
      [82.00473022, 18.03430939],
      [82.00211334, 18.0282402],
      [82.0016861, 18.02366066],
      [81.99147797, 18.02182007],
      [81.98886108, 18.02034187],
      [81.98587799, 18.01427078],
      [81.98046112, 18.01128006],
      [81.97305298, 18.00567055],
      [81.96404266, 17.99986839],
      [81.95713043, 17.99707031],
      [81.95176697, 17.99209976],
      [81.93609619, 17.98409081],
      [81.92471313, 17.98088074],
      [81.91369629, 17.97989082],
      [81.90454865, 17.97347069],
      [81.89927673, 17.96854973],
      [81.88507843, 17.96583939],
      [81.87413788, 17.95984077],
      [81.84681702, 17.95079994],
      [81.83969879, 17.95006943],
      [81.83333588, 17.95103073],
      [81.81980133, 17.94546127],
      [81.80104065, 17.93608093],
      [81.79405212, 17.9296608],
      [81.7893219, 17.92136955],
      [81.78601074, 17.91361046],
      [81.7824173, 17.90974045],
      [81.77574921, 17.9047699],
      [81.76222229, 17.89658928],
      [81.75829315, 17.89336014],
    ],
  ],
  [
    [
      [86.26335144, 20.05805969],
      [86.26167297, 20.05639076],
      [86.26139069, 20.05639076],
      [86.26029205, 20.05528069],
      [86.26000214, 20.05528069],
      [86.25971985, 20.05500031],
      [86.2591629, 20.05500031],
      [86.25860596, 20.05445099],
      [86.2583313, 20.05445099],
      [86.25805664, 20.05417061],
      [86.25776672, 20.05417061],
      [86.25749969, 20.05389023],
      [86.25721741, 20.05389023],
      [86.25692749, 20.05360985],
      [86.25666809, 20.05361176],
      [86.2563858, 20.05333328],
      [86.25611115, 20.05333328],
      [86.25590515, 20.05312538],
      [86.25527954, 20.05249977],
      [86.25444794, 20.05251122],
      [86.25415802, 20.05221939],
      [86.25389099, 20.05251122],
      [86.25332642, 20.05251122],
      [86.25307465, 20.05224609],
      [86.2527771, 20.0522213],
      [86.25250244, 20.05194473],
      [86.25222015, 20.05194473],
      [86.2519455, 20.05166626],
      [86.25166321, 20.05167007],
      [86.25138855, 20.05138969],
      [86.2508316, 20.05138969],
      [86.25055695, 20.05166626],
      [86.25055695, 20.05221939],
      [86.25026703, 20.05251122],
      [86.25055695, 20.05277824],
      [86.25055695, 20.0530529],
      [86.25195313, 20.05445099],
      [86.25222015, 20.05444336],
      [86.25333405, 20.05555534],
      [86.2536087, 20.05555534],
      [86.25389099, 20.05583382],
      [86.25415802, 20.05583],
      [86.25444794, 20.05611038],
      [86.2547226, 20.05611038],
      [86.25499725, 20.05639076],
      [86.25527954, 20.05638885],
      [86.25583649, 20.05694389],
      [86.25611115, 20.05694389],
      [86.2563858, 20.05722237],
      [86.25669861, 20.05725288],
      [86.25692749, 20.05750084],
      [86.25721741, 20.05750084],
      [86.25749969, 20.05778122],
      [86.2583313, 20.05778122],
      [86.25860596, 20.05805969],
      [86.2591629, 20.05805779],
      [86.25944519, 20.05833244],
      [86.2611084, 20.05833244],
      [86.26166534, 20.05888748],
      [86.26249695, 20.05888748],
      [86.26335144, 20.05805969],
    ],
  ],
  [
    [
      [86.25305176, 20.02528],
      [86.25332642, 20.02499962],
      [86.25444794, 20.02499962],
      [86.2547226, 20.02472115],
      [86.25527954, 20.02472115],
      [86.25583649, 20.02416611],
      [86.25611115, 20.02416992],
      [86.2583313, 20.02193832],
      [86.25749969, 20.02111053],
      [86.25721741, 20.02139091],
      [86.25692749, 20.02139091],
      [86.25666809, 20.02166939],
      [86.2563858, 20.02166748],
      [86.25611115, 20.02194405],
      [86.2547226, 20.02194405],
      [86.25444794, 20.02222252],
      [86.25415802, 20.02220917],
      [86.2536087, 20.02277946],
      [86.25333405, 20.02277756],
      [86.25166321, 20.02444458],
      [86.25166321, 20.02472115],
      [86.25195313, 20.02472115],
      [86.25250244, 20.02528],
      [86.25305176, 20.02528],
    ],
  ],
  [
    [
      [86.30999756, 20.04083252],
      [86.31027985, 20.04055405],
      [86.31083679, 20.04057121],
      [86.31111145, 20.04027939],
      [86.3125, 20.04027939],
      [86.31276703, 20.04000092],
      [86.3133316, 20.04000092],
      [86.31360626, 20.03861046],
      [86.3133316, 20.03833008],
      [86.31083679, 20.03833008],
      [86.31054688, 20.03861046],
      [86.31027985, 20.03861046],
      [86.30999756, 20.03889084],
      [86.30916595, 20.03889084],
      [86.3088913, 20.0391674],
      [86.3088913, 20.03972054],
      [86.30944824, 20.04027939],
      [86.30944824, 20.04055405],
      [86.3097229, 20.04083252],
      [86.30999756, 20.04083252],
    ],
  ],
  [
    [
      [86.32027435, 20.02944374],
      [86.32055664, 20.02916527],
      [86.32098389, 20.0291729],
      [86.32130432, 20.02913666],
      [86.32160187, 20.02897835],
      [86.32196045, 20.02890015],
      [86.32235718, 20.02882957],
      [86.32271576, 20.02861786],
      [86.32305908, 20.02833939],
      [86.32417297, 20.02721977],
      [86.32416534, 20.02694321],
      [86.32444763, 20.02667046],
      [86.32444763, 20.02639008],
      [86.32554626, 20.02528],
      [86.32583618, 20.02528],
      [86.32666779, 20.02444267],
      [86.32666779, 20.02416611],
      [86.32640076, 20.02388954],
      [86.3263855, 20.02361107],
      [86.32583618, 20.02305603],
      [86.32527924, 20.02305984],
      [86.32417297, 20.02193832],
      [86.32334137, 20.02193832],
      [86.32221985, 20.02305603],
      [86.32213593, 20.02330017],
      [86.32190704, 20.02361107],
      [86.32157898, 20.02390099],
      [86.32125854, 20.0241909],
      [86.32095337, 20.02449989],
      [86.3207016, 20.02482986],
      [86.32055664, 20.02524567],
      [86.32042694, 20.02565956],
      [86.3203125, 20.02607918],
      [86.32026672, 20.02650642],
      [86.32022095, 20.02695656],
      [86.32021332, 20.02742958],
      [86.32019043, 20.0279007],
      [86.31999969, 20.02833366],
      [86.31973267, 20.02861023],
      [86.31972504, 20.02916527],
      [86.31999969, 20.02944374],
      [86.32027435, 20.02944374],
    ],
  ],
  [
    [
      [86.26583099, 20.0336113],
      [86.26611328, 20.03333282],
      [86.26638794, 20.03333282],
      [86.26667023, 20.03305626],
      [86.26693726, 20.03305817],
      [86.26721954, 20.03277969],
      [86.26750183, 20.03277969],
      [86.26777649, 20.03250122],
      [86.26832581, 20.03250122],
      [86.26832581, 20.0313797],
      [86.26777649, 20.0308342],
      [86.26750183, 20.0308342],
      [86.26721954, 20.03055763],
      [86.26694489, 20.03055763],
      [86.26667023, 20.0308342],
      [86.26638794, 20.03083038],
      [86.26609802, 20.03111076],
      [86.26555634, 20.03111076],
      [86.26527405, 20.03138733],
      [86.26416779, 20.0313797],
      [86.26389313, 20.03166962],
      [86.26333618, 20.0316658],
      [86.26305389, 20.03194427],
      [86.26277924, 20.03194427],
      [86.26249695, 20.03222084],
      [86.26333618, 20.03305626],
      [86.26305389, 20.03333282],
      [86.26333618, 20.0336113],
      [86.26583099, 20.0336113],
    ],
  ],
  [
    [
      [86.27249908, 20.02444458],
      [86.27194214, 20.02388954],
      [86.27166748, 20.02388954],
      [86.26972198, 20.02193832],
      [86.26944733, 20.02222252],
      [86.26833344, 20.02111053],
      [86.26805878, 20.02111053],
      [86.26777649, 20.02082825],
      [86.26667023, 20.02082825],
      [86.26583099, 20.02166939],
      [86.26527405, 20.02166748],
      [86.2647171, 20.02220917],
      [86.2647171, 20.02277946],
      [86.26555634, 20.02361107],
      [86.26583099, 20.02361107],
      [86.26609802, 20.02388954],
      [86.26638794, 20.02388954],
      [86.26667023, 20.02416992],
      [86.26694489, 20.02416611],
      [86.26721954, 20.02444458],
      [86.26805878, 20.02444458],
      [86.26833344, 20.02472115],
      [86.26860809, 20.02472115],
      [86.26944733, 20.02555466],
      [86.26944733, 20.02583313],
      [86.27083588, 20.02722168],
      [86.27138519, 20.02722168],
      [86.27194214, 20.02666664],
      [86.27194214, 20.02639008],
      [86.27249908, 20.02583122],
      [86.27249908, 20.02527809],
      [86.27278137, 20.02499962],
      [86.27249908, 20.02472115],
      [86.27249908, 20.02444458],
    ],
  ],
  [
    [
      [86.28814697, 20.02861023],
      [86.28749847, 20.02861023],
      [86.28723145, 20.02833939],
      [86.28666687, 20.02833939],
      [86.28639221, 20.02861023],
      [86.28555298, 20.02861023],
      [86.28527832, 20.02833939],
      [86.28472137, 20.02833939],
      [86.28444672, 20.02806091],
      [86.28417206, 20.02806091],
      [86.2840271, 20.02791786],
      [86.28388977, 20.02778053],
      [86.28333282, 20.02778053],
      [86.28305817, 20.02750015],
      [86.28221893, 20.02750015],
      [86.28193665, 20.02721977],
      [86.28055573, 20.02722168],
      [86.28028107, 20.02694321],
      [86.27999878, 20.02695084],
      [86.27971649, 20.02667046],
      [86.27889252, 20.02667046],
      [86.27861023, 20.02695084],
      [86.27833557, 20.02694321],
      [86.27803802, 20.02721977],
      [86.27803802, 20.02806091],
      [86.27835083, 20.02833939],
      [86.27803802, 20.02861023],
      [86.27803802, 20.02889061],
      [86.27777863, 20.02916527],
      [86.27777863, 20.02943993],
      [86.27722168, 20.03000069],
      [86.27610779, 20.03000069],
      [86.27583313, 20.02972031],
      [86.27443695, 20.02972031],
      [86.27416992, 20.03000069],
      [86.27388763, 20.03000069],
      [86.27361298, 20.03028107],
      [86.27333069, 20.03028107],
      [86.27249908, 20.03111076],
      [86.27249908, 20.0313797],
      [86.27222443, 20.0316658],
      [86.27222443, 20.03194427],
      [86.27194214, 20.03222084],
      [86.27194214, 20.03250122],
      [86.27138519, 20.03305626],
      [86.27111053, 20.03333282],
      [86.26999664, 20.03333092],
      [86.26944733, 20.03388977],
      [86.26915741, 20.03388977],
      [86.26889038, 20.03417015],
      [86.26860809, 20.03416634],
      [86.26805878, 20.03472137],
      [86.26777649, 20.03472137],
      [86.26750183, 20.03500175],
      [86.26721954, 20.03500175],
      [86.26693726, 20.03528023],
      [86.26638794, 20.03528023],
      [86.26609802, 20.03500175],
      [86.26555634, 20.03500175],
      [86.26526642, 20.03472137],
      [86.26444244, 20.03472137],
      [86.26416779, 20.03500175],
      [86.26305389, 20.03500175],
      [86.26277924, 20.03472137],
      [86.26249695, 20.03472137],
      [86.26222229, 20.03444481],
      [86.26083374, 20.03444481],
      [86.26008606, 20.03369904],
      [86.25971985, 20.03333282],
      [86.25944519, 20.03333282],
      [86.2591629, 20.03305626],
      [86.25888824, 20.03305817],
      [86.2583313, 20.03250122],
      [86.25805664, 20.03250122],
      [86.25776672, 20.03222084],
      [86.25749969, 20.03222275],
      [86.25694275, 20.0316658],
      [86.2563858, 20.03166962],
      [86.25630951, 20.0315876],
      [86.25611115, 20.0313797],
      [86.25583649, 20.03138733],
      [86.2555542, 20.03111076],
      [86.25527954, 20.03111076],
      [86.25499725, 20.03083038],
      [86.2547226, 20.0308342],
      [86.25389099, 20.03000069],
      [86.25389099, 20.02972031],
      [86.25222015, 20.02972221],
      [86.25195313, 20.03000069],
      [86.25138855, 20.03000069],
      [86.25112152, 20.02972031],
      [86.2508316, 20.02972031],
      [86.25026703, 20.03028107],
      [86.25, 20.03028107],
      [86.24964905, 20.03026962],
      [86.24923706, 20.03027153],
      [86.24887085, 20.03023148],
      [86.24854279, 20.03004074],
      [86.24819946, 20.02980042],
      [86.24781799, 20.02927017],
      [86.24740601, 20.02885818],
      [86.2470932, 20.02891922],
      [86.24677277, 20.02907181],
      [86.24637604, 20.02907181],
      [86.24591064, 20.02891922],
      [86.24568176, 20.02902985],
      [86.24546051, 20.02917099],
      [86.24523163, 20.02935982],
      [86.24499512, 20.02956963],
      [86.24476624, 20.02981949],
      [86.24459076, 20.03013611],
      [86.24446869, 20.03046989],
      [86.24447632, 20.03079987],
      [86.24465942, 20.03108025],
      [86.24472046, 20.0313797],
      [86.24472046, 20.03170967],
      [86.24472046, 20.03208923],
      [86.24472046, 20.03255844],
      [86.24472046, 20.03310204],
      [86.24472046, 20.03368378],
      [86.24475861, 20.03425217],
      [86.24488068, 20.03473282],
      [86.24498749, 20.0352211],
      [86.24505615, 20.03570175],
      [86.24524689, 20.03616905],
      [86.24541473, 20.03666496],
      [86.24556732, 20.03713036],
      [86.24578857, 20.03750992],
      [86.2459259, 20.03784943],
      [86.24610901, 20.03812027],
      [86.24629211, 20.03839111],
      [86.24645996, 20.03868294],
      [86.24665833, 20.03897095],
      [86.24684143, 20.03926086],
      [86.24697113, 20.03963089],
      [86.24716949, 20.03993607],
      [86.24742126, 20.04024124],
      [86.24767303, 20.04057121],
      [86.24787903, 20.04088974],
      [86.24806976, 20.04122162],
      [86.24828339, 20.04150963],
      [86.24846649, 20.04181099],
      [86.2486496, 20.04210854],
      [86.24886322, 20.04236794],
      [86.24903107, 20.04269028],
      [86.24919891, 20.04306984],
      [86.24947357, 20.04341125],
      [86.24987793, 20.04373169],
      [86.25028992, 20.0440712],
      [86.25076294, 20.04440498],
      [86.25123596, 20.0447197],
      [86.25170135, 20.04502678],
      [86.25215912, 20.04529953],
      [86.25250244, 20.04556084],
      [86.25305176, 20.04555511],
      [86.2538681, 20.04636955],
      [86.25416565, 20.0466671],
      [86.25415802, 20.04721832],
      [86.25444794, 20.04750061],
      [86.25444794, 20.04861069],
      [86.2547226, 20.04888916],
      [86.25485229, 20.04929924],
      [86.25502014, 20.04965973],
      [86.25525665, 20.04999161],
      [86.25550079, 20.05034256],
      [86.25575256, 20.05072975],
      [86.25610352, 20.05112076],
      [86.25653839, 20.05153084],
      [86.25704193, 20.05195999],
      [86.25754547, 20.05238533],
      [86.2580719, 20.05276108],
      [86.25862122, 20.0530529],
      [86.25915527, 20.05331802],
      [86.25971985, 20.05360985],
      [86.25971985, 20.05389023],
      [86.26000214, 20.05417061],
      [86.26029205, 20.05417061],
      [86.26055908, 20.05445099],
      [86.26082611, 20.05445099],
      [86.26148224, 20.05509567],
      [86.26167297, 20.05528069],
      [86.26194763, 20.05528069],
      [86.26249695, 20.05471992],
      [86.26277924, 20.05472183],
      [86.26305389, 20.05444336],
      [86.26335144, 20.05445099],
      [86.26361084, 20.05417061],
      [86.26389313, 20.05417061],
      [86.26444244, 20.05360985],
      [86.26499939, 20.05416679],
      [86.26555634, 20.05471992],
      [86.26555634, 20.05500031],
      [86.26499939, 20.05554962],
      [86.26499939, 20.05611038],
      [86.26526642, 20.05639076],
      [86.26526642, 20.05667114],
      [86.26638794, 20.05778122],
      [86.26667023, 20.05778122],
      [86.26693726, 20.05805969],
      [86.26833344, 20.05805779],
      [86.26860809, 20.05833244],
      [86.26999664, 20.05833244],
      [86.27027893, 20.05805779],
      [86.26999664, 20.05778122],
      [86.26999664, 20.05722046],
      [86.27027893, 20.05694389],
      [86.27027893, 20.05638885],
      [86.26915741, 20.05528069],
      [86.26889038, 20.05528069],
      [86.26832581, 20.05471992],
      [86.26805878, 20.05472183],
      [86.26777649, 20.05444336],
      [86.26750183, 20.05445099],
      [86.26721954, 20.05417061],
      [86.26721954, 20.05332947],
      [86.26750183, 20.05305481],
      [86.26777649, 20.05305481],
      [86.26805878, 20.05333328],
      [86.26833344, 20.05333328],
      [86.26860809, 20.05361176],
      [86.26916504, 20.05305481],
      [86.26972198, 20.05277824],
      [86.27054596, 20.05194283],
      [86.27083588, 20.05194283],
      [86.27166748, 20.05277824],
      [86.27166748, 20.05305481],
      [86.27194214, 20.05332947],
      [86.27194214, 20.05389023],
      [86.27223206, 20.05417061],
      [86.27223206, 20.05445099],
      [86.27278137, 20.05500031],
      [86.27305603, 20.05500031],
      [86.27333069, 20.05528069],
      [86.27361298, 20.05500031],
      [86.27388763, 20.05500031],
      [86.27500153, 20.05389023],
      [86.27500153, 20.05360985],
      [86.27444458, 20.05305481],
      [86.27444458, 20.05277824],
      [86.27471924, 20.05251122],
      [86.27480316, 20.05246735],
      [86.2750473, 20.05234146],
      [86.27539063, 20.05221367],
      [86.27577972, 20.05204964],
      [86.27620697, 20.05185127],
      [86.27679443, 20.05198479],
      [86.27719116, 20.05185699],
      [86.27749634, 20.05164909],
      [86.277771, 20.05138969],
      [86.27800751, 20.05115128],
      [86.27828217, 20.05101967],
      [86.27854919, 20.0508709],
      [86.27892303, 20.05078888],
      [86.27929688, 20.0505619],
      [86.27973175, 20.05030251],
      [86.28016663, 20.0500164],
      [86.28058624, 20.0497303],
      [86.28096008, 20.04946327],
      [86.28128052, 20.04923058],
      [86.2816391, 20.04912949],
      [86.28192902, 20.04894066],
      [86.28232574, 20.04886246],
      [86.28276062, 20.04875183],
      [86.28320313, 20.04857826],
      [86.28363037, 20.04834175],
      [86.28410339, 20.04809952],
      [86.28465271, 20.04792976],
      [86.28514099, 20.04764938],
      [86.2856369, 20.04740524],
      [86.28605652, 20.04710388],
      [86.28630829, 20.04659843],
      [86.28675079, 20.04648972],
      [86.28723145, 20.046381],
      [86.28749847, 20.04611206],
      [86.28749847, 20.04527664],
      [86.28723145, 20.04499817],
      [86.28749847, 20.0447216],
      [86.28778076, 20.0447216],
      [86.28805542, 20.04444695],
      [86.28916931, 20.04445076],
      [86.28943634, 20.04417038],
      [86.28971863, 20.04417038],
      [86.29000092, 20.04388809],
      [86.29000092, 20.0433197],
      [86.29055786, 20.04277611],
      [86.29083252, 20.0430603],
      [86.29111481, 20.0430584],
      [86.29138947, 20.0433197],
      [86.29138947, 20.04445076],
      [86.29250336, 20.04556084],
      [86.29277802, 20.04556084],
      [86.29303741, 20.04528046],
      [86.29332733, 20.04528046],
      [86.29360962, 20.04499817],
      [86.29389191, 20.04499817],
      [86.29444122, 20.04445076],
      [86.29471588, 20.04445076],
      [86.29611206, 20.0430603],
      [86.29638672, 20.0430603],
      [86.29666901, 20.04277992],
      [86.29666901, 20.04249954],
      [86.29750061, 20.04166985],
      [86.29750061, 20.04111099],
      [86.29722595, 20.04083061],
      [86.29722595, 20.04055405],
      [86.29693604, 20.04027939],
      [86.29693604, 20.04000092],
      [86.29666901, 20.03972054],
      [86.29777527, 20.03861046],
      [86.29888916, 20.03861046],
      [86.29917145, 20.03833008],
      [86.30000305, 20.03833389],
      [86.30027771, 20.03805542],
      [86.30082703, 20.03806114],
      [86.30110931, 20.03778076],
      [86.30194092, 20.03778076],
      [86.30223083, 20.03750992],
      [86.30249786, 20.03750992],
      [86.30304718, 20.03695107],
      [86.3033371, 20.03695107],
      [86.30361176, 20.03667068],
      [86.30388641, 20.03667068],
      [86.3041687, 20.0363903],
      [86.30500031, 20.0363903],
      [86.30526733, 20.03611183],
      [86.30555725, 20.03611183],
      [86.3061142, 20.03555489],
      [86.30638885, 20.03555489],
      [86.30666351, 20.03527832],
      [86.30722046, 20.03528023],
      [86.3077774, 20.03472137],
      [86.30805206, 20.03472137],
      [86.30805206, 20.03444481],
      [86.30722046, 20.0336113],
      [86.3069458, 20.0336113],
      [86.3067627, 20.03342819],
      [86.30638885, 20.03305626],
      [86.30583191, 20.03305817],
      [86.30526733, 20.03250122],
      [86.30444336, 20.03250122],
      [86.30388641, 20.03305817],
      [86.30361176, 20.03277969],
      [86.3033371, 20.03277969],
      [86.30304718, 20.03250122],
      [86.30278015, 20.03250122],
      [86.30166626, 20.0313797],
      [86.30110931, 20.03194427],
      [86.30055237, 20.03194427],
      [86.29944611, 20.0308342],
      [86.29916382, 20.0308342],
      [86.29805756, 20.02972221],
      [86.29805756, 20.02944374],
      [86.29777527, 20.02916527],
      [86.29722595, 20.02916527],
      [86.29666901, 20.02972031],
      [86.29666901, 20.03000069],
      [86.29638672, 20.03028107],
      [86.29582977, 20.03028107],
      [86.29528046, 20.02972031],
      [86.29499817, 20.02972221],
      [86.29490662, 20.02962875],
      [86.29472351, 20.02944374],
      [86.29166412, 20.02944374],
      [86.29138947, 20.02916527],
      [86.29055786, 20.02917099],
      [86.29029083, 20.02889061],
      [86.29000092, 20.02889061],
      [86.28971863, 20.02861023],
      [86.28916931, 20.02861023],
      [86.28888702, 20.02889061],
      [86.28861237, 20.02861023],
      [86.28814697, 20.02861023],
    ],
  ],
  [
    [
      [86.30055237, 20.04249954],
      [86.30082703, 20.04222107],
      [86.30110931, 20.04222298],
      [86.30305481, 20.04027748],
      [86.3033371, 20.04027939],
      [86.30388641, 20.03972054],
      [86.3033371, 20.0391674],
      [86.3033371, 20.03889084],
      [86.30304718, 20.03861046],
      [86.30249786, 20.03861046],
      [86.30223083, 20.03889084],
      [86.3013916, 20.03889084],
      [86.30110931, 20.03917122],
      [86.30083466, 20.0391674],
      [86.30055237, 20.03944206],
      [86.30027771, 20.03944206],
      [86.30000305, 20.03972244],
      [86.29972076, 20.03972054],
      [86.29917145, 20.04027939],
      [86.29916382, 20.04055405],
      [86.29888916, 20.04083061],
      [86.29888916, 20.04111099],
      [86.29860687, 20.04138947],
      [86.2986145, 20.04166412],
      [86.29891205, 20.04196739],
      [86.29916382, 20.04222298],
      [86.29946136, 20.04222107],
      [86.29972076, 20.04249954],
      [86.30055237, 20.04249954],
    ],
  ],
  [
    [
      [86.29499817, 20.04972267],
      [86.29555511, 20.04916573],
      [86.29582977, 20.04916573],
      [86.29638672, 20.04972267],
      [86.29694366, 20.04972267],
      [86.29833221, 20.04833412],
      [86.29833221, 20.04805565],
      [86.29860687, 20.04777908],
      [86.29860687, 20.04750061],
      [86.29888916, 20.04721832],
      [86.29888916, 20.04694366],
      [86.29916382, 20.0466671],
      [86.29917145, 20.046381],
      [86.29944611, 20.04611206],
      [86.29944611, 20.04583359],
      [86.29972076, 20.04555511],
      [86.29972076, 20.04528046],
      [86.30027771, 20.0447197],
      [86.30027771, 20.04444695],
      [86.29972076, 20.04388809],
      [86.29888916, 20.04388809],
      [86.29833221, 20.04445076],
      [86.29805756, 20.04445076],
      [86.29750061, 20.04499817],
      [86.29722595, 20.04499817],
      [86.29693604, 20.04528046],
      [86.29638672, 20.04528046],
      [86.29611206, 20.04556084],
      [86.29582977, 20.04555511],
      [86.29555511, 20.04583359],
      [86.29528046, 20.04583359],
      [86.29499817, 20.04611206],
      [86.29472351, 20.04611206],
      [86.29444122, 20.04639053],
      [86.29416656, 20.046381],
      [86.29389191, 20.04667091],
      [86.29333496, 20.0466671],
      [86.29305267, 20.04694366],
      [86.29222107, 20.04694366],
      [86.29194641, 20.04722214],
      [86.29165649, 20.04721832],
      [86.29110718, 20.04777908],
      [86.29110718, 20.04861069],
      [86.29083252, 20.04889107],
      [86.29111481, 20.04916573],
      [86.29110718, 20.04972267],
      [86.29165649, 20.05027962],
      [86.29250336, 20.05027962],
      [86.29277802, 20.05055618],
      [86.29277802, 20.05083275],
      [86.29389191, 20.05083275],
      [86.29499817, 20.04972267],
    ],
  ],
  [
    [
      [86.78972626, 20.38760948],
      [86.78939819, 20.38754082],
      [86.78897858, 20.3877697],
      [86.78904724, 20.38815117],
      [86.7891922, 20.38869095],
      [86.7891922, 20.38923073],
      [86.78904724, 20.38997269],
      [86.78911591, 20.39059258],
      [86.78916168, 20.39103127],
      [86.78910828, 20.39144516],
      [86.78897858, 20.39182091],
      [86.78890228, 20.39217758],
      [86.78888702, 20.39252281],
      [86.78888702, 20.39285851],
      [86.78894043, 20.39317894],
      [86.78910065, 20.39346123],
      [86.78917694, 20.3937912],
      [86.78927612, 20.39413261],
      [86.78942108, 20.39447975],
      [86.78951263, 20.39485931],
      [86.78967285, 20.39520836],
      [86.78971863, 20.39558411],
      [86.78972626, 20.39596939],
      [86.78972626, 20.39638519],
      [86.78972626, 20.39682961],
      [86.78972626, 20.39731979],
      [86.78972626, 20.39782906],
      [86.78972626, 20.39836121],
      [86.78972626, 20.39888954],
      [86.79000092, 20.40018082],
      [86.78979492, 20.40098953],
      [86.78929138, 20.40200043],
      [86.78879547, 20.40301514],
      [86.78858185, 20.40389252],
      [86.78779602, 20.4050808],
      [86.78788757, 20.4056263],
      [86.78806305, 20.40611076],
      [86.78833008, 20.40639114],
      [86.78833008, 20.4066658],
      [86.78861237, 20.40694046],
      [86.78861237, 20.40777969],
      [86.78943634, 20.40860939],
      [86.78943634, 20.40889168],
      [86.78971863, 20.40916634],
      [86.78971863, 20.40944481],
      [86.79000092, 20.40971947],
      [86.79000092, 20.41055489],
      [86.7902832, 20.41082954],
      [86.7902832, 20.41167068],
      [86.79055786, 20.41195107],
      [86.79055786, 20.41278076],
      [86.7902832, 20.41306114],
      [86.7902832, 20.41389084],
      [86.79000092, 20.41417122],
      [86.79000092, 20.41527939],
      [86.789711, 20.41555977],
      [86.78971863, 20.41611099],
      [86.79027557, 20.41666412],
      [86.79000092, 20.41694069],
      [86.79000092, 20.41749954],
      [86.78971863, 20.41777611],
      [86.789711, 20.4197197],
      [86.79000092, 20.41999817],
      [86.79000092, 20.42110825],
      [86.7902832, 20.42139053],
      [86.79027557, 20.4216671],
      [86.79000092, 20.42193985],
      [86.79000092, 20.42416573],
      [86.7902832, 20.42444038],
      [86.7902832, 20.42500114],
      [86.79000092, 20.42527771],
      [86.79000092, 20.42583466],
      [86.79027557, 20.42611122],
      [86.7902832, 20.42638969],
      [86.7904129, 20.42679977],
      [86.79057312, 20.42728043],
      [86.79083252, 20.42777824],
      [86.79055786, 20.42805481],
      [86.79055786, 20.42860031],
      [86.79083252, 20.42888641],
      [86.79110718, 20.42917061],
      [86.79138947, 20.42917061],
      [86.79151154, 20.42904663],
      [86.79194641, 20.42861176],
      [86.79194641, 20.42833328],
      [86.79222107, 20.42805481],
      [86.79222107, 20.42777824],
      [86.79250336, 20.42749977],
      [86.79250336, 20.42721939],
      [86.79277802, 20.42694473],
      [86.79277802, 20.42666626],
      [86.79305267, 20.42638969],
      [86.79305267, 20.42611122],
      [86.79332733, 20.42583275],
      [86.79332733, 20.42534637],
      [86.79332733, 20.42494011],
      [86.79335785, 20.42450333],
      [86.79345703, 20.42404556],
      [86.79358673, 20.4235611],
      [86.79360962, 20.42305756],
      [86.79360962, 20.42255974],
      [86.79361725, 20.42210007],
      [86.79366302, 20.42168045],
      [86.79380035, 20.42128944],
      [86.79383087, 20.42088127],
      [86.79366302, 20.42042923],
      [86.7935791, 20.41996765],
      [86.79344177, 20.41953087],
      [86.79335022, 20.41909981],
      [86.79332733, 20.41868019],
      [86.79333496, 20.41829872],
      [86.79332733, 20.4179306],
      [86.79338074, 20.41757011],
      [86.79354095, 20.41726112],
      [86.79366302, 20.41692734],
      [86.79386902, 20.41661835],
      [86.79402161, 20.41625023],
      [86.79413605, 20.41584778],
      [86.79416656, 20.41537094],
      [86.79416656, 20.41484261],
      [86.79421997, 20.41428947],
      [86.79440308, 20.41380119],
      [86.79444122, 20.4133091],
      [86.79445648, 20.41286087],
      [86.79454041, 20.41242981],
      [86.79467773, 20.41201973],
      [86.79467773, 20.41156006],
      [86.7946167, 20.41106987],
      [86.79476929, 20.41048241],
      [86.79454803, 20.41004944],
      [86.79415894, 20.40966034],
      [86.79389954, 20.40917015],
      [86.79370117, 20.40864754],
      [86.79344177, 20.40813065],
      [86.79329681, 20.40752029],
      [86.79309845, 20.40691185],
      [86.79305267, 20.40626144],
      [86.79302216, 20.40561104],
      [86.79289246, 20.40496254],
      [86.79283905, 20.40431976],
      [86.79297638, 20.40367126],
      [86.79305267, 20.40303993],
      [86.79305267, 20.40245438],
      [86.7930603, 20.40193367],
      [86.79310608, 20.40147781],
      [86.79328156, 20.40107918],
      [86.79332733, 20.40065956],
      [86.79332733, 20.40023994],
      [86.79332733, 20.3998394],
      [86.79338074, 20.39947128],
      [86.79354095, 20.3991375],
      [86.79354095, 20.39874268],
      [86.7935791, 20.39835739],
      [86.79358673, 20.39817047],
      [86.79360199, 20.39796829],
      [86.79360962, 20.39760017],
      [86.79361725, 20.39723969],
      [86.79368591, 20.39690971],
      [86.79384613, 20.39658928],
      [86.79396057, 20.3962307],
      [86.79412079, 20.39587593],
      [86.79418945, 20.39549446],
      [86.79436493, 20.39511299],
      [86.79473114, 20.39473343],
      [86.7951889, 20.39431763],
      [86.79535675, 20.39381981],
      [86.79534149, 20.39335251],
      [86.79470825, 20.39292145],
      [86.79428864, 20.39253044],
      [86.79412079, 20.39211273],
      [86.79395294, 20.3916893],
      [86.7938385, 20.39120865],
      [86.79360962, 20.39076805],
      [86.79334259, 20.39033127],
      [86.79308319, 20.38990021],
      [86.79281616, 20.38952065],
      [86.79251099, 20.38927078],
      [86.79220581, 20.38904953],
      [86.79193115, 20.38886261],
      [86.79168701, 20.38865089],
      [86.79141998, 20.38849068],
      [86.79116821, 20.38829613],
      [86.79087067, 20.38805008],
      [86.7904129, 20.3877964],
      [86.78972626, 20.38760948],
    ],
  ],
  [
    [
      [86.79805756, 20.69194221],
      [86.79784393, 20.69030571],
      [86.79763031, 20.68982124],
      [86.79763031, 20.68922615],
      [86.79727936, 20.68862152],
      [86.79656982, 20.6878109],
      [86.79577637, 20.68558121],
      [86.79528046, 20.68416786],
      [86.79528046, 20.68388939],
      [86.79499817, 20.68361092],
      [86.79499817, 20.68333054],
      [86.79472351, 20.68305588],
      [86.79471588, 20.68278122],
      [86.79444122, 20.68250084],
      [86.79444122, 20.68222046],
      [86.79416656, 20.6819458],
      [86.79416656, 20.68166733],
      [86.79389191, 20.68139076],
      [86.79389191, 20.68083],
      [86.79360962, 20.68055534],
      [86.79360962, 20.68028069],
      [86.79332733, 20.68000031],
      [86.79332733, 20.67971992],
      [86.79305267, 20.67944527],
      [86.79305267, 20.67917061],
      [86.79250336, 20.67861176],
      [86.79250336, 20.67833138],
      [86.79222107, 20.67805481],
      [86.79222107, 20.67777824],
      [86.79110718, 20.67667007],
      [86.79110718, 20.6763916],
      [86.79083252, 20.67610931],
      [86.79055786, 20.67583084],
      [86.79016876, 20.67534065],
      [86.78967285, 20.67493057],
      [86.78924561, 20.67513847],
      [86.78924561, 20.67689133],
      [86.78931427, 20.67824173],
      [86.78931427, 20.67925262],
      [86.78943634, 20.68083],
      [86.789711, 20.68111038],
      [86.789711, 20.68278122],
      [86.79000092, 20.68305588],
      [86.79000092, 20.68333244],
      [86.79027557, 20.68361092],
      [86.7902832, 20.68388939],
      [86.79055786, 20.68416977],
      [86.79055786, 20.68499947],
      [86.79083252, 20.68527985],
      [86.79083252, 20.68555641],
      [86.79111481, 20.68583298],
      [86.79110718, 20.68610954],
      [86.79138947, 20.68638992],
      [86.79138947, 20.68694496],
      [86.79166412, 20.68722153],
      [86.79165649, 20.68749046],
      [86.79194641, 20.68778038],
      [86.79194641, 20.68805504],
      [86.79222107, 20.68833351],
      [86.79222107, 20.68860817],
      [86.79277802, 20.68916702],
      [86.79277802, 20.68971825],
      [86.79305267, 20.69000244],
      [86.79305267, 20.69055557],
      [86.79332733, 20.69083023],
      [86.79332733, 20.69111252],
      [86.79360962, 20.6913929],
      [86.79389191, 20.69138908],
      [86.79389191, 20.69194221],
      [86.79416656, 20.6922226],
      [86.79416656, 20.69277763],
      [86.79471588, 20.69333076],
      [86.79471588, 20.69388962],
      [86.79499817, 20.69416618],
      [86.79499817, 20.69444466],
      [86.79528046, 20.69472313],
      [86.79528046, 20.69582939],
      [86.79582977, 20.69638824],
      [86.7958374, 20.69721985],
      [86.79638672, 20.69778061],
      [86.79638672, 20.69806099],
      [86.79693604, 20.69861031],
      [86.79694366, 20.69888878],
      [86.79750061, 20.69944382],
      [86.79750061, 20.69972038],
      [86.79833221, 20.70055962],
      [86.79860687, 20.70055962],
      [86.79915619, 20.70000076],
      [86.79915619, 20.69972038],
      [86.79888916, 20.69944382],
      [86.79854584, 20.69907761],
      [86.79847717, 20.69861031],
      [86.79847717, 20.69684982],
      [86.79854584, 20.69617653],
      [86.79840851, 20.69495964],
      [86.79833221, 20.69416618],
      [86.79833221, 20.6922226],
      [86.79805756, 20.69194221],
    ],
  ],
  [
    [
      [86.80426788, 20.67662048],
      [86.80347443, 20.67656708],
      [86.80271149, 20.67645073],
      [86.80207825, 20.67624092],
      [86.80153656, 20.67603111],
      [86.80103302, 20.67583275],
      [86.80051422, 20.67566872],
      [86.80008698, 20.67547035],
      [86.79971313, 20.67526627],
      [86.79932404, 20.67507362],
      [86.7990036, 20.67481995],
      [86.7986908, 20.67455101],
      [86.79833984, 20.6742897],
      [86.79805756, 20.67416954],
      [86.79805756, 20.67388916],
      [86.79666901, 20.67250824],
      [86.79666901, 20.67222023],
      [86.79638672, 20.67194366],
      [86.79638672, 20.67166519],
      [86.7958374, 20.67111015],
      [86.79582977, 20.67083359],
      [86.79555511, 20.67055511],
      [86.79556274, 20.67000008],
      [86.79528046, 20.6697216],
      [86.79528046, 20.66916656],
      [86.79499817, 20.66889],
      [86.79499817, 20.66860962],
      [86.79528046, 20.66833305],
      [86.79528046, 20.66749954],
      [86.79499817, 20.66722107],
      [86.79528046, 20.6669445],
      [86.79528046, 20.66638947],
      [86.79541016, 20.66562271],
      [86.79575348, 20.66504669],
      [86.7961731, 20.6645298],
      [86.79666138, 20.66414833],
      [86.79712677, 20.6638298],
      [86.79750824, 20.6635704],
      [86.79788971, 20.66345978],
      [86.79823303, 20.66348267],
      [86.79852295, 20.66362953],
      [86.79876709, 20.66389275],
      [86.79904938, 20.66423798],
      [86.7993927, 20.66460419],
      [86.79981232, 20.66493988],
      [86.80021667, 20.66529846],
      [86.80056763, 20.66570282],
      [86.80088043, 20.6661396],
      [86.8012085, 20.66655731],
      [86.80156708, 20.6668911],
      [86.80197144, 20.66716957],
      [86.80243683, 20.66739082],
      [86.80304718, 20.66754723],
      [86.80374908, 20.66757393],
      [86.80437469, 20.6674881],
      [86.80491638, 20.66739082],
      [86.80545044, 20.66732025],
      [86.80593109, 20.66732979],
      [86.80609894, 20.66729736],
      [86.806427, 20.66723061],
      [86.80691528, 20.66712379],
      [86.80737305, 20.66693687],
      [86.80784607, 20.66673279],
      [86.8083725, 20.66652107],
      [86.80885315, 20.66620064],
      [86.80931854, 20.66584015],
      [86.80982208, 20.66546822],
      [86.81030273, 20.66508484],
      [86.81079102, 20.66470909],
      [86.81124115, 20.66431046],
      [86.81167603, 20.66390038],
      [86.81213379, 20.66351891],
      [86.81253815, 20.66311073],
      [86.81295013, 20.66275024],
      [86.81335449, 20.66238976],
      [86.81363678, 20.66194916],
      [86.81391144, 20.66150093],
      [86.8141861, 20.66106033],
      [86.81443787, 20.66059113],
      [86.81469727, 20.66012955],
      [86.81494141, 20.65971184],
      [86.81512451, 20.65933037],
      [86.81526947, 20.65901947],
      [86.81539917, 20.65875816],
      [86.81550598, 20.65853119],
      [86.81562042, 20.65826988],
      [86.81573486, 20.65799713],
      [86.81582642, 20.65768051],
      [86.81597137, 20.65737915],
      [86.8161087, 20.6570797],
      [86.81628418, 20.65678215],
      [86.8164444, 20.65642357],
      [86.81664276, 20.65605164],
      [86.81684113, 20.65566063],
      [86.81698608, 20.65526009],
      [86.81717682, 20.65488815],
      [86.81741333, 20.65450287],
      [86.81768036, 20.65407944],
      [86.81793976, 20.65364647],
      [86.81817627, 20.65316963],
      [86.81841278, 20.65270996],
      [86.8186264, 20.65229988],
      [86.81884766, 20.65193176],
      [86.81912231, 20.65155983],
      [86.81945801, 20.65113068],
      [86.81984711, 20.65065956],
      [86.82021332, 20.65014267],
      [86.82052612, 20.64965057],
      [86.82080841, 20.6491909],
      [86.82106018, 20.64877701],
      [86.82129669, 20.64836121],
      [86.8215332, 20.64795494],
      [86.82171631, 20.64752007],
      [86.82186127, 20.64706993],
      [86.8219223, 20.64660835],
      [86.82195282, 20.64614105],
      [86.82194519, 20.64571381],
      [86.8219223, 20.64530182],
      [86.82186127, 20.64492035],
      [86.82176208, 20.64455032],
      [86.82157898, 20.64413261],
      [86.82139587, 20.6436615],
      [86.82115936, 20.64320374],
      [86.82089233, 20.64274597],
      [86.82063293, 20.64228058],
      [86.82039642, 20.64183044],
      [86.82022095, 20.64142036],
      [86.82003784, 20.64105988],
      [86.81984711, 20.64068985],
      [86.81968689, 20.64031792],
      [86.81954193, 20.63992119],
      [86.81935883, 20.6395607],
      [86.81912231, 20.63924217],
      [86.81880188, 20.63892555],
      [86.81842804, 20.63858032],
      [86.81800842, 20.63826942],
      [86.81755066, 20.63803101],
      [86.81707001, 20.63788033],
      [86.8165741, 20.6378727],
      [86.81616974, 20.63780022],
      [86.81581879, 20.63775826],
      [86.81547546, 20.63776779],
      [86.81510925, 20.63784027],
      [86.81472778, 20.63803101],
      [86.81428528, 20.63824844],
      [86.81376648, 20.63848305],
      [86.81324768, 20.63878059],
      [86.81280518, 20.63917923],
      [86.81241608, 20.63961029],
      [86.81204987, 20.64003754],
      [86.8117218, 20.64045906],
      [86.81140137, 20.6408577],
      [86.81109619, 20.64125061],
      [86.81079102, 20.64165306],
      [86.81045532, 20.64203262],
      [86.810112, 20.64237022],
      [86.80969238, 20.64268494],
      [86.80921936, 20.64300156],
      [86.80874634, 20.64335442],
      [86.8082962, 20.64373016],
      [86.80786896, 20.64409065],
      [86.80745697, 20.64443588],
      [86.80700684, 20.64471245],
      [86.80652618, 20.64493942],
      [86.80610657, 20.64500046],
      [86.80583191, 20.64528084],
      [86.80500031, 20.64528084],
      [86.80371094, 20.64500046],
      [86.8024292, 20.64487076],
      [86.80082703, 20.64500046],
      [86.80027771, 20.6455555],
      [86.80027771, 20.64583015],
      [86.80000305, 20.64611053],
      [86.79611206, 20.64611053],
      [86.79437256, 20.64603806],
      [86.79401398, 20.64583397],
      [86.79305267, 20.64583015],
      [86.79277802, 20.64611053],
      [86.79250336, 20.64611053],
      [86.79222107, 20.64583015],
      [86.79111481, 20.64583397],
      [86.79083252, 20.6455555],
      [86.79055786, 20.64557076],
      [86.7902832, 20.64528084],
      [86.789711, 20.64528084],
      [86.78943634, 20.64500046],
      [86.78888702, 20.64500046],
      [86.78861237, 20.64472008],
      [86.78694153, 20.64472198],
      [86.78666687, 20.64444351],
      [86.78583527, 20.64444351],
      [86.78562927, 20.64424133],
      [86.78555298, 20.64416695],
      [86.78472137, 20.64416695],
      [86.78444672, 20.64444351],
      [86.78360748, 20.64444351],
      [86.78333282, 20.64472198],
      [86.78305817, 20.64472008],
      [86.78277588, 20.64500046],
      [86.78277588, 20.64528084],
      [86.78250122, 20.64557076],
      [86.78166962, 20.6455555],
      [86.78138733, 20.64583397],
      [86.78056335, 20.64583015],
      [86.78028107, 20.64611053],
      [86.77971649, 20.64611053],
      [86.77944183, 20.64639091],
      [86.77889252, 20.64639091],
      [86.77861023, 20.64666748],
      [86.77805328, 20.64666748],
      [86.77777863, 20.64694405],
      [86.77749634, 20.64694405],
      [86.77722168, 20.64666748],
      [86.77694702, 20.64666748],
      [86.7766571, 20.64639091],
      [86.77602386, 20.64639473],
      [86.77523804, 20.64618874],
      [86.77459717, 20.64639091],
      [86.77379608, 20.6463871],
      [86.77394104, 20.64672279],
      [86.77429962, 20.64744949],
      [86.77416992, 20.64805984],
      [86.77416992, 20.64834023],
      [86.77471161, 20.64889145],
      [86.77471924, 20.64916611],
      [86.77527618, 20.64971924],
      [86.77527618, 20.65000153],
      [86.77555847, 20.65027809],
      [86.77555847, 20.65082932],
      [86.77639008, 20.65167046],
      [86.77639008, 20.65195084],
      [86.77805328, 20.65361023],
      [86.77805328, 20.65389061],
      [86.77833557, 20.65416718],
      [86.7783432, 20.65443993],
      [86.77889252, 20.65500069],
      [86.77889252, 20.65528107],
      [86.77916718, 20.65555573],
      [86.77916718, 20.65583038],
      [86.78111267, 20.65777969],
      [86.78138733, 20.65777779],
      [86.78194427, 20.65833282],
      [86.78250122, 20.65833092],
      [86.78916931, 20.66500282],
      [86.78916931, 20.66527748],
      [86.789711, 20.66583061],
      [86.789711, 20.6661129],
      [86.79000092, 20.6661129],
      [86.79000092, 20.66638947],
      [86.79055786, 20.66694069],
      [86.79055786, 20.66749954],
      [86.79194641, 20.66889],
      [86.79194641, 20.66916656],
      [86.79250336, 20.6697197],
      [86.79250336, 20.67000008],
      [86.79277802, 20.67028046],
      [86.79277802, 20.67055511],
      [86.79305267, 20.67083359],
      [86.79305267, 20.67111015],
      [86.79499817, 20.67305565],
      [86.79499817, 20.67333031],
      [86.79556274, 20.67388916],
      [86.79555511, 20.67416573],
      [86.7958374, 20.67444038],
      [86.7958374, 20.67499924],
      [86.79611206, 20.67527771],
      [86.79611206, 20.67555618],
      [86.7977829, 20.67721939],
      [86.79750061, 20.67750168],
      [86.79750061, 20.67777824],
      [86.79777527, 20.67805481],
      [86.7977829, 20.67831993],
      [86.79860687, 20.67917061],
      [86.7986145, 20.67944527],
      [86.79888153, 20.67971992],
      [86.79888153, 20.68000031],
      [86.79944611, 20.68055534],
      [86.79944611, 20.68083],
      [86.79972076, 20.68111038],
      [86.80072021, 20.6822567],
      [86.80150604, 20.68340492],
      [86.80214691, 20.68522072],
      [86.80207825, 20.68657494],
      [86.80251312, 20.68798828],
      [86.80361176, 20.69111252],
      [86.80361176, 20.69305611],
      [86.80388641, 20.69333458],
      [86.80388641, 20.69444466],
      [86.8041687, 20.69472122],
      [86.8041687, 20.69527817],
      [86.80444336, 20.69555473],
      [86.80444336, 20.69833374],
      [86.80473328, 20.69861031],
      [86.80444336, 20.69889069],
      [86.80472565, 20.69916725],
      [86.80473328, 20.70248985],
      [86.80500031, 20.70277786],
      [86.80500031, 20.70360947],
      [86.80527496, 20.70388794],
      [86.80526733, 20.70471954],
      [86.80555725, 20.70500183],
      [86.80555725, 20.7052784],
      [86.80750275, 20.70722008],
      [86.80750275, 20.70750046],
      [86.80832672, 20.70833015],
      [86.80832672, 20.70862007],
      [86.80860901, 20.70889091],
      [86.80916595, 20.70889091],
      [86.80944824, 20.70916939],
      [86.8097229, 20.70916748],
      [86.81027985, 20.70972252],
      [86.81054688, 20.70944023],
      [86.8116684, 20.71055412],
      [86.8116684, 20.71083069],
      [86.81194305, 20.71111107],
      [86.81194305, 20.71138954],
      [86.81277466, 20.71222305],
      [86.81305695, 20.71194458],
      [86.81472015, 20.7136097],
      [86.81472015, 20.71388817],
      [86.81555176, 20.71471977],
      [86.81555176, 20.71499825],
      [86.81667328, 20.71610832],
      [86.81666565, 20.7163887],
      [86.8172226, 20.71694374],
      [86.8172226, 20.71722031],
      [86.81777954, 20.71778107],
      [86.81777954, 20.71805573],
      [86.81804657, 20.71833038],
      [86.81804657, 20.71861267],
      [86.81833649, 20.71889114],
      [86.81833649, 20.7191658],
      [86.81861115, 20.71944046],
      [86.81861115, 20.72000122],
      [86.8188858, 20.72027779],
      [86.8188858, 20.72083473],
      [86.81916809, 20.72110939],
      [86.81916809, 20.72138977],
      [86.81944275, 20.72166634],
      [86.81944275, 20.72222137],
      [86.81972504, 20.72249985],
      [86.81973267, 20.72332954],
      [86.81944275, 20.72361946],
      [86.81944275, 20.7238903],
      [86.81973267, 20.72417068],
      [86.81972504, 20.72444534],
      [86.81999969, 20.72472191],
      [86.82000732, 20.72500038],
      [86.82026672, 20.72528076],
      [86.82027435, 20.72555542],
      [86.82055664, 20.72583389],
      [86.82055664, 20.7266674],
      [86.8208313, 20.72694016],
      [86.8208313, 20.72777748],
      [86.82111359, 20.72805595],
      [86.82111359, 20.7294445],
      [86.82138824, 20.72971916],
      [86.82138824, 20.73028183],
      [86.8216629, 20.7305603],
      [86.8216629, 20.73250008],
      [86.82195282, 20.73278999],
      [86.82195282, 20.73528099],
      [86.82221985, 20.73555946],
      [86.82221985, 20.73611069],
      [86.82250214, 20.73639107],
      [86.82250214, 20.73750114],
      [86.82277679, 20.73777771],
      [86.82277679, 20.73944473],
      [86.82306671, 20.73971939],
      [86.82305908, 20.74027634],
      [86.82332611, 20.74055099],
      [86.82382965, 20.74240112],
      [86.82411957, 20.74360085],
      [86.82444763, 20.74528122],
      [86.82444763, 20.74555588],
      [86.82472229, 20.74583054],
      [86.82472229, 20.74611282],
      [86.82499695, 20.74638939],
      [86.82499695, 20.74666595],
      [86.82527924, 20.74694443],
      [86.82527924, 20.74728966],
      [86.82527924, 20.74749947],
      [86.82554626, 20.74778938],
      [86.82554626, 20.74888992],
      [86.82583618, 20.7491703],
      [86.82583618, 20.74944687],
      [86.82611084, 20.74971962],
      [86.82611084, 20.75028038],
      [86.8263855, 20.75055695],
      [86.8263855, 20.75083351],
      [86.82666779, 20.75111008],
      [86.82666779, 20.75166512],
      [86.8272171, 20.75222015],
      [86.8272171, 20.75250053],
      [86.82777405, 20.75305557],
      [86.82776642, 20.75360107],
      [86.82805634, 20.75388718],
      [86.82805634, 20.75444412],
      [86.82833099, 20.75472069],
      [86.82833099, 20.75528145],
      [86.82861328, 20.75556183],
      [86.82861328, 20.75610924],
      [86.82917023, 20.75667191],
      [86.82917023, 20.7575016],
      [86.82944489, 20.75777817],
      [86.82944489, 20.75805473],
      [86.82971954, 20.7583313],
      [86.82971954, 20.75860977],
      [86.83000183, 20.75889015],
      [86.83000183, 20.76000023],
      [86.83583069, 20.7658329],
      [86.83611298, 20.7658329],
      [86.83622742, 20.76594925],
      [86.83693695, 20.76667023],
      [86.83721924, 20.76667023],
      [86.83805084, 20.76749992],
      [86.83833313, 20.76749992],
      [86.83860779, 20.7677784],
      [86.83889008, 20.7677784],
      [86.8391571, 20.76806068],
      [86.83944702, 20.76805496],
      [86.83972168, 20.76833344],
      [86.83999634, 20.76833344],
      [86.84013367, 20.76847267],
      [86.84055328, 20.76890182],
      [86.8408432, 20.76890182],
      [86.84111023, 20.76917076],
      [86.84139252, 20.76916695],
      [86.84152985, 20.76930618],
      [86.84166718, 20.76944351],
      [86.84194183, 20.7694397],
      [86.84249878, 20.76999855],
      [86.84278107, 20.76999855],
      [86.84417725, 20.77139282],
      [86.84443665, 20.77139282],
      [86.84751129, 20.77308083],
      [86.84951019, 20.77429008],
      [86.85101318, 20.77482986],
      [86.85416412, 20.77583313],
      [86.85527802, 20.77583313],
      [86.85891724, 20.77569771],
      [86.86110687, 20.77555084],
      [86.86301422, 20.77508354],
      [86.86457825, 20.77454376],
      [86.86657715, 20.77393532],
      [86.86808014, 20.77313042],
      [86.86929321, 20.77259064],
      [86.87065125, 20.77177811],
      [86.87185669, 20.77164078],
      [86.87277985, 20.77166939],
      [86.87416077, 20.77166939],
      [86.87444305, 20.77139282],
      [86.87608337, 20.77071953],
      [86.87728882, 20.77031517],
      [86.87843323, 20.76997948],
      [86.881073, 20.76903534],
      [86.88299561, 20.76856041],
      [86.88484955, 20.76822472],
      [86.88713837, 20.76788902],
      [86.88906097, 20.76754951],
      [86.89056396, 20.76734924],
      [86.89305878, 20.76721954],
      [86.89555359, 20.76722145],
      [86.89583588, 20.76694489],
      [86.89694214, 20.76694489],
      [86.89722443, 20.76722145],
      [86.89916992, 20.76722145],
      [86.89944458, 20.76694489],
      [86.89971924, 20.76694489],
      [86.90000153, 20.76722145],
      [86.90305328, 20.76722145],
      [86.90333557, 20.76749992],
      [86.90416718, 20.76749992],
      [86.90444183, 20.7677784],
      [86.90499878, 20.7677784],
      [86.90528107, 20.76806068],
      [86.90721893, 20.76805496],
      [86.90750122, 20.76833344],
      [86.91004944, 20.76832962],
      [86.91140747, 20.76847076],
      [86.91340637, 20.76867104],
      [86.91454315, 20.76867104],
      [86.9155426, 20.7687397],
      [86.91796875, 20.76860809],
      [86.91883087, 20.76853752],
      [86.91999054, 20.76849365],
      [86.92053986, 20.76846886],
      [86.92189789, 20.76832962],
      [86.92325592, 20.76819992],
      [86.92524719, 20.76792908],
      [86.92669678, 20.76781464],
      [86.92696381, 20.76779366],
      [86.92711639, 20.76784134],
      [86.9278183, 20.76806068],
      [86.9283371, 20.76805496],
      [86.92861176, 20.76833344],
      [86.92888641, 20.76805496],
      [86.93032074, 20.76805496],
      [86.93055725, 20.76806068],
      [86.93083191, 20.7677784],
      [86.93139648, 20.7677784],
      [86.93165588, 20.76806068],
      [86.93222046, 20.76749992],
      [86.93250275, 20.7677784],
      [86.9327774, 20.7677784],
      [86.93305206, 20.76749992],
      [86.9338913, 20.76749992],
      [86.93509674, 20.76729965],
      [86.93659973, 20.76689148],
      [86.93838501, 20.76655579],
      [86.93952942, 20.76657295],
      [86.94110107, 20.76649284],
      [86.94210052, 20.76657295],
      [86.94361115, 20.76667023],
      [86.94611359, 20.76666641],
      [86.94625092, 20.76680565],
      [86.94638824, 20.76694489],
      [86.9466629, 20.76694489],
      [86.94721985, 20.76638794],
      [86.94777679, 20.76638985],
      [86.94805908, 20.76667023],
      [86.9486084, 20.76666641],
      [86.94874573, 20.76680374],
      [86.94889069, 20.76694489],
      [86.94972229, 20.76694489],
      [86.94999695, 20.76666641],
      [86.95166779, 20.76666641],
      [86.95194244, 20.76694489],
      [86.95220947, 20.76694107],
      [86.95249939, 20.76722145],
      [86.95305634, 20.76722145],
      [86.95333099, 20.76694489],
      [86.95722198, 20.76694489],
      [86.95749664, 20.76666641],
      [86.96089935, 20.76667023],
      [86.96296692, 20.7667408],
      [86.96496582, 20.76713943],
      [86.96639252, 20.76753998],
      [86.96846771, 20.76828575],
      [86.9701767, 20.76920319],
      [86.97167206, 20.76974487],
      [86.97303009, 20.77028275],
      [86.97453308, 20.77102089],
      [86.97538757, 20.77130127],
      [86.97731018, 20.77130127],
      [86.97805786, 20.77166748],
      [86.97833252, 20.77194214],
      [86.98055267, 20.77194214],
      [86.98249817, 20.77222061],
      [86.98278046, 20.77222061],
      [86.98305511, 20.77194214],
      [86.98317719, 20.77194214],
      [86.98332977, 20.77194214],
      [86.98447418, 20.77174377],
      [86.98668671, 20.77180862],
      [86.98770142, 20.77180862],
      [86.98804474, 20.77180862],
      [86.98916626, 20.77166748],
      [86.99054718, 20.77166939],
      [86.9908371, 20.77139282],
      [86.99111176, 20.77139282],
      [86.99138641, 20.77111244],
      [86.99194336, 20.77111244],
      [86.99223328, 20.77083015],
      [86.99250031, 20.77083206],
      [86.99277496, 20.7705555],
      [86.99305725, 20.77056122],
      [86.99415588, 20.7694397],
      [86.99416351, 20.76916695],
      [86.99472046, 20.76860809],
      [86.99472046, 20.76833344],
      [86.99500275, 20.76805496],
      [86.99500275, 20.7677784],
      [86.9952774, 20.76749992],
      [86.9952774, 20.76667023],
      [86.99555206, 20.76638985],
      [86.99555206, 20.76583099],
      [86.99583435, 20.76555634],
      [86.99582672, 20.76527977],
      [86.9963913, 20.76472092],
      [86.9963913, 20.76444435],
      [86.99666595, 20.76416779],
      [86.99666595, 20.76361084],
      [86.99694824, 20.76333046],
      [86.99694824, 20.7630558],
      [86.9972229, 20.7627697],
      [86.9972229, 20.76250076],
      [86.99748993, 20.76222038],
      [86.99749756, 20.76194572],
      [86.99777985, 20.76166725],
      [86.99777985, 20.76111031],
      [86.99833679, 20.76056099],
      [86.99833679, 20.76000023],
      [86.99861145, 20.75972939],
      [86.99861145, 20.75860977],
      [86.99888611, 20.7583313],
      [86.99888611, 20.75583267],
      [86.9991684, 20.75555611],
      [86.9991684, 20.75499916],
      [86.99944305, 20.75472069],
      [86.99944305, 20.75444412],
      [86.99972534, 20.75305557],
      [87, 20.75333405],
      [87.00027466, 20.75305557],
      [87.00054932, 20.75305939],
      [87.00054932, 20.75278091],
      [87.0008316, 20.75250053],
      [87.0008316, 20.75167084],
      [87.00138855, 20.75111008],
      [87.00138855, 20.75027847],
      [87.00195313, 20.75],
      [87.0019455, 20.74972153],
      [87.00305176, 20.74861145],
      [87.0036087, 20.74833488],
      [87.00389099, 20.74805641],
      [87.00389099, 20.74749947],
      [87.00332642, 20.74694061],
      [87.0036087, 20.74666595],
      [87.0036087, 20.74638939],
      [87.00444794, 20.74611282],
      [87.00444794, 20.74583054],
      [87.00499725, 20.74555588],
      [87.0047226, 20.74528122],
      [87.00499725, 20.74500275],
      [87.00583649, 20.7447319],
      [87.00583649, 20.74417114],
      [87.00694275, 20.74305916],
      [87.00694275, 20.74138832],
      [87.00723267, 20.74110985],
      [87.00694275, 20.74083328],
      [87.00694275, 20.73944473],
      [87.0063858, 20.73916626],
      [87.00668335, 20.73888969],
      [87.0063858, 20.73861122],
      [87.0063858, 20.73777771],
      [87.00611115, 20.73750114],
      [87.00611115, 20.73611069],
      [87.00583649, 20.73583031],
      [87.00583649, 20.73472214],
      [87.0055542, 20.73444366],
      [87.0055542, 20.73277855],
      [87.00527954, 20.73250008],
      [87.00527954, 20.73139191],
      [87.00499725, 20.73110962],
      [87.00499725, 20.7294445],
      [87.0047226, 20.72916603],
      [87.0047226, 20.72833061],
      [87.00416565, 20.72777748],
      [87.00417328, 20.72639084],
      [87.00389099, 20.72611046],
      [87.00389099, 20.72500038],
      [87.00372314, 20.72483635],
      [87.0036087, 20.72472191],
      [87.0036087, 20.72305679],
      [87.00333405, 20.72277832],
      [87.00332642, 20.72167015],
      [87.0027771, 20.72110939],
      [87.0027771, 20.71999931],
      [87.00222015, 20.71944046],
      [87.00222015, 20.7183342],
      [87.00166321, 20.71777725],
      [87.00166321, 20.71694183],
      [87.00138855, 20.71666718],
      [87.00138855, 20.71582985],
      [87.00111389, 20.71555519],
      [87.00110626, 20.71528053],
      [87.0008316, 20.71499825],
      [87.0008316, 20.71471977],
      [87.00055695, 20.71444511],
      [87.00054932, 20.71417046],
      [87, 20.71388817],
      [86.99973297, 20.71340752],
      [86.9994278, 20.71280861],
      [86.99899292, 20.71223831],
      [86.99855804, 20.71174049],
      [86.99811554, 20.71137428],
      [86.99773407, 20.71105957],
      [86.99740601, 20.71076012],
      [86.99700165, 20.71053123],
      [86.99658966, 20.71027946],
      [86.9961319, 20.71004295],
      [86.99559021, 20.70981026],
      [86.99500275, 20.70951271],
      [86.99429321, 20.70946121],
      [86.99356079, 20.70952034],
      [86.99285126, 20.70968628],
      [86.99214172, 20.70972061],
      [86.99146271, 20.70975876],
      [86.99088287, 20.70994949],
      [86.99040222, 20.71014786],
      [86.9899826, 20.71027946],
      [86.98957062, 20.71042061],
      [86.98912048, 20.71058083],
      [86.98860931, 20.71080971],
      [86.98808289, 20.71101189],
      [86.9875412, 20.71120071],
      [86.98701477, 20.71135521],
      [86.98641968, 20.71138954],
      [86.98581696, 20.71145058],
      [86.98583221, 20.71167946],
      [86.98555756, 20.71166611],
      [86.98527527, 20.71194458],
      [86.98500061, 20.71194458],
      [86.98472595, 20.71222305],
      [86.98443604, 20.71222115],
      [86.98416901, 20.71249962],
      [86.98306274, 20.71249962],
      [86.98278046, 20.71277809],
      [86.98222351, 20.71277618],
      [86.98194122, 20.71305466],
      [86.98166656, 20.71305466],
      [86.98139191, 20.71333313],
      [86.98083496, 20.71333313],
      [86.98055267, 20.7136116],
      [86.98007202, 20.71364975],
      [86.97962952, 20.71374512],
      [86.9792099, 20.71385956],
      [86.97873688, 20.71390152],
      [86.9782486, 20.71393776],
      [86.97780609, 20.71409988],
      [86.97734833, 20.71416092],
      [86.97688293, 20.71417046],
      [86.97641754, 20.71418953],
      [86.97598267, 20.71430969],
      [86.97553253, 20.71442032],
      [86.97506714, 20.71445084],
      [86.97460938, 20.71450806],
      [86.97416687, 20.71471024],
      [86.97370148, 20.71488762],
      [86.97318268, 20.7149868],
      [86.97277832, 20.71499443],
      [86.97261047, 20.71499825],
      [86.97197723, 20.71495056],
      [86.97130585, 20.71477127],
      [86.97059631, 20.71478081],
      [86.96985626, 20.71495056],
      [86.96910095, 20.71493912],
      [86.96766663, 20.7151413],
      [86.96639252, 20.71499825],
      [86.96577454, 20.71499825],
      [86.96531677, 20.71500015],
      [86.96486664, 20.71499825],
      [86.96440887, 20.71500015],
      [86.96394348, 20.71499443],
      [86.9634552, 20.7149334],
      [86.96292877, 20.71475983],
      [86.96232605, 20.71471977],
      [86.9617157, 20.71468163],
      [86.96115875, 20.71452522],
      [86.96060181, 20.71449089],
      [86.96009064, 20.71446991],
      [86.95962524, 20.71445084],
      [86.95921326, 20.71445084],
      [86.9588089, 20.71442986],
      [86.95838928, 20.71444321],
      [86.95793152, 20.71444321],
      [86.95742035, 20.71446037],
      [86.95690155, 20.714571],
      [86.95635986, 20.71466064],
      [86.95580292, 20.71471405],
      [86.95523071, 20.71472168],
      [86.95466614, 20.71474266],
      [86.95419312, 20.71486092],
      [86.95375061, 20.71498108],
      [86.95333099, 20.71499825],
      [86.95294952, 20.71499825],
      [86.9525528, 20.71499825],
      [86.95214844, 20.71499825],
      [86.95174408, 20.71504211],
      [86.95139313, 20.71522331],
      [86.9512558, 20.71520805],
      [86.95104218, 20.71518517],
      [86.95065308, 20.71503067],
      [86.95024109, 20.71499825],
      [86.94980621, 20.7150383],
      [86.94934082, 20.71515274],
      [86.94886017, 20.71529961],
      [86.94838715, 20.71550179],
      [86.94786835, 20.71557045],
      [86.9473877, 20.71568108],
      [86.94698334, 20.71583939],
      [86.94658661, 20.71602631],
      [86.9461441, 20.7161026],
      [86.94563293, 20.71615028],
      [86.94509125, 20.71635056],
      [86.94444275, 20.7163887],
      [86.94368744, 20.71640015],
      [86.94288635, 20.71645927],
      [86.94211578, 20.71663094],
      [86.94130707, 20.71664429],
      [86.94078064, 20.71656418],
      [86.94052124, 20.71652985],
      [86.93978882, 20.71640968],
      [86.93911743, 20.7163887],
      [86.93850708, 20.71640015],
      [86.93797302, 20.71648979],
      [86.93746185, 20.71664047],
      [86.93694305, 20.7166729],
      [86.93641663, 20.7166729],
      [86.93615723, 20.71666527],
      [86.93589783, 20.71665764],
      [86.93539429, 20.71660042],
      [86.93492126, 20.71643066],
      [86.9344101, 20.71635056],
      [86.93393707, 20.71615982],
      [86.93344116, 20.71609116],
      [86.93292236, 20.71597672],
      [86.93239594, 20.71585274],
      [86.93187714, 20.7157402],
      [86.93141174, 20.71556664],
      [86.93096924, 20.71540833],
      [86.93054199, 20.71525192],
      [86.93015289, 20.71503067],
      [86.92970276, 20.7148819],
      [86.92919922, 20.71474266],
      [86.92871094, 20.71465111],
      [86.92825317, 20.71446991],
      [86.92777252, 20.71430016],
      [86.92733002, 20.71414566],
      [86.92690277, 20.71390915],
      [86.92642975, 20.71366119],
      [86.92592621, 20.71340752],
      [86.92547607, 20.71311951],
      [86.92500305, 20.7129097],
      [86.92450714, 20.71272087],
      [86.92407227, 20.71249008],
      [86.92363739, 20.71223068],
      [86.92314911, 20.7120266],
      [86.92260742, 20.71186066],
      [86.92212677, 20.71157074],
      [86.92167664, 20.71121979],
      [86.9212265, 20.71084976],
      [86.92077637, 20.71055031],
      [86.92037201, 20.71027756],
      [86.91998291, 20.71003151],
      [86.91951752, 20.70991135],
      [86.9190979, 20.70970917],
      [86.91871643, 20.70946121],
      [86.91828918, 20.70926857],
      [86.9178009, 20.70914268],
      [86.91736603, 20.70890808],
      [86.91689301, 20.70870018],
      [86.91638184, 20.7085743],
      [86.916008, 20.70833588],
      [86.91567993, 20.70809746],
      [86.91545868, 20.70801353],
      [86.91529083, 20.70795059],
      [86.91487885, 20.70776939],
      [86.91441345, 20.70755005],
      [86.91390991, 20.70732117],
      [86.91343689, 20.70704079],
      [86.91303253, 20.70675278],
      [86.91261292, 20.70651436],
      [86.91216278, 20.70632362],
      [86.91173553, 20.70610046],
      [86.91131592, 20.70583916],
      [86.91086578, 20.70560074],
      [86.91039276, 20.70536995],
      [86.90994263, 20.70507813],
      [86.90946198, 20.70482063],
      [86.90901184, 20.70455742],
      [86.90862274, 20.70425034],
      [86.90822601, 20.70392036],
      [86.9078064, 20.70363426],
      [86.90737915, 20.70334053],
      [86.90692902, 20.70305634],
      [86.90647888, 20.70278168],
      [86.90602875, 20.70248985],
      [86.9056015, 20.70222282],
      [86.90516663, 20.70194435],
      [86.90470886, 20.70166969],
      [86.90419006, 20.70143127],
      [86.90361023, 20.70124054],
      [86.90302277, 20.70106697],
      [86.9025116, 20.70083046],
      [86.90203094, 20.70054817],
      [86.90157318, 20.70027733],
      [86.90110016, 20.70003128],
      [86.90058136, 20.69985962],
      [86.90011597, 20.69965172],
      [86.89978027, 20.69932365],
      [86.89949799, 20.69896317],
      [86.89919281, 20.69864655],
      [86.8988266, 20.69839096],
      [86.89832306, 20.69829178],
      [86.8979187, 20.69804001],
      [86.89752197, 20.6977787],
      [86.89711761, 20.69754028],
      [86.89666748, 20.69736099],
      [86.8962326, 20.69718361],
      [86.89585114, 20.69692802],
      [86.89550018, 20.69664955],
      [86.89520264, 20.69634056],
      [86.89482117, 20.69615173],
      [86.89437866, 20.69602966],
      [86.8939209, 20.69581032],
      [86.89341736, 20.69549942],
      [86.89289093, 20.69512177],
      [86.89231873, 20.69468117],
      [86.89175415, 20.69419861],
      [86.89116669, 20.69377708],
      [86.89067841, 20.69334984],
      [86.89022064, 20.69299126],
      [86.88981628, 20.69259071],
      [86.8894043, 20.69226074],
      [86.88897705, 20.69194984],
      [86.88854218, 20.69166183],
      [86.88813782, 20.6913681],
      [86.88777924, 20.69106102],
      [86.88743591, 20.69077873],
      [86.88704681, 20.69054031],
      [86.88667297, 20.69028282],
      [86.88626862, 20.69000244],
      [86.88585663, 20.68971825],
      [86.88546753, 20.68943977],
      [86.88513947, 20.68913078],
      [86.88487244, 20.6887722],
      [86.88455963, 20.68844795],
      [86.88420105, 20.68810081],
      [86.88375092, 20.68774033],
      [86.88331604, 20.68731499],
      [86.88286591, 20.68686104],
      [86.88247681, 20.68634415],
      [86.88201141, 20.68590164],
      [86.88148499, 20.6855278],
      [86.88098145, 20.68514061],
      [86.88056946, 20.68469048],
      [86.88020325, 20.68424988],
      [86.87976074, 20.68392181],
      [86.87937927, 20.68359947],
      [86.87908173, 20.6832695],
      [86.8787384, 20.68303108],
      [86.87843323, 20.68276024],
      [86.87812042, 20.68243027],
      [86.87782288, 20.68197823],
      [86.87746429, 20.68152618],
      [86.87708282, 20.68118858],
      [86.8767395, 20.68089294],
      [86.87644196, 20.68060684],
      [86.87615204, 20.68032074],
      [86.87583923, 20.68003273],
      [86.8755188, 20.67969131],
      [86.87517548, 20.67934036],
      [86.87482452, 20.67898941],
      [86.8744812, 20.6786499],
      [86.87412262, 20.67835045],
      [86.87374115, 20.67806053],
      [86.87335205, 20.67778778],
      [86.87301636, 20.67745972],
      [86.87275696, 20.67707062],
      [86.87245178, 20.67671013],
      [86.87210083, 20.67639923],
      [86.87172699, 20.67610931],
      [86.87135315, 20.67583084],
      [86.87097931, 20.67555046],
      [86.87062836, 20.67525101],
      [86.87032318, 20.67490959],
      [86.87008667, 20.67454529],
      [86.86988831, 20.67420006],
      [86.86966705, 20.67391968],
      [86.86943817, 20.6736908],
      [86.8691864, 20.67345047],
      [86.86897278, 20.67314529],
      [86.86868286, 20.67283058],
      [86.86843109, 20.67236519],
      [86.8682785, 20.67171288],
      [86.86794281, 20.67116547],
      [86.86750031, 20.67071915],
      [86.86705017, 20.67030907],
      [86.86663818, 20.66994858],
      [86.8663559, 20.66954041],
      [86.86608887, 20.66921043],
      [86.8658371, 20.66892052],
      [86.86560059, 20.66862106],
      [86.86532593, 20.66831207],
      [86.86502075, 20.6679821],
      [86.86470032, 20.66760826],
      [86.86433411, 20.66723442],
      [86.86392975, 20.66685867],
      [86.86351776, 20.6664505],
      [86.86302948, 20.6661377],
      [86.86252594, 20.66584969],
      [86.86199188, 20.66550255],
      [86.86138916, 20.66500282],
      [86.86110687, 20.66500282],
      [86.86083221, 20.66477394],
      [86.85917664, 20.66472054],
      [86.85888672, 20.66500282],
      [86.85832977, 20.66500282],
      [86.85722351, 20.6661129],
      [86.85721588, 20.66722107],
      [86.85749817, 20.66749954],
      [86.85749817, 20.66833305],
      [86.85778046, 20.66860962],
      [86.85778046, 20.66916656],
      [86.85832977, 20.6697197],
      [86.85832977, 20.67000008],
      [86.86027527, 20.67194366],
      [86.8602829, 20.67222023],
      [86.86083221, 20.67278099],
      [86.86083221, 20.67305946],
      [86.86138916, 20.67361069],
      [86.86444092, 20.67666626],
      [86.86444092, 20.67694092],
      [86.86499023, 20.67750168],
      [86.86499786, 20.67777824],
      [86.86528015, 20.67804909],
      [86.86528015, 20.67889023],
      [86.86499023, 20.67917061],
      [86.86499786, 20.67944527],
      [86.86472321, 20.67971992],
      [86.86472321, 20.68000031],
      [86.86444092, 20.68028069],
      [86.86444092, 20.68056107],
      [86.86332703, 20.68167114],
      [86.86277771, 20.68166733],
      [86.86250305, 20.6819458],
      [86.86222076, 20.6819458],
      [86.86194611, 20.68222237],
      [86.86055756, 20.68222237],
      [86.8602829, 20.68250084],
      [86.85832977, 20.68250084],
      [86.85806274, 20.68278122],
      [86.85778046, 20.68278122],
      [86.85749817, 20.68305969],
      [86.85722351, 20.68305588],
      [86.85694122, 20.68333054],
      [86.85694122, 20.68416977],
      [86.85806274, 20.68527985],
      [86.85806274, 20.68556023],
      [86.85944366, 20.68694496],
      [86.85944366, 20.68722153],
      [86.85972595, 20.68749046],
      [86.85972595, 20.68778038],
      [86.86027527, 20.68833351],
      [86.86027527, 20.68861008],
      [86.86250305, 20.69083405],
      [86.86305237, 20.69083023],
      [86.86332703, 20.69111252],
      [86.86360931, 20.69111252],
      [86.8638916, 20.6913929],
      [86.86694336, 20.6913929],
      [86.86723328, 20.69166946],
      [86.86805725, 20.69166756],
      [86.8681488, 20.69175911],
      [86.86833191, 20.69194221],
      [86.86888885, 20.69194221],
      [86.86916351, 20.6922226],
      [86.8694458, 20.6922226],
      [86.87000275, 20.69277954],
      [86.8702774, 20.69277954],
      [86.87110901, 20.69361115],
      [86.87110901, 20.69388962],
      [86.87166595, 20.69444466],
      [86.87166595, 20.69472122],
      [86.87194824, 20.69499969],
      [86.87194824, 20.69528008],
      [86.87416077, 20.69750023],
      [86.87416077, 20.69778061],
      [86.875, 20.69861031],
      [86.87526703, 20.69861031],
      [86.87555695, 20.69889069],
      [86.87582397, 20.69894218],
      [86.87590027, 20.69895554],
      [86.87638855, 20.69944382],
      [86.87666321, 20.69944382],
      [86.8769455, 20.69972229],
      [86.87722015, 20.69972038],
      [86.8777771, 20.70028114],
      [86.87805176, 20.70028114],
      [86.87832642, 20.70055962],
      [86.8786087, 20.70055962],
      [86.87944794, 20.70139122],
      [86.8797226, 20.70139122],
      [86.87999725, 20.70166969],
      [86.8805542, 20.70166588],
      [86.88083649, 20.70194435],
      [86.88111115, 20.70194435],
      [86.88388824, 20.70471954],
      [86.88388824, 20.70500183],
      [86.8841629, 20.7052803],
      [86.8841629, 20.70555305],
      [86.88444519, 20.70583344],
      [86.88445282, 20.70611191],
      [86.88471985, 20.70639038],
      [86.88471985, 20.70666695],
      [86.88527679, 20.70722008],
      [86.88527679, 20.7080555],
      [86.88500214, 20.70833015],
      [86.88500214, 20.70862007],
      [86.88471985, 20.70889091],
      [86.88471985, 20.70916748],
      [86.88444519, 20.70944595],
      [86.88445282, 20.70972061],
      [86.8841629, 20.71000099],
      [86.8841629, 20.71027756],
      [86.88360596, 20.71083069],
      [86.88360596, 20.71111107],
      [86.88194275, 20.71277809],
      [86.88166809, 20.71277618],
      [86.8813858, 20.71305466],
      [86.88111115, 20.71305466],
      [86.8805542, 20.7136116],
      [86.88028717, 20.7136097],
      [86.87999725, 20.71388817],
      [86.8797226, 20.71388817],
      [86.87944794, 20.71417046],
      [86.87917328, 20.71417046],
      [86.87889099, 20.71445084],
      [86.8786087, 20.71444511],
      [86.87833405, 20.71472168],
      [86.87805176, 20.71471977],
      [86.87805176, 20.71499825],
      [86.8777771, 20.71528053],
      [86.8777771, 20.71555519],
      [86.87750244, 20.71582985],
      [86.87750244, 20.71610832],
      [86.87722015, 20.7163887],
      [86.87722015, 20.71944427],
      [86.87750244, 20.71972466],
      [86.87750244, 20.72083473],
      [86.8777771, 20.72110939],
      [86.8777771, 20.72138977],
      [86.87805176, 20.72166634],
      [86.87805176, 20.72194481],
      [86.87832642, 20.72221947],
      [86.87833405, 20.72277832],
      [86.8786087, 20.72305107],
      [86.8786087, 20.7238903],
      [86.87889099, 20.72416687],
      [86.87889099, 20.72472191],
      [86.87916565, 20.72500038],
      [86.87917328, 20.72583008],
      [86.87944794, 20.72611046],
      [86.87944794, 20.72694397],
      [86.8797226, 20.72722054],
      [86.8797226, 20.72750092],
      [86.87944794, 20.72777748],
      [86.87944794, 20.72805977],
      [86.87889099, 20.72860909],
      [86.87889099, 20.73250008],
      [86.87832642, 20.73306084],
      [86.87805176, 20.73278999],
      [86.87805176, 20.73250008],
      [86.8777771, 20.7322197],
      [86.8777771, 20.73027992],
      [86.87750244, 20.72999954],
      [86.87750244, 20.72944069],
      [86.8777771, 20.72916603],
      [86.8777771, 20.72833061],
      [86.87805176, 20.72805595],
      [86.87805176, 20.72611046],
      [86.8777771, 20.72583008],
      [86.8777771, 20.72527695],
      [86.87750244, 20.72500038],
      [86.87750244, 20.72472],
      [86.87722015, 20.72444534],
      [86.87722015, 20.72417068],
      [86.87695313, 20.7238903],
      [86.87695313, 20.72361946],
      [86.87666321, 20.72332954],
      [86.87666321, 20.72277832],
      [86.87638855, 20.72249985],
      [86.87638855, 20.72194099],
      [86.87611389, 20.72166634],
      [86.87610626, 20.72027969],
      [86.8758316, 20.72000122],
      [86.8758316, 20.71889114],
      [86.87555695, 20.71861267],
      [86.87555695, 20.71833038],
      [86.8758316, 20.71805573],
      [86.8758316, 20.71722031],
      [86.87611389, 20.71694374],
      [86.87611389, 20.71610832],
      [86.87638855, 20.71582985],
      [86.87638855, 20.71528053],
      [86.87750244, 20.71417046],
      [86.87750244, 20.71388817],
      [86.87805176, 20.71333122],
      [86.87833405, 20.71333313],
      [86.8786087, 20.71305466],
      [86.87889099, 20.71305466],
      [86.87916565, 20.71277618],
      [86.87944794, 20.71277809],
      [86.87999725, 20.71222115],
      [86.88027954, 20.71222305],
      [86.88083649, 20.71166611],
      [86.88111115, 20.71167946],
      [86.88223267, 20.71055984],
      [86.88249969, 20.71055984],
      [86.8833313, 20.70972061],
      [86.8833313, 20.70916748],
      [86.88388824, 20.70862007],
      [86.88388824, 20.70722008],
      [86.88361359, 20.7069416],
      [86.88361359, 20.70666695],
      [86.88305664, 20.70611191],
      [86.88305664, 20.70583344],
      [86.88277435, 20.70583344],
      [86.8805542, 20.70360947],
      [86.88027954, 20.70360947],
      [86.8797226, 20.70305634],
      [86.87944794, 20.70306015],
      [86.8786087, 20.70222092],
      [86.87833405, 20.70222282],
      [86.8777771, 20.70166588],
      [86.87750244, 20.70166969],
      [86.87666321, 20.70083046],
      [86.87638855, 20.70083427],
      [86.87611389, 20.7005558],
      [86.8758316, 20.70055962],
      [86.87526703, 20.70000076],
      [86.875, 20.70000076],
      [86.87473297, 20.69972038],
      [86.87444305, 20.69972229],
      [86.87402344, 20.69930458],
      [86.87361145, 20.69888878],
      [86.87333679, 20.69889069],
      [86.8713913, 20.69695091],
      [86.8713913, 20.69668007],
      [86.87111664, 20.69639015],
      [86.87111664, 20.69610977],
      [86.87083435, 20.69583321],
      [86.87083435, 20.69555473],
      [86.87055206, 20.69527817],
      [86.87055206, 20.69499969],
      [86.8702774, 20.69472122],
      [86.8702774, 20.69444466],
      [86.86916351, 20.69333458],
      [86.86888885, 20.69333458],
      [86.8686142, 20.69305611],
      [86.86833191, 20.69305992],
      [86.86805725, 20.69277954],
      [86.86750031, 20.69277954],
      [86.86723328, 20.69305992],
      [86.86694336, 20.69305992],
      [86.8666687, 20.69277954],
      [86.8638916, 20.69277954],
      [86.86332703, 20.6922226],
      [86.86277771, 20.6922226],
      [86.86250305, 20.69194221],
      [86.86222076, 20.69194221],
      [86.86206055, 20.69178391],
      [86.86166382, 20.69138908],
      [86.86138916, 20.6913929],
      [86.85832977, 20.6883297],
      [86.85832977, 20.68805504],
      [86.85639191, 20.68610954],
      [86.85639191, 20.68583298],
      [86.85610962, 20.68555641],
      [86.85611725, 20.68527985],
      [86.85582733, 20.68499947],
      [86.85582733, 20.68472099],
      [86.85555267, 20.68444443],
      [86.85555267, 20.68305969],
      [86.85694122, 20.68167114],
      [86.85721588, 20.68167114],
      [86.85749817, 20.68139076],
      [86.85778046, 20.68139076],
      [86.85806274, 20.68111038],
      [86.85832977, 20.68111038],
      [86.85861206, 20.68083],
      [86.85944366, 20.68083382],
      [86.85972595, 20.68055534],
      [86.86000061, 20.68056107],
      [86.8602829, 20.68028069],
      [86.86083221, 20.68028069],
      [86.86110687, 20.68000031],
      [86.86194611, 20.68000031],
      [86.86222076, 20.67971992],
      [86.86277771, 20.67972374],
      [86.86360931, 20.67888832],
      [86.86360931, 20.67831993],
      [86.86166382, 20.6763916],
      [86.86138916, 20.6763916],
      [86.8602829, 20.67528152],
      [86.86000061, 20.67528152],
      [86.86000061, 20.67499924],
      [86.85861206, 20.67361069],
      [86.85861206, 20.67333031],
      [86.85832977, 20.67305565],
      [86.85832977, 20.67278099],
      [86.85806274, 20.67250824],
      [86.85806274, 20.67222023],
      [86.85666656, 20.67083359],
      [86.85666656, 20.67055511],
      [86.85639191, 20.67028046],
      [86.85639191, 20.66945076],
      [86.85611725, 20.66917038],
      [86.85611725, 20.66833115],
      [86.85583496, 20.66805649],
      [86.85582733, 20.66777992],
      [86.85555267, 20.66749954],
      [86.85555267, 20.66638184],
      [86.85583496, 20.6661129],
      [86.85582733, 20.66555977],
      [86.85694122, 20.66444016],
      [86.85722351, 20.66444206],
      [86.85778046, 20.66389084],
      [86.85806274, 20.66389275],
      [86.85832977, 20.66361237],
      [86.85888672, 20.66361237],
      [86.85972595, 20.66278076],
      [86.85917664, 20.66221809],
      [86.85916901, 20.66194534],
      [86.85871887, 20.66163063],
      [86.85832214, 20.66127968],
      [86.85791779, 20.66097641],
      [86.85752869, 20.66067123],
      [86.85721588, 20.66033554],
      [86.85692596, 20.66003036],
      [86.85665131, 20.65977097],
      [86.85637665, 20.65954971],
      [86.85611725, 20.6593399],
      [86.85582733, 20.65909004],
      [86.85555267, 20.65878105],
      [86.85523224, 20.65845108],
      [86.85484314, 20.65815163],
      [86.85449219, 20.65781784],
      [86.85415649, 20.65747261],
      [86.85385895, 20.65706062],
      [86.85352325, 20.65672493],
      [86.85312653, 20.65644073],
      [86.85276031, 20.65615463],
      [86.85237885, 20.65585709],
      [86.8519516, 20.65555954],
      [86.85150909, 20.65526772],
      [86.85106659, 20.65496826],
      [86.85058594, 20.65471268],
      [86.85012054, 20.65443993],
      [86.84964752, 20.65416718],
      [86.84915161, 20.65388107],
      [86.8486557, 20.65361595],
      [86.84815979, 20.6533699],
      [86.84765625, 20.65315819],
      [86.84716797, 20.65300941],
      [86.84668732, 20.65281105],
      [86.84623718, 20.65261078],
      [86.84578705, 20.65237999],
      [86.84532166, 20.65217972],
      [86.84487152, 20.65199852],
      [86.84436035, 20.65191078],
      [86.84383392, 20.65188789],
      [86.84332275, 20.65190125],
      [86.84287262, 20.65197945],
      [86.842453, 20.65211105],
      [86.84200287, 20.65223885],
      [86.84155273, 20.65244293],
      [86.84115601, 20.6527729],
      [86.84083557, 20.65315628],
      [86.84055328, 20.65350914],
      [86.84030151, 20.6538105],
      [86.84011078, 20.65409279],
      [86.83999634, 20.65439987],
      [86.8398819, 20.65469933],
      [86.83975983, 20.65498924],
      [86.8396759, 20.65530968],
      [86.83955383, 20.65563774],
      [86.83944702, 20.65603065],
      [86.83933258, 20.6565094],
      [86.83921051, 20.65703964],
      [86.83912659, 20.65759087],
      [86.83900452, 20.65811729],
      [86.83892059, 20.6586113],
      [86.83888245, 20.65906143],
      [86.83882141, 20.65945435],
      [86.83868408, 20.65979195],
      [86.83862305, 20.6601696],
      [86.83853912, 20.66057777],
      [86.83838654, 20.66096306],
      [86.83827209, 20.66140175],
      [86.83809662, 20.66183853],
      [86.83795166, 20.66230011],
      [86.83777618, 20.66274071],
      [86.83760071, 20.66319084],
      [86.83744812, 20.66365814],
      [86.8372345, 20.66414833],
      [86.83699036, 20.66469955],
      [86.83673859, 20.66533089],
      [86.83644867, 20.66597939],
      [86.83609009, 20.66654968],
      [86.83570099, 20.66707039],
      [86.83534241, 20.66752815],
      [86.8350296, 20.66794586],
      [86.83474731, 20.66834068],
      [86.83451843, 20.6687603],
      [86.83421326, 20.66912842],
      [86.83387756, 20.6695118],
      [86.83351898, 20.6699028],
      [86.83313751, 20.67028618],
      [86.83274841, 20.67066956],
      [86.83235931, 20.67103958],
      [86.83193207, 20.67136955],
      [86.83149719, 20.67167091],
      [86.83107758, 20.67196083],
      [86.8306427, 20.67222977],
      [86.83022308, 20.67250824],
      [86.82978821, 20.67277527],
      [86.82936096, 20.67304039],
      [86.82892609, 20.67331123],
      [86.82847595, 20.67359543],
      [86.82800293, 20.67387009],
      [86.82745361, 20.67412949],
      [86.82686615, 20.67436028],
      [86.82624054, 20.67455101],
      [86.82563782, 20.67475128],
      [86.82508087, 20.67497063],
      [86.82454681, 20.67515945],
      [86.82404327, 20.67531967],
      [86.82357025, 20.67547607],
      [86.82309723, 20.67560005],
      [86.82266998, 20.67575645],
      [86.8221817, 20.6758709],
      [86.82171631, 20.67601967],
      [86.82124329, 20.67613029],
      [86.82077026, 20.67624092],
      [86.8203125, 20.67633057],
      [86.81985474, 20.67640114],
      [86.81939697, 20.67649078],
      [86.81890869, 20.6765995],
      [86.81838989, 20.67667961],
      [86.81785583, 20.67679024],
      [86.81729889, 20.67687798],
      [86.81672668, 20.67699432],
      [86.8161087, 20.67706108],
      [86.81552887, 20.67707062],
      [86.81505585, 20.67699814],
      [86.81459045, 20.67701149],
      [86.81408691, 20.67703819],
      [86.81350708, 20.67704964],
      [86.81291962, 20.67713737],
      [86.81229401, 20.67717171],
      [86.81202698, 20.67711067],
      [86.81172943, 20.67704201],
      [86.811203, 20.67695045],
      [86.81069183, 20.6769104],
      [86.81015015, 20.67690659],
      [86.80955505, 20.67694473],
      [86.80885315, 20.67694092],
      [86.80815125, 20.67688942],
      [86.80741882, 20.67686081],
      [86.80665588, 20.67679024],
      [86.80587769, 20.67671776],
      [86.80506897, 20.67667961],
      [86.80426788, 20.67662048],
    ],
  ],
];
