import React from "react";
import { Redirect, Route } from "react-router-dom";

import { userLoggedIn, whichUserLoggedIn } from "../common/common";
import {callback} from "../common/localStorage";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const whetherUserLoggedIn = whichUserLoggedIn();
  callback();

  // console.log("PrivateRoute", whetherUserLoggedIn)
  if(whetherUserLoggedIn === 'admin') {
    return (
      <Route
        {...rest}
        render={(props) =>
          whetherUserLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          )
        }
      />
    );  
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          whetherUserLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          )
        }
      />
    );
  }


  
};

export default PrivateRoute;
