// react
import React, { useState, useEffect, createRef, useRef } from "react";

// plugin
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useScreenshot } from "use-react-screenshot";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

// Bootstrap Plugin
import Modal from "react-bootstrap/Modal";

// style
import "../dash-board-layout/dashboard.scss";
import "../../layouts-components/dash-board-components/ol-map/openlayer.scss";

// component
import Header from "../../layouts-components/dash-board-components/header/Header";
import SideBar from "../../layouts-components/dash-board-components/side-bar/SideBar";
import ComponentLoader from "../../public-screen/component-loader/ComponetLoader";
import OpenLayerMap from "../../layouts-components/dash-board-components/ol-map/OpenlayMap";
import SessonLayout from "../../layouts/sesson-layout/SessonLayout";
import BarChartDashBoard from "../../layouts-components/dash-board-components/filter-chat/BarChartDashBoard";
import LoaderButton from "../../component/loader-button/LoaderButton";

// fun
import { getProductLayerUser } from "../../action/getProductLayer";
import { districtsList } from "../../action/districtsList";
import { getLayerDateUser } from "../../action/getLayerDate";
import { districtsPolyLine } from "../../action/districtsPolyLine";
import { villagePolyLine } from "../../action/villagePolyLine";
import { blockPolyLine } from "../../action/blockPolyline";
import { statsList } from "../../action/stats";
import { blockList } from "../../action/blockList";
import { villageList } from "../../action/villageList";
import { panchayatList } from "../../action/panchayatList";
import { panchayatPolyLine } from "../../action/panchayatPolyLine";
import { stateList } from "../../action/state";
import { getVectorLayerName } from "../../action/getVectorLayerName";

// img
import headerLogo from "../../asstes/images/header-logo.svg";
import northIcon from "../../asstes/images/north.png";
import goLogo from "../../asstes/images/go-logo.png";
import { _getStorageValue } from "../../common/localStorage";
// import "../../layouts/otp-layout/otp.scss";
import { statGeo } from "../../layouts-components/dash-board-components/ol-map/stateJson";


import { ACCESS_TOKEN } from "../../common/constants";

const DashBoardLayout = (props) => {
  const ref = createRef(null);
  const inputEl = useRef(null);
  const [state, setState] = React.useState();
  // const [sessonEnableShow, setSessonEnableShow] = useState();
  const [imageBarShow, takeScreenshot] = useScreenshot();
  const [mapRender, setMapRendert] = useState(false);
  const [mapScreen, setMapScreen] = useState(true);
  const [silder, setsilder] = useState(false);
  const [graphScreen, setgraphScreen] = useState(false);

  const [pointLayer, setPointLayer] = useState([]);
  const [productLayer, setProductLayer] = useState([]);
  const [productLayerLoad, setProductLayerLoad] = useState(false);
  const [districtsList, setDistrictsList] = useState([]);
  const [mapCerdential, setMapCerdential] = useState({
    layer: "",
    time: "",
    center: [20.49, 84.46],
    zoom: 7,
    isVisble: false,
    product: { text: undefined, value: "Odisha" },
    state: { text: undefined, value: "Odisha" },
    district: { text: undefined, value: "Odisha" },
    block: { text: undefined, value: "Odisha" },
    village: { text: undefined, value: "Odisha" },
    panchaythu: { text: undefined, value: "Odisha" },
    // bboxData: [[17.8026104, 81.38300323], [22.5635891, 87.48265839]]
  });
  const [sessonOption, setSessonOption] = React.useState([]);
  const [blocksOptions, setBlocksOptions] = React.useState([]);
  const [panchayatOptions, setPanchayatOptions] = React.useState([]);
  const [centroidLatLon, setCentroidLatLon] = React.useState([]);
  const [polyGonGeo, setPolyGonGeo] = React.useState([]);
  const [barChartValue, setBarChartValue] = React.useState([]);
  const [villageOption, setVillageOptions] = React.useState([]);
  const [blockcentroidLatLon, setBlockCentroidLatLon] = React.useState([]);
  const [blockpolyGonGeo, setBlockPolyGonGeo] = React.useState([]);
  const [panchayatLatLon, setPanchayatLatLon] = React.useState([]);
  const [panchayatGonGeo, setPanchayatGonGeo] = React.useState([]);
  const [villagecentroidLatLon, setVillageCentroidLatLon] = React.useState([]);
  const [villagepolyGonGeo, setVillagePolyGonGeo] = React.useState([]);
  const [isVisbleSideBar, setIsVisbleSideBar] = React.useState(false);
  const [disableSlide, setDisableSlide] = React.useState(false);
  const [displayList, setDisplayList] = useState({});
  const [bardisplayList, setBarDisplayList] = useState({});
  const [leftMapWmsYear, setLeftMapWmsYear] = React.useState("");
  const [rightMapWmsYear, setRightMapWmsYear] = React.useState("");
  const [compareMapLoader, setCompareMapLoader] = React.useState(true);
  const [showPdf, setShowPdf] = React.useState(false);
  const [barshowPdf, setbarShowPdf] = React.useState(false);
  const [confromMapPdf, setconfromMapPdf] = React.useState(false);
  const [confromBarChartPdf, setconfromBarChartPdf] = React.useState(false);
  const [disableGoogleLayer, setDisableGoogleLayer] = React.useState(false);
  const [mapPdfImage, setmapPdfImage] = React.useState(null);
  const [barChartPdfDownload, setbarChartPdfDownload] = React.useState(null);
  const [productLoader, setProductLoader] = React.useState(true);
  const [blockLoader, setBlockLoader] = React.useState(false);
  const [districtLoader, setDistrictLoader] = React.useState(true);
  const [pachayatLoader, setPachayatLoader] = React.useState(false);
  const [villageLoader, setVillageLoader] = React.useState(false);
  const [barExportLoader, setBarExportLoader] = React.useState(false);
  const [barnoLoader, setBarnoLoader] = React.useState(false);
  const [sessonLoader, setsessonLoader] = React.useState(false);
  const [legendsData, setLegendsData] = React.useState([]);
  const [barGraph, setBarGraph] = React.useState({});
  // const [colorBarchart, setColorBarchart] = React.useState([]);

  useEffect(() => {

    _getStorageValue(ACCESS_TOKEN).then((token) => {
      props.getProductLayerUser(sucessCallBack, failuerCallBack);
      props.getVectorLayerName(sucessCallBackVector, failuerCallBackVector);
      props.districtsList(successDistrictCallBack, failuerDistrictCallBack);
      // props.stateList(successStateCoorCallBack, failuerStateCoorCallBack);
    });
  }, []);

  // Vector Layer call Back
  const sucessCallBackVector = (response) => {
    var pointLayerData = response.data;
    pointLayerData.push({
      display_name: "none",
      layer_name: "",
      layer_style: "",
    }, {
      display_name: "none1",
      layer_name: "",
      layer_style: "",
    });

    var vectorLayers = [];

    for (let list in pointLayerData) {
      if (pointLayerData[list].display_name === "none") {
        var obj = {
          display_name: "None",
          layer_name: "",
          layer_style: "",
          sortById: 1,
        };
        vectorLayers.push(obj);
      } else if (pointLayerData[list].display_name === "none1") {
        var obj = {
          display_name: "None1",
          layer_name: "",
          layer_style: "",
          sortById: 2,
        };
        vectorLayers.push(obj);
      } else {
        var obj = {
          display_name: pointLayerData[list].display_name,
          layer_name: pointLayerData[list].layer_name,
          layer_style: pointLayerData[list].layer_style,
          sortById: parseInt(list + 3),
        };
        vectorLayers.push(obj);
      }
    }

    var data = vectorLayers.sort(
      (a, b) => Number(a.sortById) - Number(b.sortById)
    );
    console.log("ascending", data);

    setPointLayer(data);

  };

  const failuerCallBackVector = (response) => {
    console.log(response);
  };

  const exportPDFWithComponent = () => {
    inputEl.current.save();
  };

  // Districts
  const successDistrictCallBack = (response) => {
    var data = response.data.district;

    var values = [];

    for (let disList in data) {
      var distObj = {
        text: data[disList].name,
        value: data[disList].id,
      };
      values.push(distObj);
    }

    setDistrictsList(values);
    setDistrictLoader(false);
  };

  const failuerDistrictCallBack = (response) => {
    // setDistrictLoader(false);
    _getStorageValue(ACCESS_TOKEN).then((token) => {
      props.districtsList(sucessCallBack, failuerCallBackRecheck);
    });
  };

  const sucessCallBack = (response) => {
    var data = response.data.layers;
    var arr = [];
    for (let list in data) {

      var obj = {
        text: list,
        value: data[list],
      };
      arr.push(obj);
    }

    setProductLayer(arr);
    setProductLayerLoad(true);
    setProductLoader(false);
  };

  const failuerCallBack = (response) => {
    console.log(response);
    // setProductLoader(false);
    _getStorageValue(ACCESS_TOKEN).then((token) => {
      props.getProductLayerUser(sucessCallBack, failuerCallBackRecheck);
    });
  };

  const failuerCallBackRecheck = (response) => {
    console.log(response);
  };

  const filterSidebar = () => {
    filterMapRender();

    // setIsVisbleSideBar(!isVisbleSideBar);
    setsilder(false);
    setgraphScreen(false);
    setMapScreen(true);
    setDisableSlide(false);
  };

  const arrowfilterSidebar = () => {
    filterMapRender();
    setIsVisbleSideBar(!isVisbleSideBar);
  };

  const triggerCallApply = (data) => {
    setMapCerdential(data);
    setMapRendert(true);
    setTimeout(() => {
      setMapRendert(false);
    }, 300);
  };

  const callFilterScreenCallBack = (fproduct) => {
    // setDisplayList(fproduct);
    //changed screen
    // setMapScreen(false);
    // setsilder(true);

    // if (mapScreen) {
    //   takeScreenshot(ref.current);
    // }
    // back
    setsilder(true);
    setgraphScreen(false);
    setMapScreen(false);
    setDisableSlide(false);
  };

  const barChartTriggerCallBack = (bproduct) => {
    // setBarDisplayList(bproduct);
    //change screen
    // setMapScreen(false);
    // setsilder(false);
    // setgraphScreen(true);

    // if (mapScreen) {
    //   takeScreenshot(ref.current);
    // }
    // back
    setsilder(false);
    setgraphScreen(true);
    setMapScreen(false);
    setIsVisbleSideBar(false);
    setDisableSlide(true);
  };

  const sessonLoaderCallBack = () => {
    setSessonOption([]);
  };

  const getLayerDateUserValue = (e) => {
    setsessonLoader(true);
    console.log("getLayerDateUserValue", e);
    props.getLayerDateUser(e, dateSuccessCallBack, dateFailureCallBack);
  };

  // Block & Sub Districts
  const getBlockDistictPoly = (e, productValue) => {
    setBlockLoader(true);
    props.blockList(e, blockSuccessCallBack, blockFailerCallBack);
    props.districtsPolyLine(
      e,
      districtsPolyLineSuccessCallBack,
      districtsPolyLineFailureCallBack
    );
    props.statsList(
      productValue,
      e,
      "district",
      successCallBackGraph,
      failureCallBackGraph
    );



  };

  // panhaythu
  const getPanchyatBlockPoly = (e, districtValue, productValue) => {
    setPachayatLoader(true);
    props.panchayatList(
      districtValue,
      e,
      panchayatListSuccessCallBack,
      panchayatListFailureCallBack
    );
    props.blockPolyLine(
      districtValue,
      e,
      blockPolyLineSuccessCallBack,
      blockPolyLineFailureCallBack
    );
    props.statsList(
      productValue,
      e,
      "block",
      successCallBackGraph,
      failureCallBackGraph
    );
  };

  // Village
  const getVillagePanchayatPoly = (
    subdistrictValue,
    districtValue,
    e,
    productValue
  ) => {
    setVillageLoader(true);
    props.villageList(
      districtValue,
      subdistrictValue,
      e,
      villageSuccessCallBack,
      villageFailerCallBack
    );
    props.panchayatPolyLine(
      districtValue,
      subdistrictValue,
      e,
      panchayatPolyLineSuccessCallBack,
      panchayatPolyLineFailureCallBack
    );
    props.statsList(
      productValue,
      e,
      "panchayat",
      successCallBackGraph,
      failureCallBackGraph
    );
  };

  const getVillageListPoly = (
    districtValue,
    panchayatId,
    subdistrictValue,
    e,
    productValue
  ) => {
    props.villagePolyLine(
      districtValue,
      subdistrictValue,
      panchayatId,
      e,
      villagePolyLineSuccessCallBack,
      villagePolyLineFailureCallBack
    );
    props.statsList(
      productValue,
      e,
      "village",
      successCallBackGraph,
      failureCallBackGraph
    );
  };

  // block
  const blockSuccessCallBack = (response) => {
    var data = response.data.blocks;
    var arr = [];
    for (let list in data) {
      var obj = {
        text: data[list].name,
        value: data[list].id,
      };
      arr.push(obj);
    }
    setBlocksOptions(arr);
    // setBlockLoader(false);
  };

  const blockFailerCallBack = (response) => {
    console.log(response);
    // setBlockLoader(false);
  };

  // districtsPolygonLine
  const districtsPolyLineSuccessCallBack = (response) => {
    var distictcordinate = response.data.district.centroid.coordinates;
    var distictPolygon = response.data.district.boundary.coordinates;
    var districtlatlog = [distictcordinate[1], distictcordinate[0]];
    setCentroidLatLon(districtlatlog);
    setPolyGonGeo(distictPolygon);
    setBlockLoader(false);
  };

  const districtsPolyLineFailureCallBack = (response) => {
    console.log(response);
    setBlockLoader(false);
  };

  const dateSuccessCallBack = (response) => {
    console.log("response.data", response.data);
    var data = response.data;

    if (data !== null) {
      var arr = [];

      for (let list in data) {
        // console.log(data[list], list);

        var obj = {
          text: list,
          value: data[list],
        };
        arr.push(obj);
      }

      setSessonOption(arr);
      setsessonLoader(false);
    } else {
      var nodata = [
        {
          text: "May - 2019",
          value: "May - 2019",
        },
        {
          text: "May - 2020",
          value: "May - 2020",
        },
        {
          text: "All Season",
          value: "All Season",
        },
      ];
      setSessonOption(nodata);
      setsessonLoader(false);
      // setSessonEnableShow(true);
    }
  };

  const dateFailureCallBack = (response) => {
    console.log(response);
    setsessonLoader(false);
  };

  const successCallBackGraph = (response) => {
    setBarChartValue(response.data.stats.value);
    // var dumydata1 = {
    //   "1": {
    //     "count": [
    //       185067.2,
    //       166408.14,
    //       139171.98,
    //       159960.98,
    //       103362.28
    //     ],
    //     "style": "#00FF00",
    //     "leg": "single",
    //     "year": [
    //       2015,
    //       2016,
    //       2017,
    //       2018,
    //       2019
    //     ]
    //   },
    //   "2": {
    //     "count": [
    //       135211.45,
    //       137152.37,
    //       186374.76,
    //       151934.14,
    //       218281.64
    //     ],
    //     "style": "#FFFF00",
    //     "leg": "singhhhle",
    //     "year": [
    //       2015,
    //       2016,
    //       2017,
    //       2018,
    //       2019
    //     ]
    //   }
    // }


    // Start code for graph fixes
    var dumydata1 = response.data.stats.value;
    var year = [];
    var graphDataSet = [];
    for (let list in dumydata1) {
      var loopYear = dumydata1[list].year;
      for (let listYear in loopYear) {
        console.log("dumydata1", loopYear[listYear]);
        year.push(`${loopYear[listYear]}`);
      }
    }
    for (let list1 in dumydata1) {
      console.log("dumydata1", dumydata1[list1].style);
      var dataset = {
        label: dumydata1[list1].name,
        maxBarThickness: 60,
        // backgroundColor: colorBarchart,
        // borderColor: colorBarchart,
        backgroundColor: dumydata1[list1].style,
        borderColor: dumydata1[list1].style,
        data: dumydata1[list1].count
      }
      graphDataSet.push(dataset);
    }
    let unique = year.filter((item, i, ar) => ar.indexOf(item) === i);
    var data = {
      labels: unique,
      datasets: graphDataSet
    };
    setBarGraph(data);
    // end code for graph fixes

  };

  const failureCallBackGraph = (response) => {
    console.log("failureCallBackGraph", response);
  };

  // pancyath
  const panchayatListSuccessCallBack = (response) => {
    console.log(response);

    var data = response.data.panchayat;

    var arr = [];

    for (let list in data) {
      // console.log(data[list]);

      var obj = {
        text: data[list].name,
        value: data[list].id,
      };
      arr.push(obj);
    }

    setPanchayatOptions(arr);
    // setPachayatLoader(false);
  };

  const panchayatListFailureCallBack = (response) => {
    console.log(response);
    setPanchayatOptions([]);
    // setPachayatLoader(false);
  };

  const villageSuccessCallBack = (response) => {
    // console.log(response);
    // console.log(response.data);
    var data = response.data.villages;
    var arr = [];

    for (let list in data) {
      // console.log(data[list]);

      var obj = {
        text: data[list].name,
        value: data[list].id,
      };
      arr.push(obj);
    }

    setVillageOptions(arr);
  };

  const villageFailerCallBack = (response) => {
    console.log(response);
  };

  const blockPolyLineSuccessCallBack = (response) => {
    var blockcordinate = response.data.blocks[0].centroid.coordinates;
    var blocklatlog = [blockcordinate[1], blockcordinate[0]];
    var blockPolygon = response.data.blocks[0].boundary.coordinates;
    setBlockCentroidLatLon(blocklatlog);
    setBlockPolyGonGeo(blockPolygon);
    setPachayatLoader(false);
  };

  const blockPolyLineFailureCallBack = (response) => {
    console.log(response);
    setPachayatLoader(false);
  };

  // pnchay
  const panchayatPolyLineSuccessCallBack = (response) => {
    console.log("pancath poly", response);
    var panchatcordinate = response.data.panchayat[0].centroid.coordinates;
    var panchayatlatlog = [panchatcordinate[1], panchatcordinate[0]];
    var panchayatPolygon = response.data.panchayat[0].boundary.coordinates;
    setPanchayatLatLon(panchayatlatlog);
    setPanchayatGonGeo(panchayatPolygon);
    setVillageLoader(false);
  };

  const panchayatPolyLineFailureCallBack = (response) => {
    console.log(response);
    setVillageLoader(false);
  };

  // faf
  const villagePolyLineSuccessCallBack = (response) => {
    var villagecordinate = response.data.villages[0].centroid.coordinates;
    var villagelatlog = [villagecordinate[1], villagecordinate[0]];
    var villagePolygon = response.data.villages[0].boundary.coordinates;
    setVillageCentroidLatLon(villagelatlog);
    setVillagePolyGonGeo(villagePolygon);
  };

  const villagePolyLineFailureCallBack = (response) => {
    console.log(response);
  };

  const filterMapRender = () => {
    setMapRendert(true);
    setTimeout(() => {
      setMapRendert(false);
    }, 1000);
  };

  const filterMapRenderPdf = () => {
    setMapRendert(true);
    setTimeout(() => {
      setMapRendert(false);
    }, 100);
  };

  const triggerPdfDownload = () => {
    setconfromMapPdf(true);
    setDisableGoogleLayer(true);
    setMapRendert(true);
    setTimeout(() => {
      setMapRendert(false);
    }, 100);
  };

  const triggerMapPdfDownload = () => {
    exportPDFWithComponent();
    setTimeout(() => {
      setShowPdf(false);
      filterMapRenderPdf();
    }, 1000);
  };

  const changeLeftMapWms = (value) => {
    setCompareMapLoader(false);
    setLeftMapWmsYear(value);
    setTimeout(() => {
      setCompareMapLoader(true);
    }, 500);
  };

  const changeRightMapWms = (value) => {
    setCompareMapLoader(false);
    setRightMapWmsYear(value);
    setTimeout(() => {
      setCompareMapLoader(true);
    }, 500);
  };

  const handleMClose = () => setShowPdf(false);

  const handleMShow = () => setShowPdf(true);

  const conformMapPdfhandleClose = () => {
    setDisableGoogleLayer(false);
    setconfromMapPdf(false);
    filterMapRenderPdf();
  };

  const genreatePdf = (e) => {
    setmapPdfImage(e);
    setDisableGoogleLayer(false);
    setTimeout(() => {
      setconfromMapPdf(false);
      setShowPdf(true);
    }, 100);
  };

  // bar Chart
  const triggerPdfBarChart = () => {
    setBarExportLoader(true);
    setconfromBarChartPdf(true);
    setTimeout(() => {
      setBarExportLoader(false);
    }, 100);
  };

  const barChartPdfImage = (e) => {
    takeScreenshot(e);
    setconfromBarChartPdf(false);
    setbarChartPdfDownload(true);
  };
  const conformBarPdfhandleClose = () => {
    setconfromBarChartPdf(false);
    setTimeout(() => {
      setBarnoLoader(true);
    }, 100);
  };

  const handleBarClose = () => {
    setbarChartPdfDownload(false);
  };

  const triggerBarPdfDownload = () => {
    exportPDFWithComponent();
    setTimeout(() => {
      setbarChartPdfDownload(false);
    }, 1000);
  };

  const addLegandData = (data) => {
    setLegendsData(data);
    var colorBArr = [];
    for (let list in data) {
      colorBArr.push(data[list].color)
      console.log("color", colorBArr);
    }
    // setColorBarchart(colorBArr);
  }

  var yearSplit = mapCerdential.time.split("-");

  console.log("Split", mapCerdential);

  var applyLoaderCheck = blockLoader || pachayatLoader || villageLoader;

  return (
    <div className="dashboard-sections">
      <Header
        filterSidebar={filterSidebar}
        barChatScreen={barChartTriggerCallBack}
        compareCallBack={callFilterScreenCallBack}
        headerCerdential={mapCerdential}
        mapScreen={mapScreen}
        silderScreen={silder}
        graphScreen={graphScreen}
        loginuiHeader={true}
      />
      <div className="dashboard-components">
        <div className="sidebar">
          <SideBar
            mapCerdentialSide={mapCerdential}
            disableSlide={disableSlide}
            mapScreen={mapScreen}
            silder={silder}
            graphScreen={graphScreen}
            isVisbleSideBar={isVisbleSideBar}
            applyLoaderBtn={false}
            productLayer={productLayer}
            productLayerLoad={productLayerLoad}
            districtsList={districtsList}
            sessonOption={sessonOption}
            blocksOptions={blocksOptions}
            centroidLatLon={centroidLatLon}
            polyGonGeo={polyGonGeo}
            villageOption={villageOption}
            barChartValue={barChartValue}
            blockcentroidLatLon={blockcentroidLatLon}
            blockpolyGonGeo={blockpolyGonGeo}
            villagecentroidLatLon={villagecentroidLatLon}
            villagepolyGonGeo={villagepolyGonGeo}
            panchayatOptions={panchayatOptions}
            panchayatLatLon={panchayatLatLon}
            panchayatGonGeo={panchayatGonGeo}
            productLoader={productLoader}
            districtLoader={districtLoader}
            pachayatLoader={pachayatLoader}
            barExportLoader={barExportLoader}
            blockLoader={blockLoader}
            sessonLoader={sessonLoader}
            sessonLoaderCallBack={sessonLoaderCallBack}
            getLayerDateUserValue={getLayerDateUserValue}
            getBlockDistictPoly={getBlockDistictPoly}
            getPanchyatBlockPoly={getPanchyatBlockPoly}
            getVillagePanchayatPoly={getVillagePanchayatPoly}
            getVillageListPoly={getVillageListPoly}
            arrowfilterSidebar={arrowfilterSidebar}
            callApply={triggerCallApply}
            triggerPdfDownload={triggerPdfDownload}
            changeRightMapWms={changeRightMapWms}
            changeLeftMapWms={changeLeftMapWms}
            triggerPdfBarChart={triggerPdfBarChart}
            sideBarCerdential={mapCerdential}
            applyLoaderBtn={mapCerdential}
            applyLoaderCheck={applyLoaderCheck}
            addLegandData={addLegandData}
          />
        </div>
        {(() => {
          if (mapScreen) {
            if (mapRender) {
              // return <ComponentLoader />;
            } else {
              return (
                <div ref={ref} className="map-sections">
                  <OpenLayerMap
                    mapCerdential={mapCerdential}
                    confromMapPdf={confromMapPdf}
                    conformMapPdfhandleClose={conformMapPdfhandleClose}
                    legendsData={legendsData}
                    // mapnoLoader={mapnoLoader}
                    genreatePdf={genreatePdf}
                    pointLayer={pointLayer}
                    disableGoogleLayer={disableGoogleLayer}
                    // resizeScreen={resizeMapScreenShot}\
                    blockLoader={blockLoader}
                    pachayatLoader={pachayatLoader}
                  />
                </div>
              );
            }
          } else if (silder) {
            return (
              <SessonLayout
                displayList={mapCerdential}
                mapRender={mapRender}
                leftMapWmsYear={leftMapWmsYear}
                rightMapWmsYear={rightMapWmsYear}
                compareMapLoader={compareMapLoader}
              />
            );
          } else if (graphScreen) {
            return (
              <div>
                <BarChartDashBoard
                  // mapImage={image}
                  // colorBarchart={colorBarchart}
                  // barcallHomeScreen={barcallHomeScreen}
                  barGraph={barGraph}
                  bardisplayList={mapCerdential}
                  confromBarChartPdf={confromBarChartPdf}
                  barChartPdfImage={barChartPdfImage}
                  conformBarPdfhandleClose={conformBarPdfhandleClose}
                  barnoLoader={barnoLoader}
                />
              </div>
            );
          }
        })()}
      </div>
      {/* {(() => {
        if (blockLoader) {
          return (<div class="cloader"> <ComponentLoader /></div>);
        } else if (pachayatLoader) {
          return (<div class="cloader"> <ComponentLoader /></div>);
        } else if (villageLoader) {
          return (<div class="cloader"> <ComponentLoader /> </div>);
        } else if (!compareMapLoader) {
          return (<div class="cloader"> <ComponentLoader /> </div>);
        }
      })()} */}
      {(() => {
        if (!compareMapLoader) {
          return (
            <div class="cloader">
              <ComponentLoader />
            </div>
          );
        }
      })()}
      {/* Bar Chart */}
      <Modal
        className="pdf-modal"
        show={barChartPdfDownload}
        onHide={handleBarClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="pdf-header-title">
            {/* <h3>Export Pdf</h3> */}
            <div className="download-pdf">
              <LoaderButton
                btnName={"Download"}
                btnCallBack={triggerBarPdfDownload}
                btnDisable={false}
                btnLoader={false}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PDFExport
            ref={inputEl}
            paperSize="A4"
            // margin={40}
            fileName={`Report for ${new Date().getFullYear()}`}
            author="KendoReact Team"
          >
            <div className="map-pdf-sections">
              <div className="pdf-header">
                <div className="header-img">
                  <img src={headerLogo} alt="headerLogo" />
                </div>
                <h2>
                  Satellite-based Rice Fallow & Cropping System Dynamics
                  (SRFCSD)
                </h2>
                <div className="header-img">
                  <img src={goLogo} alt="headerLogo" />
                </div>
              </div>
              <ul className="selection-list">
                {mapCerdential.product.text !== undefined ? (
                  <li>
                    <label>Product :</label>
                    <span>{mapCerdential.product.text}</span>
                  </li>
                ) : null}
                {mapCerdential.state.text !== undefined ? (
                  <li>
                    <label>State : </label>
                    <span>{mapCerdential.state.text}</span>
                  </li>
                ) : null}

                {mapCerdential.district.text !== undefined ? (
                  <li>
                    <label>District :</label>
                    <span>{mapCerdential.district.text}</span>
                  </li>
                ) : null}

                {mapCerdential.block.text !== undefined ? (
                  <li>
                    <label>Block :</label>
                    <span>{mapCerdential.block.text}</span>
                  </li>
                ) : null}

                {mapCerdential.panchaythu.text !== undefined ? (
                  <li>
                    <label>Gram Panchayat :</label>
                    <span>{mapCerdential.panchaythu.text}</span>
                  </li>
                ) : null}
              </ul>
              <div className="map-image">
                <div className="screen-shot">
                  <img src={imageBarShow} alt="headerLogo" />
                </div>
                <label></label>
              </div>
            </div>
          </PDFExport>
        </Modal.Body>
      </Modal>

      {/* ): null} */}
      {/* Map Screen Pdf */}
      <Modal
        className="pdf-modal"
        show={showPdf}
        onHide={handleMClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton onClick={conformMapPdfhandleClose}>
          <Modal.Title className="pdf-header-title">
            {/* <h3>Export Pdf</h3> */}
            <div className="download-pdf">
              <LoaderButton
                btnName={"Download"}
                btnCallBack={triggerMapPdfDownload}
                btnDisable={false}
                btnLoader={false}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PDFExport
            ref={inputEl}
            paperSize="A4"
            // margin={40}
            fileName={`Report for ${new Date().getFullYear()}`}
            author="KendoReact Team"
          >
            <div className="map-pdf-sections">
              <div className="pdf-header">
                <div className="header-img">
                  <img src={headerLogo} alt="headerLogo" />
                </div>
                <h2>
                  Satellite-based Rice Fallow & Cropping System Dynamics
                  (SRFCSD)
                </h2>
                <div className="header-img">
                  <img src={goLogo} alt="headerLogo" />
                </div>
              </div>
              <ul className="selection-list">
                {mapCerdential.product.text != undefined ? (
                  <li>
                    <label>Product :</label>
                    <span>{mapCerdential.product.text}</span>
                  </li>
                ) : null}
                {mapCerdential.time !== undefined ? (
                  <li>
                    <label>Season :</label>
                    <span>{yearSplit[0]}</span>
                  </li>
                ) : null}

                {mapCerdential.state.text !== undefined ? (
                  <li>
                    <label>State : </label>
                    <span>{mapCerdential.state.text}</span>
                  </li>
                ) : null}

                {mapCerdential.district.text !== undefined ? (
                  <li>
                    <label>District :</label>
                    <span>{mapCerdential.district.text}</span>
                  </li>
                ) : null}

                {mapCerdential.block.text !== undefined ? (
                  <li>
                    <label>Block :</label>
                    <span>{mapCerdential.block.text}</span>
                  </li>
                ) : null}

                {mapCerdential.panchaythu.text !== undefined ? (
                  <li>
                    <label>Gram Panchayat :</label>
                    <span>{mapCerdential.panchaythu.text}</span>
                  </li>
                ) : null}
              </ul>
              <div className="map-image">
                <div className="screen-shot">
                  <img src={mapPdfImage} alt="headerLogo" />
                </div>
                <div class="north-icon">
                  <label>
                    <img src={northIcon} alt="headerLogo" />
                  </label>
                </div>
                {/* <label className="layer-text">OpenStreetMap</label> */}
              </div>
            </div>
          </PDFExport>
        </Modal.Body>
      </Modal>
    </div>
  );
};
// redux;
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProductLayerUser: getProductLayerUser,
      getVectorLayerName: getVectorLayerName,
      stateList: stateList,
      districtsList: districtsList,
      getLayerDateUser: getLayerDateUser,
      villagePolyLine: villagePolyLine,
      blockPolyLine: blockPolyLine,
      statsList: statsList,
      districtsPolyLine: districtsPolyLine,
      blockList: blockList,
      villageList: villageList,
      panchayatList: panchayatList,
      panchayatPolyLine: panchayatPolyLine,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardLayout);
