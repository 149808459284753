import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-side-by-side";
import "leaflet-loading";

// style
import "./map-compare.scss";

const styleStateFarm = {
  color: "#DC143C",
  // fillColor: "#000",
  fillOpacity: 0,
  weight: 5,
  opacity: 0.5,
};
const MapCompare = (props) => {
  // console.log(
  //   "props.rightMapWmsYear",
  //   props.bboxData,
  //   props.polygonBoundry,
  //   props.wmsRegionLayer,
  //   props.wmsRegion
  // );

  useEffect(() => {
    if (props.layer !== "") {

      var map = new L.Map("map", { zoomControl: false, maxZoom: 15, minZoom: 4, loadingControl: true });
      L.control.zoom({
        position: 'topright'
      }).addTo(map);
      // console.log("props.rightMapWmsYear in map", props);
      // L.control({
      //   position : 'bottomright'
      // });
      var osm = new L.gridLayer.googleMutant({
        type: "satellite", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
      });

      map.setView(props.center, 10).addLayer(osm);

      L.tileLayer
        .wms("https://isarcgisdemo.irri.org/geoserver/test/wms", {
          layers: props.wmsRegion,
          format: "image/png",
          transparent: true,
          styles: "white_color",
          time: props.leftMapWmsYear,
          CQL_FILTER: `name <> '${props.wmsRegionLayer.text}'`,
        })
        .addTo(map);

      if (props.leftMapWmsYear !== "") {
        // L.tileLayer
        //   .wms("https://isarcgisdemo.irri.org/geoserver/test/wms", {
        //     layers: props.wmsRegion,
        //     format: "image/png",
        //     transparent: true,
        //     time: props.leftMapWmsYear,
        //     CQL_FILTER: `name <> '${props.wmsRegionLayer.text}'`,
        //   })
        //   .addTo(map);
        var wsmLayerLeft = L.tileLayer
          .wms("https://isarcgisdemo.irri.org/geoserver/test/wms", {
            layers: props.layer,
            format: "image/png",
            transparent: true,
            time: props.leftMapWmsYear,
            // styles: "raster_color",
            styles: props.styleLayer
          })
          .addTo(map);
        var stateBoundariesFarm = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: { name: "Washington" },
              geometry: {
                type: "MultiPolygon",
                coordinates: props.polygonBoundry,
              },
            },
          ],
        };

        var multipolygon = L.geoJSON(stateBoundariesFarm, {
          style: styleStateFarm,
        }).addTo(map);
        map.fitBounds(multipolygon.getBounds());
      } else {
        var wsmLayerLeft = new L.gridLayer.googleMutant({
          type: "satellite", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
        }).addTo(map);
        // L.tileLayer
        // .wms("https://isarcgisdemo.irri.org/geoserver/test/wms", {
        //   layers: props.wmsRegion,
        //   format: "image/png",
        //   transparent: true,
        //   time: props.leftMapWmsYear,
        //   CQL_FILTER: `name <> '${props.wmsRegionLayer.text}'`,
        // })
        // .addTo(map);

        var stateBoundariesFarm = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: { name: "Washington" },
              geometry: {
                type: "MultiPolygon",
                coordinates: props.polygonBoundry,
              },
            },
          ],
        };
        var multipolygon = L.geoJSON(stateBoundariesFarm, {
          style: styleStateFarm,
        }).addTo(map);
        map.fitBounds(multipolygon.getBounds());

      }

      if (props.rightMapWmsYear !== "") {

        var wsmLayerRight = L.tileLayer
          .wms("https://isarcgisdemo.irri.org/geoserver/test/wms", {
            layers: props.layer,
            format: "image/png",
            transparent: true,
            time: props.rightMapWmsYear,
            // styles: "raster_color",
            styles: props.styleLayer,
          })
          .addTo(map);

        var stateBoundariesFarm = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: { name: "Washington" },
              geometry: {
                type: "MultiPolygon",
                coordinates: props.polygonBoundry,
              },
            },
          ],
        };
        var multipolygon = L.geoJSON(stateBoundariesFarm, {
          style: styleStateFarm,
        }).addTo(map);
        map.fitBounds(multipolygon.getBounds());
      } else {
        var wsmLayerRight = new L.gridLayer.googleMutant({
          type: "satellite", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
        }).addTo(map);

        var stateBoundariesFarm = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: { name: "Washington" },
              geometry: {
                type: "MultiPolygon",
                coordinates: props.polygonBoundry,
              },
            },
          ],
        };
        var multipolygon = L.geoJSON(stateBoundariesFarm, {
          style: styleStateFarm,
        }).addTo(map);
        map.fitBounds(multipolygon.getBounds());
      }
      L.control.scale({
        metric: true,
        imperial: true,
        position: "bottomleft"
      }).addTo(map);
      L.control.sideBySide(wsmLayerLeft, wsmLayerRight).addTo(map);
    } else {
    }
  }, []);

  return <div id="map" />;
};

export default MapCompare;
