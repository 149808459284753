// constant
import { GET, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { BLOCK_POLY_LINE_FAILURE, BLOCK_POLY_LINE_SUCCESS, BLOCK_POLY_LINE_REQUEST } from "./actionConstants";

// url
import { blockPolygonUrl } from "../common/urls";

const blockPolyLineRequested = () => ({
    type: BLOCK_POLY_LINE_REQUEST,
});

const blockPolyLineSuccess = (data) => ({
    type: BLOCK_POLY_LINE_SUCCESS,
    data,
});

const blockPolyLineFailure = (data) => ({
    type: BLOCK_POLY_LINE_FAILURE,
    data,
});

export const blockPolyLine = (districtId, blockId, successCallBack, failureCallBack) => (dispatch) => {
    dispatch(blockPolyLineRequested());

    const url = blockPolygonUrl(districtId, blockId);
   
    const onSuccess = (response) => {
        successCallBack(response);
        dispatch(blockPolyLineSuccess(response));
    };

    const onFailure = (response) => {
        failureCallBack(response);
        dispatch(blockPolyLineFailure(response));
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(GET, url, "", onSuccess, onFailure, dispatch, token);
        }
    });
};
