import {
    STATE_FAILURE,
    STATE_SUCCESS,
    STATE_REQUEST,
} from "../action/actionConstants";

const initialState = {
    isFetchingState: false,
    error: false,
    status: "",
    message: "intial",
};

const stateList = (state = initialState, action) => {
    switch (action.type) {
        case STATE_REQUEST:
            return {
                ...state,
                isFetchingState: true,
                message: "Request",
            };
        case STATE_FAILURE:
            return {
                ...state,
                isFetchingState: false,
                error: true,
                status: action.data.status,
                message: "Failure",
            };
        case STATE_SUCCESS:
            return {
                ...state,
                isFetchingState: true,
                error: false,
                status: action.data.status,
                message: "Success",
            };
        default:
            return state;
    }
};

export default stateList;
