// react
import React, { useState } from "react";

// plugin
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

// component
import LoaderButton from "../../component/loader-button/LoaderButton";

// Function
import { enableUser } from "../../action/enableUser";
import { disableUser } from "../../action/disableUser";

// style
import "../../layouts/forgot-password-layout/forgot-password.scss";
import "../../layouts/enable-disable-layout/enable-disable.scss";
// Image
import close from '../../asstes/images/close.png';

// contanier

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    colors: {
        borderColor: "green",
    },
}));

const EnableDisableLayout = (props) => {
    let history = useHistory();

    const [edemail, setEDEmail] = useState("");
    const [EDEmailErrorShow, setEDEmailErrorShow] = useState(false);

    const classes = useStyles();
    const [enableLoader, setEnableLoader] = useState(false);
    const [disableLoader, setDisableLoader] = useState(false);

    // Email
    const edemailValidCalBack = (e) => {
        var validEDEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(e);
        // console.log(!validEDEmail);
        setEDEmailErrorShow(!validEDEmail)
        setEDEmail(e)
    }

    // Enable Mail Id
    const enable = () => {
        setEnableLoader(true);
        var dataSet = {
            email: edemail,
        };
        props.enableUser(dataSet, enableSuccessCallBack, enablefailureCallBack);
    };
    const enablefailureCallBack = () => {
        // console.log("fails");
        setDisableLoader(false);
        toast.error("Enable Mail id failure 😔");
    };
    const enableSuccessCallBack = () => {
        setDisableLoader(true);
        toast.success("Enable Mail id success 😅");
        // setTimeout(() => {
        //     window.location.reload();
        // }, 300);
    };
    // Disable Mail Id
    const disable = () => {
        setDisableLoader(true);
        var dataSet = {
            email: edemail,
        };
        props.disableUser(dataSet, disableSuccessCallBack, disablefailureCallBack);

    };
    const disablefailureCallBack = () => {
        // console.log("fails");
        setDisableLoader(false);
        toast.error("Disable Mail id failure 😔");
        setTimeout(() => {
            props.closeEnableDisableUpModel();
        }, 500);
    };
    const disableSuccessCallBack = () => {
        setDisableLoader(true);
        props.closeEnableDisableUpModel();
        toast.success("Disable Mail id success 😅");
        // setTimeout(() => {
        //     window.location.reload();
        // }, 300);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body >
                <div className="close-icon" onClick={props.onHide}>
                    <img src={close} alt="close" /></div>
                <div className="irri-forgot">
                    <div className="forgot-password">
                        <h2>Enable & Disable User E-mail</h2>
                        <div className="forgot-field">
                            <TextField
                                id="outlined-password-input"
                                label="Email"
                                type="text"
                                value={edemail}
                                onChange={(e) => edemailValidCalBack(e.target.value)}
                                autoComplete="off"
                                variant="outlined"
                                error={EDEmailErrorShow}
                            />
                            <div className="ena-dsa-btn">
                                <LoaderButton
                                    btnName={"Enable"}
                                    btnCallBack={enable}
                                    btnDisable={enableLoader}
                                    btnLoader={enableLoader}
                                />
                                <LoaderButton
                                    btnName={"Disable"}
                                    btnCallBack={disable}
                                    btnDisable={disableLoader}
                                    btnLoader={disableLoader}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
// redux;
const mapStateToProps = (state) => {
    return {
        isFetchingEnable: state.enableUser.isFetchingEnable,
        isFetchingDisable: state.disableUser.isFetchingDisable,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            enableUser: enableUser,
            disableUser: disableUser,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EnableDisableLayout);