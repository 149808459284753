// constant
import { GET, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { VILLAGE_LIST_FAILURE, VILLAGE_LIST_SUCCESS, VILLAGE_LIST_REQUEST } from "./actionConstants";

// url
import { villageListUrl } from "../common/urls";

const villageRequested = () => ({
    type: VILLAGE_LIST_REQUEST,
});

const villageSuccess = (data) => ({
    type: VILLAGE_LIST_SUCCESS,
    data,
});

const villageFailure = (data) => ({
    type: VILLAGE_LIST_FAILURE,
    data,
});

export const villageList = ( districtId ,subDistrictsName,panchayatId , successCallBack, failureCallBack) => (dispatch) => {
    dispatch(villageRequested());
  
    const url = villageListUrl( districtId, subDistrictsName, panchayatId);
  
    const onSuccess = (response) => {
        successCallBack(response);
        dispatch(villageSuccess(response));
    };

    const onFailure = (response) => {
        failureCallBack(response);
        dispatch(villageFailure(response));
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(GET, url, "", onSuccess, onFailure, dispatch, token);
        }
    });
};
