// react
import React, { useState, useRef, createRef } from "react";

// plugin
import { Bar } from "react-chartjs-2";
// import "chartjs-plugin-datalabels";
import Modal from "react-bootstrap/Modal";
import { sum } from "lodash";
import "chartjs-plugin-datalabels";

// styles
import "./barchart.scss";
import LoaderButton from "../../../component/loader-button/LoaderButton";
import "../../../layouts/otp-layout/otp.scss";


const BarChartDashBoard = (props) => {
  const ref = createRef(null);

  const [imageShow, setimageShow] = useState(false);

  const [baryesLoader, setBaryesLoader] = React.useState(false);

  const triggerBarImageAndPdf = () => {
    props.barChartPdfImage(ref.current);
    setBaryesLoader(true);
    setTimeout(() => {
      setBaryesLoader(false);
    }, 300);
  }

  // var dumydata1 = {
  //   "1": {
  //     "count": [51087.4, 54579.66, 50520.82, 34694.35, 44119.37],
  //     "year": [2015, 2016, 2017, 2018, 2019]
  //   },
  //   "2": {
  //     "count": [
  //       54220.07,
  //       52336.96,
  //       57914.28,
  //       67638.44,
  //       58800.28
  //     ],
  //     "year": [
  //       2015,
  //       2016,
  //       2017,
  //       2018,
  //       2019
  //     ]
  //   }
  // }


  // var year = [];
  // var graphDataSet = [];
  // var colors = [];


  // for(let list in dumydata1){
  //   var loopYear = dumydata1[list].year;
  //   for(let listYear in loopYear) {
  //     year.push(`${loopYear[listYear]}`);
  //   }
  // }

  // for(let list1 in dumydata1){
  //   console.log("dumydata1", list1[dumydata1]);

  // }


  // let unique = year.filter((item, i, ar) => ar.indexOf(item) === i);

  // var data = {
  //   labels: unique,
  //   datasets: graphDataSet
  // };


  const options = {
    plugins: {
      // Change options for ALL labels of THIS CHART
      datalabels: {
        anchor: function (context) {
          var value = context.dataset.data[context.dataIndex];
          return value.x < 1000 ? 'start' : 'end';
        },
        align: function (context) {
          var value = context.dataset.data[context.dataIndex];
          return value.x < 1000 ? 'start' : 'end';
        },
        offset: 2,
        padding: 0,
        color: '#116366',
        formatter: Math.round,
        font: {
          size: 20,
        },
      }
    },
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "top",
      labels: {
        fontColor: "#116366",
        fontSize: 20,
        // offset: 20,
        // padding: 20,
      },
    },
    scales: {
      xAxes: [
        {
          barThickness: 70,
          gridLines: { color: "#116366" },
          ticks: {
            fontSize: 20,
            fontColor: "#116366",
          },
        },
      ],

      yAxes: [
        {
          gridLines: { color: "#116366" },
          ticks: {
            fontSize: 20,
            fontColor: "#116366",
            // min: 0,
            // max: 10000,
            stepSize: props.bardisplayList.barChartStep
          },
          scaleLabel: {
            display: true,
            fontSize: 20,
            fontColor: "#116366",
            labelString: 'Hectares (Ha)'
          }
        },
      ],
    },

    tooltips: {
      enabled: false,
    },
  };

  // console.log("props.barGraph",props.barGraph)


  return (
    <div ref={ref} className="chart-sections">
      <div className="bar-chart">
        <Bar data={props.barGraph} options={options} />
      </div>
      <div>
        <Modal
          className="view-report"
          centered
          show={props.confromBarChartPdf}
          onHide={props.conformBarPdfhandleClose}
        >
          <Modal.Body className="generate-pdf">
            <label>View Report</label>
            <div className="select-button">
              <LoaderButton
                btnName={"Yes"}
                btnCallBack={triggerBarImageAndPdf}
                btnDisable={false}
                btnLoader={baryesLoader}
              />
              <LoaderButton
                btnName={"No"}
                btnCallBack={props.conformBarPdfhandleClose}
                btnDisable={baryesLoader}
              // btnLoader={props.barnoLoader}
              />
            </div>
          </Modal.Body>
        </Modal></div>
    </div>
  );
};

export default BarChartDashBoard;
