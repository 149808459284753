// constant
import { GET, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { LAYER_DATE_FAILURE, LAYER_DATE_SUCCESS, LAYER_DATE_REQUEST } from "./actionConstants";

// url
import { getLayerDate } from "../common/urls";

const getlayerNameRequested = () => ({
    type: LAYER_DATE_REQUEST,
});

const getlayerNameSuccess = (data) => ({
    type: LAYER_DATE_SUCCESS,
    data,
});

const getlayerNameFailure = (data) => ({
    type: LAYER_DATE_FAILURE,
    data,
});

export const getLayerDateUser = (layerName, successCallBack, failureCallBack) => (dispatch) => {

    dispatch(getlayerNameRequested);

    const url = getLayerDate(layerName);

    const onSuccess = (response) => {
        successCallBack(response);
        dispatch(getlayerNameSuccess(response));
    };

    const onFailure = (response) => {
        failureCallBack(response);
        dispatch(getlayerNameFailure(response));
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(GET, url, "", onSuccess, onFailure, dispatch, token);
        }
    });
};
