// constant
import { POST, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant 
import { FORGOTPASSWORD_FAILURE, FORGOTPASSWORD_SUCCESS, FORGOTPASSWORD_REQUEST } from "./actionConstants";

// url
import { FORGOTPASSWORD_USER_URL } from "../common/urls";


const forgotpasswordRequested = () => ({
  type: FORGOTPASSWORD_REQUEST,
});

const forgotpasswordSuccess = (data) => ({
  type: FORGOTPASSWORD_SUCCESS,
  data,
});

const forgotpasswordFailure = (data) => ({
  type: FORGOTPASSWORD_FAILURE,
  data,
});

export const forgotpasswordUser = (userData, successCallBack, failureCallBack) => (dispatch) => {
  dispatch(forgotpasswordRequested);

  const url = FORGOTPASSWORD_USER_URL;

  const onSuccess = (response) => {
    successCallBack(response);
    dispatch(forgotpasswordSuccess(response));
  };

  const onFailure = (response) => {
    failureCallBack(response);
    dispatch(forgotpasswordFailure(response));
  };
  _getStorageValue(ACCESS_TOKEN).then((token) => {
    if (token !== undefined && token !== null) {
      apiCall(POST, url, userData, onSuccess, onFailure, dispatch, token);
    }
  });
};
