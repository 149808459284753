// react
import React, { useEffect } from "react";

// plugin
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';


// Component
import SelectPicker from "../../../component/select-picker/SelectPicker";
import LoaderButton from "../../../component/loader-button/LoaderButton";
import ComponentLoader from "../../../public-screen/component-loader/ComponetLoader";
import { logoutUser } from "../../../action/logout";
import { getLayerDateUser } from "../../../action/getLayerDate";
import { getLayeStyleUser } from "../../../action/getLayerStyle";
import { blockList } from "../../../action/blockList";
import { villageList } from "../../../action/villageList";
import { panchayatList } from "../../../action/panchayatList";
import { panchayatPolyLine } from "../../../action/panchayatPolyLine";
import { districtsPolyLine } from "../../../action/districtsPolyLine";
import { villagePolyLine } from "../../../action/villagePolyLine";
import { blockPolyLine } from "../../../action/blockPolyline";
import { statsList } from "../../../action/stats";
import { setSelectedValue, removeSelectedItem, getValue } from "../../../common/localStorage";

// Images
import search from "../../../asstes/images/search.png";
import headerLogo from "../../../asstes/images/header-logo.svg";
import rightArrow from "../../../asstes/images/right-arrow.png";

import { statGeo } from "../ol-map/stateJson";

// styles
import "./sidebar.scss";

// js
var bbox = require('geojson-bbox');

const stateOption = [{ text: "Odisha", value: "Odisha" }];

const SideBar = (props) => {
  let history = useHistory();

  const [productValue, setProductValue] = React.useState("");
  const [sessonValue, setSessonValue] = React.useState("");
  const [stateValue, setStateValue] = React.useState("Odisha");
  const [districtValue, setDistrictValue] = React.useState("");
  const [subdistrictValue, setSubDistrictValue] = React.useState("");
  const [panchayatValue, setPanchayatValue] = React.useState("");
  const [villageValue, setVillageValue] = React.useState("");
  const [sesson1value, setSesson1Value] = React.useState("");
  const [sesson2value, setSesson2Value] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [getLayerStyle, setGetLayerStyle] = React.useState("");
  const [isVisbleButton, setIsVisbleButton] = React.useState(true);
  const [dataCredincial, setDataCredinicial] = React.useState({});
  const [applyLoader, setApplyLoader] = React.useState(false);
  const [clearLoader, setClearLoader] = React.useState(false);

  const [exportLoader, setExportLoader] = React.useState(false);
  const [compareLoaderBtn, setCompareLoaderBtn] = React.useState(false);
  const [floodDistrict, setFloodDistrict] = React.useState(false);
  const [floodSeason, setFloodSeason] = React.useState(false);

  const open = Boolean(anchorEl);
  const [districtLatLog] = React.useState({
    districtlatlog: "",
  });

  useEffect(() => {

    var checkList = props.barChartValue.layer;
    if (checkList !== undefined) {
      if (getValue("PRODUCT") !== undefined) {
        setProductValue(getValue("PRODUCT"));
      } else {
        setProductValue("");
      }
      if (getValue("STATE") !== undefined) {
        setStateValue(getValue("STATE"));
      } else {
        setStateValue("Odisha");
      }
      if (getValue("DISTRICT") !== undefined) {
        setDistrictValue(getValue("DISTRICT"));
      } else {
        setDistrictValue("");
      }

      if (getValue("VILLAGE") !== undefined) {
        setVillageValue(getValue("VILLAGE"));
      } else {
        setVillageValue("");
      }

      if (getValue("BLOCK") !== undefined) {
        setSubDistrictValue(getValue("BLOCK"));
      } else {
        setSubDistrictValue("");
      }

      if (getValue("GRAM_PANCHAYAT") !== undefined) {
        setPanchayatValue(getValue("GRAM_PANCHAYAT"));
      } else {
        setPanchayatValue("");
      }

      if (getValue("SESSON") !== undefined) {
        setSessonValue(getValue("SESSON"));
      } else {
        setSessonValue("");
      }
    } else {
      setProductValue("");
      setDistrictValue("");
      setSubDistrictValue("");
      setVillageValue("");
      setSessonValue("");
      setSelectedValue("PRODUCT");
      setSelectedValue("DISTRICT");
      setSelectedValue("GRAM_PANCHAYAT");
      setSelectedValue("SESSON");
      setSelectedValue("BLOCK");
      setSelectedValue("VILLAGE");
    }
  }, []);
  const loaderTrigger = () => {
    var validApply =
      productValue !== "" &&
      sessonValue !== "" &&
      stateValue !== "";
    // districtValue !=="";

    if (validApply) {
      setApplyLoader(true);
      var productArr = props.productLayer;
      var findProduct = productArr.find((obj) => obj.value === productValue);

      var villageArr = props.villageOption;
      var findVillage = villageArr.find((obj) => obj.value === villageValue);

      var districtsArr = props.districtsList;
      var findDistrict = districtsArr.find(
        (obj) => obj.value === districtValue
      );

      var subDistrictsArr = props.blocksOptions;
      var findSubDistrict = subDistrictsArr.find(
        (obj) => obj.value === subdistrictValue
      );

      var panchayatArr = props.panchayatOptions;
      var findPanchauat = panchayatArr.find(
        (obj) => obj.value === panchayatValue
      );

      // Check if condition filter
      if (villageValue !== "") {
        var polygonGeoBoundry = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "MultiPolygon",
                coordinates: props.villagepolyGonGeo,
              },
            },
          ],
        };

        var extent = bbox(polygonGeoBoundry);

        var data = {
          layer: productValue,
          product: findProduct,
          season: props.sessonOption,
          state: { text: "Odisha", value: "Odisha" },
          district: findDistrict,
          block: findSubDistrict,
          village: findVillage,
          panchaythu: findPanchauat,
          time: sessonValue,
          center: props.villagecentroidLatLon,
          centroid: props.villagecentroidLatLon,
          isVisble: true,
          zoom: 12,
          polygonBoundry: props.villagepolyGonGeo,
          wmsRegionLayer: findVillage,
          wmsRegion: "test:village",
          bboxData: [
            [extent[1], extent[0]],
            [extent[3], extent[2]],
          ],
          barDataValues: props.barChartValue,
          barChartStep: 100,
          styleLayer: getLayerStyle,
        };


        // village
        console.log("village :::::::::::::::::::::::", data);
        props.callApply(data);

      } else if (panchayatValue !== "") {

        var polygonGeoBoundry = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "MultiPolygon",
                coordinates: props.panchayatGonGeo,
              },
            },
          ],
        };

        var extent = bbox(polygonGeoBoundry);
        var data = {
          layer: productValue,
          product: findProduct,
          season: props.sessonOption,
          state: { text: "Odisha", value: "Odisha" },
          district: findDistrict,
          block: findSubDistrict,
          panchaythu: findPanchauat,
          village: { text: undefined, value: "Odisha" },
          time: sessonValue,
          center: props.panchayatLatLon,
          centroid: props.panchayatLatLon,
          isVisble: true,
          zoom: 14,
          polygonBoundry: props.panchayatGonGeo,
          wmsRegionLayer: findPanchauat,
          wmsRegion: "test:gram_panchayat_new",
          bboxData: [
            [extent[1], extent[0]],
            [extent[3], extent[2]],
          ],
          barDataValues: props.barChartValue,
          barChartStep: 100,
          styleLayer: getLayerStyle,
        };
        // panchayatGonGeo

        console.log("panchayatGonGeo :::::::::::::::::::::::", data);
        props.callApply(data);

        setTimeout(() => {
          setApplyLoader(false);
        }, 400);
      } else if (subdistrictValue !== "") {

        var polygonGeoBoundry = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "MultiPolygon",
                coordinates: props.blockpolyGonGeo,
              },
            },
          ],
        };

        var extent = bbox(polygonGeoBoundry);

        var data = {
          layer: productValue,
          product: findProduct,
          season: props.sessonOption,
          state: { text: "Odisha", value: "Odisha" },
          district: findDistrict,
          block: findSubDistrict,
          panchaythu: { text: undefined, value: "Odisha" },
          village: { text: undefined, value: "Odisha" },
          time: sessonValue,
          center: props.blockcentroidLatLon,
          centroid: props.blockcentroidLatLon,
          isVisble: true,
          zoom: 11,
          polygonBoundry: props.blockpolyGonGeo,
          wmsRegionLayer: findSubDistrict,
          wmsRegion: "test:block_shaoe",
          bboxData: [
            [extent[1], extent[0]],
            [extent[3], extent[2]],
          ],
          barDataValues: props.barChartValue,
          barChartStep: 3000,
          styleLayer: getLayerStyle,
        };

        // subDistrict

        console.log("subDistrict :::::::::::::::::::::::", data);
        props.callApply(data);
        setTimeout(() => {
          setApplyLoader(false);
        }, 400);
      } else if (districtValue !== "") {

        var polygonGeoBoundry = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "MultiPolygon",
                coordinates: props.polyGonGeo,
              },
            },
          ],
        };

        var extent = bbox(polygonGeoBoundry);

        console.log("extent", extent)

        var data = {
          layer: productValue,
          product: findProduct,
          season: props.sessonOption,
          state: { text: "Odisha", value: "Odisha" },
          district: findDistrict,
          block: { text: undefined, value: "Odisha" },
          panchaythu: { text: undefined, value: "Odisha" },
          village: { text: undefined, value: "Odisha" },
          time: sessonValue,
          center: props.centroidLatLon,
          centroid: props.centroidLatLon,
          isVisble: true,
          zoom: 9,
          polygonBoundry: props.polyGonGeo,
          wmsRegionLayer: findDistrict,
          wmsRegion: "test:district",
          bboxData: [
            [extent[1], extent[0]],
            [extent[3], extent[2]],
          ],
          barDataValues: props.barChartValue,
          barChartStep: 30000,
          styleLayer: getLayerStyle,
        };

        props.callApply(data);
        setTimeout(() => {
          setApplyLoader(false);
        }, 400);
      } else if (stateValue !== "") {

        var polygonGeoBoundry = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "MultiPolygon",
                coordinates: statGeo,
              },
            },
          ],
        }
        var extent = bbox(polygonGeoBoundry);

        var data = {
          layer: productValue,
          time: sessonValue,
          center: [20.49, 84.46],
          isVisble: true,
          zoom: 7,
          season: [],
          product: findProduct,
          state: { text: "Odisha", value: "Odisha" },
          district: { text: undefined, value: "Odisha" },
          block: { text: undefined, value: "Odisha" },
          panchaythu: { text: undefined, value: "Odisha" },
          village: { text: undefined, value: "Odisha" },
          centroid: [20.49, 84.46],
          polygonBoundry: statGeo,
          wmsRegion: "test:district",
          wmsRegionLayer: { text: "", value: 1 },
          bboxData: [
            [extent[1], extent[0]],
            [extent[3], extent[2]],
          ],
          barChartStep: 30000,
          barDataValues: { layer: "No Data", value: [] },
          styleLayer: "test:drought_style"
        };

        // village: {text: undefined, value: "Odisha"}
        // wmsRegion: "test:district"
        // wmsRegionLayer: {text: "Anugul", value: 1}
        // zoom: 9
        console.log("bboxData", data);
        props.callApply(data);
        setTimeout(() => {
          setApplyLoader(false);
        }, 400);
      }
    } else {

      toast.error("Please Select All Required Field", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      // setApplyLoader(false);
    }

  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Input Fields
  const onChangeProductValue = (e) => {
    setSessonValue("");
    setProductValue(e.target.value);
    setSelectedValue("PRODUCT", e.target.value);
    if (e.target.value === "Fani_Cyclone_Puri_May-2019") {
      setDistrictValue(20);
      setSessonValue("May - 2019");
      setSelectedValue("DISTRICT", 20);
      setFloodDistrict(true);
      setFloodSeason(true);
      setSubDistrictValue("");
      setPanchayatValue("");
      setVillageValue("");
      setSelectedValue("GRAM_PANCHAYAT", "");
      setSelectedValue("BLOCK", "");
      setSelectedValue("VILLAGE", "");
      props.getLayeStyleUser("Fani_Cyclone_Puri_May-2019", getLayeStyleSuccesCallBack, getlayerStyleFailureCallBack)
      props.getLayerDateUserValue("Fani_Cyclone_Puri_May-2019");
      props.getBlockDistictPoly(20, e.target.value);
    } else if (e.target.value === "Amphan_May-2020") {
      setDistrictValue(26);
      setSessonValue("May - 2020");
      setSelectedValue("DISTRICT", 26);
      setFloodDistrict(true);
      setFloodSeason(true);
      //  setFloodSeason(false);
      setSubDistrictValue("");
      setPanchayatValue("");
      setVillageValue("");
      setSelectedValue("GRAM_PANCHAYAT", "");
      setSelectedValue("BLOCK", "");
      setSelectedValue("VILLAGE", "");
      props.getLayeStyleUser("Amphan_May-2020", getLayeStyleSuccesCallBack, getlayerStyleFailureCallBack)
      props.getLayerDateUserValue("Amphan_May-2020");
      props.getBlockDistictPoly(26, e.target.value);
    } else if (e.target.value === "Odisha_Flood_duration_in_days") {
      setDistrictValue("");
      setSessonValue("All Season");
      setSelectedValue("DISTRICT", "");
      setFloodDistrict(true);
      setFloodSeason(true);
      setSubDistrictValue("");
      setPanchayatValue("");
      setVillageValue("");
      setSelectedValue("GRAM_PANCHAYAT", "");
      setSelectedValue("BLOCK", "");
      setSelectedValue("VILLAGE", "");
      props.getLayeStyleUser("Odisha_Flood_duration_in_days", getLayeStyleSuccesCallBack, getlayerStyleFailureCallBack)
      props.getLayerDateUserValue("Odisha_Flood_duration_in_days");
      // props.getBlockDistictPoly(26, e.target.value);
    } else if (e.target.value === "Soil_moisture_suitability_all_dists") {
      setDistrictValue("");
      setSessonValue("All Season");
      setSelectedValue("DISTRICT", "");
      setFloodDistrict(true);
      setFloodSeason(false);
      setSubDistrictValue("");
      setPanchayatValue("");
      setVillageValue("");
      setSelectedValue("GRAM_PANCHAYAT", "");
      setSelectedValue("BLOCK", "");
      setSelectedValue("VILLAGE", "");
      props.getLayeStyleUser("Soil_moisture_suitability_all_dists", getLayeStyleSuccesCallBack, getlayerStyleFailureCallBack)
      props.getLayerDateUserValue("Soil_moisture_suitability_all_dists");
    } else {
      setDistrictValue("");
      setSelectedValue("DISTRICT", "");
      setFloodDistrict(false);
      setFloodSeason(false);
      props.getLayeStyleUser(e.target.value, getLayeStyleSuccesCallBack, getlayerStyleFailureCallBack)
      props.getLayerDateUserValue(e.target.value);
    }
  };

  const getLayeStyleSuccesCallBack = (response) => {

    console.log("response :::", response)

    if (response.data != null) {
      if (response.data.legend_list != null) {
        setGetLayerStyle(response.data.style);
        props.addLegandData(response.data.legend_list);
      } else {
        toast.error("No Legend Found",
          {
            position: toast.POSITION.BOTTOM_CENTER
          });
        setGetLayerStyle(response.data.style);
      }
    } else {
      toast.error("No Data Found", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      props.sessonLoaderCallBack();
    }
  }
  const getlayerStyleFailureCallBack = (response) => {
    toast.error("No Data Found", {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }

  const onChangeSessonValue = (e) => {
    setSessonValue(e.target.value);
    setSelectedValue("SESSON", e.target.value);
  };

  const onChangeStateValue = (e) => {
    setStateValue(e.target.value);
    setSelectedValue("STATE", e.target.value);
  };

  const onChangeDistrictValue = (e) => {
    console.log("onChangeDistrictValue", e.target.value);
    setDistrictValue(e.target.value);
    setSelectedValue("DISTRICT", e.target.value);
    props.getBlockDistictPoly(e.target.value, productValue);
  };

  const onChangeSubDistrictValue = (e) => {
    setSubDistrictValue(e.target.value);
    setSelectedValue("BLOCK", e.target.value);
    props.getPanchyatBlockPoly(e.target.value, districtValue, productValue);
  };


  const onChangePanchayatValue = (e) => {
    setPanchayatValue(e.target.value);
    setSelectedValue("GRAM_PANCHAYAT", e.target.value);
    props.getVillagePanchayatPoly(subdistrictValue, districtValue, e.target.value, productValue);
  }
  const onChangeVillageValue = (e) => {
    setSelectedValue("VILLAGE", e.target.value);
    setVillageValue(e.target.value);
    props.getVillageListPoly(districtValue,
      panchayatValue,
      subdistrictValue,
      e.target.value, productValue);
  };
  const onChangeSesson1Value = (e) => {
    setSesson1Value(e.target.value);
    props.changeLeftMapWms(e.target.value);
  };

  const onChangeSesson2Value = (e) => {
    setSesson2Value(e.target.value);
    props.changeRightMapWms(e.target.value);
  }
  const filterSucces = () => {
    setIsVisbleButton(!isVisbleButton);
  };
  const triggerLogout = () => {
    props.logoutUser();
    console.log("Logout Success");
  };
  const sessonClear = () => {
    setSesson1Value("");
    props.changeLeftMapWms("");
    setSesson2Value("");
    props.changeRightMapWms("");
    setCompareLoaderBtn(true);
  };
  const clearTrigger = () => {
    setProductValue("");
    setDistrictValue("");
    setSubDistrictValue("");
    setVillageValue("");
    setSessonValue("");
    setPanchayatValue("");
    setSelectedValue("PRODUCT");
    setSelectedValue("DISTRICT");
    setSelectedValue("GRAM_PANCHAYAT");
    setSelectedValue("SESSON");
    setSelectedValue("BLOCK");
    setSelectedValue("VILLAGE");
    setClearLoader(true);
    window.location.reload();
  }
  const arrowhideSideBar = () => {
    props.arrowfilterSidebar();
  };


  var sessonEnableShow = productValue === "";
  var distrctEnableShow = sessonValue === "";
  var blockEnableShow = districtValue === "";
  var panchayatEnableShow = subdistrictValue === "";
  return (
    <div className="sidebar-sections">
      {props.disableSlide ? null : (
        <div className={`arrow-animations ${props.isVisbleSideBar ? "active" : ""}`} onClick={arrowhideSideBar}>
          <div className="sidebar-arrow">
            <label>
              <img src={rightArrow} alt="Arrow" />
            </label>
          </div>
        </div>
      )}


      {/* SideBar Left */}
      <div className="side-left hide">
        <div className="header-sections">
          {/* Header Logo */}
          <div className="header-logo">
            <img src={headerLogo} alt="headerLogo" />
          </div>

          {/* List */}
          <ul>
            {/* onClick={hideSideBar} */}
            <li >
              <label>
                <img src={search} alt="Search" />
              </label>
              <span>Search</span>
            </li>
          </ul>
        </div>

        {/* Logout */}
        <div className="logout-sections">
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={triggerLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </div>

      {(() => {
        if (props.mapScreen) {
          return (
            <div className={`side-right ${props.isVisbleSideBar ? "active" : ""}`}>
              {/* SideBar Right*/}
              <ul className="filter-list">
                <li>
                  <SelectPicker
                    selecterLable="Products"
                    selecterValue={productValue}
                    selecterOnChange={onChangeProductValue}
                    selecterOptions={props.productLayer}
                    selectPickerLoader={props.productLoader}
                  />
                </li>
                <li>
                  <SelectPicker
                    selecterLable="Season"
                    selecterValue={sessonValue}
                    selecterOnChange={onChangeSessonValue}
                    selecterOptions={props.sessonOption}
                    selectPickerLoader={props.sessonLoader}
                    selecterDisable={sessonEnableShow || floodDistrict}
                  />
                </li>
                <li className="selectpicker-arrow">
                  <SelectPicker
                    selecterLable="State"
                    selecterValue={"Odisha"}
                    selecterOnChange={onChangeStateValue}
                    selecterOptions={stateOption}
                    selecterDisable={true}
                  />
                </li>
                <li>
                  <SelectPicker
                    selecterLable="District"
                    selecterValue={districtValue}
                    selecterOnChange={onChangeDistrictValue}
                    selecterOptions={props.districtsList}
                    selectPickerLoader={props.districtLoader}
                    selecterDisable={distrctEnableShow || floodSeason}
                  />
                </li>
                <li>
                  <SelectPicker
                    selecterLable="Block"
                    selecterValue={subdistrictValue}
                    selecterOnChange={onChangeSubDistrictValue}
                    selecterOptions={props.blocksOptions}
                    selectPickerLoader={props.blockLoader}
                    selecterDisable={blockEnableShow || floodSeason}
                  />
                </li>
                <li>
                  <SelectPicker
                    selecterLable="Gram Panchayat"
                    selecterValue={panchayatValue}
                    selecterOnChange={onChangePanchayatValue}
                    selecterOptions={props.panchayatOptions}
                    selectPickerLoader={props.pachayatLoader}
                    selecterDisable={panchayatEnableShow}
                  />
                </li>
                <li>
                  <SelectPicker
                    selecterLable="Village"
                    selecterValue={villageValue}
                    selecterOnChange={onChangeVillageValue}
                    selecterOptions={props.villageOption}
                    selectPickerLoader={props.villageLoader}
                    selecterDisable={true}
                  />
                </li>
              </ul>
              <div className="conform-button">
                <div className="fun-button">
                  <div className="button" onClick={filterSucces}>
                    <LoaderButton
                      btnName={"Apply"}
                      btnCallBack={loaderTrigger}
                      btnDisable={props.applyLoaderCheck}
                      btnLoader={applyLoader}
                    />
                  </div>

                  <div className="export-button">
                    <LoaderButton
                      btnName={"Export"}
                      btnCallBack={props.triggerPdfDownload}
                      btnDisable={!props.sideBarCerdential.isVisble}
                      btnLoader={exportLoader}
                    />
                  </div>
                </div>
                <div className="button">
                  <LoaderButton
                    btnName={"Clear"}
                    btnCallBack={clearTrigger}
                    // btnDisable={!props.applyLoaderBtn.isVisble}
                    btnLoader={clearLoader}
                  />
                </div>
              </div>
            </div>

          );
        } else if (props.graphScreen) {

          return (
            <div className={`compare-bar-display ${props.isVisbleSideBar ? "active" : ""}`}>
              {/* Display List */}
              <ul>
                {productValue !== "" ? (
                  <li className="selectpicker-arrow">
                    <SelectPicker
                      selecterLable="Products"
                      selecterValue={productValue}
                      selecterOnChange={onChangeProductValue}
                      selecterOptions={props.productLayer}
                      selecterDisable={true}
                    />
                    {/* <span className="list-product">{cropName}</span> */}
                  </li>

                ) : null}

                <li className="selectpicker-arrow">
                  <SelectPicker
                    selecterLable="State"
                    selecterValue={"Odisha"}
                    selecterOnChange={onChangeStateValue}
                    selecterOptions={stateOption}
                    selecterDisable={true}
                  />
                  {/* <span className="list-product">Odisha</span> */}
                </li>
                {districtValue !== "" ? (
                  <li className="selectpicker-arrow">
                    <SelectPicker
                      selecterLable="District"
                      selecterValue={districtValue}
                      selecterOnChange={onChangeDistrictValue}
                      selecterOptions={props.districtsList}
                      selecterDisable={true}
                    />
                    {/* <span className="list-product">{districtName.text}</span> */}
                  </li>
                ) : null}
                {subdistrictValue !== "" ? (
                  <li className="selectpicker-arrow">
                    <SelectPicker
                      selecterLable="Block"
                      selecterValue={subdistrictValue}
                      selecterOnChange={onChangeSubDistrictValue}
                      selecterOptions={props.blocksOptions}
                      selecterDisable={true}
                    />
                    {/* <span className="list-product">{blockName.text}</span> */}
                  </li>

                ) : null}

                {panchayatValue !== "" ? (
                  <li className="selectpicker-arrow">
                    <SelectPicker
                      selecterLable="Gram Panchayat"
                      selecterValue={panchayatValue}
                      selecterOnChange={onChangePanchayatValue}
                      selecterOptions={props.panchayatOptions}
                      selecterDisable={true}
                    />
                  </li>

                ) : null}
                {villageValue !== "" ? (
                  <li className="selectpicker-arrow">
                    <SelectPicker
                      selecterLable="Village"
                      selecterValue={villageValue}
                      selecterOnChange={onChangeVillageValue}
                      selecterOptions={props.villageOption}
                      selecterDisable={true}
                    />
                  </li>

                ) : null}
              </ul>

              <div className="export-bar-button">
                <LoaderButton
                  btnName={"Export"}
                  btnCallBack={props.triggerPdfBarChart}
                  btnDisable={!props.sideBarCerdential.isVisble}
                  btnLoader={props.applyLoaderBtn}
                  btnLoader={props.barExportLoader}
                />
              </div>
            </div>
          );
        } else if (props.silder) {
          // console.log("0", sesson1value !== "" || sesson2value !== "");
          var clearCompare = sesson1value !== "" || sesson2value !== "";
          return (
            <div className={`compare-bar-display ${props.isVisbleSideBar ? "active" : ""}`}>
              {/* Display List */}
              <ul>
                <li className="season-list">
                  <div>
                    <SelectPicker
                      selecterLable="Season-1"
                      selecterValue={sesson1value}
                      selecterOnChange={onChangeSesson1Value}
                      selecterOptions={props.sessonOption}
                    /></div>
                  <div>
                    <SelectPicker
                      selecterLable="Season-2"
                      selecterValue={sesson2value}
                      selecterOnChange={onChangeSesson2Value}
                      selecterOptions={props.sessonOption}
                    /></div>
                </li>
                {productValue !== "" ? (
                  <li className="selectpicker-arrow">
                    <SelectPicker
                      selecterLable="Products"
                      selecterValue={productValue}
                      selecterOnChange={onChangeProductValue}
                      selecterOptions={props.productLayer}
                      selecterDisable={true}
                    />
                    {/* <span className="list-product">{cropName}</span> */}
                  </li>

                ) : null}

                <li className="selectpicker-arrow">
                  <SelectPicker
                    selecterLable="State"
                    selecterValue={"Odisha"}
                    selecterOnChange={onChangeStateValue}
                    selecterOptions={stateOption}
                    selecterDisable={true}
                  />
                  {/* <span className="list-product">Odisha</span> */}
                </li>
                {districtValue !== "" ? (
                  <li className="selectpicker-arrow">
                    <SelectPicker
                      selecterLable="District"
                      selecterValue={districtValue}
                      selecterOnChange={onChangeDistrictValue}
                      selecterOptions={props.districtsList}
                      selecterDisable={true}
                    />
                    {/* <span className="list-product">{districtName.text}</span> */}
                  </li>
                ) : null}
                {subdistrictValue !== "" ? (
                  <li className="selectpicker-arrow">
                    <SelectPicker
                      selecterLable="Block"
                      selecterValue={subdistrictValue}
                      selecterOnChange={onChangeSubDistrictValue}
                      selecterOptions={props.blocksOptions}
                      selecterDisable={true}
                    />
                    {/* <span className="list-product">{blockName.text}</span> */}
                  </li>

                ) : null}
                {panchayatValue !== "" ? (
                  <li className="selectpicker-arrow">
                    <SelectPicker
                      selecterLable="Gram Panchayat"
                      selecterValue={panchayatValue}
                      selecterOnChange={onChangePanchayatValue}
                      selecterOptions={props.panchayatOptions}
                      selecterDisable={true}
                    />
                  </li>

                ) : null}
                {villageValue !== "" ? (
                  <li className="selectpicker-arrow">
                    <SelectPicker
                      selecterLable="Village"
                      selecterValue={villageValue}
                      selecterOnChange={onChangeVillageValue}
                      selecterOptions={props.villageOption}
                      selecterDisable={true}
                    />
                  </li>

                ) : null}
              </ul>
              <div className="apply-clear">
                <div className="button">
                  <LoaderButton
                    btnName={"Clear"}
                    btnCallBack={sessonClear}
                    btnDisable={!clearCompare}
                  // btnLoader={clearCompare}
                  />
                </div>
              </div>
            </div>
          );
        }
      })()}
      {/* {(() => {
        if (applyLoader) {
          return (<div class="cloader"> <ComponentLoader /></div>);
        }
      })()} */}
    </div>
  );
};

const mapStateToProps = (state) => ({});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logoutUser: logoutUser,
      getLayerDateUser: getLayerDateUser,
      getLayeStyleUser: getLayeStyleUser,
      blockList: blockList,
      panchayatList: panchayatList,
      panchayatPolyLine: panchayatPolyLine,
      villageList: villageList,
      districtsPolyLine: districtsPolyLine,
      blockPolyLine: blockPolyLine,
      villagePolyLine: villagePolyLine,
      statsList: statsList,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
