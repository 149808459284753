// constant
import { GET, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { STATS_FAILURE, STATS_SUCCESS, STATS_REQUEST } from "./actionConstants";

// url
import { stats } from "../common/urls";

const statsRequested = () => ({
    type: STATS_REQUEST,
});

const statsSuccess = (data) => ({
    type: STATS_SUCCESS,
    data,
});

const statsFailure = (data) => ({
    type: STATS_FAILURE,
    data,
});

export const statsList = (product,statsId, level,successCallBack, failureCallBack) => (dispatch) => {
    dispatch(statsRequested());

    const url = stats(product,statsId, level);
    const onSuccess = (response) => {
        dispatch(statsSuccess(response));
        successCallBack(response);
    };

    const onFailure = (response) => {
        dispatch(statsFailure(response));
        failureCallBack(response);
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(GET, url, "", onSuccess, onFailure, dispatch, token);
        }
    });
};
