import {
    ADMIN_CREATE_FAILURE,
    ADMIN_CREATE_SUCCESS,
    ADMIN_CREATE_REQUEST,
  } from "../action/actionConstants";
  
  const initialState = {
    isFetchingAdminCreate: false,
    error: false,
    status: "",
    message: "intial",
  };
  
  const adminUser = (state = initialState, action) => {
    switch (action.type) {
      case ADMIN_CREATE_REQUEST:
        return {
          ...state,
          isFetchingAdminCreate: true,
          message: "Request",
        };
      case ADMIN_CREATE_FAILURE:
        return {
          ...state,
          isFetchingAdminCreate: false,
          error: true,
          status: action.data.status,
          message: "Failure",
        };
      case ADMIN_CREATE_SUCCESS:
        return {
          ...state,
          isFetchingAdminCreate: true,
          error: false,
          status: action.data.status,
          message: "Success",
        };
      default:
        return state;
    }
  };
  
  export default adminUser;
  