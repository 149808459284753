// react
import React, { useEffect } from "react";
// import React from "react";

// plugin
import {
  MapContainer,
  TileLayer,
  useMap,
  GeoJSON,
  WMSTileLayer,
  LayersControl,
  FeatureGroup,
  ZoomControl,
  Marker,
  ScaleControl,
  LayerGroup,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import L from "leaflet";
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import { renderToStaticMarkup } from "react-dom/server";
import "leaflet-loading";

// Bootstrap Plugin
import Modal from "react-bootstrap/Modal";

// component
import ComponentLoader from "../../../public-screen/component-loader/ComponetLoader";
import LoaderButton from "../../../component/loader-button/LoaderButton";

// geo
import { statGeo } from "./stateJson";

// style
import "./openlayer.scss";

const iconMarkup = renderToStaticMarkup(
  <label className="state-layer">Odisha</label>
);
const iconMap = L.divIcon({
  html: iconMarkup,
  iconSize: [250, 20],
  className: "label",
});

const positionCoor = [20.9517, 85.0985];

const snapshotOptions = {
  domtoimageOptions: {},
  screenName: "screen",
  mimeType: "image/png",
  captionFontSize: 15,
  captionColor: "black",
  hideElementsWithSelectors: [],
  onPixelDataFail: async function ({
    node,
    plugin,
    error,
    mapPane,
    domtoimageOptions,
  }) {
    return plugin._getPixelDataOfNormalMap(domtoimageOptions);
  },
};

const screenshotter = new SimpleMapScreenshoter(snapshotOptions);

const group = L.featureGroup();

const MyBounds = (props) => {
  const map = useMap();

  var boundingBoxData = props.bboxDataValue;
  var coords = boundingBoxData;
  useEffect(() => {
    if (!map) return;

    // group.clearLayers();

    coords.forEach((marker) => group.addLayer(L.marker(marker)));
    map.fitBounds(group.getBounds());
  }, [map, coords]);

  return null;
};

const OpenLayerMap = (props) => {
  const [mapref, setMapref] = React.useState();
  const geoJsonRef = React.useRef();

  useEffect(() => {
    if (mapref) {
      screenshotter.addTo(mapref);
    }
  }, [mapref]);

  const [innerMapLoader, setinnerMapLoader] = React.useState(true);
  const [boundsLoader, setBoundsLoader] = React.useState({});
  const [mapyesLoader, setMapyesLoader] = React.useState(false);
  const [mapWhenReady, setMapWhenReady] = React.useState(true);

  var showPoly = props.mapCerdential.polygonBoundry;

  var villageCql_Filter = props.mapCerdential.wmsRegionLayer;

  var wmsRegion = props.mapCerdential.wmsRegion;

  var boundingBoxData = props.mapCerdential.bboxData;

  var polygonGeoBoundry = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "MultiPolygon",
          coordinates: showPoly,
        },
      },
    ],
  };

  var statPolyGon = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "MultiPolygon",
          coordinates: statGeo,
        },
      },
    ],
  };

  useEffect(() => {
    setinnerMapLoader(false);
    setTimeout(() => {
      fitToCustomLayer();
    }, 500);
  }, []);

  const fitToCustomLayer = () => {
    var showPoly = props.mapCerdential.polygonBoundry;
    if (showPoly !== undefined) {
      setBoundsLoader(L.latLngBounds(boundingBoxData));
      setinnerMapLoader(true);
    } else {
      setTimeout(() => {
        setinnerMapLoader(true);
      }, 1000);
      // setinnerMapLoader(true);
    }
  };

  const onClick = (e) => {
    console.log(e);
  };

  const takeScreenShot = () => {
    // Get bounds of feature, pad ot a but too
    const featureBounds = geoJsonRef.current.getBounds();

    // Get pixel position on screen of top left and bottom right
    // of the bounds of the feature
    const nw = featureBounds.getNorthWest();
    const se = featureBounds.getSouthEast();
    const topLeft = mapref.latLngToContainerPoint(nw);
    const bottomRight = mapref.latLngToContainerPoint(se);

    // Get the resulting image size that contains the feature
    const imageSize = bottomRight.subtract(topLeft);

    screenshotter
      .takeScreen("image")
      .then((image) => {
        props.genreatePdf(image);
        setMapyesLoader(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const triggerImageAndPdf = () => {
    setTimeout(() => {
      takeScreenShot();
    }, 3000);
    setMapyesLoader(true);
  };
  return (
    <div className="map-layer-sectons">
      {(() => {
        if (innerMapLoader) {
          var timedata3 = props.mapCerdential.time !== "All Season";
          var timedata2 = props.mapCerdential.time !== "May - 2019";
          var timedata1 = props.mapCerdential.time !== "May - 2020";

          console.log(
            timedata2,
            timedata1,
            timedata1 || timedata2,
            timedata1 && timedata2
          );
          var timedata = timedata1 && timedata2 && timedata3;

          return (
            <MapContainer
              // ref={mapRef}
              center={props.mapCerdential.center}
              zoom={props.mapCerdential.zoom}
              doubleClickZoom={true}
              maxZoom={17}
              minZoom={4}
              id="mapId"
              onlayeradd={fitToCustomLayer}
              bounds={boundsLoader}
              zoomControl={false}
              preferCanvas={true}
              whenCreated={setMapref}
              loadingControl={true}
              whenReady={() => {
                setTimeout(() => {
                  setMapWhenReady(false);
                }, 1000);
              }}
            >
              {props.disableGoogleLayer ? (
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              ) : (
                <ReactLeafletGoogleLayer
                  apiKey="AIzaSyA4a74LigLcJGDcDFKrm4tV4GJMMPAPrKM"
                  type={"satellite"}
                  useGoogMapsLoader={true}
                />
              )}

              <ZoomControl position="bottomright" />
              <ScaleControl position="bottomleft" />

              <LayersControl position="topright">
                <LayersControl.BaseLayer name="OSM Layer">
                  <FeatureGroup>
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                  </FeatureGroup>
                </LayersControl.BaseLayer>

                {props.disableGoogleLayer ? null : (
                  <LayersControl.BaseLayer
                    checked
                    name="Google Satellite Layer"
                  >
                    <FeatureGroup>
                      <ReactLeafletGoogleLayer
                        apiKey="AIzaSyA4a74LigLcJGDcDFKrm4tV4GJMMPAPrKM"
                        type={"satellite"}
                        useGoogMapsLoader={true}
                      />
                    </FeatureGroup>
                  </LayersControl.BaseLayer>
                )}

                {/* {props.pointLayer.map((data, key) => {
                  return (
                    <LayersControl.Overlay name={data.display_name}>
                      <FeatureGroup>
                        <WMSTileLayer
                          url="https://isarcgisdemo.irri.org/geoserver/test/wms"
                          format="image/png"
                          layers={data.layer_name}
                          transparent="true"
                          styles={data.layer_style}
                        />
                      </FeatureGroup>
                    </LayersControl.Overlay>
                  );
                })} */}

                <GeoJSON
                  data={statPolyGon}
                  style={() => ({
                    color: "#FFFF00",
                    fillColor: "#ffffff00",
                    weight: 3,
                    opacity: 0.5,
                  })}
                />

                {props.mapCerdential.isVisble ? (
                  <LayersControl.Overlay checked name="Crop Layer">
                    <FeatureGroup>
                      {(() => {
                        if (timedata) {
                          return (
                            <WMSTileLayer
                              url="https://isarcgisdemo.irri.org/geoserver/test/wms"
                              format="image/png"
                              layers={"test:" + props.mapCerdential.layer}
                              transparent="true"
                              time={props.mapCerdential.time}
                              // attribution={props.mapCerdential.time}
                              styles={props.mapCerdential.styleLayer}
                            />
                          );
                        } else {
                          return (
                            <WMSTileLayer
                              url="https://isarcgisdemo.irri.org/geoserver/test/wms"
                              format="image/png"
                              layers={"test:" + props.mapCerdential.layer}
                              transparent="true"
                              // time={props.mapCerdential.time}
                              // attribution={props.mapCerdential.time}
                              styles={props.mapCerdential.styleLayer}
                            />
                          );
                        }
                      })()}
                    </FeatureGroup>
                  </LayersControl.Overlay>
                ) : null}
                <LayersControl.Overlay checked name="Vector Layer">
                  <FeatureGroup>
                    {(() => {
                      if (!props.mapCerdential.isVisble) {
                        return (
                          <WMSTileLayer
                            url="https://isarcgisdemo.irri.org/geoserver/test/wms"
                            format="image/png"
                            layers={"test:district"}
                            styles="white_color"
                            transparent="true"
                          // CQL_FILTER={`name <> '${"Dhenkanal"}'`}
                          />
                        );
                      } else {
                        if (timedata) {
                          return (
                            <WMSTileLayer
                              url="https://isarcgisdemo.irri.org/geoserver/test/wms"
                              format="image/png"
                              layers={wmsRegion}
                              styles="white_color"
                              transparent="true"
                              time={props.mapCerdential.time}
                              attribution={props.mapCerdential.time}
                              CQL_FILTER={`name <> '${villageCql_Filter.text}'`}
                            />
                          );
                        } else {
                          return (
                            <WMSTileLayer
                              url="https://isarcgisdemo.irri.org/geoserver/test/wms"
                              format="image/png"
                              layers={wmsRegion}
                              styles="white_color"
                              transparent="true"
                              attribution={props.mapCerdential.time}
                              CQL_FILTER={`name <> '${villageCql_Filter.text}'`}
                            />
                          );
                        }
                      }
                    })()}
                  </FeatureGroup>
                </LayersControl.Overlay>
                {/* ) : null} */}

                {showPoly != undefined ? (
                  <LayersControl.Overlay checked name="Boundary Layer">
                    <FeatureGroup>
                      <GeoJSON
                        ref={geoJsonRef}
                        data={polygonGeoBoundry}
                        style={() => ({
                          color: "#DC143C",
                          fillOpacity: 0,
                          weight: 5,
                          opacity: 0.5,
                        })}
                      />
                    </FeatureGroup>
                  </LayersControl.Overlay>
                ) : null}
              </LayersControl>

              <LayersControl
                position="topright"
                className="vector-layerchanges"
              >
                {props.pointLayer.map((data, key) => {
                  return (
                    <LayersControl.BaseLayer
                      collapse={(data) => {
                        console.log(data);
                      }}
                      name={data.display_name}
                      checked={data.display_name === "None"}
                    >
                      <FeatureGroup>
                        <WMSTileLayer
                          url={
                            data.display_name === "None"
                              ? ""
                              : "https://isarcgisdemo.irri.org/geoserver/test/wms"
                          }
                          format="image/png"
                          layers={data.layer_name}
                          transparent="true"
                          styles={data.layer_style}
                        />
                      </FeatureGroup>
                    </LayersControl.BaseLayer>
                  );
                })}
              </LayersControl>
              {props.mapCerdential.isVisble ? null : (
                <Marker position={positionCoor} icon={iconMap} />
              )}

              {/* legendsData */}

              {props.legendsData.length !== 0 ? (
                <div className="legends-sec">
                  <ul className="legend-list">
                    {props.legendsData.map((data, key) => {
                      return (
                        <li key={key} className="flex-item">
                          <span
                            style={{
                              border: "2px solid rgb(0, 0, 0)",
                              display: "inline-block",
                              padding: "7px 10px",
                              marginRight: "5px",
                              backgroundColor: data.color,
                            }}
                          ></span>
                          <label>{data.legend}</label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
            </MapContainer>
          );
        } else {
          return (
            <div class="cloader">
              <ComponentLoader />
            </div>
          );
        }
      })()}

      <Modal className="view-report" centered show={props.confromMapPdf}>
        <Modal.Body className="generate-pdf">
          <label>View Report</label>
          <div className="select-button">
            <LoaderButton
              btnName={"Yes"}
              btnCallBack={triggerImageAndPdf}
              btnDisable={mapyesLoader}
              btnLoader={false}
            />
            <LoaderButton
              btnName={"No"}
              btnCallBack={props.conformMapPdfhandleClose}
              btnDisable={mapyesLoader}
            />
          </div>
        </Modal.Body>
      </Modal>
      {(() => {
        if (mapyesLoader) {
          return (
            <div class="cloader">
              <ComponentLoader />
            </div>
          );
        }
      })()}
      {(() => {
        if (mapWhenReady) {
          return (
            <div class="cloader">
              <ComponentLoader />
            </div>
          );
        }
      })()}
    </div>
  );
};

export default OpenLayerMap;
