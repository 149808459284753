import {
   DISABLE_FAILURE,
   DISABLE_SUCCESS,
   DISABLE_REQUEST,
  } from "../action/actionConstants";
  
  const initialState = {
    isFetchingDisable: false,
    error: false,
    status: "",
    message: "intial",
  };
  
  const disableUser = (state = initialState, action) => {
    switch (action.type) {
      case DISABLE_REQUEST:
        return {
          ...state,
          isFetchingDisable: true,
          message: "Request",
        };
      case DISABLE_FAILURE:
        return {
          ...state,
          isFetchingDisable: false,
          error: true,
          status: action.data.status,
          message: "Failure",
        };
      case DISABLE_SUCCESS:
        return {
          ...state,
          isFetchingDisable: true,
          error: false,
          status: action.data.status,
          message: "Success",
        };
      default:
        return state;
    }
  };
  
  export default disableUser;
  