// react
import React, { useState } from "react";

// plugin
import Tabs from 'react-bootstrap/Tabs'
// function

// components
import Tab from '../../component/tab/tab';

// import Tabs from '../../component/tab/tabs';
import LogInLayout from "../../layouts/login-layout/LoginLayout";
import SignUpLayout from "../../layouts/signup-layout/SignUpLayout";

// Style
import '../login/login-screen.scss';

const LogIn = () => {

  const [key, setKey] = useState(0);
  return (
    <div className="home-sections">
        <LogInLayout />
    </div>
  );
};

export default LogIn;
