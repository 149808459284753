// constant
import { GET, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { LAYER_STYLE_FAILURE, LAYER_STYLE_SUCCESS, LAYER_STYLE_REQUEST } from "./actionConstants";

// url
import { getLayerStyle } from "../common/urls";

const getlayerStyleRequested = () => ({
    type: LAYER_STYLE_REQUEST,
});

const getlayerStyleSuccess = (data) => ({
    type: LAYER_STYLE_SUCCESS,
    data,
});

const getlayerStyleFailure = (data) => ({
    type: LAYER_STYLE_FAILURE,
    data,
});

export const getLayeStyleUser = (layerName, successCallBack, failureCallBack) => (dispatch) => {

    dispatch(getlayerStyleRequested);

    const url = getLayerStyle(layerName);
   
    const onSuccess = (response) => {
        successCallBack(response);
        dispatch(getlayerStyleSuccess(response));
        console.log("layer---", response);
    };

    const onFailure = (response) => {
        failureCallBack(response);
        dispatch(getlayerStyleFailure(response));
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(GET, url, "", onSuccess, onFailure, dispatch, token);
        }
    });
};
