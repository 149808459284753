// constant
import { GET, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { PANCHAYAT_POLY_LINE_FAILURE, PANCHAYAT_POLY_LINE_SUCCESS, PANCHAYAT_POLY_LINE_REQUEST } from "./actionConstants";

// url
import { panchayatPolygonUrl } from "../common/urls";

const panchayatPolyLineRequested = () => ({
    type: PANCHAYAT_POLY_LINE_REQUEST,
});

const panchayatPolyLineSuccess = (data) => ({
    type: PANCHAYAT_POLY_LINE_SUCCESS,
    data,
});

const panchayatPolyLineFailure = (data) => ({
    type: PANCHAYAT_POLY_LINE_FAILURE,
    data,
});

export const panchayatPolyLine = (districtId, blockId, panchayatId, successCallBack, failureCallBack) => (dispatch) => {
    dispatch(panchayatPolyLineRequested());

    const url = panchayatPolygonUrl(districtId, blockId, panchayatId);

    const onSuccess = (response) => {
        console.log(response);
        successCallBack(response);
        dispatch(panchayatPolyLineSuccess(response));
    };

    const onFailure = (response) => {
        console.log(response);
        failureCallBack(response);
        dispatch(panchayatPolyLineFailure(response));
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
                if (token !== undefined && token !== null) {
            apiCall(GET, url, "", onSuccess, onFailure, dispatch, token);
        }
    });
};
