// constant
import { POST } from "../common/constants";

// api
import { apiCall } from "../common/connect";

// action Constant
import { CREATE_FAILURE, CREATE_SUCCESS, CREATE_REQUEST } from "./actionConstants";

// url
import { CREATE_USER_URL } from "../common/urls";


const createRequested = () => ({
  type: CREATE_REQUEST,
});

const createSuccess = (data) => ({
  type: CREATE_SUCCESS,
  data,
});

const createFailure = (data) => ({
  type: CREATE_FAILURE,
  data,
});

export const createUser = ( userData, successCallBack, failureCallBack) => (dispatch) => {
  dispatch(createRequested);

  const url = CREATE_USER_URL;

  const onSuccess = (response) => {
    successCallBack(response);
    dispatch(createSuccess(response));
  };

  const onFailure = (response) => {
    failureCallBack(response);
    dispatch(createFailure(response));
  };

  apiCall(POST, url, userData, onSuccess, onFailure, dispatch);
};
