import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import reducers from "./reducer/index";

const customMiddleWare = (store) => (next) => (action) => {
  next(action, store);
};

const configStore = () => {
  const store = createStore(
    reducers,
    {},
    compose(applyMiddleware(thunk, customMiddleWare))
  );
  return store;
};
export default configStore;
