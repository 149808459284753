/**
 * File to create all action constants &
 * will be used as reducer type as well
 */

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const CREATE_REQUEST = 'CREATE_REQUEST';
export const CREATE_FAILURE = 'CREATE_FAILURE';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';

export const ADMIN_CREATE_REQUEST = 'ADMIN_CREATE_REQUEST';
export const ADMIN_CREATE_FAILURE = 'ADMIN_CREATE_FAILURE';
export const ADMIN_CREATE_SUCCESS = 'ADMIN_CREATE_SUCCESS';

export const OTP_REQUEST = 'OTP_REQUEST';
export const OTP_FAILURE = 'OTP_FAILURE';
export const OTP_SUCCESS = 'OTP_SUCCESS';

export const FORGOTPASSWORD_REQUEST = 'FORGOTPASSWORD_REQUEST';
export const FORGOTPASSWORD_FAILURE = 'FORGOTPASSWORD_FAILURE';
export const FORGOTPASSWORD_SUCCESS = 'FORGOTPASSWORD_SUCCESS';

export const ENABLE_REQUEST = 'ENABLE_REQUEST';
export const ENABLE_FAILURE = 'ENABLE_FAILURE';
export const ENABLE_SUCCESS = 'ENABLE_SUCCESS';

export const DISABLE_REQUEST = 'DISABLE_REQUEST';
export const DISABLE_FAILURE = 'DISABLE_FAILURE';
export const DISABLE_SUCCESS = 'DISABLE_SUCCESS';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const LAYER_DATE_REQUEST = 'LAYER_DATE_REQUEST';
export const LAYER_DATE_FAILURE = 'LAYER_DATE_FAILURE';
export const LAYER_DATE_SUCCESS = 'LAYER_DATE_SUCCESS';

export const LAYER_STYLE_REQUEST = 'LAYER_STYLE_REQUEST';
export const LAYER_STYLE_FAILURE = 'LAYER_STYLE_FAILURE';
export const LAYER_STYLE_SUCCESS = 'LAYER_STYLE_SUCCESS';

export const  LAYER_NAME_REQUEST = ' LAYER_NAME_REQUEST';
export const  LAYER_NAME_FAILURE = ' LAYER_NAME_FAILURE';
export const  LAYER_NAME_SUCCESS = ' LAYER_NAME_SUCCESS';

export const  VECTOR_LAYER_NAME_REQUEST = ' VECTOR_LAYER_NAME_REQUEST';
export const  VECTOR_LAYER_NAME_FAILURE = ' VECTOR_LAYER_NAME_FAILURE';
export const  VECTOR_LAYER_NAME_SUCCESS = ' VECTOR_LAYER_NAME_SUCCESS';

export const DISTRICTS_LIST_REQUEST = 'DISTRICTS_LIST_REQUEST';
export const DISTRICTS_LIST_FAILURE = 'DISTRICTS_LIST_FAILURE';
export const DISTRICTS_LIST_SUCCESS = 'DISTRICTS_LIST_SUCCESS';

export const BLOCK_LIST_REQUEST = 'BLOCK_LIST_REQUEST';
export const BLOCK_LIST_FAILURE = 'BLOCK_LIST_FAILURE';
export const BLOCK_LIST_SUCCESS = 'BLOCK_LIST_SUCCESS';

export const VILLAGE_LIST_REQUEST = 'VILLAGE_LIST_REQUEST';
export const VILLAGE_LIST_FAILURE = 'VILLAGE_LIST_FAILURE';
export const VILLAGE_LIST_SUCCESS = 'VILLAGE_LIST_SUCCESS';

export const DISTRICTS_POLY_LINE_REQUEST = 'DISTRICTS_POLY_LINE_REQUEST';
export const DISTRICTS_POLY_LINE_FAILURE = 'DISTRICTS_POLY_LINE_FAILURE';
export const DISTRICTS_POLY_LINE_SUCCESS = 'DISTRICTS_POLY_LINE_SUCCESS';

export const BLOCK_POLY_LINE_REQUEST = 'BLOCK_POLY_LINE_REQUEST';
export const BLOCK_POLY_LINE_FAILURE = 'BLOCK_POLY_LINE_FAILURE';
export const BLOCK_POLY_LINE_SUCCESS = 'BLOCK_POLY_LINE_SUCCESS';

export const PANCHAYAT_LIST_REQUEST = 'PANCHAYAT_LIST_REQUEST';
export const PANCHAYAT_LIST_FAILURE = 'PANCHAYAT_LIST_FAILURE';
export const PANCHAYAT_LIST_SUCCESS = 'PANCHAYAT_LIST_SUCCESS';

export const PANCHAYAT_POLY_LINE_REQUEST = 'PANCHAYAT_POLY_LINE_REQUEST';
export const PANCHAYAT_POLY_LINE_FAILURE = 'PANCHAYAT_POLY_LINE_FAILURE';
export const PANCHAYAT_POLY_LINE_SUCCESS = 'PANCHAYAT_POLY_LINE_SUCCESS';

export const VILLAGE_POLY_LINE_REQUEST = 'VILLAGE_POLY_LINE_REQUEST';
export const VILLAGE_POLY_LINE_FAILURE = 'VILLAGE_POLY_LINE_FAILURE';
export const VILLAGE_POLY_LINE_SUCCESS = 'VILLAGE_POLY_LINE_SUCCESS';

export const STATS_REQUEST = 'STATS_REQUEST';
export const STATS_FAILURE = 'STATS_FAILURE';
export const STATS_SUCCESS = 'STATS_SUCCESS';

export const STATE_REQUEST = 'STATE_REQUEST';
export const STATE_FAILURE = 'STATE_FAILURE';
export const STATE_SUCCESS = 'STATE_SUCCESS';