// constant
import { GET, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { PANCHAYAT_LIST_FAILURE, PANCHAYAT_LIST_SUCCESS, PANCHAYAT_LIST_REQUEST } from "./actionConstants";

// url
import { panchayatListUrl } from "../common/urls";

const panchayatRequested = () => ({
    type: PANCHAYAT_LIST_REQUEST,
});

const panchayatSuccess = (data) => ({
    type: PANCHAYAT_LIST_SUCCESS,
    data,
});

const panchayatFailure = (data) => ({
    type: PANCHAYAT_LIST_FAILURE,
    data,
});

export const panchayatList = ( districtId ,subDistrictsName, successCallBack, failureCallBack) => (dispatch) => {
    dispatch(panchayatRequested());
  
    const url = panchayatListUrl(districtId, subDistrictsName);
    
    const onSuccess = (response) => {
        successCallBack(response);
        dispatch(panchayatSuccess(response));
    };

    const onFailure = (response) => {
        failureCallBack(response);
        dispatch(panchayatFailure(response));
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(GET, url, "", onSuccess, onFailure, dispatch, token);
        }
    });
};
