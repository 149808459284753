// react
import React from "react";

// plugin
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useHistory } from "react-router-dom";

//functions 
import { logoutUser } from "../../../action/logout";
import { removeAllCookies } from "../../../common/localStorage";

// Images
import headerLogo from "../../../asstes/images/header-logo.svg";
import sarmap from "../../../asstes/images/logo-sarmap.jpg";
import goLogo from "../../../asstes/images/go-logo.svg";
import searchFilter from "../../../asstes/images/search-filter.png";
import filter from "../../../asstes/images/filter.png";
import barchat from "../../../asstes/images/barchart.png";
import compare from "../../../asstes/images/compare.png";
// styles
import "./header.scss";

const Header = (props) => {
    let history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isVisbleLogo, setIsVisbleLogo] = React.useState(false);

    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const hideSideBar = () => {
        props.filterSidebar();
    };
    const barChatCallBack = () => {
        props.barChatScreen();
    };
    const compareCallBack = () => {
        props.compareCallBack();
    };

    const triggerLogout = () => {
        props.logoutUser(successCallBackLogout);
        console.log("Logout Success");
    };

    const successCallBackLogout = () => {
        // history.push("/");
        removeAllCookies();
        setTimeout(() => {
            // removeAllCookies();
            setTimeout(() => {
                window.location.reload();
            }, 100);
        }, 200);
    }

    // console.log(props.loginuiHeader, "check")
    return (
        <div className="header-wrapper">
            <ul className="left-side">
                <li>
                    <img src={goLogo} alt="goLogo" />
                </li>
                {props.loginuiHeader ? (<li>
                    <img src={headerLogo} alt="headerLogo" />
                </li>) : null}


            </ul>
            <h2 className="header-heading">Satellite-based Rice Fallow & Cropping System Dynamics (SRFCSD)</h2>
            {props.loginuiHeader ? null : (<div className="flogo-icom"> <label className="homeLogo">
                <img src={headerLogo} alt="headerLogo" />
            </label>
                <label className="sarLogo">
                    <img src={sarmap} alt="headerLogo" />
                </label>
            </div>)}
            {props.loginuiHeader ? (<ul className="right-side">
                {/* mapScreen */}
                <li title="Search" className={`${props.mapScreen ? "active" : ""}`}>
                    <button onClick={hideSideBar} disabled={props.mapScreen}>
                        <label>
                            <img src={searchFilter} alt="headerLogo" />
                        </label>
                        {/* <span>Search</span> */}
                    </button>
                </li>
                <li title="Analytics" className={`${props.graphScreen ? "active" : ""}`} >
                    <button onClick={barChatCallBack} disabled={!props.headerCerdential.isVisble}>
                        <label>
                            <img src={barchat} alt="headerLogo" />
                        </label>
                        {/* <span>Analytics</span> */}
                    </button>
                </li>
                <li title="Comparasion" className={`${props.silderScreen ? "active" : ""}`} >
                    <button onClick={compareCallBack} disabled={!props.headerCerdential.isVisble}>
                        <label>
                            <img src={compare} alt="headerLogo" />
                        </label>
                        {/* <span>Comparasion</span> */}
                    </button>
                </li>
                {/* Logout */}
                <li className="logout-sections">
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={triggerLogout}>Logout</MenuItem>
                    </Menu>
                </li>
            </ul>) : null}

        </div>
    );
};

const mapStateToProps = (state) => ({});
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logoutUser: logoutUser,
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);

