// helper login

import { getUserId, getAdmin } from "../common/localStorage";

export const userLoggedIn = () => {
  if (getUserId()) {
    return true;
  } else {
    return false;
  }
};


export const whichUserLoggedIn = () => {
  if (getAdmin() === 'admin') {
    return 'admin';
  } else if(getAdmin() === 'public') {
    return 'public';
  }
};