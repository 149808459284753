// constant
import { GET, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { BLOCK_LIST_FAILURE, BLOCK_LIST_SUCCESS, BLOCK_LIST_REQUEST } from "./actionConstants";

// url
import { blockListUrl } from "../common/urls";

const blockRequested = () => ({
    type: BLOCK_LIST_REQUEST,
});

const blockSuccess = (data) => ({
    type: BLOCK_LIST_SUCCESS,
    data,
});

const blockFailure = (data) => ({
    type: BLOCK_LIST_FAILURE,
    data,
});

export const blockList = (districtsName, successCallBack, failureCallBack) => (dispatch) => {
    dispatch(blockRequested());
  
    const url = blockListUrl(districtsName);
  
    const onSuccess = (response) => {
        successCallBack(response);
        dispatch(blockSuccess(response));
    };

    const onFailure = (response) => {
        failureCallBack(response);
        dispatch(blockFailure(response));
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(GET, url, "", onSuccess, onFailure, dispatch, token);
        }
    });
};
