// Development Base URLs.
// export const AUTH_BASE_URL = `http://152.67.28.178:8788/v1/auth`;
// export const BASE_URL = `http://152.67.28.178:8788/v1/geo`;
// export const BASE_URL_DATA = `http://152.67.28.178:8788/v1/data`;
// https://isarcgisdemo.irri.org/
export const AUTH_BASE_URL = `https://isarcgisdemo.irri.org/v1/auth`;
export const BASE_URL = `https://isarcgisdemo.irri.org/v1/geo`;
export const BASE_URL_DATA = `https://isarcgisdemo.irri.org/v1/data`;


// http://152.67.28.178:8788/v1/docs http://20.198.102.74/v1/docs
// Production Base URLs.  
// export const AUTH_BASE_URL = `https://api.satsure.co/auth/v1`;
// export const BASE_URL = `https://api.satsure.co/sage/v1`;

export const LOGIN_URL = `${AUTH_BASE_URL}/login`;
export const LOGOUT_URL = `${AUTH_BASE_URL}/logout`;
export const REFERSH_URL = `${AUTH_BASE_URL}/refresh-both-tokens`;
export const CREATE_USER_URL = `${AUTH_BASE_URL}/new/create-user-public`;
export const ADMIN_CREATE_USER_URL = `${AUTH_BASE_URL}/admin/create-user-admin`;
export const FORGOTPASSWORD_USER_URL = `${AUTH_BASE_URL}/admin/change-password`;
export const ENABLE_USER_URL = `${AUTH_BASE_URL}/admin/enable-user`;
export const DISABLE_USER_URL = `${AUTH_BASE_URL}/admin/disable-user`;
export const USER_OTP_URL = `${AUTH_BASE_URL}/new/otp/req-user-public`;

// Map Layer Url
export const LAYER_NAME_USER_URL = `${BASE_URL}/get-layer-name`;

export function getLayerDate(layerName) {
  return `${BASE_URL}/get-layer-date/${layerName}`;
}

export function getLayerStyle(layerName) {
  return `${BASE_URL}/get-layer-style/${layerName}`;
}
// http://152.67.28.178:8788/v1/geo/get-layer-style/intensity


export const VECTOR_LAYER_NAME_URL = `${BASE_URL}/get-vector-layer-name`;
// http://152.67.28.178:8788/v1/geo/get-vector-layer-name

// Data

export const DISTRICTS_LIST_URL = `${BASE_URL_DATA}/district/list-district`;
// http://152.67.28.178:8788/v1/data/district/list-district

export function districtPolygonUrl(districtId) {
  return `${BASE_URL_DATA}/district/${districtId}`;
}
// http://152.67.28.178:8788/v1/data/district/1

export function blockListUrl(districtId) {
  return `${BASE_URL_DATA}/district/${districtId}/block/list-block`;
}
// http://152.67.28.178:8788/v1/data/district/1/block/list-block

export function blockPolygonUrl(districtId, blockId) {
  return `${BASE_URL_DATA}/district/${districtId}/block/${blockId}`;
}
// http://152.67.28.178:8788/v1/data/district/1/block/61

export function panchayatListUrl(districtId, blockId) {
  return `${BASE_URL_DATA}/district/${districtId}/block/${blockId}/list-panchayat`;
}
// http://152.67.28.178:8788/v1/data/district/1/block/61/list-panchayat

export function panchayatPolygonUrl(districtId, blockId, panchayatId) {
  return `${BASE_URL_DATA}/district/${districtId}/block/${blockId}/panchayat/${panchayatId}`;
}

// http://152.67.28.178:8788/v1/data/district/1/block/61/panchayat/53


export function villageListUrl(districtId, blockId, panchayatId) {
  return `${BASE_URL_DATA}/district/${districtId}/block/${blockId}/panchayat/${panchayatId}/list-village`;
}

// http://152.67.28.178:8788/v1/data/district/1/block/61/panchayat/53/list-village

export function villagePolygonUrl(districtId, blockId, panchayatId, villageId) {
  return `${BASE_URL_DATA}/district/${districtId}/block/${blockId}/panchayat/${panchayatId}/village/${villageId}`;
}
// http://152.67.28.178:8788/v1/data/district/1/block/61/panchayat/53/village/13343

export function stats(product, statsId, level) {
  return `${BASE_URL_DATA}/stats?id=${statsId}&layer=${product}&level=${level}`;
}
// http://152.67.28.178:8788/v1/data/stats?name=rice_fallow&id=2
// http://152.67.28.178:8788/v1/data/stats?id=1&layer=rice_fallow

export function stateUrl() {
  return `${BASE_URL_DATA}/state`;
}
// http://152.67.28.178:8788/v1/data/state