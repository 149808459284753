// react
import React, { useState } from "react";
// plugin
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

// component
import LoaderButton from "../../component/loader-button/LoaderButton";

// function
import { createUser } from "../../action/createUser";

// style
import "../../layouts/signup-layout/signup.scss";


// contanier
// Form Field
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  colors: {
    borderColor: "green",
  },
}));

const SignUpLayout = (props) => {
  let history = useHistory();

  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErrorShow, setPasswordErrorShow] = useState(false);
  const [confirmPasswordErrorShow, setConfirmPasswordErrorShow] = useState(false);
  const [confirmpassword, setConfirmpassword] = useState("");
  const [signupLoader, setSignupLoader] = useState(false);
  const [isVisbleError, setIsVisbleError] = React.useState(false);
  const [signupSuccess, setSignupSuccess] = React.useState("");

  // Password
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const passwordVaildCalBack = (e) => {
    var pas = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})").test(e);
    // console.log(!pas);
    setPasswordErrorShow(!pas)
    setPassword(e)
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
    setIsVisbleError(!isVisbleError)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Confirm Password
  const [confirmvalues, setConfirmValues] = React.useState({
    confirmpassword: '',
    showConfirmPassword: false,
  });
  const cpasswordVaildCalBack = (e) => {

    setConfirmpassword(e)

    var confirmPwd = password !== e;
    setConfirmPasswordErrorShow(confirmPwd)
    // console.log("confirmPwd", confirmPwd);
  }
  const handleClickShowConfirmPassword = () => {
    setConfirmValues({ ...confirmvalues, showConfirmPassword: !confirmvalues.showConfirmPassword });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  // Sign Up
  const triggerSingup = () => {
    var isName = name === ""
    var isPhone = phone === ""
    var isEmail = email === ""
    var isPassword = password === ""
    var isConfirmpassword = confirmpassword === ""
    var validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email);

    var validsuccess = isName && isPhone && isEmail && isPassword && isConfirmpassword
    // console.log(isName);
    // console.log(isEmail);
    // console.log(isPhone);
    // console.log(isPassword);
    // console.log(isConfirmpassword);
    // console.log(validPassword);
    if (!validsuccess) {
      console.log("Filed full Success");
      if (validEmail) {
        console.log("Mail Valid Success");
        setSignupLoader(true);
        var dataSet = {
          name: name,
          phone: phone,
          email: email,
          password: password,
          admin: false,
        };
        props.createUser(dataSet, createSuccessCallBack, createfailureCallBack);
      } else {
        console.log("Mail In Valid");
      }
    } else {
      console.log("fails");
    }


  };
  const createfailureCallBack = (response) => {
    // console.log("fails", response);
    setSignupLoader(false);
  };
  const createSuccessCallBack = (response) => {
    // console.log("succ", response);
    setEmail("");
    setName("");
    setPhone("");
    setPassword("");
    setConfirmpassword("");
    props.signupSuccess();
    setSignupLoader(true);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 300);
  };

  return (
    <div className="irri-sign-up">
      <div className="sign-up">
        <h2>Sign Up</h2>
        <div className="sign-up-field">
          <TextField
            id="outlined-password-input"
            label="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoComplete="off"
            variant="outlined"
          />
          <PhoneInput
            country={'in'}
            value={phone}
            onChange={(value) => setPhone(value)}
          />
          <TextField
            id="outlined-password-input"
            label="Email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="off"
            variant="outlined"
          />
          <FormControl
            className={clsx(classes.margin, classes.textField, classes.colors)}
            variant="outlined"
            autoComplete="off"
            error={passwordErrorShow}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => passwordVaildCalBack(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
              autoComplete="off"
            />
            {passwordErrorShow ? (<FormHelperText id="component-error-text">Use 8 or more characters with a mix of letters, numbers & symbols</FormHelperText>) : null}
          </FormControl>
          <FormControl
            className={clsx(classes.margin, classes.textField, classes.colors)}
            variant="outlined"
            autoComplete="off"
            error={confirmPasswordErrorShow}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Confirm Password
                    </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={confirmvalues.showConfirmPassword ? "text" : "password"}
              value={confirmpassword}
              onChange={(e) => cpasswordVaildCalBack(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                  >
                    {confirmvalues.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
              autoComplete="off"
            />
            {(() => {

            })()}
            {confirmPasswordErrorShow ? (<FormHelperText id="component-error-text">Please fill correct password</FormHelperText>) : null}
          </FormControl>
          <LoaderButton
            btnName={"Sign up"}
            btnCallBack={triggerSingup}
            btnDisable={signupLoader}
            btnLoader={signupLoader}
          />
        </div>
      </div>
    </div>
  );
};

// redux;
const mapStateToProps = (state) => {
  return {
    isFetchingCreate: state.createUser.isFetchingCreate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createUser: createUser,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpLayout);
