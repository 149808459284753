import { CONTENT_TYPE_JSON } from "./constants";

const header = {
  "Content-Type": CONTENT_TYPE_JSON,
  accept: CONTENT_TYPE_JSON,
};

// redux api
export function apiCall(
  requestMethod,
  url,
  data,
  onSuccess,
  onFailure,
  dispatch,
  accessToken = null
) {
  // TODO: Create a middle ware to handle to refresh token scenario
  if (accessToken != null) {
    header["Authorization"] = accessToken;
  }

  var formData = {
    method: requestMethod,
    headers: header,
  };
  var formBody = JSON.stringify(data);

  if (data !== undefined && data !== "") {
    formData["body"] = formBody;
  }

  fetch(url, formData)
    .then((response) => {
      // TODO: Need to create all API fallbacks.
      // if (response.status === 401) {
      //   dispatch(forcedLogout());
      // } else

      response
        .json()
        .then((responseJson) => {
          if (responseJson.status) {
            onSuccess(responseJson);
          } else {
            onFailure(responseJson);
          }
        })
        .catch((err) => {
          onFailure(err);
        });
    })
    .catch((error) => {
      onFailure(error);
    });
}

// refresh token
export async function refreshAccessToken(requestMethod, url, refreshToken) {
  header["Authorization"] = refreshToken;

  var formData = {
    method: requestMethod,
    headers: header,
  };

  // var requestJson = JSON.stringify({
  //   request: 'Requested to update access token',
  // });

  return await fetch(url, formData)
    .then((response) => {
      return response
        .json()
        .then((responseJson) => {
          return responseJson;
        })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.log(error);
    });
}
