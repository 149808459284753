// constant
import { POST } from "../common/constants";

// api
import { apiCall } from "../common/connect";

// action Constant
import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGIN_REQUEST } from "./actionConstants";

// url
import { LOGIN_URL } from "../common/urls";

import {
  setAccessToken,
  setUserId,
  setRefreshToken,
  setAdmin,
} from "../common/localStorage";

const loginRequested = () => ({
  type: LOGIN_REQUEST,
});

const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  data,
});

const loginFailure = (data) => ({
  type: LOGIN_FAILURE,
  data,
});

export const loginUser = (email, password, successCallBack, failureCallBack) => (dispatch) => {
  dispatch(loginRequested);

  const url = LOGIN_URL;

  var userDetail = {
    email: email,
    password: password,
  };

  // // Added as helper.
  // var userDetail = {
  //   email: 'cisco_user@satsure.co',
  //   password: '3IuVAVKzjTRJK52iK9IxwsnC',
  // };

  // var userDetail = {
  //   email: "testuser1@satsure.co",
  //   password: "123123",
  // };


  const onSuccess = (response) => {
    setAccessToken(response.data.accessToken);
    setUserId(response.data.userId);
    setRefreshToken(response.data.refreshToken);
    // isAdmin
    if(response.data.isAdmin === true) {
      setAdmin("admin");
    } else {
      setAdmin("public");
    }

    successCallBack(response);
    dispatch(loginSuccess(response));
  };

  const onFailure = (response) => {
    failureCallBack(response);
    dispatch(loginFailure(response));
  };

  apiCall(POST, url, userDetail, onSuccess, onFailure, dispatch);
};
