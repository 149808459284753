// react
import React, { useState } from "react";
import { toast } from 'react-toastify';
// plugin
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import clsx from "clsx";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import Modal from "react-bootstrap/Modal";

// component
import LoaderButton from "../../component/loader-button/LoaderButton";

// function
import { adminUser } from "../../action/adminUser";

// style
import "../../layouts/admin-signup-layout/admin-signup.scss";

// Image
import close from "../../asstes/images/close.png";

// Form Field
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  colors: {
    borderColor: "green",
  },
}));

const AdminSignUpLayout = (props) => {
  const classes = useStyles();

  const [aemail, setAEmail] = useState("");
  const [aname, setAName] = useState("");
  const [aphone, setAPhone] = useState("");
  const [apassword, setAPassword] = useState("");
  const [AEmailErrorShow, setAEmailErrorShow] = useState(false);
  const [ApasswordErrorShow, setAPasswordErrorShow] = useState(false);
  const [confirmPasswordErrorShow, setConfirmPasswordErrorShow] =
    useState(false);
  const [confirmpassword, setConfirmpassword] = useState("");
  const [adminsignupLoader, setAdminSignupLoader] = useState(false);
  const [isVisbleError, setIsVisbleError] = React.useState(false);

  // Email
  const aemailValidCalBack = (e) => {
    var validAEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(e);
    // console.log(!validAEmail);
    setAEmailErrorShow(!validAEmail);
    setAEmail(e);
  };
  // Password
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const passwordVaildCalBack = (e) => {
    var pas = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    ).test(e);
    // console.log(!pas);
    setAPasswordErrorShow(!pas);
    setAPassword(e);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
    setIsVisbleError(!isVisbleError);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Confirm Password
  const [confirmvalues, setConfirmValues] = React.useState({
    confirmpassword: "",
    showConfirmPassword: false,
  });
  const cpasswordVaildCalBack = (e) => {
    setConfirmpassword(e);
    var confirmPwd = apassword !== e;
    setConfirmPasswordErrorShow(confirmPwd);
    // console.log("confirmPwd", confirmPwd);
  };
  const handleClickShowConfirmPassword = () => {
    setConfirmValues({
      ...confirmvalues,
      showConfirmPassword: !confirmvalues.showConfirmPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const triggerAdminLogin = () => {
    var dataSet = {
      name: aname,
      phone: aphone,
      email: aemail,
      password: apassword,
      admin: false,
    };
    props.adminUser(dataSet, createSuccessCallBack, createfailureCallBack);
  };
  const createfailureCallBack = (response) => {
    // console.log("fails", response);
    setAdminSignupLoader(false);
    setAName("");
    setAPhone("");
    setAEmail("");
    setAPassword("");
    setConfirmpassword("");
    props.closeSingUpModel();
    toast.error("Please fill all values 👨‍💻");
  };
  const createSuccessCallBack = (response) => {
    // console.log("succ", response);
    // props.signupSuccess();
    setAdminSignupLoader(false);
    setAName("");
    setAPhone("");
    setAEmail("");
    setAPassword("");
    setConfirmpassword("");
    props.closeSingUpModel();
    toast.success("Success 🤗");
    // setTimeout(() => {
    //   window.location.reload();
    // }, 300);
  };
  // Sign Up
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="signup-section"
    >
      <Modal.Body>
        <div className="close-icon" onClick={props.onHide}>
          <img src={close} alt="close" />
        </div>
        <div className="irri-admin-sign-up">
          <div className="sign-up">
            <h2>Sign Up</h2>
            <div className="sign-up-field">
              <TextField
                id="outlined-password-input"
                label="Name"
                type="text"
                value={aname}
                onChange={(e) => setAName(e.target.value)}
                autoComplete="off"
                variant="outlined"
              />
              <PhoneInput
                country={"in"}
                value={aphone}
                onChange={(value) => setAPhone(value)}
              />
              <TextField
                id="outlined-password-input"
                label="Email"
                type="text"
                value={aemail}
                onChange={(e) => aemailValidCalBack(e.target.value)}
                autoComplete="off"
                variant="outlined"
                error={AEmailErrorShow}
              />
              <FormControl
                className={clsx(
                  classes.margin,
                  classes.textField,
                  classes.colors
                )}
                variant="outlined"
                autoComplete="off"
                error={ApasswordErrorShow}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={apassword}
                  onChange={(e) => passwordVaildCalBack(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                  autoComplete="off"
                />
                {ApasswordErrorShow ? (
                  <FormHelperText id="component-error-text">
                    Use 8 or more characters with a mix of letters, numbers &
                    symbols
                  </FormHelperText>
                ) : null}
              </FormControl>
              <FormControl
                className={clsx(
                  classes.margin,
                  classes.textField,
                  classes.colors
                )}
                variant="outlined"
                autoComplete="off"
                error={confirmPasswordErrorShow}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={confirmvalues.showConfirmPassword ? "text" : "password"}
                  value={confirmpassword}
                  onChange={(e) => cpasswordVaildCalBack(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {confirmvalues.showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                  autoComplete="off"
                />
                {(() => { })()}
                {confirmPasswordErrorShow ? (
                  <FormHelperText id="component-error-text">
                    Please fill correct password
                  </FormHelperText>
                ) : null}
              </FormControl>
              <LoaderButton
                btnName={"Sign up"}
                btnCallBack={triggerAdminLogin}
                btnDisable={adminsignupLoader}
                btnLoader={adminsignupLoader}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

// redux;
const mapStateToProps = (state) => {
  return {
    isFetchingAdminCreate: state.adminUser.isFetchingAdminCreate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminUser: adminUser,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminSignUpLayout);
