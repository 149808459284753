import {
    CREATE_FAILURE,
    CREATE_SUCCESS,
    CREATE_REQUEST,
  } from "../action/actionConstants";
  
  const initialState = {
    isFetchingCreate: false,
    error: false,
    status: "",
    message: "intial",
  };
  
  const createUser = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_REQUEST:
        return {
          ...state,
          isFetchingCreate: true,
          message: "Request",
        };
      case CREATE_FAILURE:
        return {
          ...state,
          isFetchingCreate: false,
          error: true,
          status: action.data.status,
          message: "Failure",
        };
      case CREATE_SUCCESS:
        return {
          ...state,
          isFetchingCreate: true,
          error: false,
          status: action.data.status,
          message: "Success",
        };
      default:
        return state;
    }
  };
  
  export default createUser;
  