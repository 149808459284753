import React from "react";

// component
import DashBoardLayout from "../../layouts/dash-board-layout/DashBoardLayout";

const DashBoard = () => {
  return <DashBoardLayout />;
};


export default DashBoard;
