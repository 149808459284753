// react
import React from "react";

// plugin

// style
import "./componet-loader.scss";

const ComponentLoader = () => {
  return (
    <div className="loader-sec">
      <div className="load"></div>
    </div>
  );
};

export default ComponentLoader;
