// @flow
import { combineReducers } from 'redux';

// Authentication Reducers.
import login from './login';
import logout from './logout';
import createUser from "./createUser";
import adminUser from "./adminUser";
import forgotpasswordUser from './forgotPassword';
import enableUser from './enableUser';
import disableUser from './disableUser';
import districtsList from './districtsList';
import blockList from './blockList';
import villageList from './villageList';
import villagePolyLine from './villagePolyLine';
import panchayatList from './panchayatList';
import panchayatPolyLine from './panchayatPolyLine';
import statsList from './stats';
import stateList from './state';

// sage

// Root Reducer.
const rootReducer = combineReducers({
  login: login,
  logout: logout,
  createUser: createUser,
  adminUser: adminUser,
  forgotpasswordUser: forgotpasswordUser,
  enableUser: enableUser,
  disableUser: disableUser,
  districtsList: districtsList,
  blockList: blockList,
  panchayatList: panchayatList,
  panchayatPolyLine: panchayatPolyLine,
  villageList: villageList,
  villagePolyLine: villagePolyLine,
  statsList: statsList,
  stateList: stateList,
});

export default rootReducer;
