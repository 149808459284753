// constant
import { GET, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { STATE_FAILURE, STATE_SUCCESS, STATE_REQUEST } from "./actionConstants";

// url
import { stateUrl } from "../common/urls";

const stateRequested = () => ({
    type: STATE_REQUEST,
});

const stateSuccess = (data) => ({
    type: STATE_SUCCESS,
    data,
});

const stateFailure = (data) => ({
    type: STATE_FAILURE,
    data,
});

export const stateList = (successCallBack, failureCallBack) => (dispatch) => {
    dispatch(stateRequested());

    const url = stateUrl();
    const onSuccess = (response) => {
        dispatch(stateSuccess(response));
        successCallBack(response);
    };

    const onFailure = (response) => {
        dispatch(stateFailure(response));
        failureCallBack(response);
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(GET, url, "", onSuccess, onFailure, dispatch, token);
        }
    });
};
