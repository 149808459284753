// constant
import { GET, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { DISTRICTS_POLY_LINE_FAILURE, DISTRICTS_POLY_LINE_SUCCESS, DISTRICTS_POLY_LINE_REQUEST } from "./actionConstants";

// url
import { districtPolygonUrl } from "../common/urls";

const districtsPolyLineRequested = () => ({
    type: DISTRICTS_POLY_LINE_REQUEST,
});

const districtsPolyLineSuccess = (data) => ({
    type: DISTRICTS_POLY_LINE_SUCCESS,
    data,
});

const districtsPolyLineFailure = (data) => ({
    type: DISTRICTS_POLY_LINE_FAILURE,
    data,
});

export const districtsPolyLine = (districtsId, successCallBack, failureCallBack) => (dispatch) => {
    dispatch(districtsPolyLineRequested());

    const url = districtPolygonUrl(districtsId);
    const onSuccess = (response) => {
        successCallBack(response);
        dispatch(districtsPolyLineSuccess(response));
    };

    const onFailure = (response) => {
        failureCallBack(response);
        dispatch(districtsPolyLineFailure(response));
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(GET, url, "", onSuccess, onFailure, dispatch, token);
        }
    });
};
