// react
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// styles
import "./select-picker.scss";
import "../loader-button/loader.scss";

const SelectPicker = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));

  const classes = useStyles();

  return (
    <div className="select-input">
      {/* <label className="head-label">{props.selecterLable}</label> */}
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">
          {props.selecterLable}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={props.selecterValue}
          onChange={props.selecterOnChange}
          label={props.selecterLable}
          disabled={props.selecterDisable}
        //disabled={props.selecterDisable != undefined}
        >
          {props.selecterOptions.map((data, key) => {
            return <MenuItem value={data.value}> {data.text} </MenuItem>;
          })}
        </Select>
        {props.selectPickerLoader ? (
          <div class="loadbtn loadbtncenter ">
            <div class="loader">
              <div class="circle" id="a"></div>
              <div class="circle" id="b"></div>
              <div class="circle" id="c"></div>
            </div>
          </div>
        ) : null}
      </FormControl>
    </div>
  );
};
export default SelectPicker;
