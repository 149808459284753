// constant
import { POST } from "../common/constants";

// api
import { apiCall } from "../common/connect";

// action Constant
import { OTP_FAILURE, OTP_SUCCESS, OTP_REQUEST } from "./actionConstants";

// url
import { USER_OTP_URL } from "../common/urls";


const otpRequested = () => ({
    type: OTP_REQUEST,
});

const otpSuccess = (data) => ({
    type: OTP_SUCCESS,
    data,
});

const otpFailure = (data) => ({
    type: OTP_FAILURE,
    data,
});

export const userOtp = (userData, successCallBack, failureCallBack) => (dispatch) => {
    dispatch(otpRequested);

    const url = USER_OTP_URL;

    const onSuccess = (response) => {
        successCallBack(response);
        dispatch(otpSuccess(response));
    };

    const onFailure = (response) => {
        failureCallBack(response);
        dispatch(otpFailure(response));
    };

    apiCall(POST, url, userData, onSuccess, onFailure, dispatch);
};
