// react
import React from "react";

// plugin

// function

// components
import ForgotPasswordLayout from "../../layouts/forgot-password-layout/ForgotPasswordLayout";

// contanier

const ForgotPassword = () => {
    return <ForgotPasswordLayout />;
};

export default ForgotPassword;