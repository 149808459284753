// constant
import { GET, ACCESS_TOKEN } from "../common/constants";

import { _getStorageValue } from "../common/localStorage";
// api
import { apiCall } from "../common/connect";

// action Constant
import { VILLAGE_POLY_LINE_FAILURE, VILLAGE_POLY_LINE_SUCCESS, VILLAGE_POLY_LINE_REQUEST } from "./actionConstants";

// url
import { villagePolygonUrl } from "../common/urls";

const villagePolyLineRequested = () => ({
    type: VILLAGE_POLY_LINE_REQUEST,
});

const villagePolyLineSuccess = (data) => ({
    type: VILLAGE_POLY_LINE_SUCCESS,
    data,
});

const villagePolyLineFailure = (data) => ({
    type: VILLAGE_POLY_LINE_FAILURE,
    data,
});

export const villagePolyLine = (districtId, blockId, panchayatId, villageId, successCallBack, failureCallBack) => (dispatch) => {
    dispatch(villagePolyLineRequested());

    const url = villagePolygonUrl(districtId, blockId,panchayatId, villageId);

    const onSuccess = (response) => {
        successCallBack(response);
        dispatch(villagePolyLineSuccess(response));
    };

    const onFailure = (response) => {
        failureCallBack(response);
        dispatch(villagePolyLineFailure(response));
    };
    _getStorageValue(ACCESS_TOKEN).then((token) => {
        if (token !== undefined && token !== null) {
            apiCall(GET, url, "", onSuccess, onFailure, dispatch, token);
        }
    });
};
