import {
    PANCHAYAT_POLY_LINE_FAILURE,
    PANCHAYAT_POLY_LINE_SUCCESS,
    PANCHAYAT_POLY_LINE_REQUEST,
} from "../action/actionConstants";

const initialState = {
    isFetchingPanchayatPolyLine: false,
    error: false,
    status: "",
    message: "intial",
};

const panchayatPolyLine = (state = initialState, action) => {
    switch (action.type) {
        case PANCHAYAT_POLY_LINE_REQUEST:
            return {
                ...state,
                isFetchingPanchayatPolyLine: true,
                message: "Request",
            };
        case PANCHAYAT_POLY_LINE_FAILURE:
            return {
                ...state,
                isFetchingPanchayatPolyLine: false,
                error: true,
                status: action.data.status,
                message: "Failure",
            };
        case PANCHAYAT_POLY_LINE_SUCCESS:
            return {
                ...state,
                isFetchingPanchayatPolyLine: true,
                error: false,
                status: action.data.status,
                message: "Success",
            };
        default:
            return state;
    }
};

export default panchayatPolyLine;
