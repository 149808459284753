// constant
import { POST } from "../common/constants";

// api
import { apiCall } from "../common/connect";

// action Constant
import { DISABLE_FAILURE, DISABLE_SUCCESS, DISABLE_REQUEST } from "./actionConstants";

// url
import { DISABLE_USER_URL } from "../common/urls";


const disableRequested = () => ({
  type: DISABLE_REQUEST,
});

const disableSuccess = (data) => ({
  type: DISABLE_SUCCESS,
  data,
});

const disableFailure = (data) => ({
  type: DISABLE_FAILURE,
  data,
});

export const disableUser = ( userData, successCallBack, failureCallBack) => (dispatch) => {
  dispatch(disableRequested);

  const url = DISABLE_USER_URL;

  const onSuccess = (response) => {
    successCallBack(response);
    dispatch(disableSuccess(response));
  };

  const onFailure = (response) => {
    failureCallBack(response);
    dispatch(disableFailure(response));
  };

  apiCall(POST, url, userData, onSuccess, onFailure, dispatch);
};
